@keyframes spin {
    from {
        transform: rotate(0);
    }
    to{
        transform: rotate(359deg);
    }
}

.spinner-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.three-quarter-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid $c_sub;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin .5s linear 0s infinite;
}