.myex-list-checkbox {
	gap: calculateRem(10px);
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	.myex-form-field {
		margin: 0;
	}
	@include min-mobile {
		flex-direction: row;
		gap: calculateRem(20px);
	}

    &--center{
        justify-content: center;
    }
}
