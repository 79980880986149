/* ===== LAYOUT ===========================================
used for single pages
- explorer 
- collection
*===== */

// usually contain the back button of the single page and it's unique
#myex-section__single__top {
	margin-bottom: calculateRem(20px);

	@include min-mobile {
		margin-bottom: calculateRem(40px);
	}
}

// usually contain main content of the single page and it's unique
#myex-section__single__header {
	margin-bottom: calculateRem(20px);

	@include min-mobile {
		margin-bottom: calculateRem(60px);
	}
}

#myex-section__single__anchors {
	margin-bottom: calculateRem(23px);


	// hide first title after anchor
	@include mobile {
		+ .myex-section {
			.myex-section__header {
				display: none;
			}
		}
	}
	@include min-mobile {
		display: none;
	}
}
