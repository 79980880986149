//field with icon marker
.myex-input-place {
	@extend .icon-ico-localisation;

	position: relative;

	input[type=list],
	input[type=text] {
		padding-left: calculateRem(44px);
	}

	&:before {
		position: absolute;
		left: calculateRem(21px);
		top: 50%;
		transform: translateY(-50%);

		@include font();
	}
}
