// Container sans colonnes
// ==========================================================================
.content-wrapper {
	display: block;
	position: relative;
	max-width: $max-width;
	margin: 0 auto;

	&.content-wrapper-large{
		@include wide{
			max-width: $max-width-large !important;
		}
	}

	@media screen and (min-width: 1600px) {
		&.bloc-small {
			max-width: $grid-small !important;
		}
		&.bloc-extra-small {
			max-width: $grid-extra-small !important;
		}
	}
	@media screen and (max-width: $max-width+$border-gutter+$border-gutter+0px) {
		margin: 0 $border-gutter;
	}
	@include mobile{
		margin: 0 20px;
	}

	// BLOC SMALL -> Force a 980px par défaut sur écran normaux
	@media screen and (min-width: 1240px) {
		&.bloc-small {
			max-width: $grid-small !important;
		}
		&.bloc-extra-small {
			max-width: $grid-extra-small !important;
		}
	}

	// BLOC SMALL -> Doit rester centré tant qu'il est plus petit que le contenu normal, donc : margin:auto;
	// Une fois à la taille normale, il reprend le comportement d'un bloc normal (min-width IMPORTANT)
	@media screen and (max-width: $max-width+$border-gutter+$border-gutter) and (min-width: $grid-small+$border-gutter+$border-gutter+$content-gutter) {
		&.bloc-small {
			max-width: $grid-small !important;
			margin: 0 auto !important;
		}
	}

	@media screen and (max-width: $max-width+$border-gutter+$border-gutter) and (min-width: $grid-extra-small+$border-gutter+$border-gutter+$content-gutter) {
		&.bloc-extra-small {
			max-width: $grid-extra-small !important;
			margin: 0 auto !important;
		}
	}

    @include tablet_landscape{
        margin-left: 40px !important;
        margin-right: 40px !important;
    }

	// Breakpoint mobile, on met les marge de l'écran pour tout
	@include mobile{
		margin-left: 20px !important;
		margin-right: 20px !important;
	}
}

// Container avec colonnes
// ==========================================================================
.col-wrapper {
	position: relative;
	box-sizing: content-box;
	margin: 0 auto;
	max-width: $max-width+$content-gutter !important; // 1200 + 30 (goutière intérieur des blocs)

	// Pour les écrans supérieurs à 1600px on agrandit le contenu (1600px - les bords de l'écran border-gutter)
	&.col-wrapper-large{
		@include wide{
			max-width: 1600px + $content-gutter - $border-gutter - $border-gutter !important;
		}
	}

	// BLOC SMALL -> Force a 980px par défaut sur écran normaux
	@media screen and (min-width: 1240px) {
		&.bloc-small {
			max-width: $grid-small+$content-gutter !important; // 980px + 30 (goutière intérieur des blocs)
		}
		&.bloc-extra-small {
			max-width: $grid-extra-small+$content-gutter !important;
		}
	}

	// Résolution jusqu'à 1280px, on met le contenu par défaut à 40 px des bords (FLUID)
	@media screen and (max-width: $max-width+$border-gutter+$border-gutter) {
		margin-left: $border-gutter - ($content-gutter/2) !important;
		margin-right: $border-gutter - ($content-gutter/2) !important;
	}

	// BLOC SMALL -> Doit rester centré tant qu'il est plus petit que le contenu normal, donc : margin:auto;
	// Une fois à la taille normale, il reprend le comportement d'un bloc normal (min-width IMPORTANT)
	@media screen and (max-width: $max-width+$border-gutter+$border-gutter) and (min-width: $grid-small+$border-gutter+$border-gutter+$content-gutter) {
		&.bloc-small {
			max-width: $grid-small+$content-gutter !important;
			margin: 0 auto !important;
		}
	}

	@media screen and (max-width: $max-width+$border-gutter+$border-gutter) and (min-width: $grid-extra-small+$border-gutter+$border-gutter+$content-gutter) {
		&.bloc-extra-small {
			max-width: $grid-extra-small+$content-gutter !important;
			margin: 0 auto !important;
		}
	}

    // Breakpoint mobile, on met les marge de l'écran pour tout
    @include tablet_landscape{
        margin-left: 40px - ($content-gutter/2) !important;
        margin-right: 40px - ($content-gutter/2) !important;
    }

	// Breakpoint mobile, on met les marge de l'écran pour tout
	@include mobile{
		margin-left: 20px - ($content-gutter/2) !important;
		margin-right: 20px - ($content-gutter/2) !important;
	}
	&:after {
		content: '';
		display: block;
		clear: both;
	}
	> * {
		padding-left: $content-gutter/2 !important;
		padding-right: $content-gutter/2 !important;
	}
}

// Grille en colonnes
// ==========================================================================
.col-20, .col-25, .col-30, .col-33, .col-50, .col-75, .col-66, .col-70, .col-100 {
	float: left;
	display: block;
	box-sizing: border-box;
}

.col-20 {
	width: calc(20% - 20px);
	@include tablet_landscape {
		width: calc(25% - 20px);
	}
}

.col-25 {
	width: 25%;
}

.col-30 {
	width: 30%;
}

.col-33 {
	width: 33.33333%;
}

.col-40 {
	width: 40%;
}

.col-50 {
	width: 50%;
}

.col-60 {
	width: 60%;
}

.col-70 {
	width: 70%;
}

.col-66 {
	width: 66.66666%;
}

.col-75{
	width: 75%;
}

.col-100 {
	width: 100%;
}

@include tablet_portrait {

	.col-25, .col-75 {
		width: 50%;
	}
}

@include mobile {
	.col-20, .col-25, .col-33, .col-50, .col-66, .col-75 {
		float: none;
		clear: both;
		margin: 0 auto;
		width: 100%;
	}
}