.bloc-explorer-cibles {
    padding: 50px 13vw;

    @include tablet_portrait {
        padding: 100px 6vw 50px 7vw;
    }

    .cibles-title {
        color: white;
        font-size: 28px;
        font-weight: 500;
    }

    .cibles-cta {
        position: absolute;
        right: 200px;
        padding: 11px 17px;
        background-color: #7A97AA;
        color: white;
        border-radius: 3px;
        font-size: 13px;
    }

    .cibles-tab {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;

        @include mobile {
            justify-content: space-between;
        }

        .item {
            background-color: #33393c;
            border-radius: 5px;
            margin-right: 20px;
            margin-bottom: 20px;
            opacity: 0.8;

            & > * {
                color: $c_white;
            }

            @include mobile {
                width: 48%;
                margin-bottom: 15px;
                margin-right: 0;
            }

            a {
                padding: 30px 40px 29px 40px;
                display: flex;
                align-items: center;

                @include mobile {
                    padding: 7vw 0;
                    justify-content: center;
                    flex-direction: column;
                }

                .icon {
                    font-size: 30px;
                    color: white;
                    margin-right: 14px;

                    @include mobile {
                        font-size: 40px;
                        margin-right: 0;
                        margin-bottom: 12px;
                    }
                }

                .item-text {
                    font-size: 14px;
                    font-weight: 500;
                    color: white;
                    padding-top: 5px;

                    @include mobile {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .cibles-cta {
        right: 240px;
    }
}
@media screen and (max-width: 1300px) {
    .cibles-cta {
        right: 160px;
    }
}
@media screen and (max-width: 1200px) {

    .bloc-explorer-cibles {
        display: grid;

        .cibles-cta {
            position: relative;
            order: 3;
            left: auto;
            margin-left: auto;
            right: 20px;
        }
    }
}