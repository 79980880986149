// Define styles for individual elements
$bg-dark: #2e3336;
$bg-light: #fff;
$bg-field: #585c5e;

$bg-my-explore: #1e2529;

// Breakpoints
// ==========================================================================
// $breakpoint_mo_small: 320px;
// $breakpoint_mo: 599px;
// $breakpoint_tablet_small: 768px;
// $breakpoint_portrait: 980px;
// $breakpoint_paysage: 1024px;
// $breakpoint_desktop: 1280px;
// $breakpoint_wd: 1600px;

// z-index
// ==========================================================================
$-z-overlay: 1000000012;
$-z-header: 1000;
$-z-map: 903;
$-z-option: 902;
$-z-mobile-actions: 901;
$-z-nav: 900;

$-z-popins: $-z-overlay;
$-z-see-map: 800;
$-z-sidebar: 600;

//--------------
//------- base variables : OTHER variable THAN Background and colors not depending to theme mode
//---------------------

:root {
	--marge-content-top: #{calculateRem(45px)};
	--color-myex: #e13060;

	--header-height: #{calculateRem(74px)};
	@include min-desktop {
		--header-height: #{calculateRem(80px)};
	}

	--side-padding-base: 5vw;
	--side-padding-left: var(--side-padding-base);
	--side-padding-right: var(--side-padding-base);
	@include min-tablet_portrait {
		--side-padding-base: 7.7vw;
	}


	//field 
	--field-font-size: #{calculateRem(13px)};
	@include min-mobile {
		--field-font-size: #{calculateRem(15px)};
	}
	//

	// variaible for box
	--section-radius: #{calculateRem(5px)};
	--section-padding: #{calculateRem(15px)};
	@include min-mobile {
		--section-padding: #{calculateRem(40px)};
	}
}
