.bloc__grandest {
    position: relative;
    background: #fff url('/wp-content/themes/artge/assets/images/deco/grandest_bg.svg') left bottom no-repeat;
    max-width: 1270px;
    display: flex;
    width: 100%;
    flex-direction: row;

    &::before {
        content: "";
        position: absolute;
        right: 100%;
        width: calc(100vw - 1600px);
        background-color: white;
        height: 100%;
        visibility: visible !important;
    }
    
    
    @include tablet_landscape {
        flex-direction: column;
        background-position: left top;
    }
    &_hashtag {
        
        width: 350px;
        height: 100%;
        @include tablet_landscape {
            width:100%;

        }
        div {
            writing-mode: tb-rl;
            writing-mode:vertical-lr;
            writing-mode:sideways-lr;/* if not understood, then vertical-rl + transform:scale(-1,-1); */
            transform: rotate(180deg);
            position: relative;
            left: 180px;
            z-index: 3;
            margin-bottom: 180px;
            @include tablet_landscape {
                transform: unset;
                text-align: center;
                writing-mode: lr-tb;
                writing-mode:horizontal-tb;
                writing-mode:sideways-rl;
                font-size: 32px;
                line-height: 34px;
                margin-top: 20px;  
                left: 0;
                margin-bottom: 20px;  
                &::before {
                    display: none;
                }
            }
            margin-top: 50px;
            font: normal normal bold 96px/34px Poppins;
            color: var(--darkblue);

            &::before {
                content: '';
                position: absolute;
                top: -70px;
                left: -80px;

                width: 257px;
                height: 257px;
                z-index: -1;
                background: url('/wp-content/themes/artge/assets/images/deco/grandest.svg') 0px 0px no-repeat;

            }
        }
    }

    &_content {
        
        width: calc(100% - 350px);
        height: 100%;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 400px;
            background-color: #FFE4D8;
            width: 100%;
            height: calc(100% - 400px);
            display: none;
        }
        @include tablet_landscape {
            position: relative;
            width: 100%;
            &::before {
               display: block;
            }
        }
    }
    &_listing {
        
        
        @include tablet_landscape {
            width: 100%;
            margin-left: 15px;    
            margin-right: 15px;    
        }
    }

    .card__listing_row {
        @include tablet_landscape {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}