a {
	text-decoration: none;
	color: $c_main;

	&:hover, &:focus {
		text-decoration: none;

		body:not(.no-hover) & {
			color: $c_greydark;
		}
	}
}

// Basics
// ==========================================================================
h1, .title-h1 {
    position: relative;
    display: block;
    margin: 0 0 10px 0;
    @include ft($f_hx, $s_h1, 600, $c_white, $s_h1);
    @include tablet_landscape {
        font-size: calculateRem(56px);
        line-height: calculateRem(56px);
    }
    @include tablet_portrait {
        font-size: calculateRem(50px);
        line-height: calculateRem(50px);
    }
    @include mobile {
        font-size: calculateRem(40px);
        line-height: calculateRem(40px);
    }
    @include mobile_small {
        font-size: calculateRem(30px);
        line-height: calculateRem(30px);
    }

	&.medium-size {
		font-size: calculateRem(60px);
		line-height: calculateRem(60px);
		@include tablet_portrait {
			font-size: calculateRem(50px);
			line-height: calculateRem(50px);
		}
		@include mobile {
			font-size: calculateRem(40px);
			line-height: calculateRem(40px);
		}
		@include mobile_small {
			font-size: calculateRem(30px);
			line-height: calculateRem(30px);
		}
	}
}

h2, .title-h2 {
	position: relative;
	display: block;
	margin: 0 0 20px 0;
	@include ft($f_hx, 60px, 600, $c_txt, 60px);
	letter-spacing: 0.2px;

	@include tablet_landscape {
		font-size: calculateRem(50px);
		line-height: calculateRem(50px);
	}
	@include mobile {
		font-size: calculateRem(25px);
		line-height: calculateRem(30px);
	}
}

h3, .title-h3 {
	position: relative;
	display: block;
	margin: 0 0 10px 0;
	@include ft($f_hx, 40px, 600, $c_txt, 40px);
	letter-spacing: 0.2px;

	@include tablet_landscape {
		font-size: calculateRem(18px);
		line-height: calculateRem(30px);
	}
}

h4, .title-h4 {
	@include ft($f_hx, 28px, 600, $c_txt, 30px);
	margin-bottom: 10px;
	letter-spacing: 0.2px;
}

.title--white {
	color: #fff;
}

p {
	position: relative;
	display: block;
	margin: 0 0 20px 0;
	@include ft($f_txt, $s_p, 400, $c_txt, $s_p+13);
	@include mobile {
		font-size: calculateRem(13px);
		line-height: calculateRem(25px);
	}
}

ul {
	position: relative;
	display: block;
	list-style-type: none;

	li {
		position: relative;
		padding: 0;
		@include ft($f_txt, $s_p, 400, $c_txt, $s_p+13);
		@include mobile {
			font-size: calculateRem(13px);
			line-height: calculateRem(25px);
		}

		a {
			color: $c_sub;
		}
	}
}

ol {
	position: relative;
	display: block;
	list-style-type: decimal;

	li {
		position: relative;
		padding: 0;
		@include ft($f_txt, $s_p, 400, $c_txt, $s_p+13);
		@include mobile {
			font-size: calculateRem(13px);
			line-height: calculateRem(25px);
		}

		a {
			color: $c_sub;
		}
	}
}

blockquote {
	@include ft($f_txt, 16px, 400, $c_txt, 22px);
	padding: 10px 15px;
	margin: 10px 0 10px 50px;
	border-left: 2px solid $c_main;
	position: relative;
	@include mobile {
		padding: 10px 0 10px 25px;
		margin: 15px 0 20px 0;
	}

	p {
		margin-bottom: 0;
	}
}

// Additionals
// ==========================================================================

.big-title {
	@include ft($f_hx, 90px, 200, $c_titre, 90px);
	text-transform: uppercase;
	letter-spacing: 0;
	@include tablet_landscape {
		font-size: calculateRem(60px);
		line-height: calculateRem(60px);
	}
	@include tablet_portrait {
		font-size: calculateRem(50px);
		line-height: calculateRem(50px);
	}
	@include mobile {
		font-size: calculateRem(45px);
		line-height: calculateRem(45px);
	}
	@include mobile_small {
		font-size: calculateRem(35px);
		line-height: calculateRem(35px);
	}
}

.basic-link {
	@include ft($f_txt, 13px, 600, $c_white, 20px);
	display: block;

	&:before {
		margin-right: 5px;
	}

	&:after {
		margin-left: 5px;
	}
}

.btn {
	@include ft($f_txt, 14px, 500, $c_white, 20px);
	@extend %appearance;
	position: relative;
	text-align: center;
	background: $c_main;
	border-radius: 4px;
	display: inline-block;
	min-width: 100px;
	padding: 14px 20px 12px;
	@include addTransition();

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			color: $c_white;
			background: $c_greydark;
		}
	}

	&.btn-custom-mobile {
		@include mobile {
			background: none;
			padding: 0;
			font-size: calculateRem(11px);
			line-height: calculateRem(18px);
			&:before, &:after {
				content: '-';
				margin: 0 4px;
			}
		}
	}

	&.btn-sub {
		background: $c_sub;

		&:hover, &:focus {
			body:not(.no-hover) & {
				cursor: pointer;
				color: $c_white;
				background: $c_third;
			}
		}
	}

	&.btn-third {
		background: $c_third;

		&:hover, &:focus {
			body:not(.no-hover) & {
				cursor: pointer;
				color: $c_white;
				background: $c_main;
			}
		}
	}
}

.btn-filtrer {
	@include ft($f_hx, 15px, 500, $c_white, 20px);
	@extend %appearance;
	position: relative;
	text-align: right;
	background: $c_main;
	border-radius: 4px;
	display: inline-block;
	min-width: 180px;
	padding: 22px 20px 18px;
	flex: none;
	@include addTransition();

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			background: $c_third;
		}
	}

	&:before {
		content: "\e93f";
		@include font();
		position: relative;
		top: -1px;
		float: left;
		font-size: 20px;
		line-height: 20px;
		margin-right: 20px;
		@include addTransition();
	}
}

.btn-map {
	@include ft($f_hx, 15px, 500, $c_white, 20px);
	@extend %appearance;
	background: $c_main;
	border-radius: 4px;
	padding: 21px 40px 19px;
	@include mobile {
		padding: 11px 10px 9px;
		font-size: calculateRem(12px);
	}
	@include mobile_small {
		padding: 11px 10px 9px;
	}

	&:after {
		content: "\e949";
		@include font();
		position: relative;
		top: -1px;
		font-size: 20px;
		line-height: 20px;
		margin-left: 5px;
		@include addTransition();
		@include mobile {
			font-size: 14px;
		}
	}
}

.reservable {
	@include ft($f_txt, 13px, 700, $c_white, 20px);
	@extend %appearance;
	text-transform: uppercase;
	text-align: center;
	background: $c_sub;
	border-radius: 4px;
	display: inline-block;
	padding: 12px 20px 10px;
	float: right;
	@include addTransition();

	&:hover, &:focus {
		body:not(.no-hover) & {
			color: $c_white;
			background: $c_greydark;
		}
	}

	&:before {
		content: "\e908";
		@include font();
		position: relative;
		top: -2px;
		float: left;
		font-size: 20px;
		line-height: 20px;
		margin-right: 14px;
	}
}

.count-filters {
	@include ft($f_txt, 10px, 700, $c_white, 18px);
	width: 20px;
	height: 20px;
	background: $c_dark;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
	position: relative;
	top: -2px;

	&:empty {
		display: none;
	}
}

.filtres-actifs,
.filtres-actifs-mobile {
	@include ft($f_txt, 10px, 600, $c_white, 22px);
	width: 20px;
	height: 20px;
	border-radius: 50px;
	background: $c_greydark;
	text-align: center;
	position: absolute;
	right: -10px;
	top: 50%;
	margin-top: -10px;
	z-index: 100;
}

.surtitre {
	@include ft($f_hx, 18px, 400, $c_third, 27px);
	text-transform: uppercase;
	letter-spacing: 0.5px;
	display: block;
	margin-bottom: 20px;
	@include mobile {
		font-size: calculateRem(13px);
		line-height: calculateRem(20px);
	}
}

.location {
	@include ft($f_hx, 11px, 400, $c_main, 15px);
	position: relative;
	text-transform: uppercase;
	letter-spacing: 0.4px;
	display: block;
	padding-left: 15px;

	&:before {
		content: "\e91e";
		@include font();
		position: absolute;
		top: 1px;
		left: 0;
		font-size: 10px;
		color: $c_main;
	}
}

.cta-overlay-search {
	display: inline-block;
	width: 150px;
	padding: 15px 20px 13px;
	overflow: hidden;
	background: $c_main;
	border-radius: 4px;
	vertical-align: middle;

	@include ft($f_hx, 14px, 500, $c_white, 20px);
	@extend %appearance;

	@include addTransition();

	@include desktop1280 {
		font-size: calculateRem(12px);
	}

	@include mobile {
		margin: auto;
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			background: $c_third;
			border-color: $c_third;
		}
	}

	&:before {
		content: "\e92d";
		@include font();
		position: relative;
		font-size: 14px;
		line-height: 20px;
		top: -2px;
		float: left;
		margin-right: 15px;
		font-weight: 700;

		@include mobile {
			content: "\e9d1";
			font-weight: 300;
		}
	}


	@include desktop1280 {
		padding: 12px 20px;
	}

	body.no-search & {
		display: none;
	}
}

.wrapper-cat {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	padding-left: 15px;
	hyphens: auto;

	&:before {
		content: "\e907";
		@include font();
		position: absolute;
		top: -1px;
		left: 0;
		float: left;
		font-size: 10px;
		line-height: 15px;
		color: $c_txt;
	}

	span, a, button {
		@include ft($f_hx, 12px, 300, $c_white, 15px);
		text-transform: uppercase;
		vertical-align: top;
		letter-spacing: 1.5px;

		&:last-child {
			&:after {
				content: none !important;
			}
		}
	}

	&:not(.no-virgule) {
		span, a, button {
			&:after {
				content: ',';
				margin-right: 4px;
			}
		}
	}

	a, button {
		@extend %appearance;
		background: none;
		margin: 0;
		padding: 0;
		@include addTransition();

		&:hover, &:focus {
			body:not(.no-hover) & {
				cursor: pointer;
				color: $c_sub;
			}
		}
	}
}

.cta-favoris {
	@extend %appearance;
	background: none;
	margin-bottom: 20px;
	font-size: 20px;
	color: $c_white;
	@include addTransition();

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			color: $c_sub;
		}
	}

	span {
		display: block;
	}


	// Si c'est checked
	&.cta-favoris-checked {
		span {
			&:before {
				content: "\e959";
				color: $c_third;
			}
		}
	}
}

.wrapper-favoris .cta-add-favoris {
	// Si c'est checked
	&.cta-favoris-checked {
		&:before {
			content: "\e959";
			color: $c_third;
		}
	}
}


.classement {
	display: inline-block;
	vertical-align: top;
	margin-left: 5px;
	margin-top: -2px;

	span {
		font-size: 12px;
		line-height: 22px;
		margin: 0 1px;
		color: $c_third;
		@include mobile {
			font-size: 12px;
		}

		&.icon-ico-fleur {
			& + .icon-ico-star, & + .icon-ico-toque, & + .icon-ico-epis, & + .icon-ico-cles {
				margin-left: 5px;
			}
		}

		&.icon-ico-star {
			& + .icon-ico-fleur, & + .icon-ico-toque, & + .icon-ico-epis, & + .icon-ico-cles {
				margin-left: 5px;
			}
		}

		&.icon-ico-toque {
			& + .icon-ico-fleur, & + .icon-ico-star, & + .icon-ico-epis, & + .icon-ico-cles {
				margin-left: 5px;
			}
		}

		&.icon-ico-epis {
			& + .icon-ico-fleur, & + .icon-ico-star, & + .icon-ico-toque, & + .icon-ico-cles {
				margin-left: 5px;
			}
		}

		&.icon-ico-cles {
			& + .icon-ico-fleur, & + .icon-ico-star, & + .icon-ico-toque, & + .icon-ico-epis {
				margin-left: 5px;
			}
		}
	}
}

.reset {
	@include ft($f_hx, 14px, 400, $c_light_txt, 20px);
	@extend %appearance;
	position: relative;
	text-align: left;
	background: none;
	padding: 0;
	@include addTransition();
	@include mobile {
		font-size: calculateRem(10px);
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			color: $c_greydark;
		}
	}

	&:before {
		content: "\e92b";
		@include font();
		position: relative;
		top: -2px;
		font-size: 15px;
		line-height: 20px;
		float: left;
		margin-right: 10px;
	}
}


.remove {
	@include ft($f_hx, 11px, 500, $c_greydark, 20px);
	@extend %appearance;
	position: relative;
	text-align: left;
	background: none;
	padding: 0;
	@include addTransition();
	@include mobile {
		font-size: calculateRem(10px);
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			color: $c_third;
		}
	}

	&:before {
		content: "\e92a";
		@include font();
		position: relative;
		top: -1px;
		font-size: 16px;
		line-height: 20px;
		float: left;
		margin-right: 10px;
	}
}


.playlist-detail-btn-telecharger {
	@include ft($f_hx, 14px, 500, $c_white, 20px);
	@extend %appearance;
	position: absolute;
	top: 50%;
	margin-top: -23px;
	left: calc(100% - 500px);
	display: inline-block;
	background: $c_sub;
	border-radius: 4px;
	padding: 14px 15px 12px 20px;
	min-width: 150px;
	@include addTransition();
	@include desktop1280 {
		left: calc(100% - 450px);
	}
	@include tablet_landscape {
		font-size: calculateRem(16px);
		min-width: 100px;
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			background: $c_third;
		}
	}

	&:before {
		content: "\e912";
		@include font();
		display: inline-block;
		vertical-align: middle;
		font-size: 15px;
		color: $c_white;
		margin-right: 15px;
		position: relative;
		top: -1px;
	}
}

.cta-reserver {
	@include ft($f_hx, 20px, 500, $c_white, 20px);
	@extend %appearance;
	position: relative;
	background: $c_sub;
	border-radius: 4px;
	padding: 21px 20px 19px;
	min-width: 150px;
	text-align: right;
	flex: 1;
	width: 100%;
	margin-left: 20px;
	@include addTransition();
	@include tablet_landscape {
		font-size: calculateRem(16px);
		min-width: 100px;
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			background: $c_greydark;
			color: #fff !important;
		}
	}

	&:before {
		content: "\e908";
		@include font();
		position: absolute;
		left: 20px;
		top: calc(50% - 1px);
		font-size: 24px;
		color: $c_white;
		margin-top: -12px;
	}
}

.cta-offre-indispo {
	@extend %appearance;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $c_main;
	border-radius: 4px;
	padding: 10px 15px;
	text-align: center;

	@include addTransition();
	@include tablet_landscape {
		font-size: calculateRem(16px);
		min-width: 100px;
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			background: $c_greydark;
			color: #fff !important;
		}
	}

	&__large {
		@include ft($f_hx, 16px, 500, $c_white, 24px);
	}

	&__small {
		@include ft($f_hx, 13px, 400, $c_white);
	}
}

.barre {
	text-decoration: line-through;
	color: $c_light_txt;
	font-weight: 500;
	display: inline-block;
	margin-left: 4px;
}


.more-filters {
	@include ft($f_hx, 13px, 400, $c_light_txt, 20px);
	@extend %appearance;
	position: relative;
	margin: 0;
	background: none;
	flex: none;
	padding: 31px 20px 29px 50px;
	min-width: 205px;
	@include desktop1280 {
		padding: 21px 20px 19px 20px;
		min-width: 140px;
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			color: $c_third;
		}
	}

	&:before {
		content: "\e94c";
		@include font();
		position: relative;
		top: -1px;
		font-size: 18px;
		margin-right: 10px;
	}

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 1px;
		height: 60px;
		background: rgba($c_border, 0.2);
		margin-top: -30px;
	}

	.filtres-actifs,
	.filtres-actifs-mobile {
		background: $c_main;
	}
}


.btn-logout {
	@include ft($f_hx, 11px, 400, $c_light_txt, 20px);
	@extend %appearance;
	display: inline-block;
	float: right;
	background: none;
	position: relative;
	padding: 15px 0;
	margin-left: auto;
	@include addTransition();
	@include mobile {
		padding: 10px 0;
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			color: $c_white;
		}
	}

	&:after {
		content: "\e960";
		@include font();
		position: relative;
		top: -1px;
		float: right;
		margin-left: 10px;
		font-size: 16px;
		line-height: 20px;
	}
}


hr.thin {
	border: none;
	background: rgba(112, 112, 112, 0.2);
	width: 100%;
	height: 1px;
}