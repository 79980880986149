.myex-checkbox-button {
	label {
		@extend .style-field;
		@extend .icon-ico-close;
		cursor: pointer;
		display: inline-flex;
		gap: calculateRem(8px);
		align-items: center;
		position: relative;
        font-size: calculateRem(12px);
        font-weight: 600;
		padding: calculateRem(15px) calculateRem(40px) calculateRem(15px) calculateRem(30px);
        line-height: 1.5;
		justify-content: center;
		
        //category icon
		i {
			font-size: calculateRem(20px);
		}


        // close icon
		&:before {
			@include font();
			color: #fff;
			font-size: calculateRem(10px);
			position: absolute;
			right: calculateRem(15px);
			opacity: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

    
	input {
		display: none;

		&:checked {
			~ label {
				background: var(--color-primary);
				color: #fff;

				&:before {
					opacity: 1;
				}
			}
		}
	}
}
