.search-bar {
	padding: 10px 0;
	background-color: $c_white;

	@include tablet_landscape {
		background-color: #F3F4F5;
		padding: 0;
		border-bottom: 1px solid #ABB3B9;
	}

	&__overlay {
		background-color: rgba($c_greydark, 0.5);
		position: absolute;
		width: 100%;
		height: calc(100vh - 80px);
		z-index: 10000000;
		animation: fadeIn 500ms;


		@include tablet_landscape {
			top: 0;
			height: 100vh;
		}
	}
	@keyframes fadeIn {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	&--wrapper {
		max-width: 900px;
		margin: 0 auto;
		color: $c-txt;
	}

	&__content {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 30px;

		@include tablet_landscape {
			padding-left: 15px;
		}
	}

	&__input {
		width: 100%;
		@include ft($f_txt, 18px, 500, $c_main);
		border: 0;
		padding: 5px 20px 0;

		&::placeholder {
			opacity: 0.5;
			font-weight: 400;
		}

		@include tablet_landscape {
			font-size: 13px;
			background-color: transparent;
			height: 55px;
			font-weight: 600;
			padding-left: 10px;
		}
	}

	&__search-icon {
		font-size: calculateRem(19px);
	}

	&__btn {
		box-sizing: content-box;
		&[disabled] {
			opacity: .4;
		}

		@include tablet_landscape {
			display: none;
		}
	}

	&__empty {
		margin-right: 30px;
		cursor: pointer;
		font-size: calculateRem(24px);
		color: #BABABA;
		transition: color $t_duration;

		&:hover {
			body:not(.no-hover) & {
				color: $c_third;
			}
		}
	}

	&__mobile-close-btn {
		display: none;

		@include tablet_landscape {
			@include reset-button;
			font-size: 11px;
			padding-right: 16px;
			text-decoration: underline;
			font-weight: 500;
			display: block;
			line-height: 60px;
			color: $c_txt;
		}
	}

}