//inside single collection header
.myex-author {
	display: flex;
	flex-direction: row;
	gap: calculateRem(10px);
	align-items: center;
	font-size: calculateRem(13px);
	color: currentColor;
	transition: all 500ms linear;

	&:hover {
		opacity: 0.8;
		color: currentColor !important; //overwrite
	}

	&__avatar {
		width: calculateRem(24px);
		height: calculateRem(24px);
		border-radius: 50%;
		overflow: hidden;
		flex-shrink: 0;
		background: var(--bg-card-img);

		@include min-mobile {
			width: calculateRem(30px);
			height: calculateRem(30px);
		}

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&__content {
		font-weight: normal;
		font-size: calculateRem(10px);
		line-height: 1;
		@include min-mobile {
			font-size: calculateRem(13px);
		}
	}

	&__name {
		margin-right: calculateRem(5px);
		display: inline;
		font-weight: 600;

		@include min-mobile {
			margin-right: calculateRem(20px);
		}
	}

	&__super {
		vertical-align: middle;
		overflow: hidden;
		padding: 0;
		margin: 0;
		display: inline-block;

		span {
			display: none;
		}

		@include min-mobile {
			width: auto;
			height: auto;

			span {
				display: inline-block;
			}
		}
	}
}
