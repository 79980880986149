.myex-home {

    .cta-desktop-container {
        a:hover {
            background-color: $c_third;
        }
        @include mobile {
            display: none;
        }
    }

    .cta-mobile-container {
        margin-top: 15px;

        button {
            width: 100%;

            &:hover {
                background-color: $c_third;
            }
        }

        @include min-mobile {
            display: none;
        }
    }

    &.loading {
        display: none;
    }

    #myex-section__suggestion {
        padding-bottom: 40px;
    }
}