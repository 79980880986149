.overlay-notifications {
	transform: translateX(0);
	display: none !important;
	width: 89vw;
	pointer-events: none;
	max-width: calculateRem(405px);
	top: 0;

	&.open {
		z-index: 10000000000;
		display: block !important;
		pointer-events: all;
	}

	@include min-mobile {
		width: 100vw;
		top: var(--header-height);
		right: var(--side-padding-base);
		max-height: calculateRem(524px);
	}

	.top {
		align-items: center;
		background-color: #f3f4f5;
		padding: calculateRem(17px) calculateRem(15px);

		@include min-mobile {
			display: none;
		}

		.notifications {
			.icon {
				font-size: calculateRem(23px);
			}
		}

		.close-overlay {
			padding: 0;
			color: currentColor;
			font-weight: 600;
			cursor: pointer;
			margin-left: auto;
			font-size: calculateRem(11px);
			text-transform: none;
			top: 0;
			display: flex;
			align-items: center;
			gap: calculateRem(5px);

			&:hover{
				color: $c_third;
			}

			@extend .icon-ico-chevron-right;

			span {
				text-decoration: underline;
			}

			&:before {
				order: 2;
				@include font();
			}

			&:after {
				display: none;
			}
		}
	}

	&-top {
		position: fixed;
		z-index: 100;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding: 30px;
		padding-bottom: 0;
		background-color: $c-white;

		.close-overlay {
			padding: 0;
			margin-bottom: 10px;

			@include mobile {
				display: none;
			}
		}

		&__read-all {
			display: flex;
			justify-content: space-between;
			background-color: white;
			width: 100%;
			align-items: baseline;
			color: $c_third;

			span {
				cursor: pointer;
			}

			.icon {
				position: relative;
				top: 3px;
				margin-right: 5px;
			}
		}
	}

	.content {
		> .close-overlay {
			position: absolute;
			right: calculateRem(30px);
			top: calculateRem(30px);
			padding: 0;
			display: none;
			cursor: pointer;

			@include min-mobile {
				display: block;
			}
		}
	}

	&__title {
		color: #a0a5a8;
		margin-bottom: calculateRem(14px);
		margin-top: 0;
		font-size: calculateRem(10px);
		line-height: 1.5;
	}

	//overlay notifications liste
	.overlay-notifications__notifs {
		display: flex;
		gap: calculateRem(20px);
		flex-direction: column;
	}

	.bottom {
		padding: 0 calculateRem(15px) calculateRem(20px) calculateRem(15px);

		@include min-mobile {
			padding: 0 calculateRem(30px) calculateRem(30px) calculateRem(30px);
		}

		* {
			margin-right: 0;
		}

		&.hidden {
			display: none;
		}
	}

	.scroller {
		padding: calculateRem(30px) calculateRem(15px);
		position: relative;
		max-height: 100vh;

		// &:after{
		// 	display: none;
		// }

		@include mobile {
			padding-top: 80px;
		}

		@include min-mobile {
			padding-top: 110px;
		}
	}

	.card-article {
		margin-bottom: 25px;
		cursor: pointer;

		&__content {
			flex: 40%;

			@include tablet_portrait {
				flex: 24%;
			}
		}

		&__title {
			font-weight: 600;
			color: currentColor;
			margin-bottom: 3px;

			@include mobile {
				font-size: 13px;
				line-height: 1.3;
			}
		}

		.caption-link {
			color: currentColor;
			font-size: 12px;
			line-height: 18px;

			@include mobile {
				font-size: 10px;
				line-height: 16px;
			}
		}
	}
}

.overlay-notifications__highlight {
	position: relative;
	display: block;
	border-radius: 10px;
	margin-bottom: 25px;
	padding: 43px 30px;
	text-align: center;
	background-image: url("../images/deco/highlighted-notification-background.png");
	background-size: cover;
	color: currentColor;
	cursor: pointer;

	@include min_mobile {
		padding: 68px 30px;
	}

	&::before {
		content: "";
		position: absolute;
		left: 25px;
		top: 25px;
		background: var(--current-bg);
		width: calc(100% - 50px);
		height: calc(100% - 50px);
	}

	&__content {
		position: relative;
		z-index: 2;
	}

	&.is-new-notif {
		@include style-dot-notif-card();
	}

	&__surtitre {
		color: currentColor;
		font-size: calculateRem(20px);
		font-weight: 300;
		margin-bottom: calculateRem(5px);
		display: block;
	}

	&__title {
		font-size: calculateRem(31px);
		font-weight: 600;
		text-transform: uppercase;
		color: currentColor;
		display: block;
	}
}
