.bloc-mosaique {

    &.alignleft {
        .wrapper-mosaique {
            .item {
                .credits{
                    text-align: right;
                }
                &:nth-child(2) {
                    margin-right: 80px;
                    width: 61%;
                    @include tablet_landscape {
                        margin-right: 40px;
                    }
                    @include tablet_portrait {
                        width: 62%;
                        margin-right: 0;
                    }
                    @include mobile{
                        width: calc(50% - 10px);
                        margin-right: 10px;
                        margin-left: 0;
                    }
                    figure{
                        @include mobile{
                            @include aspect-ratio(1,1);
                        }
                    }
                }

                &:first-child, &:nth-child(4) {
                    width: calc(100% - 61% - 80px - 30px);
                    @include tablet_landscape {
                        width: calc(100% - 61% - 40px - 30px);
                    }
                    @include tablet_portrait {
                        width: calc(100% - 62% - 30px);
                    }
                    @include mobile {
                        width: 100%;
                    }

                    figure {
                        @include aspect-ratio(1, 1);
                        overflow: visible;
                        @include mobile{
                            @include aspect-ratio(880, 543);
                        }
                    }
                }

                &:first-child {
                    margin-right: 30px;
                    margin-top: auto;
                    @include mobile {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                &:nth-child(3) {
                    width: 61%;
                    margin-left: 80px;
                    @include tablet_landscape{
                        margin-left: 40px;
                    }
                    @include tablet_portrait {
                        width: 62%;
                        margin-left: 0;
                    }
                    @include mobile{
                        width: calc(50% - 10px);
                        margin-left: 10px;
                    }
                    figure{
                        @include mobile{
                            @include aspect-ratio(1,1);
                        }
                    }
                }

                &:nth-child(4) {
                    margin-left: 30px;
                    margin-right: 0;
                    @include mobile {
                        margin-left: 0;
                        margin-right: 0;
                    }

                }
            }
        }
    }

    &.alignright {
        .wrapper-mosaique {
            .item {
                &:first-child {
                    margin-left: 80px;
                    width: 61%;
                    @include tablet_landscape {
                        margin-left: 40px;
                    }
                    @include tablet_portrait {
                        width: 62%;
                        margin-left: 0;
                    }
                    @include mobile{
                        width: 100%;
                        margin-left: 0;
                    }
                }

                &:nth-child(2), &:nth-child(3) {
                    width: calc(100% - 61% - 80px - 30px);
                    @include tablet_landscape {
                        width: calc(100% - 61% - 40px - 30px);
                    }
                    @include tablet_portrait {
                        width: calc(100% - 62% - 30px);
                    }
                    @include mobile {
                        width:calc(50% - 10px);
                    }

                    figure {
                        @include aspect-ratio(1, 1);
                        overflow: visible;
                    }
                }

                &:nth-child(2) {
                    margin-left: 30px;
                    margin-top: auto;
                    @include mobile {
                        margin-top: 10px;
                        margin-left: 0;
                        margin-right: 10px;
                    }
                }

                &:nth-child(3) {
                    margin-left: 0;
                    margin-right: 30px;
                    @include mobile {
                        margin-right: 0;
                        margin-left: 10px;
                    }
                }

                &:nth-child(4) {
                    width: 61%;
                    @include tablet_portrait {
                        width: 62%;
                    }
                    @include mobile{
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .big-title {
        padding: 0 80px;
        @include tablet_landscape {
            padding: 0 40px;
        }
        @include mobile {
            padding: 0;
            margin-bottom: 20px;
        }
    }

    .wrapper-mosaique {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        @include clearfix();

        .item {
            position: relative;
            margin: 15px 0;
            @include mobile{
                margin: 10px 0;
            }

            &:first-child, &:nth-child(2) {
                margin-top: 0;

                .credits {
                    bottom: calc(100% + 5px);
                }
            }
            &:nth-child(2){
                @include mobile{
                    margin-top: 10px;
                }
            }

            &:nth-child(3) {
                clear: left;
            }

            &:nth-child(3), &:nth-child(4) {
                .credits{
                    top: 100%;
                    text-align: right;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }

            a{
                position: relative;
                display: block;
                &:hover, &:focus{
                    body:not(.no-hover) &{
                        figure{
                            img{
                                opacity: 0.6;
                            }
                        }
                        .cta-gallery{
                            background: $c_greydark;
                            border-color: $c_greydark;
                        }
                    }
                }
            }

            figure {
                @include aspect-ratio(880, 543);
                overflow: visible;
                img {
                    @extend %center;
                    @include addTransition();
                }
                figcaption{
                    @include mobile{
                        display: none;
                    }
                }
            }
            .credits{
                @include mobile{
                    display: none;
                }
            }

            .cta-gallery{
                top: auto;
                left: auto;
                margin: 0;
                bottom: 40px;
                right: 40px;
                transform: none;
                background: $c_third;
                border-color: $c_third;
                @include tablet_landscape{
                    right: 20px;
                    bottom: 20px;
                }
            }
        }
    }
}