.bloc-plus-dinformations {

	.bloc {
		@include mobile {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.item-contact {
		position: relative;
		margin-top: 30px;
		padding: 40px 45px;
		border: 1px solid rgba($c_border, 0.5);
		border-radius: 4px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;

		@include tablet_portrait {
			flex-wrap: wrap;
			padding: 20px;
			margin-top: 55px;
		}
		@include mobile {
			margin-top: 0;
		}

		.contact-left {
			display: flex;
			max-width: 60%;

            @include desktop {
                flex-basis: 55%;
            }

            @include tablet_portrait {
                flex-basis: 100%;
                max-width: 100%;
            }

			figure, .no-photo {
				position: static !important;
				box-sizing: border-box;
				width: 120px !important;
				height: 120px !important;
				@extend %aligncenter;
				border: 1px solid rgba(#ADB3B8, 0.5);
				margin: 0 15px 0 0;
				background: none;
				flex-shrink: 0;

				img {
					width: auto;
					height: 100%;
					object-fit: contain;
				}

				& ~ .content-txt {
					width: calc(100% - 75px);
				}
			}

			figcaption {
				display: none;
			}

			.credits {
				display: none;
			}

			.heb_particulier_more {
				margin-bottom: 30px;
				color: $c_txt;
				font-size: 10px;
			}

			.heb_particulier_mention {
				display: inline-flex;
				padding: 9px 15px 5px;
				border-radius: 4px;
				background-color: $c_lightgray;
				text-transform: uppercase;
				font-weight: bold;
				text-align: center;
			}

			.heb_particulier_number {
				margin-left: 15px;
				font-style: italic;
			}

			@include desktop {
				flex-basis: 55%;
			}
			@include tablet_portrait {
				flex-basis: 100%;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
			}

			.avis {
				position: relative;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-top: 30px;
				@include tablet_portrait {
					justify-content: center;
				}
				@include mobile {

				}

				.title {
					@include ft($f_txt, 18px, 400, $c_dark, 24px);
					margin-right: 7px;
					letter-spacing: 0.20px;
				}

				figure {
					background: transparent;
					margin-right: 7px;
					margin-top: -3px;
				}

				.rating {
					@include ft($f_txt, 15px, 600, $c_dark, 15px);

					.nombre-avis {
						@include ft($f_txt, 15px, 400, $c_main, 15px);
					}

					@include mobile {
						margin-top: 5px;
					}
				}

				&:after {
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					flex: 1;
					border-bottom: 1px dashed rgba(173, 179, 184, 0.5);
					margin-left: 20px;
					@include tablet_landscape {
						flex: auto;
						margin-top: 5px;
						margin-left: 0;
					}
					@include mobile {
						margin-top: 10px;
					}
				}
			}

			.avis-content {
				margin-top: 30px;

				& + .cta-expand {
					margin-top: 14px;
					text-align: right;
					@include mobile {
						margin-top: 0;
					}
				}

				.avis-author {
					@include ft($f_txt, 13px, 600, $c_main, 24px);
					letter-spacing: 0.20px;

					.author-name {
						color: $c_txt;
					}

					.author-date {
						font-weight: 400;
					}

					& + .avis-wrapper {
						margin-top: 10px;
						@include mobile {
							margin-top: 20px;
						}
					}

				}

				.avis-wrapper {
					display: flex;
					align-items: center;
					border-bottom: 1px dashed rgba($c_border, 0.5);
					padding-bottom: 20px;
					@include tablet_portrait {
						border-bottom: none;
					}
					@include mobile {
						flex-direction: column;
						justify-content: center;
					}

					.col-left {
						@include ft($f_txt, 24px, 300, $c_txt, 24px);
						letter-spacing: 0.20px;
						@include mobile {
							margin-bottom: 10px;
						}

						& + .col-right {
							margin-left: 14px;
							@include mobile {
								margin-top: 10px;
							}
						}
					}

					.col-right {
						@include ft($f_txt, 12px, 400, $c_txt, 23px);
					}
				}
			}
		}

		.txt-left {
			position: relative;
			flex: auto;
			@include clearfix();
			@include tablet_portrait {
				width: 100%;
			}
			@include mobile {
				margin-top: 20px;
				
			}

			> * {
				position: relative;
				float: left;
				@include tablet_portrait {
					float: none;
				}
			}

			.credits {
				display: none;
			}

			.bloc-texte {
				.title {
					@include ft('', 24px, 400, $c_greydark, 24px);
					display: block;
					margin: 0 0 14px 0;
				}

				p {
					@include ft('', 12px, 400, $c_txt, 23px);

					@include mobile {
						color: rgba($c_greydark, 0.5);
					}
				}
			}

			.cta-expand {
				position: absolute;
				bottom: -10px;
				right: 0;
				z-index: 10;
				@include tablet_portrait {
					position: relative;
					bottom: 0;
				}
			}
		}

		.contact-right {
			padding-left: 40px;
			margin-left: 30px;
			border-left: 1px dashed rgba($c_border, 0.5);
			flex: none;
			flex: 1;
			width: 35%;
			

			@include tablet_landscape {
				margin-left: 40px;
			}

			@include tablet_portrait {
				width: 100%;
				flex-basis: 100%;
				max-width: none;
				margin-left: 0;
				padding-top: 30px;
				padding-left: 0;
				margin-top: 10px;
				border-left: none;
				border-top: 1px dashed rgba($c_border, 0.5);
			}

			@include mobile {
				text-align: center;
			}

			> * {
				&:last-child {
					margin-bottom: 0;
				}
			}

			&__top {
				display: flex;

				@include mobile {
					display: block;
				}

				&__text {
					max-width: 100%;
				}	

				figure, .no-photo {
					position: static !important;
					box-sizing: border-box;
					width: 105px !important;
					height: 105px !important;
					@extend %aligncenter;
					border: 1px solid #D3D8E7;
					margin: 0 15px 0 0;
					background: none;
					flex-shrink: 0;

					img {
						width: auto;
					}

					& ~ .content-txt {
						width: calc(100% - 75px);
					}
				}

				figure.contains img {
					object-fit: contain;
				}

				figcaption {
					display: none;
				}

				.credits {
					display: none;
				}
			}

			address,
			a,
			.coords-presta {
				@include ft($f_txt, 15px, 400, $c_surtitre, 18px);
				display: block;
				margin-bottom: 15px;
			}

			a {
				&:hover, &:focus {
					body:not(.no-hover) & {
						color: $c_greydark;
					}
				}
			}

			.link {
				@include ft($f_txt, 15px, 600, $c_main, 18px);
				font-style: italic;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				@include ellipsis(1, 18px);
				display: block;

				&:hover, &:focus {
					body:not(.no-hover) & {
						color: $c_third;
					}
				}
			}

			.container_sncf_connect {
				border-top: none;
				border-bottom: none;
			}

			.contact-presta-button {
				font-family: "Poppins", sans-serif;
				padding: 10px 15px 10px 15px;
				font-size: .875rem;
				font-weight: 500;
				color: #fff;
				line-height: 1.25rem;
				background: #7A97AA;
				border-radius: 4px;
				vertical-align: middle;
				transition: all .2s ease;
				border: none;
				cursor: pointer;

				&:hover {
					background: #DEA99E;
				}
			}

		}

		.labels-certifications {
			width: 100%;
			padding-top: 30px;
			margin-top: 30px;
			border-top: dotted 1px #ADB3B8;

			.title {
				display: block;
				@include ft($f_txt, 18px, 600, $c_sub, 24px);
				letter-spacing: 0.20px;
			}

			ul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				max-width: 100%;
				@include tablet_portrait {
					max-width: 100%;
				}
				@include mobile {
					margin-top: 20px;
				}

				li {
					position: relative;
					@include ft($f_txt, 14px, 600, $c_txt, 28px);
					display: flex;
					align-items: center;
					border: 1px solid $c_sub;
					border-radius: 4px;
					padding: 3px 20px 3px 3px;
					margin-right: 10px;
					margin-top: 10px;

					@include mobile {
						font-size: calculateRem(12px);
						line-height: calculateRem(18px);
						text-align: left;
						padding-right: 10px;
					}

					&:last-of-type {
						margin-right: 0;
					}

					[class^="icon-"], [class*=" icon-"] {
						flex-shrink: 0;
						color: $c_white;
						font-size: calculateRem(13px);
						width: 34px;
						height: 34px;
						background-color: $c_sub;
						border-radius: 4px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 20px;
						@include mobile {
							width: 20px;
							height: 20px;
							font-size: calculateRem(10px);
							margin-right: 10px;
						}
					}

					.label-holder {
						position: absolute;
						top: -142px;
						left: 50%;
						transform: translateX(-50%) scale(0.9);
						display: flex;
						align-items: center;
						justify-content: center;
						width: 128px;
						height: 128px;
						background-color: $c_white;
						box-shadow: 0 16px 25px rgba($c_dark, 0.16);
						z-index: 1;
						opacity: 0;
						pointer-events: none;
						@include addTransition();

						figure {
							background: transparent;
						}

						&:after {
							content: " ";
							position: absolute;
							top: 100%; /* At the top of the tooltip */
							left: 50%;
							margin-left: -8px;
							border-width: 8px;
							border-style: solid;
							border-color: $c_white transparent transparent transparent;
						}

					}

					&:hover, &:focus {
						body:not(.no-hover) & {
							.label-holder {
								transform: translateX(-50%) scale(1);
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}