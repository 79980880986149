// @import '../../base/mixins';

@mixin linear-gradient($side, $color) {
	/* Chrome10-25,Safari5.1-6 */
	background: -webkit-linear-gradient(
		$side,
		rgba($color, 0) 0%,
		rgba($color, 1) 100%
	);

	/* FF3.6-15 */
	background: -moz-linear-gradient(
		$side,
		rgba($color, 0) 0%,
		rgba($color, 1) 100%
	);

	background: linear-gradient($side, rgba($color, 0) 0%, rgba($color, 1) 100%);

	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	background: linear-gradient(
		to side,
		rgba($color, 0) 0%,
		rgba($color, 1) 100%
	);
}

@mixin gradient-overflow($size, $color: "") {
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 1;
		width: $size;
	}

	&:before {
		left: 0;
		background: linear-gradient(to left, transparent, $color 75%);
	}

	&:after {
		right: 0;
		background: linear-gradient(to right, transparent, $color 75%);
	}
}

// RATIO IMAGE
// ==========================================================================
@mixin square-img-ratio {
	&:after {
		content: "";
		padding-bottom: 100%;
		display: block;
	}

	img {
		position: absolute;
	}
}

@mixin card-img-hover() {
	img {
		transition: all 700ms ease-in-out;
		backface-visibility: hidden;
		transform: translateZ(0);
	}

	&:hover {
		img {
			opacity: 0.9;
			transform: scale(1.1);
		}
	}
}

// Mixin Aspect-ratio pour les images
// ==========================================================================
@mixin aspect-ratio($width, $height, $add: 0px) {
	position: relative;
	overflow: hidden;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-bottom: $add;
		padding-top: ($height / $width) * 100%;
	}

	> img {
		//added
		position: absolute;
		object-fit: cover;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
	}
}


