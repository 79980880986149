.wrapper-bloc-video{

    @include mobile {
        margin: 0 !important;
    }

    &.hide-wrapper-video{
        .bloc-texte{
            opacity: 0;
            @media screen and (max-width: 800px){
                opacity: 1;
            }
        }
    }
    .bloc-texte{
        position: absolute;
        left: 80px;
        bottom: 130px;
        z-index: 50;
        max-width: 560px;
        opacity: 1;
        transition: all 0.7s ease-in-out;
        @include tablet_landscape{
            left: 60px;
            bottom: 110px;
        }
        @media screen and (max-width: 800px){
            position: relative;
            left: 0;
            bottom:0;
            margin-bottom: 20px;
        }
        @include mobile{
            margin-bottom: 10px;
        }
        h3{
            color: $c_white;
            @media screen and (max-width: 800px){
                color: $c_titre;
            }

            @include mobile {
                color: #172540;
            }
        }
    }
}


.bloc-video {
    position: relative;
    width: 100%;
    @include mobile{
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
    }
    &:hover, &:focus {
        body:not(.no-hover) & {
            cursor: pointer;
            .mask-video{
                &:after{
                    color: $c_third;
                }
            }
        }
    }
    .mask-video {
        height: 100%;
        z-index: 10;
        opacity: 1;
        order: 2;
        transition: all 0.7s ease-in-out;
        @extend %center;

        &:after{
            content: "\e94b";
            @include font();
            font-size: 160px;
            color: $c_white;
            z-index: 50;
            position: absolute;
            top: 50%;
            left: 80px;
            margin-top: -80px;
            @include addTransition();
            @include desktop1280{
                font-size: 120px;
                margin-top: -60px;
            }
            @include tablet_landscape{
                font-size: 100px;
                margin-top: -50px;
                left: 60px;
            }
            @include mobile{
                font-size: 60px;
                margin-top: -30px;
                left: 80px;
            }
        }

        & ~ .embed-container {
            opacity: 0;
        }

        &.hide-mask {
            figure, .credits {
                opacity: 0;
            }
            & + .embed-container {
                opacity: 1;
                z-index: 100;
                pointer-events: auto;
            }
        }

        figure {
            height: 100%;
            opacity: 1;
            transition: all 0.7s ease-in-out;
            &:before{
                content: '';
                @extend .gradient-left;
                z-index: 10;
                right: auto;
                width: 75%;
                opacity: 0.85;
            }
            img{
                height: 100%;
            }
            figcaption{
                display: none;
            }
        }
        .credits{
            text-align: right;
            @include mobile{
                margin-right: 20px;
            }
        }
    }

    .embed-container {
        @include aspect-ratio(1280, 790);
        height: 100%;
        min-height: 200px;
        transition: all 0.7s ease-in-out;
        iframe, video {
            @extend %center;
        }
    }

}