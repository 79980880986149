.footer-newsletter {
	margin-top: 20px;
	margin-bottom: 60px;

	@include mobile {
		margin-top: 40px;
		margin-bottom: 0;
	}

	.mag-newsletter-block {
		display: flex;
		align-items: flex-end;
		cursor: default;
		width: 100%;

		@include desktop1200 {
			flex-direction: column;
		}
	}

	.mag-block--column {
		width: 62%;
		flex-shrink: 0;

		@include desktop1200 {
			width: 100%;
			margin-bottom: 30px;
		}
	}

	.mag-block {

		&__title {
			@include ft($f_txt, 40px, 600, $c_txt, 40px);
			margin-bottom: 35px;
		}

		&__articles {
			width: 100%;
			display: flex;
			align-items: stretch;

			padding-right: 20px;

			@include tablet_landscape {
				flex-direction: column;
				border-right: 0;
				padding-right: 0;
			}
		}

		&__featured-article {
			flex: 1;
			margin-right: 9px;

			.card-article__img {
				width: 100%;
			}

			@include tablet_landscape {
				margin-right: 0;
			}

			.card-article__content {
				width: 100%;
				padding-top: 4px;

				@include tablet_landscape {
					margin-bottom: 12px;
				}
			}
		}

		&__articles-list {
			display: flex;
			flex-direction: column;
			flex: 1;
			margin-left: 9px;
			@include tablet_landscape {
				margin-left: 0;
			}
		}

		&__btn {
			display: block;
			background-color: $c_third;
			width: 100%;
			margin-top: auto;
			box-sizing: border-box;
		}
	}

	.newsletter-block--column {
		position: relative;
		width: 100%;
		padding-left: 40px;

		@include desktop1200 {
			padding-left: 0;
		}
	}

	.newsletter-block {

		position: relative;
		width: 100%;
		box-shadow: 0px 15px 25px #00000029;
		border: 2px solid #D8D8D8;
		border-radius: 10px;
		padding: 40px 20px 20px;
		display: flex;

		flex-direction: column;
		justify-content: space-between;
		background: url('/wp-content/themes/artge/assets/images/deco/nl-encoche.svg') 20px 0px no-repeat;

		@include desktop1200 {
			flex-direction: row;
		}


		@include tablet_small {
			flex-direction: column;
			height: auto;
			padding: 45px 20px 20px;
			margin-bottom: 20px;
		}

		&_content {

			@include desktop1200 {
				width: 50%;
			}

			@include tablet_small {
				width: 100%;
			}

			&__title {
				font: normal normal bold 40px/44px Poppins;
				letter-spacing: 1px;
				color: var(--darkblue);

				@include desktop {
					font-size: 30px;
					line-height: 32px;
				}

				@include desktop1200 {
					font-size: 40px;
					line-height: 44px;
				}

				@include mobile {
					font-size: 30px;
					line-height: 32px;
				}
			}

			p {
				font: normal normal normal 14px/18px Poppins;
				color: var(--darkblue);

				strong {
					font-weight: bold;
				}
				@include mobile {
					font-size: 14px;
					line-height: 18px;
				}
			}
		}

		&_mail {

			position: relative;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			@include desktop1200 {
				width: 45%;
			}

			@include tablet_small {
				width: 100%;
			}

			p {
				font: normal normal normal 9px/15px Poppins;

				a {
					text-decoration: underline;
				}
			}
			&--form {
				height: auto;
				p {
					margin: 0 !important;
				}
			}
			&--input {

				border: 1px solid #BABABA;
				border-radius: 3px;

			}

			&--submit {
				width: 100%;
				height: 44px;
				outline: none;
				border: none;
				background-color: var(--rose);
				border-radius: 3px;
				font: normal normal medium 13px/20px Poppins;
				color: var(--white);
				transition: background-color 200ms ease-out;
				cursor: pointer;
				margin-top: 10px;

				&:hover {
					background-color: var(--grey);
				}

			}

		}

		.bloc-form input:not([type="radio"]),
		.bloc-form select,
		.bloc-form textarea {
			padding: 4px 22px;
			height: 60px;
		}
		.bloc-form form, .bloc-form .fake-form {
			height: auto;
		}
		.bloc-form .frm_switch_block  {
			margin-bottom: 0;
		}
		.bloc-form .frm_switch {
			background-color: #DCDBDA;
		}

		.bloc-form .frm_switch .frm_slider::before {
			background: var(--white);
		}

	}
}