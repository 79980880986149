.toolbar{
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 80px;
    background: $c_white;
    z-index: 5100;
    height: 80px;
    padding: 0 30px;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: rgba($c_border,0.5);
    }
    @include tablet_portrait{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    @include mobile{
        bottom: 60px;
        height: 60px;
        padding: 0 20px;
    }

    &.toolbar-sit {
        @include tablet_landscape {
            height: 74px;
            bottom: 0;
            padding-right: 0;

            .cta-reserver {
                border-radius: 0;
                align-self: stretch;
                display: flex;
                align-items: center;
            }
        }
    }

    &.toolbar-exp{
        .btn-filtrer{
            min-width: 150px;
            @include mobile_small{
                min-width: 100px;
            }
        }
    }

    > * {
        margin: 0 10px;
        @include mobile{
            margin: 0 5px;
            flex: 1;
        }
    }

    .trier{
        @include ft($f_txt, 15px, 500, $c_surtitre, 20px);
        position: relative;
        width: auto;
        text-align: center;
        padding: 21px 40px 19px 40px;
        border: 1px solid rgba($c_border,0.25);
        border-radius: 4px;
        white-space: nowrap;
        @include mobile{
            padding: 11px 10px 9px 10px;
            font-size: calculateRem(12px);
        }
        @include mobile_small{
            font-size: calculateRem(10px);
        }
        select{
            @extend %center;
            opacity: 0;
        }
    }

    .btn-map{
        @include tablet_portrait{
            position: absolute;
            left: 30px;
        }
        @include mobile {
            position: relative;
            left: 0;
            flex: none;
            width: 50%;
            margin-right: 0;
            margin-left: 0;
        }
        @include mobile_small{
            font-size: calculateRem(10px);
        }
    }

    .btn-filtrer{
        background: $c_sub;
        padding: 21px 40px 19px 40px;
        min-width: 50px;
        @include mobile {
            font-size: calculateRem(12px);
            padding: 11px 20px 9px 20px;
            flex: none;
            width: calc(50% - 20px);
            margin-left: auto;
        }
        @include mobile_small{
            padding: 11px 20px 9px 10px;
            font-size: calculateRem(10px);
        }
        &:before{
            margin-right: 25px;
            @include mobile{
                font-size: 14px;
            }
            @include mobile_small{
                margin-right: 15px;
            }
        }
    }

    .metas-sit{
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        > * {
            position: relative;
            display: block;
            width: 100%;
            margin: 0;

            &.vendu-par-desktop {
                @include tablet_portrait {
                    display: none;
                }
            }

            &.vendu-par-mobile {
                display: none;

                @include tablet_portrait {
                    display: block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

            }
        }

        .price{
            @include ft('', 15px, 300, $c_greydark, 24px);
            text-align: left;
            flex: 1;
            width: 100%;
            @include mobile{
                font-size: calculateRem(12px);
                line-height: calculateRem(18px);
            }

            .barre{
                text-decoration: line-through;
                color: $c_light_txt;
                font-weight: 500;
                display: inline-block;
                margin-left: 4px;
            }

            span{
                @include ft('', 20px, 600, $c_greydark, 24px);
                text-transform: uppercase;
                letter-spacing: 0.5px;
                margin-top: -5px;
                @include mobile{
                    font-size: calculateRem(15px);
                    line-height: calculateRem(18px);
                    margin-top: -1px;
                }
            }
            
            &.price_desktop {

                @include tablet_portrait {
                    display: none;
                }
            }
            &.price_mobile {
                font-size:20px; 
                font-weight: 900; 
                padding-bottom: 5px;

                @include min-tablet_portrait {
                    display: none;
                }
            }
        }

        .offre-valable{
            @include ft('', 12px, 700, $c_main, 18px);
            box-sizing: border-box;
            text-transform: uppercase;
            letter-spacing: 0.8px;
            margin-top: -2px;
            @include mobile{
                font-size: calculateRem(12px);
                line-height: calculateRem(12px);
                margin-top: 0;
            }
            &.highlight{
                color: $c_third;
            }
        }
    }

    .cta-reserver{
        flex: none;
        width: auto;
        @include mobile{
            font-size: calculateRem(15px);
            margin-right: 0;
            padding: 12px 17px 10px 17px;
        }
        @include mobile_small{
            font-size: calculateRem(13px);
            padding: 12px 13px 10px 13px;
        }
        &:before{
            position: relative;
            left: 0;
            top: -2px;
            margin: 0 40px 0 0;
            float: left;
            @include mobile{
                top: -2px;
                font-size: 20px;
                margin-right: 15px;
            }
            @include mobile_small{
                top: 0;
                font-size: 16px;
            }
        }
    }

    .cta-offre-indispo {
        width: auto;

        &__large {
            @include mobile{
                font-size: calculateRem(12px);
            }
        }

        &__small {
            @include mobile{
                font-size: calculateRem(10px);
            }
        }
    }

    .indic-pagination{
        display: block;
        padding: 0;
        @include mobile{
            font-size: calculateRem(10px);
            line-height: calculateRem(12px);
        }
    }


    & ~ #footer{
        @include tablet_portrait{
            padding-bottom: 80px;
        }
        @include mobile{
            padding-bottom: 60px;
        }
    }
}