.homepage-hero {
	position: relative;
	height: 65vh;

	@include mobile {
		height: 52vh;
	}

	display: flex;
	align-items: flex-end;

	&__background {
		position: absolute;
		height: 100%;
		width: 100%;

		figure, img {
			height: 100%;
		}
	}

	&__content {
		margin: 0 auto;
		width: 100%;
		max-width: 1000px !important;

        @include mobile {
            margin-bottom: 20px;
        }
        
        h1 {
			width: 75%;
            font-weight: 400;
            strong {
                font-weight: 600;
            }

			@include tablet_portrait {
				width: 100%;
			}

			@include mobile {
				font-size: 15px;
				line-height: 20px;

				strong {
					font-weight: 700;
				}
            }
        }
    }

	&__cta-container {
		display: flex;

		margin-top: 20px;
		margin-bottom: 50px;

		@include mobile {
			margin-top: 13px;
			margin-bottom: 80px;
		}
	}

	&__search-cta {
		flex: 1;

		display: flex;

		cursor: pointer;

		&:hover .homepage-hero__search-btn {
			background-color: $c_greydark;
		}

	}

    &__search-input {
        position: relative;
        width: 100%;
        display: block;
        box-sizing: border-box;
        text-align: left;
        background: #fff;
        border-radius: 4px 0 0 4px;
        padding: 0 30px;
        border: 1px solid rgba($c_border, 0.5);
        border-right: none;
        font-size: 18px;
        line-height: 68px;
        color: $c_main;
        overflow: hidden;
        text-wrap: nowrap;

        &:before{
            @include font();
            content: "\e92d";
            display: inline-block;
            margin-right: 20px;
            font-size: 20px;
            color: $c_greydark;
        }

        @include mobile {
            font-size: 13px;
            color: $c_light_txt;
            border-radius: 4px;
            line-height: 42px;
            padding: 0 9px;
        }
    }

	&__search-btn {
		position: relative;
		display: flex;
		border-radius: 0 4px 4px 0;
		padding: 0 40px;
		line-height: 70px;
		min-width: auto;
		background-color: $c_sub;
		@include ft($f_txt, 14px, 500, $c_white);
		transition: all 0.16s;

		@include mobile {
			display: none;
		}
	}

	&__secondary-cta {
		position: relative;
		display: flex;
		margin-left: 20px;
		padding: 0 40px;
		line-height: 70px;
		background-color: $c_main;
		@include ft($f_txt, 14px, 500, $c_white);
		border-radius: 4px;
		transition: all 0.16s;
		text-wrap: nowrap;

		&:hover {
			background-color: $c_greydark;
			color: #fff !important;
		}

		@include mobile {
			display: none;
		}
	}
}