.card__slidedossier {
    max-width: 390px;

    position: relative;
    z-index: 2;

    figure {
        width: 100%;
        height: 260px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;

        @include mobile {
            height: 173px;
        }

    }

    &_content {

        position: relative;
        padding-top: 6px;
        width: 100%;

        &--cat {

            font: normal normal 11px/17px Poppins;
            letter-spacing: 0.44px;
            color: var(--bluegrey);
            text-transform: uppercase;

            strong {
                font-weight: bold;
            }

            padding-left: 13px;
            position: relative;

            &::before {
                content: '\e907';
                @include font();
                font-size: 10px;
                color: var(--bluegrey);
                top: 4px;
                left: 0;
                position: absolute;
                height: 10px;
                width: 6px;
            }
        }
        &--title {
            font: normal normal bold 34px/44px Poppins;
            
            color: var(--darkblue);
            margin-bottom: 10px;
        }
        &--text {
            margin-top: 7px;
            font: normal normal normal 14px/18px Poppins;

            color: var(--darkblue);
        }

       
    }
}