.bloc-slider-offres-auto-wrapper {

    @include tablet_portrait {
        padding-left: 0;
        padding-right: 0;
    }

    @include min-large-desktop {
        .bloc-texte {
            padding-left: 16vw;
        }
    }
    
    .splide__list {
        height: auto !important;
    }

    .bloc-slider-offres-auto-title {
        margin-bottom: 15px;
        padding-left: 0;

        @include mobile {
            margin-bottom: 20px;
        }

        h3 {
            font-size: 40px;
            @include tablet_portrait {
                font-size: 25px;
                line-height: 1;
            }

            @include mobile {
                margin-bottom: 20px;
            }
        }
    }
    .bloc-slider-offres-auto {

        .splide:not(.is-overflow) .splide__pagination, .splide:not(.is-overflow) .splide__arrow {
            display: flex;
        }

        .splide__track {
            padding-bottom: 20px;
            margin: auto;
            z-index: 1000;

            @include mobile {
                padding-bottom: 15px;
            }
        }

        .slider-offres-auto-cta-desktop {
            position: absolute;
            right: 80px;
            bottom: 27em;

            @include tablet_portrait {
                display: none;
            }
        }

        .slider-offres-auto-cta-mobile {
            display: none;
            position: relative;
            width: 100%;

            @include tablet_portrait {
                display: inline-block;
                margin-top: 20px;
            }
        }

        .splide__arrow {
            top: unset;
            bottom: -24px;
            background-color: transparent;

            svg {
                fill: #627A89;
            }

            &--prev {
                left: unset;
                right: 11vw;
            }

            &--next {
                right: 8vw;
            }

            span:before {
                font-size: 18px;
            }

            @include tablet_portrait {
                display: none !important;
            }
        }

        .class-pagination {
            position: static;
            display: flex;
            flex-wrap: nowrap;
            padding-left: 0;
            padding-right: 0;
            justify-content: left;
            width: 88%;
            padding-top: 20px; 

            @include tablet_portrait {
                width: 100%;
            }

            li {
                width: 100%;

                &:last-child {
                    button:not(.is-active) {
                        background: linear-gradient(90deg, rgba(#ABB3B9, 0.5) 0%, rgba(#ABB3B9, 0.5) 60%, rgba(255, 255, 255, 0) 100%);
                    }
                }
            }
        }
        
        .class-page {
            margin: 0;
            height: 1px;
            border-radius: 8px;
            width: 100%;
            background-color: rgba(#ABB3B9, 0.5);
            &.is-active {
                height: 5px;
                background-color: #DEA99E;
                opacity: 1;
                transform: unset;
                padding-bottom: 2px;
            }
        }
    }

    .bloc-slider-offres-auto-header {
        display: flex;

        &__title-tabs {
            width: 70%;
            flex-shrink: 0;

            @include tablet_portrait {
                width: 100%;
            }
        }

        .cta-desktop-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;
            margin-bottom: 25px;

            @include tablet_portrait {
                display: none;
            }
        }
    }

    .splide-slider-offres-auto-tab {
        margin: auto;

        .splide__arrow {
            background-color: white;
            padding: 30px;
            opacity: 1;
            top: 30%;
            border-radius: 0;
            top: unset;
            bottom: -22px;
            background-color: transparent;

            span:before {
                font-size: 18px;
            }

            @include tablet_portrait {
                display: none;
            }

            &--prev {
                right: 45px;
                left: unset;

                &:after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    bottom: 0;
                    height: 75px;
                    background: linear-gradient(to left, #fff 75%, rgba(255,255,255, 0.5) 100%);
                    width: 110px;
                    opacity: 1;
                }
            }

            &--next {
                right: 0;
            }
        }

        .splide__track {

            @include tablet_portrait {
                max-width: unset;
            }

            li.splide__slide {
                margin-bottom: 25px;
                padding: 10px 15px;

                button {
                    font-size: 16px;
                    font-weight: 600;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    &.is-active {
                        color: #DEA99E;

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: -8px;
                            left: 50%;
                            margin-left: -8px;
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: #DEA99E;

                            @include mobile {
                                width: 10px;
                                height: 10px;
                                bottom: -4px;
                            }
                        }
                    }
                }

                @include min-tablet_portrait {

                    &:first-child {
                        padding-left: 0;

                        button:after {
                            translate: -50%;
                        }
                    }
                }

                @include tablet_portrait {

                    button {
                        font-size: 17px;
                    }
                }

                @include mobile {
                    padding: 10px 5px;

                    &:first-of-type {
                        padding-left: 15px;
                    }
                }
            }
        }
        hr {
            color: rgba(98,122,137,0.3);
            margin: 0;
            bottom: 24px;
            position: absolute;
            left: 0;
            right: 0;
            z-index: -1;
            opacity: 0.3;
            width: 80%;

            @include tablet_portrait {
                width: 100%;
            }
        }
    }
}


