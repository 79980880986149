.bloc-destination-libre {
    background: $c_main;
    padding: 130px 0 100px;
    margin: $content-spacing/2 auto;
    @include tablet_portrait {
        padding: 80px 0 70px;
        margin: $content-spacing-tab/2 auto;
    }
    @include mobile {
        padding: 60px 0 50px;
        margin: $content-spacing-mobile/2 auto;
    }

    .bloc-texte {
        @extend %bloc-texte-white;
        @media screen and (max-width: 750px) {
            padding: 0 20px;
        }
        @include mobile_small {
            padding: 0;
        }

        h2 {
            margin-bottom: 40px;
            @include tablet_portrait {
                margin-bottom: 50px;
            }
        }

        p {
            @include mobile {
                font-size: calculateRem(13px);
                line-height: calculateRem(25px);
            }
        }

        &.move-bloc-texte {
            padding: 0;
            @media screen and (min-width: 1201px) {
                width: calc((75% - (25% + 50px)) / 2);
                position: relative;
                top: 70px;
            }
            p {
                font-size: 14px;
            }
        }
    }

    .wrapper-destination {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 35px;
        @media screen and (min-width: 1201px) {
            bottom: 135px;
        }
        @include clearfix();
        @include desktop {
            padding: 0;
        }

        > * {
            position: relative;
            box-sizing: border-box;
            float: left;
            display: block;
            padding: 0 20px;
            margin: 20px 0;
            @extend %appearance;
            background: none;
            @include tablet_portrait {
                padding: 0 10px;
                margin: 10px 0;
            }
            @media screen and (max-width: 750px) {
                padding: 0;
            }

            &:nth-child(2), &:nth-child(5) {
                @media screen and (min-width: 1201px) {
                    position: relative;
                    top: 165px;
                }

            }

        }


        .item-destination {
            width: calc((95% - (25% + 50px)) / 2);
            @include addTransition();
            @include desktop1200 {
                width: 50%;
            }
            @media screen and (max-width: 750px) {
                width: 100%;
            }

            &:hover, &:focus {
                body:not(.no-hover) & {
                    cursor: pointer;

                    figure {
                        img {
                            opacity: 0.5 !important;
                        }
                    }

                    .caption {
                        p {
                            opacity: 1;
                            max-height: 100%;
                        }
                    }
                }
            }

            figure {
                @include aspect-ratio(460, 320);
                @include desktop1200 {
                    @include aspect-ratio(335, 200);
                }

                &:after {
                    content: '';
                    @extend %center;
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,202529+100&0+0,1+100 */
                    background: -moz-linear-gradient(top, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00202529', endColorstr='#202529', GradientType=0); /* IE6-9 */
                    opacity: 0.65;
                    z-index: 10;
                    @include addTransition();
                }

                img {
                    @extend %center;
                    @include addTransition();
                }
            }

            .credits, figcaption {
                display: none;
            }

            .caption {
                position: absolute;
                top: auto;
                bottom: 30px;
                padding: 0 40px;
                z-index: 50;
                width: calc(100% - 40px);
                text-align: center;
                @include addTransition();
                @include desktop1280{
                    width: calc(100% - 40px);
                    padding: 0 20px;
                }
                @include tablet_portrait {
                    bottom: 20px;
                }
                @media screen and (max-width: 750px) {
                    width: 100%;
                    bottom: 25px;
                }

                > * {
                    color: $c_white;
                    text-align: center;
                }

                h3 {
                    @include ft('', 30px, 400, '', 35px);
                    margin: 0;
                    @include desktop1280 {
                        font-size: calculateRem(25px);
                        line-height: calculateRem(30px);
                    }
                    @include desktop1200 {
                        font-size: calculateRem(30px);
                        line-height: calculateRem(35px);
                    }
                    @media screen and (max-width: 750px) {
                        font-size: calculateRem(25px);
                        line-height: calculateRem(30px);
                    }
                }

                p {
                    @include ft('', 13px, 300, '', 24px);
                    opacity: 0;
                    overflow: hidden;
                    max-height: 0;
                    margin: 5px 0 5px;
                    @include addTransition();
                    @include desktop1280 {
                        font-size: calculateRem(12px);
                        line-height: calculateRem(18px);
                    }
                    @include tablet_portrait {
                        display: none;
                    }

                    .no-hover & {
                        display: none;
                    }
                }

                .btn {
                    margin-top: 10px;
                    @media screen and (max-width: 750px) {
                        margin-top: 0;
                        background: none;
                        padding: 0;
                        font-size: calculateRem(11px);
                        line-height: calculateRem(18px);
                        &:before, &:after {
                            content: '-';
                            margin: 0 4px;
                        }
                    }

                    &:hover, &:focus {
                        body:not(.no-hover) & {
                            background: $c_main;
                        }
                    }

                }
            }
        }
    }
}