.row {
    max-width: 1560px;
    display: flex;
    width: 100%;
    flex-direction: row;

    margin: 0 auto;

}

.no-radius {
    border-radius: 0 !important;
}

.lemag {


    .row {
        flex-wrap: wrap-reverse;
    }

    .content-wrapper--lemag-title {
        @media screen and (min-width: 1440px) {
            margin: 0 80px;
        }
    }

    ::selection {
        background-color: var(--darkblue);
        color: var(--white);
    }

    background-color: var(--darkblue);
    &__slider {
        margin-top: -20px;
        width: 1210px;
        
        @include tablet_portrait {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;
        }

        @include mobile {
            margin-left: -20px;
            width: calc(100% + 40px);
        }

        .class-pagination {
            width: calc(100vw - 775px) !important;

            @include desktop1200 {
                width: calc(100vw - 450px) !important;
            }

            @include tablet_portrait {
                width: 60vw !important;
            }

            @include mobile {
                width: 100% !important;
                padding-left: 20px !important;
                padding-right: 20px !important;
            }
        }

        .splide__arrow {
            bottom: 0;
            transform: translateY(43%);
            left: calc(100vw - 705px);
            &--prev {
                transform: translate(-40px, 43%);
            }
    
            @include wide {
                left: 850px;
            }
    
            @include desktop1200 {
                left: calc(100vw - 400px);
            }
        }
    }
    &__header {
        &::before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            z-index: 10;
            top: 8px;
            margin: auto;
            width: 129px;
            height: 4px;
            border-radius: 2px;
            background-color: rgba(#1E2529, 0.3);
            display: none;

            @include mobile {
                display: block;
            }
        }

        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-size: cover;
        background-position: 0 75%;
        height: 70vh;
        
        @include desktop {
            max-height: 674px;
        }

        @include mobile {
            max-height: 684px;
        }

        &_content {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 235px;
            padding-top: 67px;
            background: transparent linear-gradient(180deg, #00000000 0%, #0000009F 74%, #000000 100%) 0% 0% no-repeat padding-box;

            @include mobile {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 30px;
            }

            h3 {

                text-align: left;
                font: normal normal normal 11px/17px Poppins;

                strong {
                    font-weight: 600;
                }

                letter-spacing: 1.65px;
                color: var(--white);
                text-transform: uppercase;
                margin-left: 87px;
                padding-left: 13px;

                &::before {
                    content: '\e907';
                    @include font();
                    font-size: 10px;
                    color: var(--white);
                    top: 3px;
                    left: 0;
                    position: absolute;
                    height: 10px;
                    width: 6px;
                }

                @include mobile {
                    margin: 0;
                }
            }

            h1 {
                margin-left: 87px;
                text-align: left;
                font: normal normal bold 60px/44px Poppins;
                letter-spacing: 0px;
                color: var(--white);

                @include mobile {
                    font-size: 38px;
                    line-height: 44px;
                    margin: 0;
                }
            }

            p {
                margin-left: 87px;
                font: normal normal normal 20px/24px Poppins;
                letter-spacing: 0px;
                color: var(--white);

                @include mobile {
                    font-size: 16px;
                    line-height: 24px;
                    margin: 0;
                }
            }
        }
    }

    &__title {
        padding-top: 30px;
        margin-bottom: -40px;

        @include tablet_portrait {
            margin-bottom: 0;
        }

        h1, span {
            font: normal normal normal 110px/110px "Libre Baskerville";
            letter-spacing: 23.1px;
            color: var(--white);
            text-transform: uppercase;
            @include tablet_portrait {
                font-size: 44px;
                line-height: 44px;
                letter-spacing: 9.24px;
            }
        }
    }

    &__featuredtitle {
        margin: 25px 0;

        h3 {
            font: normal normal 600 24px/24px Poppins;
            color: var(--black);
            margin: 0;
        }
    }

    &__dossier {
        max-width: 880px;

        @include tablet_portrait {
            margin: 40px auto 0;
        }

        margin-top: 40px;
        overflow: hidden;
        @include mobile {
            margin-top: 20px;
            
        }
        h1 {
            font: normal normal bold 40px/45px Poppins;
            text-align: center;
            letter-spacing: 0px;
            color: var(--rose);
            margin-bottom: 30px;

            span {
                color: var(--darkblue);
            }
            @include mobile {
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 10px;
                
            }
        }

        p {
            text-align: center;
            margin-bottom: 10px;
            font: normal normal normal 13px/22px Libre Baskerville;
            color: var(--black);
            @include mobile {
                display: none;
            }
        }

        &_info {
            position: relative;
            width: max-content;
            margin: 30px auto;
            @include mobile {
                margin-top: 0px;
                margin-bottom: 20px;
            }
            &::before {
                position: absolute;
                content: '';
                top: 12px;
                left: -505px;
                width: 500px;
                height: 1px;
                background-color: var(--verylightgrey);

            }

            &::after {
                position: absolute;
                content: '';
                top: 12px;
                right: -505px;
                width: 500px;
                height: 1px;
                background-color: var(--verylightgrey);

            }

            &__articles-nb {
                position: relative;
                text-align: center;
                font: normal normal normal 13px/28px Poppins;
                text-transform: uppercase;
                display: inline;

                .icon-article {
                    font-size: 13px;
                    line-height: 1;
                    color: var(--black);

                    &:before {
                        content: '\e9e4';
                    }
                }


            }
        }
    }

    &__nl {
        margin: 80px auto;
        max-width: 880px;
        width: 100%;
       
    }

    &__sidebar {

        background-color: var(--white);
        width: 350px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            right: 100%;
            width: calc(100vw - 1600px);
            background-color: white;
            height: 100%;
            visibility: visible !important;
        }

        @include desktop1200 {
            width: 100%;
            height: 1px;
            border-radius: 0;
        }

        .listing-cover & {
            border-radius: 0;
        }
    }

    &__content {
        border-top-right-radius: 10px;
        background-color: var(--white);
        width: calc(100% - 640px);

        @include desktop1200 {
            width: calc(100% - 290px);
            padding: 0 40px;
            border-top-left-radius: 10px;
        }

        @include tablet_portrait {
            position: relative;
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            
        }

        .deco__bottom_center {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 103px;
            width: 1202px;
            height: 902px;
            background: #FFE4D8 0% 0% no-repeat padding-box;
            border-radius: 800px 0px 0px 800px;

            @include mobile {
                border-radius: 800px 0px 0px 0px;
                bottom: unset;
                top: 0;
                left: 0px;
                width: 100%;
                height: 100%;
            }
        }

        &_listing {
            max-width: 915px;

        }

        &_cardlisting {
            &--wrapper {
                max-width: calc(100% - 40px);
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        .listing-cover & {
            border-radius: 0;
        }

        .mobile-separator {
            border-top: 1px solid rgba(171, 179, 185, 0.33);
            display: none;

            @include mobile {
                display: inherit;
            }
        }
    }

    &__highlight {
        width: 290px;
        @include tablet_portrait {
            width: 100%;
        }

        @include mobile {
            h3 {
                display: none;
            }
        }

        &_content {

            position: sticky;
            top: 80px;

            padding: 25px 20px 25px 52px;
            @include tablet_portrait {
                position: static;
                top: 0;
                padding: 0;
                margin-left: 59px;
            }
            @include mobile {
                margin-left: 15px;
                padding-top: 15px;
            }
            .slider-lemag &{
                margin-top: 440px;
                @include tablet_portrait {
                    margin-top:0;
                }
            }
            &__title {
                font: normal normal 600 20px/24px Poppins;
                color: var(--white);
                margin-bottom: 10px;
            }
            .splide__list {
                flex-direction: column;
                @include tablet_portrait {
                    flex-direction: unset;
                }

                li {
                    @include tablet_portrait {
                        margin-right: 30px;
                    }

                    @include mobile {
                        margin-right: 0;
                    }
                }
            }
            &--item {}
        }
    }

    &__listing__title {
        font: normal normal 600 24px/24px Poppins;
        color: var(--rose);
        margin: 0;

        span {
            color: var(--black);
        }
    }

    .splide__list {
        height: auto;
    }
    
    .bloc-video .embed-container {
        height: auto;
    }

    #slider-dossier {
        width: 100vw;

        @include mobile {
            width: 100%;
        }
    }

    #slider-dossier-track {
        overflow: visible;
    }

    .class-pagination {
        position: static;
        display: flex;
        flex-wrap: nowrap;
        padding-left: 0;
        padding-right: 0;
        justify-content: left;
        width: 60vw;
        max-width: 800px;
        padding-top: 20px; 

        @include tablet_portrait {
            width: 100%;
        }

        li {
            width: 100%;

            &:last-child {
                button:not(.is-active) {
                    background: linear-gradient(90deg, rgba(#ABB3B9, 0.5) 0%, rgba(#ABB3B9, 0.5) 60%, rgba(255, 255, 255, 0) 100%);
                }
            }
        }
    }
    
    .class-page {
        margin: 0;
        height: 1px;
        border-radius: 8px;
        width: 100%;
        background-color: rgba(#ABB3B9, 0.5);
        &.is-active {
            height: 5px;
            background-color: #DEA99E;
            opacity: 1;
            transform: unset;
            padding-bottom: 2px;
        }
    }

    #pagination {
        @include mobile {
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    .absolute-link {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
    }

    &__page__newsletter-block.bloc.bloc-small {
        @include mobile {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .bloc__newsletter_content p {
            font: normal normal normal 14px/18px Poppins !important;
        }
    }
}

.page-template-archive-lemag {
    #footer {
        @include mobile {
            margin-top: 0;
        }
    }
}

/*
.queries {
    @include min-mobile {
        margin-top: 0px;
    }
    @include tablet_small {
		bottom: 95px;
	}

	@include min-tablet_small {
		margin-top: 0px;
	}

	@include min-tablet_portrait {
		margin-top: 0px;
	}
}
*/