// 2 ET 3 COLONNES
// ==========================================================================
.bloc-colonne {
    padding: 20px 0;
    @include clearfix();
    @include mobile {
        padding: 0;
        margin: 20px 0;
    }

    // Si l'otion background est cochée
    &.with-background {
        background: $c_greydark;
        padding: 20px 0;
        margin: $content-spacing/2 auto;
        @include clearfix();
        @include tablet_landscape {
            margin: $content-spacing-tab/2 auto;
        }
        @include mobile {
            padding: 10px 0;
            margin: $content-spacing-mobile/2 auto;
        }

        .col-50, .col-33{
            a.wrapper-link {
                @include addTransition();
                &:hover, &:focus{
                    body:not(.no-hover) & {
                        .no-photo{
                            & + .bloc-texte{
                                .btn{
                                    background: $c_white;
                                    color: $c_third;
                                }
                            }
                        }
                    }
                }
            }
        }

        .no-photo{
            background: $c_greydark;
        }

        .credits{
            color: $c_white;
        }

        .bloc-texte{
            @extend %bloc-texte-white;
            .btn{
                color: $c_greydark;
                background: $c_white;
                &:hover, &:focus{
                    body:not(.no-hover) &{
                        color: $c_white;
                        background: $c_greydark;
                    }
                }
            }
        }

        .wrapper-link{
            .bloc-texte{
                .btn{
                    color: $c_white;
                }
            }
        }
    }

    .col-50 {
        &:nth-child(odd) {
            clear: left;
            padding-top: 30px;
            @include mobile {
                padding-top: 0;
            }
        }
    }

    .col-33 {
        &:nth-child(3n+1) {
            clear: left;
        }
        @media screen and (max-width: 800px){
            width: 100%;
            clear: none;
            float: none;
        }
    }

    .col-50, .col-33 {
        position: relative;
        padding: 0 $border-gutter/2;
        margin: 40px 0;
        @include clearfix();
        @include tablet_landscape {
            padding: 0 20px;
        }
        @include mobile {
            padding: 0;
            margin: 40px 0;
        }

        &:first-child {
            @include tablet_portrait {
                padding-left: 0;
            }
        }

        &:last-child {
            @include tablet_portrait {
                padding-right: 0;
            }
        }

        > div, > a {
            position: relative;
        }

        .wrapper-image-link {
            display: block;
            figure {
                background: $c_dark;
            }

            &:hover, &:focus {
                body:not(.no-hover) & {
                    figure {
                        img {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

        .credits {
            margin-top: 10px;
            padding: 0 $border-gutter/2;
            @include tablet_landscape{
                padding: 0 20px;
            }
            @include mobile {
                padding: 0;
                margin-top: 5px;
            }
        }

        figure {
            //background: none;
            @include aspect-ratio(640, 395);

            &.no-ratio{
                background: none;
                &:before{
                    content: none !important;
                }
                img{
                    position: relative;
                    height: auto;
                }
            }

            img {
                @extend %center;
                @include addTransition();
            }

            figcaption{
                display: none;
                padding: 35px 40px;
                @include tablet_landscape{
                    padding: 13px 20px;
                }
            }

            &:not(.hero){
                ~ .bloc-texte{
                    h2{
                        font-size: calculateRem(24px);
                        line-height: calculateRem(24px);
                    }
                }
            }
        }

        figure, .no-photo, .wrapper-image-link {
            & ~ .bloc-texte {
                margin-top: 55px;
                @include tablet_landscape {
                    margin-top: 40px;
                }
                @include mobile {
                    margin-top: 32px;
                }
            }
        }

        .wrapper-image-link{
            .credits{
                padding: 0;
            }
        }

        // Image + Lien
        // ==========================================================================
        .wrapper-link {
            position: relative;
            display: block;

            &.no-gradient {
                figure {
                    &:after {
                        content: none;
                    }
                    figcaption{
                        display: block;
                    }
                }
            }

            figure, .no-photo {
                @include aspect-ratio(640, 918);

                img {
                    @extend %center;
                }
            }

            figure {
                &:after {
                    content: '';
                    @extend %center;
                    top: auto;
                    height: 50%;
                    @extend .gradient-bottom;
                    opacity: 0.8;
                }
            }

            figure, .no-photo {
                margin: 0;
            }

            .credits{
                padding: 0;
            }

            .bloc-texte {
                position: absolute;
                left: 60px;
                right: 60px;
                bottom: 60px;
                z-index: 10;
                width: calc(100% - 120px);
                @extend %bloc-texte-white;
                @include tablet_landscape {
                    left: 40px;
                    right: 40px;
                    bottom: 40px;
                    width: calc(100% - 80px);
                }
                @include tablet_portrait{
                    left: 30px;
                    right: 30px;
                    bottom: 30px;
                    width: calc(100% - 60px);
                }
                @include mobile {
                    left: 20px;
                    right: 20px;
                    width: calc(100% - 40px);
                }

                h2, h3, .surtitre {
                    color: $c_white;
                    @include addTransition();
                }

                .btn{
                    background: $c_third;
                    margin: 0;
                }
                p {
                    font-family: 'Libre Baskerville', Times, serif;
                }
                
            }
        }

        a.wrapper-link {
            @include addTransition();
            &:hover, &:focus{
                body:not(.no-hover) & {
                    .bloc-texte{
                        .btn{
                            background: $c_greydark;
                        }
                    }
                }
            }
        }
    }
}

// STYLE POUR 3 COLONNES
// ==========================================================================
.bloc-3-colonnes {
    .col-33 {
        figure, .no-photo, .wrapper-image-link{
            & ~ .bloc-texte {
                margin-top: 55px;
                @include tablet_landscape {
                    margin-top: 45px;
                }
                @include mobile {
                    margin-top: 25px;
                }
            }
        }

        .bloc-texte{
            h2{
                @include tablet_landscape{
                    font-size: calculateRem(30px);
                    line-height: calculateRem(30px);
                }
                @include tablet_portrait{
                    font-size: calculateRem(25px);
                    line-height: calculateRem(25px);
                }
            }

            p {
                @include mobile {
                    margin-bottom: 25px !important;
                }
            }
        }

        .wrapper-link {
            .bloc-texte {
                left: 30px;
                right: 30px;
                bottom: 30px;
                width: calc(100% - 60px);
                @include tablet_landscape {
                    left: 20px;
                    right: 20px;
                    bottom: 20px;
                    width: calc(100% - 40px);
                }
            }
        }


        figure:not(.hero){
            ~ .bloc-texte{
                h2{
                    font-size: calculateRem(24px);
                    line-height: calculateRem(24px);
                }
            }
        }
    }
}

.bloc-colonne.with-background + .bloc-colonne.with-background{
    margin-top: -60px;
    @include tablet_landscape{
        margin-top: -40px;
    }
    @include mobile{
        margin-top: -30px;
    }
}