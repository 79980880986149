//show the pagination
.splide:not(.is-overflow) .splide__pagination {
	display: flex !important;
}

// show slide outside the slider
.my-ex-slider-overflow--visible {
	.splide__track {
		overflow: visible;
		
	}
}

//all sliders
.myex-splide-slider {
	.splide {
		&__arrow {
			top: unset;
			background-color: transparent;
			color: currentColor;
			position: absolute;
			bottom: calculateRem(-8px);
			right: 0;
			transform: none;
			display: none;

			svg {
				fill: var(--color-text-lighter);
			}

			&--prev {
				left: unset;
				right: calculateRem(53px);
			}

			span:before {
				font-size: calculateRem(18px);
			}

			@include min-mobile {
				display: flex;
			}
		}

		&__list {
			height: auto;
		}

		&__slide {
			color: currentColor; // overwrite

			&.collections {
				width: calc(220px);
				// height: 292px !important; //why height ?
			}

			&.offres {
				width: calculateRem(300px);
				// height: 270px !important;
			}

			&.collections {
				width: calc(220px);
				// height: 317px !important;
			}

			&.explorateurs {
				width: calc(220px);
			}
		}

		&__pagination {
			position: static;
			display: flex;
			flex-wrap: nowrap;
			padding-left: 0;
			padding-right: 0;
			justify-content: left;
			width: 100%;
			padding-top: calculateRem(15px);
			z-index: 0;

			@include min-mobile {
				width: calc(100% - 6rem);
			}

			@include min-tablet_small {
				padding-top: calculateRem(20px);
			}

			li {
				display: block;
				width: 100% !important;

				button {
					border: none;
				}

				&:last-child {
					button:not(.is-active) {
						background: linear-gradient(
							90deg,
							var(--color-text-lighter) 0%,
							var(--color-text-lighter) 60%,
							rgba(255, 255, 255, 0) 100%
						);
					}
				}
			}
		}

		&__pagination__page {
			margin: 0;
			height: calculateRem(1px);
			border-radius: calculateRem(8px);
			width: 100%;
			background-color: var(--color-text-lighter);
			&.is-active {
				height: calculateRem(5px);
				background-color: var(--color-primary);
				opacity: 1;
				transform: unset;
				padding-bottom: calculateRem(2px);
			}
		}
	}
}

// Slider big highlight collection
.myex-splide-slider.myex-splide-slider--hightlight {
	position: relative;
	

	.splide {
		&__pagination {
			position: absolute;
			top: calculateRem(15px);
			bottom: unset;
			padding: 0 calculateRem(25px);
			width: 100%;

			@include min-mobile {
				padding: 0 calculateRem(40px);
				top: calculateRem(25px);
			}
		}

		&__arrows {
			display: none;
		}

		&__slide {
			width: 100%;
		}
	}
}

// Slider Bloc Lieux

.myex-section__bloc-slider-lieux {

	&__tabs {
		display: flex;
		justify-content: space-between;

		ul {
			display: flex;

			li {
				align-content: end;

				button {
					background-color: transparent;
					border: none;
					cursor: pointer;
					font-size: 16px;

					&:first-of-type {
						color: white;
						font-weight: 600;
					}

					&:nth-of-type(2n) {
						color: grey;
						position: relative;
						bottom: 1px;
						right: 5px;
					}

					&.active {
						color: $c_third;

						&:after {
							content: '';
							position: absolute;
							top: 45px;
							left: 45%;
							width: 10px;
							height: 10px;
							border-radius: 50%;
							background-color: #DEA99E;
							transform: translate(-50%, 50%);

							@include mobile {
								top: 40px;
							}
                        }
					}
				}
			}
		}

		button {
			cursor: pointer;
		}
	}

	&__add-lieu {
		color: white;
		background-color: $c_third;
		border: none;
		border-radius: 50%;
		width: 45px;
		height: 45px;
		font-size: 20px;

		@include mobile {
			width: 40px;
			height: 40px;
		}
	}

	.separator {
		height: .0625rem;
		border-radius: .5rem;
		width: 100%;
		background: linear-gradient(90deg, var(--color-text-lighter) 0%, var(--color-text-lighter) 60%, rgba(255,255,255,0) 100%);
		margin: 10px auto 25px;
	}
}
