.bloc-partenaire-testimonial {

    padding: 20px 0;

    .bloc-testimonial-title {
        margin-bottom: 15px;
        padding-left: 0;

        h3 {
            font-size: 40px;
            @include tablet_portrait {
                font-size: 25px;
            }
            @include mobile {
                font-size: 18px;
            }
        }
    }

    .bloc-texte {
        padding-bottom: 30px;

        @include mobile {
            padding-bottom: 0;
        }
    }

    .splide {

        &__arrow {
            color: #fff;
            background: #7A97AA;
            border-radius: 4px;
            width: 60px;
            height: 60px;
            opacity: 1;

            @include tablet_landscape {
                width: 40px;
                height: 40px;
            }

            &--prev {
                left: 4vw;
            }

            &--next {
                right: 4vw;
            }

            &:hover {
                transition-duration: 300ms;
                background-color: #DEA99E;
                opacity: 1;
            }

            &:disabled {
                opacity: 0 !important;
            }

            @include mobile {
                display: none;
            }
        }

        &__track {
            max-width: 1280px;
            margin: 0 auto;

            @include min-large-desktop {
                max-width: 1440px;
            }

            @include tablet_landscape {
                margin: 0 auto;
                padding: 0 40px !important;
            }

            @include mobile {
                padding: 0 20px !important;
            }

            .splide__slide {

                .item {

                    .bloc-texte {
                        padding-top: 30px;

                        @include mobile {
                            padding-top: 15px;
                        }

                        h2, h3 {
                            @include mobile {
                                font-size: 13px;
                                margin-bottom: 0;
                            }
                        }
                    }

                    &.testimonial {

                        .bloc-texte::before {
                            content: '\e964';
                            font-family: 'icomoon';
                            display: block;
                            position: relative;
                            text-align: center;
                            width: 100%;
                            font-size: 40px;
                            color: #DEA99E;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .one-slide {
        width: 85vw;
        display: flex;
        margin: auto;

        @include min-large-desktop {
            max-width: 1350px;
        }

        @media (min-width: 1440px) and (max-width: 1599px) {
            max-width: 1200px;
        }

        @include mobile {
            flex-direction: column;
            width: auto;
            margin: auto 20px;
        }
        .pic {
            margin-right: 40px;

            @include mobile {
                margin-right: 0;
            }
        }
        .bloc-texte {
            display: flex;
            flex-direction: column;
            align-self: center;
            max-width: 830px;
            padding: 0;

            p {
                margin: 0 !important;

                @include mobile {
                    line-height: 22px;

                    &:first-of-type {
                        margin-top: 10px !important;
                    }
                }

                .btn {
                    color: #172540;
                    padding: 0;
                    background-color: transparent;
                    margin-top: 20px;
                    font-weight: 600;
                    @include mobile {
                        text-align: left;
                        margin-top: 10px;
                        font-size: 11px
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -4px;
                        width: 20px;
                        height: 3px;
                        border-radius: 50px;
                        background-color: #DEA99E;
                        left: 5px;

                        @include mobile {
                            left: 1px;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1440px) and (min-width: 1024px) {
        .splide__track {
            margin: 0 auto;
            padding: 0 80px !important;
        }
    }
}
