// #overlay-shadow {
// 	@include min-mobile {
// 		display: none; // we need it for overlay notif
// 	}
// }

.myex-overlay {
	z-index: $-z-overlay;
	--overlay-side-padding: #{calculateRem(14px)};
	top: var(--header-height);
	border-top-left-radius: calculateRem(14px);
	border-top-right-radius: calculateRem(14px);

	@include min-mobile {
		--overlay-side-padding: #{calculateRem(44px)};
		top: 0;
		border-radius: 0;
	}

	&__top {
		padding: calculateRem(24px) var(--overlay-side-padding) calculateRem(20px)
			var(--overlay-side-padding);
		display: flex;
		flex-direction: row;
		row-gap: calculateRem(10px);
		column-gap: calculateRem(4px);

		@include min-mobile {
			padding: calculateRem(38px) var(--overlay-side-padding) calculateRem(27px)
				var(--overlay-side-padding);
			flex-direction: column;
		}
	}

	&__content {
		padding-left: var(--overlay-side-padding);
		padding-right: var(--overlay-side-padding);
	}

	&__title {
		font-size: calculateRem(25px);
		font-weight: 600;
		line-height: 1;
		margin: 0;
		color: currentColor;
	}

	&__close {
		padding: 0;
		display: flex;
		justify-content: flex-end;
		font-size: calculateRem(12px);
		font-weight: 400;
		color: #4b5155;
		position: relative;
		background: none;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		margin: 0;
		transition: all 0.2s ease;
		border: none;
		flex-direction: row;
		margin-left: auto;
		order: 2;

		@include min-mobile {
			order: 0;
		}

		&:hover {
			color: var(--color-primary);
		}

		span {
			display: none;
			@include min-mobile {
				display: block;
			}
		}
	}

	&__scroller {
		height: 100%;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: auto;
		padding-left: var(--overlay-side-padding);
		padding-right: var(--overlay-side-padding);
	}
}

// Overlay add to collection
#overlay-add-to-collection {
	.myex-overlay__content {
		position: relative;
		&:after {
			content: "";
			height: 1px;
			width: 100%;
			background: #abb3b980;
			bottom: 0;
			left: 0;
			display: block;
		}
	}

	div {
		text-align: -webkit-center;

		.overlay-add-to-collection-list-loader {
			width: 25px;
			height: 25px;
			border: 3px solid #FFF;
			border-bottom-color: var(--color-primary);
			border-radius: 50%;
			display: none;
			box-sizing: border-box;
			animation: rotation 1s linear infinite;
			margin-top: 20px;

			&.on-load {
				display: inline-block;
			}

			@keyframes rotation {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
	}
}


