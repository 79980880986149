.myex-priceFrom {
	font-size: calculateRem(10px);
	font-weight: 500;
	line-height: 1;
	display: flex;
	align-items: center;
	padding: calculateRem(12px) calculateRem(17px) calculateRem(10px)
		calculateRem(17px);
	background-color: white;
	border-radius: calculateRem(8px) 0 0 calculateRem(8px);
	gap: calculateRem(5px);
	@include theme-light;
	color: var(--color-text);

	&__price {
		font-size: calculateRem(18px);
		font-weight: 800;
		letter-spacing: 0.05em;
		line-height: 1;
	}
}
