.nav__section {
    max-width: 1440px;
    height: 60px;
    width: 100%;
    margin: 0 auto;
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
        padding-left: 15px;
    }
    ul {
        display: inline-flex;

        li {
            margin-right: 30px;
            text-align: center;

            a {
                font: normal normal 600 14px/14px Poppins;
                @include mobile {
                    font-size: 11px;
                    line-height: 14px;
                }
                color: #1E2529;
                transition: color 300ms ease-out;
                &::after {
                    content: '';
                    position: absolute;
                    opacity: 0;
                    top: 35px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 12px;
                    height: 12px;
                    border-radius: 10px;
                    background-color: var(--rose);
                    transition: all 300ms ease-out;

                    @include mobile {
                        top: 37px;
                        right: 0;
                        left: 10px;
                        margin: unset;
                        width: 10px;
                    height: 10px;
                    }
                }

                &.active,&:hover {
                    color: var(--rose);

                    &::after {
                        opacity: 1;
                        top: 39px;
                    }
                }
            }
        }
    }

    &_share {
        width: 17px;
        height: 19px;
        color: #202529;
        font-size: 19px;
        &:hover {
            color:var(--rose)
        }
    }
}