// Fil d'ariane
// ==========================================================================
#breadcrumb {
	overflow: hidden;
	text-overflow: ellipsis;

	span {
		@include ft($f_hx, 11px, 600, $c_greydark, 1.54);
		white-space: nowrap;
		max-width: 100%;
		@include addTransition();
	}

	a {
		@include ft($f_hx, 11px, 300, $c_greydark, 1.54);
		@include addTransition();

		&:after {
			content: '-';
            display: inline-block;
            margin: 0 4px;
		}
        span{
            font-weight: 300;
        }

		&:hover, &:focus {
			body:not(.no-hover) & {
				span{
					text-decoration: underline;
				}
			}
		}
	}

	&.--text-white {
		span {
			color: $c_white;
		}
		a {
			color: $c_white;
		}
	}
}

%breadcrumb-white{
    span, a{
        color: $c_white;
    }
    a{
        &:hover, &:focus {
            body:not(.no-hover) & {
                span{
                    color: $c_main;
                }
            }
        }
    }
}