.bookingkit {
    padding: 20px 25px 20px 40px;
    overflow-y: scroll !important;

    #bookingKitContainer {

        .alphaBg {
            background-color: #6FAC90!important;
            fill: #6FAC90!important;
        }

        ol,
        ul,
        p {
            margin:0;
            & + ol,
            & + ul,
            & + p {
                margin: 20px 0px 0px 0px;
            }
        }

        .fillTextColor {
            fill: rgb(174,174,174) !important
        }

        .bk-detail-right-column .bk-detail-payments-logos {
            display: flex;
            align-items: flex-start;

            margin-top: 20px;
            margin-bottom: 20px;

            ul {
                font-size: 0;
                li {
                    display: inline-block;
                    margin-left: 1px;
                    margin-bottom: 3px;
                    font-size: 0;
                    line-height: 0;
                }
            }
        }

        .bk_ext_widget,
        .bk_ext_widget.bk_ext_widget_ib {

            display: block;
            margin: 0;
            border-left: 0;
            padding-top: 10px;
            margin-top: 10px;
            padding-left: 0;
            border-top: 1px solid #f4f4f4;

        }
        .bk-events-footer {
            padding: 20px 0px;
        }
        &.bk-portrait .bk-events-footer-links li {
            font-size: 9px;
            line-height: inherit;
            margin:0;
            & + li {
                margin-top:2px;
            }
        }

        .bk-date-btn span,
        .bk-offer-btn span {
            flex-grow: 1;
            line-height: 15px;
            display: inline-block;
            padding: 3px 48px 0px 0px;
            color:#FFF !important;
        }

        .bk-voucher-btn span,
        .bk-date-btn span,
        .bk-offer-btn span {
            flex-grow: 1;
            line-height: 15px;
            display: inline-block;
            padding: 3px 48px 0px 0px;
            margin: 0 !important;
            max-width: inherit !important;
        }

        .bk-detail-location-icon {
            display: none;
        }
    }

    #bookingKitContainer .bk_ext_widgets_grid_wrapper {
        display: grid;
        grid-template-columns: repeat(7,1fr);
        display: none;
    }

    #bookingKitContainer .bk-detail-content .bk-header-calendar {
        margin-top: -47px;
    }

    #bookingKitContainer .bk-detail-content .icon-result {
        top:20px;
    }

    #bookingKitContainer .bk_ext_widgets {
        padding: 20px 0 0px;
        border-top: 0px dashed #ccc;
        border-bottom: 0px dashed #ccc;
        margin-top: 0px;
        grid-column-end: 8;
        grid-column-start: 1;
    }

    #bookingKitContainer .bk-voucher-btn,
    #bookingKitContainer.bk-portrait .bk-detail-content .bk-date-btn {
        display: flex;
        align-items: center;
        &:after {
            left:0;
            right:0;
        }
    }

    #bookingKitContainer .calendar-day-number.bk-cal-action a {
        color:#6FAC90 !important;
    }

    #bookingKitContainer .bk-date-btn.off:not(.btn_go_up) span {
        color:#757575 !important;
    }

    #bookingKitContainer .bk-detail-content .bk-detail-choose-date-title,
    #bookingKitContainer .bk-detail-content .bk-detail-pick-time-slot-title,
    #bookingKitContainer .bk-detail-content .bk-detail-choose-date-title.on.wrong,
    #bookingKitContainer .bk-detail-content .bk-detail-pick-time-slot-title.wrong {
        border-bottom: 0px !important;
        border-top: 0px !important;
    }

    #bookingKitContainer .bk-detail-content .bk-header-calendar {
        width: calc(100% - 20px);
    }

    #bookingKitContainer .bk-detail-bookingdates,
    #bookingKitContainer .bk-header-calendar {
        box-shadow: none !important;
    }

    #bookingKitContainer .bk-detail-content .bk-detail-choose-date-title,
    #bookingKitContainer .bk-detail-content .bk-detail-pick-time-slot-title {
        height: 59px;
        line-height: 59px;
    }

    #bookingKitContainer .bk-detail-content .bk-detail-choose-date-title .bk-arrow,
    #bookingKitContainer .bk-detail-content .bk-detail-pick-time-slot-title .bk-arrow {
        margin-top: 21px;
    }

    #bookingKitContainer .bk-voucher-btn .icon_button,
    #bookingKitContainer .bk-date-btn .bk-btn-icon,
    #bookingKitContainer .bk-offer-btn .bk-btn-icon {
        float: none;
        margin:0;
        padding:0;
        display: flex;
        top: 0;
        left:0;
        width: 48px;
        height: 48px;
        line-height: 48px;
        align-items: center;
        justify-content: center;
    }

    #bookingKitContainer.bk-portrait .bk-detail-buttons {
        margin-top:0;
        margin-bottom: 20px;
    }

    #bookingKitContainer.bk-portrait .bk-detail-grid {
        margin-bottom:0;
    }

    #bookingKitContainer .bk-detail-images img {
        display: block;
        margin: 0px;
    }

    #bookingKitContainer .bk-detail-location {

    }

    #bookingKitContainer .bk-detail-more-highlights h3.headColor,
    #bookingKitContainer .bk-detail-informations .bk-detail-info-item h3.headColor,
    #bookingKitContainer .bk-detail-map h3.headColor {
        color: #757575 !important;
        margin: 0px 0px 20px 0px;

    }

    #bookingKitContainer .bk-detail-highlight {
        border-top:0 !important;
    }

    #bookingKitContainer.bk-portrait .bk-detail-details-grid,
    #bookingKitContainer .bk-detail-map,
    #bookingKitContainer .bk-detail-grid .bk-detail-informations,
    #bookingKitContainer .bk-video-container-description,
    #bookingKitContainer .bk-video-container,
    #bookingKitContainer.bk-portrait .bk-detail-details-grid .bk-detail-description {
        grid-column: 1/12;
        border-top: solid 0px #848ea2;
        padding-top: 0px;
        margin-top: 0px;
    }

    #bookingKitContainer .bk-detail-more-highlights h3 .icon,
    #bookingKitContainer .bk-detail-informations .icon,
    #bookingKitContainer .bk-detail-map h3 .icon {
        height: 28px;
    }
    .betaColorFill {
        fill: #757575 !important;
    }
    #bookingKitContainer .bk-detail-image.bk-detail-images-space,
    #bookingKitContainer .bk-detail-grid .bk-detail-informations .bk-detail-info-item {
        margin-bottom: 30px;
        margin-top:0;
    }

    #bookingKitContainer * {
        color: #172540 !important;
        font-family:"Poppins",sans-serif !important;
    }
    #bookingKitContainer.bk-portrait .bk-flexible-duration .bk-detail-item {
        background: #6fac90 !important;
        display: flex;
        border-radius: 4px;
        border: none;
        height: 55px;
        margin-top: 10px;
        color: #FFF;
        padding: 3px 4px;
        align-items: stretch;
        width: calc(100% - 10px);
        justify-content: center;
        flex-direction: column;
        transition: background 200ms linear;
        &:hover {
            padding: 3px 4px;
            background: #dea99e !important;
        }
    }

    #bookingKitContainer .bk-detail-content .bk-detail-go-checkout {
        font-weight: bold;
        color:#FFF !important;
        transition: background 200ms linear;
        &:hover {
            background: #dea99e !important;
        }
    }

    #bookingKitContainer .bk-flexible-duration #bk-starting-times .bk-item-date,
    #bookingKitContainer .bk-flexible-duration #bk-starting-times .bk-item-free {
        color:#FFF !important;
    }

    #bookingKitContainer .bk-detail-locations-box .bk-detail-location-icon {
        display: none;
    }
    #bookingKitContainer .bk-flexible-duration .bk-detail-item .bk-detail-bookingdates-item .bk-item-label,
    #bookingKitContainer .bk-detail-item.bk-opening-hours .bk-detail-bookingdates-item .bk-item-label {
        display: flex;
    }


    #bookingKitContainer .bk-detail-bookingdates-item .bk-item-label .bk-item-min-price {
        text-align: right !important;
        color:#FFF !important;
        padding-top:0px !important;
    }

    #bookingKitContainer .bk-flexible-duration #bk-starting-times .bk-item-free,
    #bookingKitContainer .bk-flexible-duration #bk-starting-times .bk-item-date {
        color:#FFF;
    }


    #bookingKitContainer .bk-events-buttons a.bk-date-btn.bk-button-invisible,
    #bookingKitContainer .bk-detail-grid a.bk-date-btn.bk-button-invisible {
        opacity: 1;
        cursor: no-drop;
    }

    #bookingKitContainer .bk-detail-grid .bk-sold-out .bk-date-btn span {
        padding: 0px 48px !important;
    }

    #bookingKitContainer {
        .bk-detail-left-column {
            & > div {
                display: block;
                margin-bottom: 20px;
                margin-top:0;
                & > {
                    margin-top:0;
                    margin-bottom:0px;
                }
            }
        }
    }

}

#bkCartCt {
    display: none !important;
}

#bk-lightbox_backdrop {
    z-index: 9999999999999999 !important;
}
