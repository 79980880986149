.myex-pagination {
	display: flex;
	gap: calculateRem(20px);
	display: flex;
	justify-content: center;
	align-items: baseline;
	margin-top: calculateRem(80px);
	margin-bottom: calculateRem(80px);

	@include min-mobile {
		gap: calculateRem(30px);
	}

	&__buttonsNav {
		display: flex;
		gap: calculateRem(20px);
		align-items: center;
	}

	&__next,
	&__previous {
		@extend .myex-button--primary;
		gap: calculateRem(20px);
	}

	&__next {
		@extend .myex-button--icon-right;
		@extend .icon-ico-chevron-right;
	}

	&__previous {
		@extend .myex-button--grey;
		@extend .icon-ico-chevron-left;

		span {
			display: none;

			@include min-desktop {
				display: block;
			}
		}
	}

	&__numbers {
		display: none;
		gap: calculateRem(20px);
		align-items: center;

		@include min-mobile {
			display: flex;
		}
	}

	&__number {
		margin: 0;
		color: currentColor;
		opacity: 0.7;
		font-size: calculateRem(13px);
		font-weight: normal;
		cursor: pointer;

		&:hover {
			color: $c_third !important;
		}

		&--active-page {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-weight: 600;
			position: relative;
			opacity: 1;

			//circle
			&:after {
				content: "";
				position: absolute;
				width: calculateRem(8px);
				height: calculateRem(8px);
				border-radius: 50%;
				background-color: $c_third;
				bottom: calculateRem(-3px);
				transform: translateY(100%);
			}
		}
	}

	&__numbersSelect {
		display: flex;
		align-items: center;
		position: relative;

		@include min-mobile {
			display: none;
		}

		&__currentSelect {
			background: transparent;
			appearance: none;
			border: none;
			padding: 0;
			margin: 0;
			width: auto;
			font-weight: bold;
			color: currentColor;
		}

		&__select {
			opacity: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			padding: 0;
			    cursor: pointer;
		}
	}
	//@include mobile {
	//	display: none;
	//}
}
