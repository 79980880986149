.bloc__newsletter {
    position: relative;
    max-width: 880px;
    
    width: 96%;
    box-shadow: 0px 15px 25px #00000029;
    border: 2px solid #D8D8D8;
    border-radius: 10px;
    padding: 45px 40px;
    display: flex;
    
    flex-direction: row;
    justify-content: space-between;
    background: url('/wp-content/themes/artge/assets/images/deco/nl-encoche.svg') 20px 0px no-repeat;

    @include tablet_landscape {
        width: 100%;
    }

    
    @include mobile {
        flex-direction: column;
        height: auto;
        padding: 45px 20px 20px;
        margin-bottom: 20px;
    }
    &_content {
        width: 50%;
        @include mobile {
            width: 100%;
        }
        &__title {
            font: normal normal bold 50px/56px Poppins;
            letter-spacing: 1px;
            color: var(--darkblue);
            @include mobile {
                font-size: 36px;
                line-height: 40px;
            }
        }

        p {
            font: normal normal normal 14px/18px Poppins;
            color: var(--darkblue);

            strong {
                font-weight: bold;
            }
            @include mobile {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    &_mail {
        width: 50%;
        @include mobile {
            width: 100%;
        }
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        p {
            font: normal normal normal 9px/15px Poppins;

            a {
                text-decoration: underline;
            }
        }
        &--form {
            height: auto;
            p {
                margin: 0 !important;
            }
        }
        &--input {

            border: 1px solid #BABABA;
            border-radius: 3px;

        }

        &--submit {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 124px;
            height: 44px;
            outline: none;
            border: none;
            background-color: var(--rose);
            border-radius: 3px;
            font: normal normal medium 13px/20px Poppins;
            color: var(--white);
            transition: background-color 200ms ease-out;
            cursor: pointer;
            &:hover {
                background-color: var(--grey);
            }
            @include mobile {
                margin-top: 10px;
                position: relative;
                top: unset;
                right: unset;
                width: 100%;
            }
        }

    }

    .bloc-form input:not([type="radio"]),
    .bloc-form select,
    .bloc-form textarea {
        padding: 4px 22px;
        height: 60px;
    }
    .bloc-form form, .bloc-form .fake-form {
        height: auto;
    }
    .bloc-form .frm_switch_block  {
        margin-bottom: 0;
    }
    .bloc-form .frm_switch {
        background-color: #DCDBDA;
    }

    .bloc-form .frm_switch .frm_slider::before {
        background: var(--white);
    }
}