.component-fade-enter-active,
.component-fade-leave-active {
    transition: opacity 0.3s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
    opacity: 0;
}

// Overlay - Gestion de l'utilisateur
// ==========================================================================
.overlay-user {
    z-index: 100000000000;

    .bottom{
        @include mobile{
            flex-wrap: wrap;
            justify-content: center;
        }
        > * {
            white-space: nowrap;
            @include mobile{
                flex: none;
            }
        }

        .remove{
            @include mobile{
                margin: 0;
                order: 2;
                display: inline-block;
                margin-top: 20px;
                width: auto;
            }
        }

        .frm_submit{
            margin: 0;
            button{
                min-width: 100px;
                @include mobile{
                    font-size: calculateRem(12px);
                    padding: 10px 20px;
                }
            }
        }
    }

}

// Overlay - Authentification
// ==========================================================================
.overlay-user-authentification {
    .close-overlay{
        position: absolute;
        right: 0;
    }
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 80px;
        @include mobile{
            padding: 20px 40px;
        }
        form{
            width: 100%;
            height: auto;
            display: block;
            text-align: center;
            .title-auth{
                @include ft($f_hx, 40px, 300, $c_titre, 40px);
                letter-spacing: 0.2px;
                display: block;
                margin-bottom: 30px;
                @include mobile{
                    font-size: calculateRem(25px);
                    line-height: calculateRem(25px);
                }
                span{
                    font-size: 80px;
                    display: block;
                    margin-bottom: 27px;
                    @include mobile{
                        font-size: 60px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}


// Overlay - Première connexion
// ==========================================================================
.overlay-user-save-favoris{
    .close-overlay{
        position: absolute;
        right: 0;
    }

    .overlay-authentication-tabs.onglets {
        padding-top: 0;
        &::before {
            left: 0;
            width: 100%;
        }

        button {
            padding-top: 0;
        }
    }

    .content{
        @extend %overlay-gradient-bottom;
        .scroller{
            padding: 120px 0 0;
            text-align: center;
            @include mobile{
                padding: 75px 0 0;
            }

            .overlay-authentication-content {
                padding: 0 40px;
                @include mobile{
                    padding: 0 20px;
                }
            }
            .bloc-texte{
                margin-bottom: 50px;
                @include mobile{
                    margin-bottom: 35px;
                }
                .title-overlay{
                    @include ft($f_hx, 35px, 300, $c_titre, 40px);
                    position: relative;
                    display: block;
                    letter-spacing: 0.2px;
                    margin-bottom: 20px;
                    @include mobile{
                        font-size: calculateRem(25px);
                        line-height: calculateRem(24px);
                    }
                    &:before{
                        content: "\e916";
                        @include font();
                        position: relative;
                        display: block;
                        font-size: 80px;
                        margin: 0 auto 20px auto;
                        color: $c_third;
                        @include mobile{
                            font-size: 60px;
                            margin: 0 auto 10px auto;
                        }
                    }
                    &--pro {
                        &:before {
                            content: none;
                        }
                    }
                }
            }

            .group-btn{
                border-top: 1px solid rgba($c_grey70,0.2);
                padding: 40px 0;
                @include mobile{
                    padding: 30px 0;
                }
                &:last-child{
                    padding-bottom: 0;
                }
                p{
                    @include ft($f_hx, 18px, 600, $c_txt, 22px);
                    margin-bottom: 15px;
                }
                button, a {
                    display: block;
                    margin: 0 40px;
                    width: calc(100% - 80px);
                    padding: 21px 20px 19px;
                    @include mobile{
                        padding: 15px 20px 13px;
                        margin: 0 20px;
                        width: calc(100% - 40px);
                    }
                }

                a {
                    width: calc(100% - 120px);
                    @include mobile{
                        width: calc(100% - 80px);
                    }
                }
            }
        }
    }
}


// Overlay - Gestion du profil
// ==========================================================================
.overlay-user-dashboard{
    background: $c_bg_overlay;
    .top{
        background: none;
        &:after{
            content: none;
        }
        .title-overlay-h2{
            position: relative;
            color: $c_white;
            padding-left: 80px;
            @include mobile{
                padding-left: 60px;
            }
            &:before{
                content: "\e936";
                @include font();
                position: absolute;
                top: 38px;
                left: 40px;
                font-size: 30px;
                color: $c_main;
                @include mobile{
                    top: 20px;
                    left: 20px;
                    font-size: 30px;
                }
            }
            .sous-titre{
                color: $c_white;
            }
        }
        .close-overlay{
            color: $c_white;
        }
    }
    
    .top-btn{
        padding: 0 40px 40px 40px;
        margin-top: -5px;
        @include mobile{
            margin-top: 0;
            padding: 0 20px 20px 20px;
        }
        .btn{
            width: 100%;
            display: block;
            padding: 21px 20px 19px;
            &:hover, &:focus{
                body:not(.no-hover) & {
                    background: $c_third;
                }
            }
            @include mobile{
                padding: 15px 20px 13px;
            }
        }
    }

    .content{
        background: $c_white;
        .surtitre{
            margin-top: 40px;
            margin-bottom: 15px;
            @include mobile{
                margin-top: 25px;
                margin-bottom: 0;
            }
        }

        .th-slider-wrapper {
            margin-left: -40px !important;
            margin-right: -40px !important;
            width: calc(100% + 80px);
            padding: 0;
            @include mobile {
                margin-left: -20px !important;
                margin-right: -20px !important;
                width: calc(100% + 40px);
            }

            .nav {
                button {
                    top: calc(360px / 2);
                    @include desktop{
                        top: calc(300px / 2);
                    }
                    @include desktop1280{
                        top: calc(280px / 2);
                    }

                    &:first-child {
                        left: 40px;
                    }

                    &:last-child {
                        right: 40px;
                    }
                }
            }
        }

        .th-slider {
            margin: 20px 0 0 40px !important;
            padding: 0;
            .no-hover & {
                margin: 20px 0 0 0 !important;
                @include desktop {
                    scroll-padding: 40px;
                    padding-left: 40px;
                    padding-right: 40px;
                }
                @include mobile {
                    scroll-padding: 20px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .card {
                @include desktop{
                    width: 300px;
                }
                @include desktop1280{
                    width: 280px;
                }
                @include tablet_landscape{
                    margin-right: 20px;
                }
                @include mobile_small{
                    width: 260px;
                }
            }
        }
    }
}


// Overlay - Mes Favoris
// ==========================================================================
.overlay-user-favoris,
.overlay-user-choice-playlist {
    .scroller:after {
        content: none;
    }
}

// Overlay - Ajouter à une Playlist
// ==========================================================================
.overlay-user-choice-playlist{
    .top{
        background: $c_bg_overlay;
        .title-overlay{
            color: $c_white;
        }
        .close-overlay{
            color: $c_white;
        }
    }

    .my-card-fav{
        background: $c_bg_overlay;
        padding: 20px 40px 40px 40px;
        @include mobile{
            padding: 20px;
        }
        .card{
            @include card-horizontale();
            min-height: 120px;
            &:hover, &:focus{
                body:not(.no-hover) & {
                    > div{
                        transform: none;
                    }
                }
            }
            > div{
                &:before{
                    content: none;
                }
                figure{
                    width: 120px;
                    @include aspect-ratio(1,1);
                    &:after{
                        content: none;
                    }
                    & ~ .caption{
                        width: calc(100% - 120px);
                    }
                }

                .caption{
                    padding: 0 0 0 10px;
                    .title-card{
                        @include ft($f_hx, 14px, 600, $c_white, 18px);
                        margin: 0;
                    }
                    .tarif{
                        color: $c_white;
                        background:none;
                        padding-top:5px;
                        &.gratuit{
                            font-size: calculateRem(14px);
                            line-height: calculateRem(20px);
                            color: $c_white;
                        }
                        .price{
                            font-size: calculateRem(20px);
                            line-height: calculateRem(24px);
                            color: $c_white;
                            top: 1px;
                        }

                        .barre{
                            color: $c_white;
                        }
                    }
                    .reservable {
                        display:none;
                    }
                    .metas-card{
                        margin: 0;
                        > * {
                            color: rgba($c_white,0.7);
                            &:before{
                                color: rgba($c_white,0.7);
                            }
                            span{
                                color: rgba($c_white,0.7);
                            }
                        }
                    }
                }
            }

            .encart{
                display: none;
            }
        }
    }
}

// Overlay - Galerie
// ==========================================================================
.overlay-playlist-detail {
    z-index: 9999999999;
}

.title-list-fav{
    @include ft($f_hx, 18px, 600, $c_greydark, 24px);
    display: block;
    margin: 37px 0 12px;
    @include mobile{
        font-size: calculateRem(14px);
        line-height: calculateRem(21px);
        margin: 27px 0 4px;
    }
    span{
        font-weight: 300;
    }
}

.create-list-favoris {
    width: 100%;
    padding: 20px;
    background: $c_sub;
}

.item-favoris{
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid rgba($c_grey70,0.2);
    figure{
        width: 60px;
        height: 60px;
        margin-right: 10px;
    }
    
    .credits, figcaption{
        display: none;
    }

    .content-txt{ 
        padding: 0 20px 0 0;
        flex: 1 auto;

        &--add-this-playlist {
            max-width: 230px;
        }

        @include mobile{
            padding: 0 10px 0 0;
        }
        &:hover, &:focus{
            body:not(.no-hover) &{
                cursor: pointer;
                .title{
                    color: $c_third;
                }
            }
        }
        time{
            @include ft($f_hx, 12px, 400, $c_grey, 14px);
            text-transform: uppercase;
            letter-spacing: 1.5px;
            display: block;
            @include mobile{
                font-size: calculateRem(10px);
                line-height: calculateRem(12px);
            }
        }
        .title{
            @include ft($f_hx, 14px, 600, $c_greydark, 18px);
            display: block;
            @include addTransition();
        }
        .nb-of-fav{
            @include ft($f_hx, 14px, 300, $c_third, 20px);
            position: relative;
            display: block;
            &:after{
                content: "\e916";
                @include font();
                font-size: 15px;
                line-height: 20px;
                margin-left: 5px;
            }
        }
    }

    .actions{
        display: flex;
        align-items: center;

        button{
            @extend %appearance;
            margin: 0;
            padding: 0;
        }

        .cta-download{
            box-sizing: border-box;
            width: 40px;
            height: 40px;
            border: 1px solid rgba($c_border,0.5);
            border-radius: 4px;
            @extend %aligncenter;
            @include addTransition();
            &:hover, &:focus{
                body:not(.no-hover) & {
                    cursor: pointer;
                    background: $c_third;
                    border-color: $c_third;
                    span{
                        color: $c_white;
                    }
                }
            }
            span{
                font-size: 15px;
                color: $c_greydark;
                @include addTransition();
            }
        }

        &--add-this-playlist {
            flex-direction: column;
            margin-left: auto;
        }

        .add-this-playlist{

            &-text {
                font-size: 11px;
                text-align: center;
            }

            @include ft($f_txt, 14px, 500, $c_white, 42px);

            box-sizing: border-box;
            width: 40px;
            height: 40px;
            border-radius: 4px;
            border: none;
            background: $c_third;
            margin-bottom: 15px;

            @include addTransition();

            &:hover, &:focus{
                body:not(.no-hover) & {
                    cursor: pointer;
                    background: $c_main;
                }
            }
        }

        .modifier-favoris{
            @extend %appearance;
            background: none;
            margin: 0;
            font-size: 16px;
            color: $c_greydark;
            padding: 10px 0 10px 22px;
            @include addTransition();
            &:hover, &:focus{
                body:not(.no-hover) & {
                    cursor: pointer;
                    color: $c_third;
                }
            }
            span{
                display: block;
            }
        }
    }
}


.modale-favoris{
    @extend %center;
    background: rgba($c_greydark,0.5);
    z-index: 100;
    //display: none;
    //&.open{
    //    display: block;
    //}
    .modale-content{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background: $c_white;
        .top-modale{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title{
                @include ft($f_hx, 18px, 600, $c_greydark, 24px);
                display: block;
                padding: 38px 0 38px 40px;
                @include mobile{
                    font-size: calculateRem(14px);
                    padding: 18px 0 14px 20px;
                }
            }
        }

        input{
            @include ft($f_hx, 14px, 500, $c_surtitre, 20px);
            @extend %appearance;
            padding: 29px 20px;
            margin: 0 40px 10px;
            background: none;
            border: 1px solid rgba($c_border,0.5);
            border-radius: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
            width: calc(100% - 80px);
            @include mobile{
                margin: 0 20px 10px;
                width: calc(100% - 40px);
                padding: 19px 20px;
            }
        }

        .bottom{
            position: relative;
            &:before{
                content: none;
            }
        }
    }
}

.overlay-user-notif-message {
    position: fixed;
    width: calc(100% - 40px);
    padding: 10px 20px;
    top: 20px;
    left: 20px;
    right: 20px;
    background-color: $c_third;
    border-radius: 3px;
    color: $c_white;
    text-align: center;
    z-index: 9999999999999;
    font-size: .875rem;
    box-sizing: border-box;
}


.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

// OVERLAY DETAIL D'UNE PLAYLIST
.overlay-playlist-detail{
    .top{
        background: $c_greydark;
        .title{
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 80px;
            @include desktop1280{
                padding-left: 40px;
            }
            @include mobile{
                padding-left: 20px;
            }
            span{
                @include ft($f_hx, 18px, 600, $c_white, 22px);
                @include mobile{
                    font-size: calculateRem(12px);
                    line-height: calculateRem(14px);
                }
                .title-overlay{
                    @include ft($f_hx, 30px, 300, '', 35px);
                    letter-spacing: 0.2px;
                    padding: 0;
                    @include mobile{
                        font-size: calculateRem(20px);
                        line-height: calculateRem(25px);
                        margin-top: -2px;
                        display: block;
                    }
                }
            }
        }

        .playlist-detail-btn-telecharger{
            @include tablet_portrait{
                display: none;
            }
        }
    }

    .close-overlay{
        color: $c_white;
    }

    .content{
        &:before{
            content: '';
            position: absolute;
            top: 20px;
            left: 0;
            right: 0;
            height: 20px;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,202529+100&1+0,0+100 */
            background: -moz-linear-gradient(top,  rgba(32,37,41,1) 0%, rgba(32,37,41,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(32,37,41,1) 0%,rgba(32,37,41,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(32,37,41,1) 0%,rgba(32,37,41,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#202529', endColorstr='#00202529',GradientType=0 ); /* IE6-9 */
            z-index: 100;
            width: calc(100% - 500px);
            @include desktop1280{
                width: calc(100% - 450px);
            }
            @include tablet_landscape{
                width: calc(100% - 350px);
            }
            @include tablet_portrait{
                width: 100%;
                top: 0;
            }
        }
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 19px;
            background: $c_greydark;
            border-top: 1px solid rgba($c_border,0.5);
            z-index: 100;
            @include tablet_portrait{
                content: none;
            }
        }
    }

    .scroller{
        padding: 0;
        // Partie de gauche
        .has-no-results, .wrapper-listing-cards{
            position: relative;
            float: left;
            width: calc(100% - 500px);
            @include desktop1280{
                width: calc(100% - 450px);
            }
            @include tablet_landscape{
                width: calc(100% - 350px);
            }
            @include tablet_portrait{
                width: 100%;
                float: none;
                margin: 0;
                margin-top: 20px;
                height: auto;
            }
            @include mobile{
                margin-top: 10px;
            }
        }
    }

    // Si pas de résultats
    .has-no-results{
        height: 100%;
        @extend %aligncenter;
        @include tablet_portrait{
            height: auto;
        }
        .no-results{
            text-align: center;
            padding: 40px;
            @include mobile{
                padding: 20px;
            }
            [class^="icon-"], [class*=" icon-"] {
                font-size: 80px;
                color: $c_third;
                margin: 0 auto 28px auto;
                display: block;
                @include mobile{
                    font-size: 60px;
                    margin: 0 auto 20px auto;
                }
            }
            .title{
                @include ft($f_hx, 40px, 300, $c_white, 40px);
                display: block;
                letter-spacing: 0.2px;
                margin-bottom: 10px;
                @include mobile{
                    font-size: calculateRem(25px);
                    line-height: calculateRem(25px);
                }
            }
            .txt-rose{
                @include ft($f_hx, 25px, 600, $c_third, 26px);
                display: block;
                @include mobile{
                    font-size: calculateRem(18px);
                    line-height: calculateRem(22px);
                }
            }
        }
    }

    // Listing des cards
    .wrapper-listing-cards{
        position: relative;
        padding: 5px 70px;
        margin-top:20px;
        @include desktop1280{
            padding: 5px 30px;
        }
        @include tablet_portrait{
            padding: 0 30px;
        }
        @include mobile{
            padding: 0;
        }
        &:after {
            content: '';
            position: relative;
            display: block;
            width: 100%;
            height: 170px;
            clear: both;
        }
        > * {
            position: relative;
            box-sizing: border-box;
            float: left;
            padding: 0 10px;
            width: 33.33%;
            margin: 15px auto;
            &:nth-child(3n+1){
                clear: left;
            }
            @include wide{
                width: 25%;
                &:nth-child(3n+1){
                    clear: none;
                }
                &:nth-child(4n+1){
                    clear: left;
                }
            }
            @media screen and (max-width: 1200px){
                width: 50%;
                &:nth-child(3n+1){
                    clear: none;
                }
                &:nth-child(odd){
                    clear: left;
                }
            }
            @include tablet_portrait{
                width: 33.33%;
                &:nth-child(odd){
                    clear: none;
                }
                &:nth-child(3n+1){
                    clear: left;
                }
            }

            @media screen and (max-width: 800px){
                width: 50%;
                &:nth-child(3n+1){
                    clear: none;
                }
                &:nth-child(odd){
                    clear: left;
                }
            }

            @include mobile{
                width: 100%;
                float: none;
                @include card-horizontale();
                padding: 0 20px;
                margin: 10px 0;
            }
        }

        .card{
            > div {
                position:initial;
                &:before {
                    content:none;
                }
                &:hover {

                }
            }
            &:hover {
                > div {
                    transform:none !important;
                }
            }

            .caption{
                .title-card {
                    color: $c_white;
                }
                .location, .wrapper-cat span{
                    color: $c_light_txt;
                }
                .tarif{
                  //  color: $c_white;
                    @include mobile {
                        background:none;
                        color:$c_white;
                        > * {
                            color:$c_white;
                        }
                    }
                    &.gratuit{
                        color: $c_sub;
                    }
                    .barre, span{
                      //  color: $c_white;
                    }
                }

                .reservable {
                    bottom:0;
                    @include mobile {
                        display:none;
                    }
                }
            }
        }

    }

    // Map de droite
    .wrapper-map{
        position: absolute;
        top: 1px;
        right: 0;
        bottom: 0;
        float: left;
        width: 500px;
        height: 100%;
        z-index: 2000;
        background: $c_main;
        @include desktop1280{
            width: 450px;
        }
        @include tablet_landscape{
            width: 350px;
        }
        @include tablet_portrait{
            display: none;
        }
    }

    // Bottom Fixe filtres / map tablette portrait + mobile
    .bottom{
        display: none;
        background: $c_greydark;
        @include tablet_portrait{
            display: flex;
            justify-content: space-between;
        }
        &:before{
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e2529+0,1e2529+100&0+0,1+100 */
            background: -moz-linear-gradient(top,  rgba(30,37,41,0) 0%, rgba(30,37,41,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(30,37,41,0) 0%,rgba(30,37,41,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(30,37,41,0) 0%,rgba(30,37,41,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e2529', endColorstr='#1e2529',GradientType=0 ); /* IE6-9 */
        }

        > * {
            position: relative;
            margin: 0;
            flex: none;
            width: calc(50% - 10px);
            min-width: calc(50% - 10px);
            font-size: calculateRem(15px);
            padding: 21px 20px 19px;
            @include mobile{
                width: calc(50% - 5px);
                min-width: calc(50% - 5px);
                font-size: calculateRem(12px);
                padding: 11px 15px 9px;
            }
            @include mobile_small{
                padding: 11px 10px 9px;
            }
        }

        .btn-map{
            text-align: left;
            @include mobile_small{
                text-align: center;
            }
            &:after{
                position: absolute;
                right: 20px;
                top: 50%;
                margin-top: -10px;
                @include mobile{
                    right: 15px;
                    font-size: 14px;
                    line-height: 14px;
                    margin-top: -7px;
                }
                @include mobile_small{
                    content: none;
                }
            }
        }

        .playlist-detail-btn-telecharger{
            background: $c_sub;
            left: 0;
            text-align: right;
            @include mobile_small{
                text-align: center;
            }
            &:before{
                top: 50%;
                position: absolute;
                left: 20px;
                margin-top: -10px;
                @include mobile{
                    left: 15px;
                    font-size: 14px;
                    line-height: 14px;
                    margin-top: -7px;
                }
                @include mobile_small{
                    content: none;
                }
            }
        }
    }

    // Si pas de résultats
    .has-no-results{
        height: 100%;
        width: 100%;
        @extend %aligncenter;
        .no-results{
            text-align: center;
            padding: 40px;
            @include mobile{
                padding: 20px;
            }
            [class^="icon-"], [class*=" icon-"] {
                font-size: 80px;
                color: $c_third;
                margin: 0 auto 28px auto;
                display: block;
                @include mobile{
                    font-size: 60px;
                    margin: 0 auto 20px auto;
                }
            }
            .title{
                @include ft($f_hx, 40px, 300, $c_white, 40px);
                display: block;
                letter-spacing: 0.2px;
                margin-bottom: 10px;
                @include mobile{
                    font-size: calculateRem(25px);
                    line-height: calculateRem(25px);
                }
            }
            .txt-rose{
                @include ft($f_hx, 25px, 600, $c_third, 26px);
                display: block;
                @include mobile{
                    font-size: calculateRem(18px);
                    line-height: calculateRem(22px);
                }
            }
        }
    }
}