.myex-notification {
	$self: &;
	@extend .bg--light;
	opacity: 0;
	z-index: $-z-overlay;
	position: fixed;
	bottom: calculateRem(15px);
	max-width: calculateRem(350px);
	width: calc(100% - #{calculateRem(30px)});
	display: flex;
	align-items: center;
	justify-content: center;
	padding: calculateRem(19px) calculateRem(25px);
	box-shadow: 0 calculateRem(10px) calculateRem(30px) rgba(30, 37, 41, 0.1);
	animation-duration: 1s;
	animation-fill-mode: both;
	right: calculateRem(15px);
	gap: calculateRem(10px);
	pointer-events: none;

	@include min-mobile {
		top: calc(var(--header-height) + #{calculateRem(54px)});
		right: var(--side-padding-base);
		bottom: unset;
		left: unset;
		padding: calculateRem(20px) calculateRem(20px);
	}

	&.fadeInBottom {
		animation-name: fadeInBottom;
		pointer-events: all;
	}

	&.fadeOutTop {
		animation-name: fadeOutTop;
	}

	// collection
	&__image {
		width: calculateRem(82px);
		border-radius: calculateRem(4px);
		flex-shrink: 0;
		@include aspect-ratio(82px, 55px);
	}

	&__text {
		line-height: 1.3;
		font-size: calculateRem(11px);
		font-weight: 600;

		@include min-mobile {
			font-size: calculateRem(13px);
		}
	}

	&__link {
		line-height: 1.3;
		font-weight: 600;
		color: $c_third;
		text-decoration: underline;
		font-size: calculateRem(11px);
		cursor: pointer;
		transition: all ease 500ms;

		&:hover {
			opacity: 0.8;
			text-decoration: none;
		}

		@include min-mobile {
			font-size: calculateRem(13px);
		}
	}

	&__icon {
		margin-left: calculateRem(5px);
		font-size: calculateRem(12px);
		display: none;
		&:before {
			@include font();
		}

		&--valid {
			@extend .icon-ico-check-circle;
			color: #51be7b;
			display: inline-block;
		}

		&--notvalid {
			@extend .icon-circle-xmark;
			color: red;
		}
	}

	&.favorites_notif_explorer {
		#{$self} {
			&__image {
				width: calculateRem(45px);
				border-radius: 50%;
				@include aspect-ratio(45px, 45px);
			}
		}
	}

	//notif
	&.update_user, &.update_avatar {
		max-width: calculateRem(305px);
		justify-content: start;

		#{$self} {
			&__image {
				width: calculateRem(35px);
				border-radius: 50%;
				@include aspect-ratio(35px, 35px);
			}

			&__icon--valid {
				display: inline-block;
			}
			&__link {
				display: none;
			}
		}
	}
}

@keyframes fadeInBottom {
	from {
		opacity: 0;
		transform: translateY(-100%);
	}
	to {
		opacity: 1;
	}
}

.fadeOutTop {
	animation-name: fadeOutTop;
}
@keyframes fadeOutTop {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
