// ---- card collection inside slider
.myex-splide-slider .myex-card-collection {
	flex-direction: column;

	&__avatar {
		width: 100%;
	}

	&__meta {
		flex-direction: row;
	}
}

// ---- Card collection
.myex-card-collection {
	display: flex;
	row-gap: calculateRem(10px);
	column-gap: calculateRem(15px);
	color: currentColor;
	@include card-img-hover();

	@include min-mobile {
		flex-direction: column;
	}

	&:hover,
	&:focus {
		color: currentColor !important;
	}

	&__avatar {
		border-radius: calculateRem(10px);
		overflow: hidden;
		position: relative;
		width: calculateRem(120px);
		flex-shrink: 0;
		background: var(--bg-card-img);

		@include aspect-ratio(120px, 120px);

		@include min-mobile {
			width: 100%;
		}

		.myex-button--favorite {
			position: absolute;
			left: calculateRem(15px);
			bottom: calculateRem(15px);
			z-index: 2;
		}
	}

	&__title-on-card {
		position: absolute !important;
		height: 100%;
		align-content: center;
		font-size: 30px;
		font-weight: 700;
		padding: 0 35px 0 25px;
		bottom: 0;
		overflow-wrap: anywhere;

		@include mobile {
			font-size: 16px;
			height: 100%;
			max-width: 120px;
			padding: 0 25px 0 10px;
			margin: unset;
		}
	}

	&__content {
		display: flex;
		gap: calculateRem(4px);
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		text-align: left;
		justify-content: center;
		flex-grow: 1;
	}

	&__wrapper-title {
		display: flex;
		gap: calculateRem(6px);
		align-items: center;
		width: 100%;
	}

	&__title {
		font-size: calculateRem(14px);
		font-weight: 600;
		line-height: 1.125;
		color: currentColor;
		margin: 0;
		transition: all ease 500ms;
		@include ellipsis(2);
		width: 100%;

		@include min-mobile {
			font-size: calculateRem(16px);
		}

		a:hover & {
			opacity: 0.9;
		}
	}

	&__meta {
		display: flex;
		row-gap: calculateRem(2px);
		column-gap: calculateRem(15px);
		margin: 0;
		color: var(--color-text-lighter);
		font-size: calculateRem(11px);
		flex-direction: column;
		line-height: 1.5;

		@include min-mobile {
			flex-direction: row;
		}

		&__item {
			@include ellipsis(1);
			letter-spacing: 0.04em;

			&.myex-text-icon--superexplorer span {
				color: var(--color-text-lighter);
			}
		}
	}
}
