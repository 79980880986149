.bloc-faq {

    @include mobile {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .bloc.bloc-faq__header {
        padding: 0;
        margin-bottom: 20px !important;

        h2 {
            @include mobile {
                font-size: 18px;
                line-height: 18px;
                margin-bottom: 10px;
            }
        }
    }

    &__content {
        background-color: rgba($c_main, 0.1);
        margin-bottom: 40px;
        .bloc-texte {
            p {
                font-family: 'Libre Baskerville', Times, serif;
            }
        }
        .bloc.bloc-small {
            @include mobile {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
    }

    .accordeon {
        margin-bottom: 60px;
        @include mobile {
            margin-bottom: 35px;
        }

        &:last-child {
            margin-bottom: 0;
            .bloc-texte {
                
                padding-bottom: 0;
            }
        }

        &.open {
            .title-accordeon {
                @include mobile {
                    &:after {
                        transform: rotate(180deg);
                    }
                }

                .see-more {
                    span {
                        display: none;

                        & + span {
                            display: block;
                        }
                    }
                }
            }

            .item {
                max-height: 100% !important;
                button{
                    display: none;
                    @include tablet_portrait{
                        display: block;
                    }
                }
            }
        }


    }

    &__share {
        display: flex;
        flex-wrap: wrap;

        &__text-content {
            display: flex;
            width: 50%;

            @include desktop1200 {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        &__icon {
            margin-right: 28px;
            font-size: 50px;
            color: $c_main;
        }

        &__title {
            line-height: 55px;
            margin-bottom: 0;
        }

        &__text {
            
            p:last-child {
                margin-bottom: 0;
            }
        }

        &__buttons {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            flex: 1;
            padding-bottom: 10px;

            @include desktop1200 {
                justify-content: center;
                flex-wrap: wrap;
            }

            .btn {
                display: inline-flex;
                justify-content: center;
                margin-left: 10px;
                padding: 16px 28px 15px;
                min-width: 0;
                font-size: 16px;

                @include mobile {
                    margin-bottom: 10px;
                }

                &:first-child {
                    margin-left: 0;
                }


                i {
                    margin-right: 10px;
                    font-size: 16px;
                }

                &--facebook {
                    background-color: $c_facebook;
                }

                &--twitter {
                    background-color: $c_twitter;
                }
            }
        }
    }
}