
.post-type-archive-itineraires {
    background-color: #20252A;
}

.page-listing-itineraires {
    --border-gutter: 80px;
    padding-bottom: 40px;
    background-color: #20252A;

    @include desktop {
        --border-gutter: 40px;
    }

    @include mobile {
        --border-gutter: 20px;
    }

    .ancres-itinerance {
        justify-content: flex-start;

        &::before {
            content: none;
        }

        .menu-ancres-itinerance {
            justify-content: flex-start;
        }

    }

    .social-share {
        transform: translateX(-100%);

        .toggle {
            background-color: #20252A;
            color: white;
        }
    }

}


// Cover
// ==========================================================================

.cover-listing-itineraires {
    padding: 220px var(--border-gutter) 50px;
    //@include bd(white,1);

    @include tablet_portrait {
        padding-top: 160px;
    }

    @include mobile {
        padding-top: 120px;
    }

    .cover-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .cover-title {
        max-width: 740px;
    }
    
    .cover-text {
        > * {
            color: $c_white !important;
        }
    }
}


.wrapper-listing-itineraires {
    display: flex;
    flex-direction: column;

    .filters-deplacements {

    }
}


.listing-itineraires {
    padding-top: 70px;

    @include mobile {
        padding-top: 50px;
    }

    > * {
        margin-bottom: 160px;

        @include tablet_portrait {
            margin-bottom: 100px;
        }

        @include mobile {
            margin-bottom: 60px;
        }
    }
}