.myex-slider-fake {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	overflow: visible;
	width: 100%;
	gap: calculateRem(20px);
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;


}
