.myex-card-fake {
	width: calculateRem(220px);
	flex-shrink: 0;
	$color: 414548;
	position: relative;
	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(#1e2529 0%, rgba(30, 37, 41, 0) 100%);
		opacity: 0.9;
		z-index: 1;
	}

	&__img {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23#{$color}' stroke-width='6' stroke-dasharray='12%2c 10' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
		opacity: 0.5;
		border-radius: calculateRem(10px);
		margin-bottom: calculateRem(10px);
		display: block;
		@include aspect-ratio(220px, 220px);
	}

	&__title {
		background: #414548;
		height: calculateRem(20px);
		border-radius: calculateRem(5px);
		margin-bottom: calculateRem(6px);
		display: block;
	}

	&__text {
		background: #303336;
		height: calculateRem(20px);
		width: 80%;
		border-radius: calculateRem(5px);
		display: block;
	}
}
