.card-offre {
	.myex-button--favorite {
		position: absolute;
		left: calculateRem(15px);
		bottom: calculateRem(15px);
		z-index: 2;
	}

	.myex-card-offre__wrapper-title {
		display: flex;
		gap: calculateRem(6px);
		align-items: center;
		width: 100%;
	}
}
