.bloc-accordeon {
    background-color: rgba($c_main, 0.1);
    max-width: 1100px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-top: 0;
    padding-bottom: 0;

    @include mobile {
        margin: 20px 15px !important;

        .bloc.bloc-small {
            margin: 0 !important;
        }

    }
    
    .accordeon {
        margin-bottom: 45px;
        @include mobile {
            margin-bottom: 35px;
        }

        &:last-child {
            margin-bottom: 0;
            .bloc-texte {
                padding-bottom: 0;
            }
        }

        &.open {
            .title-accordeon {
                
                @include mobile {
                    &:after {
                        transform: rotate(180deg);
                    }
                }

                .see-more {
                    span {
                        display: none;

                        & + span {
                            display: block;
                        }
                    }
                }
            }

            .item {
                max-height: 100%;
                button{
                    display: none;
                    @include tablet_portrait{
                        display: block;
                    }
                }
            }

            @include mobile {
                margin-bottom: 0;
            }
        }

        .title-accordeon {
            @include ft($f_txt, 20px, 300, $c_txt, 18px);
            font-weight: 600;
            @extend %appearance;
            background: none;
            position: relative;
            display: block;
            text-align: left;
            width: 100%;
            padding: 5px 100px 14px 0;
            border-bottom: 1px solid rgba($c_grey70, 0.2);
            @include addTransition();

            &:hover, &:focus {
                body:not(.no-hover) & {
                    cursor: pointer;
                    color: $c_txt;
                }
            }

            @include mobile {
                font-size: calculateRem(14px);
                line-height: calculateRem(16px);
                padding-bottom: 10px;
            }
        }

        .see-more {
            position: absolute;
            right: 0;
            bottom: 12px;
            font-size: 14px;

            @include mobile {
                font-size: 12px;
            }

            span {
                & + span {
                    display: none;
                }
            }
        }

        .item {
            max-height: 0;
            overflow: hidden;

            &.anim {
                @include addTransition();
            }

            .bloc-texte {
                padding: 30px 0 25px 0;
                p {
                    font-family: 'Libre Baskerville', Times, serif;
                }
                @include mobile {
                    padding: 20px 0;
                }
            }
        }
    }
}