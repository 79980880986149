.myex-missing-data {
	text-align: center;
	z-index: 2;
	width: 100%;
	padding: 0 calculateRem(25px);
	max-width: calculateRem(780px);
	margin: 0 auto;

	&__icon {
		font-size: calculateRem(60px);
		color: var(--color-primary);
		margin-bottom: calculateRem(11px);
		display: block;

		@include min-mobile {
			font-size: calculateRem(77px);
		}
	}

	&__title {
		font-size: calculateRem(25px);
		font-weight: 600;
		margin: 0;
		color: currentColor;
		line-height: 1.1;
		max-width: calculateRem(700px);
		text-align: center;
		margin-left: auto;
		margin-right: auto;

		@include min-mobile {
			font-size: calculateRem(40px);
		}
	}

	&__text {
		font-size: calculateRem(13px);
		margin: calculateRem(3px) auto 0 auto;
		max-width: calculateRem(460px);
		line-height: 1.46;

		@include min-mobile {
			font-size: calculateRem(15px);
			margin: calculateRem(7px) auto 0 auto;
		}
	}

	&__link {
		margin-top: calculateRem(20px);
		display: inline-flex;

		@include min-mobile {
			margin-top: calculateRem(30px);
		}
	}

	&__form {
		margin-top: calculateRem(16px);
	}
}
