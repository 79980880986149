#zoombox .zoombox_gallery img {
	display: inline-block;
}

.bloc-galerie{

	.gallery {
		position: relative;
		@include clearfix;
		.wrapper-picture {
			.one, .half, .three {
				float: left;
				background-color: $c_dark;
				text-align: center;

				figure {
					width: 100%;
					height: 100%;
					@extend %center;
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.one {
				width: 100%;
				@include aspect-ratio(785, 440);
			}

			.half {
				width: 50%;
				@include aspect-ratio(393, 265);
			}

			.three {
				width: 33.33%;
				@include aspect-ratio(258, 175);
			}

			p {
				position: absolute;
				top: 50%;
				left: 50%;
				margin: -30px 0 0 -30px;
				strong {
					font-size: 35px !important;
				}
				color: $c_white;
			}
		}

		a {
			img {
				@include addTransition();
			}
			&:hover, &:focus {
				body:not(.no-hover) & {
					img {
						opacity: 0.6;
					}
				}
			}
		}

	}

	@include mobile{
		.gallery {
			.wrapper-picture a.half {
				width: 100%;
			}

			.wrapper-picture a.three {
				width: 100%;

			}
		}
	}

}