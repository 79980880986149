.bloc-relation-map {
    padding-left: 0;
    padding-right: 0;

    .bloc-relation-map-title {
        margin-bottom: 15px;
        padding-left: 0;

        h3 {
            font-size: 40px;
            @include tablet_portrait {
                font-size: 25px;
            }
        }
    }

    .splide-relation-map {

        .splide__list {
            height: auto !important;
        }

        .bloc-texte {
            margin-bottom: 30px;

            @include mobile {
                margin-bottom: 20px;
            }
        }

        .map {
            position: relative;
            z-index: 10;
            width: 100%;
            height: 382px;
            background: #4B5155;
        }

        
        &.splide:not(.is-overflow) .splide__pagination, .splide:not(.is-overflow) .splide__arrow {
            display: flex;
        }
        
        .splide-wrapper.with-map {
            margin-top: -150px;
            height: auto !important;

            @include mobile {
                margin-top: 10px;
            }

            .splide__pagination {
                @include tablet_portrait {
                    display: none;
                }
            }

            .splide__slide {

                @include mobile {

                    a {
                        div:first-child {
                            display: flex;
                            width: 100%;

                            figure, .no-photo {
                                max-width: 105px;
                                height: 70px;
                                margin-right: 10px;
                            }

                            .caption {
                                margin-top: 0;

                                .encart {
                                    left: 28px;
                                    top: 6px;
                                    right: unset;
                                }
                            }
                        }

                        &.edito-v2 {

                            div:first-child {
                                align-items: center;

                                .caption {
                                    text-align: left;
                                    position: unset;
                                    transform: unset;

                                    & > * {
                                        color: #172540;
                                    }

                                    &-link {
                                        margin-top: 15px;
                                    }

                                    &:after {
                                        left: unset;
                                        bottom: unset;
                                        transform: unset;
                                    }
                                }
                            }

                        }
                    }
                }

                
            }
        }


        .splide__track {
            padding-bottom: 20px;
            margin: auto;
            z-index: 1000;

            @include tablet_portrait {
                padding-bottom: 0;
            }
        }

        /*.relation-map-cta-desktop {
            position: absolute;
            right: 80px;
            top: 0;

            @include tablet_portrait {
                display: none;
            }
        }*/


        .splide__arrow {
            top: unset;
            bottom: -22px;
            background-color: transparent;

            svg {
                fill: #627A89;
            }

            &--prev {
                left: unset;
                right: 11vw;
            }

            &--next {
                right: 8vw;
            }

            span:before {
                font-size: 18px;
            }

            @include tablet_portrait {
                display: none !important;
            }
        }

        .class-pagination {
            position: static;
            display: flex;
            flex-wrap: nowrap;
            padding-left: 0;
            padding-right: 0;
            justify-content: left;
            width: 88%;
            padding-top: 20px; 

            @include tablet_portrait {
                width: 100%;
                padding-bottom: 20px;
            }

            li {
                width: 100%;

                &:last-child {
                    button:not(.is-active) {
                        background: linear-gradient(90deg, rgba(#ABB3B9, 0.5) 0%, rgba(#ABB3B9, 0.5) 60%, rgba(255, 255, 255, 0) 100%);
                    }
                }
            }
        }
        
        .class-page {
            margin: 0;
            height: 1px;
            border-radius: 8px;
            width: 100%;
            background-color: rgba(#ABB3B9, 0.5);
            &.is-active {
                height: 5px;
                background-color: #DEA99E;
                opacity: 1;
                transform: unset;
                padding-bottom: 2px;
            }
        }
    }

    .splide__slide {
        &.splide__slide-cta {
            padding: 41px;
            display: flex;
            width: 300px;
            background-color: $c_third;
            flex-direction: column;
            justify-content: center;
            border-radius: 10px;
            text-align: left;

            span {
                color: $c_white !important;
                font-size: 25px;
                line-height: 30px;
                font-weight: 600;
            }
            &.slide-s {
                height: 273px !important;
            }

            &.slide-m {
                height: 285px !important;
            }

            &.slide-l {
                height: 430px !important;
            }

            button {
                margin-top: 22px;
                border: 1px solid white;
                color: $c_white;
                background-color: transparent;
                border-radius: 4px;
                padding: 13px 40px;
                font-weight: 600;
            }
        }
    }

    .relation-map-cta-mobile {
        display: none;
        position: relative;
        width: 100%;
        box-sizing: border-box;

        @include tablet_portrait {
            display: inline-block;
        }
    }

    .splide-relation-map-header {
        display: flex;

        &__title-tabs {
            width: 70%;
            flex-shrink: 0;

            @include tablet_portrait {
                width: 100%;
            }

            p {
                margin-bottom: 0;
            }
        }

        .cta-desktop-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;
            margin-bottom: 25px;

            @include tablet_portrait {
                display: none;
            }
        }
    }
}


