#regiondo-widget {

  &.regiondo-booking-widget {

    /*
    --regiondo-padding: 20px;
    --regiondo-font: inherit;
    --regiondo-font-size: 16px;
    --regiondo-font-size-large: 20px;
    --regiondo-font-size-small: 14px;
    --regiondo-font-line-height: 1.4;
    --regiondo-background-normal: transparent;
    --regiondo-background-lowlight: #fff;
    --regiondo-border-normal: #dadce0;
    --regiondo-border-lowlight: #ecedef;
    --regiondo-tab-color: #444f60;
    --regiondo-tab-text: #ffffff;
    --regiondo-field-border: #ecedef;
    --regiondo-field-focus: #444f60;
    --regiondo-text-normal: #404040;
    --regiondo-text-highlight: #e7eaef;
    --regiondo-link-normal: #e7eaef;
    --regiondo-infobox: #f5f6f7;
    --regiondo-button-normal: #64b473;
    --regiondo-button-normal-text: #ffffff;
    --regiondo-secondary-button: transparent;
    --regiondo-secondary-button-text: #71be7e;
    --regiondo-price: #71be7e;
    --regiondo-calendar-text: #404040;
    --regiondo-calendar-available: #64b473;
    --regiondo-calendar-high-demand: #fbb649;
    --regiondo-calendar-sold-out: #ec3737;
    --regiondo-calendar-selected: #444f60;
    --regiondo-calendar-selected-text: #ffffff;
    --regiondo-msg-info: #404040;
    --regiondo-msg-error: #ec3737;
    --regiondo-msg-success: #71be7e;
  
    --regiondo-infobox: #ffffff;
    --regiondo-background-lowlight: #ffffff;
    --regiondo-button-normal: #caa85b;
    --regiondo-button-normal-text: black;
    --regiondo-calendar-selected: #caa85b;
    --regiondo-calendar-selected-text: black;
    --regiondo-tab-color: #000000;
    --regiondo-tab-color-text: white;
    --regiondo-font-size: 16px;
    --regiondo-font-size-large: 20px;
    --regiondo-font-size-small: 14px;
    --regiondo-font-line-height: 1.4;
    --regiondo-padding: 20px;
    --regiondo-font: "Open Sans";
    */


    /* Couleur custom global */
    --regiondo-calendar-selected: #6FAC90!important;
    --regiondo-calendar-selected-text: #fff!important;

    --regiondo-tab-color: #7A97AA!important;
    --regiondo-link-normal: #7A97AA!important;

    --regiondo-button-normal: #6FAC90!important;
    --regiondo-button-normal-text: #fff!important;
  }


  /* Couleur de la tabs */
  .regiondo-tabs__tab {
    color:#7A97AA;
  }
  .regiondo-tabs__tab--active {
    color:#fff;
  }

  /* retrait des espacement a droite et gauche */
  .regiondo-tabs__nav,
  .regiondo-intro-text,
  .regiondo-step__header,
  .regiondo-step__inner,
  .regiondo-step__content,
  .regiondo-total,
  .regiondo-cart,
  .regiondo-checkout-contact,
  .regiondo-checkout-payment,
  .regiondo-checkout-totals
  {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }


  /* Gestion de la quantité + et - */
  .regiondo-qty__minus, .regiondo-qty__plus {
    border-color:#7a97aa;
    width: 100%;
    background: #7a97aa;
    border-radius: 0;
  }
  .regiondo-qty__plus {
    border-radius: 0px 0px 3px 3px;
  }
  .regiondo-qty__minus {
    margin-right: 0;
    border-radius: 3px 3px 0px 0px;
  }
  .regiondo-qty select {
    border-color:#7A97AA;
    color:#7A97AA;
    width: 100%;
    border-radius: 0px;
  }
  .regiondo-qty__minus:before, .regiondo-qty__plus:before {
    background:#fff;
  }
  .regiondo-qty__minus:after, .regiondo-qty__plus:after {
    background:#fff;
  }

  /* Fix un soucis de scroll de gauche a droite */
  .regiondo-fade-out {
    max-width: 100%;
  }

  /* Fix background color */
  div.regiondo-widget, div.regiondo-widget > div.regiondo-content > div.regiondo-checkout-contact > div.regiondo-form > div.regiondo-form-fields > div.regiondo-field > div.regiondo-label{
    background-color: #fff !important;
  }
}