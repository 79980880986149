
.myex-my-account {

	&__top {
		margin-bottom: calculateRem(30px);
		@include min-mobile {
			margin-bottom: calculateRem(60px);
		}
	}

	//------- header
	&__header {
		display: flex;
		gap: calculateRem(20px);
		align-items: center;

		&__avatar {
			min-width: calculateRem(85px);
			max-width: calculateRem(130px);
			width: 25%;
			position: relative;
			flex-shrink: 0;

			&__wrapper-img {
				background: var(--bg-card-img);
				border-radius: 50%;
				@include aspect-ratio(35, 35);
			}

			&-edit {
				position: absolute;
				bottom: 10%;
				right: -8%;
			}
		}

		&__summary {
			display: flex;
			flex-direction: column;
			gap: calculateRem(4px);
			align-items: flex-start;
		}

		&__title {
			font-size: calculateRem(14px);
			font-weight: normal;
			color: currentColor;
			margin: 0;
			line-height: 1.5;
			text-transform: uppercase;

			@include min-mobile {
				font-size: calculateRem(18px);
			}
		}

		&__name {
			margin: 0;
			font-size: calculateRem(25px);
			font-weight: 600;
			line-height: 1;
			color: currentColor;
			position: static; // bug fix on button not clickable

			@include min-mobile {
				font-size: calculateRem(40px);
			}
		}

		//link edit
		&__edit {
			font-size: calculateRem(12px);
			font-weight: 600;
			line-height: 1.25;
			appearance: none;
			border: none;
			color: currentColor;
			background: transparent;
			padding: 0;
			cursor: pointer;

			&:hover {
				color: var(--hover-link);
			}
		}
	}

	//------- form
	&__form {
		margin-top: calculateRem(30px);

		#myex-my-account__account-info {
			max-width: calculateRem(420px);
		}

		#myex-account-avatar {
			display: none;
		}

		//btn submit
		.myex-my-account-save {
			width: 100%;
			@include min-mobile {
				width: auto;
			}
		}
	}

	&__framed {
		.myex-explorer__top {
			padding-left: 0;
		}
	}

	.myex-my-account-save-loader {
		width: 25px;
		height: 25px;
		border: 3px solid #FFF;
		border-bottom-color: transparent;
		border-radius: 50%;
		display: none;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;

		&.on-load {
			display: inline-block;
		}
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.myex-my-account-save-text {
		&.on-load {
			display: none;
		}
	}
}
