.myex-autocomplete-list {
	background: var(--bg-field);
	border-radius: calculateRem(4px);
	overflow: hidden;
	padding-top: calculateRem(10px);
	padding-bottom: calculateRem(10px);
	position: absolute;
	bottom: calculateRem(-10px);
	transform: translateY(100%);
	z-index: 2;
	width: 100%;

	div {
		padding: calculateRem(10px) calculateRem(20px);
		transition: color 0.3s;
		cursor: pointer;
		line-height: 1.1;
		font-size: var(--field-font-size);

		&:hover {
			color: var(--color-primary);
		}
	}
}
