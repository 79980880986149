// ---- Card collection
.myex-card-collection-notif {
	position: relative;
	display: flex;
	row-gap: calculateRem(12px);
	column-gap: calculateRem(15px);
	color: currentColor;
	cursor: pointer;
	@include card-img-hover();
	

	&:hover,
	&:focus {
		color: currentColor !important;
	}

	&__avatar {
		position: relative;
		width: calculateRem(100px);
		flex-shrink: 0;

		.is-new-notif & {
			@include style-dot-notif-card();
		}

		&__wrapper-img {
			border-radius: calculateRem(10px);
			background: var(--bg-card-img);
			@include aspect-ratio(100px, 100px);
		}
	}

	&__content {
		display: flex;
		gap: calculateRem(7px);
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		text-align: left;
		justify-content: center;
		flex-grow: 1;
	}

	&__wrapper-title {
		display: flex;
		gap: calculateRem(6px);
		align-items: center;
		width: 100%;
	}

	&__title {
		font-size: calculateRem(14px);
		font-weight: 600;
		line-height: 1.125;
		color: currentColor;
		margin: 0;
		transition: all ease 500ms;
		@include ellipsis(2);
		width: 100%;

		@include min-mobile {
			font-size: calculateRem(16px);
		}

		a:hover & {
			opacity: 0.9;
		}
	}

	&__type {
		color: #dea99e;
		text-transform: uppercase;
		font-size: calculateRem(11px);
		letter-spacing: 0.05em;
	}

	&__meta {
		display: flex;
		row-gap: calculateRem(2px);
		column-gap: calculateRem(15px);
		margin: 0;
		color: var(--color-text-lighter);
		font-size: calculateRem(11px);
		flex-direction: column;
		line-height: 1.5;

		&__item {
			@include ellipsis(1);
			letter-spacing: 0.04em;
		}
	}
}
