body.landing-reoptin-newsletter {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $c_main;

  @include tablet_portrait {
    min-height: auto;
  }

  &::before {
    content: unset;
  }

  .header {
    &--light {
      height: 80px;
      background-color: $c_greydark;
      display: flex;
      flex-shrink: 0;

      .header {
        &__container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        &__logo {
          width: 50px;
          height: auto;
          figure {
            background-color: transparent;
          }
        }

        &__link {
          @include ft($f_txt, 13px, 400, $c_white, 20px);
        }

      }

    }

  }

  .landing-content {
    padding-top: 30px;
    background-color: $c_white;

    .content-wrapper {
      max-width: 980px;
      margin: 0 auto;
    }

    &__col {
      width: 47%;

      @include tablet_portrait {
        width: 100%;
      }

      &--right {
        margin-left: auto;
      }
    }

    .form-container {
      position: absolute;
      width: 100%;
      top: 0;

      @include tablet_portrait {
        position: static;
        background-color: $c_main;
      }

      .sticky {
        position: sticky;
        top: 110px;

        @include tablet_portrait {
          position: static;
        }
      }

      .error-container {
        display: none;

        &.show {
          display: block;
        }
      }
    }

    .full-height {
      height: 100%;
    }

    .hero {
      position: relative;
      z-index: 5;
      &__title {
        @include ft($f_txt, 44px, 600, $c_greydark, 50px);
        margin-bottom: 15px;
      }
      &__text {
        @include ft($f_txt, 13px, 400, $c_txt, 22px);
      }
    }

    .arguments-display-links {
      display: none;

      a {
        text-decoration: underline;
        font-weight: 500;

        &.hide {
          display: none;
        }
      }

      @include tablet_portrait {
        display: block;
        margin-bottom: 15px;
      }
    }

    .arguments {
      position: relative;
      z-index: 5;
      background-color: $c_main;

      @include tablet_portrait {
        padding: 0;
        height: 0;
        overflow: hidden;
        transition: height 0.2s ease-in;
      }

      &__title {
        padding-top: 44px;
        p {
          @include ft($f_txt, 18px, 400, $c_white, 28px);
        }
      }

      &__list {
        margin-bottom: 30px;
        li {
          @include ft($f_txt, 13px, 400, $c_white, 22px);
          margin-bottom: 15px;
          overflow: hidden;
          i {
            float: left;
            padding: 6px;
            border: 2px solid;
            border-radius: 100%;
            margin-right: 8px;
            font-size: 11px;
            color: $c_third;
          }
        }
      }
    }

    .info-box {
      display: flex;
      align-items: center;
      padding: 20px;
      background-color: $c_third;
      border-radius: 8px;

      p, i {
        color: $c_white;
        margin-bottom: 0;
      }

      &__title {
        @include ft($f_txt, 16px, 400, $c_white, 22px);
      }

      &__text {
        @include ft($f_txt, 13px, 400, $c_white, 22px);
      }

      i {
        font-size: 35px;
        margin-right: 20px;
      }

    }

    .bloc-form {
      form {
        height: auto;
      }
      .frm_switch_block .frm_on_label p {
        font-size: inherit;
        display: inline;
      }

      .frm_submit {
        margin: 0 0 20px 0;
        button {
          width: 100%;
        }
      }
    }

    .landing-form {
      position: relative;
      z-index: 6;
      &__title {
        @include ft($f_txt, 25px, 400, $c_main, 32px);
        margin-bottom: 15px;

        @include tablet_portrait {
          padding-top: 25px;
          color: $c_white;
        }

        i {
          float: left;
          padding: 6px;
          border: 2px solid;
          border-radius: 100%;
          margin-right: 8px;
          font-size: 11px;
          color: inherit;
        }
      }
    }

    .form-box {
      padding: 20px;
      background-color: $c_white;
      box-shadow: 0px 10px 35px #00000029;
      border-radius: 8px;

      .reglement {
        & > * {
          font-size: 11px;
          line-height: 1.8;
          margin-bottom: 5px;

            a {
                text-decoration: underline;
            }
        }
      }
    }
  }


  .footer-bottom {
    margin-top: auto;

    @include tablet_portrait {
      margin-top: 20px;
    }

    .menu-legal {
      span, a {
        color: $c_white;
      }
    }
  }


}



