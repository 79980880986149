.bloc-relation-itineraire-wrapper {

    @include mobile {
        padding-left: 0;
        padding-right: 0;
    }

    .padding-inner {
        padding: 0 0 20px 0;
    }
    .splide-relation-itineraire-tab {
        .splide__track {
            position: relative;
            font-size: 0;
            white-space: nowrap;
            display: block;
            width: 100%;
            margin-bottom: 20px !important;
            padding-top: 20px;
            @include clearfix();
            @include desktop1200 {
                margin-bottom: 10px;
            }
            @include mobile {
                padding-top: 0;
            }

            &:before {
                content: '';
                position: absolute;
                left: 6vw;
                bottom: 8px;
                width: 100%;
                height: 1px;
                background: rgba($c_border, 0.5);
                @include desktop {
                    left: 80px;
                }
                @include tablet_landscape {
                    left: 40px;
                }
                @include tablet_portrait {
                    width: calc(100% + 40px);
                }
                @include mobile {
                    left: 0;
                    bottom: 0;
                    width: 100%;
                }
            }

            button {
                @include ft($f_txt, 20px, 600, $c_txt, 24px);
                @extend %appearance;
                background: none;
                letter-spacing: 0.2px;
                position: relative;
                display: inline-block;
                vertical-align: top;
                margin: 0 17px;
                padding: 20px 0;
                @include addTransition();
                @include tablet_landscape {
                    font-size: calculateRem(20px);
                    line-height: calculateRem(20px);
                }
                @include mobile {
                    font-size: calculateRem(17px);
                    margin: 0 14px;
                    padding: 15px 0;
                }

                &:hover {
                    cursor: pointer;
                }


                &.is-active {
                    color: $c_third;
                    font-weight: 500;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: -8px;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: $c_third;

                        @include mobile {
                            bottom: -7px;
                        }
                    }
                }
            }
        }
    }

    .splide-relation-itineraire {

        .splide__track {
            @include mobile {
                padding-top: 0 !important;
            }
        }

        .splide {

            &__arrow {
                position: absolute;
                background-color: transparent;
                top: 0;

                &--prev {
                    left: 15vw;
                }

                &--next {
                    left: 20vw;
                }

                @include mobile {
                    display: none;
                }
            }

            &__track {
                padding-top: 30px;
            }
        }
    }
}