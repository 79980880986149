.splide__readmore {
    background-color: var(--white);
    height: 520px;
    margin-bottom: 0;
    padding: 40px 0px 40px 185px;
    h3 {
        padding-bottom: 40px;
    }
    .splide__scroll {
        left: 185px;
        bottom: 25px;
    }
    .splide__arrow {
        left: calc(970px + 185px);
        bottom: 0px;
        &--next {
            left: calc( 1000px + 185px);
        }
    }
}