// Theme color is initialized with dark theme

:root {
	--bg-dark: #{$bg-dark};
	--bg-light: #{$bg-light};
	--current-bg: #{$bg-my-explore};

	--bg-grey-light: #{lighten($bg-dark, 4%)}; 
	--bg-card-img :  #{darken($bg-my-explore, 4%)}; 

	//field
	--bg-field: #43474a; //don't put transparency color
	--bg-border-field: #363b3e;
	//switch
	--bg-switch: rgba(220, 219, 218, 0.2);
	--bg-border-switch: transparent;

	--bg-field-hightlight: #{darken($bg-field, 6%)};
	--border-color--button--primary: var(--color-primary);

	//bouton outline
	--border-color--button--outline: rgba(255, 255, 255, 0.5);
	--color--button--outline: #fff;

	//bouton primary
	--btn-secondary-bg-hover: #ffffff;
	--btn-secondary-color-hover: #181f22;

	//Text
	--hover-link: #{var(--color-primary)};
	--color-text: #fff;
	--color-text-lighter: #969899;

	//border
	--color-border: rgba(173, 179, 184, 30%);

	// colors
	--color-primary: rgb(222, 169, 158);
	--color-secondary: rgb(122, 151, 170);

	--btn-primary-bg-hover: #ffffff;
	--btn-primary-color-hover: #181f22;

	--btn-secondary-bg-hover: #ffffff;
	--btn-secondary-color-hover: #181f22;
}

//theme dark
.bg--dark {
	background-color: var(--bg-dark);
	color: var(--color-text);
	--current-bg: var(--bg-dark);
	--bg-card-img :  #{darken($bg-dark, 4%)}; 
}
