.top1 {

    @include tablet_portrait {
		&#content.content--scrollable {
			margin-top: -29px;
		}
	}

    background-color: var(--darkblue);

    &.page-content-snacking-template {
        .top1__content {
            margin-bottom: 80px;
        }
    }

    .lemag__title {
        @include mobile {
            display: none;
        }
    }

    .content-wrapper--lemag-title {
        @media screen and (min-width: 1440px) {
            margin: 0 80px;
        }
    }

    &__bg {

        position: relative;

        &::before {
            content: '';
            position: absolute;
            z-index: 0;
            top: 505px;
            width: 100%;
            height: calc(100% - 505px);
            background-color: var(--white);
            
            @include desktop1200 {
                top: 0;
                height: 100%;
            }
        }

        .credits {
            text-align: right;
            position: static;
            width: 100%;
            display: block;

            @include mobile {
                padding-right: 20px;
            }
        }
    }

    &__header {
        &::before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            top: 8px;
            margin: auto;
            width: 129px;
            height: 4px;
            border-radius: 2px;
            background-color: rgba(#1E2529, 0.3);
            display: none;

            @include mobile {
                display: block;
            }
        }
        position: relative;
        z-index: 1;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        max-width: 1440px;
        width: 100%;
        padding-top: 60px;
        padding-bottom: 20px;
        padding-left: 40px;
        padding-right: 40px;
        margin: 0px auto;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        @include tablet_portrait {
            padding-left: 20px;
            padding-right: 20px;
        }

        &__headline {

            position: relative;
            font: normal normal normal 11px/17px Poppins;
            letter-spacing: 1.65px;
            color: var(--darkblue);
            text-transform: uppercase;
            text-align: center;
            padding-left: 13px;
            margin-bottom: 7px;
            display: flex;

            @include tablet_portrait {
                flex-direction: column;
                align-items: center;
            }

            strong {
                position: relative;

                @include tablet_portrait {
                    margin-left: 8px;
                }

                &::before {
                    content: '\e907';
                    @include font();
                    font-size: 10px;
                    color: var(--darkblue);
                    top: 3px;
                    left: -14px;
                    position: absolute;
                    height: 10px;
                    width: 6px;
                }
            }
        }

        h1 {
            font: normal normal bold 44px/48px Poppins;

            color: var(--darkblue);
            text-align: center;

            @include mobile {
                font-size: 28px;
                line-height: 34px;
            }
        }

        .bloc-texte {
            text-align: center;
            @include mobile {
                margin-left: 20px;
                margin-right: 20px;
            }
        }


        .breadcrumbs-wrapper {
            display: flex;
            align-items: center;
            margin-top: 30px;

            @include tablet_portrait {
                flex-direction: column-reverse;
                align-items: baseline;
            }

            @include mobile {
                display: none;
            }
        }

    }



    &__content {
        background-color: var(--white);
        position: relative;
        z-index: 2;
        margin: 0 auto;
        max-width: 1440px;
        width: 100%;

        &::before,
        &::after {
            content: '';
            position: absolute;
            z-index: 0;
            top: 505px;
            left: 0px;
            width: 110px;
            height: 1px;
            background-color: rgba(#ABB3B9, 0.5);

            @include desktop1200 {
                content: none;
            }

        }

        &::after {

            left: unset;
            right: 0px;

        }

        &_wrapper {
            margin: 0 10%;

            @include tablet_portrait {
                margin: 0 auto;
            }

            &--large {
                margin: 0 auto;
            }
        }
    }


}