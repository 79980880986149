.card__slidereadmore {
    max-width: 300px;

    position: relative;
    z-index: 2;

    &_thumb {
        width: 100%;
        height: 200px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;

    }

    &_content {

        position: relative;
        padding-top: 6px;
        width: 100%;

        &--cat {

            font: normal normal bold 11px/17px Poppins;
            letter-spacing: 0.44px;
            color: var(--bluegrey);
            text-transform: uppercase;

            strong {
                font-weight: bold;
            }

            padding-left: 13px;
            position: relative;

            &::before {
                content: '\e907';
                @include font();
                font-size: 10px;
                color: var(--bluegrey);
                top: 4px;
                left: 0;
                position: absolute;
                height: 10px;
                width: 6px;
            }
        }

        &--text {
            margin-top: 7px;
            font: normal normal normal 14px/18px Poppins;

            color: var(--darkblue);
        }

        &--readnext {
            font: normal normal 600 14px/16px Poppins;
            color: var(--darkblue);
            position: relative;
            display: inline;
            &::after {
                content:'';
                position: absolute;
                bottom: -10px;
                left: 0;
                height: 3px;
                border-radius: 2px;
                background-color: #DEA99E;
                width: 20px;
                transition: width 200ms ease-out;

            
            }
            &:hover {
                &::after {
                    width: 100%;
                }
            }
        }
    }
}