.icon-new-myexplore-my {
	.path1 {
		font-size: 40px;
	}

	.path2 {
		font-size: 40px;
		&:before {
			border-radius: 8px;
			background: linear-gradient(-30deg, #dea99e 0%, #e13060 100%);
		}
	}

	@include mobile {
		.path1, .path2 {
			font-size: 25px;
		}
	}
}

