@import "../sections/homepage-hero";

.home {
    .moteur-search {
        @media screen and (min-width: 981px) {
            opacity: 0;
            transform: translate3d(-50%, 0%, 0) scaleY(0);
        }
    }

    #header {

        @include mobile {

            background: transparent;

            &:after {
                display: none
            }

            .menu-barre-mobile {
                background: unset;

            }

            button.cta-overlay-search {
                opacity: 0;
                transition: opacity 0.2s ease;
            }

            &.scrolled {

                &:after {
                    display: unset;
                }
                background-color: #1E2529;
                button.cta-overlay-search {
                    opacity: 1;
                }

            }
        }
    }

    #content {

        @include mobile {

            .mobile-search__wrapper {
                display: none;
            }

            .homepage-hero {
                height: 300px;



                &__background {
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #1A1A1A;
                        opacity: 0.5;
                    }
                }

                &__search-input {
                    border-radius: 8px;

                    &:before {
                        font-weight: 600;
                        font-size: 20px;
                        margin-right: 10px;
                    }
                }
            }

            .bloc-push-home {
                padding-top: 0;
            }
        }


        .homepage-mobile-links {
            display: none;

            @include mobile {
                display: block;
                margin-top: -42px;
                margin-bottom: 0;

                &::after {
                    content: "";
                    position: relative;
                    z-index: 9;
                    display: block;
                    height: 14px;
                    width: 100%;
                    background-color: #fff;
                    border-radius: 14px 14px 0 0;
                    margin-top: 4px;
                }

            }

            ul {
                display: flex;
                flex-wrap: nowrap;
                height: 25px;
                padding-top: 0;
                padding-bottom: 0;
                margin-bottom: 25px;

                li {
                    flex-shrink: 0;
                    margin: 0 6px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        color: #fff;
                        font-size: 11px;
                    }

                    .icon {
                        font-weight: 16px;

                        &::after {
                            content: "";
                            position: absolute;
                            z-index: 999;
                            bottom: -8px;
                            width: 10px;
                            height: 10px;
                            border-radius: 100%;
                            background-color: $c_third;
                            left: 1px;
                        }
                    }
                }
            }
        }
    }
}

