.page-detail {
	padding-bottom: 20px;

	// STYLES DE BASE
	// ==========================================================================
	.title-sit {
		@include ft($f_hx, 40px, 300, $c_main, 40px);
		letter-spacing: 0.2px;
		position: relative;
		display: block;
		margin-bottom: 10px;
		@include mobile {
			font-size: calculateRem(25px);
			line-height: calculateRem(25px);
			margin: 0;
		}
	}

	.open_sncf_connect {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 10px 0;
		cursor: pointer;

		span.text {
			text-decoration: underline;
			margin-left: 10px;
			font-weight: 500;
		}
	}

	.contact-presta {
		text-decoration: underline;
	}

	.container_sncf_connect {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		border-top: dotted 1px $c_border;
		border-bottom: dotted 1px $c_border;
		padding: 15px 0;

		@include mobile {
			flex-direction: column;
		}
	}

	.big-title {
		@include ft($f_hx, 90px, 100, $c_white, 90px);
		position: relative;
		display: block;
		margin: 0;
		@include desktop1280 {
			font-size: calculateRem(70px);
			line-height: calculateRem(70px);
		}
		@include tablet_landscape {
			font-size: calculateRem(50px);
			line-height: calculateRem(50px);
		}
		@include mobile {
			font-size: calculateRem(45px);
			line-height: calculateRem(45px);
		}
		@include mobile_small {
			font-size: calculateRem(40px);
			line-height: calculateRem(40px);
		}
	}

	.title-small {
		@include ft($f_txt, 18px, 600, $c_txt, 18px);
		position: relative;
		display: block;
		margin-bottom: 10px;
		@include mobile {
			font-size: calculateRem(17px);
			line-height: calculateRem(17px);
		}
	}

	// HEADER
	// ==========================================================================
	header {
		position: relative;
		background: $c_greydark;
		padding-top: 60px;
		padding-bottom: 60px;

		@include tablet_portrait {
			&::before {
				content: "";
				position: absolute;
				width: 130px;
				top: 9px;
				left: 50%;
				transform: translateX(-50%);
				height: 4px;
				background-color: rgba(#fff, 0.6);
				border-radius: 4px;
				z-index: 9;
			}
		}

		body.no-search & {
			padding-top: 120px;
		}

		@include tablet_portrait {
			padding-top: 210px;
			body.no-search & {
				padding-top: 120px;
			}
		}
		@include tablet_portrait {
			padding-top: 0;
			padding-bottom: 30px;
			background: $c_white;
			body.no-search & {
				padding-top: 0;
			}
		}
		@include mobile {
			padding-bottom: 0;
		}

		> * {
			@include desktop1280 {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.caption-txt-header {
			@include tablet_portrait {
				display: none;
			}
		}


		.logo-sens-matieres {
			position: absolute;
			bottom: 20px;
			left: -4px;
			z-index: 11;
			@include mobile {
				max-width: 123px;
				height: auto;
			}
		}
	}

	.caption-txt-header {
		width: 100%;
		max-width: calc(100% - 440px);
		@include desktop1280 {
			max-width: calc(100% - 380px);
		}
		@include tablet_landscape {
			max-width: calc(100% - 340px);
		}

		.wrapper-top {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			width: 100%;

			> * {
				margin-bottom: 10px;
			}

			.encart {
				margin-right: 20px;
				@include tablet_portrait {
					box-shadow: 0px 0px 10px 1px #e3e3e3;
				}
			}
		}

		.location, .location::before {
			color: white;

			@include tablet_landscape {
				color: $c_main;
			}
		}

		.wrapper-cat {
			display: block;
			white-space: normal;
			overflow: visible;

			@include tablet_portrait {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}

			span {
				font-weight: 500;
				@include tablet_portrait {
					color: $c_surtitre;
				}
			}
		}

		h1 {
			color: white;
			display: inline;

			@include tablet_landscape {
				color: $c_txt;
				font-size: calculateRem(25px);
				line-height: calculateRem(30px);
			}
		}

		.classement {
			margin-left: 5px;

			span {
				font-size: 26px;
				line-height: 40px;
				@include mobile {
					font-size: 16px;
					line-height: 22px;
				}

				&.icon-ico-fleur {
					& + .icon-ico-star, & + .icon-ico-toque, & + .icon-ico-epis, & + .icon-ico-cles {
						margin-left: 10px;
					}
				}

				&.icon-ico-star {
					& + .icon-ico-fleur, & + .icon-ico-toque, & + .icon-ico-epis, & + .icon-ico-cles {
						margin-left: 10px;
					}
				}

				&.icon-ico-toque {
					& + .icon-ico-fleur, & + .icon-ico-star, & + .icon-ico-epis, & + .icon-ico-cles {
						margin-left: 10px;
					}
				}

				&.icon-ico-epis {
					& + .icon-ico-fleur, & + .icon-ico-star, & + .icon-ico-toque, & + .icon-ico-cles {
						margin-left: 10px;
					}
				}

				&.icon-ico-cles {
					& + .icon-ico-fleur, & + .icon-ico-star, & + .icon-ico-toque, & + .icon-ico-epis {
						margin-left: 10px;
					}
				}
			}
		}



		.breadcrumbs-wrapper {
			display: flex;
			align-items: center;
			margin-top: 15px;

			@include tablet_portrait {
				flex-direction: column-reverse;
				align-items: baseline;
			}

			@include mobile {
				display: none;
			}
		}

		.cta-back {
			display: inline-block;
			margin-right: 16px;
			@include ft($f_hx, 14px, 500, $c_white, 20px);
			@extend %appearance;
			background: transparent;
			border-radius: 4px;
			border: 1px solid;
			padding: 10px 15px 8px;
			white-space: nowrap;
			@include addTransition();

			@include tablet_portrait {
				margin-top: 10px;
				padding: 5px 10px 3px;
				@include ft($f_hx, 11px, 500, $c_txt, 17px);
				border-color: #B2BBBF;
			}

			&:before {
				content: "\e90b";
				@include font();
				position: relative;
				top: 0;
				font-size: 9px;
				line-height: 18px;
				margin-right: 11px;
			}

			&:hover, &:focus {
				background: $c_white;
				color: $c_greydark;
			}
		}

		#breadcrumb {
			margin-bottom: 0;
			margin-top: 0;
			width: 100%;

			a {
				&:hover, &:focus {
					body:not(.no-hover) & {
						span {
							color: $c_sub;
						}
					}
				}
				&::after {
					color: $c_white;
				}
			}

			span {
				color: $c_white;
				@include tablet_portrait {
					color: $c_greydark;
				}
			}
		}
	}

	.mobile-text-header {
		.caption-txt-header {
			display: none;

			@include tablet_portrait {
				display: block;
				max-width: 100%;
				margin-top: 30px;
			}
		}
	}

	// HEADER - IMAGES MOBILE + TAB PORTRAIT
	.wrapper-top-images-mobile {
		display: none;
		@include tablet_portrait {
			position: relative;
			display: block;
			margin-left: -40px;
			margin-right: -40px;
			width: calc(100% + 80px);
		}
		@include mobile {
			margin-left: -20px;
			margin-right: -20px;
			width: calc(100% + 40px);
		}

		figure {
			&:before {
				position: relative;
				height: auto;
				@include mobile {
					background: none;
				}
			}

			&:after {
				content: '';
				@extend .gradient-bottom;
				z-index: 10;
				top: auto;
				height: 100px;
				opacity: 0.85;
			}

			@include tablet_portrait {
				@include aspect-ratio(3, 2);
			}
			@include mobile {
				@include aspect-ratio(1, 1);
			}

			img {
				@extend %center;
			}
		}

		.bottom {
			position: absolute;
			left: 40px;
			right: 40px;
			bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			z-index: 1000;

			@include tablet_portrait {
				right: auto;
				left: 20px;
				justify-content: space-between;
				width: 92%;

				.cta-add-favoris-mobile {
					background-color: transparent;
					border: none;

					&:before {
						content: "\e916";
						@include font();
						font-size: 24px;
						color: white;
						margin-right: 8px;
					}

					&.active, &:hover {
						&:before {
							content: "\e959";
							color: $c_third;
						}
					}
				}
			}

			> div {
				height: 100%;
				display: flex;
				@include mobile {
					margin-left: 20px;
				}
				@include mobile_small {
					margin-left: 0;
				}
			}
		}

		.encart {
			margin-left: 20px;
		}

		.cta-favoris {
			margin: 0;
			padding: 0;
		}

		.cta-gallery {
			position: relative;
			right: 0;
			left: auto;
			bottom: 0;
			top: auto;
			transform: none;
		}

	}

	// HEADER - IMAGES
	.wrapper-top-images {
		display: flex;
		margin-bottom: 40px;
		width: 100%;
		@include tablet_portrait {
			display: none;
		}

		// Si deux images
		&.nb-images-2 {
			> * {
				&.right {
					a {
						width: calc(100%);
						@include aspect-ratio(1035, 480);
					}
				}
			}
		}

		a {
			&:hover, &:focus {
				body:not(.no-hover) & {
					figure {
						img {
							opacity: 0.6;
						}
					}
				}
			}
		}

		> * {
			position: relative;
			display: block;

			figure {
				@extend %center;
				background: none;

				&:before {
					content: none;
				}

				img {
					@include addTransition();
				}
			}

			.credits, figcaption {
				display: none;
			}

			&:first-child {
				@include aspect-ratio(790, 480);
				width: 100%;
				@extend %appearance;
				padding: 0;
				background: none;
			}

			&.right {
				@include clearfix();
				width: 410px;
				flex: none;
				margin-left: 30px;
				align-self: flex-start;
				@include desktop1280 {
					width: 360px;
				}
				@include tablet_landscape {
					width: 320px;
				}
				@include tablet_portrait {
					display: none;
				}

				> a {
					@include aspect-ratio(1, 1);
					@extend %appearance;
					padding: 0;
					background: none;
					position: relative;
					float: left;
					display: block;
					width: calc(50% - 5px);
					margin-left: 10px;
					margin-top: 10px;
					margin-bottom: 40px;
					@include mobile {
						margin-left: 0;
						width: calc(50% - 5px);
					}

					&:first-child {
						margin-left: 0;
					}

					&:nth-child(-n+2) {
						margin-top: 0;
					}

					&.more-images {
						&:hover, &:focus {
							body:not(.no-hover) & {
								.cta-gallery {
									background: $c_white;
									border-color: $c_white;
									color: $c_greydark;

									& ~ figure {
										img {
											opacity: 0.25;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// Call to action overlay gallery
	.cta-gallery {
		@include ft($f_hx, 14px, 500, $c_white, 20px);
		@extend %appearance;
		background: $c_third;
		margin: 0;
		border-radius: 4px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transform: translate3d(-50%, -50%, 0);
		z-index: 100;
		padding: 10px 15px 8px;
		white-space: nowrap;
		@include addTransition();

		&:before {
			content: "\e927";
			@include font();
			position: relative;
			top: 0;
			font-size: 15px;
			line-height: 18px;
			margin-right: 11px;
		}

		& ~ figure {
			img {
				opacity: 0.25;
			}
		}
	}

	// ANCRE
	.bloc-ancre {
		&.bloc-ancre--aux-alentours {
			.th-slider {
				.card .reservable {
					@include tablet_landscape {
						display: none;
					}
				}
			}
		}

		.track-scroll {
			li {
				position: relative;
				padding: 0 15px 0 16px;
				@include tablet_landscape {
					padding: 0 10px 0 11px;
				}

				&:first-child {
					padding-left: 80px;

					&::before {
						content: none;
					}

					@include desktop1280 {
						padding-left: 40px;
					}

					.no-hover & {
						@include desktop1280 {
							padding-left: 0;
						}
						@include tablet_landscape {
							padding-left: 20px;
						}
					}
				}

				a {
					font-size: calculateRem(12px);
					@include desktop1280 {
						font-size: calculateRem(11px);
					}
					@include tablet_portrait {
						font-size: calculateRem(10px);
					}
				}
			}
		}
	}

	.page-tabs {
		position: sticky;
		top: 80px;
		background-color: #fff;
		z-index: 99999;
		border-bottom: 1px solid #d5d9dc;

		.content-wrapper {
			@include desktop1280 {
				padding: 0;
			}
		}

		@include mobile {
			top: 74px;
			background-color: transparent;
			padding-right: 0;
			border: 0;
			overflow: hidden;
	
			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				height: calc(100% - 8px);
				width: 100%;
				background-color: white;
			}

			// horizontal line
			&:after {
				content: '';
				height: 1px;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba($c_border, 0.3);
		
				@include mobile {
					bottom: 8px;
				}
			}
		}
		

		.splide__track {
			overflow: visible;

			@include mobile {
				padding-bottom: 8px;
			}
		}

		&__list {
			height: auto;
		}

		&__item {
            @include ft($f_txt, 14px, 600, $c_surtitre, 22px);
            position: relative;
            padding: 24px 15px 21px;
            display: block;
            width: auto;
            margin: 0;
            white-space: nowrap;
            @include addTransition();
            @include mobile {
                font-size: calculateRem(11px);
                padding: 16px 20px 12px 0;

				&.menu-ancre--description {
					padding-bottom: 8px;
				}
            }

            &:hover, &:focus {
                body:not(.no-hover) & {
                    color: $c_third;
                }
            }
            &.active {
                color: $c_third;
                &:after {
                    opacity: 1;
                }
            }
            &:after {
                content: '';
                position: absolute;
                top: calc(100% - 6px);
                left: calc(50% - 10px);
                width: 12px;
                height: 12px;
                background: $c_third;
                border-radius: 50%;
                opacity: 0;
                translate: -50%;
                @include addTransition();
            }

			.icon {
    			display: inline-block;
				font-size: 18px;
				margin-bottom: 4px;
			}
        }
	}

	// CONTENU FICHE SIT
	// ==========================================================================
	.wrapper-content-sit {
		@include clearfix();
		@include desktop1280 {
			padding: 0;
		}
		@include tablet_portrait {
			display: flex;
			flex-wrap: wrap;
		}

		&.bloc-relation {

			.onglets {
				overflow: visible;
				&::before {
					bottom: 0;
				}

				.splide__list {
					height: auto;
				}
			}

			.bloc {
				@include mobile {
					padding: 0;
				}
			}
			
			.splide-other-offers-tabs {

				.splide__track {
					overflow: visible;
				}

				button{
					font-size: 16px;
					cursor: pointer;
					
					&:first-child {
						margin-left: 0 !important;
					}
				}
			}

			.splide-other-offers-slider, .splide-relation-map {
				.splide:not(.is-overflow) .splide__pagination {
					display: flex;
				}

				@include mobile {
					margin-left: -20px;
				}
			
				.splide__track {
			
					.splide__slide {
			
						@include mobile {
							min-width: 150px;
			
							a {
								width: auto;
							}
						}
					}
				}

				.splide__list {
					height: auto;
				}
			
				.splide__arrow {
					top: unset;
					bottom: -68px;
					background-color: transparent;
			
					svg {
						fill: white;
					}
			
					&--prev {
						left: unset;
						right: 6vw;
					}
			
					&--next {
						right: 3vw;
					}
			
					span:before {
						font-size: 18px;
					}
			
					@include tablet_portrait {
						display: none;
					}
				}
			
				.class-pagination {
					bottom: -3.5em;
					padding-right: 7em;
					padding-left: 0;
					justify-content: flex-start;
			
					@include tablet_portrait {
						padding: 0 75px;
						max-width: 80vw;
					}
			
					@include mobile {
						bottom: -2.5em;
						max-width: 90vw;
						padding: 0 20px;
						justify-content: left;
					}
				}
				.class-page {
					margin: 0;
					height: 1px;
					border-radius: 8px;
					width: 100%;
					background-color: #627A89;
					&.is-active {
						height: 5px;
						background-color: #DEA99E;
						opacity: 1;
						transform: unset;
						padding-bottom: 2px;
					}
				}
			}

			.splide-relation-map {
				.class-pagination {
					bottom: -3.5em;
					padding-right: 7em;
					padding-left: 0;
					justify-content: center;
				}
			}

			.wrapper-slider.slider-onglets {
				margin: 0;
				max-width: 100%;
				@include wide {
					max-width: $max-width + $border-gutter + $border-gutter;
				}

				.th-slider {
					margin-left: 0;
				}

				.no-hover & {
					max-width: 100%;

					&:before {
						max-width: 100%;
					}

					.th-slider {
						max-width: 100%;
						width: calc(100% + 160px);
						margin-left: 0;
						@include tablet_landscape {
							width: calc(100% + 80px);
						}
						@include mobile {
							width: calc(100% - 40px);
						}
					}
				}
			}
		}
	}

	.splide-relation-map {
		z-index: 999;

		@include mobile {
			margin-left: 0 !important;
		}
	}

	.content-left {
		position: relative;
		box-sizing: border-box;
		float: left;
		width: calc(100% - 410px);
		padding-right: 80px;
		order: 2;
		
		margin-bottom: $content-spacing/2;

		@include desktop1280 {
			width: calc(100% - 360px);
			padding-right: 40px;
		}
		@include tablet_landscape {
			width: calc(100% - 320px);
			padding-right: 40px;
			margin-bottom: $content-spacing-tab/2;
		}
		@include tablet_portrait {
			float: none;
			width: 100%;
			padding: 0;
			margin-bottom: $content-spacing-mobile/2;
		}

		> .bloc-detail {
			display: block;
			float: none;
			margin: 75px 0;
			@include tablet_landscape {
				margin: 50px 0;
			}
			@include mobile {
				margin: 40px 0;
			}

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		hr {
			border: none;
			background: rgba($c_grey70, 0.2);
			width: 100%;
			height: 1px;
		}

		.bloc-detail {
			position: relative;
			z-index: 10;

			> * {
				&:last-child:not(ul) {
					margin-bottom: 0;
				}
			}

			.cta-expand {
				align-items: flex-start;
				margin-left: 0;
			}

		}


		.bloc-detail.detail-texte {
			display: flex;
			flex-direction: column;
			margin: 20px 0;

			.cta-expand--description {
				position: relative;
				top: 40px;
				@include ft($f_hx, 14px, 500, $c_white, 20px);
				@extend %appearance;
				position: relative;
				background: $c_third;
				border-radius: 4px;
				padding: 10px 20px 9px 20px;
				align-self: center;
				@include addTransition();
				@include tablet_landscape {
					font-size: calculateRem(16px);
					min-width: 100px;
				}

				&:hover, &:focus {
					body:not(.no-hover) & {
						cursor: pointer;
						background: $c_greydark;
						color: #fff !important;
					}
				}

				&::before {
					content: none;
				}
			}

			& + hr {
				//margin
			}
		}


		// Informations complémentaires
		.info-comp {
			position: relative;
			margin-top: 50px;
			margin-bottom: 20px;

			@include clearfix();
			@include mobile {
				margin-bottom: 40px;
			}

			li {
				@include ft($f_txt, 15px, 600, $c_txt, 18px);
				position: relative;
				float: left;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				width: 50%;
				padding: 0 10px;
				margin: 0 0 25px 0;
				@include mobile {
					float: none;
					padding: 0;
					width: 100%;
					margin-bottom: 30px;
				}

				[class^="icon-"], [class*=" icon-"] {
					font-size: 60px;
					color: $c_third;
					margin-right: 15px;
					@include tablet_landscape {
						font-size: 40px;
					}
					@include tablet_portrait {
						font-size: 60px;
					}
					@include mobile {
						font-size: 40px;
						margin-right: 10px;
					}
				}

				.value {
					display: block;
					font-weight: 300;
				}

				.wrapper-icons {
					display: block;
					margin-top: 5px;
					@include clearfix();

					[class^="icon-"], [class*=" icon-"] {
						position: relative;
						float: left;
						font-size: 20px;
						margin-right: 15px;
						color: $c_txt;
					}
				}

				figure {
					display: block;
					background: none;
					margin-top: 3px;

					img {
						width: auto;
						max-height: 21px;
					}
				}

				.credits, figcaption {
					display: none;
				}
			}
		}

		.labels {
			position: relative;
			display: block;
			margin-left: -19px;
			margin-right: -19px;
			@include mobile {
				margin-left: -10px;
				margin-right: -10px;
			}

			&:after {
				content: '';
				display: block;
				clear: both;
			}

			> li {
				width: calc(20% - 38px);
				display: block;
				float: left;
				margin: 25px 19px 60px;
				background-color: white;
				box-shadow: 0 0 40px 0 rgba(0, 0, 0, .08);
				@include aspect-ratio(1, 1);
				overflow: visible;
				@include mobile {
					margin: 0 10px 20px;
					width: calc(50% - 20px);
				}

				> figure {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					height: 100%;
					width: 100%;
					background-color: transparent;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						max-width: 70%;
						max-height: 70%;
						transform: translate3d(0, 0, 0);
					}
				}

				.icons {
					position: absolute;
					bottom: -14px;
					left: 50%;
					transform: translate3d(-50%, 0, 0);
					background-color: white;
					z-index: 100;
					font-size: 0;
					white-space: nowrap;
					padding: 6px 10px;
					border-radius: 50px;
					height: 16px;

					li {
						display: inline-block;
						font-size: 16px;
						line-height: 1;
						margin-right: 2px;

						&:before {
							font-family: 'icomoon' !important;
						}

						span {
							transform: translate3d(0, 0, 0);
						}

						&:last-child {
							margin-right: 0;
						}

						&.icon-ico-epis-label:before, &.icon-ico-cles-label:before {
							font-family: 'icomoon2' !important;
						}
					}

					&.gamme {
						height: auto;

						figure {
							background-color: transparent;
							width: 56px;
							height: auto;

							img {
								width: 100%;
								height: auto;
							}
						}
					}

					&.rouge {
						li {
							color: #D62142;
						}
					}

					&.bleu {
						li {
							color: #1E8DD4;
						}
					}

					&.vert {
						li {
							color: #11663F;
						}
					}
				}

			}

			.title-sit {
				font-size: 25px;
				font-weight: 500;
				color: $c_greydark;
				line-height: 24px;
				letter-spacing: 0.2px;
				margin: 0 0 5px 0;
				margin-left: 20px;
				margin-right: 20px;
				margin-bottom: 25px;
				padding-bottom: 16px;
				border-bottom: 1px solid rgba($c_border, .5);
				@include mobile {
					margin-left: 10px;
					margin-right: 10px;
				}
			}
		}

		// Programme
		.programme {
			ul {
				margin: 0;

				li {
					margin-top: 80px;
					display: flex;
					align-items: center;

					&:first-child {
						margin-top: 20px;
					}

					@include tablet_landscape {
						margin-top: 60px;
					}
					@include mobile {
						flex-wrap: wrap;
						margin-top: 50px;
					}

					figure {
						width: 200px;
						flex: none;
						background: none;
						@include aspect-ratio(1, 1);
						@include mobile {
							width: 100%;
						}

						img {
							@extend %center;
						}

						& ~ .bloc-texte {
							margin-left: 40px;
							margin-bottom: 0;
							@include mobile {
								margin: 20px 0 0 0;
							}
						}
					}

					figcaption {
						display: none;
					}

					.credits {
						top: 100%;
						@include mobile {
							position: relative;
						}
					}

					.bloc-texte {
						margin-bottom: -10px;
					}

					.title-programme {
						@include ft($f_txt, 18px, 600, $c_txt, 18px);
						margin-bottom: 10px;
					}
				}
			}

			.cta-expand {
				margin-top: 10px;
			}
		}

		// Highlight
		.highlight {
			margin-top: 40px;
			margin-bottom: 20px;
			background-color: transparent;


			> div {

				.title {
					@include ft($f_txt, 25px, 600, $c_txt, 30px);
					border-bottom: 1px solid rgba($c_grey70, 0.2);
					padding-bottom: 6px;
					margin-bottom: 20px;
				}

				.bloc-texte {

					p, li {
						@include ft($f_txt, 15px, 400, $c_txt, 28px);

						&:last-child {
							margin-bottom: 0;
						}
					}

					ul {
						padding: 0;
						margin: 0;

						li {
							line-height: calculateRem(22px);
							padding-left: 20px;

							&:before {
								top: 11px;
								background-color: $c_third;
							}
						}
					}
				}
			}
		}

		// Onglets
		.detail-onglets {
			margin-top: 20px;
			margin-bottom: 40px;

			.detail-onglets-wrapper {

				.splide__track {
					overflow: visible;
				}

				.splide__list {
					height: auto;
				}

				&::before {
					content: '' !important;
					position: absolute;
					width: 100%;
					bottom: 8px;
					height: 1px;
					background: rgba($c_border, 0.5);
				}

				&::after {
					content: '';
					position: absolute;
					background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
					top: 0;
					bottom: 9px;
					right: 0;
					width: 80px;

					@include mobile {
						display: none;
					}
				}

				.th-slider {
					margin-left: 0;
				}
			}

			.onglets {
				padding-top: 0;
				padding-bottom: 8px;

				&:before {
					content: none;
				}

				button {
					margin: 0 20px;
					font-size: calculateRem(25px);
					@include mobile {
						font-size: calculateRem(16px);
						margin: 0 15px;
					}

					&:first-child {
						margin-left: 0;
					}
				}
			}

			.wrapper {
				&[hidden] {
					display: none;
				}
			}

			.item {
				position: relative;
				margin: 35px 0;
				@include clearfix();
				@include mobile {
					margin: 40px 0;
				}

				&:first-of-type {
					margin-top: 20px;
				}

				&:last-child {
					margin-bottom: 0;
				}

				&:not(.bloc-texte) {
					margin: 0 0 20px 0;

					li {
						padding: 0;
						margin: 0;

						&:before {
							content: none;
						}
					}
				}

				.offre-ok, .offre-not-ok {
					position: relative;
					box-sizing: border-box;
					float: left;
					width: 50%;
					padding-right: 20px;
					margin-bottom: -10px;
					@include mobile {
						float: none;
						width: 100%;
						padding-right: 0;
					}

					ul {
						margin: 0;
						margin-top: 20px;
						@include mobile {
							margin-top: 15px;
						}

						li {
							padding: 0 0 0 30px;
							margin: 10px 0;
							line-height: 22px;
							vertical-align: center;

							&:before {
								content: none;
							}

							&:after {
								@include font();
								position: absolute;
								top: 0;
								left: 0;
								font-size: 20px;
							}
						}
					}
				}

				.offre-ok {
					& + .offre-not-ok {
						@include mobile {
							margin-top: 40px;
						}
					}

					ul {
						li {
							&:after {
								content: "\e925";
								color: #6FAC90;
							}
						}
					}
				}

				.offre-not-ok {
					ul {
						li {
							&:after {
								content: "\e922";
								color: $c_third;
							}
						}
					}
				}

				&.list-infos {
					ul {
						position: relative;
						font-size: 0;
						margin: 0;
						margin-top: 17px;
						margin-bottom: -10px;
						@include clearfix();
						@include mobile {
							margin-bottom: -5px;
						}

						li {
							position: relative;
							float: left;
							background-color: $c_lightgray;
							border-radius: 4px;
							margin: 0 10px 10px 0;
							padding: 6px 15px 4px;
							font-size: 14px;
							@include mobile {
								margin: 0 5px 5px 0;
							}

							&:before {
								content: none;
							}

							span {
								padding-right: 4px;
								
							}
						}
					}

					&.handicap {
						ul {
							margin-bottom: auto;
						}

						.list-infos__details {
							display: block;
							font-size: 11px;
							line-height: 14px;
							font-style: italic;
						}
					}
				}

				&.list-two-cols {
					ul {
						margin: 0;
						margin-top: -10px;
						position: relative;
						@include clearfix();

						li {
							@include ft('', 18px, 600, '', 18px);
							float: left;
							width: calc(50% - 20px);
							margin-bottom: 60px;
							@include mobile {
								font-size: calculateRem(15px);
								width: 100%;
								float: none;
								margin-bottom: 40px;
							}

							&:nth-child(odd) {
								clear: left;
							}

							&:nth-child(even) {
								margin-left: 40px;
								@include mobile {
									margin-left: 0;
								}
							}

							&:nth-last-child(-n+2) {
								margin-bottom: 0;
								@include mobile {
									margin-bottom: 40px;
								}
							}

							&:last-child {
								@include mobile {
									margin-bottom: 0;
								}
							}

							.wrapper-line {
								@include ft('', 15px, 300, '', 20px);
								position: relative;
								display: block;
								margin-top: 5px;
								@include clearfix();

								> * {
									position: relative;
									float: left;
								}

								[class^="icon-"], [class*=" icon-"] {
									position: relative;
									top: -1px;
									font-size: 20px;
									line-height: 20px;
									margin-right: 2px;

									&:last-child {
										margin-right: 10px;
									}

									&:before {
										color: $c_txt;
									}
								}
							}
						}
					}
				}

				&.large-list {
					ul {
						margin: -10px 0;

						li {
							@include ft('', 20px, 300, '', 32px);
							position: relative;
							padding-left: 20px;
							margin: 30px 0;
							@include mobile {
								font-size: calculateRem(16px);
								line-height: calculateRem(24px);
							}

							&:first-child {
								margin-top: 0;
							}

							&:last-child {
								margin-bottom: 0;
							}

							&:before {
								content: '';
								position: absolute;
								top: 11px;
								left: 0;
								width: 8px;
								height: 8px;
								background: $c_third;
								border-radius: 50%;
								@include mobile {
									top: 7px;
								}
							}
						}
					}
				}
			}

			.title-print {
				display: none;
			}
		}


		// Accordéon
		.eco-responsable {
			display: inline-flex;
			align-items: center;
			margin-top: 0;
			padding: 15px 20px 13px;
			border-radius: 4px;
			border: 1px solid $c_sub;
			transition: color 0.2s ease-in;

			&:hover {
				color: rgba($c_sub, 0.6);

				.title-sit, .link, [class^="icon-"] {
					color: rgba($c_sub, 0.6);
				}
			}

			[class^="icon-"] {
				transition: color 0.2s ease-in;
				font-size: 35px;
				color: $c_sub;
			}

			.text {
				padding-left: 20px;
			}

			.title-sit {
				@include ft('', 15px, 500, $c_sub, 20px);
				margin: 0 0 5px 0;
				transition: color 0.2s ease-in;

				&:before, &:after {
					content: none;
				}
			}

			.link {
				@include ft('', 15px, 400, $c_sub, 20px);
				text-decoration: underline;
				transition: color 0.2s ease-in;
			}
		}


	}

	#id-item-activites, .bloc-relation--map {
		overflow: visible;
		.th-slider {
			padding-bottom: 0;
		}
	}

	.bloc-relation--map {
		@include mobile {
			padding: 0 !important;
		}

		.content-wrapper {
			@include desktop1280 {
				padding: 0;
			}
		}
	} 
	
}

// Spé. Labels CléVacances
.gamme-logo-clevacances {
	background-color: transparent !important;
	bottom: -30px !important;

	.figure-logo-clevacances {
		width: 130px !important;
	}
}

#informations-contact {
	@include mobile {
		.title-h3 {
			display: none;
		}
	}
}