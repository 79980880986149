.overlay-mice {
	max-width: 100%;
	z-index: 10000000001;
	background-color: $c_dark_grey;

	.close-overlay {
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		left: 50%;
		text-align: center;
		color: $c_white;
		cursor: pointer;
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		&:after {
			margin-left:0;
		}

		@include tablet_portrait {
			font-size: 12px;
		}
	}

	.gradient-deco {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		pointer-events: none;
	}

	> .container {
		padding-top: 120px;
		display: flex;
		max-height: 100%;
		height: 100%;
		flex-wrap: nowrap;
		flex-direction: column;
		align-content: center;
		justify-content: center;

		@include tablet_portrait {
			overflow-y: scroll;
		}

		&.show-gradient {
			&:after {
				opacity: 1;
			}
		}

		.univers {

			width: 80%;
			margin: 0 auto;

			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			@include tablet_portrait {
				max-width: 320px;
				text-align: center;
			}

			&:after {
				content: "";
				display: block;
				height: 4px;
				background-color: #707070;
				position: absolute;
				top: 50%;
				transform: translate(-50%,-4px);
				left: 50%;
				width: 0;
				opacity: 0;
				pointer-events: none;
				transition: 400ms linear all;
			}


			.titre {
				display: block;
				@include ft($f_txt, 26px, 200, $c_white, 26px);
				letter-spacing: 0.2px;
				background:$c_dark_grey;
				padding: 0px 15px;
				position: relative;
				z-index: 9;
				transition: 200ms linear all;
			}

			&:hover,
			&.not-active {
				.titre {
					color: $c_mice;
				}
				&:after {
					opacity: 1;
					width: 100%;
				}
			}

			&.currently-hovered {
				box-shadow: 0 30px 90px #171A1C;
			}

			& + .univers {
				margin-top:20px;
			}

		}

	}


}

.overlay-mice-map {

	max-width: 100%;
	background-color: $c_dark_grey;
	padding: 0;

	.close-overlay {
		position: absolute;
		right: 20px;
		top: 20px;
		background: #FFF;
		border-radius: 5px;
		padding: 9px 20px 7px 20px;
		line-height: 24px;
	}

	.custom-popup-thuria {
		width: 405px;
		margin-bottom: 25px;
		@include mobile {
			position: fixed;
			bottom: 0 !important;
			top: auto !important;
			left: 0 !important;
			width: 100%;
			transform: none !important;
			margin: 0;
		}

		.leaflet-popup-content-wrapper {
			border-radius: 3px;
			@include addTransition();

			@include mobile {
				border-radius: 0px;
			}

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 11px 23px 11px 11px;
				@include addTransition();
			}

			.image-wrapper {
				flex-shrink: 0;
				width: 91px;
				@include aspect-ratio(91, 91);
				margin-right: 11px;

				figure, .no-photo {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}

			.caption {
				flex: 1 1 0px;
				.title {
					@include ft($f_txt, 16px, 600, $c_txt, 22px);
					margin-bottom: 5px;
					@include addTransition();
				}
			}
		}

		.leaflet-popup-close-button {
			@include mobile {
				display: none;
			}
		}

		.location-container {
			width: 100%;
			display: flex;
			align-items: center;

			.wrapper-cat,
			.location {
				display: flex;
				align-items: center;
				@include ft($f_hx, 11px, 300, $c_main, 16px);
				position: relative;
				text-transform: uppercase;
				letter-spacing: 1.5px;
				margin-bottom: 5px;
				margin-right: 5px;
			}

			.location {
				&:before{
					top: 2px;
				}
			}

			.wrapper-cat {
				&:before{
					top: 1px;
				}
			}

			.location-title {
				display: block;
				@include ft($f_hx, 18px, 600, $c_txt, 22px);
				text-transform: uppercase;
			}
		}

		.tarif {
			.price {
				line-height: 19px;
			}
			&.gratuit {
				@include ft($f_txt, 14px, 700, $c_mice, 14px);
			}
		}

	}



	.custom-markers {

		display: flex;
		align-items: center;
		justify-content: center;
		width: 34px !important;
		height: 34px !important;
		border-radius: 50%;
		background-color: $c_mice;
		border: solid 1px $c_mice;
		font-size: 18px;
		color: $c_white;

		@include addTransition();

	}

	/*

	.leaflet-touch .leaflet-bar {
		border: 1px solid #AD8F43;
		margin-left: 70px;
		margin-top: 40px;
		@include mobile {
			margin-left: 20px;
			margin-top: 20px;
		}

		a {
			width: 34px;
			height: 34px;
			background: transparent;
			border-bottom: transparent;

			&:last-of-type {
				border-top: 1px solid #AD8F43;
			}

			&:first-child, &:last-child {
				&:after {
					color: $c_white;
				}
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					background-color: $c_greenish_blue;
				}
			}
		}
	}
	*/

}
#overlay-mice-contact {
	.selectric {
		.label {
			height: 0;
			display: flex;
			align-items: center;
			font-size: 14px;
		}
	}
	#other_statut_div {
		display: none;
	}
	#label-file {
		top: 35%;
	}
	.frm_form_field input[type=file] {
		max-width: unset;
		display: none;
	}
	.optin_mice {
		font-size: 12px;
		line-height: 1.4rem;
	}
	#depot_file {
		cursor: pointer;
	}
}

/* Duplicate scrollbar */
body.hide-scroll::-webkit-scrollbar {
	display: none;
}
