.search-help {
	background-color: $c_white;
	margin-top: 3px;
	padding: 0 30px;
	border-radius: 4px;
	box-shadow: 0 0 30px rgba(0, 0, 0, .1);

	@include mobile {
		margin-top: 0;
		border-radius: 0;
		padding: 0;
		box-shadow: none;
	}

	&__bloc {
		position: relative;
		padding-top: 20px;

		&__title {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			color: #A0A5A8;
			font-weight: 300;

			@include mobile {
				font-size: 10px;
				line-height: 16px;
				padding-left: 15px;
				padding-right: 15px;
			}

			&::before {
				content: "";
				flex-grow: 1;
				height: 1px;
				background-color: #A0A5A8;
				margin-right: 8px;
			}

			&-icon {
				margin-left: 8px;
				font-size: calculateRem(15px);
			}
		}
	}

	&__suggestions {
		&-item {
			cursor: pointer;
			font-size: calculateRem(14px);
			line-height: calculateRem(21px);
			color: $c_main;
			font-weight: 400;
			padding: 6px 0;
			transition: color $t_duration;

			@include mobile {
				padding-left: 15px;
			}

			&:first-letter {
				text-transform: uppercase;
			}

			&:hover {
				body:not(.no-hover) & {
					color: $c_sub;
				}
			}
		}
	}

	&-highlight, em {
		font-weight: 700;
		font-style: normal;
	}

	&__tendances {

		.search-help__bloc__title {
			margin-bottom: 0;
		}

		&-list {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			padding-top: 20px;

			@include tablet_landscape {
				flex-wrap: nowrap;
				overflow-x: scroll;
				width: 100%;

				padding-left: 15px;
				padding-right: 15px;
			}
		}

		&-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 0 10px 10px 0;
			padding: 5px 30px 5px 5px;
			border-radius: 100px;
			box-shadow: 0 0 16px rgba($c_dark, .1);
			font-size: 12px;
			font-weight: 700;
			cursor: pointer;

			&--no-photo {
				padding: 5px 30px;
			}

			img {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				margin-right: 10px;
			}

			@include tablet_landscape {
				flex-shrink: 0;
			}
		}
	}

	&__recents {
		&-list {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			@include tablet_landscape {
				flex-direction: column;
				align-items: flex-start;
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		.card {
			width: 50%;

			@include tablet_landscape {
				width: 100%;

				&:first-child {
					margin-bottom: 10px;
				}
			}

			div:first-child {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;

				figure, .no-photo {
					margin-right: 16px;

					@include tablet_landscape {
						width: 60%;
						border-radius: 5px;
					}
				}

				.caption {
					margin-top: 0;
					display: flex;
					flex-direction: column;
				}

				.encart-tarif {
					position: static;
					order: 3;

					.encart {
						display: none;
					}

					.tarif {
						position: static;
						font-size: 10px;
						padding: 0;
						height: auto;
						margin-top: 3px;

						.price {
							font-size: 12px;
						}
					}

					.title-card {
						@include mobile {
							font-size: 11px;
							line-height: 14px;
						}
					}

					&::before {
						display: none;
					}
				}
			}
		}
	}
}