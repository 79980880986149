.bloc-reassurance {
    display: flex;
    padding: 0;

    .col-wrapper-large {
        margin-left: -25px;
        margin-right: -25px;

        @include mobile {
            display: flex;
            flex: none;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            scroll-snap-align: start;

            margin-left: -20px;
            margin-right: -20px;
        }
    }
    &__content {
        padding-top: 40px;
        padding-bottom: 40px;
        
        @include mobile {
            margin-top: 14px;
            padding-top: 0;
            padding-bottom: 20px;
            overflow: hidden;
        }
    }

    &__column {
        padding-left: 25px;
        padding-right: 25px;

        @include mobile {
            flex-shrink: 0;
            width: 75%;
            padding-left: 10px;
            padding-right: 10px;

            &:first-child {
                margin-left: 10px;
            }

            &:last-child {
                margin-right: 10px;
            }


        }

        &__header {
            @include tablet_portrait {
                display: flex;
                align-items: center;
            }
        }

        &__icon {
            font-size: 50px;
            margin-bottom: 15px;

            @include mobile {
                margin-bottom: 4px;
                font-size: 42px;
            }
        }
        &__title {
            font-size: 20px;
            line-height: 26px;
            font-weight: 600;
            color: $c_greydark;
            margin-bottom: 10px;

            @include tablet_landscape {
                margin-left: 10px;
                line-height: 16px;
            }
            
            @include mobile {
                font-size: 14px;
                margin-bottom: 0;
            }
        }
        &__text p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: $c_greydark;
            margin-bottom: 0;

            @include mobile {
                font-size: 13px;
                line-height: 22px;
                margin-bottom: 0;
            }
        }
    }


}