//on single page
.myex-explorer {
	@include mobile {
		margin-bottom: calculateRem(90px); // height mobile button
	}

	//component page header
	&__header {
		display: flex;
		row-gap: calculateRem(15px);
		flex-direction: column;

		&__contentdesktop {
			display: flex;
			column-gap: calculateRem(15px);
			align-items: center;
			flex-wrap: nowrap;

			@include min-mobile {
				column-gap: calculateRem(33px);
			}

			.myex-explorer__header__desc {
				display: none;

				@include min-mobile {
					display: block;
				}
			}
		}

		&__contentmobile {
			display: block;

			@include min-mobile {
				display: none;
			}
		}

		&__avatar {
			width: calculateRem(120px);
			height: calculateRem(120px);
			border-radius: calculateRem(120px);
			overflow: hidden;
			flex-shrink: 0;
			background: var(--bg-card-img);

			@include min-mobile {
				width: calculateRem(220px);
				height: calculateRem(220px);
				border-radius: calculateRem(220px);
			}

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		//info superexplorateur
		&__superexplorer {
			margin-bottom: calculateRem(10px);
			display: block;
		}

		&__title {
			font-size: calculateRem(18px);
			font-weight: 600;
			line-height: 1.1;
			margin-bottom: 0;
			color: currentColor;

			@include min-mobile {
				font-size: calculateRem(40px);
			}
		}

		&__desc {
			margin-bottom: 0;
			font-size: calculateRem(11px);
			line-height: 1.7;
			color: currentColor;

			@include min-mobile {
				font-size: calculateRem(15px);
			}
		}

		&__meta {
			font-size: calculateRem(13px);
			display: flex;
			flex-wrap: wrap;
			margin-top: calculateRem(7px);
			font-size: calculateRem(11px);
			line-height: 1.27;
			column-gap: calculateRem(20px);
			row-gap: calculateRem(3px);

			@include min-mobile {
				margin-top: calculateRem(15px);
				column-gap: calculateRem(20px);
				font-size: calculateRem(13px);
			}

			&__item {
				@include ellipsis(1);
			}
		}

		&__actions {
			display: flex;
			position: fixed;
			gap: calculateRem(10px);
			bottom: 0;
			left: 0;
			width: 100%;
			padding: calculateRem(16px);
			justify-content: center;
			background: $bg-my-explore;
			z-index: $-z-mobile-actions;

			@include min-mobile {
				gap: calculateRem(20px);
				margin-top: calculateRem(22px);
				position: relative;
				background: transparent;
				padding: 0;
				justify-content: start;
				flex-wrap: wrap;
				z-index: 0;
			}

			[class^="myex-button"] {
				flex-grow: 1;
				@include min-mobile {
					flex-grow: 0;
				}
			}
		}
	}

	&.loading {
		display: none;
	}
}
