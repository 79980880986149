// use for each section inside page
.myex-section {
	margin-bottom: calculateRem(40px);

	&:last-child{
		margin-bottom: 0;
	}

	@include min-mobile {
		margin-bottom: calculateRem(60px);
	}

	&__header {
		display: flex;
		align-items: self-end;
		margin-bottom: calculateRem(20px);
		justify-content: space-between;
		@include min-mobile {
			margin-bottom: calculateRem(40px);
		}

		&--border-bottom {
			border-bottom: 1px solid var(--color-border);
			padding-bottom: calculateRem(10px);

			@include min-mobile {
				padding-bottom: calculateRem(32px);
			}
		}

		button {
			display: none;
			@include min-mobile {
				display: flex;
			}
		}
	}

	&__title {
		font-size: calculateRem(18px);
		font-weight: 600;
		color: currentColor;
		line-height: 1.2;
		margin: 0;
		@include min-mobile {
			font-size: calculateRem(28px);
		}

		&--big {
			font-size: calculateRem(25px);

			@include min-mobile {
				font-size: calculateRem(33px);
			}

			@include min-desktop {
				font-size: calculateRem(40px);
			}
		}
	}

	&__slider {
		position: relative;
	}
}
