.destinations {
    position: relative;
    background-color: $c_greydark;
    padding-top: 20px;

    @include tablet_portrait {
        padding-bottom: 20px;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            height: 110px;
            background: $c_greydark;
        }
    }

    @include mobile {
        padding-top: 0;
    }

    .bloc-large {
        @include tablet_portrait {
            padding-bottom: 20px;
        }

        @include mobile {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__wrapper {
        display: flex;

        @include tablet_landscape {
            flex-direction: column-reverse;
        }
    }

    &__regions {
        position: relative;
        z-index: 3;
        width: 50%;

        @include tablet_landscape {
            width: 100%;

            display: grid;
            gap: 12px;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__sticky-container {
        width: 50%;

        @include tablet_landscape {
            width: 100%;
        }
    }

    &__sticky {

        &__content {
            position: sticky;
            top: 120px;
            max-height: 100vh;
        }

        &__map {
            position: relative;
            top: -170px;
            display: flex;
            align-items: flex-end;
            margin-left: -80px;

            svg.js-destinations-map {
                [data-region-display="false"] {
                    display: none;
                }
            }

            @include tablet_landscape {
                display: none;
            }
        }
    }

    &__title {
        z-index: 3;
        margin-left: 55px;
        font-size: 60px;
        line-height: 60px;
        color: #fff;
        font-weight: 600;

        strong {
            display: block;
            color: $c_main;
            font-weight: 600;
        }

        @include tablet_landscape {
            position: static;
            font-size: 25px;
            margin-left: 0;

            strong {
                display: inline;
            }
        }
    }

    &__cta {
        z-index: 3;
        margin-left: 55px;
        color: #fff;
        padding-left: 40px;
        padding-right: 40px;

        &:hover {
            background-color: rgba(white, 0.5) !important;
        }

        @include tablet_landscape {
            margin-left: 0;
            margin-bottom: 30px;
        }

        &--desktop{
            @include tablet_portrait {
                display: none;
            }
        }

        &--mobile{
            display: none;
            
            @include tablet_portrait {
                display: block;
                box-sizing: border-box;
                margin-bottom: 20px;
                padding-top: 18px;
                padding-bottom: 18px;
            }
        }
    }

    &__region-card {
        display: flex;
        margin-bottom: 40px;
        align-items: center;

        @include tablet_landscape {
            margin-bottom: 0;
        }

        &:nth-child(even) {
            flex-direction: row-reverse;

            .card-thematique {
                margin-right: -20px;
                margin-left: 40px;

                @include tablet_landscape {
                    margin: auto;
                }
            }
        }

        &:nth-child(odd) {
            .card-thematique {
                margin-left: 0;
                margin-right: 40px;

                @include tablet_landscape {
                    margin: auto;
                }
            }
        }

        .card-thematique {
            border-radius: 10px;
            width: 56%;
            flex-shrink: 0;

            @include tablet_landscape {
                width: 100%;
            }

            figure, .no-photo {
                @include aspect-ratio(400, 600);

                &::after {
                    display: none;

                    @include tablet_landscape {
                        display: inherit;
                        background: rgba(#202529, 0.4);
                        height: 100%;
                    }
                }
            }

            .caption {
                display: none;

                @include tablet_landscape {
                    display: inherit;
                }

                .title-card {
                    font-size: 18px;
                    margin-bottom: 50%;
                    @include mobile {
                        overflow: visible;
                    }
                }
            }


        }


        .text-content {
            @include tablet_landscape {
                display: none;
            }

            .btn {
                background-color: transparent;
                border: 1px solid rgba(white, 0.5);
                color: white;

                &:hover {
                    background-color: rgba(white, 0.5);
                }
            }
        }



        h4 {
            @include ft($f_hx, 40px, 500, $c_white, 40px);
        }

        p {
            @include ft($f_txt, 15px, 400, $c_white, 28px);
        }

    }

}