// on card collection / explorer / product

.myex-button-actions {
	position: relative;
	&__popup {
		@extend .bg--light;
		z-index: $-z-option;
		display: flex;
		gap: calculateRem(20px);
		flex-direction: column;
		align-items: flex-start;
		padding: calculateRem(20px) calculateRem(15px);
		opacity: 0; /* Commencez avec une opacité à 0 */
		transition: all 0.3s ease; /* Animation de transition */
		pointer-events: none;
		position: absolute;
		width: 100vw;
		max-width: calculateRem(230px);
		transform: translate(0%, -100%);
		right: 0;
		border-radius: 0;
		top: 0;

		@include min-mobile {
			max-width: calculateRem(280px);
			right: unset;
			left: 50%;
			transform: translate(-50%, -100%);
			padding: calculateRem(20px) calculateRem(20px);
		}

		&.is-active {
			top: calculateRem(-16px);
			pointer-events: all;
			opacity: 1;
		}

		&__title {
			font-size: calculateRem(25px);
			font-weight: 600;
			line-height: 1;
		}

		&__item {
			appearance: none;
			border: none;
			background: transparent;
			font-size: calculateRem(13px);
			font-weight: 600;
			text-align: inherit;
			padding: 0;
			display: flex;
			align-items: center;
			text-align: left;
			gap: calculateRem(13px);
			line-height: 1;
			transition: all 300ms linear;
			color: currentColor;
			cursor: pointer;

			&:before {
				font-size: calculateRem(16px);
			}

			&:hover {
				color: var(--color-primary) !important; // overwrite
			}
		}
	}
}
