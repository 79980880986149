.marker-cluster-small,
.marker-cluster-small div,
.marker-cluster-medium,
.marker-cluster-medium div,
.marker-cluster-large,
.marker-cluster-large div {
	background-color: rgba($c_main, 0.6);
}


.clustergroup-image {
	&.marker-cluster-small,
	&.marker-cluster-small div,
	&.marker-cluster-medium,
	&.marker-cluster-medium div,
	&.marker-cluster-large,
	&.marker-cluster-large div {
		background-color: rgba($c_third, 0.6);
	}
}

.clustergroup-etape {
	&.marker-cluster-small,
	&.marker-cluster-small div,
	&.marker-cluster-medium,
	&.marker-cluster-medium div,
	&.marker-cluster-large,
	&.marker-cluster-large div {
		background-color: rgba($c_sub, 0.6);
	}
}





/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
	background-color: $c_main;
}
.leaflet-oldie .marker-cluster-small div {
	background-color: $c_main;
}

.leaflet-oldie .marker-cluster-medium {
	background-color: $c_main;
}
.leaflet-oldie .marker-cluster-medium div {
	background-color: $c_main;
}

.leaflet-oldie .marker-cluster-large {
	background-color: $c_main;
}
.leaflet-oldie .marker-cluster-large div {
	background-color: $c_main;
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	color:#fff;
	font-size: 12px;
	font-weight: 700;
	//font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
	line-height: 32px;
	font-family: $f_txt;
}