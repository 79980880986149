#header {
	//background: linear-gradient(rgba(30, 37, 41, 100%) 0%, #000 100%);

	&:after {
		content: "";
		position: absolute;
		left: 0;
		background: url("../images/my-explore/header-radius.png");
		width: 100%;
		height: var(--header-height);
		background-size: 100% var(--header-height);
		background-repeat: no-repeat;
		top: calculateRem(12px);

		@include min-mobile {
			top: calculateRem(7px);
		}

		@include min-tablet_portrait {
			display: none;
		}
	}

	.menu-barre-mobile {
		background: transparent;
	}
}
