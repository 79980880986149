// -------eye to see password
// wrap element with a span myex-input-see-pwd
.myex-input-geolocalisation {
	position: relative;

	input {
		padding-right: calculateRem(50px);
	}

	&__button {
		position: absolute;
		right: calculateRem(25px);
		top: 50%;
		transform: translateY(-50%);
		padding: 0;
		margin: 0;
		background: none;
		display: block;
		font-size: calculateRem(15px);
		z-index: 100;
		cursor: pointer;
		transition: all 0.2s ease;
		color: currentColor;
		border: none;

		@extend .icon-geolocalisation;

		&:before {
			@include font();
		}

		&:hover {
			color: var(--color-primary);
		}
	}
}
