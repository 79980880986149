@mixin mosaique-img-full {
    width: 100%;
}

@mixin mosaique-img-94 {
    width: 94%;
    @include mobile{
        width: 100%;
    }
}

@mixin mosaique-img-square {
    width: calc(32%);
    @include mobile {
        width:calc(50% - 15px);
    }

    figure {
        @include aspect-ratio(1, 1);
        overflow: visible;
    }
}

@mixin mosaique-img-two-third {
    width: 60%;
    @include desktop {
        width: 59%;
    }
    @include mobile{
        width: 100%;
    }
}


// A chaque changement de nombre d'images, la mosaïque a un comportement différent
// Difficile de garder le tout bien factorisé

.bloc-mosaique-v2 {

    @include mobile {
        padding-left: 0;
        padding-right: 0;
    }

    .wrapper-mosaique {

        .item:nth-child(1) {
            @include mosaique-img-two-third;
            margin-left: 6%;

            @include mobile{
                margin-left: 0;
            }
        }

        .item:nth-child(2) {
            @include mosaique-img-square;
            margin-left: auto;
            align-self: flex-end;

            @include mobile{
                margin-left: 0;
                margin-top: 20px;
            }
        }

        &.nb-images-1 {
            .item {
                @include mosaique-img-full;
            }
        }

        &.nb-images-3 {
            .item:nth-child(3) {
                @include mosaique-img-94;
                margin-top: 30px;
                padding-left: 6%;

                @include mobile{
                    @include mosaique-img-square;
                    margin-left: 20px;
                    margin-top: 20px;
                    padding-left: 0;
                }
            }
        }

        &.nb-images-4 {
            .item:nth-child(3) {
                @include mosaique-img-square;
                margin-top: 30px;

                @include mobile{
                    margin-left: 20px;
                    margin-top: 20px;
                }
            }

            .item:nth-child(4) {
                @include mosaique-img-two-third;
                margin-top: 30px;
                margin-left: auto;
                margin-right: 6%;

                @include mobile{
                    margin-right: 0;
                    margin-top: 20px;
                }
            }
        }

        &.nb-images-5 {
            .item:nth-child(3) {
                @include mosaique-img-94;
                margin-top: 30px;
                margin-left: auto;
                margin-right: 6%;

                @include mobile{
                    @include mosaique-img-square;
                    margin-left: 20px;
                    margin-right: 0;
                    margin-top: 20px;
                }
            }

            .item:nth-child(4) {
                @include mosaique-img-square;
                margin-top: 30px;

                @include mobile{
                    @include mosaique-img-full;
                    margin-top: 20px;
                }
            }

            .item:nth-child(5) {
                @include mosaique-img-two-third;
                margin-top: 30px;
                margin-left: auto;
                margin-right: 6%;

                @include mobile{
                    margin-right: 0;
                    margin-top: 20px;
                }
            }
        }

        &.nb-images-6 {
            .item:nth-child(3) {
                width: 45%;
                margin-top: 30px;
                margin-left: 6%;

                figure {
                    @include aspect-ratio(1, 0.907);
                }

                @include mobile{
                    @include mosaique-img-square;
                    margin-left: 20px;
                    margin-top: 20px;
                }
            }

            .item:nth-child(4) {
                @include mosaique-img-square;
                width: 40.8%;
                margin-top: 30px;
                margin-left: 30px;

                @include desktop{
                    width: calc(43% - 30px);
                }

                @include mobile{
                    @include mosaique-img-square;
                    margin-left: 0;
                    margin-top: 20px;
                    margin-right: 0;
                }
            }

            .item:nth-child(5) {
                @include mosaique-img-square;
                margin-top: 30px;

                @include mobile{
                    margin-left: 20px;
                    margin-top: 20px;
                }
            }

            .item:nth-child(6) {
                @include mosaique-img-two-third;
                margin-top: 30px;
                margin-left: auto;
                margin-right: 6%;

                @include mobile{
                    margin-right: 0;
                    margin-top: 20px;
                }
            }
        }


        &.nb-images-7 {
            .item:nth-child(2) {
                @include mobile{
                    @include mosaique-img-full;
                }
            }

            .item:nth-child(3) {
                @include mosaique-img-94;
                margin-top: 30px;
                margin-left: auto;
                margin-right: 6%;

                @include mobile{
                    @include mosaique-img-full;
                    margin-right: 0;
                    margin-top: 20px;
                }
            }

            .item:nth-child(4) {
                width: 47%;
                margin-top: 30px;
                figure {
                    @include aspect-ratio(1, 0.957);
                }

                @include desktop{
                    width: calc(49% - 30px);
                }

                @include mobile{
                    @include mosaique-img-square;
                    margin-top: 20px;
                }
            }

            .item:nth-child(5) {
                @include mosaique-img-square;
                width: 44.9%;
                margin-top: 30px;
                margin-left: 30px;

                @include mobile{
                    @include mosaique-img-square;
                    margin-left: 30px;
                    margin-right: 0;
                    margin-top: 20px;
                }
            }

            .item:nth-child(6) {
                @include mosaique-img-square;
                margin-top: 30px;

                @include mobile{
                    @include mosaique-img-full;
                    margin-top: 20px;
                }
            }

            .item:nth-child(7) {
                @include mosaique-img-two-third;
                margin-top: 30px;
                margin-left: auto;
                margin-right: 6%;

                @include mobile{
                    margin-right: 0;
                    margin-top: 20px;
                }
            }
        }

        &.nb-images-8 {
            .item:nth-child(3) {
                width: 47%;
                margin-top: 30px;
                figure {
                    @include aspect-ratio(1, 0.955);
                }

                @include mobile{
                    @include mosaique-img-square;
                    margin-left: 20px;
                    margin-top: 20px;
                }
            }

            .item:nth-child(4) {
                @include mosaique-img-square;
                width: 44.9%;
                margin-top: 30px;
                margin-left: 30px;

                @include mobile{
                    @include mosaique-img-full;
                    margin-left: 0;
                    margin-top: 20px;
                }
            }

            .item:nth-child(5) {
                @include mosaique-img-square;
                width: 44.9%;
                margin-top: 30px;
                margin-left: auto;

                @include mobile{
                    @include mosaique-img-square;
                    margin-top: 20px;
                }
            }

            .item:nth-child(6) {
                width: 47%;
                margin-top: 30px;
                margin-left: 30px;
                figure {
                    @include aspect-ratio(1, 0.955);
                }

                @include mobile{
                    @include mosaique-img-square;
                    margin-left: 20px;
                    margin-top: 20px;
                }
            }

            .item:nth-child(7) {
                @include mosaique-img-square;
                margin-top: 30px;

                @include mobile{
                    @include mosaique-img-full;
                    margin-top: 20px;
                }
            }

            .item:nth-child(8) {
                @include mosaique-img-two-third;
                margin-top: 30px;
                margin-left: auto;
                margin-right: 6%;

                @include mobile{
                    margin-right: 0;
                    margin-top: 20px;
                }
            }
        }

        &.nb-images-9 {
            .item:nth-child(3) {
                width: 47%;
                margin-top: 30px;
                figure {
                    @include aspect-ratio(1, 0.955);
                }

                @include mobile{
                    @include mosaique-img-square;
                    margin-left: 20px;
                    margin-top: 20px;
                }
            }

            .item:nth-child(4) {
                @include mosaique-img-square;
                width: 44.9%;
                margin-top: 30px;
                margin-left: 30px;

                @include mobile{
                    @include mosaique-img-full;
                    margin-left: 0;
                    margin-top: 20px;
                }
            }

            .item:nth-child(5) {
                @include mosaique-img-94;
                margin-top: 30px;
                margin-left: 6%;
                margin-right: 6%;

                @include mobile{
                    margin-right: 0;
                    margin-left: 0;
                    margin-top: 20px;
                }
            }

            .item:nth-child(6) {
                @include mosaique-img-square;
                width: 44.9%;
                margin-top: 30px;
                margin-left: auto;

                @include mobile{
                    @include mosaique-img-square;
                    margin-top: 20px;
                }
            }

            .item:nth-child(7) {
                width: 47%;
                margin-top: 30px;
                margin-left: 30px;
                figure {
                    @include aspect-ratio(1, 0.955);
                }

                @include mobile{
                    @include mosaique-img-square;
                    margin-left: 20px;
                    margin-top: 20px;
                }
            }

            .item:nth-child(8) {
                @include mosaique-img-square;
                margin-top: 30px;

                @include mobile{
                    @include mosaique-img-full;
                    margin-left: 0;
                    margin-top: 20px;
                }
            }

            .item:nth-child(9) {
                @include mosaique-img-two-third;
                margin-top: 30px;
                margin-left: auto;
                margin-right: 6%;

                @include mobile{
                    margin-right: 0;
                    margin-top: 20px;
                }
            }
        }

    }


    .wrapper-mosaique {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        @include clearfix();

        .item {
            position: relative;

            a{
                position: relative;
                display: block;
                &:hover, &:focus{
                    body:not(.no-hover) &{
                        figure{
                            img{
                                opacity: 0.6;
                            }
                        }
                        .cta-gallery{
                            background: $c_greydark;
                            border-color: $c_greydark;
                        }
                    }
                }
            }

            figure {
                @include aspect-ratio(880, 543);
                overflow: visible;
                img {
                    @extend %center;
                    @include addTransition();
                }
                figcaption{
                    @include mobile{
                        display: none;
                    }
                }
            }
            .credits {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                display: inline-block;
                color: $c_white;
                text-transform: unset;
                white-space: initial;
                overflow: visible;
                line-height: 18px;

                &:hover {
                    span:last-child {
                        opacity: 1;
                    }
                }


                span:first-child {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    background-color: $c_greydark;
                }

                span:last-child {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    padding: 20px;
                    display: block;
                    font-style: italic;
                    pointer-events: none;
                    opacity: 0;
                    background-color: $c_greydark;
                    z-index: -9;
                    transition: all 0.2s ease-in;
                }

                @include mobile{
                    display: none;
                }
            }
        }
    }
}

