.bloc-explorer-thematique {
    margin-bottom: 95px;
    /*height: 40vh;*/

    @include tablet_portrait {
        margin-bottom: 75px;
    }

    .splide:not(.is-overflow) .splide__pagination {
        display: flex;
    }

    .thematique-title {
        padding-left: 13vw;
        font-size: 28px;
        font-weight: 500;
        color: white;

        @include tablet_portrait {
            padding-left: 7vw;
        }
    }

    .thematique-cta-desktop {
        position: absolute;
        right: 13vw;

        @include tablet_portrait {
            display: none;
        }
    }

    .thematique-cta-mobile {
        display: none;
        position: relative;
        bottom: -55px;
        left: 40px;
        width: calc(100% - 80px);

        @include tablet_portrait {
            display: inline-block;
        }

        @include mobile {
            left: 20px;
        }
    }

    .splide__track {
        margin-top: 50px;

        .splide__slide {

            a {
                .hovered {
                    opacity: 0;

                    &:after {
                        background: linear-gradient(to bottom, rgba(32,37,41,0) 0%, #202529 100%);
                        content: '';
                        z-index: 0;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;

                    }
                }

                &:hover {
                    .hovered {
                        transition: all .2s ease;
                        opacity: 1;
                    }
                }
            }

            .item {

                figure, img {
                    border-radius: 10px;

                }

                .credits, figcaption {
                    display: none;
                }

                .thematique-tab-data {
                    position: absolute;
                    left: 50%;
                    translate: -50%;
                    bottom: -15px;
                    text-align: center;
                    z-index: 5;

                    div {
                        background-color: #1E2529;
                        padding: 16px 30px 40px 30px;
                        border-radius: 100%;
                        width: 100px;
                        height: 100px;

                        .icon {
                            color: white;
                            font-size: 35px;
                            margin: 0 0 5px 0;

                            @include mobile {
                                font-size: 30px;
                            }
                        }
                    }

                    .thematique-item-title {
                        color: white;
                        font-size: 12px;
                        margin: 0;
                        position: absolute;
                        font-weight: 500;
                        line-height: 15px;
                        bottom: 14px;
                        left: 50%;
                        translate: -50%;
                        width: 100px;
                    }
                }
            }
        }
    }

    .splide__arrow {
        top: unset;
        bottom: -68px;
        background-color: #1E2529;

        svg {
            fill: white;
        }

        &--prev {
            left: unset;
            right: 16vw;

            @media screen and (min-width: 1440px) {
                right: 15vw;
            }
        }

        &--next {
            right: 13vw;
        }

        span:before {
            font-size: 18px;
        }

        @include tablet_portrait {
            display: none;
        }
    }

    .class-pagination {

        bottom: -3.5em;
        padding-right: 7em;
        padding-left: 0;

        @include tablet_portrait {
            padding: 0 2em 0 2em;
        }

        @include mobile {
            padding: 0;
        }
    }
    .class-page {
        margin: 0;
        height: 1px;
        border-radius: 8px;
        width: 100%;
        background-color: #627A89;
        &.is-active {
            height: 5px;
            background-color: #DEA99E;
            opacity: 1;
            transform: unset;
            padding-bottom: 2px;
        }
    }
}

