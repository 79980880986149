// ------- form label
.myex-label,
label {
	font-size: calculateRem(12px);
	font-weight: 600;
	line-height: 1.42;
	margin-bottom: 0;

	@include min-mobile {
		font-size: calculateRem(14px);
	}

	.myex-form-field__label-info {
		font-weight: normal;
	}
}
