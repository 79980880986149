////////////
.page-listing {
	box-sizing: border-box;

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}


}

.btn-map-overlay {
	position: sticky;
	bottom: 20px;
	left: 50%;
	z-index: 1000;
	translate: -50%;
	max-width: 120px;

	@include tablet_small {
		bottom: 95px;
	}

	@include min-tablet_small {
		margin-top: 50px;
	}

	@include min-tablet_portrait {
		display: none;
	}

}

.app-archive-offres {
	.background-header {
		width: 100%;
		height: 20px;
		background-color: #1E2529;
		position: fixed;
		z-index: 1000;

		@include min-mobile {
			display: none;
		}
	}
}

.app-archive-offres {
	.background-filter {
		position: fixed;
		top: -100px;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: black;
		opacity: 0.7;
		z-index: 10000;
		display: none;

		&.open {
			display: block;
		}
	}


	#overlay-filtres {
		.noUi-tooltip {
			visibility: hidden;
		}
	}
}

.app-archive-offres, #app-search-v3 {
	#overlay-filtres {

		.facettes {
			display: none;

			.wrap-select {
				display: flex;
				align-items: center;
				right: 10px;
				top: 14px;

				label {
					padding-right: 3px;
				}

				#moteur-explore {
					padding: 0 0 2px 0;
					width: unset;
					border: none;
				}

				#moteur-explore-overlay {
					@include mobile {
						border: 0;
						color: black;
						padding-top: 0;
						padding-bottom: 0;
						padding-left: 0;
						padding-right: 8px;
						width: auto;
					}
				}

				&:after {
					top: 40%;
					right: 5px;
					content: '\e9a7'
				}
			}
		}

		@include mobile {
			top: 75px;
			border-radius: 15px 15px 0 0;

			.top {
				border-radius: 15px 15px 0 0;
				margin-bottom: 7px;
				margin-top: 6px;

				.facettes {
					display: block;
				}
			}

			.bloc-form {

				.scroller {
					padding-top: 10px;

					.group-checkbox {
						padding-bottom: 0;
					}
				}
			}
		}

		@include min-mobile {
			.facette-slider {
				padding-bottom: 20px;
			}
			.top {
				.title-overlay {
					padding-top: 40px;
				}
			}
		}

		.switch {
			padding-bottom: 26px;
			padding-top: 21px;
		}

		.label-facette-cible {
			width: 46%;
			margin: 0 15px 15px 0;

			.value {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 22px 31px 22px;

				span {
					font-size: 42px;
    				padding-bottom: 10px;
				}
			}

			input:checked ~ .value {

				&:after {
					font-family: 'icomoon' !important;
					content: '\e940';
					color: $c_white;
					border: 2px solid $c_white;
					background-color: $c_third;
					padding: 0 7px 13px 5px;
					font-size: 9px;
					position: absolute;
					top: -10px;
					left: 96%;
					border-radius: 25px;

					@include mobile {
						padding-left: 4px;
					}
				}
			}

			@include mobile {
				width: 100%;

				.value {
					flex-direction: row;
					justify-content: center;
					padding-top: 20px;
					padding-bottom: 15px;

					span {
						font-size: 40px;
						margin-right: 8px;
						margin-top: -5px;
					}
				}
			}
		}

		.type-destination {

			input:checked ~ .value {

				&:after {
					font-family: 'icomoon' !important;
					content: '\e940' !important;
					color: #fff;
					border: 2px solid #fff;
					background-color: #DEA99E;
					padding: 5px 14px 14px 4px;
					font-size: 9px;
					position: absolute;
					top: -55px;
					left: 92%;
					border-radius: 25px;
					font-weight: 100;

					@include mobile {
						top: -19vw;
						padding-right: 13px;
					}
				}
			}
		}

		.filter-button {
			position: sticky;
			bottom: 0;
			z-index: 1000;
			background-color: white;
			padding: 17px 40px 18px 40px;
			border-top: none;

			@include mobile {
				padding: 14px 15px 15px 15px;
				position: absolute;
				width: 100vw;
				left: 0;
				-webkit-transform: translate3d(0, 0, 0);

				box-shadow: 0px 9px 20px 12px #0000003b;
			}

			&::after {
				content: '';
				position: absolute;
				top: -34px;
				left: 0;
				right: 0;
				bottom: 42px;
				z-index: -1;
				background: linear-gradient(0deg, #fff 0%, #fff 50%, rgba(255, 255, 255, 0) 100%);

				@include mobile {
					content: unset;
				}
			}

			button {
				width: 100%;

				&.return-to-list {
					background-color: transparent;
					color: $c_greydark;
					text-decoration: underline;
					font-size: 11px;
				}
			}
		}

		.classement-labels-filters {
			.classement-title {
				margin-bottom: 0;
			}

			.frm_checkbox {
				display: flex;
				flex-direction: column;

				div {
					.title-group {
						font-size: 12px;
						font-weight: normal;
						margin-bottom: 10px;
						margin-top: 20px;
					}
				}

				span.value {
					padding-top: 14px;
					padding-bottom: 7px;

					.icon {
						margin: 0 2px;
					}
				}
			}
		}

		.padding-0, .padding-1 {
			input:checked ~ .value {

				padding-right: 45px;

				&:after {
					font-family: 'icomoon' !important;
					content: '\e940';
					color: $c_white;
					position: absolute;
					left: unset;
					right: 22px;
					top: 11px;
					font-size: 10px;
					background: transparent;
				}
			}
		}

		.padding-1 {
			input:checked ~ .value {

				&:after {
					top: 8px;
				}
			}
		}


	}
}

.header-listing {
	margin-top: 75px;
	padding-bottom: 12px;
	border-radius: 15px 15px 0 0;

	@include tablet_portrait {
		margin-top: 50px;
	}

	@include mobile {
		position: sticky;
		top: 80px;
		z-index: 1100;
		height: 50px;
		margin-top: 0;
		padding-top: 13px;
		background-color: $c_white;
	}
}

.header-listing__inner {
	display: grid;
	grid-auto-columns: minmax(10px, 1fr);
	gap: 40px;

	.btn-filters-mobile-listing-offres {
		position: absolute;
		right: 20px;

		&:after {
			content: '\e9ac';
		}

		@include min-mobile {
			display: none;
		}
	}

	@include tablet_portrait {
		gap: 20px;
	}

	@include mobile {
		margin: 0 !important;
		padding: 0 20px 10px;
		background-color: white;
	}

	.col-left {
		grid-row: 1;
		grid-column: 1;
	}

	.col-right {
		grid-row: 1;
		grid-column: 2;

		@include tablet_portrait {
			grid-column: 1;
			grid-row: 2;
		}

		@include mobile {
			display: none;
		}

		.expanded {
			height: auto !important;
		}
	}

	.header-listing__title {
		color: $c_greydark;

		@include mobile {
			overflow: hidden; // truncate
			/*text-overflow: ellipsis;
			white-space: nowrap;*/
			margin-bottom: initial;
			padding-right: 90px;
			font-size: calculateRem(25px);

			&.is-scrolled {
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	.breadcrumb {

		a {
			color: #000;
		}

		span {
			font-weight: 600;
		}

		@include mobile {
			display: none;
		}
	}

	.header-listing__description {

		&:not(.expanded) {
			max-height: 70px;
		}

		@include min-tablet_portrait {
			text-align: right;
		}

		&:after {
			content: '';
			position: absolute;
			top: 10px;
			left: 0;
			right: 0;
			height: 95%;
			background: -moz-linear-gradient(bottom, $c_white 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(bottom, $c_white 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to top, $c_white 0%, rgba(0, 0, 0, 0) 100%);
			opacity: 1;
		}

		&.expanded {

			&:after {
				opacity: 0;
			}
		}

		p {
			font-size: calculateRem(13px);
			line-height: 1.69;
		}
	}
}


.toolbar-listing {
	position: sticky;
	top: 80px;
	z-index: 1200;
	background-image: linear-gradient(to right, $c_white, $c_white), linear-gradient(to right, $c_light_txt, $c_light_txt);
	background-repeat: no-repeat, no-repeat;
	background-size: 100% 99px, 100% 1px;
	background-position: left top, left calc(100% - 6px);

	@include mobile {
		top: calc(80px + 50px);
		background-size: 100% 59px, 100% 1px;
		background-position: left top, left calc(100% - 6px);
		overflow-x: hidden;
	}

	.toolbar-listing__inner {
		display: flex;
		align-items: center;
		height: 106px;

		@include mobile {
			height: 66px;
		}

		.splide__track {
			width: 63vw;

			@include min-tablet_landscape {
				width: calc(100vw - 440px);
			}

			@media (min-width: 1449px) and (max-width: 1599px) {
				width: 64vw;
			}

			@include wide {
				width: 67vw;
			}

			@media (min-width: 1750px) and (max-width: 1900px) {
				width: 62vw;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: unset;
				right: 0;
				height: 90%;
				background: -moz-linear-gradient(right, $c_white 40%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(right, $c_white 40%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to left, $c_white 40%, rgba(0, 0, 0, 0) 100%);
				opacity: 1;
				width: 150px;
			}

			@include mobile {
				width: 100vw;

				&:after {
					content: unset;
				}
			}

			.splide__slide {
				@include mobile {
					width: auto !important;
				}
			}
		}

		.splide__arrow {
			top: unset;
			bottom: -10px;
			background-color: white;
			opacity: 1;
			border-radius: unset;
			padding: 35px;

			&:hover {
				opacity: 1;
			}

			&--prev {
				left: unset;
				right: 45px;
				padding-left: 10px;

				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: unset;
					right: 45px;
					height: 100%;
					background: -moz-linear-gradient(right,  $c_white 20%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(right,  $c_white 20%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to left,  $c_white 20%,rgba(0,0,0,0) 100%);
					opacity: 1;
					width: 150px;
				}
			}

			&--next {
				right: 0;
			}

			span:before {
				font-size: 18px;
			}

			@include tablet_landscape {
				display: none !important;
			}
		}
	}

	.item-categorie {
		padding-bottom: 6px;
	}

	.btn-categorie {
		position: relative;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		@include ft('', 12px, 600, $c_greydark, 1.16);
		text-align: center;
		@include addTransition();

		@include mobile {
			font-size: 11px;
			height: 60px;
			width: auto;
			white-space: nowrap;
		}

		&.is-active:after {
			visibility: initial;
		}

		&.is-active, &.is-active span:before {
			color: $c_third;
		}

		&:hover {
			body:not(.no-hover) & {
				color: $c_third;
			}
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background-color: $c_third;
			transform: translate(-50%, 50%);
			visibility: hidden;
		}

		[class^="icon-"], [class*=" icon-"] {
			display: block;
			margin-bottom: 6px;
			font-size: 35px;

			@include mobile {
				margin-bottom: 4px;
				font-size: 22px;
			}
		}
	}


	.col-right {
		display: flex;
		align-items: center;
		margin-left: auto;
		position: absolute;
		right: 0;
		height: 85%;
		background-color: white;
		align-content: center;
		flex-wrap: wrap;
		padding-left: 15px;

		@include mobile {
			padding-left: 0;
		}

		* + .btn-filters {
			@include min-mobile {
				margin-left: 20px;
			}
		}

		.facettes {

			max-width: 100px;
			@include tablet_portrait {
				display: none;
			}

			.wrap-select {
				padding-right: 40px;
				margin-top: -15px;

				& > label {
					line-height: 1;
					padding-right: 0;
				}

				#moteur-explore {
					padding-left: 0;
					width: unset;
					border: none;
					color: $c_dark;
					position: absolute;
					left: -14px;
					top: -10px;
					background: none;
					cursor: pointer;
				}

				&:after {
					top: 100%;
					right: 0;
					content: '\e9a7'
				}

				select {
					line-height: 1;
				}
			}
		}

		.btn-filters {
			@include mobile {
				position: absolute;
				top: -42px;
				right: 0;
			}

			&:after {
				content: '\e9ac';
			}
		}
	}
}


.results-listing {
	padding: 20px 0 30px;
	text-align: right;

	@include mobile {
		display: none;
	}
}

.cards-listing {
	@include mobile {
		margin-top: -15px;
	}

	.index-result {
		text-align: right;
		padding-top: 20px;
		padding-bottom: 30px;

		span {
			color: #707070;
			opacity: 0.7;
		}

		@include mobile {
			display: none;
		}
	}

	.wrapper-map-cards {
		display: grid;
		grid-template-columns: minmax(435px, 1fr) minmax(10px, 1045px);
		gap: 40px;

		@include min-tablet_portrait {
			margin-left: calc(-1 * ((100vw - 100%) / 2) + 8px); // pour sortir la map de son wrapper et la caler sur le bord gauche du viewport
		}

		@include tablet_portrait {
			grid-template-columns: minmax(10px, 1fr);
		}
	}

	.wrapper-map {
		position: sticky;
		top: calc(80px + 100px);
		display: flex;
		flex-direction: column;
		height: calc(100vh - 80px - 100px);

		@include tablet_portrait {
			display: none;
		}

		.leaflet-control-container {
			.leaflet-top {
				right: 0;
				left: unset;

				@include mobile {
					display: none;
				}
			}
		}

		.v-mobile-map {
			width: 100vw;
			position: absolute;
			bottom: 0;
			left: -20px;

			.splide-listing-auto {
				z-index: 1000;
				visibility: visible;
				background-color: $c_white;
				position: fixed;
				bottom: 0;
				width: 100vw;

				.splide__list {
					padding: 10px 0 !important;

					a {
						div:first-child {
							display: flex;
							width: 100%;

							figure, .no-photo {
								max-width: 110px;
								height: 70px;
								margin-right: 10px;
								border-radius: 5px;
							}

							.caption {
								margin-top: 0;

								.metas-card {
									margin-bottom: 0;
								}

								.classement {
									display: none;
								}

								.location {
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 1;
									line-height: 18px;
									max-height: 36px;
								}

								.encart-tarif {
									position: absolute;
									bottom: -30px;
									max-width: 260px;

									.tarif {
										background-color: transparent;
										width: 155px;

										.price {
											font-size: 13px;
										}
									}
								}

								.encart {
									display: none;
								}

								.title-card {
									font-size: 11px;
									line-height: 13px;
								}
							}
						}
					}
				}

				.return-div {
					text-align: center;
					padding: 30px;
					border-top: 1px solid rgba(#ABB3B9, 0.5);
					height: 74px;

					span {
						text-decoration: underline;
						font-size: 11px;
						color: $c_txt;
					}
				}
			}
		}

		.maps {
			position: relative;
		}

		.move-search {
			position: absolute;
			z-index: 10000;
			top: 15px;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			align-items: center;
			padding: 12px 14px 12px 12px;
			@include ft('', 13px, 600, $c_white, 1.07);
			white-space: nowrap;
			background-color: $c_main;
			border-radius: 4px;
			cursor: pointer;

			span {
				flex-shrink: 0;
				position: relative;
				display: block;
				width: 32px;
				height: 16px;
				margin-right: 10px;
				border-radius: 90px;
				background-color: $c_white;

				&:before {
					content: '';
					position: absolute;
					top: 3px;
					left: 3px;
					width: 10px;
					height: 10px;
					background-color: $c_light_txt;
					border-radius: 50%;
					@include addTransition();
				}
			}

			input {
				display: none;

				&:checked {
					~ span {
						&:before {
							left: auto;
							right: 3px;
							background-color: $c_sub;
						}
					}
				}
			}
		}
	}

	.wrapper-listing-cards.basic-grid {
		row-gap: 40px;
		grid-auto-flow: row dense; // remplit les trous dû aux cards qui prennent deux cellules
		padding-bottom: 125px;

		@include mobile {
			row-gap: 20px;
			margin-top: 30px;
			padding-bottom: 0;
		}

		a.card-offre {
			max-height: 300px;

			@include mobile {
				max-height: none;
			}
		}
	}

	.map-mobile {
		display: block;
		z-index: 100;

		@include mobile {
			#map-listing {
				position: absolute !important;
				left: -20px;
				width: 100vw;
			}
		}
	}

	#map-listing {
		width: 100%;

		.leaflet-popup-pane {

			.custom-popup-thuria {

				a {

					div {
						align-items: unset;

						.title-card {
							position: absolute;
							margin-top: 25px !important;
						}

						.classement {
							display: none;
						}

						.metas-card {
							margin-top: 5px;

							.wrapper-cat {
								display: none;
							}

							.location {
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 1;
								line-height: 18px;
								max-height: 36px;
								padding-left: 12px;
							}

						}

						.encart-tarif {
							position: absolute;
							bottom: 0;
							top: unset;
							left: unset;
							right: unset;

							.tarif {
								background-color: transparent;
							}
						}
					}
				}
			}
		}
	}
}

.pagination-archives-offres {

	@include min-mobile {
		display: flex;
		justify-content: center;
		align-items: baseline;
		margin-top: 50px;
		margin-bottom: 50px;
		position: absolute;
		bottom: 0;
		width: 65%;


		button,
		a.next-page,
		a.previous-page {
			padding: 13px 38px;
			border: none;
			background-color: #F3F4F5;
			border-radius: 3px;
			margin-right: 20px;
			cursor: pointer;
			color: $c_dark;

			&.desktop1250 {
				padding: 13px 20px 16px;
				margin-right: 10px;

				span {
					margin-right: 0 !important;
				}
			}

			span {
				position: relative;
				top: 2px;

				&.icon-ico-chevron-left {
					margin-right: 15px;
				}

				&.icon-ico-chevron-right {
					margin-left: 15px;
				}
			}

			&:hover {
				background-color: $c_third;
				color: white;

				& span {
					color: white;
				}
			}

			&.next-page {
				background-color: $c_third;
				color: $c_white;
			}
		}

		a:not(.next-page):not(.previous-page) {
			margin: 0 10px;
			color: #707070;
			opacity: 0.7;
			font-size: 11px;

			&.active-page {
				font-size: 12px;
				color: black;
				display: flex;
				flex-direction: column;
				align-items: center;
				font-weight: 700;

				div {
					width: 6px;
					height: 6px;
					border-radius: 50px;
					background-color: $c_third;
					margin-top: 3px;
				}
			}
		}
	}

	@media (min-width: 980px) and (max-width: 1250px) {
		width: 55%;
	}
	@media (min-width: 600px) and (max-width: 979px) {
		width: 100%;
	}


	&.desktop-pagination {
		@include mobile {
			display: none;
		}
	}

	&.mobile-pagination {

		box-shadow: none;
		z-index: 95;

		&::after {
			content: '';
			position: absolute;
			top: -17px;
			left: 0;
			right: 0;
			bottom: 76px;
			z-index: 1;
			background-image: linear-gradient(to bottom, transparent 0, black 100%);
			opacity: 0.3;
		}

		.content-wrapper {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}

		@include min-mobile {
			display: none;
		}

		.next-page {

			&:after {
				margin-bottom: 1px;
			}
		}

		.select-page {

			&:after {
				margin-left: 12px;
				padding-bottom: 2px;
			}

			#select-pagination {
				text-align: right;
			}

			span {
				display: inline-block;
				width: 10px;

				select {
					opacity: 0;
					width: 35px;
					height: 45px;
					position: absolute;
					top: 0;
				}
			}
		}
	}
}


// Card Edito
.listing-offre-link-edito {
	display: flex;
	min-height: 200px;
	background-color: $c_greydark;
	border-radius: $border-radius;
	overflow: hidden;


	@include min-tablet_portrait {

	}

	@include min-mobile {
		grid-column: span 2; // Prend deux cellules

		> * {
			width: 50%;
		}
	}

	@include mobile {
		flex-direction: column-reverse;
	}

	.listing-offre-link-edito__caption {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 40px;

		@include tablet_small {
			padding: 20px;
		}
	}

	.listing-offre-link-edito__surtitre {
		@include ft('', 14px, 300, $c_main, 1.5);
		@include letter-spacing(150);
		text-transform: uppercase;

		& + * {
			margin-top: 4px;
		}
	}

	.listing-offre-link-edito__title {
		@include ft('', 18px, 600, $c_white, 1.22);

		& + * {
			margin-top: 10px;
		}
	}

	.listing-offre-link-edito__subtitle {
		@include ft('', 12px, 700, $c_white, 1.5);
		padding: 3px 10px 2px;
		background-color: $c_main;
		border-radius: 4px;

		& + * {
			margin-top: 20px;
		}
	}

	.listing-offre-link-edito__link {
		@include ft('', 13px, 700, #E4E4E4, 1.5);
		text-decoration: underline;
	}

	.listing-offre-link-edito__image {
		figure {
			height: 100%;

			@include mobile {
				@include aspect-ratio(530, 300);
				height: initial;

				> * {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	// -- modifiers
	&.--bg-blue {
		background-color: $c_main;

		.listing-offre-link-edito__surtitre {
			color: $c_greydark;
		}

		.listing-offre-link-edito__subtitle {
			background-color: $c_greydark;
		}

		.listing-offre-link-edito__link {
			color: $c_white;
		}
	}

	&.--bg-white {
		background-color: $c_white;

		.listing-offre-link-edito__title,
		.listing-offre-link-edito__link {
			color: $c_greydark;
		}
	}

	&.--bg-pink {
		background-color: $c_third;

		.listing-offre-link-edito__surtitre,
		.listing-offre-link-edito__link {
			color: $c_white;
		}

		.listing-offre-link-edito__subtitle {
			color: $c_third;
			background-color: $c_white;
		}
	}
}


.loading-card {
	pointer-events: none;

	figure {
		background: $c_grey;
		border-radius: 2px;
		background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
		background-size: 200% 100%;
		animation: 1s shine linear infinite;
	}

	.caption {
		> * {
			background: $c_grey;
			border-radius: 2px;
			background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
			background-size: 200% 100%;
			animation: 1s shine linear infinite;
			color: transparent;
		}

		.metas {
			width: 75%;
			height: 18px;
			margin-bottom: 10px;
		}

		.title {
			width: 95%;
			height: 18px;
			margin-bottom: 6px;
		}

		.title-2 {
			width: 35%;
			height: 18px;
		}
	}
}


@keyframes shine {
	to {
		background-position-x: -200%;
	}
}
