.splide__dossier {
    background-color: var(--white);
    margin: 0;
    padding-top: 44px;
    padding-bottom: 30px;
    .splide__scroll {
        left: 83px;
        bottom: 0;
    }
    .splide__arrow {
        left: 870px;
        bottom: 12px;
        &--next {
            left: 900px;
        }
    }
}