@mixin theme-light{
	--bg-field: #fff;
	--bg-border-field: rgb(220, 219, 218);
	--bg-card-img :  #{darken($bg-light, 5%)}; 
	--bg-grey-light: #{darken(#fff, 4%)};//#F8F8F8;


	--bg-field-hightlight: #{darken(#fff, 6%)};
	--bg-switch: rgb(220, 219, 218);

	--color-text: #202529;
	--color-text-lighter: #abb3b9;

	--bg-border-switch: rgb(220, 219, 218);

	//bouton outline
	--border-color--button--outline: rgba(24, 31, 34, 0.3);
	--color--button--outline: rgba(24, 31, 34, 1);

	//bouton primary

	--btn-primary-bg-hover: #181f22;
	--btn-primary-color-hover: #ffffff;

	--btn-secondary-bg-hover: #181f22;
	--btn-secondary-color-hover: #ffffff;

	//border
	--color-border: rgba(0, 0, 0, 30%);
	--current-bg: var(--bg-light);
}

//theme light
.bg--light {
	@include theme-light; 
	background-color: var(--current-bg);
	color: var(--color-text);
}
