/* ===== LAYOUT ===========================================
used for pages with left sidebar
*===== */



.is-sidebar-active {
	.myex-sidebar-mobile {
		display: block;
		@include min-tablet_portrait {
			display: none;
		}
	}
}

//---- Sidebar HIDE AND SHOW
// ------ when sidebar is active / and not active
@include min-tablet_portrait {
	.myex-column {
		// State : not active
		.myex-column__left {
			transform: translateX(-100%);
			transition: all ease 600ms;
			display: block; // active the sidebar
		}
		.myex-column__right {
			.my-ex--container {
				transition: padding ease 600ms;
			}
		}

		// State : active
		&.is-sidebar-active {
			.myex-column__left {
				transform: translateX(0%);
			}

			--side-padding-left: calc(
				#{calculateRem(226px)} + var(--side-padding-base) + 94px
			);
		}
	}
}

.myex-column {
	width: 100%;

	&__left {
		display: none;
		left: 0;
		top: calc(var(--header-height));
		height: calc(100vh - (var(--header-height)));
		width: calc(#{calculateRem(226px)} + var(--side-padding-base));
		padding-left: var(--side-padding-base);
		position: fixed;
		left: 0;
		z-index: $-z-sidebar;
		padding-top: var(--marge-content-top);
		padding-bottom: calculateRem(45px);

		//background
		// the background is added this way, because of the overflow cut the circle active menu
		&:after {
			content: "";
			width: 100%;
			background: var(--current-bg);
			right: calculateRem(6px);
			position: absolute;
			top: 0;
			z-index: -1;
			height: 100%;
		}
	}

	&__right {
		width: 100%;
		padding-top: 0;
		padding-bottom: calculateRem(60px);
		@include min-tablet_portrait {
			padding-bottom: calculateRem(115px);
			padding-top: var(--marge-content-top);
		}

		> *:first-child {
			padding-top: var(--side-padding-base);

			@include min-tablet_portrait {
				padding-top: 0;
			}
		}
	}
}
