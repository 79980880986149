//for share collection

.single-collections {
	@extend .bg--dark;
	background: $bg-my-explore;

	* {
		box-sizing: border-box;
	}

	&__header {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: calculateRem(25px);
		color: currentColor;
		margin-top: calculateRem(13px);
		margin-bottom: calculateRem(14px);
		padding: 0 calculateRem(30px);

		&:hover {
			color: currentColor !important;
		}

		&--wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		@include min-mobile {
			gap: calculateRem(38px);
			margin-top: calculateRem(48px);
			margin-bottom: calculateRem(26px);
		}

		&__logo-my-explore {
			max-width: calculateRem(150px);
			width: 100%;
			img {
				width: 100%;
				height: auto;
			}
			@include min-mobile {
				max-width: calculateRem(226px);
			}
		}

		&__logo-grand-est {
			max-width: calculateRem(92px);
			width: 100%;

			img {
				width: 100%;
				height: auto;
			}
			@include min-mobile {
				max-width: calculateRem(140px);
			}
		}
	}

	&__main {
		max-width: 1060px;
		margin: 0 auto;
		border-radius: var(--section-radius);
		margin-bottom: calculateRem(70px);
		background-color: $bg-dark;
		@extend .bg--dark;
		padding: calculateRem(28px) var(--side-padding-base);
		@include min-mobile {
			padding: calculateRem(60px) calculateRem(50px);
		}
	}

	&__top {
		max-width: calculateRem(307px);
		margin-left: auto;
		margin-right: auto;
		margin-bottom: calculateRem(30px);
		display: flex;
		flex-direction: column;
		align-items: center;

		@include min-mobile {
			// max-width: calculateRem(668px);
			max-width: 75%;
			margin-bottom: calculateRem(60px);
		}
	}

	&__avatar {
		background: var(--bg-card-img);
		overflow: hidden;
		border-radius: calculateRem(10px);
		margin-bottom: calculateRem(20px);
	}

	&__title {
		font-size: calculateRem(18px);
		font-weight: 600;
		line-height: 1.1;
		text-align: center;
		color: currentColor;
		margin-bottom: 0;

		@include min-mobile {
			font-size: clamp(
				calculateRem(18px),
				calc(3vw + 0.5rem),
				calculateRem(50px)
			);
		}

		@include min-desktop {
			font-size: calculateRem(50px);
		}
	}

	&__meta__numberOffers {
		font-size: calculateRem(15px);
		margin-top: calculateRem(11px);
		margin-bottom: calculateRem(11px);

		@include min-mobile {
			font-size: calculateRem(26px);
			margin-top: calculateRem(18px);
			margin-bottom: calculateRem(18px);
		}
	}

	&__meta__cats {
		font-size: calculateRem(11px);
		font-weight: 600;
		gap: calculateRem(10px);
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: center;

		@include min-mobile {
			font-size: calculateRem(12px);
		}

		&__item {
			border: 1px solid rgba(255, 255, 255, 0.3);
			padding: calculateRem(6px) calculateRem(14px) calculateRem(4px)
				calculateRem(14px);
			border-radius: calculateRem(4px);
			line-height: 1.1;
			flex-shrink: 0;
			text-align: center;
		}
	}

	&__content {
		.myex-section__list--vertical {
			.myex-card-product {
				figcaption, .credits {
					display: none;
				}
			}
		}
	}

	&__section {
		&__header {
			display: flex;
			align-items: self-end;
			margin-bottom: calculateRem(30px);
			justify-content: space-between;
			border-bottom: 1px solid var(--color-border);
			padding-bottom: calculateRem(10px);

			@include min-mobile {
				padding-bottom: calculateRem(20px);
			}
		}

		&__title {
			font-size: calculateRem(18px);
			font-weight: 600;
			color: currentColor;
			line-height: 1.2;
			margin: 0;
			@include min-mobile {
				font-size: calculateRem(28px);
			}
		}
	}

	&__listing {
		display: grid;
		grid-template-columns: 1fr;
		row-gap: calculateRem(19px);

		@include min-mobile {
			row-gap: calculateRem(30px);
		}
	}

	&__ad {
		background-image: url("../images/my-explore/encart_myex_bg_mobile.png");
		background-size: cover;
		background-position: center center;
		overflow: hidden;
		margin-top: calculateRem(40px);
		padding: calculateRem(26px) calculateRem(30px);
		display: flex;
		gap: calculateRem(20px);
		border-radius: calculateRem(10px);
		flex-direction: column;

		@include min-tablet_small {
			margin-top: calculateRem(60px);
			padding: calculateRem(60px);
			flex-direction: row;
			align-items: center;
			gap: calculateRem(80px);
			background-image: url("../images/my-explore/encart_myex_bg.png");
		}

		&__content {
			text-align: center;
			display: flex;
			gap: calculateRem(20px);
			flex-direction: column;
			max-width: calculateRem(280px);
			margin-left: auto;
			margin-right: auto;
			align-items: center;

			@include min-mobile {
				max-width: calculateRem(495px);
			}
			@include min-tablet_small {
				align-items: self-start;
				text-align: left;
			}
		}

		&__title {
			color: currentColor;
			font-size: calculateRem(20px);
			font-weight: bold;
			line-height: 1.1;
			margin-bottom: 0;

			@include min-mobile {
				font-size: clamp(
					calculateRem(20px),
					calc(3vw + 0.5rem),
					calculateRem(40px)
				);
			}

			@include min-desktop {
				font-size: calculateRem(40px);
			}
		}

		&__logo {
			max-width: calculateRem(149px);
			height: auto;

			@include min-mobile {
				max-width: calculateRem(185px);
			}
		}

		&__img {
			max-width: calculateRem(180px);
			margin-bottom: calculateRem(-26px);
			width: 100%;
			flex-shrink: 0;
			margin-left: auto;
			margin-right: auto;
			@include aspect-ratio(187, 225);

			img {
				object-position: top;
			}

			@include min-mobile {
				@include aspect-ratio(231, 336);

				max-width: calculateRem(231px);
				margin-bottom: calculateRem(-60px);
			}
		}
	}
}
