
// Overlay highlight

.overlay-highlight {
	z-index: 9999999999;
	background-color: $c_greydark;

	.top {
		background: transparent;
        justify-content: flex-end;
	}

    .close-overlay {
        color: white;
    }

    .content {

        .figcopyright {
            font: normal normal normal 10px/16px Poppins;

            color: var(--verylightgrey);
        }


        .overlay-highlight__h3 {
            
            color: var(--white);
            font: normal normal normal 10px/16px Poppins;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 10px;

            strong {
                font: normal normal 600 9px/16px Poppins;
                letter-spacing: 1.35px;
            }
        }

        .overlay-highlight__h2 {
            font: normal normal bold 28px/38px Poppins;
            color: var(--white);
            text-align: center;
            margin-bottom: 20px;
        }

        &--text {
            padding: 20px 0;

            p {
                font: normal normal normal 13px/21px Libre Baskerville;

                color: var(--white);
            }

            a {
                margin-top: 20px;
                text-decoration: underline;
                font: normal normal normal 13px/21px Libre Baskerville;
                letter-spacing: 0px;
                color: var(--white);

                &:hover {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }

        &--related {
            padding: 35px 0;

            h2 {
                font: normal normal 600 24px/24px Poppins;
                letter-spacing: 0.48px;
                color: var(--white);
                text-align: left;
            }
        }
    }
}