@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon/fonts/icomoon.eot?slnplv');
	src: url('../fonts/icomoon/fonts/icomoon.eot?slnplv#iefix') format('embedded-opentype'),
	url('../fonts/icomoon/fonts/icomoon.woff2?slnplv') format('woff2'),
	url('../fonts/icomoon/fonts/icomoon.ttf?slnplv') format('truetype'),
	url('../fonts/icomoon/fonts/icomoon.woff?slnplv') format('woff'),
	url('../fonts/icomoon/fonts/icomoon.svg?slnplv#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-pets:before {
  content: "\e9f0";
  color: #dea99e;
}
.icon-new-myexplore-my .path1:before {
  content: "\e9ee";
  color: rgb(0, 0, 0);
}
.icon-new-myexplore-my .path2:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-tag-close:before {
  content: "\e9ed";
}
.icon-mag-burger:before {
  content: "\e9ec";
}
.icon-eye-fill:before {
  content: "\e9eb";
}
.icon-circle-xmark:before {
  content: "\e9ea";
}
.icon-article:before {
  content: "\e9e4";
}
.icon-assiette:before {
  content: "\e9e5";
}
.icon-chrono:before {
  content: "\e9e6";
}
.icon-cooking:before {
  content: "\e9e7";
}
.icon-external-link2:before {
  content: "\e9e8";
}
.icon-salade:before {
  content: "\e9e9";
}
.icon-magic-wand:before {
  content: "\e9d5";
}
.icon-ico-check-circle:before {
  content: "\e9d6";
}
.icon-check-circle-outline:before {
  content: "\e9d7";
}
.icon-my-explore:before {
  content: "\e9d8";
}
.icon-eye:before {
  content: "\e9d9";
}
.icon-facebook-simple:before {
  content: "\e9da";
}
.icon-x:before {
  content: "\e9db";
}
.icon-mail-outline:before {
  content: "\e9dc";
}
.icon-favorite:before {
  content: "\e9dd";
}
.icon-line-order:before {
  content: "\e9de";
}
.icon-search-highlight:before {
  content: "\e9df";
}
.icon-geolocalisation:before {
  content: "\e9e0";
}
.icon-profil:before {
  content: "\e9e1";
}
.icon-certified:before {
  content: "\e9e2";
}
.icon-localisation-highlight:before {
  content: "\e9e3";
}
.icon-copy:before {
  content: "\e9d4";
}
.icon-notification:before {
  content: "\e9a5";
}
.icon-world:before {
  content: "\e9a3";
  color: #a0a5a8;
}
.icon-link:before {
  content: "\e9a4";
}
.icon-edit-pencil:before {
  content: "\e9d3";
}
.icon-couple:before {
  content: "\e9c8";
}
.icon-pictos-1:before {
  content: "\e9d2";
}
.icon-np_search_1644197_000000:before {
  content: "\e9d1";
}
.icon-agenda:before {
  content: "\e9cb";
}
.icon-venir:before {
  content: "\e9cc";
}
.icon-transport:before {
  content: "\e9cd";
}
.icon-groupe:before {
  content: "\e9ce";
}
.icon-carte:before {
  content: "\e9cf";
}
.icon-history:before {
  content: "\e9d0";
}
.icon-pro:before {
  content: "\e9ca";
}
.icon-famille:before {
  content: "\e9c9";
}
.icon-entre-amis:before {
  content: "\e9c7";
}
.icon-thick-chevron-bottom:before {
  content: "\e9c6";
}
.icon-np_home_5866625_000000:before {
  content: "\e9c5";
  color: #fff;
}
.icon-ferme-auberge:before {
  content: "\e9c4";
}
.icon-Groupe-21039:before {
  content: "\e9ae";
}
.icon-Groupe-21041:before {
  content: "\e9af";
}
.icon-Groupe-21045:before {
  content: "\e9b0";
}
.icon-Groupe-21047:before {
  content: "\e9b1";
}
.icon-Groupe-21049:before {
  content: "\e9b2";
}
.icon-Groupe-21054:before {
  content: "\e9b3";
}
.icon-Groupe-21056:before {
  content: "\e9b4";
}
.icon-Groupe-21065:before {
  content: "\e9b5";
}
.icon-Groupe-21037:before {
  content: "\e9b6";
}
.icon-Groupe-21063:before {
  content: "\e9b7";
}
.icon-Pictos-1:before {
  content: "\e9b8";
}
.icon-Pictos-2:before {
  content: "\e9b9";
}
.icon-Pictos:before {
  content: "\e9ba";
}
.icon-en-hiver:before {
  content: "\e9bb";
}
.icon-en-ville:before {
  content: "\e9bc";
}
.icon-escapade:before {
  content: "\e9bd";
}
.icon-Groupe-21026:before {
  content: "\e9be";
}
.icon-autour-de-leau:before {
  content: "\e9bf";
}
.icon-autour-des-savoir-faire:before {
  content: "\e9c0";
}
.icon-bien-etre:before {
  content: "\e9c1";
}
.icon-Ludique:before {
  content: "\e9c2";
}
.icon-Tout:before {
  content: "\e9c3";
}
.icon-filtres:before {
  content: "\e9ac";
}
.icon-edit:before {
  content: "\e9ad";
}
.icon-trend:before {
  content: "\e9a6";
}
.icon-np-tri:before {
  content: "\e9a7";
}
.icon-np_filter:before {
  content: "\e9ab";
}
.icon-steps:before {
  content: "\e9a2";
}
.icon-distance:before {
  content: "\e9a0";
}
.icon-stopwatch:before {
  content: "\e9a1";
}
.icon-flag:before {
  content: "\e9a8";
}
.icon-gauge:before {
  content: "\e9a9";
}
.icon-zero:before {
  content: "\e9aa";
}
.icon-gpx:before {
  content: "\e99f";
}
.icon-poi-arrivee:before {
  content: "\e98f";
}
.icon-difficulty-1:before {
  content: "\e990";
}
.icon-difficulty-3:before {
  content: "\e991";
}
.icon-difficulty-2:before {
  content: "\e992";
}
.icon-poi-depart:before {
  content: "\e993";
}
.icon-burger-arrow:before {
  content: "\e994";
}
.icon-itinerance:before {
  content: "\e995";
}
.icon-offres-travel:before {
  content: "\e996";
}
.icon-offre-restaurant:before {
  content: "\e997";
}
.icon-offres-hebergement:before {
  content: "\e998";
}
.icon-fullscreen:before {
  content: "\e999";
}
.icon-time:before {
  content: "\e99a";
}
.icon-deplacement-bateau:before {
  content: "\e99b";
}
.icon-deplacement-velo:before {
  content: "\e99c";
}
.icon-deplacement-pied:before {
  content: "\e99d";
}
.icon-localisation-double:before {
  content: "\e99e";
}
.icon-ico-webcam:before {
  content: "\e98e";
}
.icon-ico-piste-nordique:before {
  content: "\e988";
}
.icon-ico-piste-raquettes:before {
  content: "\e989";
}
.icon-ico-piste-snowpark:before {
  content: "\e98a";
}
.icon-ico-piste-luge:before {
  content: "\e98b";
}
.icon-ico-remontees:before {
  content: "\e98c";
}
.icon-ico-piste-alpine:before {
  content: "\e98d";
}
.icon-train:before {
  content: "\e987";
}
.icon-etoile-audace:before {
  content: "\e986";
}
.icon-chalet:before {
  content: "\e97f";
}
.icon-etoile:before {
  content: "\e980";
}
.icon-flocon1:before {
  content: "\e981";
}
.icon-flocon2:before {
  content: "\e982";
}
.icon-neige1:before {
  content: "\e983";
}
.icon-neige2:before {
  content: "\e984";
}
.icon-skieur:before {
  content: "\e985";
}
.icon-ico-mains-unies:before {
  content: "\e97b";
}
.icon-ico-poignee-main:before {
  content: "\e97c";
}
.icon-ico-cocarde:before {
  content: "\e97d";
}
.icon-ico-poing:before {
  content: "\e97e";
}
.icon-ico-arrow-left-medium:before {
  content: "\e97a";
}
.icon-ico-maison:before {
  content: "\e979";
}
.icon-carte-btn:before {
  content: "\e978";
}
.icon-tel:before {
  content: "\e977";
}
.icon-ico-aeroport-full:before {
  content: "\e976";
}
.icon-squares:before {
  content: "\e975";
}
.icon-vase:before {
  content: "\e96d";
}
.icon-serviette:before {
  content: "\e96e";
}
.icon-service:before {
  content: "\e96f";
}
.icon-raisin:before {
  content: "\e970";
}
.icon-main:before {
  content: "\e971";
}
.icon-expo:before {
  content: "\e972";
}
.icon-cloche:before {
  content: "\e973";
}
.icon-avion:before {
  content: "\e974";
}
.icon-gift:before {
  content: "\e96a";
}
.icon-tree:before {
  content: "\e96b";
}
.icon-boules:before {
  content: "\e96c";
}
.icon-bulles:before {
  content: "\e969";
}
.icon-external-link:before {
  content: "\e968";
}
.icon-ico-filtres:before {
  content: "\e967";
}
.icon-empty:before {
  content: "\e966";
}
.icon-recherche:before {
  content: "\e965";
}
.icon-quote:before {
  content: "\e964";
}
.icon-ico-valide:before {
  content: "\e963";
}
.icon-cles-label:before {
  content: "\e961";
}
.icon-epis-label:before {
  content: "\e962";
}
.icon-ico-logout:before {
  content: "\e960";
}
.icon-ico-pinterest:before {
  content: "\e95f";
}
.icon-ico-buy:before {
  content: "\e957";
}
.icon-ico-explore-grandest:before {
  content: "\e958";
}
.icon-ico-favoris-ok:before {
  content: "\e959";
}
.icon-ico-map-escapade:before {
  content: "\e95a";
}
.icon-ico-roller-coaster:before {
  content: "\e95b";
}
.icon-ico-searching-bar:before {
  content: "\e95c";
}
.icon-ico-suitcases:before {
  content: "\e95d";
}
.icon-ico-sunbed:before {
  content: "\e95e";
}
.icon-ico-menu:before {
  content: "\e956";
}
.icon-ico-bus:before {
  content: "\e955";
}
.icon-ico-arrow-bottom:before {
  content: "\e951";
}
.icon-ico-arrow-right:before {
  content: "\e952";
}
.icon-ico-arrow-top:before {
  content: "\e953";
}
.icon-ico-arrow-left:before {
  content: "\e954";
}
.icon-ico-dots:before {
  content: "\e91b";
}
.icon-ico-eco-responsable:before {
  content: "\e917";
}
.icon-ico-agriculture:before {
  content: "\e91f";
}
.icon-ico-discret:before {
  content: "\e943";
}
.icon-ico-handicap-moteur:before {
  content: "\e944";
}
.icon-ico-handicap-muet:before {
  content: "\e945";
}
.icon-ico-handicap-ouie:before {
  content: "\e946";
}
.icon-ico-handicap-vue:before {
  content: "\e947";
}
.icon-ico-legumes:before {
  content: "\e948";
}
.icon-ico-location:before {
  content: "\e949";
}
.icon-ico-main-etoiles:before {
  content: "\e94a";
}
.icon-ico-play:before {
  content: "\e94b";
}
.icon-ico-round-more:before {
  content: "\e94c";
}
.icon-ico-security:before {
  content: "\e94d";
}
.icon-ico-toque-chef:before {
  content: "\e94e";
}
.icon-ico-toque:before {
  content: "\e94f";
}
.icon-ico-voiture-elec:before {
  content: "\e950";
}
.icon-ico-auth:before {
  content: "\e941";
}
.icon-ico-view-password:before {
  content: "\e942";
}
.icon-ico-close:before {
  content: "\e940";
}
.icon-ico-filtrer:before {
  content: "\e93f";
}
.icon-ico-moins:before {
  content: "\e93d";
}
.icon-ico-plus:before {
  content: "\e93e";
}
.icon-ico-linkedin:before {
  content: "\e93b";
}
.icon-ico-mail:before {
  content: "\e93c";
}
.icon-chevron-slider-bottom:before {
  content: "\e900";
}
.icon-chevron-slider-right:before {
  content: "\e901";
}
.icon-chevron-slider-top:before {
  content: "\e902";
}
.icon-chevron-slider-left:before {
  content: "\e903";
}
.icon-ico-a-pied:before {
  content: "\e904";
}
.icon-ico-avion:before {
  content: "\e905";
}
.icon-ico-bien-etre:before {
  content: "\e906";
}
.icon-ico-bookmark:before {
  content: "\e907";
}
.icon-ico-calendar:before {
  content: "\e908";
}
.icon-ico-capacite-accueil:before {
  content: "\e909";
}
.icon-ico-chevron-bottom:before {
  content: "\e90a";
}
.icon-ico-chevron-left:before {
  content: "\e90b";
}
.icon-ico-chevron-right:before {
  content: "\e90c";
}
.icon-ico-chevron-top:before {
  content: "\e90d";
}
.icon-ico-cles:before {
  content: "\e90e";
}
.icon-ico-conseil:before {
  content: "\e90f";
}
.icon-ico-culture:before {
  content: "\e910";
}
.icon-ico-destination:before {
  content: "\e911";
}
.icon-ico-download:before {
  content: "\e912";
}
.icon-ico-duree:before {
  content: "\e913";
}
.icon-ico-epis:before {
  content: "\e914";
}
.icon-ico-facebook:before {
  content: "\e915";
}
.icon-ico-favoris:before {
  content: "\e916";
}
.icon-ico-fleur:before {
  content: "\e918";
}
.icon-ico-geoloc:before {
  content: "\e919";
}
.icon-ico-instagram:before {
  content: "\e91a";
}
.icon-ico-langues:before {
  content: "\e91c";
}
.icon-ico-level:before {
  content: "\e91d";
}
.icon-ico-localisation:before {
  content: "\e91e";
}
.icon-ico-nature:before {
  content: "\e920";
}
.icon-ico-newsletter:before {
  content: "\e921";
}
.icon-ico-not-ok:before {
  content: "\e922";
}
.icon-ico-oeil:before {
  content: "\e923";
}
.icon-ico-oenotourisme:before {
  content: "\e924";
}
.icon-ico-ok:before {
  content: "\e925";
}
.icon-ico-patrimoine:before {
  content: "\e926";
}
.icon-ico-photos:before {
  content: "\e927";
}
.icon-ico-questions:before {
  content: "\e928";
}
.icon-ico-rando:before {
  content: "\e929";
}
.icon-ico-remove:before {
  content: "\e92a";
}
.icon-ico-reset:before {
  content: "\e92b";
}
.icon-ico-restauration:before {
  content: "\e92c";
}
.icon-ico-search:before {
  content: "\e92d";
}
.icon-ico-securty:before {
  content: "\e92e";
}
.icon-ico-share:before {
  content: "\e92f";
}
.icon-ico-soutenir:before {
  content: "\e930";
}
.icon-ico-sport:before {
  content: "\e931";
}
.icon-ico-star:before {
  content: "\e932";
}
.icon-ico-tourisme-handicap:before {
  content: "\e933";
}
.icon-ico-train:before {
  content: "\e934";
}
.icon-ico-twitter:before {
  content: "\e935";
}
.icon-ico-user:before {
  content: "\e936";
}
.icon-ico-velo:before {
  content: "\e937";
}
.icon-ico-veloroute:before {
  content: "\e938";
}
.icon-ico-voiture:before {
  content: "\e939";
}
.icon-ico-youtube:before {
  content: "\e93a";
}