

.page-itinerance.--etape {

    .cover-itinerance {

        @include mobile {
            height: 275px;

            .cover-title {
                font-size: 24px;
                line-height: 30px;
                margin-top: 2px;
                font-weight: 700;
            }
        }

    }

    .item-etape__preview {
        width: 80%;
        @include mobile {
            width: 100%;
        }
    }

    .metas-etape {
        @include mobile {
            justify-content: space-around;
        }
    }

    .meta-etape {

        &__label {
            font-size: 15px;
            line-height: 16px;
        }

        @include mobile {
            flex-direction: column;
            align-items: center;
            padding-right: 0;
            padding-left: 0;
            flex-shrink: 0;
            border-color: rgba(#ADB3B8, 0.5);

            &__icon {
                margin-right: 0;
                margin-bottom: 7px;

                &.icon-ico-veloroute {
                    margin-bottom: 2px;
                    font-size: 34px;
                }
            }

            &__label {
                font-size: 13px;
            }
        }
    }

    // Section - Header
    // ==========================================================================
    .wrapper-content-map {
        @include tablet_portrait {
            --header-height : 0;
        }
    }

    // Section - Villes + distance
    // ==========================================================================

    .group-cities {
        display: flex;
        flex-direction: column;
        padding-left: calc(var(--border-gutter));
        padding-right: calc(var(--border-gutter));
        padding-top: 10px;
        padding-bottom: 48px;
        background-color: rgba(#1C2124, 0.1);

        .row-cities {
            display: flex;
            justify-content: space-between;

            @include desktop {
                padding-left: 20px;
                padding-right: 20px;
            }

            @include mobile {
                padding-left: 30px;
                padding-right: 30px;
            }
        }

        .label-city {
            @include ft('', 10px, 700, $c_greydark, 1.2);
            @include letter-spacing(150);
            text-transform: uppercase;
            max-width: calc(50% - 20px);

            &:nth-child(2) {
                text-align: right;
            }
        }


        .row-distance {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 24px;

            // horizontal line
            background-image: linear-gradient(to right, $c_main_alt, $c_main_alt);
            background-repeat: no-repeat;
            background-size: 100% 6px;
            background-position: left center;

            @include desktop {
                margin-left: 20px;
                margin-right: 20px;
            }

            @include mobile {
                margin-left: 30px;
                margin-right: 30px;
            }
        }

        .picto-city {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            @include ft('', 18px, 600, $c_white, 0.5);
            border-radius: 50%;
            background-color: $c_main_alt;
            box-shadow: 0 0 0 15px rgba($c_main_alt,0.2);

            // icone
            &:before {
                font-size: 13px;
            }

            // triangle
            &:after {
                content: '';
                @include triangle('down', $c_main_alt, 8px, 8px);
                position: absolute;
                top: calc(100% - 2px);
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .label-distance {
            display: inline-flex;
            justify-content: center;
            @include ft('', 13px, 700, $c_white, 1);
            text-transform: uppercase;
            line-height: 32px;
            height: 30px;
            padding: 0 15px;
            background-color: $c_main_alt;
            border-radius: 15px;
        }


        .btn-nav-etape-light {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            font-size: 20px;
            color: $c_gradient;

            @include can-hover {
                color: $c_main_alt;
            }


            &.--prev {
                left: -65px;

                @include desktop {
                    left: -55px;
                }
            }
            &.--next {
                right: -65px;

                @include desktop {
                    right: -55px;
                }
            }
        }
    }

    // Section - Aperçu de l'étape
    // ==========================================================================

    .group-preview {

        padding: 30px;
        background-color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            margin-top: -8px;
        }

        .preview-metas {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-top: 30px;

            @include desktop {
                flex-direction: column;
                justify-content: start;
                margin-top: 10px;
            }
        }

        .preview-meta {
            display: flex;
            align-items: center;
            padding: 10px 0;

            @include desktop {
                justify-content: space-between;
                border-bottom: 1px solid rgba($c_border, 0.5);
                text-align: right;
            }

            .preview-meta__icon-wrapper {
                display: flex;
                align-items: center;
            }

            &.--deplacement {
                .preview-meta__icon {
                    color: $c_gradient;

                    & + .preview-meta__icon {
                        margin-left: 10px;
                    }
                }
            }

            &.--duration {
                .preview-meta__icon {
                    color: $c_main;
                }
            }

            &.--difficulty {
                .preview-meta__icon {
                    color: $c_main;
                }
            }

            .preview-meta__icon {
                font-size: 30px;

                @include mobile {
                    font-size: 25px;
                }
            }

            .preview-meta__content {
                display: flex;
                flex-direction: column;
                margin-left: 30px;
            }

            .preview-meta__title {
                @include ft('', 24px, 300, $c_txt, 1.16);
            }

            .preview-meta__subtitle{
                @include ft('', 12px, 700, $c_surtitre, 1.16);
            }


            // centrage en absolu du 2eme meta
            &.--duration {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                
                @include desktop {
                    position: initial;
                    transform: initial;
                }
            }
        }
    }


    // Section - Description
    // ==========================================================================

    .group-description {
        margin-top: 40px;
    }

    .item-point-fort {
        display: flex;

        & + .item-point-fort {
            margin-top: 16px;
        }

        // bullet
        &:before {
            content: '';
            flex-shrink: 0;
            width: 8px;
            height: 8px;
            margin-right: 10px;
            margin-top: 8px;
            background-color: $c_third;
            border-radius: 50%;
        }
    }

    .description-text-wrapper {
        margin-top: 50px;
        display: flex;
        flex-direction: column;

        @include mobile {
            margin-top: 20px;
        }

        .cta-expand {
            margin-left: 0;
            margin-right: auto;
            align-items: flex-start;
        }
    }


    // Section - Galerie d'images
    // ==========================================================================

    .group-gallery {
        display: flex;
        flex-direction: column;
        margin-top: 60px;

        @include mobile {
            margin-bottom: 40px;
        }

        .btn-overlay-gallery {
            align-self: flex-start;
            transform: none;
            margin-left: 20px;
            margin-top: 65px;
            padding-top: 11px;
            padding-bottom: 10px;
            font-size: 13px;

            @include mobile {
                transform: none;
                align-self: auto;
                margin-top: 20px;
                margin-left: 0;
                padding: 12px 0 10px;
                font-size: 11px;
            }
        }
    }

    .gallery-etape {
        display: grid;
        grid-template-areas: "thumb1 thumb1"
                             "thumb2 thumb3";
        grid-template-columns: 6fr 4fr;
        grid-template-rows: auto auto;
        gap: 20px;

        @include mobile {
            grid-template-areas: "thumb1";
            grid-template-columns: 1fr;
            grid-template-rows: auto;

            > :nth-child(2),
            > :nth-child(3) {
                display: none;
            }
        }

        > :nth-child(1) {
            grid-area: thumb1;

            figure {
                @include aspect-ratio(840px, 500px);

                @include mobile {
                    @include aspect-ratio(1, 1);
                }
            }
        }

        > :nth-child(2) {
            grid-area: thumb2;

            figure {
                height: 100%;

                @include mobile {
                    @include aspect-ratio(1, 1);
                }
            }
        }
        > :nth-child(3) {
            grid-area: thumb3;

            figure {
                @include aspect-ratio(1, 1);
            }
        }

        .thumb-etape {
            figure {

                &::before {
                    content: '';
                    position: relative;
                    z-index: 99;
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,202529+100&0+0,1+100 */
                    background: -moz-linear-gradient(top, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00202529', endColorstr='#202529', GradientType=0); /* IE6-9 */
                    opacity: 0;
                    z-index: 10;
                    @include addTransition();
                    display: block;
                    content: "";
                    width: 100%;
                    padding-bottom: 0px;
                    height: 100%;
                }

                &::after {
                    content: "\e999";
                    @include font();
                    position: absolute;
                    z-index: 100;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 40px;
                    color: #fff;
                    opacity: 0;
                    @include addTransition();
                }
            }

            &:hover {
                figure {
                    &::before {
                        opacity: 0.65;
                    }
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }



    // Section - Infos pratiques
    // ==========================================================================

    .group-infos {
        margin-top: 100px;

        @include mobile {
            margin-top: 20px;
        }
    }

    .list-infos-pratiques {
        display: grid;
        grid-template-columns: repeat(2, minmax(10px, 1fr));
        gap: 40px;
        margin-top: 40px;

        @include mobile {
            grid-template-columns: minmax(10px, 1fr);
            gap: 30px;
            margin-top: 20px;
        }
    }

    .item-info-pratique {
        display: flex;
        align-items: center;

        figure {
            flex-shrink: 0;
            
            & + * {
                margin-left: 20px;
            }

            img {
                width: 50px;
                height: auto;

                @include mobile {
                    width: 42px;
                }
            }
        }
        
        .item-info-pratique__text {
            p {
                line-height: 1.2;
            }
        }
    }

    .infos-pratiques-text {
        margin-top: 50px;
        @include mobile {
            margin-top: 30px;
        }
    }


    // Section - Offres sur ce parcours
    // ==========================================================================

    .section-offres {
        margin-top: 50px;
        .bloc-slider-offres-auto {
            .content-wrapper.padding-inner {
                padding: 0 !important;
            }
            .onglets.wrapper-slider {
                margin-left: initial !important;
                max-width: initial !important;
                @include min-desktop {
                    max-width: 96% !important;
                }
            }
            .slider-item {
                @include min-desktop {
                    margin-left: 80px;
                }

                button {
                    @include min-desktop {
                        right: 30px;
                    }
                    @include desktop1200 {
                        right: auto;
                        left: 88%;
                        width: 125px;
                    }
                    @include tablet_portrait {
                        left: 65%;
                    }
                    @include mobile {
                        left: 55%;
                    }
                }
            }
        }


        .th-slider-wrapper {
            .nav {
                button:last-child {
                    right: calc((100% - 40vw) / 2);
                }
            }
        }

        .content-wrapper {
            width: auto;

            h3 {
                @include mobile {
                    font-size: 28px;
                }
            }

        }

        .splide-slider-offres-auto-tab {
            .splide__track {
                @include min-mobile {
                    max-width: 90%;
                    
                    .splide__arrow {
                        display: none;
                    }
                }
            }
        }

        .onglets .th-slider {
            margin-left: var(--border-gutter);
        }

        .th-slider-wrapper .wrapper-slider {
            margin: 0 var(--border-gutter);
        }
    }


    // Section - Navigation étape précédente  / suivante
    // ==========================================================================

    .section-nav-etapes {
        display: block;
        padding-top: 40px;
        padding-bottom: 100px;
        margin-bottom: -120px;      // remonte le footer

        @include tablet_landscape {
            margin-bottom: -80px;
        }

        @include mobile {
            display: block;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        &__main-iti-link {
            margin-top: 25px;
            text-align: center;

            @include tablet_portrait {
                margin-top: 19px;
            }

            a {
                color: $c_txt;
                text-decoration: underline;
            }
        }
    }
    
    .section-nav-etapes__inner {
        display: flex;
        justify-content: space-between;

        @include min-tablet_portrait {
            > * {
                width: 50%;
            }
        }

    }

    .btn-nav-etape {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $c_white;
        border-radius: 3px;

        @include can-hover {
            .btn-nav-etape__title {
                color: $c_txt;
            }
        }

        &.--prev {
            background-color: $c_third;
            padding: 20px 30px;
            flex-shrink: 0;
            margin-right: 12px;

            @include tablet_portrait {
                padding: 10px 18px;
                background-color: #F3F4F5;
            }

            .btn-nav-etape__icon {
                left: 0;

                @include tablet_portrait {
                    color: $c_txt;
                }
            }

            .btn-nav-etape__text {
                @include tablet_portrait {
                    display: none;
                }
            }

            .btn-nav-etape__cities {
                justify-content: flex-end;
            }
        }

        &.--next {
            width: 100%;
            background-color: $c_third;
            padding: 20px 30px;
            @include min-tablet_portrait {
                margin-left: auto;
                text-align: right;
            }

            @include tablet_portrait {
                padding: 10px 18px;
            }

            .btn-nav-etape__icon {
                right: 0;
            }

        }
    }

    .btn-nav-etape__icon {
        font-size: 14px;
    }


    .btn-nav-etape__label {
        @include ft('', 12px, 300, $c_greydark, 1.15);
        @include letter-spacing(150);
        text-transform: uppercase;

        & + * {
            margin-top: 5px;
        }

        @include tablet_portrait {
            text-align: center;
        }
    }

    .btn-nav-etape__title {
        @include ft('', 16px, 600, '', 20px);
        @include letter-spacing(20);
        @include addTransition();
        color: #fff !important;

        @include tablet_portrait {
            text-align: center;
            font-size: 11px;
            line-height: 12px;
        }
    }

    .btn-nav-etape__cities {
        display: flex;
        align-items: center;
        @include ft('', 11px, 300, '', 12px);
        text-transform: uppercase;

        & + * {
            margin-top: 6px;
        }

        span {
            display: inline-flex;
            align-items: center;

            &:nth-child(2) {
                margin-left: 2px;

                &:before {
                    @include font();
                    content: "\e901";
                    margin-right: 2px;
                    font-size: 9px;
                    transform: translateY(-1px);
                }
            }
        }
    }
    .download-gpx, .download-gpx-tablet-mobile {
        background-color: #D7A095;
        position: absolute;
        bottom: 39px;
        left: 40px;
        z-index: 10;
        padding: 12px 20px;
        font-size: 13px;
        color: white;
        text-decoration: none;
        border-radius: 4px;
        @include mobile {
            width: 225px;
        }
        span {
            margin-left: 4px;
        }

    }
    .download-gpx-tablet-mobile {
        bottom: -18px;
        left: 50%;
        translate: -50%;
    }

    // Menu ancre
    // ==========================================================================

    .col-content {
        max-width: 56vw;
        @include tablet_landscape {
            max-width: 100vw;
        }
    }


    .close-map {
        position: static;
    }

    .slider-cards {
        display: flex;
        overflow-x: scroll;
        padding-bottom: 0;
        border-bottom: 1px solid rgba(#ABB3B9, 0.5);

        .card-offre {
            flex-shrink: 0;
            padding: 0 15px;

            div:first-of-type {
                align-items: flex-start;
                .fit-cover {
                    width: 115px;
                    height: 80px;
                }

                .caption {
                    width: calc(100% - 115px);
                }
            }
        }
    }

    .maps {
        @include mobile {
            height: calc(100vh - 235px);
        }

        .leaflet-control-zoom {
            @include mobile {
                display: none;
            }
        }
    }

    .splide-etape-map {
        z-index: 10;
        background-color: white;
        position: absolute;
        bottom: 0;
        max-width: 100vw;

        .splide-wrapper {
            height: 96px;

            .splide__slide {
                margin-top: 10px;

                a {
                    div:first-of-type {
                        display: flex;

                        figure {
                            width: 200px;
                            height: 76px;
                            margin-right: 11px;
                        }

                        .caption {
                            flex-direction: column;
                            margin-top: 4px;

                            .title-card {
                                line-height: 12px;
                            }

                            .metas-card {
                                margin-bottom: 2px;
                            }

                            .encart {
                                position: absolute;
                                top: 5px;
                                left: 13%;
                                right: unset;
                            }

                            .tarif {
                                top: 45px;
                                left: 36%;
                                background: transparent;
                                font-size: 11px;

                                .price {
                                    font-size: 13px;
                                }
                            }

                            .title-card {
                                font-size: 11px
                            }
                        }
                    }
                }
            }
        }

        .return_to_etape {
            height: 74px;
            border-top: 1px solid lightgrey;
            text-align: center;
            padding-top: 25px;

            &--button {
                background: none;
                border: none;
                text-decoration: underline;
                font-size: 11px;
                color: $c_txt;
            }
        }
    }
}


