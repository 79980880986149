.page-experience {
    p.accroche {
        margin-top: 30px;
        margin-bottom: 10px;
        font: italic normal normal 24px/32px Libre Baskerville;
        color: var(--black);
    }
    p {
        font-family: $fnew_txt !important;
    }

    .bloc-texte {
        blockquote {
            &::after {
                content: "\e949";
                font-family: 'icomoon';
                font-size: 18px;
                font-style: normal;
                position: absolute;
                left: 0;
                top: 0;
                background: white;
                border-radius: 100%;
                box-shadow: 0px 3px 6px #00000029;
                width: 46px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--rose);
                transform: translate(-50%, -70%);
            }
            
            p {
                font-family: $f_txt !important;
                font-size: 15px;
                font-style: normal;
                line-height: 24px;
            }
        }

        h4,
        .title-h4 {
            display: block;
            font: normal normal 300 40px/40px Poppins;
            color: var(--black);
            padding-left: 18px;
            position: relative;
            margin-bottom: 18px;

            @include tablet_portrait {
                font-size: 20px;
                line-height: 24px;
            }

            &::before {
                content: '';
                position: absolute;
                top: -6px;
                left: 0;
                width: 4px;
                height: 42px;
                background-color: var(--rose);
            }
        }
    }

}