// Général
.aside-bar {
	position: sticky;
	-ms-position: sticky;
	z-index: 99999;
	order: 1;
	float: left;
	top: 180px;
	width: 410px;
	margin-top: 0;
	border-radius: 10px;
	overflow: hidden;

	background: $c_white;
	box-shadow: 0 0 40px rgba($c_dark, 0.08);
	@include mobile {
		display: none;
	}
	@include desktop1280 {
		width: 360px;
	}
	@include tablet_landscape {
		width: 320px;
	}
	@include tablet_portrait {
		width: 100%;
		position: static !important;
		top: 0;
		margin: 0;
		box-shadow: none;
		z-index: 100;
	}

	&:before, &:after {
		@include tablet_portrait {
			content: '';
			position: absolute;
			left: -40px;
			right: -40px;
		}
		@include mobile {
			left: -20px;
			right: -20px;
		}
	}

	&:before {
		@include tablet_portrait {
			top: 0;
			border-top: 1px dashed rgba($c_border, 0.5);
		}
	}

	&:after {
		@include tablet_portrait {
			bottom: 0;
			border-bottom: 1px dashed rgba($c_border, 0.5);
		}
	}
}

.aside-bar-slider {
	height: 0;
	opacity: 0;

	transition: opacity 0.2s ease-in;

	body.scrolled-gallery & {
		height: calculateRem(200px);
		opacity: 1;
	}

	.slider-item, .th-slider, .fit-cover {
		height: 100%;
	}

	@include tablet_portrait {
		display: none;
	}

	.th-slider {
		padding: 0 !important;
		margin: 0 !important;
	}

	figure {
		display: inline-block;
	}

	a {
		.badge-number {
			position: absolute;
			top: 20px;
			right: 20px;
			width: 16px;
			height: 16px;
			border-radius: 100%;
			background-color: $c_greydark;
			transform: translate(50%, -50%);
			z-index: 200;
			font-size: 9px;
			line-height: 16px;
			text-align: center;
			color: $c_white;
			font-family: $f_txt;
			font-weight: 500;
		}

		.cta-gallery {
			top: 20px;
			right: 20px;
			left: auto;
			transform: unset;
			padding: 10px;

			&::before {
				margin-right: 0;
				font-size: calculateRem(22px);
			}

			&:hover {
				color: $c_white;
				background-color: $c_greydark;
			}
		}
	}

	.dots {
		left: 50%;
		right: auto;
		transform: translateX(-50%);
		bottom: 8px;

		li {
			display: inline-block;
		}

		button {
			width: 8px;
			height: 8px;
			border-radius: 100%;
			background-color: rgba($c_white, 0.5);

			&.is-active {
				background-color: $c_white;

				&:hover {
					body:not(.no-hover) & {
						background-color: $c_white;
					}
				}
			}

			&:hover {
				body:not(.no-hover) & {
					background-color: $c_white;
				}
			}
		}
	}
}


// Partie Haute
.top-aside {
	position: relative;

	.offre-valable {
		@include ft('', 20px, 600, $c_white, 24px);
		width: 100%;
		box-sizing: border-box;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.8px;
		padding: 14px 10px 10px;
		margin: 0;
		background: $c_main;
		@include tablet_landscape {
			font-size: calculateRem(16px);
		}
		@include tablet_portrait {
			display: none;
		}

		&.highlight {
			background: $c_third;
		}
	}

	.list-metas-offres {
		width: 100%;
		padding: 15px 5px;
		font-size: 0;
		text-align: center;
		box-sizing: border-box;
		@include tablet_portrait {
			text-align: left;
			padding: 30px 0;
		}
		@include mobile {
			padding: 25px 20px;
		}
		@include mobile_small {
			padding: 25px 0;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			border-bottom: 1px dashed rgba($c_border, 0.5);
			@include tablet_portrait {
				content: none;
			}
		}

		li {
			@include ft($f_txt, 15px, 600, $c_txt, 20px);
			margin: 0 15px;
			padding: 0;
			display: inline-block;
			vertical-align: middle;

			&:first-child {
				@include tablet_portrait {
					margin-left: 0;
				}
			}

			@include tablet_landscape {
				font-size: calculateRem(13px);
			}
			@include tablet_portrait {
				font-size: calculateRem(15px);
			}
			@include mobile {
				display: block;
				margin: 0 0 10px 0;
				&:last-child {
					margin: 0;
				}
			}
			@include mobile_small {
				margin: 0 10px;
				font-size: calculateRem(13px);
			}

			span {
				position: relative;
				top: -3px;
				font-size: 20px;
				line-height: 20px;
				float: left;
				margin-right: 5px;
				color: $c_titre;
				@include mobile {
					margin-right: 8px;
				}
			}

			&.date-de-loffre {
				display: none;
				@include tablet_portrait {
					display: inline-block;
				}
			}

		}
	}

	.wrapper-btn {
		position: relative;
		padding: 15px 20px 15px 20px;
		width: 100%;
		@include tablet_landscape {
			padding: 20px;
		}
		@include tablet_portrait {
			display: none;
		}
	}

	.wrapper-btn-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.wrapper-price {
		flex: 1;
		width: 100%;
		align-self: center;
	}

	.price {
		@include ft('', 12px, 300, $c_greydark, 24px);
		text-align: left;

		&.gratuit span {
			@include ft('', 24px, 600, $c_greydark, 30px);
			display: inline;
		}

		span {
			@include ft('', 30px, 700, $c_txt_lien, 40px);
			display: block;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			margin-top: -5px;
		}

		&.price_mobile {
			display: none;
		}
	}

	.cta-reserver,
	.cta-offre-indispo {
		min-width: 100px;
		@include desktop1280 {
			min-width: 120px;
			margin-left: 10px;
		}
		@include tablet_landscape {
			min-width: 100px;
			margin-left: 5px;
		}
		@include tablet_portrait {
			min-width: 110px;
			margin-left: 20px;
		}
	}
}

.vendu-par {
	@include ft('', 10px, 400, #4B6D83, 12px);
	font-style: italic;
	display: block;
	margin-top: 10px;
}

.heb_particulier_aside {
	display: inline-block;
	margin-top: 10px;
	padding: 8px 15px 5px;
	border-radius: 4px;
	background-color: #D5DFE5;
	color: #537184;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: bold;
	text-align: center;
}

.caption-localisation {
	position: relative;
	padding: 15px 20px;
	@include tablet_landscape {
		padding: 20px;
	}
	@include tablet_portrait {
		padding: 30px 0;
	}
	@include mobile {
		padding: 25px 20px;
	}
	@include mobile_small {
		padding: 25px 0;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		border-bottom: 1px dashed rgba($c_border, 0.5);
		@include tablet_portrait {
			left: -40px;
			right: -40px;
		}
		@include mobile {
			left: -20px;
			right: -20px;
		}
	}

	.adresse-offre {
		margin-bottom: 10px;
	}

	.location {
		margin-bottom: 2px;
	}

	address,
	.adresse-offre {
		@include ft($f_txt, 15px, 300, $c_surtitre, 18px);
		display: block;
	}
}

// Intérieur
.caption-contact-sit {
	padding: 15px 20px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	@include clearfix();
	@include tablet_landscape {
		padding: 20px;
	}
	@include tablet_portrait {
		padding: 30px 0;
	}
	@include mobile {
		padding: 25px 20px;
	}
	@include mobile_small {
		padding: 25px 0;
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			.link {
				color: $c_third;
			}
		}
	}

	&:before {
		content: unset !important;
	}


	> * {
		position: relative;
	}

	figure, .no-photo {
		position: static !important;
		box-sizing: border-box;
		width: 60px !important;
		height: 60px !important;
		@extend %aligncenter;
		border: 1px solid #D3D8E7;
		margin: 0 15px 0 0;
		background: none;

		img {
			width: auto;
		}

		& ~ .content-txt {
			width: calc(100% - 75px);
		}
	}

	figure.contains img {
		object-fit: contain;
	}

	.no-photo {
		background: $c_main;
	}

	.credits, figcaption {
		display: none;
	}

	.content-txt {
		width: 100%;
	}

	.surtitre {
		@include ft($f_txt, 12px, 400, $c_surtitre, 18px);
		display: block;
		text-transform: uppercase;
		letter-spacing: 1.5px;
		margin-bottom: 2px;
	}

	.title-aside-sit {
		@include ft($f_hx, 20px, 300, $c_greydark, 20px);
		letter-spacing: 0.2px;
		position: relative;
		display: block;
		margin-bottom: 2px;
		@include tablet_landscape {
			font-size: calculateRem(20px);
			line-height: calculateRem(20px);
		}
		@include mobile {
			font-size: calculateRem(20px);
			line-height: calculateRem(20px);
			margin-bottom: 2px;
		}

		strong {
			font-weight: 300;
		}
	}

	.link {
		@include ft($f_txt, 13px, 500, $c_light_txt, 20px);
		display: block;
		@include addTransition();
	}
}


// Vente alcool
.information-alcool {
	@include ft($f_txt, 12px, 400, #646464, 18px);
	font-style: italic;
	background: #EDEDED;
	padding: 18px 30px;
	margin: 0;
	@include desktop1200 {
		padding: 14px 20px;
	}
	@include tablet_portrait {
		margin-left: -40px;
		margin-right: -40px;
		padding: 18px 40px;
		text-align: left;
	}
	@include mobile {
		margin-left: -20px;
		margin-right: -20px;
	}
}

// Ajouter au favoris + Social Share
.wrapper-favoris {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	border-top: 1px dashed rgba($c_border, 0.5);
	@include tablet_portrait {
		margin-left: -40px;
		margin-right: -40px;
		width: calc(100% + 80px);
	}
	@include mobile {
		margin-left: -20px;
		margin-right: -20px;
		width: calc(100% + 40px);
	}

	> * {
		position: relative;
		width: 50%;
	}

	button {
		@include ft($f_txt, 13px, 600, $c_txt, 18px);
		@extend %appearance;
		position: relative;
		background: none;
		padding: 18px 10px;
		text-align: center;
		@include addTransition();
		@include desktop1280 {
			font-size: calculateRem(11px);
		}
		@include tablet_portrait {
			font-size: calculateRem(13px);
		}
		@include mobile {
			font-size: calculateRem(11px);
		}

		&:hover, &:focus {
			body:not(.no-hover) & {
				cursor: pointer;
				color: $c_third;
			}
		}
	}

	.cta-add-favoris {
		border-right: 1px dashed rgba($c_border, 0.5);

		&:before {
			content: "\e916";
			@include font();
			font-size: 24px;
			color: black;
			margin-right: 8px;


		}

		&.active, &:hover {
			&:before {
				content: "\e959";
				color: $c_third;
			}
		}
	}

	.dropdown {
		&.open {
			ul {
				display: block;
			}
		}

		button {
			width: 100%;
			height: 100%;

			&:before {
				content: "\e92f";
				@include font();
				font-size: 18px;
				margin-right: 8px;
			}
		}

		ul {
			position: absolute;
			bottom: calc(100% + 1px);
			left: 0;
			right: 0;
			width: 100%;
			background: $c_white;
			display: none;
			padding: 8px 0;
			box-shadow: 0 -5px 10px rgba($c_border, 0.1);

			li {
				display: block;

				&.facebook {
					a {
						&:before {
							content: "\e915";
						}
					}
				}

				&.twitter {
					a {
						&:before {
							content: "\e935";
						}
					}
				}

				&.linkedin {
					a {
						&:before {
							content: "\e93b";
						}
					}
				}

				&.mail {
					a {
						&:before {
							content: "\e93c";
						}
					}
				}

				a {
					@include ft($f_txt, 13px, 600, $c_txt, 24px);
					position: relative;
					display: block;
					padding: 6px 25px;
					@include addTransition();

					&:hover, &:focus {
						body:not(.no-hover) & {
							color: $c_third;
						}
					}

					&:before {
						@include font();
						font-size: 16px;
						line-height: 16px;
						margin-right: 10px;
					}
				}
			}
		}
	}

}