.page-template-page-listing-edito {
    .header-listing-edito {
        text-align: center !important;
        @include mobile {
            margin: 0 auto !important;

            h1 {
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 20px;
            }

            p {
                font-size: 14px;
            }
        }
    }

    div#listing-edito {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        margin: auto;
        margin-top: 75px;

        &.no-bg {
            margin-top: 0;
        }

        @include tablet_landscape {
            width: 90%;
        }
        @include mobile {
            width: 95%;
        }

        span.highlighted-tab {
            width: 48%;
            margin: 1%;

            .content-cards {

                figure {
                    @include aspect-ratio(700, 473);

                    @include mobile {
                        @include aspect-ratio(345, 470);
                    }

                    > * {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        width: 100%;
                    }
                }

                .caption {
                    .title-card {
                        color: #1E2529;
                    }
                }
            }

            @include tablet_landscape {
                width: 98%;
            }
            @include mobile {
                width: 98%;
                margin: 15px auto;
            }

            .edito-v2 {
                .content-cards .caption .title-card {
                    color: $c_white;
                }
            }

        }

        div.move {
            width: 23%;
            margin: 1%;

            @include tablet_landscape {
                width: 48%;
            }

            @include mobile {
                width: 98%;
                margin: 5px auto;

                a {
                    .content-cards {
                        display: flex;
                        width: 100%;
                        align-items: center;

                        figure, .no-photo {
                            max-width: 100px;
                            height: 70px;
                            margin-right: 10px;
                            border-radius: 5px;
                        }

                        .caption {
                            margin-top: 0;
                            position: relative;
                            z-index: 50;
                            left: 0;
                            bottom: 0;
                            width: auto;
                            transform: unset;
                            text-align: left;

                            &-link {
                                color: #1E2529;
                                font-weight: 400;
                            }

                            &:after, .basic-link:after {
                                content: unset;
                            }

                            .title-card {
                                font-size: 12px;
                                letter-spacing: 0;
                                color: black !important;
                            }

                            .basic-link, .caption-link {
                                font-size: 11px;
                            }

                            .encart {
                                left: 28px;
                                top: 6px;
                                right: unset;
                            }
                        }
                    }
                }
            }

            .content-cards {
                figure {
                    @include aspect-ratio(330, 473);

                    > * {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        width: 100%;
                    }
                }

                .caption {
                    .title-card {
                        color: #1E2529;
                    }
                }
            }

            .edito-v2 {
                .content-cards .caption .title-card {
                    color: $c_white;
                }
            }
        }
    }

    .content-modulaire {
        width: 100%;

        #modulaire {
            width: 900px;
            margin: 90px auto;
            @include mobile {
                width: 90%;
                margin-top: 40px;
            }
        }
    }
}