#overlay-matomo {
	top: 50%;
	left: 0;
	right: 0;
	width: 600px;
	height: auto;
	margin: auto;
	background: $c_white;
	overflow: inherit;
	height: auto;
	min-height: 100px;
	border-radius: 5px;
	transform: translate(100vw, -50%);
	transform: translate3d(100vw ,-50%,0);
	transition: transform 400ms cubic-bezier(0.86, 0, 0.07, 1);
	&.open{
		transform: translate(0,-50%);
		transform: translate3d(0,-50%,0);
	}

	@media screen and (max-width: 700px){
		width: calc(100% - 100px);
		margin: 0 50px;
	}
	@media screen and (max-width: 700px){
		width: calc(100% - 40px);
		margin: 0 20px;
	}

	.top-title{
		@include ft($f_txt, 20px, 600, $c_greydark, 26px);
		margin: 20px;
		display: block;
		border-bottom: 1px solid #E0E0E0;
		padding-bottom: 20px;
	}

	[class^="icon-"], [class*=" icon-"] {
		position: absolute;
		right: -15px;
		top: -15px;
		width: 30px;
		height: 30px;
		background: $c_main;
		color: $c_white;
		border-radius: 50%;
		text-align: center;
        font-size: 10px;
		line-height: 30px;
		box-shadow: 0 5px 15px rgba($c_dark,0.2);
		@include addTransition();
		&:hover{
			cursor: pointer;
			background: $c_third;
			color: $c_white;
		}
	}

	iframe{
		border: 0;
		margin: 0 12px;
		width: calc(100% - 24px);
		@include mobile{
			height: 180px;
		}
	}
}