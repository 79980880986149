.splide__lemag {
    margin-bottom: 40px;
    .splide__arrow {
        bottom: -50px;
        top: unset;
        left: 820px;
        background: unset;
        svg {
            path {
                fill: var(--darkblue);
            }
        }
        &--next {
            left: 860px;
        }

        @include wide {
            left: 850px;
        }

        @include desktop1200 {
            left: calc(100vw - 400px);
        }
        @include tablet_portrait {
            display: none;
        }
    }
}