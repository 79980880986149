.bloc-form {

	@-webkit-keyframes passing-through {
		0% {
			opacity: 0;
			transform: translateY(40px);
		}
		30%, 70% {
			opacity: 1;
			transform: translateY(0px);
		}
		100% {
			opacity: 0;
			transform: translateY(-40px);
		}

	}
	@-moz-keyframes passing-through {
		0% {
			opacity: 0;
			transform: translateY(40px);
		}
		30%, 70% {
			opacity: 1;
			transform: translateY(0px);
		}
		100% {
			opacity: 0;
			transform: translateY(-40px);
		}

	}
	@keyframes passing-through {
		0% {
			opacity: 0;
			transform: translateY(40px);
		}
		30%, 70% {
			opacity: 1;
			transform: translateY(0px);
		}
		100% {
			opacity: 0;
			transform: translateY(-40px);
		}

	}
	@-webkit-keyframes slide-in {
		0% {
			opacity: 0;
			transform: translateY(40px);
		}
		30% {
			opacity: 1;
			transform: translateY(0px);
		}

	}
	@-moz-keyframes slide-in {
		0% {
			opacity: 0;
			transform: translateY(40px);
		}
		30% {
			opacity: 1;
			transform: translateY(0px);
		}

	}
	@keyframes slide-in {
		0% {
			opacity: 0;
			transform: translateY(40px);
		}
		30% {
			opacity: 1;
			transform: translateY(0px);
		}

	}

	.frm_dropzone, .frm_dropzone * {
		box-sizing: border-box;
	}

	.frm_dropzone.dz-clickable {
		margin-top: 5px;
		line-height: 1.42857143;
	}

	.frm_dropzone.dz-clickable * {
		cursor: default;
	}

	.frm_dropzone.dz-clickable.frm_single_upload {
		max-width: 100%;
	}

	.frm_dropzone.dz-clickable.frm_single_upload.dz-started {
		max-width: 100%;
	}

	.frm_form_field.frm_full .frm_dropzone.dz-clickable.frm_single_upload,
	.frm_form_field.frm_full_upload .frm_dropzone.dz-clickable.frm_single_upload {
		max-width: 100%;
	}

	.frm_form_field.frm_full .frm_dropzone.dz-clickable.frm_single_upload .dz-message,
	.frm_form_field.frm_full_upload .frm_dropzone.dz-clickable.frm_single_upload .dz-message {
		margin: .5em 0 1em;
	}

	.frm_dropzone .dz-message {
		border: 2px dashed #dfdfdf;
		background: #ffffff;
		padding: 20px;
		border-radius: 5px;
		cursor: pointer;
		display: none;
		font-size: 20px;
		text-align: center;
		margin: .5em 0 1em;
	}

	.frm_dropzone.dz-clickable.frm_single_upload .dz-message {
		margin: 0 0 .5em 0;
	}

	.frm_dropzone.frm_single_upload.dz-started .dz-message {
		display: none;
	}

	.frm_dropzone.dz-clickable .dz-message,
	.frm_dropzone .frm_upload_icon {
		display: block;
	}
	.frm_dropzone.dz-clickable .dz-message,
	.frm_dropzone.dz-clickable .dz-message * {
		cursor: pointer;
	}
	#frm_form_editor_container .frm_dropzone.dz-clickable,
	#frm_form_editor_container .frm_dropzone.dz-clickable * {
		cursor: default;
	}

	.frm_dropzone.dz-drag-hover .dz-message {
		opacity: 0.5;
	}
	.frm_dropzone .frm_upload_icon:before {
		font-size: 40px;
	}
	.frm_dropzone .frm_small_text {
		font-size: 14px;
	}
	.frm_dropzone.dz-drag-hover {
		border-style: solid;
	}
	.frm_dropzone .dz-preview {
		padding: 15px 0;
		border-bottom: 1px solid #dfdfdf;
	}

	.frm_dropzone .dz-preview:last-child {
		border-bottom: none;
	}

	.frm_dropzone .dz-preview:hover {
		z-index: 1000;
	}

	.frm_dropzone .dz-preview.dz-file-preview .dz-details {
		opacity: 1;
	}
	.frm_dropzone .dz-preview.dz-image-preview .dz-details {
		-webkit-transition: opacity 0.2s linear;
		-moz-transition: opacity 0.2s linear;
		-ms-transition: opacity 0.2s linear;
		-o-transition: opacity 0.2s linear;
		transition: opacity 0.2s linear;
	}
	.frm_dropzone .dz-preview .dz-remove {
		cursor: pointer;
		border: none;
		float: right;
	}

	.frm_dropzone .dz-preview:hover .dz-details {
		opacity: 1;
	}

	.frm_dropzone .dz-preview .dz-details {
		font-size: 14px;
		margin: 5px 0 10px;
	}

	.frm_dropzone .dz-preview .dz-details .dz-size,
	.frm_dropzone .dz-preview .dz-details .dz-filename {
		display: inline-block;
		overflow: hidden;
		white-space: nowrap;
	}

	.frm_dropzone .dz-preview .dz-details .dz-size strong {
		font-weight: normal;
	}

	.frm_dropzone .dz-preview .dz-details .dz-filename {
		max-width: 75%;
		text-overflow: ellipsis;
		padding-right: 5px;
	}

	.frm_dropzone .dz-preview .dz-details .dz-filename a {
		cursor: pointer;
	}

	.frm_dropzone .dz-preview .dz-column {
		float: left;
		width: 68%;
	}

	.frm_dropzone .dz-preview .dz-image {
		overflow: hidden;
		width: 30%;
		height: 60px;
		display: block;
		float: right;
		margin-left: 2%;
		margin-top: 5px;
	}

	.frm_dropzone .dz-preview .dz-image img {
		display: block;
		margin: 0 auto;
		max-height: 60px;
	}
	.frm_dropzone .dz-preview.dz-success .dz-success-mark {
		-webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
		-moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
		-ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
		-o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
		animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
	}

	.frm_dropzone .dz-preview.dz-processing .dz-progress {
		-webkit-transition: all 0.2s linear;
		-moz-transition: all 0.2s linear;
		-ms-transition: all 0.2s linear;
		-o-transition: all 0.2s linear;
		transition: all 0.2s linear;
	}

	.frm_dropzone .dz-preview .dz-progress {
		pointer-events: none;
		position: relative;
		height: 10px;
		width: 100%;
		background: rgba(221, 221, 221, 1);
		-webkit-transform: scale(1);
		border-radius: 8px;
		overflow: hidden;
	}

	.frm_dropzone .dz-preview .dz-progress .dz-upload,
	.frm_dropzone .dz-preview.dz-complete .dz-progress {
		background: #333;
		background: linear-gradient(to bottom, #666, #444);
	}

	.frm_dropzone .dz-preview .dz-progress .dz-upload {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 0;
		transition: width 300ms ease-in-out;
	}
	.frm_dropzone .dz-preview.dz-error .dz-error-message {
		display: block;
		opacity: 1;
		pointer-events: auto;
	}
	.frm_dropzone .dz-preview .dz-error-message {
		pointer-events: none;
		display: none;
		opacity: 0;
		transition: opacity 0.3s ease;
		color: #B94A48;
		padding: 0.5em;
	}

	.frm_dropzone .fallback input[type=file] {
		display: block;
	}

	/* Compact version */
	.frm_compact_text {
		display: none;
	}

	.frm_compact .frm_dropzone.dz-clickable {
		min-height: 60px;
		padding: 0;
		border: none;
		background-color: transparent;
	}

	.frm_compact .frm_dropzone.dz-clickable.frm_single_upload {
		max-width: 100%;
	}

	.frm_compact .frm_compact_text {
		display: inline;
	}

	.frm_compact .frm_dropzone.frm_single_upload.dz-started .dz-message,
	.frm_compact .frm_dropzone.dz-clickable.frm_single_upload .dz-message,
	.frm_compact .frm_dropzone.dz-clickable .dz-message {
		font-size: 14px;
		border: 1px solid #dfdfdf;
		background: #fff;
		display: inline-block;
		border-radius: 4px;
		padding: 6px 11px;
		box-sizing: border-box;
		box-shadow: 0 1px 1px #eeeeee;
		vertical-align: middle;
		margin: 0 0 1em;
	}

	.frm_compact .frm_dropzone .frm_upload_icon:before {
		font-size: 22px;
		padding-right: 7px;
		vertical-align: middle;
	}

	.frm_compact .frm_dropzone .frm_upload_icon {
		display: inline;
	}

	.frm_compact .frm_dropzone .dz-preview {
		min-height: 20px;
		max-width: 200px;
		margin: 0 5px;
		padding: 0;
		display: inline-block;
		border-bottom: none;
	}

	.frm_compact .frm_dropzone .dz-preview.frm_clearfix:after {
		content: '';
	}

	.frm_compact .frm_dropzone .frm_upload_text,
	.frm_compact .frm_dropzone .frm_small_text,
	.frm_compact .frm_dropzone .dz-preview .dz-image,
	.frm_compact .frm_dropzone .dz-preview .dz-details .dz-size {
		display: none;
	}

	.frm_compact .frm_dropzone .dz-preview .dz-details {
		margin: 0;
	}

	.frm_compact .frm_dropzone .dz-preview .dz-column {
		float: none;
		width: 100%;
	}

	.frm_compact .frm_dropzone .dz-preview .dz-details .dz-filename {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	/* Prevent horizontal scroll on RTL sites */
	.frm-show-form .dz-hidden-input {
		display: none;
	}
}