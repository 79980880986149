.axeptio_mount {
  z-index: 10000!important;
}
@media screen and (max-width: 980px){
  .axeptio_mount > div{
    bottom: 80px;
  }
}
@media screen and (max-width: 599px){
  .axeptio_mount > div{
    bottom: 60px;
  }
}
