// Custom Windows Map
// ==========================================================================
.custom-popup-thuria {
	width: 330px;
	margin-bottom: 50px;
	box-sizing: border-box;
	*, *:before, *:after {
		box-sizing: inherit;
	}

	.leaflet-popup-content-wrapper {
		padding: 0;
		border-radius: 0;
		background-color: initial;
		box-shadow: 0 10px 20px rgba($c_dark, 0.1);

		.leaflet-popup-content {
			margin: 0;
			width: 100% !important;

			.card-map{
				position: relative;
				top: auto;
				right: auto;
				transform: none;
				box-shadow: none;
				display: block;
			}
			.card-map-mobile{
				display: none;
				@include mobile{
					display: block;
				}
			}
			.card{
				width:100%;
			}
		}
	}

	.leaflet-popup-tip-container {
		width: 26px;
		height: 26px;
		transform: rotate(45deg);
		position: absolute;
		left: 50%;
		margin-left: -13px;
		bottom: -13px;
		overflow: hidden;
		pointer-events: none;
		z-index: 1;

		.leaflet-popup-tip{
			padding: 0;
			box-shadow: none;
			margin: 0 auto;
			width: 26px;
			height: 26px;
			border-radius: 0;
			transform: none;
		}
	}

	a.leaflet-popup-close-button {
		position: absolute;
		top: -15px;
		right: -15px;
		background: $c_main;
		color: $c_white;
		padding: 0;
		font-size: 15px;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		line-height: 30px;
        @include addTransition();
		&:after{
			content: none;
		}
		&:hover{
			body:not(.no-hover) & {
				cursor: pointer;
				background: $c_third;
				color: $c_white;
			}
		}
	}

	.card{
        background: $c_white;
		padding: 15px;
		border-radius: 10px;

		&:hover, &:focus{
			body:not(.no-hover) & {
				.title-card{
					color: $c_txt;
				}
                > div{
                    transform: none;
                }
			}
		}

		> div {
			position: relative;
			display: flex;
			align-items: center;
		}

		figure, .no-photo{
			flex-shrink: 0;
			@include aspect-ratio(112,76);
			margin: 0;
			height:auto;
			width: 112px;
			border-radius: 4px;

            &:before{
                height: auto;
                background: none;
                position: relative;
            }
		}

		.caption{
			display: flex;
			flex-direction: column;
            padding: 0 0 0 10px;
            margin: 0;

            .metas-card{
                margin: 0;
				margin-bottom: 4px;
            }
            .cta-favoris{
                padding: 0;
                margin-top: -58px;
            }
            .encart{
                margin-top: 0;
                right: 10px;
            }
            .tarif{
                color: $c_grey;
            }
            .reservable{
                display: none;
            }
		}

		&.card-offre{
			&:before{
				content:none;
			}
			>div{
				position: relative;
			}
			figure,.no-photo{
				@include aspect-ratio(112,76);
				height:auto;
			}

			.encart-tarif {
				position: static;
				display: block;
				order: 5;
				overflow: visible;

				&:before {
					display: none;
				}

				.tarif {
					position: static;
					display: block;
					font-weight: 500;
					color: #151B2A;
					padding: 0;
					height: initial;
				}
				.price {
					font-size: 13px;
					line-height: 1;
				}
			}

			.caption{
				min-height: initial;
				.cta-favoris{
					top: auto;
					right:auto;
					left: 20px;
					margin-top: -71px;
				}
			}

			.title-card {
				font-size: 11px;
				line-height: 1;
				margin-bottom: 8px;
			}
		}

		&.card-exp{
			figure, .no-photo{
				@include aspect-ratio(360, 516);
			}
			.caption{
				position: absolute;
				left: 20px;
				right: 20px;
				bottom: 25px;
				width:calc(100% - 40px);
				padding: 0;
			}
		}
	}
}



/* Style pour Zoom Control MAPS */
.leaflet-touch {
    .leaflet-bar {
        border: 1px solid #E0E0E0;
        margin-top: 40px;
        margin-right: 40px;
        border-radius: 30px;
		margin-left: 40px;
        @include mobile{
            margin-top: 20px;
            margin-right: 20px;
        }
        a {
            position: relative;
            font-size: 0;
            border-radius: 0;
            @include addTransition();
            &:first-child, &:last-child{
                border-radius: 0;
                &:after{
                    @include font();
                    color: $c_dark;
                    font-size: 10px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    margin: auto;
                    margin-top: -5px;
                    @include addTransition();
                }
            }
            &:first-child{
                &:after{
                    content: "\e93e";
                }
            }

            &:last-child{
                &:after{
                    content: "\e93d";
                }
            }

            &:hover, &:focus {
                body:not(.no-hover) & {
                    background: $c_greydark;
                    &:after{
                        color: $c_white;
                    }
                }
            }
        }
    }
}


.leaflet-bottom.leaflet-right {
	margin-bottom: 40px;
	margin-right: 40px;
	margin-top: initial;

	.leaflet-bar {
		margin: initial;
	}
}



// Data Bottom
// ==========================================================================
.data-bottom{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: $c_white;
    padding: 10px 0;
    transform: translate3d(0,0,0);
    display: none;
    @include mobile {
        display: block;
    }
    .th-slider{
       .no-hover & {
           margin: 0;
           scroll-padding: 10px;
           padding: 0 20px;
       }
    }
    .card {
        @include card-horizontale();
        margin-left: 0;
        margin-bottom: 0;
        overflow: hidden;
        width: calc(100vw - 60px) !important;
        @include mobile_small{
            width: calc(100vw - 40px) !important;
        }
    }
}