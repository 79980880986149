
.image-icon {

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	// ∆∆∆∆∆∆∆∆
	position: relative;

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		transform: translate(-50%, -50%);
		padding: 0 3px;
		font-weight: 700;
		color: $c_white;
		background-color: $c_main;

		display: none;
	}
}

.overlay-itinerance {
	z-index: 1000000001;
	right: 0;
	//width: auto;
	max-width: initial;
	display: none;

	.overlay-itinerance__inner {
		display: grid;
		grid-template-areas: "slider map"
							 "slider close"
							 "slider content"
							 "slider etape";
		grid-template-columns: 1fr 500px;
		grid-template-rows: 200px auto minmax(0, 1fr) auto;
		height: 100%;

		@include tablet_landscape {
			grid-template-areas: "slider"
                                "content"
                                "etape";
			grid-template-columns: 1fr;
			grid-template-rows: auto minmax(0, 1fr) auto;
		}
	}


	// Slider
	// ==========================================================================

	.area-slider {
		grid-area: slider;
		background-color: $c_main;

		@include tablet_landscape {
			@include aspect-ratio(800, 400);
		}

		@media only screen and (max-width: 420px) {
			@include aspect-ratio(1, 1);
		}
	}

	.slider-itinerance {
		height: 100%;
		width: 100%;

		@include tablet_landscape {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	.slide-image {
		figure {
			width: 100%;
			height: 100%;
		}

		.credits {
			color: white;
			margin-top: initial;
			bottom: 70px;
			left: 40px;
			right: 300px;

			@include tablet_landscape {
				bottom: 60px;
				right: 280px;
			}

			@include mobile {
				bottom: 20px;
				left: 20px;
				right: 160px;
			}
		}
	}

	.nav-slider {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 1;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 50px 40px;
		pointer-events: none;

		@include mobile {
			padding: 10px 20px;
		}

		// gradients
		&:before,
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			height: 300px;
			width: 100%;
			max-width: 600px;

			@include tablet_landscape {
				height: 130px;
			}

			@include mobile {
				height: 80px;
			}
		}

		&:before {
			right: 0;
			background-image: linear-gradient(to top left, #181E21, rgba(#181E21, 0) 50%);
		}

		&:after {
			left: 0;
			background-image: linear-gradient(to top right, #181E21, rgba(#181E21, 0) 21%);
		}


		.btn-arrow {
			@include reset-button;
			position: relative;
			pointer-events: initial;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 60px;
			height: 60px;
			background-color: $c_main;
			border-radius: 4px;
			@include addTransition();

			@include tablet_landscape {
				width: 40px;
				height: 40px;
			}

			@include mobile {
				width: 30px;
				height: 30px;
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					&:not(:disabled) {
						cursor: pointer;
						background: $c_third;
					}
				}
			}

			&:after {
				content: '';
				@include font();
				font-size: 15px;
				color: $c_white;

				@include tablet_landscape {
					font-size: 12px;
				}
			}

			&:disabled {
				opacity: 0.2;
				cursor: auto;
				pointer-events: none;
			}

			&.--prev {
				&:after {
					content: "\e903";
				}
			}

			&.--next {
				&:after {
					content: "\e901";
				}
			}
		}

		.counter {
			position: relative;
			display: flex;
			justify-content: center;
			width: 130px;
			@include ft('', 20px, 400, $c_white, '');

			@include mobile {
				width: 60px;
				margin-top: 3px;
				font-size: 15px;
			}

			.current {
				font-weight: 700;
			}
		}
	}


	// Map
	// ==========================================================================

	.area-map {
		grid-area: map;
		position: relative;
		background-color: $c_lightgray;

		@include tablet_landscape {
			display: none;
		}
	}

	.close-overlay {
		grid-area: close;
		justify-self: flex-end;
		padding-bottom: initial;

		position: absolute;
		padding: 12px 20px 11px 20px !important;
		background-color: white;
		top: 20px;
		right: 20px;
		border-radius: 6px;

		@include tablet_landscape {
			grid-area: slider; // placement au-dessus du slider
			position: relative;
			z-index: 10;
			align-self: flex-start;
			color: $c_white;
			font-size: 0;
			letter-spacing: 0;
			background: none;
			&:after {
				margin-left: 0;
			}
		}
	}

	.show-hide-etape.hide {
		display: none;
		visibility: hidden;
	}

	// Infos image
	// ==========================================================================

	.area-infos {
		grid-area: content;
		display: flex;
		flex-direction: column;

		@include tablet_landscape {
			padding-top: 20px;
		}
		@include mobile {
			padding-top: initial;
		}

		.scroller {
			padding-top: 20px;
			padding-bottom: 80px;

			&:after {
				display: none;
			}
		}

		.info-image {
			display: none;

			&.is-active {
				display: block;
			}
		}

		.title-image {
			display: block;
			@include ft('', 25px, 300, $c_main, 1);
			@include letter-spacing(20);

			@include mobile {
				font-size: 20px;
			}

			& + * {
				margin-top: 20px;
			}
		}

		.text-image {

			// ∆∆∆∆∆
			p + p {
				margin-top: 500px;
			}
		}
	}


	// CTA étape
	// ==========================================================================

	.area-etape {
		grid-area: etape;
		display: flex;
		flex-direction: column;
		padding: 20px 40px 40px;
		background-color: $c_white;
		position: relative;

		@include mobile {
			padding: 20px;
		}

		.preview-etape__label {
			@include ft('', 25px, 500, $c_txt, 1);
			@include letter-spacing(20);
			padding-bottom: 5px;
			border-bottom: 1px solid rgba($c_border, 0.5);

			@include tablet_landscape {
				display: none;
			}

			& + * {
				margin-top: 20px;
			}
		}


		.preview-etape__cities {
			display: flex;
			align-items: center;
			@include ft('', 14px, 300, $c_gradient, 1);

			@include tablet_landscape {
				display: none;
			}

			& + * {
				margin-top: 8px;
			}

			span {
				display: inline-flex;
				align-items: center;

				&:before {
					@include font();
				}

				&:first-child {
					&:before {
						content: "\e949";
						margin-right: 3px;
						font-size: 14px;
						transform: translateY(-2px);
					}
				}

				&:nth-child(2) {
					margin-left: 2px;

					&:before {
						content: "\e901";
						margin-right: 2px;
						font-size: 9px;
						transform: translateY(-2px);
					}
				}
			}
		}

		.preview-etape__title {
			@include ft('', 22px, 700, $c_gradient, 1.27);
			@include letter-spacing(20);
			margin-bottom: 5px;

			@include tablet_landscape {
				display: none;
			}
		}

		.metas-etape {
			@include tablet_landscape {
				display: none;
			}
		}


		.meta-etape {
			font-size: 12px;

			& + .meta-etape {
				padding-left: 14px;
			}

			&:not(:last-child) {
				padding-right: 14px;
			}
		}

		.meta-etape__icon {
			font-size: 15px;
		}

		.meta-etape__label {
			transform: translateY(2px);
		}


		.btn-discover-etape {
			justify-content: flex-end;

			@include min-tablet_landscape {
				margin-top: 20px;
			}

			&:before {
				margin-left: auto;
			}
		}
	}
}

