// text with icons

@mixin myex-text-icon {
	&:before {
		margin-right: calculateRem(5px);
		@include font();
	}
}

.myex-text-icon--left{
	&:before{
		margin-left: calculateRem(5px);
		margin-right: 0 !important ;
	}
}

.myex-text-icon--superexplorer {
	@extend .icon-certified;
	@include myex-text-icon;
	color: var(--color-primary);
	font-weight: 600;
}

.myex-text-icon--numbersuscribers {
	@extend .icon-ico-favoris-ok;
	@include myex-text-icon;
}

.myex-text-icon--numbercollections {
	@extend .icon-ico-bookmark;
	@include myex-text-icon;
}

.myex-text-icon--numberoffers {
	@extend .icon-eye-fill;
	@include myex-text-icon;
}

.myex-text-icon--place {
	@extend .icon-ico-localisation;
	@include myex-text-icon;
}
