// Card - Default Styles
// ==========================================================================
.card {
	position: relative;
	display: block;

	&.hover,
	&:hover,
	&:focus {
		body:not(.no-hover) & {
			> div {
				//transform: translate(-20px);
				//transform: translate3d(0, -20px, 0);
				.reservable {
					opacity: 1;
				}
			}
		}
	}

	// card-link = dernière vignette d'un bloc de mise en relation
	&.card-link {
		background: $c_third;
		box-sizing: border-box;
		border: none;
		height: auto;
		cursor: pointer;
		border-radius: 8px;

		@extend %aligncenter;
		@include mobile {
			padding: 0;
			height: auto;
		}

		&:hover,
		&:focus {
			body:not(.no-hover) & {
				.btn {
					background: $c_sub;
					border-color: $c_sub;
				}

				.btn-white {
					background: white;
					border-color: white;
					color: $c_third;
				}
			}
		}

		.txt {
			@include ft($f_hx, 25px, 300, $c_white, 30px);
			@extend %center;

			padding: 40px;
			display: flex;
			flex-wrap: wrap;
			box-sizing: border-box;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;

			@include tablet_landscape {
				padding: 20px 40px;
			}

			span:not(.btn-white) {
				color: white;
				margin-bottom: 10px;
				font-size: calculateRem(25px);
			}

			& .btn:first-child {
				margin-left: auto;
				margin-right: auto;
			}
		}

		.btn {
			border-radius: 4px;
			box-sizing: border-box;
			display: block;
			background-color: transparent;
			border: 1px solid $c_white;
			padding: 15px 50px 14px;
			font-size: 14px;
			font-weight: 600;
		}
	}

	> div {
		position: relative;
		@include addTransition();
	}

	.title-card {
		display: block;
		@include ft($f_hx, 18px, 600, $c_txt, 22px);
		margin-bottom: 0;
		margin-top: 0;
		@include addTransition();
		@include mobile {
			font-size: calculateRem(16px);
		}

		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	figure,
	.no-photo {
		position: relative;
		@include aspect-ratio(1, 1);
		@include addTransition();

		> img,
		> picture {
			@extend %center;
		}

		figcaption {
			display: none;
		}

		& ~ .caption {
			margin-top: 15px;
		}
	}

	.no-photo {
		&:after {
			content: none;
		}
	}

	.credits {
		display: none;
	}

	.encart {
		position: absolute;
		right: 20px;
		margin-top: -54px;
	}

	.caption {
		width: 100%;

		.cta-favoris {
			position: absolute;
			z-index: 100;
			margin: 0;
			margin-top: -72px;
			padding: 0 30px;
			z-index: 50;
		}
	}

	.reservable {
		opacity: 0;
		@include addTransition();
		@include mobile {
			pointer-events: none;
		}
	}

	.wrapper-cat {
		display: inline-block;
		vertical-align: top;

		&:before {
			top: 0;
		}

		span {
			@include ft($f_hx, 11px, 300, $c_main, 16px);
			@include mobile {
				font-size: calculateRem(10px);
			}
		}
	}

	.location {
		display: inline-block;
		vertical-align: top;

		&:before {
			top: 2px;
		}

		@include ft($f_hx, 11px, 300, $c_main, 16px);
		letter-spacing: 0.4px;
	}

	.metas-card {
		display: block;
		clear: both;

		> * {
			display: block;
		}
	}

	//Refonte des cards
	&.edito-v2 {
		figure,
		.no-photo {
			@include aspect-ratio(314, 450);
			border-radius: 10px;
			overflow: hidden;

			picture {
				@extend %center;
			}

			&:after {
				content: "";
				@extend .gradient-bottom;
				z-index: 10;
				top: auto;
				opacity: 0.8;
				height: 75%;
				@include mobile {
					opacity: 1;
				}
			}
		}

		.no-photo {
			height: auto;

			&:after {
				content: none;
			}

			& ~ .caption {
				.btn {
					background: $c_greydark;

					&:hover,
					&:focus {
						body:not(.no-hover) & {
							background: $c_greydark;
						}
					}
				}
			}
		}

		.caption {
			position: absolute;
			z-index: 50;
			left: 50%;
			bottom: 40px;
			width: calc(100% - 40px);
			transform: translateX(-50%);
			text-align: center;

			&::after {
				content: "";
				position: absolute;
				left: 50%;
				bottom: -10px;
				width: 20px;
				height: 3px;
				border-radius: 50px;
				transform: translateX(-50%);
				background-color: $c_third;
			}

			.title-card {
				color: white;
			}

			h4 {
				@include ft($f_hx, 18px, 500, $c_white, 22px);
				display: block;
				margin-bottom: 15px;
				@include mobile {
					font-size: calculateRem(16px);
					line-height: calculateRem(18px);
					margin: 0;
				}

				& + .btn {
					margin-top: 15px;
				}
			}

			.btn {
				@include mobile {
					display: none;
				}

				&:hover,
				&:focus {
					body:not(.no-hover) & {
						background: $c_main;
					}
				}
			}

			.caption-link {
				@include ft($f_hx, 14px, 600, $c_white, 21px);

				@include mobile {
					font-size: calculateRem(11px);
					line-height: calculateRem(17px);
				}
			}
		}

		&:hover {
			body:not(.no-hover) & {
				.caption {
					.btn {
						background-color: $c_sub;
						border-color: $c_sub;
					}
				}
			}
		}
	}

	&.card-page--horizontal {
		figure,
		.no-photo {
			&::before {
				padding-top: 150px;
			}
		}
	}

	.on-light-bg & {
		&.edito-v2 {
			figure,
			.no-photo {
				&::before {
					padding-top: 100% !important;
				}
			}

			.btn {
				border: 1px solid rgba($c_greydark, 0.35);
			}
		}

		&.card-exp {
			figure,
			.no-photo {
				&::before {
					padding-top: 100% !important;
				}
			}
		}
	}

	&.card-offre {
		display: block;

		.card-offre-btn-add-favs {
			bottom: 84px;
		}

		figure {
			@include aspect-ratio(3, 2);
			border-radius: 10px;
		}

		.encart-tarif {
			@include aspect-ratio(3, 2);
			pointer-events: none;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			width: 100%;
		}

		.tarif {
			pointer-events: auto;
			@include ft($f_hx, 10px, 500, $c_txt_lien, 16px);
			box-sizing: border-box;
			position: absolute;
			display: block;
			align-items: center;
			height: 41px;
			padding: 10px 15px;
			right: 0;
			bottom: 20px;
			left: auto;
			background-color: white;
			border-radius: 8px 0 0 8px;
			z-index: 2;

			span {
				vertical-align: middle;
			}

			&.gratuit {
				@include ft("", 18px, 500, $c_main, 27px);
				@include mobile {
					@include ft("", 12px, 500, $c_main, 18px);
				}
			}

			.price {
				@include ft("", 18px, 700, $c_txt_lien, 27px);
				position: relative;
				letter-spacing: 0.5px;
				top: 2px;
				display: inline-block;
				vertical-align: middle;
				margin-left: 5px;
				margin-top: -2px;
			}
		}

		.encart {
			top: 10px;
			right: 10px;
			background-color: $c_main;
			color: white;
			font-weight: 500;
			margin-top: 0;
			z-index: 2;
		}

		.caption {
			min-height: 50px;
			margin-top: 8px;
			@include mobile {
				min-height: 0;
			}

			.metas-card {
				margin-top: 0;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 5px;

				.classement {
					margin-top: -3px;
					margin-bottom: 0;
					margin-left: 6px;
				}
			}
		}

		.title-card {
			font-size: 14px;
			line-height: 18px;
			font-weight: 600;
			margin: 0 !important;
			@include ellipsis(2, 22px);
			@include mobile {
				margin-bottom: 10px;
				@include ellipsis(2, 18px);
			}
		}

		.card-offres-dots {
			padding: 10px;
			border-radius: 100px;

			&:hover {
				background-color: #d8d8d8;
				color: $c_third;
			}
		}
	}
}

.tarif {
	@include ft($f_hx, 12px, 300, $c_grey, 24px);
	display: inline-block;
	vertical-align: middle;
	margin-top: -3px;

	&.gratuit {
		@include ft("", 18px, 500, $c_sub, 27px);
	}

	.price {
		@include ft("", 24px, 700, $c_greydark, 35px);
		position: relative;
		letter-spacing: 0.5px;
		top: 2px;
		display: inline-block;
		vertical-align: middle;
		margin-left: 5px;
		@include mobile {
			top: 1px;
			font-size: calculateRem(21px);
			line-height: calculateRem(30px);
		}
	}
}

.encart {
	@include ft($f_hx, 12px, 500, $c_greydark, 20px);
	padding: 8px 10px 6px;
	border-radius: 4px;
	background: $c_white;
	z-index: 100;
	@include mobile {
		@include ft($f_hx, 8px, 500, $c_greydark, 16px);
		padding: 5px 8px 3px;
	}

	&.highlight {
		background: $c_third;
		color: $c_white;
	}
}

// Card - Expériences
// ==========================================================================
.card-exp {
	width: 100%;
	overflow: hidden;

	figure,
	.no-photo {
		@include aspect-ratio(400, 265);
		border-radius: 5px;
	}

	.no-photo {
		height: auto;
	}

	.caption {
		margin-top: 15px;

		.card-exp__subtitle {
			font: normal normal 11px/17px Poppins;
			letter-spacing: 1.65px;
			color: var(--bluegrey);
			text-transform: uppercase;

			overflow: hidden; // truncate
			text-overflow: ellipsis;
			white-space: nowrap;
			display: block;

			strong {
				font-weight: bold;
			}

			padding-left: 13px;
			position: relative;

			&::before {
				content: '\e907';
				@include font();
				font-size: 10px;
				color: var(--bluegrey);
				top: 2px;
				left: 0;
				position: absolute;
				height: 10px;
				width: 6px;

				@include mobile {
					top: 2px;
					font-size: 9px;
				}
			}

			@include mobile {
				font-size: 9px;
				line-height: 13px;
				margin-bottom: 6px;
			}
		}

		.title-card {
			@include ft($f_txt, 14px, 400, $c_txt, 18px);
			margin-top: 3px;
		}

	}

	.basic-link {
		display: flex;
		flex-direction: column;
		@include ft($f_txt, 14px, 600, $c_txt, 17px);
		margin-top: 10px;

		@include mobile {
			margin-top: 2px;
			font-weight: 500;
			color: #000;
		}

		&::before {
			content: none;
		}

		&::after {
			content: "";
			margin-top: 5px;
			width: 20px;
			height: 3px;
			background-color: $c_third;
			border-radius: 4px;
			margin-left: 0;
		}
	}
}

// Card - Thématiques
// ==========================================================================
.card-thematique {
	figure,
	.no-photo {
		@include aspect-ratio(314, 450);
		border-radius: 10px;
		overflow: hidden;

		picture {
			@extend %center;
		}

		&:after {
			content: "";
			@extend .gradient-bottom;
			z-index: 10;
			top: auto;
			opacity: 0.8;
			height: 75%;
			@include mobile {
				opacity: 1;
			}
		}
	}

	.no-photo {
		height: auto;

		&:after {
			content: none;
		}

		& ~ .caption {
			.btn {
				background: $c_greydark;

				&:hover,
				&:focus {
					body:not(.no-hover) & {
						background: $c_greydark;
					}
				}
			}
		}
	}

	.caption {
		position: absolute;
		z-index: 50;
		left: 50%;
		bottom: 40px;
		width: calc(100% - 40px);
		transform: translateX(-50%);
		text-align: center;

		@include mobile {
			bottom: 20px;
			width: calc(100% - 40px);
		}

		&::after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: -10px;
			width: 20px;
			height: 3px;
			border-radius: 50px;
			transform: translateX(-50%);
			background-color: $c_third;

			@include mobile {
				bottom: -5px;
			}
		}

		.title-card {
			@include ft($f_hx, 18px, 600, $c_white, 22px);
			display: block;
			margin-bottom: 15px;
			@include mobile {
				font-size: calculateRem(13px);
				line-height: calculateRem(17px);
			}

			& + .btn {
				margin-top: 15px;
			}
		}

		.btn {
			@include mobile {
				display: none;
			}

			&:hover,
			&:focus {
				body:not(.no-hover) & {
					background: $c_main;
				}
			}
		}

		.caption-link {
			@include ft($f_hx, 14px, 600, $c_white, 21px);

			@include mobile {
				font-size: calculateRem(11px);
				line-height: calculateRem(17px);
			}
		}

		.basic-link {
			@include mobile {
				font-size: calculateRem(12px);
			}
		}
	}

	&--luxe {
		margin-right: 40px !important;

		@include mobile {
			width: 80% !important;
			margin-right: 20px !important;
		}

		.caption {
			.headline {
				@include ft($f_hx, 14px, 300, $c_gold_luxe, 21px);
			}
		}
	}

	.hovered {
		opacity: 0;
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
		border-radius: 8px;
		overflow: hidden;

		&:after {
			background: linear-gradient(
											to bottom,
											rgba(32, 37, 41, 0) 0%,
											#202529 100%
			);
			content: "";
			z-index: 0;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	&:hover {
		.hovered {
			transition: all 0.2s ease;
			opacity: 1;
		}
	}
}

// Card - Itinérance
// ==========================================================================

.card-itineraire {
	figure,
	.no-photo {
		height: auto;
		@include aspect-ratio(300, 430);
		border-radius: 10px;

		&:after {
			content: "";
			@extend .gradient-bottom;
			opacity: 0.75;
			top: auto;
			z-index: 1;
			height: 70%;
			opacity: 1;
			@include addTransition();
		}
	}

	.caption {
		position: absolute;
		left: 20px;
		right: 20px;
		bottom: 20px;
		z-index: 2;
		width: auto;

		.wrapper-infos {
			display: flex;
			align-items: center;
			margin-bottom: 7px !important;
			color: $c_white;
			justify-content: center;
		}

		[class^="icon-"],
		[class*=" icon-"] {
			font-size: 25px;

			@include mobile {
				font-size: 18px;
			}

			& + * {
				margin-left: 5px;
			}
		}

		.distance {
			@include ft($f_hx, 16px, 400, $c_white, 1);
			margin-top: 3px;
			display: flex;
			align-items: center;

			@include mobile {
				font-size: 14px;
			}
		}

		.title-card {
			@include ft($f_hx, 20px, 700, $c_white, 1.12);
			font-size: 20px !important;
			text-align: center;
			margin-bottom: 8px !important;
			@include mobile {
				font-size: 18px;
			}
		}
	}

	&:hover {
		body:not(.no-hover) & {
			figure,
			.no-photo {
				&:after {
					height: 95%;
				}
			}
		}
	}

	.map-card {
		position: absolute;
		bottom: 55%;
		left: 50%;
		translate: -50%;
	}
}

.card-itineraire-listing {
	display: grid;
	grid-template-columns: 4fr 5fr;
	color: $c_white;

	@media screen and (max-width: 768px) {
		display: block;
	}

	@include mobile {
		position: relative;
		border-radius: 10px;
		overflow: hidden;
	}

	&.hide {
		display: none;
	}

	.col-left {
		grid-column: 1;
		padding-right: 40px;

		@media screen and (max-width: 768px) {
			padding-right: initial;
			padding-bottom: 40px;
		}

		@include mobile {
			padding-bottom: 20px;
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}

	.col-right {
		grid-column: 2;

		@include mobile {

			.image-cover {
				opacity: 0.8;
				height: 440px;
			}
		}
	}

	.caption {
		@include min-desktop {
			margin-left: 80px;
		}

		.wrapper-infos {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			color: $c_white;

			[class^="icon-"],
			[class*=" icon-"] {
				font-size: 25px;

				& + * {
					margin-left: 5px;
				}

				& + .distance {
					margin-left: 10px;
				}
			}

			@include mobile {
				justify-content: center;
			}
		}

		.distance {
			@include ft($f_hx, 16px, 400, $c_white, 1);
			margin-top: 3px;

			@include mobile {
				font-size: 14px;
			}
		}

		.title-card {
			@include ft($f_hx, 36px, 700, $c_white, 1.12);
			margin-bottom: initial;

			@include mobile {
				font-size: 20px;
				font-weight: 600;
			}
		}

		@include mobile {
			position: absolute;
			bottom: 30px;
			z-index: 99;
			text-align: center;
			left: 50%;
			transform: translateX(-50%);
			width: 90%;
		}
	}

	.image-listing {
		margin-top: 20px;
		max-width: 600px;

		@include mobile {
			display: none;
		}
	}

	.buttons-wrapper {
		display: flex;
		margin-top: 30px;

		> *:first-child {
			margin-right: 10px;
		}

		@include tablet_portrait {
			flex-direction: column;
			align-items: flex-start;

			> *:first-child {
				margin-right: initial;
				margin-bottom: 10px;
			}
		}

		@media screen and (max-width: 768px) {
			margin-top: 10px;
			flex-direction: initial;

			> *:first-child {
				margin-right: 10px;
			}
		}

		@include mobile {
			display: none;
		}
	}

	figure,
	.no-photo {
		height: auto;
	}
}

.card-etape {
	figure {
		img.lazy.loaded {
			opacity: 1 !important;
		}
	}

	.title-card {
		font-size: 18px !important;

		&:before {
			content: "\e949";
			margin-right: 8px;
			font-size: 20px;
			transform: translateY(-1px);
			font-family: "icomoon" !important;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			display: inline-block;
			vertical-align: middle;
		}
	}

	.from-iti {
		color: white;
		margin-bottom: 0;
		text-align: center;
	}
}

.card-article {
	width: 100%;

	display: flex;
	align-items: center;
	margin-bottom: 10px;

	&__img {
		flex: 1;
		margin-right: 10px;

		figure,
		.no-photo {
			@include aspect-ratio(400, 265);
			border-radius: 10px;
		}

		&.is-new-notif {
			@include style-dot-notif-card();
		}
	}

	&__content {
		flex: 30%;
	}

	&__title {
		@include ft($f_txt, 14px, 400, $c_txt, 18px);
	}

	&__tag {
		display: flex;
		align-items: center;
		@include ft($f_txt, 11px, 300, $c_txt, 17px);
		text-transform: uppercase;
		letter-spacing: 1.2;
		margin-bottom: 5px;

		&__icon {
			display: flex;
		}

		span {
			margin-top: 2px;
			margin-left: 3px;
		}
	}

	&__link {
		display: flex;
		flex-direction: column;
		@include ft($f_txt, 14px, 600, $c_txt, 17px);

		&::after {
			content: "";
			margin-top: 5px;
			width: 20px;
			height: 3px;
			background-color: $c_third;
			border-radius: 4px;
		}
	}

	&--featured {
		flex-direction: column;

		.card-article__img {
			margin-right: 0;
		}

		figure {
			border-radius: 10px;
		}
	}
}
