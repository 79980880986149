.myex-radio-picture {
	position: relative;

	label {
		@extend .icon-ico-close;
		cursor: pointer;
		background: transparent;
		font-size: calculateRem(18px);
		font-weight: 600;
		padding: calculateRem(15px);
		line-height: 1;
		letter-spacing: 0.2px;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		color: #fff;
		text-align: center;
		padding: calculateRem(10px);
		top:0;
		z-index: 2;

		@include min-tablet_small {
			font-size: calculateRem(16px);
		}
	}

	.wrapper-img {
		border-radius: calculateRem(10px);
		width: 100%;
		height: 100%;
		transition: all ease 500ms;
		border: 0 solid transparent;
		background: var(--bg-card-img);

		@include aspect-ratio(61px, 61px);

		&:after {
			content: "";
			background: linear-gradient(160deg, #1e2529 0%, rgba(30, 37, 41, 0) 100%);
			opacity: 0.5;
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			top: 0;
			z-index: 0;
		}
	}

	input {
		display: none;

		&:checked {
			~ label {
				&:before {
					opacity: 1;
					background: var(--color-primary);
				}
			}

			~ .wrapper-img {
				border: calculateRem(3px) solid var(--color-primary);
			}
		}
	}

	// no label show
	&--no-label {
		label {
			opacity: 0;
		}

		//no-overlay
		.wrapper-img:after {
			display: none;
		}
	}

	&--random {
		.wrapper-img {
			background: #dea99e;
			i {
				position: absolute;
				top: 50%;
				right: 50%;
				transform: translate(50%, -50%);
				color: #fff;
				font-size: calculateRem(20px);
			}
		}
	}
}
