.search-results {
	position: fixed;
	width: 100%;
	height: calc(100vh - 80px);
	background-color: $c_greydark;
	display: flex;
	flex-direction: column;
	z-index: 9999999;

	@include mobile {
		height: calc(100vh - 74px);
	}

	&__header-wrapper {
		height: auto !important;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	&__header {
		padding-top: 55px;
		padding-bottom: 40px;
		background-color: $c_white;

		@include mobile {
			padding-top: 15px;
			padding-bottom: 12px;
			border-bottom: none;
			border-radius: 15px 15px 0 0;
		}

		&-left {
			display: inline-block;
		}
	}

	&__title {
		display: inline;
		font-family: $f_txt;
		font-size: calculateRem(40px);
		line-height: calculateRem(45px);
		font-weight: 600;
		color: $c_dark;

		@include tablet_portrait {
			font-size: calculateRem(24px);
			line-height: calculateRem(28px);
		}

		@include mobile {
			font-size: calculateRem(18px);
			line-height: calculateRem(20px);


			&-text {
				display: none
			}
		}

		&::before {
			content: "\e92d";
			@include font();
			font-size: 20px;
			margin-top: -4px;
			margin-right: 18px;

			@include mobile {
				margin-right: 8px;
			}
		}

		&-search {
			color: $c_main;
			font-style: italic;

			&::first-letter {
				text-transform: uppercase;
			}

			@include mobile {
				color: $c_txt;
				font-style: normal;
			}
		}

	}

	&__edit-btn {
		@include reset-button;
		display: inline-block;
		padding-bottom: 18px;
		vertical-align: middle;
		margin-left: 30px;
		color: $c_light_txt;
		text-decoration: underline;
		font-size: calculateRem(13px);
		font-weight: 500;
		font-family: $f_txt;

		@include mobile {
			font-size: calculateRem(11px);
			margin-left: 8px;
			padding-bottom: 0;
		}


		&::before {
			content: "\e9ad";
			@include font();
			font-size: 12px;
			margin-top: -1px;


			@include mobile {
				margin-right: 2px;
			}
		}
	}

	&__filters-btn {
		display: flex;
		align-items: center;
		background-color: white;
		border: 2px solid #D5D9DC;
		color: $c_txt;
		padding: 15px 38px 11px;

		@include mobile {
			font-size: 11px;
			padding: 6px 5px 4px 7px;
			min-width: auto;
		}

		i {
			margin-top: -2px;
			margin-left: 14px;

			@include mobile {
				margin-left: 2px;
			}

			&::before {
				font-size: 17px;
			}
		}
	}

	&__content-container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
		background-color: $c_white;
	}

	&__content {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		align-items: stretch;
		height: 100%;

		@include mobile {
			display: block;
			height: auto;
			min-height: 0;
		}

		&-top {
			border-bottom: 1px solid $c_border;
			padding-bottom: 25px;

			@include tablet_landscape {
				position: relative;
				border-bottom: 0;
				padding-bottom: 0;
				margin-bottom: -15px;

				&::after {
					content: "";
					position: absolute;
					width: 100%;
					height: 1px;
					background-color: $c_border;
					bottom: 15px;
					left: 0;
				}
			}

			.content-wrapper {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}

			&-left {
				margin-left: -20px;
				margin-right: -20px;

				@include tablet_landscape {
					position: relative;
					display: flex;
					padding-bottom: 25px;
					overflow-x: scroll;
				}

				.search-onglet {
					position: relative;
					padding: 10px 20px;
					cursor: pointer;
					font-size: 12px;
					font-weight: 600;
					color: $c_greydark;

					@include tablet_landscape {
						flex-shrink: 0;
						padding: 10px;
					}

					&.search-onglet--active {
						color: $c_third;

						&:hover {
							color: $c_third;
							opacity: 1;
						}

						&::after {
							content: "";
							position: absolute;
							width: 12px;
							height: 12px;
							background-color: #DEA99E;
							border-radius: 100%;
							left: 50%;
							bottom: -16px;
							z-index: 999999;
							transform: translateX(-50%);
						}
					}

					&:hover {
						color: $c_greydark;
						opacity: 0.3;
					}
				}
			}

			&-right {
				text-align: right;
				color: $c_light_txt;

				@include tablet_landscape {
					display: none;
				}

				.content-wrapper {
					height: auto !important;
					justify-content: right;
				}
			}
		}

		.btn-map-overlay {
			position: absolute;
			bottom: 106px;
			left: 50%;
			z-index: 150;
			translate: -50%;

			@include tablet_small {
				bottom: 16vh;
			}
			@include min-tablet_small {
				margin-top: 50px;
			}

			@include min-tablet_portrait {
				display: none;
			}

		}
	}

	&__content-wrapper {
		margin-left: 0 !important;
	}

	&__list {
		overflow-y: scroll;
		width: 100%;
		height: 100%;
		margin-left: 32px;

		display: flex;
		flex-direction: column;

		@include tablet_landscape {
			margin-left: 0;
		}

		.content-wrapper {
			margin-left: 0 !important;
			margin-bottom: 100px;

			@include tablet_landscape {
				margin-left: 20px !important;
			}

			@media screen and (min-width: 1440px) {
				width: 91%;
			}
		}

		&--small {
			.card {
				width: calc(100% - 20px) !important;
			}
		}

		&__cards {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin-top: 32px;
			margin-bottom: 32px;

			margin-left: -10px;
			margin-right: -10px;

			color: $c_greydark;
		}

		button.btn {
			margin: 0 auto;
			display: block;
			padding: 13px 38px 11px;
		}

		.card {
			width: calc(100% / 3 - 20px);
			margin: 0 10px 20px;

			&.myex-card-explorer,
			&.myex-card-collection,
			&.edito-v2,
			&.card-itineraire {
				width: calc(100% / 4 - 20px);

				@include desktop1200 {
					width: calc(100% / 3 - 20px);
				}
			}

			@include desktop1200 {
				width: calc(100% / 2 - 20px);
			}

			&,
			&.myex-card-collection {
				@include mobile {
					width: calc(100% - 20px);
				}
			}

			&.myex-card-explorer ,
			&.edito-v2,
			&.card-itineraire {
				@include mobile {
					width: calc(100% / 2 - 20px);
				}
			}

			&.card-itineraire {
				@include mobile {
					.title-card {
						font-size: 12px !important;
						font-weight: 600 !important;
						line-height: 1.4 !important;
					}
					
					.from-iti {
						font-size: 11px !important;
						line-height: 1.5 !important;
					}
				}
			}
		}
	}

	&__load-more {
		width: 100%;
		position: relative;
		text-align: center;
		bottom: 80px;
		background-color: white;
		z-index: 100;
		padding-top: 18px;
		max-height: 115px;

		@include mobile {
			max-height: 80px;
		}

		&::after {
			display: flex;
			position: relative;
			content: '';
			z-index: 75;
			bottom: 185px;
			opacity: 0.8;
			height: 125px;
			background: linear-gradient(to bottom, rgba(32, 37, 41, 0) 0%, white 100%);
		}

		&-btn {
			width: 60px;
			height: 60px;
			font-size: 28px;
			min-width: auto;
		}
	}
}