.card__listing_row {
    position: relative;
    max-width: calc(100% - 40px);
    display: grid;
    margin: 0 auto 40px 0;
    gap: 20px 30px;
    grid-template-columns: repeat(2, 48%);
    border-bottom: 1px solid rgba(#ABB3B9, 0.5);

    
    @include desktop1200 {
        max-width: 880px;
        margin: 0 auto 30px;
    }

    @include mobile {
        max-width: 100%;
        grid-template-columns: repeat(1, 100%);
        margin-bottom: 20px;
    }
}

.card__listing_row>article {
    @include mobile {
        flex: unset;    
    }
    flex: 50%;
}

.card__video {
    width: 1210px;
    height: 681px;
    
    border-radius: 10px;
    background-color: var(--rose);
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    @include mobile {
        width: 100%;
        height: 211px;
        border-radius: 0px;
        
    }
    &::after {
        position: absolute;
        z-index: 2;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: var(--black);
        opacity: 0.3;
    }

    &_content {
        position: relative;
        z-index: 3;
        padding-left: 140px;
        @include mobile {
            padding-left: 65px;
        }
        &::before {
            position: absolute;
            z-index: 2;
            content: '\e94b';
            top: 0;
            left: 34px;
            @include font();
            color: var(--white);
            font-size: 80px;
            @include mobile {
                left: 16px;
                font-size: 40px;
            }

        }

        h3 {
            font: normal normal 600 40px/40px Poppins;
            letter-spacing: 0px;
            color: var(--white);
            @include mobile {
                font-size: 18px;
                line-height: 22px;
            }
        }

        p {
            color: var(--white);
            font: normal normal normal 13px/22px Poppins;
            text-transform: uppercase;

            strong {
                font-weight: bold;
                text-transform: lowercase;
            }
            @include mobile {
                font-size: 11px;
                line-height: 22px;
            }
        }
    }
}

.card__listing {

    position: relative;
    z-index: 2;

    &_thumb {
        width: 100%;
        height: 281px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    &_content {

        position: relative;
        padding-top: 14px;
        width: 100%;

        &--link {

            font: normal normal 11px/17px Poppins;
            letter-spacing: 1.65px;
            color: var(--bluegrey);
            text-transform: uppercase;

            overflow: hidden; // truncate
			text-overflow: ellipsis;
			white-space: nowrap;
            

            strong {
                font-weight: bold;
            }

            padding-left: 13px;
            position: relative;

            &::before {
                content: '\e907';
                @include font();
                font-size: 10px;
                color: var(--bluegrey);
                top: 4px;
                left: 0;
                position: absolute;
                height: 10px;
                width: 6px;
            }
        }

        &--title {
            margin-top: 7px;
            font: normal normal bold 20px/28px Poppins;

            color: var(--darkblue);
            margin-bottom: 10px;
        }

        &--text {
            margin-top: 7px;
            font: normal normal normal 13px/22px Libre Baskerville;

            color: var(--darkblue);

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        &--info {
            margin-top: 20px;
            font: normal normal normal 11px/22px Poppins;

            color: var(--darkblue);
            padding-left: 17px;
            position: relative;

            &::before {
                content: '\e9eb';
                @include font();
                font-size: 12px;
                color: var(--rose);
                top: 3px;
                left: 0;
                position: absolute;
                height: 12px;
                .bloc__grandest & {
                    color: var(--darkblue);
                }
            }
        }
    }
}

.bloc__listing {
    max-width: 880px;
    margin: 40px auto;
    margin-right: 40px;
    width: calc(100% - 40px);
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include desktop1200 {
        margin-right: auto;
    }

    @include mobile {
        margin: 20px auto;
        width: 100%;
    }

    &::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: -20px;
        width: 100%;
        height: 1px;
        background-color: rgba(#ABB3B9, 0.5);

        @include mobile {
            display: none;
        }
    }



    &_thumb {
        width: 250px;
        height: 167px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;

        @include mobile {
            width: 120px;
            height: 79px;
        }

    }

    &_content {
        @include mobile {
            margin-left: 15px;

            width: calc(100% - 120px);
        }
        margin-left: 35px;

        width: calc(100% - 285px);

        &--link {
            font: normal normal 11px/17px Poppins;
            letter-spacing: 1.65px;
            color: var(--bluegrey);
            text-transform: uppercase;

            overflow: hidden; // truncate
			text-overflow: ellipsis;
			white-space: nowrap;
            display: block;

            width: 90%;
            

            strong {
                font-weight: bold;
            }

            padding-left: 13px;
            position: relative;

            &::before {
                content: '\e907';
                @include font();
                font-size: 10px;
                color: var(--bluegrey);
                top: 4px;
                left: 0;
                position: absolute;
                height: 10px;
                width: 6px;

                @include mobile {
                    top: 2px;
                    font-size: 9px;
                }
            }

            @include mobile {
                font-size: 9px;
                line-height: 13px;
                margin-bottom: 6px;
            }
        }

        &--title {
            font: normal normal bold 24px/36px Poppins;

            color: var(--darkblue);
            margin-bottom: 10px;

            @include mobile {
                font-size: 13px;
                line-height: 16px;
            }
        }

        &--text {
            font: normal normal normal 13px/22px "Libre Baskerville";

            color: var(--darkblue);

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            @include mobile {
                display: none;
            }
        }

        &--info {
            font: normal normal normal 11px/22px Poppins;

            color: var(--darkblue);

            padding-left: 17px;
            position: relative;

            &::before {
                content: '\e9eb';
                @include font();
                font-size: 12px;
                color: var(--rose);
                top: 3px;
                left: 0;
                position: absolute;
                height: 12px;
                .bloc__grandest & {
                    color: var(--darkblue);
                }

            }

            @include mobile {
                display: none;
            }
        }
    }
}