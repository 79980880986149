.search-map {
	width: 435px;
	height: 100%;

	&__container {
		position: relative;

		@include tablet_portrait {
			display: none;
		}

		#search-map {
			@include mobile {
				height: 55vh;

				.leaflet-control-zoom {
					display: none;
				}
			}

			.leaflet-popup-pane {

				.custom-popup-thuria {

					a {

						div {
							align-items: unset;

							.title-card {
								position: absolute;
								margin-top: 25px !important;
							}

							.classement {
								display: none;
							}

							.metas-card {
								margin-top: 5px;

								.wrapper-cat {
									display: none;
								}

								.location {
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 1;
									line-height: 18px;
									max-height: 36px;
									padding-left: 12px;
								}

							}

							.encart-tarif {
								position: absolute;
								bottom: 0;
								top: unset;
								left: unset;
								right: unset;

								.tarif {
									background-color: transparent;
								}
							}
						}
					}
				}
			}
		}

		.v-mobile-map {
			.splide-search-map {
				z-index: 1000;
				background-color: white;
				position: fixed;
				bottom: 0;
				max-width: 100vw;

				.splide-wrapper {
					height: 96px;

					.splide__slide {
						margin-top: 10px;

						a {
							div:first-of-type {
								display: flex;

								figure {
									width: 200px;
									height: 76px;
									margin-right: 11px;
									border-radius: 5px;
								}

								.caption {
									flex-direction: column;
									margin-top: 4px;

									.title-card {
										line-height: 12px;
									}

									.metas-card {
										margin-bottom: 2px;
									}

									.classement {
										display: none;
									}

									.location {
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-box-orient: vertical;
										-webkit-line-clamp: 1;
										line-height: 18px;
										max-height: 36px;
									}

									.encart {
										position: absolute;
										top: 5px;
										left: 13%;
										right: unset;
									}

									.tarif {
										top: 45px;
										left: 36%;
										background: transparent;
										font-size: 11px;

										.price {
											font-size: 13px;
										}
									}

									.title-card {
										font-size: 11px
									}
								}
							}
						}
					}
				}

				.return_to_search {
					height: 74px;
					border-top: 1px solid lightgrey;
					text-align: center;
					padding-top: 25px;

					&--button {
						background: none;
						border: none;
						text-decoration: underline;
						font-size: 11px;
						color: $c_greydark;
					}
				}
			}
		}

		&.map-mobile {
			display: block;
			z-index: 100;
		}

		.marker-cluster.active {
			background-color: $c_sub;
		}

		.price-icon {
			width: initial !important;
			height: initial !important;

			@include can-hover {
				.price-icon__inner {
					color: $c_white;
					background-color: $c_main;
				}
			}

			&.active {
				.price-icon__inner {
					color: $c_white;
					background-color: $c_main;
				}
			}

			&__inner {
				padding: 7px 12px 6px;
				@include ft('', 13px, 700, $c_txt, 1.5);
				white-space: nowrap;
				border-radius: 20px;
				background-color: $c_white;
				border: 1px solid #AAB3B9;
				@include addTransition();
			}
		}
	}

	&__on-move {
		position: absolute;
		z-index: 1000;
		top: 15px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: $c_main;
		color: $c_white;
		font-weight: 600;
		padding: 12px;
		border-radius: 4px;
		cursor: pointer;
		white-space: nowrap;

		span {
			position: relative;
			display: block;
			border-radius: 90px;
			background-color: $c_white;
			width: 32px;
			height: 16px;
			margin-right: 10px;

			&:before {
				content: '';
				position: absolute;
				top: 3px;
				left: 3px;
				width: 10px;
				height: 10px;
				z-index: 10;
				background-color: $c_light_txt;
				border-radius: 50%;
				transition: left $t_duration, background-color $t_duration;
			}
		}

		input {
			display: none;

			&:checked {
				~ span {
					&:before {
						left: calc(100% - 13px);
						background-color: $c_sub;
					}
				}
			}
		}
	}
}