.bloc-relation-experiences{
    .bloc-texte{
        > * {
            text-align: right;
            margin-left: auto;
            @include tablet_portrait{
                text-align: left;
            }
        }
    }
    .nav {
        button {
            top: calc(50% - 30px);
        }
    }
}