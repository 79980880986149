/* ------------------
--- CSS GLOBAL + GRILLE RESPONSIVE ---
------------------ */

html, body {
	&.no-scroll {
		overflow: hidden;
	}
}

html {
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	margin-top: 0 !important;
}

body {
	font-family: $f_txt;
	font-size: 13px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $c_txt;
	margin: 0;
	padding: 0;
	width: 100%;
	overflow-x: hidden;

	&.fixed {
		@extend %center;
		position: fixed;
	}

	&.overlay-menu-opened {
		.overlay-filtres,
		.overlay-widget,
		.overlay-map:not(.overlay-map-search-mobile),
		.overlay-search {
			z-index: 100000000;

		}
	}
}

section, div, header, footer, aside {
	box-sizing: border-box;
}

main {
	position: relative;
}


// @extend
// ==========================================================================
%center {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

%aligncenter {
	display: flex;
	display: -ms-flexbox;
	align-items: center;
	justify-content: center;
}

%appearance {
	-webkit-appearance: none;
	resize: none;
	box-shadow: none;
	border-radius: 0;
	border: none;
	outline: none;
	margin: 0;

	&::-ms-clear {
		display: none;
	}
}

%bloc-texte-white {
	> * {
		color: $c_white;
	}

	a:not(.btn):not(.basic-link):not(.link-icon) {
		color: $c_white;
		text-decoration: underline;

		&:hover, &:focus {
			body:not(.no-hover) & {
				color: $c_txt;
			}
		}
	}

	ul, ol {
		li {
			color: $c_white;
		}
	}

	blockquote p {
		color: $c_white;
	}
}

// Margins / Paddings
// ==========================================================================
.center {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	transform: translate3d(0, -50%, 0);
	width: 100%;
}

.no-gutter > [class*="col-"] {
	padding-left: 0 !important;
	padding-right: 0 !important;
	@include min-tablet_portrait {
		padding-left: 25px !important;
		padding-right: 25px !important;
	}
}

.wrapper {
	position: relative;
	z-index: 995;
}

.hide {
	display: none;
}

.maps,
#favoris-map {
	@extend %center;
	width: 100%;
	height: 100%;
}

.padding-inner {
	padding: 0 80px;
	@include tablet_landscape {
		padding: 0 40px;
	}
	@include mobile {
		padding: 0;
	}
}

// Margins / Paddings
// ==========================================================================
.no-pad {
	padding: 0 !important;
}

.m-20 {
	margin: 20px 0;
}

.m-30 {
	margin: 30px 0;
}

.m-40 {
	margin: 40px 0;
}

.m-50 {
	margin: 50px 0;
}

.p-20 {
	padding: 20px 0;
}

.p-30 {
	padding: 30px 0;
}

.p-40 {
	padding: 40px 0;
}

.p-50 {
	padding: 50px 0;
}

// Images
// ==========================================================================
.no-photo {
	height: 100%;
	background: $c_main;
}

figure {
	position: relative;

	&.--no-bg {
		background: initial;
	}

	&.loaded {
		&.o10, &.o20, &.o25, &.o30, &.o40, &.o50, &.o60, &.o70, &.o75, &.o80, &.o90 {
			background: black;
		}
	}

	&[data-lazyload="true"]:not(.loaded) {
		background: #f5f5f5;
		background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
		background-size: 200% 100%;
		animation: 1s shine linear infinite;
	}
}

.fit-cover {
	width: 100%;
	object-fit: cover;
	font-family: 'object-fit: cover';

	img {
		object-fit: cover;
		font-family: 'object-fit: cover';
		width: 100%;
		height: 100%;
	}
}

img {
	&.lazy {
		opacity: 0 !important;
		transition: opacity 0.3s ease;

		&.loaded {
			opacity: 1 !important;
		}
	}
}

// Alignements
// ==========================================================================
.aligncenter {
	text-align: center;
}

.alignleft {
	text-align: left;
}

.alignright {
	text-align: right;
}

// Opacity
// ==========================================================================
.o10 img {
	opacity: 0.1;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.1 !important;
		}
	}
}

.o20 img {
	opacity: 0.2;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.2 !important;
		}
	}
}

.o25 img {
	opacity: 0.25;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.25 !important;
		}
	}
}

.o30 img {
	opacity: 0.3;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.3 !important;
		}
	}
}

.o40 img {
	opacity: 0.4;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.4 !important;
		}
	}
}

.o50 img {
	opacity: 0.5;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.5 !important;
		}
	}
}

.o60 img {
	opacity: 0.6;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.6 !important;
		}
	}
}

.o70 img {
	opacity: 0.7;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.7 !important;
		}
	}
}

.o75 img {
	opacity: 0.75;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.75 !important;
		}
	}
}

.o80 img {
	opacity: 0.8;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.8 !important;
		}
	}
}

.o90 img {
	opacity: 0.9;

	&.lazy {
		opacity: 0 !important;

		&.loaded {
			opacity: 0.9 !important;
		}
	}
}

.o10, .o20, .o30, .o40, .o50, .o60, .o70, .o80, .o90 {
	img {
		&.lazy {
			visibility: hidden !important;
			transition: none;

			&.loaded, &.loading {
				visibility: visible !important;
			}
		}
	}
}

// Ratios
// ==========================================================================
.ratio-16-9 {
	@include aspect-ratio(16, 9);
}

.ratio-16-10 {
	@include aspect-ratio(16, 10);
}

.ratio-4-3 {
	@include aspect-ratio(4, 3);
}

.ratio-16-9 {
	> img {
		@extend %center;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

// Viewports
// ==========================================================================
.v-mobile {
	display: none !important;
	@include mobile {
		display: block !important;
	}
}

.v-tablet-p {
	display: none !important;
	@include tablet_portrait {
		display: block !important;
	}
}

.v-tablet-l {
	display: none !important;
	@include tablet_landscape {
		display: block !important;
	}
}

.hide-mobile {
	display: block;
	@include mobile {
		display: none !important;
	}
}

.hide-tablet-p {
	display: block;
	@include tablet_portrait {
		display: none !important;
	}
}

.hide-tablet-l {
	display: block;
	@include tablet_landscape {
		display: none !important;
	}
}