// STYLES FORMULAIRES - DEFAULT SETTINGS
// ==========================================================================
.bloc-form {

    /* Validation */
    .frm_message, .frm_error_style {
        background: $c_sub;
        border-radius: 5px;
        padding: 15px 25px;

        p {
            @include ft($f_hx, 20px, 700, $c_white, 26px);
            margin: 0;
            display: block;
            width: 100%;
            text-align: center;
            @include mobile {
                font-size: calculateRem(14px);
                line-height: calculateRem(20px);
            }
        }
    }

    .title{
        @include ft($f_hx, 32px, 700, $c_main, 36px);
        display: block;
        width: 100%;
        margin: 0;
        @include mobile {
            font-size: calculateRem(26px);
            line-height: calculateRem(28px);
        }
    }

    // FORMULAIRE FORMIDABLE
    // ==========================================================================
    form,
    .fake-form {
        position: relative;
        height: 100%;

        .frm_error {
            position: relative;
            display: block;
            margin-top: 5px;
            font-size: 12px;
            color: $c_error;
        }

        .frm_error_style, .frm_none_container label, .frm_form_title {
            display: none !important;
        }

        legend, .title {
            @include ft($f_hx, 32px, 700, $c_main, 36px);
            display: block;
            width: 100%;
            margin: 0;
            @include mobile {
                font-size: calculateRem(26px);
                line-height: calculateRem(28px);
            }
        }
        .frm_screen_reader{
            display: none;
        }

        .frm_description {
            @include ft($f_txt, 12px, 400, rgba($c_txt, 0.7), 19px);
            margin-top: 10px;
            font-style: italic;

            a {
                text-decoration: underline;
            }
        }

        .frm_hidden_container {
            > .frm_primary_label {
                display: none;
            }
        }

        .title-section {
            @include ft($f_txt, 18px, 600, $c_gradient, 22px);
            margin: 15px 0 10px;
            display: block;
            @include mobile{
                font-size: calculateRem(14px);
                line-height: calculateRem(18px);
                margin: 5px 0 12px;
            }
        }

        .frm_required {
            color: $c_third;
        }

        /* MISE EN PAGE */
        .form-field {
            position: relative;
            padding-bottom: 10px;

            &.frm_section_heading {
                margin-top: 30px;
                margin-bottom: 10px;
            }

            /* HAS CONTENT INSIDE INPUT */
            &.has-content {
                label, .frm_primary_label {
                    transform: translate(0, -12px);
                    transform: translate3d(0, -12px, 0);
                    @include mobile{
                        transform: translate(0, -10px);
                        transform: translate3d(0, -10px, 0);
                    }
                }

                input {
                    &[type="password"] {
                        padding: 40px 50px 18px 20px;
                        @include mobile {
                            padding: 30px 50px 8px 20px;
                        }

                        & + .see-pwd {
                            display: block;
                        }
                    }

                    &[type="text"] {
                        & + .see-pwd {
                            display: block;
                        }
                    }
                }
            }

            &.radio-grp{
                margin-top: 10px;
                p:not(.frm_small_text){
                    margin-bottom: 20px;
                    font-size: calculateRem(18px);
                    line-height: calculateRem(22px);
                    font-weight: 600;
                    span.frm_required{
                        padding-left: 5px;
                    }
                }
            }

            input[type="radio"]{
                position: absolute;
                margin-top: -6px;
                opacity: 0;
            }
            input[type="radio"] + label{
                position: relative;
                padding-left: 35px;
                padding-top: 5px;
                padding-bottom: 5px;
                margin: 0 0 5px 0;
                top: auto;
                bottom: auto;
                left: auto;
                right: auto;
                transform: none;
                text-transform: none;
                color: $c_surtitre;
                font-size: calculateRem(14px);
                line-height: calculateRem(18px);
                font-weight: 500;
                &::before{
                    content: '';
                    display: block;
                    width: 23px;
                    height: 23px;
                    border-radius: 50%;
                    border: 1px solid $c_border_input;
                    position: absolute;
                    left: 0;
                    top: 0;
                    @include addTransition();
                }
                &::after{
                    content: '';
                    display: block;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    background: $c_main;
                    opacity: 0;
                    transform: scale(0);
                    @include addTransition();
                }
                &:hover, &:focus{
                    body:not(.no-hover) &{
                        cursor: pointer;
                        color: $c_dark;
                        &::before{
                            border-color: $c_third;
                        }
                    }
                }
            }
            input[type="radio"]:checked + label{
                &::after{
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

    }

    .frm_message {
        text-align: center;

        p {
            font-weight: 700;
        }
    }

    ul {
        li {
            &:before {
                content: none;
            }
        }
    }

    .input-group {
        position: relative;
    }

    /* Gestion des labels */
    label, .frm_primary_label {
        @include ft($f_txt, 12px, 400, $c_surtitre, 18px);
        text-transform: uppercase;
        letter-spacing: 1.5px;
        box-sizing: border-box;
        display: block;
        clear: both;
        text-indent: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: absolute;
        top: 50%;
        margin-top: -12px;
        left: 20px;
        @include addTransition();

        a {
            color: $c_sub;
            text-decoration: underline;

            &:hover, &:focus {
                body:not(.no-hover) & {
                    color: darken($c_sub, 10%);
                }
            }
        }
    }

    .form-field--textarea {
        label, .frm_primary_label {
            top: 20%;
        }
    }

    /* Input, Textarea, Select */
    input:not([type="radio"]), select, textarea {
        @include ft($f_txt, 14px, 500, $c_surtitre, 20px);
        @extend %appearance;
        box-sizing: border-box;
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 40px 20px 18px;
        background: $c_white;
        border: 1px solid $c_border_input;
        border-radius: 4px;
        text-overflow: ellipsis;
        height: 80px;
        @include addTransition();

        &::placeholder {
            color: $c_dark;
        }

        @include mobile {
            height: 60px;
            padding: 30px 20px 8px;
        }

        &:hover, &:focus {
            body:not(.no-hover) & {
                border-color: $c_third;
            }
        }
    }

    input {
        &[type="date"]::-webkit-inner-spin-button {
            display: none;
        }

        &[type="date"]::-webkit-calendar-picker-indicator {
            display: none;
        }
    }

    /*Change text in autofill textbox*/
    input:-webkit-autofill {
        -webkit-text-fill-color: $c_txt;
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 80px $c_white inset !important;
        @include mobile {
            -webkit-box-shadow: 0 0 0 60px $c_white inset !important;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance: textfield; /* Firefox */
    }

    textarea {
        height: 200px;
    }

    .switch-field{
        position: relative;
        .frm_primary_label{
            display: none;
        }
        .frm_description{
            display: block;
            padding-left: 40px;
            max-width: 800px;
            @include mobile{
                padding-left: 50px;
            }
            a{
                text-decoration: underline;
                &:hover{
                    body:not(.no-hover) &{
                        color: darken($c_main,5%);
                    }
                }
            }
        }
        .frm_switch{
            width: 40px;
            height: 20px;
            position: relative;
            border: none;
        }
        .frm_switch_block{
            top: 0 !important;
            //left: 10px !important;
            height: 40px;
            input{
                display: none;
            }
        }
        .frm_slider{
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 20px;
            border: 1px solid $c_grey;
            background: $c_white;
            &::before{
                box-shadow: none;
                background: $c_sub;
                top: 3px;
                left: 3px;
                height: 14px;
                width: 14px;
            }
        }
        .frm_description{
            position: absolute;
            top: 0;
            margin-top: 3px;
            padding-left: 50px;
        }
        input:checked + .frm_switch .frm_slider{
            background: $c_sub;
            border-color: $c_sub;
            &::before{
                transform: translateX(20px) !important;
                background: $c_white !important;
            }
        }
    }

    .see-pwd {
        @extend %appearance;
        padding: 0;
        margin: 0;
        background: none;
        position: absolute;
        right: 20px;
        bottom: 26px;
        font-size: 20px;
        color: $c_main;
        z-index: 100;
        display: none;
        @include addTransition();
        @include mobile {
            bottom: 17px;
        }

        &:hover, &:focus {
            body:not(.no-hover) & {
                cursor: pointer;
                color: $c_third;
            }
        }
    }

    /* Style for Submit Button */
    .frm_submit {
        margin-top: 20px;
        @include clearfix();

        button {
            @include ft($f_txt, 15px, 500, $c_white, 20px);
            @extend %appearance;
            transform: translate3d(0, 0, 0);
            position: relative;
            z-index: 10;
            background: $c_sub;
            border-radius: 4px;
            display: inline-block;
            padding: 21px 20px 19px;
            width: auto;
            min-width: 300px;
            @include mobile {
                width: 100%;
                min-width: 100px;
                max-width: 100%;
                padding: 15px 20px 13px;
            }
            @include addTransition();

            &:hover {
                body:not(.no-hover) & {
                    cursor: pointer;
                    color: $c_white;
                    background: $c_third;
                }
            }

            &:focus {
                outline: none;
            }

            &.frm_prev_page {
                float: left;
            }

            &.frm_final_submit {
                float: right;
            }

            &[disabled]{
                opacity: 0.5;
                pointer-events: none;
            }

        }

        .forgot-your-password {
            @include ft($f_txt, 12px, 400, $c_light_txt, 20px);
            display: block;
            text-decoration: underline;
            text-align: right;
            margin-top: 15px;
            @include addTransition();

            &:hover, &:focus {
                body:not(.no-hover) & {
                    color: $c_greydark;
                }
            }
        }
    }

    .vertical_radio{
        label:not(.star-rating):not(.frm_hidden):not(.star-rating):not(.frm_switch){
            padding-left: 42px !important;
            padding-top: 1px !important;
        }
    }

    [class*="_radio"],[class*="_col"]{
        margin-bottom: 30px;
        .frm_primary_label{
            position: relative;
            top: auto;
            margin-top: 20px;
            margin-bottom: 2px;
        }
    }

    /* Style for Radio & Checkbox */
    .frm_opt_container {
        font-size: 0;
        margin-bottom: -10px;
        @include mobile {
            display: block;
        }

        & + .frm_error {
            margin-top: 15px;
        }

        input {
            display: none !important;
        }

        label:not(.star-rating):not(.frm_hidden):not(.star-rating):not(.frm_switch):not(.type-destination) {
            position: relative;
            top: auto;
            padding-left: 22px;
            margin: 0;
            @include ft($f_txt, 14px, 400, $c_txt, 20px);
            white-space: normal;
            overflow: visible;
            @include addTransition();
            text-transform: none;

            &:hover {
                cursor: pointer;

                body:not(.no-hover) & {
                    color: $c_sub;

                    span {
                        &:before {
                            border-color: $c_sub;
                        }
                    }
                }
            }
        }

        .frm_checkbox, .frm_radio {
            @include mobile {
                display: block;
            }
        }

        /* Style for Radio */
        .frm_radio {
            input[type="radio"]:checked + span:after {
                opacity: 1;
                transform: scale(1);
            }

            span {
                &:before {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    border-radius: 50px;
                    border: 1px solid rgba($c_dark, 0.2);
                    box-shadow: 0 0 15px rgba($c_dark, 0.1);
                    cursor: pointer;
                    @include addTransition();
                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    top: 7px;
                    left: 4px;
                    background: $c_sub;
                    border-radius: 50%;
                    opacity: 0;
                    transform: scale(0);
                    @include addTransition();
                }
            }
        }

        /* Style for Checkbox */
        .frm_checkbox {
            input {
                display: none;

                &:checked {
                    ~ span:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }

            label {
                > span {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 3px;
                        left: 0;
                        width: 12px;
                        height: 12px;
                        border: 1px solid rgba($c_dark, 0.2);
                        box-shadow: 0 0 15px rgba($c_dark, 0.1);
                        cursor: pointer;
                        @include addTransition();
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: 7px;
                        left: 4px;
                        width: 6px;
                        height: 6px;
                        background: $c_sub;
                        opacity: 0;
                        transform: scale(0);
                        @include addTransition();
                    }
                }
            }
        }

        /* Style for Scale */
        .frm_scale {
            display: inline-block;
            width: 40px;
            @include mobile {
                margin-bottom: 10px;
            }

            label {
                padding: 0;
                text-align: center;

                &:hover {
                    body:not(.no-hover) & {
                        cursor: pointer;

                        input {
                            border-color: $c_sub;
                        }
                    }
                }
            }

            input {
                position: relative;
                display: block !important;
                @extend %appearance;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: $c_white;
                border: 1px solid rgba($c_dark, 0.5);
                box-shadow: 0 0 15px rgba($c_dark, 0.2);
                padding: 0;
                margin: 0 auto 4px auto;
                box-sizing: content-box;
                cursor: pointer;
                @include addTransition();

                &:after {
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    top: 3px;
                    left: 3px;
                    background: $c_main;
                    border-radius: 50%;
                    opacity: 0;
                    transform: scale(0);
                    @include addTransition();
                }

                &:checked {
                    &:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        border-color: $c_dark;
                    }
                }
            }
        }
    }

    /* Switch */
    .frm_switch_block {
        position: relative;
        top: 0;
        left: 0;
        text-align: left;
        margin: 10px 0;

        &:hover, &:focus {
            body:not(.no-hover) & {
                cursor: pointer;

                .frm_switch {
                    border-color: $c_sub;
                }
            }
        }

        input {
            opacity: 0;
            position: absolute;
            width: 60px;
            height: 30px;
            padding: 0;

            &:checked {
                & ~ .frm_switch {
                    .frm_slider {
                        &:before {
                            transform: translateX(30px);
                            transform: translate3d(30px, 0, 0);
                            background: $c_sub;
                        }
                    }
                }
            }
        }

        .frm_on_label {
            @include ft('', 12px, 400, $c_txt, 20px);
            padding: 5px 0 5px 80px;
            display: block;
            text-transform: none;
            letter-spacing: 0;

            a {
                color: $c_main;
                text-decoration: underline;

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        color: $c_third;
                    }
                }
            }
        }
    }

    .frm_verify{
        label{
            display: none !important;
        }
    }

    .frm_switch {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: 60px;
        height: 30px;
        border: 1px solid $c_border_input;
        border-radius: 15px;
        vertical-align: middle;
        @include addTransition();

        .frm_slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: none;

            &:before {
                content: '';
                position: absolute;
                top: 4px;
                left: 4px;
                width: 20px;
                height: 20px;
                background: $c_light_txt;
                border-radius: 50%;
                transform: none;
                @include addTransition();
            }
        }
    }

    /* Style for Hours */
    .frm_time_wrap {
        position: relative;
        @include clearfix();

        > * {
            float: left;
            box-sizing: border-box;
        }

        > span {
            position: relative;
            width: 70% !important;
            @include clearfix();
            padding-right: 2.5%;

            > * {
                float: left;
                width: calc(50% - 16px) !important;
            }

            .frm_time_sep {
                line-height: 60px;
                padding: 0 2px;
                width: 26px !important;
                text-align: center;
            }
        }

        > * {
            width: 30% !important;
            box-sizing: border-box;
        }
    }

    /* Style for Selectric */
    .selectric-wrapper {
        .selectric {
            border-color: $c_border_input;
            background: none;
            width: 100%;

            &:hover {
                body:not(.no-hover) & {
                    border-color: $c_third;

                    .label {
                        color: $c_surtitre;

                        &:after {
                            color: $c_surtitre;
                        }
                    }
                }
            }

            .label {
                @include ft($f_txt, 14px, 500, $c_surtitre, 20px);
                padding: 40px 20px 18px;
                @include mobile {
                    padding: 30px 20px 8px;
                }

                &:after {
                    right: 20px;
                    bottom: 23px;
                    font-size: 12px;
                    @include mobile{
                        bottom: 15px;
                    }
                }
            }
        }

        .selectric-items {
            .selectric-scroll {
                ul {
                    li {
                        @include ft($f_txt, 14px, 500, $c_surtitre, 20px);
                        padding: 6px 20px;

                        &:hover {
                            body:not(.no-hover) & {
                                color: $c_third;
                            }
                        }
                    }
                }
            }
        }
    }

    /* Style for Address */
    .frm_combo_inputs_container {
        .frm_form_field {
            .frm_hidden, .frm_screen_reader {
                //display: block !important;
                margin-bottom: 5px;
            }

            &:last-child {
                padding-bottom: 0;
            }

            &.form-field:not(.horizontal_radio):not(.vertical_radio):not(.label-bloc) {
                label {
                    padding: 0 !important;
                    margin: 0 0 5px 0;
                }
            }
        }
    }

    /* Style for Upload file */
    .input-type-file {
        &.has-content {
            .focus-jfilestyle {
                label {
                    margin-top: -16px;
                }
            }
        }

        > label {
            width: calc(100% - 80px);
            padding-right: 130px;
            z-index: 1000;
            pointer-events: none;
        }

        .fallback {
            width: 100%;
        }

        .jfilestyle {
            position: relative;

            > input {
                width: 100% !important;
                padding-right: 120px;

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        box-shadow: none;
                    }
                }
            }
        }

        .focus-jfilestyle {
            label {
                @include ft($f_txt, 12px, 700, $c_dark, 22px);
                position: absolute;
                left: auto;
                top: 50%;
                right: 10px;
                margin-top: -16px;
                padding: 4px 20px;
                border: 1px solid #EBEBEB;
                border-radius: 16px;
                @include addTransition();

                &:hover {
                    body:not(.no-hover) & {
                        cursor: pointer;
                        color: $c_white;
                        background: $c_dark;
                        border-color: $c_dark;
                    }
                }
            }
        }
    }

    .half-input{
        @include clearfix();
        > * {
            position: relative;
            float: left;
            width: calc(50% - 5px);
            clear: none;
            @include mobile{
                width: 100%;
                float: none;
            }
            &:nth-child(even){
                margin-left: 10px;
                @include mobile{
                    margin-left: 0;
                }
            }
        }
    }

    .city_autocomplete {
        margin-bottom: 30px;
        margin-top: -10px;
        > p {
            font-size: 14px;
            line-height: 43px;
            height: 40px;
            margin: 0;
        }
        .autocomplete-items {
            position: relative;
            z-index: 111;
            /*position the autocomplete items to be the same width as the container:*/
            top: calc(100% - 10px);
            left: 0;
            right: 0;

            div {
                border: 1px solid $c_border_input;
                padding: 14px 10px 12px 20px;
                cursor: pointer;
                background-color: #fff;
                margin-bottom: 2px;
                border-radius: 2px;
                font-size: 13px;
                line-height: 13px;

                &:hover {
                    /*when hovering an item:*/
                    background-color: rgba(112,112,112,0.2);
                }
            }
        }

        .autocomplete-active {
            /*when navigating through the items using the arrow keys:*/
            background-color: DodgerBlue !important;
            color: #ffffff;
        }
    }

    .user_form_error {
        display: inline-block;
        color: $c_error;
        margin-bottom: 25px;
    }

    .mentions {
        font-size: 12px;
        font-size: .75rem;
        font-weight: 400;
        color: #172540;
        line-height: 20px;
        line-height: 1.25rem;
        padding: 5px 0 5px 0px;
        display: block;
        text-transform: none;
        letter-spacing: 0;
    }
}