.myex-checkbox-picture {
	position: relative;
	margin-bottom: 0;

	label {
		@extend .icon-ico-close;
		cursor: pointer;
		background: transparent;
		font-size: calculateRem(18px);
		font-weight: 600;
		padding: calculateRem(15px);
		line-height: 1;
		letter-spacing: 0.2px;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		color: #fff;
		text-align: center;
		padding: calculateRem(10px);
		top: 0;

		@include min-tablet_small {
			font-size: calculateRem(16px);
		}

		//category icon
		i {
			font-size: calculateRem(20px);
		}

		// close icon
		&:before {
			@include font();
			color: #fff;
			font-size: calculateRem(8px);
			position: absolute;
			right: 0;
			opacity: 0;
			top: 0;
			transform: translate(50%, -50%);
			background-color: var(--color-primary);
			border-style: solid;
			border-width: calculateRem(2px);
			padding: calculateRem(5px);
			border-radius: 50%;
			transition: all ease 500ms;
			border-color: var(--current-bg);
		}
	}

	.wrapper-img {
		border-radius: calculateRem(10px);
		overflow: hidden;
		width: 100%;
		height: 100%;
		position: absolute;
		transition: all ease 500ms;
		border: 0 solid transparent;
		@include aspect-ratio(135px, 135px);
		background: var(--bg-card-img);

		&:after {
			content: "";
			background: linear-gradient(160deg, #1e2529 0%, rgba(30, 37, 41, 0) 100%);
			opacity: 0.5;
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			top: 0;
			z-index: 0;
		}
	}

	input {
		display: none;

		&:checked {
			~ label {
				&:before {
					opacity: 1;
					background: var(--color-primary);
				}
			}

			~ .wrapper-img {
				border: calculateRem(6px) solid var(--color-primary);
			}
		}
	}
}
