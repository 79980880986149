.myex-card-explorer {
	display: flex;
	gap: calculateRem(11px);
	flex-direction: column;
	color: currentColor;
	@include card-img-hover();

	&:hover,
	&:focus {
		color: currentColor !important; //overwrite important
	}

	&__avatar {
		border-radius: 50%;
		background: var(--bg-card-img);
		@include aspect-ratio(50, 50);

		.myex-button--favorite {
			position: absolute;
			left: 50%;
			bottom: calculateRem(15px);
			transform: translateX(-50%);
			z-index: 2;
		}
	}

	&__content {
		display: flex;
		gap: calculateRem(5px);
		flex-direction: column;
		align-items: center;
		position: relative;
		text-align: center;
	}

	&__wrapper-title {
		display: flex;
		gap: calculateRem(6px);
		align-items: center;
		width: 100%;
		padding-left: calculateRem(41px);
	}

	&__title {
		font-size: calculateRem(14px);
		font-weight: 600;
		line-height: 1.125;
		color: currentColor;
		margin: 0;
		@include ellipsis(2);
		width: 100%;

		@include min-mobile {
			font-size: calculateRem(16px);
		}
	}

	&__desc {
		color: currentColor;
		margin: 0;
		line-height: 1.38;
		font-size: calculateRem(12px);
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		@include min-mobile {
			font-size: calculateRem(13px);
		}
	}

	&__meta {
		margin: 0;
		display: flex;
		gap: calculateRem(15px);
		color: var(--color-text-lighter);
		font-size: calculateRem(11px);

		&__item {
			@include ellipsis(1);
		}
	}
}

// horizontal card inside list, on mobile
@include mobile {
	.myex-section__list {
		.myex-card-explorer {
			flex-direction: row;
			align-items: center;
			gap: calculateRem(15px);

			&__avatar {
				width: calculateRem(120px);
				flex-shrink: 0;
			}

			&__wrapper-title {
				padding-left: 0;
			}

			&__content {
				width: 100%;
				align-items: self-start;
				text-align: left;
			}
		}
	}
}
