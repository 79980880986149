.blocslideheader__lemag {
    position: relative;
    display: flex;
    
    flex-direction: row;
    justify-content: space-between;
    @include mobile {
        flex-direction: column-reverse;
    }
    &_content {
        padding-top: 147px;
        max-width: 460px;
        width: 35%;
        @include mobile {
            padding-top: 15px;
            max-width: unset;
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }
        &--link {

            text-align: left;
            font: normal normal normal 11px/17px Poppins;

            strong {
                font-weight: 600;
            }
            @include mobile {
                font-size: 9px;
                line-height: 13px;
            }
            letter-spacing: 1.65px;
            color: var(--darkblue);
            text-transform: uppercase;
            margin-left: 87px;
            padding-left: 13px;

            &::before {
                content: '\e907';
                @include font();
                font-size: 10px;
                color: var(--darkblue);
                top: 3px;
                left: 0;
                position: absolute;
                height: 10px;
                width: 6px;
            }

            @include mobile {
                margin: 0;
            }
        }

        h2 {
            font: normal normal 600 28px/38px Poppins;

            color: var(--darkblue);
            @include mobile {
                font-size: 16px;
                line-height: 24px;
            }
        }

        p {
            font: normal normal normal 13px/22px Libre Baskerville;
            color: var(--darkblue);

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;

            @include mobile {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 5px;
            }
        }

        p.blocslideheader__lemag_content--info {
            margin-top: 26px;
            font: normal normal normal 11px/22px Poppins;

            color: var(--darkblue);
            padding-left: 17px;
            position: relative;

            &::before {
                content: '\e9eb';
                @include font();
                font-size: 12px;
                color: var(--rose);
                top: 3px;
                left: 0;
                position: absolute;
                height: 12px;

            }
        }

    }

    &_image {
        @include mobile {
            
            width: 100%;
            
            height: 250px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        border-radius: 10px;
        overflow: hidden;
        width: 750px;
        height: 440px;
    }
}