// Share Button Wrapper
.social-share {
    position: absolute;
    z-index: 8000;
    right: calc((100% - #{$max-width}) / 2);
    margin-left: auto;

    @include addTransition();
    @include wide{
        right: calc((100% - #{$max-width-large}) / 2);
    }
    @include desktop{
        right: 0;
        background-color: white;

    }
    @include tablet_landscape{
        right: 40px;
    }
    @include mobile{
        right: -10px;
    }
}

// Toggle Button
.toggle {
    position: relative;
    display: block;
    font-size: 20px;
    color: $c_gradient;
    padding: 30px;
    z-index: 500;

    &:after {
        content: '';
        position: absolute;
        top: 12px;
        bottom: 12px;
        right: 78px;
        left: -45px;
        background: -moz-linear-gradient(right,  white 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(right,  white 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to left,  white 0%,rgba(0,0,0,0) 100%);
    }

    @include mobile {
        padding: 16px;
        &:after {
            top: 5px;
            bottom: 5px;
            right: 50px;
            left: -50px;
        }
    }

    @include min-mobile {
        padding-top: 18px;
        background-color: white;
        padding-bottom: 18px;
        top: 12px;
        padding-right: 50px;
        right: -25px;
    }

    span{
        display: block;
    }

    &:hover, &:focus {
        body:not(.no-hover) & {
            cursor: pointer;
            color: $c_main;
        }
    }

    // Hidden input to toggle state
    &-input {
        display: none;
    }
}

// =============================================
// Network List
// =============================================

.network-list {
    // List Item
    position: absolute;
    z-index: 1000;
    bottom: 0;
    right: 30px;
    top: 20px;
    a {
        width: 40px;
        margin: 0;
    }
    li {
        position: absolute;
        top: 0;
        right: 10px;
        display: block;
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        opacity: 0;
        transition: all 0.25s cubic-bezier(0.43, 1.17, 0.69, 1.24);
    }

    // Link
    a {
        position: relative;
        font-size: 0;
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        background: $c_gradient;
        overflow: hidden;
        text-indent: 120%;
        text-decoration: none;
        @include addTransition();
        &:hover, &:focus {
            body:not(.no-hover) & {
                background: $c_main;
            }
        }
    }

    // Network Icon
    a:before {
        @include font();
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        text-indent: 0;
    }

    a:after {
        content: '';
        width: 40px;
        height: 40px;
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: -1;
        border-radius: 50%;
    }

    // Twitter
    .twitter {
        a:before {
            content: "\e935";
            color: $c_white;
        }
    }

    // Facebook
    .facebook {
        a:before {
            content: "\e915";
            color: $c_white;
        }
    }

    // Linkedin
    .linkedin {
        a:before {
            content: "\e93b";
            color: $c_white;
        }
    }

    // Mail
    .mail {
        a:before {
            content: "\e93c";
            color: $c_white;
        }
    }
}

// =============================================
// Open Network List

input:checked ~ .network-list {
    li {
        opacity: 1;
        top: 0;
    }
    li:nth-child(1) {
        right: 40px;
    }
    li:nth-child(2) {
        right: 90px;
    }
    li:nth-child(3) {
        right: 140px;
    }
    li:nth-child(4) {
        right: 190px;
    }
    li:nth-child(5) {
        right: 240px;
    }
}


.social-share.--anim-top {
    position: relative;
    right: initial;

    .toggle {
        padding: 30px 0 28px 10px;
    }

    .network-list {
        top: 0;
        left: 0;

        li {
            top: 0;
            left: -5px;
        }
    }

    input:checked ~ .network-list {
        li {
            opacity: 1;
        }
        li:nth-child(1) {
            top: -60px;
        }
        li:nth-child(2) {
            top: -110px;
        }
        li:nth-child(3) {
            top: -160px;
        }
        li:nth-child(4) {
            top: -210px;
        }
        li:nth-child(5) {
            top: -260px;
        }
    }
}


