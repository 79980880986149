.myex-nav-link {

    &-pastille {
        color: white;
        max-height: 20px;
        padding: 0 14px 5px 14px;
        background: linear-gradient(to right, #E13060, #DEA99E);
        text-align: center;
        font-size: 11px;
        font-weight: bold;
        border-radius: 12px;
    }

    &.myex-is-new-style {
        display: flex !important;

        a {
            color: #E04B6E !important;
        }

        .myex-nav-link-pastille {
            position: relative;
            right: 15px;
            top: 8px;
        }
    }

    &.myex-is-new-style-mobile {

        .myex-nav-link-pastille {
            vertical-align: middle;
            margin-left: 17px;
            padding-top: 3px;
            padding-bottom: 2px;
        }
    }
}

// TODO: à revoir
.page-template-page-my-explore {
    .myex-nav-link {
        &-pastille {
            max-height: 24px;
        }
    }
}