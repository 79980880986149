// Affichage des blocs en fonction des containers, des colonnes ...
// ==========================================================================
.bloc {
	display: block;
	position: relative;
	margin: 0 auto;
	padding: $content-spacing/2 0;
    @include tablet_landscape{
        padding: $content-spacing-tab/2 0;
    }
	@include mobile{
		padding: 20px;
	}

	&:not(.bloc-large) {
		max-width: $max-width;
		&:not(.bloc-small) {
			@media screen and (max-width: $max-width+$border-gutter+$border-gutter+0px) {
				margin: 0 $border-gutter;
			}
		}
		&:not(.bloc-small) {
			@include mobile{
				margin: 0 20px;
			}
		}

		@include mobile{
			margin: 0 20px;
		}
	}

	&.bloc-small {
		max-width: $grid-small;

		&:not(.col-wrapper) {
			@media screen and (max-width: $grid-small+$border-gutter+$border-gutter) {
				margin: 0 $border-gutter !important;
			}
            @media screen and (max-width: $grid-small+$border-gutter) {
                margin: 0 $border-gutter/2 !important;
            }
            @include mobile{
                margin: 0 20px !important;
            }
		}

		@include mobile{
			margin: 0 $border-gutter/2;
		}
	}
	&.bloc-large {
		width: 100%;
	}
}