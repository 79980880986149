

.page-itinerance.--itineraire {

    // Section - Description
    // ==========================================================================

    .wrapper-content-map {
        --header-height: 50px;
        @include tablet_landscape{
            --header-height: 0;
        }
        .cover-itinerance {
            padding: 0 0 0 0;
            @include tablet_portrait {
                min-height: 0;
            }
            @include mobile {
                align-items: center;
            }
            .bloc-slider.th-slider-wrapper {
                padding: 0;
                z-index: 1;
                .wrapper {
                    padding: 0;
                }
            }
            .cover-content {
                z-index: 5;
                position: absolute;
                margin: 0 0 30px 80px;
                @include mobile {
                    margin: 0;
                    left: 0;
                    right: 0;
                    padding: 0 20px;
                }
            }
            .cta-add-favoris {
                @include mobile {
                    padding: 0;
                    top: 76px;
                }
            }
        }

        .maps {
            @include mobile {
                height: calc(100vh - 235px);
            }
            
            .leaflet-control-zoom {
                @include mobile {
                    display: none;
                }
            }
        }

        .splide-itineraire-map {
            z-index: 10;
            background-color: white;
            position: absolute;
            bottom: 0;
            max-width: 100vw;

            .splide-wrapper {
                height: auto;
                min-height: 96px;
                padding-top: 9px;

                .splide__slide {
                    display: flex;
                    align-items: center;

                    figure {
                        width: 112px;
                        height: 76px;
                        border-radius: 5px;
                        margin-right: 11px;

                        img {
                            border-radius: 5px;
                        }

                        figcaption {
                            display: none;
                        }
                    }
                    
                    .credits {
                        display: none;
                    }

                    .caption {
                        display: flex;
                        flex-direction: column;

                        &__trajet {
                            font-size: 11px;
                            letter-spacing: 0.44px;
                            text-transform: uppercase;
                            color: #7a97aa;
                            line-height: 16px;

                            &-depart:before {
                                content: "\e949";
                                font-family: 'icomoon';
                                font-size: 12px;
                                position: relative;
                                top: 2px;
                                margin-right: 3px;
                            }

                        }
                        &__title {
                            font-size: 11px;
                            font-weight: 600;
                            line-height: 14px;

                        }
                        &__metas {
                            display: flex;
                            justify-content: space-between;

                            &-etape {
                                font-size: 10px;
                            }

                            @include mobile {
                                justify-content: flex-start;

                                span {
                                    padding: 0 10px;
                                    color: #1E25294D;
                                }
                            }

                        }
                    }
                }
            }

            .return_to_itineraire {
                height: 74px;
                border-top: 1px solid lightgrey;
                text-align: center;
                padding-top: 25px;

                &--button {
                    background: none;
                    border: none;
                    text-decoration: underline;
                    font-size: 11px;
                    color: $c_txt;
                }
            }
        }
    }


    // Section - Description
    // ==========================================================================

    .group-description {
        margin-top: 50px;
        @include mobile {
            margin-top: 0;
        }
    }


    .list-infos-description {
        display: grid;
        grid-template-columns: repeat(3, minmax(10px, 1fr));
        column-gap: 20px;
        row-gap: 40px;

        @include desktop {
            grid-template-columns: repeat(2, minmax(10px, 1fr));
        }

        @include tablet_landscape {
            grid-template-columns: repeat(3, minmax(10px, 1fr));
        }

        @include mobile {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
        }
    }

    .item-info-description {
        display: flex;
        align-items: center;

        @include mobile {
            width: 50%;
            flex-shrink: 0;
            min-width: 170px;
        }

        figure {
            flex-shrink: 0;

            & + * {
                margin-left: 20px;

                @include mobile {
                    margin-left: 10px;
                }
            }

            img {
                width: 50px;
                height: auto;
            }
        }

        .item-info-description__text {
            p {
                line-height: 1.2;
                font-size: 13px;

                strong {
                    font-size: 15px;
                }
            }
        }
    }

    .description-text-wrapper {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        @include mobile {
            margin-top: 22px;

            .to-expand:not(.expand) {
                height: 345px !important;
            }
        }

        .cta-expand {
            margin-left: 0;
            margin-right: auto;
            align-items: flex-start;
        }
    }


    // Section - Listing des étapes
    // ==========================================================================

    .group-listing-etapes {
        margin-top: 40px;

        h3 {

            border-bottom: 1px solid rgba($c_border, 0.5);
            padding-bottom: 5px;

            @include mobile {
                font-size: 25px;
                line-height: 30px;
            }
        }
    }

    .listing-etapes {
        margin-top: 30px;

        @include mobile {
            margin-top: 22px;
        }
    }


    .item-etape {
        position: relative;
        padding-bottom: 40px;

        @include mobile {
            padding-bottom: 15px;
        }

        & + .item-etape {
            margin-top: 20px;

            @include mobile {
                margin-top: 15px;
            }
        }

        // gradient
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: calc(var(--border-gutter) * -1);
            right: calc(var(--border-gutter) * -1);
            height: 160px;
            background-image: linear-gradient(to top, rgba($c_gradient, 1), rgba($c_gradient, 0));
            opacity: 0.05;
        }
    }


    .item-etape__gallery {
        display: grid;
        grid-template-areas: "thumb1 thumb2"
                             "thumb1 thumb3";
        grid-template-columns: 2fr 1fr;

        @include mobile {
            grid-template-areas: "thumb1";
            grid-template-columns: 1fr;
        }

        // marge négative pour avoir un gap de 10px entre les thumbs
        margin-left: -5px;
        margin-right: -5px;

        > * {
            padding: 5px;

            figure {
                @include aspect-ratio(1, 1);
                border-radius: 10px;
                border-bottom-left-radius: 10px;

                &::before {
                    position: relative;
                    z-index: 99;
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,202529+100&0+0,1+100 */
                    background: -moz-linear-gradient(top, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00202529', endColorstr='#202529', GradientType=0); /* IE6-9 */
                    opacity: 0;
                    z-index: 10;
                    @include addTransition();
                }

                &::after {
                    content: "\e999";
                    @include font();
                    position: absolute;
                    z-index: 100;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 40px;
                    color: #fff;
                    opacity: 0;
                    @include addTransition();
                }

                @include mobile {
                    &::before {
                        padding-top: 65%;
                    }
                }
            }

            &:nth-child(n+2) {
                &:hover {
                    figure {
                        &::before {
                            opacity: 0.65;
                        }
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
            
        }

        > :nth-child(1) {
            grid-area: thumb1;
        }
        > :nth-child(2) {
            grid-area: thumb2;

            @include mobile {
                display: none;
            }
        }
        > :nth-child(3) {
            grid-area: thumb3;

            @include mobile {
                display: none;
            }
        }
    }


    .thumb-etape__caption {
        position: absolute;
        bottom: 5px;
        left: 5px;
        right: 5px;
        padding: 80px 20px 20px 20px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        z-index: 1;
        color: $c_white;
        background-image: linear-gradient(to top, rgba($c_gradient, 1), rgba($c_gradient, 0));

        @include mobile {
            .title-h4 {
                font-size: 25px;
            }
        }
    }

    .thumb-etape__title {
        display: block;
        @include ft('', 32px, 700, '', 1.1);
        max-width: 320px;

        @include mobile {
            font-size: 25px;
        }
    }

    .thumb-etape__cities {
        display: flex;
        align-items: center;
        @include ft('', 20px, 300, '', 1);
        margin-bottom: 10px;

        @include mobile {
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        & + * {
            margin-top: 12px;

            @include mobile {
                margin-top: 8px;
            }
        }

        span {
            display: inline-flex;
            align-items: center;

            &:before {
                @include font();
            }

            &:first-child {
                &:before {
                    content: "\e949";
                    margin-right: 6px;
                    font-size: 20px;
                    transform: translateY(-1px);

                    @include mobile {
                        margin-right: 3px;
                        font-size: 14px;
                    }
                }
            }

            &:nth-child(2) {
                margin-left: 4px;

                @include mobile {
                    margin-left: 2px;
                }

                &:before {
                    content: "\e901";
                    margin-right: 4px;
                    font-size: 12px;
                    transform: translateY(-1px);

                    @include mobile {
                        margin-right: 2px;
                        font-size: 9px;
                    }
                }
            }
        }
    }


    .item-etape__preview {
        display: grid;
        grid-template-areas: "metas btn";
        grid-template-columns: 2fr 1fr;

        // marge négative pour avoir un gap de 10px entre les thumbs
        margin-left: -5px;
        margin-right: -5px;

        > * {
            padding: 5px;
        }

        @include mobile {
            grid-template-areas: "metas"
                                  "btn";
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }
    }

    .metas-etape {
        grid-area: metas;

        @include mobile {
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }

    .item-etape__discover {
        grid-area: btn;

        .btn-discover-etape {
            width: 100%;
            justify-content: flex-end;

            &:before {
                margin-left: auto;
            }
        }
    }


    // Section - FAQ + Infos pratiques
    // ==========================================================================

    .group-infos {
        margin-top: 60px;

        @include mobile {
            margin-top: 30px;
        }
    }

    .bloc-faq {
        .accordeon {
            padding: 0 34px;

            @include tablet_portrait {
                padding: 0;
            }
        }

        .bloc-accordeon {
            @include tablet_portrait {
                margin: 0 !important;
            }
        }
    }

    .zone-infos {
        display: flex;
        padding: 40px;
        align-items: flex-start;
        margin-top: 40px;
        border: 1px solid rgba($c_border, 0.5);

        @include mobile {
            flex-direction: column;
            align-items: center;
            padding: 30px 15px;
            margin-top: 20px;
        }

        figure {
            flex-shrink: 0;

            img {
                width: 80px;
                height: auto;

                @include mobile {
                    width: 60px;
                }
            }

            & + * {
                margin-left: 30px;

                @include mobile {
                    margin-left: initial;
                    margin-top: 10px;
                }
            }
        }
    }

    .zone-infos__content {
        display: flex;
        align-items: flex-start;

        .btn {
            width: 100%;
            max-width: 200px;

            @include mobile {
                max-width: none;
            }
        }

        @include desktop {
            flex-wrap: wrap;
        }

        @include mobile {
            justify-content: center;
        }
    }

    .zone-infos__content-text {
        > * {
            display: block;

            @include mobile {
                text-align: center;
            }
        }

        & + * {
            margin-left: 30px;

            @include desktop {
                margin-left: initial;
                margin-top: 20px;
            }
        }
    }

    .zone-infos__title {
        @include ft('', 22px, 400, $c_txt, 1);

        & + * {
            margin-top: 5px;
        }
    }

    .zone-infos__surtitre {
        @include ft('', 15px, 500, $c_main, 1);
        font-style: italic;

        & + * {
            margin-top: 10px;
        }
    }

    .zone-infos__text {
        > * {
            @include ft('', 12px, 400, rgba($c_txt, 0.5), 1.5);
        }
    }



    // Section - Bloc relation
    // ==========================================================================

    .section-relation-itineraires {

        .bloc-relation {
            .onglets.wrapper-slider {
                margin-left: initial !important;
                max-width: initial !important;
            }
        }
    }


    .bloc {
        @media screen and (max-width:1440px) {
            margin: 0 !important;
        }
    }
}

