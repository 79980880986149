select{
    @include ft($f_txt, 13px, 300, $c_main, 25px);
    @extend %appearance;
    background: $c_white;
    width: 200px;
    border: 1px solid $c_border;
    border-radius: 4px;
    padding: 25px 20px 8px 20px;
	display: block;
	box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include addTransition();
    &:hover, &:focus{
        body:not(.no-hover) & {
            color: $c_third;
        }
    }
}

.selectric-wrapper{
	.selectric{
		background: $c_white;
        width: 200px;
        border: 1px solid $c_border;
        border-radius: 4px;
		@include addTransition();
		&:hover{
			body:not(.no-hover) & {
                border-color: $c_main;
                .label{
                    color: $c_third;
                }
			}
		}
		.label{
			@include ft($f_txt, 13px, 300, $c_main, 25px);
			box-sizing: border-box;
			display: block;
            width: 100%;
			height: 100%;
			margin: 0;
			padding: 25px 42px 8px 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include addTransition();
			&:after{
				content: "\e900";
				@include font();
                position: absolute;
                right: 20px;
                bottom: 18px;
				font-size: 10px;
			}
		}
		.button{
			display: none;
		}
	}
	.selectric-items{
		width: 100% !important;
        margin-top: 10px;
        margin-bottom: 10px;
		border: 1px solid rgba($c_border,0.2);
        border-radius: 4px;
		background: $c_white;
        box-shadow: none;

		@media screen and (max-width: 1200px) {
			width: 142% !important;
		}

		.selectric-scroll{
			ul{
				padding: 5px 0;
				li{
                    @include ft('', 12px, 400, $c_dark_grey, 18px);
                    display: block;
					padding: 3px 20px;
					margin-bottom: 0;
                    white-space: normal;
					@include addTransition();
					&:hover{
						background: none;
                        color: $c_third;
					}
					&.selected{
						background: none;
					}
				}
			}
		}
	}
}
