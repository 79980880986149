// -------  link forget password
.myex-form-field {
	.myex-forgot-password {
		text-align: right;
		font-size: calculateRem(11px);
		color: currentColor;
		text-decoration: underline;
		margin-top: calculateRem(10px);

		&:hover {
			color: var(--hover-link) !important;
		}
	}
}
