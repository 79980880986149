%arrow-right {
    left: auto;
    right: -12px;
    border-width: 9px 0 9px 14px;
    border-color: transparent transparent transparent $c_white;
    @include mobile{
        right: -7px;
        border-width: 5px 0 5px 8px;
    }
}

.bloc-image {
    .wrapper{
        @include mobile{
            margin-left: -20px;
            margin-right: -20px;
            width: calc(100% + 40px);
        }
    }
    figure {
        background: none;
        @include mobile {
            @include aspect-ratio(1, 1);
        }

        img {
            height: auto;
            @include mobile {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .credits {
        text-align: right;
        top: 100%;
        margin: 10px 0 0 0;
        @include mobile {
            width: 100%;
            padding: 0 20px;
        }
    }


    .cta-image {
        @extend %appearance;
        background: none;
        padding: 0;
        position: absolute;
        top: 20%;
        right: 35%;
        z-index: 100;
        width: 100px;
        height: 100px;
        @include addTransition();
        @include mobile{
            width: 55px;
            height: 55px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: -12px;
            margin-top: -9px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 9px 14px 9px 0;
            border-color: transparent $c_white transparent transparent;
            @include mobile{
                left: -7px;
                margin-top: -5px;
                border-width: 5px 8px 5px 0;
            }
        }


        // Alignement
        // ligne 1
        &.top-1-1 {
            left: calc((1 / 5 * 100%) - 50px);
            top: calc((1 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((1 / 5 * 100%) - 30px);
                top: calc((1 / 4 * 100%) - 30px);
            }
            &:before{
                @extend %arrow-right;
            }
        }

        &.top-2-1 {
            left: calc((2 / 5 * 100%) - 50px);
            top: calc((1 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((2 / 5 * 100%) - 30px);
                top: calc((1 / 4 * 100%) - 30px);
            }
            &:before{
                @extend %arrow-right;
            }
        }

        &.top-3-1 {
            left: calc((3 / 5 * 100%) - 50px);
            top: calc((1 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((3 / 5 * 100%) - 30px);
                top: calc((1 / 4 * 100%) - 30px);
            }
        }

        &.top-4-1 {
            left: calc((4 / 5 * 100%) - 50px);
            top: calc((1 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((4 / 5 * 100%) - 30px);
                top: calc((1 / 4 * 100%) - 30px);
            }
        }

        // ligne 2
        &.top-1-2 {
            left: calc((1 / 5 * 100%) - 50px);
            top: calc((2 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((1 / 5 * 100%) - 30px);
                top: calc((2 / 4 * 100%) - 30px);
            }
            &:before{
                @extend %arrow-right;
            }
        }

        &.top-2-2 {
            left: calc((2 / 5 * 100%) - 50px);
            top: calc((2 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((2 / 5 * 100%) - 30px);
                top: calc((2 / 4 * 100%) - 30px);
            }
            &:before{
                @extend %arrow-right;
            }
        }

        &.top-3-2 {
            left: calc((3 / 5 * 100%) - 50px);
            top: calc((2 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((3 / 5 * 100%) - 30px);
                top: calc((2 / 4 * 100%) - 30px);
            }
        }

        &.top-4-2 {
            left: calc((4 / 5 * 100%) - 50px);
            top: calc((2 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((4 / 5 * 100%) - 30px);
                top: calc((2 / 4 * 100%) - 30px);
            }
        }

        // ligne 3
        &.top-1-3 {
            left: calc((1 / 5 * 100%) - 50px);
            top: calc((3 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((1 / 5 * 100%) - 30px);
                top: calc((3 / 4 * 100%) - 30px);
            }
            &:before{
                @extend %arrow-right;
            }
        }

        &.top-2-3 {
            left: calc((2 / 5 * 100%) - 50px);
            top: calc((3 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((2 / 5 * 100%) - 30px);
                top: calc((3 / 4 * 100%) - 30px);
            }
            &:before{
                @extend %arrow-right;
            }
        }

        &.top-3-3 {
            left: calc((3 / 5 * 100%) - 50px);
            top: calc((3 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((3 / 5 * 100%) - 30px);
                top: calc((3 / 4 * 100%) - 30px);
            }
        }

        &.top-4-3 {
            left: calc((4 / 5 * 100%) - 50px);
            top: calc((3 / 4 * 100%) - 50px);
            @include mobile {
                left: calc((4 / 5 * 100%) - 30px);
                top: calc((3 / 4 * 100%) - 30px);
            }
        }

        &:hover, &:focus {
            body:not(.no-hover) & {
                cursor: pointer;

                [class^="icon-"], [class*=" icon-"] {
                    background: $c_third;
                }
            }
        }

        [class^="icon-"], [class*=" icon-"] {
            width: 80px;
            height: 80px;
            border: 10px solid $c_white;
            background: $c_main;
            border-radius: 50%;
            @extend %aligncenter;
            color: rgba($c_white, 0.75);
            font-size: 40px;
            @include addTransition();
            @include mobile {
                width: 45px;
                height: 45px;
                border-width: 5px;
                font-size: 22px;
            }
        }

        .price {
            @include ft($f_hx, 12px, 500, $c_white, 20px);
            position: absolute;
            top: calc(100% - 20px);
            left: 50%;
            transform: translateX(-50%);
            transform: translate3d(-50%, 0, 0);
            padding: 8px 10px 6px 10px;
            border-radius: 4px;
            background: $c_greydark;
            white-space: nowrap;
            @include mobile {
                top: calc(100% - 12px);
                padding: 5px 10px 3px 10px;
            }
        }
    }
}