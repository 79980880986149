.bloc-slider {
    &.th-slider-wrapper {
        padding: $content-spacing/2 0;
        @include tablet_landscape{
            padding: $content-spacing-tab/2 0;
        }
        @include mobile {
            padding: $content-spacing-mobile/2 0;
        }
    }

    .wrapper {
        padding: 0 12vw;

        @include wide{
            padding: 0 16vw;
        }

        @include tablet_portrait{
            padding: 0 12vw;
        }
        @include mobile{
            padding: 0 12vw;
        }

        // TOUCH DEVICE
        .no-hover & {
            @include desktop {
                margin-left: -$border-gutter;
                margin-right: -$border-gutter;
                width: calc(100% + #{$border-gutter*2});
                max-width: calc(100% + 120px);
            }
            @include tablet_landscape {
                margin-left: -40px;
                margin-right: -40px;
                width: calc(100% + 80px);
                max-width: calc(100% + 80px);
            }
            @include mobile {
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                max-width: 100% !important;
            }
        }
    }

    .nav {

        @include tablet_landscape {
            display: none;
        }

        button {
            &:first-child {
                left: 0;
                @include wide{
                    left: 16vw;
                }
                @include tablet_landscape {
                    left: 60px;
                }
            }

            &:last-child {
                right: 0;
                @include wide{
                    right: 0;
                }
                @include tablet_landscape {
                    right: 60px;
                }
            }

            .no-hover & {
                &:first-child {
                    left: 12vw;
                    @include tablet_landscape {
                        left: 60px;
                    }
                }

                &:last-child {
                    right: 12vw;
                    @include tablet_landscape {
                        right: 60px;
                    }
                }
            }
        }
    }


    .dots {
        position: static;
        display: flex;
        align-items: center;
        width: 100%;
        height: 1px;
        background-color: $c_light_txt;
        margin-top: 20px;

        button {
            width: 100%;
            background-color: transparent;
            border-radius: 8px;
        }
    }

    .wrapper-slider {
        max-width: calc(100% - 30vw);

       
    }

    .th-slider {
        padding: 0;
        display: flex;
        height: calc(100vh - 100px);
        max-height: 700px;

        .item{
            position: relative;
            width: 100% !important;
            margin: 5% 36px 0;

            transition: margin 0.16s ease-in;

            @include tablet_portrait {
                width: 100% !important;
                height: 100%;
            }

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            &.active {
                margin-top: 0;
            }

            @include mobile {
                margin-left: 6px;
                margin-right: 6px;
            }
        }
    }

    .item {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;

        .caption {
            position: absolute;
            left: 80px;
            right: 80px;
            top: 50%;
            transform: translateY(-50%);
            transform: translate3d(0, -50%, 0);
            z-index: 50;
            @extend %bloc-texte-white;
            @include tablet_landscape {
                left: 60px;
                right: 60px;
            }
            @include mobile {
                left: 40px;
                right: 40px;
            }

            .surtitre {
                & + h2, & + h3 {
                    margin-top: -5px;
                    @include tablet_landscape{
                        margin-top: -10px;
                    }
                }
            }

            h2, h3 {
                @include ft('', 56px, 200, '', 56px);
                text-transform: none;
                letter-spacing: 0;
                margin-bottom: 15px;
                @include tablet_landscape {
                    font-size: calculateRem(46px);
                    line-height: calculateRem(46px);
                    margin-bottom: 10px;
                }
                @include tablet_portrait {
                    font-size: calculateRem(40px);
                    line-height: calculateRem(40px);
                }
                @include mobile_small {
                    font-size: calculateRem(35px);
                    line-height: calculateRem(35px);
                }
            }

            .btn {
                width: auto;
            }

            + figure{
                &:after {
                    content: '';
                    @extend .gradient-left;
                    right: auto;
                    width: 100%;
                    max-width: calc(900px + 120px);
                    opacity: 0.75;
                    z-index: 10;
                }
            }
        }

        figure {
            height: 100%;
            border-radius: 10px;
            overflow: hidden;

            img {
                height: 100%;
            }
            figcaption{
                display: none;
            }
        }

        .credits{
            bottom: 55px;
            left: 80px;
            padding: 0;
            @extend %credit-absolute;
            @include tablet_landscape{
                left: 60px;
                bottom: 35px;
            }
            @include mobile{
                display: none;
            }
        }

        .absolute-link {
            @include mobile {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 999;
            }
        }
    }

    &--slides-only {

        @include mobile {
            padding-left: 0;
            padding-right: 0;
        }
        
        .wrapper-slider {
            @include desktop {
                margin: 0 auto;
            }
            @include tablet_landscape {
                margin: 0;
                width: 100%;
                max-width: 100%;
            }
        }   
       
        .th-slider {
            @include desktop {
                max-height: 600px;
            }
            
            @include tablet_landscape {
                overflow-y: hidden !important;
                scroll-padding-inline: 100px !important;
                padding-left: 60px !important;
                padding-right: 60px !important;
                max-height: 400px;
                overflow-y: unset;
            }

            @include mobile {
                scroll-padding-inline: 120px !important;
            }

            .item {
                vertical-align: bottom;

                @include tablet_landscape {
                    width: 85% !important;
                    margin-left: 10px !important;
                    margin-right: 10px !important;
                }
            }
        }

        .dots-wrapper {
            @include tablet_landscape {
                width: calc(100% - 48vw);
                margin: 0 auto;
            }
        }

        .nav {
            button:first-child {
                @include wide{
                    left: 0;
                }
            }
        }
        
    }
}