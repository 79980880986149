.bloc__text-top1 {
    position: relative;

    figure {
        p.credits {
            font: normal normal normal 10px/16px Poppins;
            
            color: #ABB3B9;
        }
    }

    &_content {
        position: relative;
        margin: 80px auto;
        width: 900px;
        &::after {
            content: '';
            position: absolute;
            bottom: -15px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #ABB3B9;
    
        }
        h2 {
            font: normal normal 300 40px/40px Poppins;
            color: var(--black);
            padding-left: 18px;
            position: relative;
            margin-bottom: 18px;

            &::before {
                content: '';
                position: absolute;
                top: -6px;
                left: 0;
                width: 4px;
                height: 42px;
                background-color: var(--rose);
            }
        }

        p {
            font: normal normal normal 15px/26px Libre Baskerville;
            color: var(--black);
            margin-bottom: 4px;
        }

        a {
            color: var(--black);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        p.quote {
            margin-top: 30px;
            margin-bottom: 10px;
            font: italic normal normal 24px/32px Libre Baskerville;
            color: var(--black);
        }
    }



}