.myex-favorites {
    &.loading {
        display: none;
    }

    /*.myex-section__list--alert-empty {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23414548' stroke-width='6' stroke-dasharray='12%2c 10' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
        opacity: 1;
        border-radius: .625rem;
        margin-bottom: .625rem;
        display: block;
        position: relative;
        overflow: hidden;
        height: 285px;
        text-align: center;
        align-content: center;

        &:before {
            content: '';
            position: absolute;
            top: -34px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            background: linear-gradient(180deg, rgba(30, 37, 41, 0.9) 8%, rgba(30, 37, 41, 0.1) 50%);
        }

        .myex-section__list {
            margin: auto 20%;
        }
    }*/
}