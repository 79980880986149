
.page-itinerance {
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}


	.offre-icon {
		width: initial !important;
		height: initial !important;

		@include can-hover {
			.offre-icon__inner {
				color: $c_white;
				background-color: $c_main;

				&:before {
					color: $c_white;
				}
			}
		}

		&.--activites {
			.offre-icon__inner:before {
				content: "\e99c";
			}
		}

		&.--restaurants {
			.offre-icon__inner:before {
				content: "\e997";
			}
		}

		&.--hebergements {
			.offre-icon__inner:before {
				content: "\e998";
			}
		}

		&.--idees-sejours {
			.offre-icon__inner:before {
				content: "\e996";
			}
		}

		.offre-icon__inner {
			display: flex;
			align-items: center;
			padding: 7px 12px 6px;
			@include ft('', 13px, 700, $c_txt, 1.5);
			white-space: nowrap;
			border-radius: 20px;
			background-color: white;
			@include addTransition();

			&:before {
				content: "\e91e";
				@include font();
				display: inline-block;
				margin-right: 5px;
				font-size: 12px;
				color: $c_main;
				@include addTransition();
			}
		}
	}


	.thumb-etape {
		position: relative;

		figure {
			background-color: $c_gradient;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.maps {
		z-index: 1;
	}

	.bloc-accordeon .accordeon .item .bloc-texte p {
		font-family: $f_txt;
	}
}

// Main layout
// ==========================================================================

.wrapper-content-map {
	--border-gutter: 80px;
	--header-height: 80px;
	position: relative;
	display: flex;

	@include desktop {
		--border-gutter: 40px;
	}

	@include tablet_landscape {
	}

	@include tablet_portrait {
		--header-height: 100px;
	}

	@include mobile {
		--header-height: 66px;
		--border-gutter: 20px;
	}

	// gradient
	&:before {
		@include pseudo-block(100%, 150px);
		top: 0;

		left: 0;
		z-index: 10;
		background-image: linear-gradient(to bottom, $c_gradient, rgba($c_gradient, 0));
		opacity: 0.85;
		pointer-events: none;

		@include tablet_landscape {
			display: none;
		}
	}


	.col-content {
		position: relative;
		z-index: 1;
		width: 56%;
		@include tablet_landscape {
			width: 100%;
		}

		@keyframes zoom {
			from {
				transform: scale(1);
			}
			to {
				transform: scale(1.2);
			}
		}

		.swiper-iti {
			width: 100%;
			height: 100%;
			.swiper-wrapper {

				.swiper-slide {
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.swiper-slide-prev,
				.swiper-slide-active,
				.swiper-slide-duplicate-active {

					img {
						animation-name: zoom;
						animation-duration: 5s;
						animation-fill-mode: forwards;
					}
				}
			}
		}
	}

	.col-map {
		width: 44%;
		position: sticky;
		top: var(--header-height);
		z-index: 50;
		height: calc(100vh - var(--header-height));
		background-color: $c_main;

		@include tablet_landscape {
			display: none;
		}
	}


	// Col content layout
	// ==========================================================================

	.wrapper-sections {
		display: flex;
		flex-direction: column;
		padding-bottom: 40px;

		@include mobile {
			padding-bottom: 20px;
		}
	}


	.section-itinerance {
		padding-left: var(--border-gutter);
		padding-right: var(--border-gutter);

		&.--small-gap {
			padding-left: 20px;
			padding-right: 20px;
		}

		.section-title {
			padding-bottom: 5px;

			&:not(.--no-underline) {
				border-bottom: 1px solid rgba($c_border, 0.5);
			}

			@include mobile {
				font-size: 25px;
				line-height: 30px;
			}
		}
	}
}


// Cover
// ==========================================================================

.cover-itinerance {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: calc(100vh - 130px);
	padding: 30px var(--border-gutter);
	overflow: hidden;

	@include tablet_portrait {
		height: calc(100vh - 230px);
	}

	.page-itinerance.--itineraire & {
		padding-bottom: 30px;
	}

	.cover-image {
		@include pseudo-block-stretch();

		figure {
			// gradient
			&:before {
				top: initial;
				opacity: initial;
				right: initial;

				@include pseudo-block(100%, 100%);
				bottom: 0;
				left: 0;
				z-index: 1;
				max-height: 580px;
				background-image: linear-gradient(to top, $c_gradient, rgba($c_gradient, 0));
				opacity: 0.85;
				mix-blend-mode: multiply;
			}
		}
	}

    .swiper-slide {
        // gradient
        &:before {
            top: initial;
            opacity: initial;
            right: initial;

            @include pseudo-block(100%, 100%);
            bottom: 0;
            left: 0;
            z-index: 1;
            max-height: 580px;
            background-image: linear-gradient(to top, $c_gradient, rgba($c_gradient, 0));
            opacity: 0.85;
            mix-blend-mode: multiply;
        }
    }

	.cover-content {
		position: relative;
		z-index: 1;
	}

	.cover-cities {
		display: flex;
		align-items: center;
		color: $c_white;
		@include ft($f_hx, 20px, 300, '', 1.5);

		@include mobile {
			font-size: 14px;
		}

		& + * {
			margin-top: 10px;
		}

		span {
			display: inline-flex;
			align-items: center;
			text-transform: uppercase;

			&:before {
				@include font();
			}

			&:first-child {
				&:before {
					content: "\e949";
					margin-right: 8px;
					font-size: 20px;
					transform: translateY(-1px);

					@include mobile {
						font-size: 16px;
						margin-right: 6px;
					}
				}
			}

			&:nth-child(2) {
				margin-left: 8px;

				@include mobile {
					margin-left: 6px;
				}

				&:before {
					content: "\e901";
					margin-right: 7px;
					font-size: 11px;
					transform: translateY(-1px);

					@include mobile {
						font-size: 9px;
						margin-right: 4px;
					}
				}
			}
		}
	}

	.cover-title {
		@include ft($f_hx, 32px, 700, $c_white, 1.06);

		@include mobile {
			font-size: 25px;
			margin-bottom: 0;
			padding-right: 40px;
			font-weight: 600;
		}

		.page-itinerance.--itineraire & {
			font-size: 44px;

			@include tablet_landscape {
				font-size: 35px;
			}

			@include mobile {
				margin-bottom: 15px;
			}
		}
	}


	.cta-add-favoris {
		@include reset-button;
		display: inline-flex;
		align-items: center;
		padding: 20px 0 0 20px;

		position: absolute;
		bottom: 0;
		right: 0;

		.page-itinerance.--itineraire & {
			bottom: initial;
			top: 150px;
			right: 20px;
			z-index: 10;
		}

		@include min-mobile {
			display: none;
		}

		&.cta-favoris-checked {
			&:before {
				content: "\e959";
				color: $c_white;
			}
		}

		@include can-hover {
			&:before {
				color: $c_main;
			}
		}

		&:before {
			content: "\e916";
			@include font();
			font-size: 22px;
			color: $c_white;
			@include addTransition();
		}
	}

	.breadcrumb {
		margin: 0 !important;

		& + * {
			margin-top: 30px;

			@include mobile {
				margin-top: initial;
			}
		}

		@include mobile {
			display: none;
		}
	}

	.wrapper-cat {
		white-space: initial;
		overflow: initial;
		text-overflow: initial;

		margin-bottom: 10px;
		max-width: 100%;
		padding-left: 15px;

		span {
			font-weight: 500;
		}
	}

	.buttons-wrapper {
		display: flex;

		> *:first-child {
			margin-right: 10px;
		}

		@include mobile {
			width: 100%;
			flex-wrap: wrap;
			justify-content: space-between;

			.btn-icon {
				width: 48%;
				padding: 8px 12px 8px;
				font-size: 11px;
				text-align: center;
				margin-bottom: 30px;

				&::before {
					font-size: 16px;
					margin-right: 10px;
				}

				&.--icon-etapes::before {
					display: none;
				}
			}
		}
	}
}


// Map en tablette
// ==========================================================================

.map-tablet {
	position: absolute;
	height: 370px;
	margin-top: 70px;
	background-color: $c_main;
	display: none;

	@include min-tablet_landscape {
		display: none;
	}

	// map étendue
	&.is-open {
		position: fixed;
		display: block;
		top: var(--header-height);
		z-index: 600;
		height: calc(100% - 70px);
		width: 100%;
	}
}

// bouton pour étendre la carte
.floating-btn-container {
	position: sticky;
	top: initial;
	bottom: 10px;
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;

	&.--double {
		.btn {
			font-size: 11px;
			align-items: center;
			&:first-child {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
			&:last-child {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				&::before {
					content: "";
					width: 1px;
					height: 40%;
					position: absolute;
					top: 50%;
					transform: translate(-20px, -50%);
					background: rgba(white, 0.5);
				}
			}
		}
	}
}

.btn.btn--mobile-map-layer {
	display: none;
	width: max-content;
	border-radius: 30px;
	margin-top: 20px;
	margin-bottom: 20px;

	@include mobile {
		display: flex;
		text-align: center;
		background-color: $c_greydark;
		font-size: 11px;

		i {
			margin-right: 5px;
		}
	}
}

.close-map {
	position: fixed;
	z-index: 9999;
	bottom: 0;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	color: $c_txt;
	text-decoration: underline;
	cursor: pointer;
	width: 100%;
}


// Metas concernant les étapes (
// ==========================================================================

.metas-etape {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.meta-etape {
	display: inline-flex;
	align-items: center;
	@include ft('', 16px, 400, $c_surtitre, 1);

	@include mobile {
		font-size: 12px;
	}
}

.meta-separator {
	width: 1px;
	height: 20px;
	background-color: rgba(173,179,184,0.5);
}

.meta-etape__icon {
	display: inline-block;
	margin-right: 10px;
	font-size: 24px;
	color: $c_third;
}

.meta-etape__label {
	transform: translateY(3px);
	font-weight: 600;

	@include mobile {
		transform: translateY(2px);
	}
}

.listing-itineraires {
	.col-right {
		.credits {
			text-align: right;
		}
	}
}