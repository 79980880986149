.myex-card-collection-highlight {
	color: currentColor;
    overflow: hidden;
	border-radius: calculateRem(10px);
    display: block;

	&:hover,
	&:active,
	&:focus {
		color: var(--color-text) !important;
	}

	&__img {
		// @include card-img-hover();

		@include aspect-ratio(345, 347);
		z-index: 0;

		@include min-tablet_small {
			@include aspect-ratio(960, 595);
		}

		.myex-button--favorite {
			position: absolute;
			right: calculateRem(15px);
			bottom: calculateRem(15px);
			z-index: 2;

			@include min-mobile {
				right: calculateRem(20px);
				bottom: calculateRem(20px);
			}
		}
		//overlay
		&:after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background: linear-gradient(
					rgba(0, 69, 114, 0.8) 0%,
					rgba(255, 255, 255, 0.1) 100%
				),
				linear-gradient(rgba(0, 69, 114, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 0.5;
			z-index: 0;
		}
	}

	&__content {
		position: absolute;
		left: 0;
		top: 0;
		display: grid;
		align-items: flex-start;
		column-gap: calculateRem(20px);

		grid-template-areas:
			"a "
			"b "
			"c ";

		width: 100%;
		padding: calculateRem(46px) calculateRem(25px);

		@include min-mobile {
			grid-template-areas:
				"a c"
				"b c";
		}

		@include min-mobile {
			padding: calculateRem(70px) 0 0 calculateRem(40px);
			column-gap: calculateRem(50px);
		}
	}

	&__wrapper-title {
		display: flex;
		grid-area: a;
	}

	&__title {
		color: currentColor;
		line-height: 1.1;
		font-weight: 600;
		margin: 0;
		font-size: calculateRem(25px);
		@include ellipsis(3);
		width: 100%;

		@include min-mobile {
			font-size: calculateRem(40px);
			width: auto;
		}
	}

	&__meta {
		grid-area: b;
		font-weight: 300;
		font-size: calculateRem(12px);
		letter-spacing: 0.04em;
		text-transform: uppercase;
	}

	&__price {
		flex-shrink: 0;
		grid-area: c;
		justify-content: flex-end;
		justify-self: end;
		right: calculateRem(-25px);
		position: relative;
		@include min-mobile {
			right: 0;
		}
	}

	// Card hightlight collection
	&__collection {
		max-width: 85%;
        width: 100%;
		display: flex;
		gap: calculateRem(12px);
		padding: calculateRem(15px);
		align-items: center;
		position: absolute;
		left: 0;
		bottom: 0;
		@include card-img-hover();

		@include min-mobile {
			gap: calculateRem(32px);
			padding: calculateRem(20px);
		}

		&__avatar {
			@include aspect-ratio(100px, 100px);
			width: calculateRem(70px);
			border-radius: calculateRem(10px);
		
			flex-shrink: 0;

			@include min-mobile {
				width: calculateRem(100px);
			}
		}

		&__type {
			font-size: calculateRem(13px);
			letter-spacing: 0.05em;
			line-height: 1;
			@include min-mobile {
				font-size: calculateRem(18px);
			}
		}

		&__content {
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			gap: calculateRem(4px);
		}

		&__title {
			font-size: calculateRem(18px);
			font-weight: 600;
			margin: 0;
			line-height: 1.2;
			@include ellipsis(1);

			@include min-mobile {
				font-size: calculateRem(24px);
			}
		}

		.myex-author img {
			transform: none;
		}
	}
}
