.bloc__highlight {
    position: relative;
    padding-bottom: 15px;

    @include mobile {
        height: 100%;

        @include mobile {
            a {
                height: 100%;
            }
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        display: flex;
        flex-direction: row;
    }

    &_thumb {
        position: relative;
        margin-right: 12px;


        figure {
            position: relative;
            z-index: 1;
            width: 75px;
            height: 75px;
            border-radius: 10px;
        }
        

        &.thumb__video {
            &::before {
                
                
                content: '\e94b';
                position: absolute;
                z-index: 10;
                @include font();
                font-size: 25px;
                color: var(--white);
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 25px;
                height: 25px;
                
            }
        }
    }

    &_content {
        position: relative;
        width: 100%;

        strong {
            position: relative;
            font: normal normal normal 11px/17px Poppins;
            letter-spacing: 1.65px;
            color: var(--rose);
            text-transform: uppercase;
            padding-left: 13px;
            margin-bottom: 7px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &::before {
                content:'\e907';
                @include font();
                font-size: 10px;
                color: var(--rose);
                top: 3px;
                left: 0;
                position: absolute;
                height: 10px;
                width: 6px;
            }

            @include tablet_portrait {
                font-size: 9px;
                line-height: 13px;

                &::before {
                    top: 1px;
                }
            }
        }

        p {
            text-align: left;
            font: normal normal normal 13px/20px Poppins;
            letter-spacing: 0px;
            color: var(--white);
            margin-bottom: 15px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 3px;
            left: 0;
            width: 100%;
            height: 1px;
            background: #6a6a6a;
        }

    }

}