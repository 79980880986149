.ancres {
    position: sticky;
    -ms-position: sticky;
    z-index: 5000;
    left: 0;
    right: 0;
    top: 80px;
    width: 100%;
    box-sizing: border-box;
    background: none;
    display: flex;
    align-items: center;
    background: $c_white;
    overflow: visible;
    @include addTransition();
    @include mobile {
        top: 74px;
        background-color: transparent;
        padding-right: 0;
        border: 0;
        overflow: hidden !important;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: calc(100% - 8px);
            width: 100%;
            background-color: white;
        }
    }

    // horizontal line
    &:after {
        content: '';
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($c_border, 0.3);

        @include mobile {
            bottom: 8px;
        }
    }

    & + .bloc {
        padding-top: 100px;
        @include tablet_portrait{
            padding-top: 20px;
        }
        @include mobile {
            padding-top: 20px;
        }
    }


    // TOUCH DEVICE
    .no-hover & {
        @include desktop {
            padding: 0;
        }
        @include mobile{
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
        .th-slider{
            @include desktop{
                margin: 0;
            }
        }
    }

    .splide-bloc-ancre {
        width: 100%;
        height: 60px;

        .splide__track {
            height: 100%;
            overflow: visible;

            @include mobile {
				padding-bottom: 8px;
			}
        }

        @include wide {
            max-width: 1440px;
            margin: auto;
        }
    }

    .track-scroll {
        position: relative;
        z-index: 5000;
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding: 0;
        width: 100%;
        margin: 0 auto;

        // TOUCH DEVICE
        .no-hover & {
            @include desktop {
                /*overflow: hidden;*/
                /*overflow-x: auto;*/
                -webkit-overflow-scrolling: touch;
                scroll-snap-type: both mandatory;
                scroll-padding: $border-gutter;
                padding-left: $border-gutter;
                padding-right: $border-gutter;
                //margin: 0 $border-gutter;
                > * {
                    scroll-snap-align: start;
                }

                > *:last-child {
                    scroll-snap-align: end;
                    padding-right: 40px;
                }
            }
            @include tablet_landscape {
                scroll-padding: 40px;
                padding-left: 20px;
                padding-right: 20px;
            }
            @include mobile {
                scroll-padding: 20px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        li{
            display: inline-block;
            vertical-align: top;
            padding: 0 16px;
            margin: 0 !important;
            width: unset !important;
            @include mobile{
                padding: 0 8px;

                .menu-ancre {
                    font-size: 11px;
                }
            }
            &:before{
                content: none;
            }
            &:first-child{
                padding-left: 100px;
                @include wide {
                    padding-left: 0;
                }
                @include desktop {
                    padding-left: 80px;
                }
                @include tablet_landscape {
                    padding-left: 60px;
                }
                @include mobile {
                    padding-left: 20px !important;
                }
            }
        }

        .cta-favoris{
            color: $c_third;
            padding: 30px;
            margin: 0;
            @include mobile{
                padding: 20px;
            }
        }

        a {
            @include ft($f_txt, 14px, 600, $c_surtitre, 22px);
            position: relative;
            padding: 24px 0 21px;
            display: block;
            width: auto;
            margin: 0;
            white-space: nowrap;
            @include addTransition();
            @include mobile {
                font-size: calculateRem(12px);
                padding: 16px 0 12px;
            }
            &:hover, &:focus {
                body:not(.no-hover) & {
                    color: $c_third;
                }
            }
            &.active {
                color: $c_third;
                &:after {
                    opacity: 1;
                }
            }
            &:after {
                content: '';
                position: absolute;
                top: calc(100% - 10px);
                left: 50%;
                width: 12px;
                height: 12px;
                background: $c_third;
                border-radius: 50%;
                opacity: 0;
                translate: -50%;
                @include addTransition();

                @include mobile {
                    width: 10px;
                    height: 10px;
                    top: calc(100% - 4px);
                }
            }
        }
    }

    .social-share {
        background-color: $c_white;
        top: -10px;
        max-height: 0;
        right: 70px;

        @include wide {
            right: 0;
        }

        @include tablet_portrait {
            right: 30px;
        }

        @include mobile {
            right: 0;
            top: 0;
            max-height: unset;
        }
    }
}