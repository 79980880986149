.bloc-push-home{
    position: relative;
    display: flex;
    align-items: flex-start;

    &__text {
        position: sticky;
        top: 200px;
        bottom: 0;
        margin: 150px 0;
        width: 33%;
        padding-right: 60px;
        flex-shrink: 0;

        @include tablet_landscape {
            display: none;
        }
    }

    &__cards {
        flex: 1;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        @include tablet_landscape {
            grid-template-columns: repeat(2, 1fr);
            gap: 12px;
        }

        a.card-thematique {
            margin-right: initial;
            width: 100% !important;

            &:nth-child(3n+2) {
                transform: translateY(80px);

                @include tablet_landscape {
                    transform: none;
                }
            }

            .title-card {
                word-break: break-word;
            }
        }
    }

    button.btn {
        position: absolute;
        z-index: 95;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        background: $c_main;
        min-width: auto;
        color: #fff;
        padding: 0 20px;
        margin: 0;
        width: auto;
        height: 60px;
        font-size: calculateRem(30px);
        font-weight: 300;
        line-height: 65px;

        &::before {
            content: none;
        }
    }

}

.bloc-relation-thematiques-wrapper {

    padding: 60px 0;

    .bloc-relation-thematiques-title {
        margin-bottom: 15px;
        padding-left: 0;

        h3 {
            font-size: 40px;
            @include tablet_portrait {
                font-size: 25px;
            }

            @include mobile {
                font-size: 18px;
            }
        }

        span {
            p {
                margin-bottom: 10px !important;
            }
        }
    }

    @include mobile {
        padding: 20px 0;
    }

    .bloc-relation-thematiques {
        max-width: 100vw;
    }

    .splide:not(.is-overflow) .splide__pagination {
        display: flex;
    }

    .splide__track {

        .splide__slide {

            @include mobile {
                min-width: 150px;

                a {
                    width: auto !important;
                }
            }
        }
    }

    .splide__arrow {
        top: unset;
        bottom: -54px;
        background-color: transparent;

        svg {
            fill: #627A89;
        }

        &--prev {
            left: unset;
            right: 11vw;
        }

        &--next {
            right: 8vw;
        }

        span:before {
            font-size: 18px;
        }

        @include tablet_portrait {
            display: none !important;
        }
    }

    .class-pagination {

        bottom: -2.5em;
        padding-left: 80px;
        justify-content: left;
        max-width: 1600px;
        margin: auto;

        @include wide {
            padding-left: 7vw;
        }

        @include tablet_portrait {
            padding: 0 7vw;
            bottom: -1.5em;
        }

        @include mobile {
            padding: 0;
            justify-content: center;
        }
    }
    .class-page {
        margin: 0;
        height: 1px;
        border-radius: 8px;
        width: 100%;
        background-color: #627A89;
        &.is-active {
            height: 5px;
            background-color: #DEA99E;
            opacity: 1;
            transform: unset;
            padding-bottom: 2px;
        }
    }
}