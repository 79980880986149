.myex-popins--global-login {
	display: none;

	.myex-popin__close {
		@include mobile {
			display: none;
		}
	}

	&__top {
		padding: calculateRem(20px) var(--marge-side--popins) calculateRem(15px) var(--marge-side--popins);

		@include min-mobile {
			padding: calculateRem(40px) var(--marge-side--popins) calculateRem(40px);
		}
	}

	&__logo {
		display: flex;
		flex-direction: column;
		gap: calculateRem(10px);
		align-items: center;
		font-size: calculateRem(26px);

		@include min-mobile {
			flex-direction: column;
			font-size: calculateRem(57px);
		}

		.myex-logo__name {
			font-size: calculateRem(17px);
			font-weight: 500;
			line-height: 1.3;

			@include min-mobile {
				font-size: calculateRem(28px);
			}
		}

		.myex-logo__img {
			max-height: 90px;
			max-width: 90px;
		}
	}

	&__notice {
		max-width: calculateRem(420px);
		margin-top: calculateRem(10px);
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		line-height: 1.3;

		@include min-mobile {
			margin-top: calculateRem(20px);
		}
	}

	&__form {
		max-width: calculateRem(420px);
		margin-left: auto;
		margin-right: auto;

		.myex-button--submit {
			width: 100%;
		}

		&__footer {
			text-align: center;
			flex-direction: column;
			display: flex;
			gap: calculateRem(10px);
			max-width: calculateRem(420px);
			width: 100%;
			margin-top: calculateRem(20px);

			p {
				margin: 0;
				line-height: 1;
				font-size: calculateRem(11px);
				color: currentColor;

				@include min-mobile {
					font-size: calculateRem(14px);
				}
			}
		}
	}

	&__bottom {
		text-align: center;
		@include min-mobile {
			padding-top: calculateRem(20px);
		}

		button {
			width: 100%;
			padding: 0;
		}
	}

	.myex-popin--form {
		max-height: 100dvh;
	}

}
