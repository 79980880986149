/* ------------------
--- MIXINS ---
------------------ */
@mixin style-dot-notif-card() {
	position: relative;
	&:after {
		content: "";
		background: #e23060;
		border: calculateRem(2px) solid var(--current-bg);
		top: 0;
		right: 0;
		position: absolute;
		width: calculateRem(10px);
		height: calculateRem(10px);
		border-radius: 50%;
		transform: translateY(-50%);
	}
}


@mixin ft($family:"", $size:"", $weight:"", $color:"", $lineheight:"") // define font
{
    @if $family != "" {
        font-family: $family;
    }
    @if $size != "" {
        @include font-size($size);
    }
    @if $weight != "" {
        font-weight: $weight;
    }
    @if $color != "" {
        color: $color;
    }
    @if $lineheight != "" {
        @include line-height($lineheight);
    }
}

@mixin absolute() {
	width: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	transform: translate(-50%, -50%);
}

@mixin font() {
	font-family: 'icomoon' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: inline-block;
	vertical-align: middle;
}

@mixin clearfix() {
	&::after {
		display: block;
		content: "";
		clear: both;
	}
}


@mixin ellipsis($number:"",$lineheight:""){
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	@if $number != "" {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: $number;
	}
	@if $lineheight != "" {
		line-height: $lineheight;
		max-height: $lineheight * $number;
	}
}


// Define default font size
@function calculateRem($size) {
	$remSize: $size / 16px;
	@return $remSize * 1rem;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}

@mixin letter-spacing($spacing) {
	letter-spacing: ($spacing / 1000) * 1em;
}

@mixin line-height($size) {
	line-height: $size;

	@if(not unitless($size)) {
		line-height: calculateRem($size);
	}
}

// Mixin Breakpoints
// ==========================================================================
@mixin wide {
	@media screen and (min-width: #{$breakpoint_wd}) {
		@content;
	}
}

@mixin desktop {
	@media screen and (max-width: #{$max-width + $border-gutter + $border-gutter}) {
		@content;
	}
}

@mixin desktop1280 {
    @media screen and (max-width: #{$max-width}) {
        @content;
    }
}

@mixin desktop1200 {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

@mixin tablet_landscape {
	@media screen and (max-width: #{$breakpoint_paysage}) {
		@content;
	}
}

@mixin tablet_portrait {
	@media screen and (max-width: #{$breakpoint_portrait}) {
		@content;
	}
}

@mixin tablet_small {
	@media screen and (max-width: #{$breakpoint_tablet_small}) {
		@content;
	}
}

@mixin mobile {
	@media screen and (max-width: #{$breakpoint_mo}) {
		@content;
	}
}

@mixin mobile420 {
    @media screen and (max-width: 420px) {
        @content;
    }
}

@mixin mobile_small {
	@media screen and (max-width: #{$breakpoint_mo_small}) {
		@content;
	}
}

// Approche Mobile First
// ==========================================================================
@mixin min-mobile {
	@media screen and (min-width: #{$breakpoint_mo}) {
		@content;
	}
}

@mixin min-tablet_small {
	@media screen and (min-width: #{$breakpoint_tablet_small}) {
		@content;
	}
}

@mixin min-tablet_portrait {
	@media screen and (min-width: #{$breakpoint_portrait}) {
		@content;
	}
}

@mixin min-tablet_landscape {
	@media screen and (min-width: #{$breakpoint_paysage}) {
		@content;
	}
}

@mixin min-desktop {
	@media screen and (min-width: #{$breakpoint_desktop}) {
		@content;
	}
}

@mixin min-large-desktop {
	@media screen and (min-width: #{$breakpoint_wd}) {
		@content;
	}
}

// Mixin Transition effect
// ==========================================================================
@mixin addTransition($effect: all, $duration: 0.2s, $easeing:ease) {
	$anim: $effect $duration $easeing;
	transition: $anim;
}

// Mixin Aspect-ratio pour les images
// ==========================================================================
@mixin aspect-ratio($width, $height, $add: 0px) {
	position: relative;
	overflow: hidden;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-bottom: $add;
		padding-top: ($height / $width) * 100%;
	}
}

// Card à l'Horizontale
// ==========================================================================
@mixin card-horizontale(){
	width: 100%;
    z-index: 10;
	min-height: 180px;
	@include addTransition();
    @include mobile{
        min-height: 70px;
    }

    &:hover, &:focus{
        body:not(.no-hover) & {
            z-index: 100;
            > div{
                transform: translateX(-40px);
                transform: translate3d(-40px,0,0);
                &:before{
                    opacity: 1;
                }
            }
        }
    }

	// Card Expériences
	&.card-exp {
		> div {
			figure {
				&:after {
					content: none;
				}
			}
		}
	}
	&.card-offre {
		overflow: visible;
	}


	> div {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&:before {
			content: '';
			position: absolute;
            top: -20px;
            left: -20px;
            right: -20px;
            bottom: -20px;
            width: calc(100% + 40px);
            height: calc(100% + 40px);
            background: $c_white;
            border-radius: 4px;
            box-shadow: -30px 0 40px rgba($c_dark,0.08);
            z-index: -1;
            opacity: 0;
            @include addTransition();
            .no-hover & {
                content: none;
            }
        }
        figure, .no-photo{
            width: 292px;
            @include aspect-ratio(292,180);
            &:after{
                content: none;
                @include mobile{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 50%;
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,000000+100&1+0,0+100 */
                    background: -moz-linear-gradient(top,  rgba(32,37,41,1) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top,  rgba(32,37,41,1) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom,  rgba(32,37,41,1) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#202529', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
                    opacity: 0.8;
                }
            }
            @include desktop1280{
                width: 240px;
                @include aspect-ratio(240,180);
            }
            @include tablet_portrait{
                width: 292px;
                @include aspect-ratio(292,180);
            }
            @include mobile{
                width: 105px;
                @include aspect-ratio(105,70);
            }
        }
        .caption{
            width: calc(100% - 292px);
            padding: 10px 80px 10px 20px;
            margin: 0;
            position: static;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @include desktop1280{
                width: calc(100% - 240px);
                padding: 10px 40px 10px 20px;
            }
            @include tablet_portrait{
                width: calc(100% - 292px);
            }
            @include mobile{
                width: calc(100% - 105px);
                padding: 0 0 0 10px;
            }

            .cta-favoris{
                position: relative;
                margin: 0;
                color: $c_surtitre;
                display: block;
                padding: 0;
                margin-bottom: 13px;
                @include mobile {
					position: absolute;
					left: auto;
					right: 0;
					top: -5px;
					margin: 0;
					color: $c_white;
				}
            }

        }
    }

    .title-card{
		color: $c_txt;
		font-weight: 500px;
		@include tablet_landscape {
			font-size: calculateRem(16px);
			line-height: calculateRem(20px);
		}
		@include mobile {
			font-size: calculateRem(13px);
			line-height: calculateRem(14px);
			margin: 0 0 5px 0;
			max-width: calc(100% - 25px);
		}
    }

    .wrapper-cat{
        @include mobile{
            margin: 0;
        }
        > * {
            //color: $c_grey;
        }
        @include mobile{
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
            display: block;
        }
    }

    .location{
        @include mobile{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            margin: 0;
            box-sizing: border-box;
        }
    }

    .basic-link{
        margin: 0;
        color: $c_greydark;
    }

    .encart{
        position: absolute;
        top: 10px;
        left: 10px;
        margin: 0;
        right: auto;
        @include mobile{
			position: relative;
			display: inline-block;
			top: 0;
			left: 0;
			margin-right: 8px;
			vertical-align: middle;
        }
    }

    .reservable{
        position: absolute;
        right: 0;
    }


	//Version mobile des Cards v2 dans la recherche
	&.card.card-offre{
		.caption{
			display: flex;
			flex-flow: column;

			.title-card{
				order: 2;
				margin-bottom: 5px;
				font-size: 18px;
				line-height: 22px;
				font-weight: 600;
				@include mobile {
					font-size: 13px;
					line-height: 14px;
					margin-bottom: 0;
				}
			}
			.metas-card{
				width: 100%;
				display: flex;
				order:1;
				margin: 0 0 2px 0;
				padding-right: 30px;
				.wrapper-cat, .location{
					width: auto;
					text-align: left;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				.wrapper-cat{
					margin-right: 10px;
				}
				.location{
					flex-shrink: 0;
				}
			}
		}
		.encart-tarif{
			&:before{content:none;}
			position: relative;
			display: block;
			order:5;

			.tarif{
				position: relative;
				border: 0;
				margin-top: 0;
				top: 0;
				width: auto;
				display: inline-block;
				padding: 0;
			}

			.encart {
				display: none;
			}
		}

		.reservable {
			box-sizing: border-box;
			padding: 12px 20px 10px;
			min-width: 190px;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 13px;
			line-height: 20px;
		}

		.classement {
			display: none;
		}
	}

	&.card.card-exp{
		.title-card{
			margin-bottom: 5px;
			font-size: 13px;
			line-height: 14px;
		}
		.basic-link{
			color: $c_txt;
		}
		.caption{
			.wrapper-cat{
				text-align: left;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				span{
					color: $c_surtitre;
				}
			}
		}
	}

	&.card.edito-v2{
		>div:after{
			content:none;
		}
		.caption{
			text-align: left;
			.title-card{
				margin-bottom: 5px;
			}
			.btn{
				width:auto;
				min-width:0;
				display: inline-block;
				font-size: 8px;
				line-height: 12px;
				padding: 8px 20px 6px;
			}
		}
		figure{
			&:after{
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				height: 100%;
				opacity: 1;
				background-color: transparent;
				box-sizing: border-box;
				z-index: 25;
				border:2px solid rgba(white, .4);
			}
		}

		.reservable {
			display: none;
		}

		.metas-card {
			margin: 0;
		}
	}

}















// Debug box avec background
@mixin bg($color:#ff0000, $opacity:0.1) {
	background-color: rgba($color, $opacity);
}

// Debug box avec outline
@mixin bd($color:#000000, $opacity:0.15, $thickness:1, $offset:null) {
	outline: ($thickness * 1px) dotted rgba($color, $opacity);
	@if $offset { outline-offset: #{$offset}px };
}


// Comportement de hover sauf si touchscreen
@mixin can-hover() {
	&:hover,
	&:focus {
		body:not(.no-hover) & {
			@content;
		}
	}
}



@mixin reset-button {
	appearance: none;
	padding: 0;
	border: none;
	outline:none;
	background-color: transparent;
	cursor: pointer;
	text-align: initial;
	font-family: inherit;
}


// Concatène un sélecteur avec un sélecteur d'élement (ex : a.btn-primary, button.btn-primaray...)
@mixin unify-parent($child) {
	@at-root #{selector-unify(&, $child)} {
		@content;
	}
}


// Set le box-sizing à border-box
@mixin box-sizing {
	box-sizing: border-box;
	*, *:before, *:after {
		box-sizing: inherit;
	}
}


// Étire un élement en absolue dans son parent (qui doit être positionné)
/// @param {Color} $bg-color - Couleur de fond
@mixin absolute-stretch($bg-color: null) {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	@if $bg-color { background-color: $bg-color;  }
}



//  Z-layer system
// -----------------------------------------------


// Gestion des valeurs des z-index
// ==========================================================================
$z-layers: (
		'overlay-mega-menu': 400,
		'overlay' : 300,
		'overlay-shadow': 100,
		'toolbar': 70,
		'barre-navigation': 70,
		'barre-infos': 50,
		'couverture' : 30,
		'credits-image' : 10,
		'default' : 1,
		'below' : -1,
);

// Renvoi une valeur de z-index défini à partir de la map de config
// (à renonmmer en z() )
@function z-index($layer) {
	@if not map-has-key($z-layers, $layer) {
		@warn "No z-index found in $z-layers map for `#{$layer}`. Property omitted.";
	}

	@return map-get($z-layers, $layer);
}



// Pseudo-block en absolue et dimensionné, son positionnement se fait en dehors du mixin
/// @param {Length} $width - Largeur
/// @param {Length} $height - Hauteur
/// @param {Color} $bg-color - Couleur de fond
@mixin pseudo-block($width, $height, $bg-color: null) {
	content: '';
	position: absolute;
	width: $width;
	height: $height;
	@if $bg-color { background-color: $bg-color;  }
}


// Pseudo-block en absolue et étiré dans son parent
/// @param {Color} $bg-color - Couleur de fond
@mixin pseudo-block-stretch($bg-color: null) {
	@include absolute-stretch($bg-color);
	content: '';
}



/// Crée un triangle, son positionnement se fait en dehors du mixin
/// @param {String} $direction - Orientation du triangle `up`, `right`, `down` plus des variantes pour les "corner triangles"
/// @param {Color} $color - Couleur du triangle
/// @param {Length} $width - Longueur du triangle
/// @param {Length} $height [$width] - Hauteur du triangle (si non renseigné -> triangle equilatéral)

@mixin triangle($direction, $color, $width, $height: $width) {
	@if not index(down up right left down-right down-left up-right up-left, $direction) {
		@warn "`#{$direction}` is not a valid parameter";
	}

	display: inline-block;
	width: 0;
	height: 0;
	$half-width: $width/2;
	$half-height: $height/2;

	// basic triangles : ▼ ▲ ▶ ◀
	@if $direction == 'down' {
		border-top  : $height solid $color;
		border-left : $half-width solid transparent;
		border-right: $half-width solid transparent;
	} @else if $direction == 'up' {
		border-bottom: $height solid $color;
		border-left  : $half-width solid transparent;
		border-right : $half-width solid transparent;
	} @else if $direction == 'right' {
		border-left  : $width solid $color;
		border-top   : $half-height solid transparent;
		border-bottom: $half-height solid transparent;
	} @else if $direction == 'left' {
		border-right : $width solid $color;
		border-top   : $half-height solid transparent;
		border-bottom: $half-height solid transparent;

		// corner triangles : ◥ ◢ ◤ ◣
	} @else if $direction == 'up-right' {
		border-top   : $half-height solid $color;
		border-left  : $half-width solid transparent;
		border-right : $half-width solid $color;
		border-bottom: $half-height solid transparent;
	} @else if $direction == 'down-right' {
		border-top   : $half-height solid transparent;
		border-left  : $half-width solid transparent;
		border-right : $half-width solid $color;
		border-bottom: $half-height solid $color;
	} @else if $direction == 'up-left' {
		border-top   : $half-height solid $color;
		border-left  : $half-width solid $color;
		border-right : $half-width solid transparent;
		border-bottom: $half-height solid transparent;
	} @else if $direction == 'down-left' {
		border-top   : $half-height solid transparent;
		border-left  : $half-width solid $color;
		border-right : $half-width solid transparent;
		border-bottom: $half-height solid $color;
	}
}



@mixin quick-container() {
	display: inline-flex;
	align-self: flex-end;
	height: 72px;
	margin-left: auto;
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 500;
	@include tablet_portrait {
		left: 50%;
		transform: translateX(-50%);
		bottom: 20px;
		width: auto;
		right: auto;
	}
	@include mobile {
		width: max-content;
		height: 48px;
	}

	> * {
		position: relative;
		-webkit-appearance: none;
		resize: none;
		box-shadow: none;
		border: none;
		background: transparent;
		cursor: pointer;
		flex-shrink: 0;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		@include addTransition();

	}

	.btn-prev-page {
		padding-left: 25px;
		padding-right: 20px;
		background-color: rgba($c_dark, 0.75);
		margin: 0;
		border-radius: 75px 0 0 75px;
		@include mobile {
			padding-left: 21px;
			padding-right: 13px;
		}

		[class^="icon-"], [class*=" icon-"] {
			color: #B49B4E;
			font-size: 20px;
		}

		&:hover, &:focus {
			background-color: $c_greenish_blue;

			& + .btn-voir-univers:before {
				opacity: 0;
			}
		}
	}

	.btn-voir-mice,
	.btn-voir-univers {
		padding-left: 24px;
		padding-right: 33px;
		background-color: rgba($c_dark, 0.75);
		margin: 0;
		border-radius: 0px 75px 75px 0;
		@include mobile {
			padding-left: 17px;
			padding-right: 25px;
		}

		[class^="icon-"], [class*=" icon-"] {
			color: #B49B4E;
			font-size: 17px;
			margin-right: 10px;
			@include addTransition();
		}

		.text {
			@include ft($f_hx, 14px, 500, $c_white, 16px);
			position: relative;
			top: 1px;
			@include mobile {
				font-size: 11px;
			}
		}

		&:before {
			content: "";
			display: block;
			width: 1px;
			height: 20px;
			background-color: rgba($c_white, 0.42);
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			@include addTransition();
		}

		&:hover, &:focus {
			padding-left: 25px;
			background-color: $c_greenish_blue;

			&:before {
				opacity: 0;
			}
		}

	}
}