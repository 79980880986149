.bloc-slider-hotspot {

    padding: 20px 0;

    .bloc-hotspot-title {
        margin-bottom: 15px;
        padding-left: 0;

        h2 {
            font-size: 40px;
            @include tablet_portrait {
                font-size: 25px;
            }
        }
    }

    @media (max-width: 1440px) and (min-width: 1280px) {
        .padding-inner {
            padding: 0 145px;
        }
    }

    .splide {

        &__arrow {
            color: #fff;
            background: #7A97AA;
            border-radius: 4px;
            width: 60px;
            height: 60px;
            opacity: 1;

            @include tablet_landscape {
                width: 40px;
                height: 40px;
            }

            &--prev {
                left: 12vw;

                @include tablet_portrait {
                    left: 11vw;
                }
            }

            &--next {
                right: 12vw;

                @include tablet_portrait {
                    right: 11vw;
                }
            }

            &:disabled {
                opacity: 0;
            }

            &:hover {
                transition-duration: 300ms;
                background-color: #DEA99E;
                opacity: 1 !important;
            }

            @include mobile {
                display: none;
            }
        }

        &__slide {

            figure, .no-photo{

                margin-bottom: 10px;

                @include aspect-ratio(1,1);
                border-radius: 9px;

            }
            .credits{
                display: block;
                bottom: calc(100% + 5px);
            }

            .metas-card {
                margin-bottom: 5px;
            }

            @include mobile {
                .card {

                    .caption {
                        margin-top: 10px;

                        .metas-card {
                            margin-bottom: 0;

                            .location {
                                font-size: 12px;
                                letter-spacing: 1.8px;
                            }
                        }

                        h4 {
                            font-size: 14px;
                            margin-bottom: 7px;
                        }

                        p {
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
}