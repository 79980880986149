.offers-archive {

    margin-top: 260px;

    @include tablet_portrait {
        margin-top: 140px;
    }

    @include mobile {
        margin-top: 100px;
    }

    &__header {

        h1 {
            @include ft('', 40px, 300, $c_main, 40px);
            margin-bottom: 10px;

            @include tablet_landscape{
                font-size: calculateRem(35px);
                line-height: calculateRem(35px);
            }
            @include mobile {
                font-size: calculateRem(30px);
                line-height: calculateRem(30px);
                margin-bottom: 5px;
            }
        }


        .caption-txt {
            display: flex;

            @include min-tablet_portrait {
                justify-content: space-between;
            }

            @include tablet_portrait {
                flex-direction: column;
            }

            .header__title {
                position: relative;
                max-width: 600px;
                margin-right: 100px;

                @include tablet_portrait {
                    padding-right: initial;
                }

                @include desktop {
                    max-width: 100%;
                }

                @include tablet_portrait {
                    width: 100%;
                }
            }

            .header__desc {
                line-height: 28px;
                @include min-tablet_portrait {
                    text-align: right;
                }
            }

        }
    }

    &__content {
        position: relative;
    }

    &__content__map {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 100;
        width: calc(100% - ((100% - #{$max-width}) / 2) - (#{$max-width} - 600px));
        @include wide {
            width: calc(100% - ((100% - #{$max-width-large}) / 2) - (#{$max-width-large} - 600px));
        }
        @include desktop {
            width: 500px;
        }
        @include tablet_landscape {
            width: 400px;
        }
        @include tablet_portrait {
            position: relative;
            width: 100%;
            height: auto;
        }
    }

    &__filters {
        position: sticky;
        top: 80px;
        background: #fff;
        padding: 0 0 10px 20px;
        z-index: 2000;
        margin-left: -20px;
    }

    .wrapper-facettes {
        flex-flow: column;
        margin-top: -22px;
        padding-bottom: 10px;
        @media screen and (max-width: 1600px) {
            margin-top: -35px;
        }
        @include desktop {
            margin-top: -32px;
        }
        @include tablet_landscape {
            margin-top: 0;
        }
        @include tablet_portrait {
            position: relative;
            top: 0;
            display: block;
            background-color: transparent;
            padding-left: 0;
            margin-left: 0;
        }
        @include mobile {
            position: relative;
            top: 0;
            margin-top: -40px;
        }

        .content-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        &__top {
            @include tablet_landscape {
                display: none;
            }

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }
    }


    .wrapper-map {
        position: sticky;
        top: 80px;
        height: calc(100vh - 80px);


        @include tablet_portrait {
            display: none;
        }
    }

    .move-search {
        position: absolute;
        z-index: 10000;
        top: 15px;
        left: 50%;
        right: auto;
        transform: translate3d(-50%, 0, 0);
        display: block;
        box-sizing: border-box;
        background-color: $c_main;
        color: white;
        font-size: 13px;
        line-height: 14px;
        font-weight: 600;
        padding: 12px 12px 10px 49px;
        border-radius: 4px;
        cursor: pointer;
        white-space: nowrap;
        @include tablet_landscape {
            width: calc(100% - 40px);
            left: 20px;
            right: 20px;
            transform: translate3d(0, 0, 0);
            text-align: center;
        }

        span {
            position: absolute;
            left: 11px;
            top: 10px;
            box-sizing: border-box;
            border-radius: 90px;
            background-color: $c_white;
            width: 32px;
            height: 16px;
            margin-right: 10px;
            flex-shrink: 0;

            &:before {
                content: '';
                position: absolute;
                top: 3px;
                left: 3px;
                width: 10px;
                height: 10px;
                z-index: 10;
                background-color: $c_light_txt;
                border-radius: 50%;
                @include addTransition();
            }
        }

        input {
            display: none;

            &:checked {
                ~ span {
                    &:before {
                        left: auto;
                        right: 3px;
                        background-color: $c_sub;

                    }
                }
            }
        }

    }

    .indic-pagination {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        padding-bottom: 0;
        padding-top: 0;

        &::before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: $c_border;
            flex-shrink: 1;
            margin-right: 8px;
            margin-top: -3px;
        }

        span {
            flex-shrink: 0;

            &.numbers, &.resultats {
                padding: 0 4px;
            }
        }
    }
    .filters{
        position: relative;
        top: 0;
        width: 100%;
        font-size: 20px;
        z-index: 100;
        padding:10px 0 10px;

        @include tablet_portrait{
            width: 100%;
            padding: 0;
        }
        @include mobile{
            width: calc(100% + 40px);
            margin-left: -20px;
            top: 0;
            padding: 0;
            background-color: transparent;
        }

        .fake-select{
            display: flex;
            align-items: center;
            font-size: 0;
            @include tablet_portrait{
                width: 100%;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
                scroll-snap-type: both mandatory;
                >*{
                    flex-shrink: 0;
                    scroll-snap-align: center;
                }
                &:before, &:after {
                    content: '';
                    display: block;
                    height: 40px;
                    width: 40px;
                    flex-shrink: 0;
                    @include tablet_portrait {
                        display: none;
                    }
                }
            }
            @include mobile{
                transform:translate3d(0,-10px,0);
            }

            .felect {
                position: relative;
                display: inline-block;
                background-color: white;
                border: 1px solid $c_border;
                color: $c_surtitre;
                margin-right: 10px;
                font-size: 12px;
                line-height: 18px;
                font-weight: 500;
                border-radius: 4px;
                padding: 11px 50px 9px 15px;
                cursor: pointer;
                @include mobile {
                    padding: 10px 20px 9px 15px;
                }

                span.facet-icon {
                    position: absolute;
                    font-size: 12px;
                    color: $c_titre;
                    right: 20px;
                    top: 50%;
                    margin-top: -6px;
                    transform: translate3d(0, 0, 0);
                    @include addTransition();
                    @include mobile {
                        display: none;
                    }
                }

                &:first-of-type {
                    @include mobile {
                        padding-left: 20px;
                    }
                }

                .counter {
                    position: absolute;
                    left: -8px;
                    width: 15px;
                    height: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: $c_main;

                    .number {
                        position: relative;
                        top: 1px;
                        color: $c_white;
                        font-size: calculateRem(8px);
                        line-height: calculateRem(12px);
                        font-weight: 600;
                        @include letter-spacing(100);
                    }

                    &.hide {
                        display: none;
                    }
                }

                &:hover {
                    body:not(.no-hover) & {
                        .icon-chevron-slider-bottom {
                            transform: translate3d(0, 2px, 0);
                        }
                    }
                }

                .facet-title {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;

                    &.checked-filters {
                        color: $c_main;
                        font-weight: 400;
                    }
                }

                &[data-facet_key="equipements_hebergement"] {
                    width: 150px;
                    max-width: 150px;
                }

                &[data-facet_key="types_activite"] {
                    width: 140px;
                    max-width: 140px;
                    @media (max-width: 1420px) {
                        width: 130px;
                        max-width: 130px;
                    }
                    @include mobile {
                        width: 122px;
                        max-width: 122px;
                    }
                }


                &[data-facet_key="destinations"] {
                    width: 160px;
                    max-width: 160px;
                    @media (max-width: 1420px) {
                        width: 135px;
                        max-width: 135px;

                        .facet-title {
                            text-overflow: ellipsis;
                        }
                    }
                    @include mobile {
                        width: auto;
                        max-width: 122px;
                    }
                }

                &[data-facet_key="budget"] {
                    @media (max-width: 1420px) {
                        width: 145px;
                        max-width: 145px;
                    }
                }
            }

            .sejour-switch-input{
                display: none;
                &:checked{
                    +.sejour-switch{
                        span{
                            &:after {
                                left: auto;
                                right: 4px;
                                background-color: $c_sub;
                            }
                        }

                    }
                }
            }

            .sejour-switch {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $c_greydark;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                cursor: pointer;
                height: 40px;
                padding-left: 6px;

                @media screen and (min-width: 1200px){
                    margin-right: 12px;
                    padding-left: 0;
                }

                @include mobile {
                    padding-right: 60px;
                }

                span {
                    position: relative;
                    box-sizing: border-box;
                    border-radius: 90px;
                    border: 1px solid rgba($c_light_txt, 0.50);
                    width: 60px;
                    height: 30px;
                    margin-right: 8px;
                    flex-shrink: 0;
                    @include addTransition();
                    @include mobile {
                        background-color: $c_greydark;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        border-radius: 90px;
                        background-color: $c_light_txt;
                        width: 20px;
                        height: 20px;
                        @include addTransition();
                    }
                }

                &:hover{
                    body:not(.no-hover) & {
                        span{
                            border-color: $c_sub;
                        }
                    }
                }
            }
        }

        .fake-select-popup {
            position: absolute;
            top: 115%;
            left: 0;
            width: 365px;
            background-color: white;
            border-radius: 4px;
            padding: 20px;
            z-index: 1000;
            border: 1px solid $c_border;
            box-shadow: 0 0 30px rgba(0, 0, 0, .15);
            @include addTransition();

            &.listing:not(.open) {
                opacity: 0;
                pointer-events: none;
            }

            @include tablet_portrait {
                position: fixed;
                bottom: 80px;
                left: 0;
                right: 0;
                top: auto;
                border-radius:0;
            }
            @include mobile{
                bottom: 60px;
            }

            @include desktop {
                &[data-facet_popup="types_activite"] {
                    max-height: 300px;
                    overflow: hidden;
                    padding-bottom: 100px;

                    .filtrage {
                        overflow-y: scroll;
                        height: 210px;
                        padding-bottom: 40px;

                        &:before{
                            content: '';
                            z-index: 10;
                            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+90&0+0,1+90 */
                            background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%); /* FF3.6-15 */
                            background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%); /* Chrome10-25,Safari5.1-6 */
                            background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
                            top: auto;
                            height: 40px;
                            position: absolute;
                            bottom: 80px;
                            display: block;
                            width: 90%;
                            opacity: 0.6;
                        }
                    }

                    .common {
                        position: absolute;
                        bottom: 0;
                        width: 90%;
                        padding: 20px 0;
                        background-color: white;
                    }
                }
            }


            .top{
                display: none;
                position: relative;
                margin-bottom: 20px;
                .title{
                    display: block;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 600;
                    padding-right: 20px;
                    color: $c_gradient;
                }
                .close{
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 14px;
                    color: #BABABA;
                    @include addTransition();
                    &:hover{
                        body:not(.no-hover) & {
                            color: $c_third;
                        }
                    }
                }

                @include tablet_portrait{
                    display: block;
                }
            }


            .filtrage{
                padding-bottom: 20px;
                .title {
                    font-size: 12px;
                    color: $c_surtitre;
                    margin-bottom: 10px;
                }
                .choice {
                    position: relative;
                    display: inline-block;
                    box-sizing: border-box;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    border-radius: 4px;
                    font-size: 11px;
                    line-height: 17px;
                    border: 1px solid $c_border;
                    padding: 14px 20px 12px 20px;
                    color: $c_surtitre;
                    font-weight: 500;
                    cursor: pointer;
                    @include addTransition();
                    transition-property: background;
                    &:last-child{
                        margin-right: 0;
                    }
                    &:after{
                        content: '\e940';
                        font-family:'icomoon';
                        position: absolute;
                        line-height: 1;
                        right: 20px;
                        top: 50%;
                        margin-top: -6px;
                        font-size: 10px;
                        color: white;
                        display: none;
                    }

                    &.active{
                        background-color: $c_main;
                        color: white;
                        border-color: $c_main;
                        padding:14px 40px 12px 20px;
                        &:after{
                            display: inline-block;
                        }

                        &:hover{
                            body:not(.no-hover) & {

                            }
                        }
                    }

                    &:hover{
                        body:not(.no-hover) & {
                            background-color: $c_main;
                            color: white;
                            border-color: $c_main;
                        }
                    }

                    span {
                        color: white;
                        margin-left: 10px;
                    }

                    .icon-ico-close {
                        display: none;
                        vertical-align: middle;
                    }
                }
                input{
                    display: none;
                    &:checked{
                        +.choice {
                            background-color: $c_main;
                            color: white;
                            border-color: $c_main;

                            .icon-ico-close {
                                display: inline;
                            }
                        }
                    }
                }
            }
            .common{
                display: flex;
                justify-content:space-between;
                align-items:center;
                padding-top: 20px;
                border-top: 1px solid rgba($c_border, .5);
                button{
                    cursor: pointer;

                    &:last-child {
                        border: 0;
                        border-radius: 4px;
                        background-color: $c_main;
                        font-family: $f_txt;
                        font-weight: 500;
                        padding: 14px 20px 12px;
                        color: white;
                        font-size: 12px;
                        line-height: 14px;
                        margin-left: 10px;
                        @include addTransition();

                        &:hover {
                            body:not(.no-hover) & {
                                background-color: $c_sub;
                            }
                        }
                    }
                }

                .empty-filters {
                    border: none;
                    background-color: transparent;
                    color: $c_surtitre;
                    font-family: $f_txt;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 17px;
                    text-decoration: underline;
                    padding: 0;
                    margin-right: 10px;
                    @include addTransition();

                    &:hover {
                        body:not(.no-hover) & {
                            color: $c_third;
                        }
                    }
                }
            }
        }
    }

    .filters-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 3px;
        max-width: 100%;
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            margin-top: 10px;
        }
        @include mobile {
            overflow: hidden;
            margin-top: 0;
        }

        .wrap-select {
            position: relative;
            display: flex;
            align-items: center;

            @include desktop {
                flex-direction: column;
                align-items: flex-start;
            }

            @include tablet_portrait {
                flex-direction: row;
                align-items: center;
            }

            > label {
                position: static;
                margin-left: 0;
                white-space: nowrap;
                margin-right: 5px;
                font-weight: 400;
                line-height: 15px;
            }
        }

        .selectric-wrapper {
            position: static;

            .selectric {
                overflow: visible;

                .label {
                    padding: 0;
                    height: auto;
                    font-size: calculateRem(12px);
                    line-height: calculateRem(15px);
                    font-weight: 400;
                    color: $c_main;
                    text-transform: lowercase;
                    overflow: visible;

                    &:after {
                        position: initial !important;
                        content: "\e967";
                        @include font();
                        font-size: calculateRem(14px);
                        color: $c_dark;
                        margin-left: 10px;

                        @include desktop {
                            margin-top: -20px;
                        }

                        @include tablet_portrait {
                            margin-top: 0;
                        }
                    }
                }
            }

            .selectric-items {
                @media screen and (min-width: 1200px){
                    left: auto;
                    right: 0;
                    width: 165% !important;
                }
            }
        }

        .facettes {
            z-index: 1;
            @media screen and (max-width: 1600px) {
                margin: 20px 0;
                align-self: flex-start;
            }
            @include tablet_portrait {
                align-self: flex-end;
                margin: 0;
            }
        }


        .filters {
            @include tablet_portrait {
                display: none;
            }
        }

    }

    .selectric-wrapper .selectric {
        border: none;
        width: auto;
    }

    .content-right {
        position: relative;
        z-index: 1000;
        width: calc(100% - 600px);
        padding-left: 20px;
        margin-left: auto;
        @include desktop {
            width: calc(100% - 500px + #{$border-gutter});
        }
        @include tablet_landscape {
            width: calc(100% - 400px + 40px);
        }
        @include tablet_portrait {
            width: 100%;
            padding-left: 0;
        }

        .wrapper-listing-cards {
            width: 100%;
            margin-top: 20px;

            @include desktop {
                margin-top: 0;
            }

            .listing-offre-link-edito {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 20px 0;
                transition: transform .3s, box-shadow .3s;

                &:hover {
                    transform: translateX(-40px);
                    box-shadow: 0 10px 10px -10px rgba(0,0,0,.5);
                }

                @include mobile {
                    margin: 10px 0;
                }

                $block_root: listing-offre-link-edito;

                &__surtitre {
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 300;
                    margin-bottom: 10px;

                    @include mobile {
                        margin-bottom: 5px;
                    }
                }

                &__title {
                    color: $c_white;
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 15px;

                    @include mobile {
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                }

                &__link {
                    margin-top: auto;
                    padding-top: 25px;
                    color: $c_white;
                    text-decoration: underline;
                    font-weight: 700;
                    font-size: 13px;

                    @include mobile {
                        padding-top: 0;
                        margin-top: 0;
                    }
                }

                &__subtitle {
                    padding: 5px 10px;
                    border-radius: 4px;
                    font-weight: 700;
                    font-size: 12px;
                }

                &.bg-black,
                &.bg-white {
                    .#{$block_root}__surtitre {
                        color: $c_main;
                    }

                    .#{$block_root}__subtitle {
                        color: $c_white;
                        background-color: $c_main;
                    }
                }

                &.bg-pink,
                &.bg-blue {
                    .#{$block_root}__surtitre {
                        color: $c_white;
                    }

                    .#{$block_root}__subtitle {
                        background-color: $c_white;
                    }
                }

                &.bg-black {
                    background-color: $c_greydark;
                }

                &.bg-pink {
                    background-color: $c_third;

                    .#{$block_root}__subtitle {
                        color: $c_third;
                    }
                }

                &.bg-blue {
                    background-color: $c_main;

                    .#{$block_root}__subtitle {
                        color: $c_main;
                    }
                }

                &.bg-white {
                    background-color: $c_white;

                    .#{$block_root}__title {
                        color: $c_greydark;
                    }

                    .#{$block_root}__link {
                        color: $c_greydark;
                    }
                }

                &__col-left {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 40px;
                    max-width: 300px;
                    min-width: 215px;

                    @include mobile {
                        padding: 20px;
                    }
                }

                figure {
                    height: 100%;
                }

                .bottom {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    height: 100%;

                    @include mobile {
                        flex-direction: row;
                        align-items: center;
                    }
                }
            }

            .card {
                @include card-horizontale();
                margin: 20px 0;

                @include mobile {
                    margin: 10px 0;
                }

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

}
