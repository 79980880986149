// Variables
// ==========================================================================
@import "base/var";

// Base Styles
//=====================================
@import "base/reset";
@import "base/mixins";
@import "base/breakpoints";
@import "base/auto";
@import "base/text";
@import "base/grid";
@import "base/anim";
@import "base/gradients";
@import "base/loader";

// Library styles + Library Custom
// ==========================================================================
@import "libs/selectric";
@import "libs/selectric-custom";
@import "libs/icomoon";
@import "libs/leaflet";
@import "libs/leaflet_cluster";
@import "libs/matomo-custom";
@import "libs/formidable/grid";
@import "libs/formidable/dropzone";
@import "libs/formidable/theme";
@import "libs/formidable/components";
@import "libs/formidable/progressbar";
@import "libs/nouislider";
@import "libs/nouislider-custom";
@import "libs/daterangepicker_custom";
@import "libs/th-slider--modified";
@import "libs/axeptio";

// Éléments communs du site
// ==========================================================================
@import "layouts/basic-grid";
@import "layouts/breadcrumb";
@import "layouts/images";
@import "layouts/layout";
@import "layouts/buttons";
@import "layouts/overlay";
@import "layouts/overlay-user";
@import "layouts/overlay-notifications";
//@import "layouts/overlay-search";
@import "layouts/overlay-luxe";
@import "layouts/overlay-mice";
@import "layouts/overlay-slider-offres-auto";
@import "layouts/overlay-univers";
@import "layouts/cards";
@import "layouts/pagination";
@import "layouts/maps";
@import "layouts/social-share";
@import "layouts/facettes";
@import "layouts/aside-sit";
//@import "layouts/moteur-search";
@import "layouts/toolbar";
@import "layouts/itinerance/common-itinerance";
@import "layouts/itinerance/ancres-itinerance";
@import "layouts/itinerance/overlay-itinerance";
/* styles LE MAG */
@import "layouts/menu-lemag";
@import "layouts/overlay-highligth";
@import "layouts/nav-section";
@import "layouts/splide-lemag-theme";
@import "layouts/card-slide-dossier";
@import "layouts/card-slide-readmore";
@import "layouts/splide-dossier";
@import "layouts/splide-readmore";
@import "layouts/splide-video";
@import "layouts/layouts-search";

// Style Header + Footer
// ==========================================================================
@import "style/nav";
@import "style/layout_header";
@import "style/layout_footer";
@import "style/footer_newsletter";

// Style Spécifique Pages du site
// ==========================================================================
@import "pages/homepage";
@import "pages/page-header";
@import "pages/page-experience";
@import "pages/page-etape";
@import "pages/page-itineraire";
@import "pages/page-listing-itineraires";
@import "pages/page-destination";
@import "pages/page-listing";
@import "pages/page-detail";
@import "pages/page-listing-experiences";
@import "pages/page-password";
@import "pages/page-listing-auto";
@import "pages/page-explorer";
@import "pages/page-listing-edito";
@import "pages/landing-reoptin-newsletter";
@import "pages/landings-mice";
@import "pages/landings-luxe";
@import "pages/offers-archive";
/* styles LE MAG */
@import "pages/page-listing-lemag";
@import "pages/page-recette";
@import "pages/page-top1";
@import "pages/page-portrait";
@import "pages/page-article-long";

// Style des blocs modulables
// ==========================================================================
@import "blocs/bloc";
@import "blocs/bloc-texte";
@import "blocs/bloc-ancre";
@import "blocs/bloc-texte-image";
@import "blocs/bloc-iframe";
@import "blocs/bloc-form";
@import "blocs/bloc-colonne";
@import "blocs/bloc-galerie";
@import "blocs/bloc-relation";
@import "blocs/bloc-image";
@import "blocs/bloc-slider";
@import "blocs/bloc-destination";
@import "blocs/bloc-mosaique";
@import "blocs/bloc-video";
@import "blocs/bloc-accordeon";
@import "blocs/bloc-relation-thematiques";
@import "blocs/bloc-relation-experiences";
@import "blocs/bloc-slider-offres-auto";
@import "blocs/bloc-plus-dinformations";
@import "blocs/bloc-faq";
@import "blocs/bloc-mosaique-v2";
@import "blocs/bloc-reassurance";
@import "blocs/bloc-decouvrez-offres";
@import "blocs/bloc-destinations";
@import "blocs/bloc-explorer-thematiques";
@import "blocs/bloc-explorer-lieux";
@import "blocs/bloc-explorer-cibles";
@import "blocs/bloc-destination-libre";
@import "blocs/bloc-relation-onglet";
@import "blocs/bloc-slider-new";
@import "blocs/bloc-partenaire-testimonial";
@import "blocs/bloc-hotspot";
@import "blocs/bloc-push-thematique";
@import "blocs/bloc-relation-itineraire";
@import "blocs/bloc-relation-map";
@import "blocs/bloc-relation-collection";
@import "blocs/bloc-relation-explorateur";
/* styles LE MAG */
@import "blocs/bloc-listing-lemag";
@import "blocs/bloc-highlight";
@import "blocs/bloc-etape-recette";
@import "blocs/bloc-text-top1";
@import "blocs/bloc-slide-header-lemag";
@import "blocs/bloc-newsletter";
@import "blocs/bloc-grandest";

@import "widgets/regiondo";
@import "widgets/bookingkit";
@import "widgets/clevacances";

// MyExplore
// ==========================================================================
@import "app-my-explore/my-explore";


/*.menu-barre-mobile {
    display: none;
}*/


#content {
	@include tablet_portrait {
		&.content--scrollable {
			margin-top: -101px;
			transition: margin 0.15s ease-in;

			&.scrolled {
				margin: 0;

				.breadcrumb-mobile-wrapper {
					overflow-x: hidden;
					.breadcrumb_last {
						&::before {
							content: "";
							position: absolute;
							bottom: -14px;
							left: 50%;
							width: 8px;
							height: 8px;
							background-color: $c_third;
							z-index: 99;
							border-radius: 100%;
							transform: translateX(-50%);
						}
					}
				}
			}
		}
	}
}

html {
	scroll-behavior: smooth;
}

// Search
// ==========================================================================
@import "app-search/search-style";