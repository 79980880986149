// BLOC TEXTE - DEFAULT SETTINGS
// ==========================================================================
.bloc-texte {

	&.text-bloc {
		@include mobile {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}

	// Alignement
	&.aligncenter {
		margin-left: auto;
		margin-right: auto;

		>* {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&.alignright {
		margin-left: auto;
		margin-right: 0;

		>* {
			margin-left: auto;
			margin-right: 0;
		}
	}

	// Gestion des alignements et du max-width des éléments
	>* {
		position: relative;

		&:not(.wrapper):not(.btn):not(ul):not(ol):not(blockquote):not(.wp-caption) {
			max-width: 900px;
		}

		&:first-child {
			margin-top: 0 !important;
		}

		&:last-child {
			margin-bottom: 0 !important;

			&:not(.btn) {
				padding-bottom: 0;
			}

			.btn {
				margin-bottom: 0;
			}
		}

		&.aligncenter,
		&[style*="text-align: center"] {
			margin-left: auto;
			margin-right: auto;
		}

		&.alignright,
		&[style*="text-align: right"] {
			margin-left: auto;
			margin-right: 0;
		}

		&[style*="text-align: justify"] {
			margin-left: 0;
			margin-right: 0;
			max-width: 100% !important;
		}
	}

	.surtitre {

		&+h2,
		&+.title-h2,
		&+h3,
		&+.title-h3 {
			margin-top: -15px;

			&.big-title {
				margin-top: -10px;
			}
		}
	}

	li,
	blockquote p {
		max-width: 800px;

		&[style*="text-align: center"] {
			margin-left: auto;
			margin-right: auto;
		}

		&[style*="text-align: right"] {
			margin-left: auto;
			margin-right: 0;
		}
	}

	// Styles de textes
	h2,
	.title-h2 {
		&+.surtitre {
			margin-top: -22px;
			margin-bottom: 20px;

			@include mobile {
				margin-top: -25px;
			}
		}
	}

	h3,
	.title-h3 {
		display: block;

		&+.soustitre {
			margin-top: -5px;
			margin-bottom: 25px;

			@include mobile {
				margin-bottom: 20px;
			}
		}
	}

	p {
		@include ft($f_txt, $s_p, 400, $c_txt, 28px);
		display: block;
		margin-bottom: 30px !important;

		@include mobile {
			font-size: calculateRem(13px);
			line-height: calculateRem(22px);
			width: 100%;
			margin-bottom: 30px !important;
		}

		&.accroche {
			@include ft('', 18px, 600, $c_txt, 28px);
			margin-bottom: 25px;

			@include mobile {
				font-size: calculateRem(15px);
				line-height: calculateRem(26px);
			}
		}

		&.horaire {
			position: relative;
			max-width: unset;
			overflow: unset;
			font: italic normal normal 28px/37px Libre Baskerville;
			color: var(--darkblue);
			margin-bottom: 5px !important;
			display: flex;
			align-items: center;

			@include mobile {
				font-size: 22px;
			}
			

			&::after {
				content: '';
				flex: 1;
				z-index: 1;
				top: 20px;
				left: 100px;
				height: 1px;
				background-color: rgba(#ABB3B9, 0.9);
				margin-left: 20px;
			}
		}

		&.soustitre-horaire {
			font: normal normal 600 34px/44px Poppins;
			letter-spacing: 0px;
			color: var(--black);
			overflow: visible;

			@include mobile {
				font: normal normal 600 20px/24px Poppins;
			}
		}

		&.mention-section {
			position: relative;
			font: italic normal normal 24px/32px Libre Baskerville;
			letter-spacing: 0px;
			color: #000000;
			padding-bottom: 17px !important;
			margin-bottom: 10px !important;
			&::after {
				content: '';
				position: absolute;
				z-index: -1;
				bottom: 0px;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: rgba(#ABB3B9, 0.9);

			}
		}
	}

	b,
	strong {
		font-weight: 600;
	}

	ul {
		padding: 0;
		margin-bottom: 15px;
		list-style-type: none;
		@include clearfix();

		li {
			@include ft($f_txt, $s_p, 400, $c_txt, $s_p+13);
			position: relative;
			padding: 7px 0 7px 15px;

			&[style*="text-align: right"] {
				padding: 7px 15px 7px 0;

				&:before {
					left: auto;
					right: 0;
				}
			}

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 14px;
				width: 8px;
				height: 8px;
				background: $c_third;
				border-radius: 50%;
			}
		}
	}

	ol {
		padding: 0;
		margin-bottom: 15px;
		list-style-type: decimal;
		list-style-position: inside;
		@include clearfix();

		li {
			@include ft($f_txt, $s_p, 400, $c_txt, $s_p+13);
			position: relative;
			margin-left: 15px;
			padding: 7px 0;

			@include mobile {
				margin-left: 0;
			}
		}
	}

	a:not(.btn):not(.basic-link) {
		color: $c_main;
		text-decoration: underline;

		&:hover,
		&:focus {
			body:not(.no-hover) & {
				color: $c_third;
			}
		}
	}

	blockquote {
		font: italic normal normal 30px/30px Libre Baskerville;
		position: relative;

		margin: 40px 0px;
		padding: 16px 25px;

		border: 0;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0px;
			background-color: var(--rose);
			height: calc(100% + 2px);
			width: 4px;

		}

		@include mobile {
			margin: 30px 0;
		}


		p {
			font: italic normal normal 30px/30px Libre Baskerville;
			position: relative;
			margin-bottom: 10px !important;

			&:last-child {
				margin-bottom: 0 !important;
			}
		}
	}

	.btn {
		margin-top: 10px;
		margin-bottom: 20px;

		@include mobile {
			box-sizing: border-box;
			margin-top: 5px;
			width: 100%;
		}
	}

	.basic-link {
		display: flex;
		flex-direction: column;
		@include ft($f_txt, 14px, 600, $c_txt, 17px);
		margin-top: 10px;

		&::before {
			content: none;
		}

		&::after {
			content: '';
			margin-top: 5px;
			width: 20px;
			height: 3px;
			background-color: $c_third;
			border-radius: 4px;
			margin-left: 0;
		}

	}

	// Gestion iFame
	iframe:not(.instagram-media) {
		width: 100%;
		height: 430px;

		@include mobile {
			height: 100%;
		}
	}

	.instagram-media {
		margin: 30px auto !important;
	}

	// Gestion des éléments dans le Wysiwyg
	p,
	.wp-caption {
		margin-bottom: 20px;
		overflow: hidden;

		iframe {
			margin-bottom: 0;
		}

		&.alignleft {
			float: left;
			margin: 15px auto;
			margin-right: 40px;

			@include mobile {
				float: none;
				margin: 10px auto;
			}
		}

		&.aligncenter {
			display: block;
			margin: 30px auto;
		}

		&.alignright {
			float: right;
			margin: 30px auto;
			margin-left: 40px;

			@include mobile {
				float: none;
				margin: 10px auto;
			}
		}

		&.alignnone {
			margin: 30px 0;
		}

		img {
			height: auto;
			margin: 15px auto;

			&.alignleft {
				float: left;
				padding-right: 40px;

				@include mobile {
					float: none;
					margin: 10px 0;
					padding: 0;
				}
			}

			&.aligncenter {
				display: block;
				margin: 30px auto;
			}

			&.alignright {
				float: right;
				padding-left: 40px;

				@include mobile {
					float: none;
					margin: 10px 0;
					padding-left: 0;
				}
			}
		}
	}

	.wp-caption {
		img {
			width: 100%;
			margin: 0;
		}
	}

	figure {
		overflow: hidden;
		background: none;

		img {
			margin: 15px auto;
			height: auto;

			@include mobile {
				margin: 10px auto;
			}
		}

		&.alignnone {
			background: none;
		}

		&.alignleft {
			background: none;
			float: left;
			margin-bottom: 30px;
			padding-right: 40px;

			@include mobile {
				float: none;
				padding: 0;
				margin: 10px 0;
			}

			img {
				height: auto;
			}
		}

		&.aligncenter {
			background: none;
			display: block;
			margin: 30px auto;

			@include mobile {
				margin: 10px auto;
			}

			img {
				height: auto;
			}
		}

		&.alignright {
			background: none;
			float: right;
			padding-left: 40px;
			margin-bottom: 30px;

			@include mobile {
				float: none;
				padding-left: 0;
				margin: 10px 0;
			}

			img {
				height: auto;
			}
		}
	}

	.titre-trait {
		@include ft($f_hx, 18px, 600, $c_titre, 18px);
		text-transform: uppercase;
		margin-bottom: 25px;
		position: relative;
		display: block;
		width: 100%;

		@include mobile {
			font-size: calculateRem(17px);
			margin-bottom: 20px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 6px;
			left: 0;
			right: 0;
			height: 1px;
			background-color: $c_titre;
			z-index: 0;

			.with-background & {
				background-color: white;
			}
		}

		span {
			position: relative;
			display: inline-block;
			background-color: white;
			z-index: 1;
			padding-right: 15px;

			.with-background & {
				background-color: $c_main;
			}

			@include mobile {
				padding-right: 10px;
			}
		}
	}

}