//cards from base website
//colors adjsutements
.card {
	@include card-img-hover();
	color: currentColor;

	&:hover,
	&:focus {
		color: currentColor !important; // override color
	}

	.title-card {
		color: currentColor;
		width: 100%;
	}

	.location,
	.location:before {
		color: var(--color-text-lighter);
	}
}


