.page-template-page-explorer {
    background-color: #1E2529;

    h1 {
        visibility: hidden;
    }

    .thematique-title, .cibles-title, .lieux-title, .bloc-relation-onglet-title h2 {
        @include mobile {
            font-size: 18px !important;
        }
    }

    .bloc-explorer-lieux {
        margin-bottom: 115px;
        .splide__arrow {
            * {
                color: white;
            }
        }

        .lieux-tab {
            .credits {
                display: none;
            }
        }

        @include tablet_portrait {
            padding-top: 75px;
        }

        @include mobile {
            padding-top: 40px;
            padding-bottom: 60px;
            margin-bottom: 65px;
        }
    }

    .bloc-explorer-thematique {
        .splide__arrow {
            * {
                color: white;
            }
        }

        .splide__track {
            @include mobile {
                margin-top: 15px;
            }
        }
    }

    .bloc-explorer-cibles {
        padding: 110px 13vw 0;

        .cibles-tab {
            @include mobile {
                margin-top: 20px;
            }
        }

        @include tablet-portrait {
            padding: 150px 7vw 30px;
        }

        @include mobile {
            padding-top: 100px
        }
    }

    .bloc-relation-onglet-wrapper {

        * {
            color: white;
            margin-left: 0;
            margin-right: 0;
        }

        .content-wrapper {
            max-width: unset;

            .splide-relation-onglet-header {
                justify-content: space-between;

                .cta-desktop-container {
                    width: unset;

                    .relation-onglet-cta-desktop {
                        right: 13vw;
                        position: absolute;

                    }
                }
            }
        }

        .bloc-relation-onglet-title {
            padding: 0 13vw;
            margin: 0;
            padding-bottom: 28px;

            h2{
                font-size: 28px;
                font-weight: 500;
            }

            @include tablet_portrait {
                padding: 0 2vw;
            }
        }

        .bloc-relation-onglet {
            @include mobile {
                margin-top: 15px;
            }

            .relation-onglet-cta-desktop {
                right: 13vw;
            }
        }

        .splide__arrow {
            &--prev {
                right: 16vw !important;
            }
            &--next {
                right: 13vw !important;
            }
        }

        .splide__track {
            padding-left: 13vw !important;
            padding-right: 13vw !important;

            @include tablet_portrait {
                padding-left: 7vw !important;
                padding-right: 7vw !important;
            }

            .splide__slide {
                h3, .wrapper-cat > *, .wrapper-cat:before{
                    color: white;
                }
            }
        }

        .splide__pagination.class-pagination {
            padding-left: 13vw;
            max-width: 68vw;

            @include tablet_portrait {
                padding: 0;
                justify-content: center;
                max-width: 90vw;
            }
        }

        .card-exp .caption .card-exp__subtitle,
        .card-exp .caption .card-exp__subtitle::before {
            color: white;
        }

    }

    .bloc-slider-splide {
        padding-top: 110px;

        @include tablet_portrait {
            padding-top: 135px;
        }

        @include mobile {
            padding-top: 50px;
        }
    }
}
