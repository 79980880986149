.content-top-hub{
    padding: 80px 0 60px;
    @include clearfix();
    @include mobile{
        padding: 40px 0 30px;
    }
    figure{
        background: none;
        padding-left: 60px;
        margin-top: -30px;
        width: 100%;
        box-sizing: border-box;
        @include tablet_landscape{
            padding-left: 40px;
        }
        @include mobile{
            margin-top: 20px;
            padding-left: 0;
        }
    }
    .credits,figcaption{
        display: none;
    }
}