.page-template-page-listing-auto {
	
	.toolbar-listing {
		width: 100%;
		position: absolute;
		top: -175px;
		background-repeat: unset;
		transition: 0.5s ease all;
		max-height: 74px;

		&.visible {
			position: fixed;
			top: 80px;
			transition: 0.5s ease all;

			&:after {
				content: '';
				position: absolute;
				top: 74px;
				left: 0;
				right: 0;
				height: 95%;
				background: -moz-linear-gradient(top,  rgba(32,37,41,0.8) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top,  rgba(32,37,41,0.8) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom,  rgba(32,37,41,0.8) 0%,rgba(0,0,0,0) 100%);
				opacity: 0.1;
			}
		}

		&__inner {
			height: 74px;

			.col-right {

				width: 100%;
				margin-left: unset;
				justify-content: space-between;

				.facettes {

					max-width: 100px;

					& > *, #moteur-explore{
						text-align: left;
					}
				}

			}
		}

		@include mobile {
			display: none;
		}
	}

	.header-listing{

		@include mobile {
			padding-top: 15px;
			padding-bottom: 45px;
		}

		&__inner {

			@include mobile {
				margin: auto 20px !important;
				padding: unset;
				background-color: white;
				padding-bottom: 5px;
			}

		}

		&__description {
			height: 75px !important;

			&:after {
				content: '';
				position: absolute;
				top: 10px;
				left: 0;
				right: 0;
				height: 95%;
				background: -moz-linear-gradient(bottom,  $c_white 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(bottom,  $c_white 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to top,  $c_white 0%,rgba(0,0,0,0) 100%);
				opacity: 1;
			}

			&.expanded {

				&:after {
					opacity: 0;
					height: 0;
				}
			}
		}

		.btn-filters-mobile {
			display: none;

			&:after {
				content: '\e9ac';
			}

			@include mobile {
				display: block;
				position: absolute;
				right: 0;
				
				span {
					display: none;
				}
			}
		}

		hr {
			margin-bottom: 0;
			margin-top: 40px;

			@include mobile {
				margin-top: 0;
			}
		}
	}

	.cards-listing {

		@include mobile {
			margin-top: -15px;
		}

		.has-no-map {
			margin-left: unset;
			grid-template-columns: none;

			.close-map-filter-overlay {
				position: fixed;
				text-align: right;
				top: 80px;
				left: 0;
				width: 100vw;
				height: 74px;
				z-index: 100000000;
				background-color: $c_white;
				display: none;

				&:after {
					content: '';
					position: absolute;
					top: 74px;
					left: 0;
					right: 0;
					height: 65%;
					background: -moz-linear-gradient(top,  rgba(32,37,41,1) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top,  rgba(32,37,41,1) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom,  rgba(32,37,41,1) 0%,rgba(0,0,0,0) 100%);
					opacity: 0.5;
				}

				button {
					margin-right: 80px;
					margin-top: 18px;
					color: $c_white;
					background-color: $c_third;
					border: none;

					&:after {
						font-family: 'icomoon' !important;
						content: '\e940' !important;
						color: #fff;
						font-size: 15px;
						padding-bottom: 3px;
					}
				}
			}

			.search-map__container {
				position: fixed;
				width: calc(100vw - 500px);
				height: calc(100vh - 154px);
				top: 0;
				left: 0;
				z-index: 10000000;
				display: none;

				&.map-mobile {
					display: block;
					z-index: 100;
					height: 100vh;

					#map-listing {
						left: 0;
						top: unset;
						bottom: 160px;
						height: calc(100vh - 290px);
					}
				}

				.v-mobile-map {
					width: 100vw;
					position: fixed;
					bottom: 0;
					left: 0;
					z-index: 9999999;
					box-shadow: 0px 12px 19px 10px #0000009F;
					
					.splide-listing-auto {
						z-index: 1000;
						visibility: visible;
						background-color: $c_white;

						a {
							div:first-child {
								display: flex;
								width: 100%;

								figure, .no-photo {
									height: 70px;
									margin-right: 10px;
									border-radius: 5px;
								}

								.title-card {
									font-size: 11px;
									line-height: 14px;
								}

								.caption {
									margin-top: 0;

									.encart {
										display: none;
									}
								}
							}
						}
					}
				}

				.search-map__on-move {
					transform: unset;
					top: 45px;
					left: 45px;
				}
			}
		}
	}

	@include min-mobile {
		.pagination-archives-offres {
			width: 100%;
		}
	}

	.down-top {
		top: 154px !important;

		&.overlay-filtres {
			z-index: 10000000;
		}
	}
}