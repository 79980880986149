// base styles for input field, switch , select

.style-field {
	
	background: var(--bg-field);
	border-width: calculateRem(1px);
	border-style: solid;
	border-color: var(--bg-border-field);
	appearance: none;
	color: var(--color-text);
	font-size: var(--field-font-size);
	line-height: 1.5;
	font-weight: 400;
	transition: all ease-in-out 0.5s;
	border-radius: calculateRem(4px);
	width: -webkit-fill-available;
	padding: calculateRem(15px) calculateRem(24px);
	font-family: $f_txt;
	width: 100%;

	
	@include min-mobile {
		
		padding: calculateRem(18px) calculateRem(21px);
	}

	&:focus {
		border-color: var(--color-primary);
	}
}

@import "_form-field";
@import "_forgot-password";
@import "_input-see-pwd";
@import "_input-place";
@import "_input-geolocalisation";
@import "_switch";
@import "_label";
@import "_select";
@import "_checkbox-button";
@import "_checkbox-picture";
@import "_radio-picture";
@import "_autocomplete-list";
