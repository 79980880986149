.myex-card-product {
	display: flex;
	gap: calculateRem(11px);
	width: 100%;
	align-items: center;
	color: currentColor !important; //overwrite
	transition: all 700ms ease-in-out;
	@include card-img-hover();

	&:hover,
	&:focus {
		color: currentColor;
	}

	&__img {
		width: calculateRem(112px);
		border-radius: calculateRem(4px);
		flex-shrink: 0;
		align-self: flex-start;
		background: var(--bg-card-img);

		@include aspect-ratio(112px, 76px);
	}

	&__wrapper {
		flex-direction: column;
		display: flex;
		align-items: flex-start;
		flex-grow: 1;
		gap: calculateRem(3px);
		@include min-mobile {
			flex-direction: row;
			gap: 12%;
			align-items: center;
		}

		@include min-tablet_landscape {
			gap: 25%;
		}
	}

	&__content {
		max-width: calculateRem(320px);
		width: 100%;
	}

	&__meta {
		font-size: calculateRem(11px);
		color: var(--color-text-lighter);
		line-height: 1.5;
		text-transform: uppercase;
		letter-spacing: 0.04em;

		&__item {
			@include ellipsis(1);
		}
	}

	&__title {
		color: currentColor;
		font-size: calculateRem(11px);
		font-weight: 600;
		line-height: 1.3;
		margin: 0;
		@include ellipsis(2);

		@include min-mobile {
			font-size: calculateRem(14px);
		}
	}

	&__price {
		font-size: calculateRem(10px);
		flex-grow: 1;
		flex-shrink: 0;

		width: 100%;
		text-align: left;
		@include min-mobile {
			font-size: calculateRem(11px);
			max-width: 35%;
		}
		&__amount {
			font-size: calculateRem(13px);
			font-weight: bold;
			line-height: 1.5;
			display: inline-block;
			vertical-align: middle;

			@include min-mobile {
				font-size: calculateRem(16px);
			}
		}
	}

	&__buttons {
		.myex-card-product__seeCard {
			display: block;
			@include mobile {
				display: none;
			}
		}
	}
}
