figcaption{
    @include ft($f_txt, 15px, 400, $c_white, 28px);
    position: absolute;
    left:0;
    right: 0;
    bottom: 0;
    padding: 13px 20px;
    z-index: 100;
    text-align: left;
    &:before{
        content: '';
        @extend %center;
        top: auto;
        bottom: 0;
        height: 150px;
        opacity: 0.8;
        z-index: -1;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,202529+100&0+0,1+99 */
        background: -moz-linear-gradient(top,  rgba(32,37,41,0) 0%, rgba(32,37,41,1) 99%, rgba(32,37,41,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(32,37,41,0) 0%,rgba(32,37,41,1) 99%,rgba(32,37,41,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(32,37,41,0) 0%,rgba(32,37,41,1) 99%,rgba(32,37,41,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00202529', endColorstr='#202529',GradientType=0 ); /* IE6-9 */
    }
}

.credits {
	@include ft($f_txt, 12px, 300, $c_surtitre, 15px);
	position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
    box-sizing: border-box;
    text-align: left;
    //text-transform: uppercase;
    letter-spacing: 1.5px;
	margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	@include mobile{
        font-size: calculateRem(10px);
        line-height: calculateRem(12px);
		margin-top: 5px;
	}
}

%credit-absolute{
    padding: 10px;
    color: $c_white;
    top: auto;
    bottom: 0;
    right: auto;
}