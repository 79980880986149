// used for list region checkbox image
.myex-list-checkbox-image {
	gap: calculateRem(15px);
	display: grid;
	grid-template-columns: repeat(2, minmax(120px, 1fr));
	margin-top: calculateRem(10px);

	.myex-form-field {
		margin: 0;
	}

	@include min-mobile {
		grid-template-columns: repeat(3, minmax(120px, 1fr));
		gap: calculateRem(10px);
	}

	@include min-tablet_small {
		grid-template-columns: repeat(3, minmax(120px, 1fr));
	}
	@include min-tablet_landscape {
		grid-template-columns: repeat(4, minmax(120px, 1fr));
	}

	&:not(.myex-form-field) {
		@include min-desktop {
			grid-template-columns: repeat(6, minmax(120px, 1fr));
		}
	}
}
@media screen and (min-width: 1370px) {
	.myex-list-checkbox-image.myex-form-field {
		grid-template-columns: repeat(6, minmax(120px, 1fr));
	}
}