#create_collection__cta.hide {
	display: none;
}

.myex--card-collection-add {
	$self: &;
	display: flex;
	gap: calculateRem(10px);
	padding: calculateRem(8px) calculateRem(14px);
	margin: calculateRem(8px) calculateRem(-14px);
	// @include card-img-hover();
	cursor: pointer;

	&:hover {
		background: var(--bg-grey-light);
		color: var(--color-primary);

		//overlay img
		#{ $self }__avatar {
			&:not(.no-img) {
				color: var(--color-primary);
			}

			&:after {
				opacity: 0.8;
			}
		}
	}

	&__avatar {
		background: var(--color-primary);
		color: #fff;
		max-width: calculateRem(45px);
		flex-shrink: 0;
		border-radius: calculateRem(5px);
		width: 100%;
		@include aspect-ratio(45px, 45px);
		display: flex;
		align-items: center;
		justify-content: center;

		&__icon {
			position: absolute;
			font-size: calculateRem(14px);
			z-index: 1;
		}

		&:not(.no-img) {
			//overlay img
			&:after {
				content: "";
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: 0;
				opacity: 0.25;
				transition: all ease 500ms;
				background: #1e2529;
			}
		}
	}

	&__content {
		display: flex;
		align-items: center;
	}

	&__title {
		font-weight: 600;
		font-size: calculateRem(13px);
		@include ellipsis(2);
		margin: 0;
		line-height: 1;
		color: currentColor;
	}
}
