.myex-map__container {
	position: fixed;
	top: var(--header-height);
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	z-index: $-z-map;

	&--header {
		padding-top: calculateRem(32px);
		padding-bottom: calculateRem(32px);
		background-color: white;
		color: black;
		border-radius: calculateRem(14px) calculateRem(14px) 0 0;

		@include min-mobile {
			border-radius: 0;
		}

		.content-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
		}

		&-title {
			font-size: calculateRem(25px);
			font-weight: 600;
		}

		&-close-btn {
			cursor: pointer;

			span {
				text-transform: uppercase;
				font-size: calculateRem(12px);
				letter-spacing: 1.5px;
				display: flex;
				align-items: center;
				gap: calculateRem(5px);

				&.icon {
					font-size: calculateRem(14px);
				}
			}
		}
	}

	.search-map__on-move {
		translate: 50%;
		left: calculateRem(30px);
		top: calculateRem(140px);
	}

	#map-myex {
		position: relative;
		width: 100%;
		height: 100%;

		.leaflet-control-container {
			.leaflet-top {
				right: 0;
				left: auto;
			}

			@include mobile {
				display: none;
			}
		}

		.leaflet-popup-pane {
			.leaflet-popup-content {
				border-radius: 10px;
			}
			.custom-popup-thuria {
				@extend .bg--light;

				//card
				a {
					div {
						align-items: unset;

						.title-card {
							color: var(--color-text);
						}

						.classement {
							display: none;
						}

						.caption {
							position: relative;

							.card-offre-btn-add-favs {
								display: none;
							}
						}

						.metas-card {
							margin-top: 5px;

							.wrapper-cat {
								display: none;
							}

							.location {
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 1;
								line-height: 18px;
								max-height: 36px;
								padding-left: 12px;
							}
						}

						.encart-tarif {
							// position: absolute;
							// bottom: 0;
							// top: unset;
							// left: unset;
							// right: unset;
							margin-top: auto;

							.encart {
								left: -77px;
								right: unset;
								font-size: 9px;
								padding: 8px 7px 6px 7px;
								line-height: 1.2;
								top: 4px;
							}

							.tarif {
								background-color: transparent;
							}
						}
					}
				}
			}
		}
	}

	//map mobile
	.myex-map__mobile {
		width: 100vw;
		position: absolute;
		bottom: 0;

		//slider cards
		.splide-myex__slider {
			z-index: 1000;
			visibility: visible;
			background-color: $c_white;
			position: fixed;
			bottom: 0;
			width: 100vw;

			.splide__list {
				padding: calculateRem(10px) 0 !important;

				.card-my-explore {
					div:first-child {
						display: flex;
						width: 100%;
						gap: calculateRem(10px);

						figure,
						.no-photo {
							max-width: calculateRem(110px);
							height: calculateRem(76px);
							overflow: visible;
						}

						.myex-button--favorite {
							left: calculateRem(5px);
							bottom: calculateRem(5px);
						}

						.encart-tarif {
							overflow: visible;
							.tarif {
								position: absolute;
								bottom: calculateRem(2px);
								max-width: calculateRem(260px);
								background-color: transparent;
								width: calculateRem(155px);
								padding: 0;
								left: 100%;
								height: auto;
								margin-left: calculateRem(10px);

								.price {
									font-size: calculateRem(13px);
								}
							}
						}

						.myex-button-actions {
							display: none;
						}

						.caption {
							margin-top: 0;

							.metas-card {
								margin-bottom: 0;
							}

							.classement {
								display: none;
							}

							.location {
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 1;
								line-height: 18px;
								max-height: 36px;
							}

							.encart {
								display: none;
							}

							.title-card {
								font-size: calculateRem(11px);
								line-height: 1.2;
							}
						}
					}
				}
			}

			.return-div {
				text-align: center;
				padding: calculateRem(30px);
				border-top: 1px solid rgba(#abb3b9, 0.5);
				height: calculateRem(74px);

				span {
					text-decoration: underline;
					font-size: calculateRem(11px);
					color: $c_txt;
				}
			}
		}
	}
}
