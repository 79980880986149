.myex-register,
.myex-login,
.myex-forget-password {
	&__framed {
		max-width: calculateRem(960px);
		margin: 0 auto;
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: calculateRem(20px);

	}

	&__header {
		display: flex;
		flex-direction: column;
		gap: calculateRem(20px);
		max-width: calculateRem(420px);
		align-self: center;
		width: 100%;
		text-align: center;

		@include min-mobile {
			align-self: center;
			max-width: calculateRem(480px);
			padding-bottom: calculateRem(28px);
		}

		&__logo {
			display: flex;
			flex-direction: row;
			gap: calculateRem(10px);
			align-items: center;
			font-size: calculateRem(26px);

			@include min-mobile {
				flex-direction: column;
				font-size: calculateRem(57px);
			}

			img {
				max-width: 300px;

				@include mobile {
					max-width: 200px;
				}
			}
		}

		&__desc {
			font-size: calculateRem(15px);
			color: currentColor;
			@include mobile {
				display: none;
			}
		}

		&__notice {
			font-size: calculateRem(13px);
			color: currentColor;

			@include min-mobile {
				font-size: calculateRem(15px);
			}
		}
	}

	&__form {
		width: 100%;
		max-width: calculateRem(420px);

		.myex-button--submit {
			width: 100%;

			.myex-login-sign-in-loader, .myex-register-sign-up-loader {
				width: 25px;
				height: 25px;
				border: 3px solid #FFF;
				border-bottom-color: transparent;
				border-radius: 50%;
				display: none;
				box-sizing: border-box;
				animation: rotation 1s linear infinite;

				&.on-load {
					display: inline-block;
				}
			}

			@keyframes rotation {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}

			.myex-login-sign-in-text, .myex-register-sign-up-text {
				&.on-load {
					display: none;
				}
			}
		}
	}

	&__footer {
		text-align: center;
		flex-direction: column;
		display: flex;
		gap: calculateRem(10px);
		max-width: calculateRem(420px);
		width: 100%;

		p {
			margin: 0;
			line-height: 1;
			font-size: calculateRem(14px);
			color: currentColor;
		}
	}
}
