// Gradient
// ==========================================================================
.gradient-bottom {
    @extend %center;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,202529+100&0+0,1+100 */
    background: -moz-linear-gradient(top,  rgba(32,37,41,0) 0%, rgba(32,37,41,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(32,37,41,0) 0%,rgba(32,37,41,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(32,37,41,0) 0%,rgba(32,37,41,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00202529', endColorstr='#202529',GradientType=0 ); /* IE6-9 */
}

.gradient-left{
    @extend %center;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,202529+100&1+0,0+100 */
    background: -moz-linear-gradient(left,  rgba(32,37,41,1) 0%, rgba(32,37,41,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(32,37,41,1) 0%,rgba(32,37,41,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(32,37,41,1) 0%,rgba(32,37,41,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#202529', endColorstr='#00202529',GradientType=1 ); /* IE6-9 */
}

.gradient-bottom-black{
    @extend %center;
	background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
	background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}

.gradient-left-black {
    @extend %center;
	background: -moz-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
	background: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
	background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);
}

.gradient-header{
    @extend %center;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,202529+100&1+0,0+100 */
    background: -moz-linear-gradient(top,  rgba(32,37,41,1) 0%, rgba(32,37,41,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(32,37,41,1) 0%,rgba(32,37,41,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(32,37,41,1) 0%,rgba(32,37,41,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#202529', endColorstr='#00202529',GradientType=0 ); /* IE6-9 */
}