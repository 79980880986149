.myex-to-expand {
	position: relative;
	overflow-y: clip;
	transition: height ease 500ms;

	//button expand
	&__button {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		z-index: 20;
		bottom: 0;

		@include button-with-icon();
		@extend .myex-button--secondary;
		padding: calculateRem(14px);
		font-size: calculateRem(16px);
		line-height: 1;
		@include min-mobile {
			padding: calculateRem(21px);
		}

		&:before {
			@extend .icon-ico-plus;
		}
	}

	&--hide {
		//overlay to hide content
		&:before {
			content: "";
			background: linear-gradient(
				to bottom,
				transparent 0%,
				var(--current-bg) 100%
			);
			height: calculateRem(223px);
			position: absolute;
			left: 0;
			width: 100%;
			top: unset;
			bottom: 0;
			z-index: 10;
			pointer-events: none;
		}
	}
}
