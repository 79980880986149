// --------- Switch component
.myex-switch {
	display: flex;
	align-items: center;
	gap: calculateRem(15px);
	flex-direction: row;

	label {
		order: 2;
	}

	//label as small text
	&__text {
		font-weight: 400;
		font-size: calculateRem(9px);
		align-self: center;
		opacity: 0.8;
		@include min-mobile {
			font-size: calculateRem(11px);
		}

		a {
			color: var(--color-secondary);

			&:hover {
				color: var(--color-primary) !important;
			}
		}
	}

	&__container {
		position: relative;
		align-self: flex-start;
		line-height: 0;
		margin-top: 0;
	}

	//circle
	.myex-switch__slider {
		content: "";
		position: absolute;
		height: calculateRem(20px);
		width: calculateRem(20px);
		left: calculateRem(5px);
		top: calculateRem(5px);
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
		cursor: pointer;
		pointer-events: none;
	}

	input[type="checkbox"] {
		@extend .style-field;
		border-radius: calculateRem(90px);
		height: calculateRem(30px);
		width: calculateRem(60px);
		margin: 0;
		flex-shrink: 0;
		padding: calculateRem(4px);
		cursor: pointer;
		align-self: self-start;
		background: var(--bg-switch);
		border-color: var(--bg-border-switch);

		// ------- switch active state
		&:checked {
			background: var(--color-primary);
			+ .myex-switch__slider {
				-webkit-transform: translateX(28px);
				-ms-transform: translateX(28px);
				transform: translateX(28px);
			}
		}
	}
}
