.bloc-relation {
    @include desktop1200 {
        display: flex;
        flex-wrap: wrap;
    }

    .bloc-texte {
        width: 100%;

        > * {
            max-width: calc(100% - 320px) !important;
            @include desktop1200 {
                max-width: 900px !important;
            }
        }

        & ~ .map {
            margin-top: 30px;
            @include mobile {
                margin-top: 20px;
            }
        }

        & ~ .slider-item {
            margin-top: 30px;
            @include mobile {
                margin-top: 20px;
            }
        }

        .title-h3 {
            @include mobile {
                font-size: calculateRem(25px);
                line-height: calculateRem(30px);
                margin-bottom: 10px !important;
            }
        }
    }

    .onglets {
        & ~ .slider-item {
            margin-top: 0;
        }
    }

    .splide__list {
        height: auto !important;
    }

    .wrapper-slider {

        .scroll-mode {
            padding-bottom: 0;
            scrollbar-width: auto;
            scrollbar-color: white #ffffff;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: white;
                border-radius: 10px;
                border: 1px solid white;
              }
        }
    }

    .slider-item {
        @include tablet_portrait{
            display: flex;
            flex-wrap: wrap;
        }
        > .btn {
            box-sizing: border-box;
            position: absolute;
            width: auto;
            left: auto;
            right: calc((100% - #{$max-width}) / 2);
            margin-top: -95px;
            max-width: 320px;
            @include wide {
                right: calc((100% - #{$max-width-large}) / 2);
            }
            @include desktop {
                right: 0;
            }
            @include desktop1280{
                max-width: 280px;
            }
            @include tablet_landscape{
                right: 40px;
            }
            @include tablet_portrait{
                position: relative;
                order: 3;
                margin-bottom: $content-spacing/2;
                margin-top: 0;
                margin-right: auto;
                margin-left: 80px;
                right: 0;
                left: 0;
            }
            @include mobile{
                max-width: calc(100% - 80px);
                width: 100%;
            }

            .no-hover & {
                @include desktop1280{
                    right: 160px;
                }
                @include tablet_landscape{
                    right: 80px;
                }
                @include tablet_portrait{
                    right: 0;
                }
            }
        }
    }

    > .btn {
        position: absolute;
        width: auto;
        left: auto;
        right: calc((100% - #{$max-width}) / 2);
        margin-top: -50px;
        @include wide {
            right: calc((100% - #{$max-width-large}) / 2);
        }
        @include desktop {
            right: $border-gutter;
        }
        @include desktop1200 {
            position: relative;
            order: 3;
            left: auto;
            margin-left: auto;
            margin-top: 0;
            margin-bottom: $content-spacing/2;
        }
        @include tablet_landscape {
            right: 40px;
            margin-top: 0;
            margin-bottom: $content-spacing-tab/2;
        }
        @include mobile {
            right: 20px;
            left: 20px;
            margin-left: 0;
            margin-top: 0;
            width: calc(100% - 40px);
            margin-bottom: $content-spacing-mobile/2;
        }
    }

    .map {
        position: relative;
        z-index: 10;
        width: 100%;
        height: 500px;
        background: $c_grey;
        @include mobile {
            height: 260px;
        }

        & ~ .slider-item {
            margin-top: -160px;
            @include mobile {
                margin-top: 10px;
            }

            .th-slider {
                .no-hover & {
                    @include mobile {
                        scroll-padding: 10px;
                        padding-left: 10px;
                        padding-right: 10px;
                        margin: 0 20px;
                    }
                }
            }

            .card {
                @include mobile {
                    @include card-horizontale();
                    width: calc(100% - 20px);
                    &:last-child {
                        scroll-snap-align: start;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .nav {
        button {
            top: calc(360px / 2);
            @include tablet_landscape {
                top: calc(300px / 2);
            }
        }
    }

    .wrapper-slider {
        &[hidden] {
            display: none;
        }
    }

    .dots {
        position: static;
        display: flex;
        align-items: center;
        width: 100%;
        height: 1px;
        background-color: $c_light_txt;
        margin-top: 20px;
        margin-bottom: 20px;
        
        button {
            width: 100%;
            background-color: transparent;
            border-radius: 8px;
        }
    }

    .nav-container {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        width: 100%;

        @include mobile {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    .nav {
        display: flex;
        button {
            position: static;
            margin: 0;
            margin-bottom: 0;
            background: transparent;
            width: 46px;
            height: 46px;
            line-height: 46px;
            @include mobile{
                display: none !important;
            }

            &:not(.disabled) {
                &:hover, &:focus {
                    body:not(.no-hover) & {
                        background: transparent;

                        &:after {
                            color: $c_third;
                        }
                    }
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                right: 0;
                margin: 0;
                margin-bottom: -10px;
            }

            &:after {
                color: $c_titre;
            }
        }
    }   

    a.card-thematique, .card.edito-v2 {
        @include mobile{
            width: calc(67vw - 120px) !important;
            margin-right: 10px !important;
        }
    }

}


.onglets {
    position: relative;
    font-size: 0;
    white-space: nowrap;
    display: block;
    width: 100%;
    margin-bottom: 20px !important;
    padding-top: 20px;
    @include clearfix();
    @include desktop1200 {
        margin-bottom: 10px;
    }
    @include mobile {
        padding-top: 0;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 8px;
        width: 100%;
        height: 1px;
        background: rgba($c_border, 0.5);
        @include tablet_portrait{
            width: calc(100% + 40px);
        }
        @include mobile {
            left: 0;
            bottom: 0;
            width: 100%;
        }
    }

    &.wrapper-slider {
        max-width: calc(1440px - 520px);
        margin-left: calc((100% - 1440px + 160px) / 2);
        overflow: hidden;

        width: 100%;

        @include wide{
            margin-left: calc((100% - 1440px) / 2);
        }
        @include desktop {
            max-width: calc(100% - 360px - 160px - 80px);
            margin-left: 80px;
        }
        @include desktop1280{
            max-width: calc(100% - 280px - 160px - 40px);
        }
        @include tablet_landscape{
            margin-left: 40px;
        }
        @include tablet_portrait{
            max-width: 100%;
            overflow: visible;
        }
        @include mobile{
            margin-left: 20px;
        }
    }

    .th-slider {
        padding-bottom: 8px;
        margin-left: 80px;
        @include tablet_landscape {
            margin-left: 40px;
        }
        @include mobile {
            margin-left: 0;
        }

        > * {
            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: $border-gutter;
                @include tablet_landscape {
                    margin-right: 40px;
                }
                @include mobile {
                    margin-right: 0;
                }
            }
        }
    }

    button {
        @include ft($f_txt, 20px, 600, $c_txt, 24px);
        @extend %appearance;
        background: none;
        letter-spacing: 0.2px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 0 17px;
        padding: 20px 0;
        @include addTransition();
        @include tablet_landscape {
            font-size: calculateRem(20px);
            line-height: calculateRem(20px);
        }
        @include mobile {
            font-size: calculateRem(17px);
            margin: 0 10px;
            padding: 15px 0 8px;
        }

        &:hover, &:focus {
            body:not(.no-hover) & {
                cursor: pointer;
                color: $c_third;
                font-weight: 500;
            }
        }

        &[aria-selected="true"] {
            color: $c_third;
            font-weight: 500;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: $c_third;

                transform: translate(-50%, 50%);

                @include mobile {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
}