.myex-popins {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	z-index: $-z-popins;
	padding-left: 0;
	padding-right: 0;
	height: 100dvh;

	--marge-side--popins: #{calculateRem(15px)};

	@include min-mobile {
		--marge-side--popins: #{calculateRem(50px)};
	}

	@include min-mobile {
		overflow: auto;
		/*position: fixed;*/
		align-items: flex-start;
		padding-left: var(--side-padding-left);
		padding-right: var(--side-padding-right);
		padding-top: calc(var(--marge-content-top) + var(--header-height));
		padding-bottom: calculateRem(45px);
	}

	&.is-visible {
		display: block;
	}

	//backdrop
	&__background {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: #1e2529;
		opacity: 0.5;
		z-index: -1;
		height: 100dvh;
	}
}

.myex-popin {
	@extend .bg--light;
	position: absolute;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	bottom: 0;
	border-radius: calculateRem(14px) calculateRem(14px) 0 0;
	max-height: 85dvh;
	overflow: inherit;
	background: transparent;

	@include min-mobile {
		max-width: calculateRem(960px);
		border-radius: calculateRem(15px);
		bottom: unset;
		max-height: unset;
		position: relative;
	}

	&__close {
		position: absolute;
		right: var(--marge-side--popins);
		top: calculateRem(20px);
		cursor: pointer;
		transition: all ease 500ms;
		@extend .icon-ico-close;
		@include font();
		font-size: calculateRem(15px);

		@include min-mobile {
			top: calculateRem(30px);
			right: calculateRem(30px);
		}

		&:hover {
			opacity: 0.5;
		}
	}

	&__top {
		padding: calculateRem(20px) calc(var(--marge-side--popins) + 20px)
			calculateRem(20px) var(--marge-side--popins);
		width: 100%;
		z-index: 1;
		position: sticky;
		top: 0;
		background: var(--current-bg);
		border-radius: calculateRem(14px) calculateRem(14px) 0 0;

		@include min-mobile {
			padding: calculateRem(40px) var(--marge-side--popins) calculateRem(40px);
		}
	}

	&__scroller {
		// overflow: scroll;
		// max-height: calc(100dvh - 300px);
		padding: 0 var(--marge-side--popins);
		width: 100%;
		background: var(--current-bg);

		@include min-mobile {
			max-height: unset;
			overflow: visible;
		}

		a {
			cursor: pointer;
		}
	}

	&__form {
		justify-content: center;
	}

	&__bottom {
		width: 100%;
		padding: calculateRem(20px) var(--marge-side--popins) calculateRem(16px)
			var(--marge-side--popins);

		position: sticky;
		bottom: 0;
		background: var(--current-bg);

		@include min-mobile {
			padding: calculateRem(40px) var(--marge-side--popins) calculateRem(40px);
		}
	}

	.myex-confetti {
		position: absolute;
		z-index: 2;
		translate: -50% -50%;
		left: 50%;
		top: 50%;
		pointer-events: none;
		width: 100%;
		height: 100%;
	}

	// when popin have a form
	&--form {
		width: 100%;

		.myex-popin {
			&__title {
				font-size: calculateRem(18px);
				font-weight: 600;
				color: currentColor;
				line-height: 1.1;
				margin: 0;

				@include min-mobile {
					font-size: calculateRem(28px);
					text-align: center;
				}
			}

			&__buttons {
				margin-left: auto;
				margin-right: auto;
				align-items: center;
				justify-content: center;
				display: flex;
				flex-direction: row;
			}
		}
	}

	// when popup is big info (congrats, thanks"
	&--infos {
		.myex-popin {
			&__top {
				padding: calculateRem(24px) var(--marge-side--popins) calculateRem(10px);

				@include min-mobile {
					padding-top: calculateRem(40px);
				}
			}

			&__icon {
				margin-left: auto;
				margin-right: auto;
				text-align: center;
				font-size: calculateRem(30px);
				color: #dea99e;
				display: block;

				&.myex-logo-icon {
					color: #df697d;
				}

				@include min-mobile {
					font-size: calculateRem(40px);
				}
			}

			&__title {
				font-size: calculateRem(50px);
				margin-top: calculateRem(20px);
				margin-bottom: 0;
				color: currentColor;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
				line-height: 1;

				@include min-mobile {
					font-size: calculateRem(68px);
				}
			}

			&__text {
				font-size: calculateRem(13px);
				line-height: 1.73;
				margin-bottom: calculateRem(20px);
				padding-left: 0;
				padding-right: 0;
				text-align: center;

				@include min-mobile {
					font-size: calculateRem(15px);
					padding-left: calculateRem(40px);
					padding-right: calculateRem(40px);
				}
			}

			&__buttons {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}

	&.myex-popin--welcome {
		.myex-popin--new-logo {
			text-align: -webkit-center;

			img {
				max-width: 150px;
			}
		}
	}

	&--add-lieu {
		.myex-bloc-lieu-loader {
			width: 25px;
			height: 25px;
			border: 3px solid #FFF;
			border-bottom-color: transparent;
			border-radius: 50%;
			display: none;
			box-sizing: border-box;
			animation: rotation 1s linear infinite;

			&.on-load {
				display: inline-block;
			}
		}

		.myex-bloc-lieu-text {
			&.on-load {
				display: none;
			}
		}
	}
}
