.myex-listing {
	&__header {
		display: flex;
		align-items: self-end;
		margin-bottom: calculateRem(20px);
		justify-content: space-between;
		align-items: center;
		@include min-mobile {
			margin-bottom: calculateRem(40px);
		}
	}

	&__title {
		font-size: calculateRem(25px);
		font-weight: 600;
		color: currentColor;
		line-height: 1.2;
		margin: 0;

		@include min-mobile {
			font-size: calculateRem(30px);
		}

		@include min-tablet_small {
			font-size: calculateRem(40px);
		}
	}

	&__categories {
		display: flex;
		gap: calculateRem(10px);

		&__item {
			font-size: alculateRem(16px);
			font-weight: 600;
			display: flex;
			gap: calculateRem(5px);
			cursor: pointer;
			align-items: center;
			@extend .icon-tag-close;
			transition: all ease 500ms;

			&:hover {
				opacity: 0.5;
			}

			&:before {
				@include font();
				order: 2;
				color: var(--color-text-lighter);
				font-size: calculateRem(8px);
			}
		}
	}

	&__list {
		display: grid;
		column-gap: calculateRem(26px);
		row-gap: calculateRem(20px);
		grid-template-columns: 1fr;

		@include min-mobile {
			grid-template-columns: repeat(3, 1fr);
		}

		//min 768px
		@include min-tablet_small {
			grid-template-columns: repeat(3, 1fr);
		}

        //980px
		@include min-tablet_portrait {
			grid-template-columns: repeat(2, 1fr);
			row-gap: calculateRem(40px);
		}

        //1024px
		@include min-tablet_landscape {
			grid-template-columns: repeat(3, 1fr);
		
		}

		@include min-desktop {
			grid-template-columns: repeat(4, 1fr);
			row-gap: calculateRem(40px);
		}
	}
}
