#footer {
	position: relative;
	z-index: 5;
	background-color: $c_greydark;
	padding-top: 50px;

	@include tablet_landscape {
		margin-top: $border-gutter;
	}
	@include mobile {
		margin-top: 40px;
		padding-top: 22px;
	}

	.title-footer {
		@include ft($f_txt, 20px, 700, $c_white, 24px);
		text-transform: uppercase;
		display: block;
		margin: 0 0 20px 0;
	}

	.footer-bottom {
		background: $c_white;
	}
}

// Newsletter
// ==========================================================================
.footer-newsletter,
.footer-luxe-contact,
.footer-mice-contact {
	position: relative;
	display: flex;
	align-items: center;
	@extend %appearance;
	width: 100%;
	background: none;
	padding: 0;
	@include desktop {
		width: calc(100% - 160px);
	}
	@include tablet_landscape {
		width: calc(100% - 80px);
	}
	@include tablet_portrait {
		display: block;
	}
	@include mobile {
		position: static;
		width: calc(100% - 40px);
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;

			.cta-newsletter {
				background: $c_main;
				color: $c_white;
			}
		}
	}

	.txt {
		position: relative;
		width: 100%;
		display: block;
		box-sizing: border-box;
		text-align: left;
		background: $c_white;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		border: 1px solid rgba($c_border, 0.5);
		border-right: none;
		padding: 37px 120px;
		flex: 1;
		@include tablet_portrait {
			padding: 30px 20px 50px;
			text-align: center;
			border: 1px solid rgba($c_border, 0.5);
			border-radius: 4px;
		}

		&:before {
			content: "\e921";
			@include font();
			position: absolute;
			top: 50%;
			left: 45px;
			font-size: 34px;
			margin-top: -17px;
			color: $c_main;
			@include tablet_portrait {
				position: relative;
				top: 0;
				left: auto;
				margin: auto;
				display: block;
				margin-bottom: 10px;
			}
		}

		.title {
			@include ft($f_txt, 25px, 600, $c_greydark, 24px);
			display: block;
		}

		.p {
			@include ft($f_txt, 15px, 400, $c_greydark, 20px);
			margin: 0;
			@include mobile {
				font-size: calculateRem(13px);
				line-height: calculateRem(18px);
			}
		}
	}

	.cta-newsletter,
	.cta-luxe-contact,
	.cta-mice-contact {
		@include ft($f_txt, 18px, 700, $c_white, 20px);
		display: block;
		padding: 50px 100px;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		background: $c_sub;
		@include addTransition();
		@include tablet_portrait {
			padding: 20px;
			width: calc(100% - 40px);
			display: block;
			box-sizing: border-box;
			margin: auto;
			position: relative;
			z-index: 50;
			margin-top: -30px;
			text-align: center;
			border-radius: 4px;
		}
		@include mobile {
			width: calc(100% - 20px);
		}

		&:hover, &:focus {
			body:not(.no-hover) & {
				background: $c_main;
				color: $c_white;
			}
		}
	}
}


// Conseils
// ==========================================================================
.footer-conseils {
	margin-top: 5px;
	padding-bottom: 60px;
	@include tablet_portrait {
		margin-top: 0;
		padding-bottom: 30px;
	}
	@include mobile {
		padding: 0 20px 30px 20px;
	}

	> * {
		position: relative;
		margin: 30px 0;
		@include mobile {
			margin: 25px 0;
			text-align: center;
		}

		&:last-child {
			margin-bottom: 0
		}
	}

	.big-title {
		@include ft($f_txt, 60px, 600, $c_white, 60px);
		display: block;
		letter-spacing: 0.2px;
		text-transform: none;
		@include mobile {
			font-size: calculateRem(25px);
			line-height: calculateRem(25px);
			font-weight: 600;
		}
	}

	.wrapper-top-conseils {
		margin-bottom: 90px;
		@include mobile {
			margin-top: 15px;
			margin-bottom: 20px;
		}

		p {
			@include ft($f_txt, 28px, 600, $c_third, 28px);
			display: block;
			padding: 0;
			letter-spacing: 0.5px;
			@include mobile {
				font-size: calculateRem(16px);
				line-height: calculateRem(20px);
				margin-top: 2px;
			}
		}
	}

	[class^="icon-"], [class*=" icon-"] {
		position: absolute;
		left: 31px;
		top: -65px;
		font-size: 50px;
		color: white;
		@include tablet_portrait {
			font-size: 40px;
		}
		@include mobile {
			position: relative;
			top: 0;
			left: auto;
			font-size: 35px;
			display: block;
			margin: auto;
			margin-bottom: 15px;
		}
	}

	.title {
		@include ft($f_hx, 20px, 600, $c_white, 26px);
		display: block;
		margin-bottom: 10px;
		@include tablet_portrait {
			font-size: calculateRem(20px);
			line-height: calculateRem(20px);
		}
		@include mobile {
			font-size: calculateRem(16px);
			line-height: calculateRem(16px);
			padding-left: 0;
		}
	}

	p {
		@include ft($f_txt, 14px, 400, $c_white, 24px);
		margin: 0;
		@include mobile {
			font-size: calculateRem(12px);
			line-height: calculateRem(22px);
			padding-left: 0;
		}

		a {
			color: $c_white;
			text-decoration: underline;

			&:hover, &:focus {
				body:not(.no-hover) & {
					color: $c_third;
				}
			}
		}
	}

}


// Menu footer
// ==========================================================================
.footer-menu {
	padding: 30px 0 0;
	@include mobile {
		padding: 0;
	}

	> * {
		margin: 40px auto;
		@include tablet_portrait {
			margin: 20px auto;
		}
		@include mobile {
			margin: 0;
		}

		&:nth-child(3) {
			@include tablet_portrait {
				clear: left;
			}
		}
	}

	.accordeon {
		@include tablet_portrait {
			width: 50%;
			&.footer-partenaires {
				width: 100%;
			}
		}
		@include mobile {
			width: 100%;
			border-bottom: 1px solid rgba($c_border, 0.5);
		}

		&.open {
			.title-accordeon {
				@include mobile {
					&:after {
						transform: rotate(180deg);
					}
				}
			}
		}

		li a {
			font-size: 13px !important;
			color: #1E2529 !important;
			line-height: 18px !important;

		}
	}

	.title-accordeon {
		@include ft($f_hx, 20px, 500, #1E2529, 30px);
		@extend %appearance;
		padding: 0;
		background: none;
		position: relative;
		display: block;
		margin: 0 0 13px 0;
		text-align: left;
		
		@include mobile {
			width: 100%;
			box-sizing: border-box;
			margin: 0;
			font-size: calculateRem(16px);
			line-height: calculateRem(24px);
			font-weight: 600;
			padding: 27px 40px 23px 20px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&:after {
				content: "\e900";
				@include font();
				font-size: 8px;
				color: $c_titre;
				position: absolute;
				top: 50%;
				right: 20px;
				margin-top: -4px;
				@include addTransition();
			}
		}
		@include mobile_small {
			padding: 27px 0 23px 0;
			font-size: calculateRem(14px);
			line-height: calculateRem(20px);
			&:after {
				right: 0;
			}
		}
	}

	p {
		@include ft($f_txt, 15px, 400, $c_white, 24px);
	}


	.item {
		&.anim {
			@include mobile {
				@include addTransition();
			}
		}

		@include mobile {
			padding: 0;
			max-height: 0;
			overflow: hidden;
		}

		&.footer-contact {
			> * {
				@include mobile {
					margin-left: 20px;
					margin-right: 20px;
					&:last-child {
						margin-bottom: 40px;
					}
				}
			}

			figure {
				background: none;
				margin-bottom: 20px;
			}

			.adresse > p {
				font-weight: 300;
				line-height: 22px;
				font-size: 14px;
				color: #1E2529;

				strong {
					color: $c_dark;
				}
			}
		}
	}

	.menu-col {
		padding-right: 20px;
		@include mobile {
			padding: 0 20px 40px;
		}
		@include mobile_small {
			padding: 0 0 40px;
		}

		li {
			a {
				@include ft($f_txt, 13px, 400, rgba($c_txt_lien, 0.6), 20px);
				display: block;
				padding: 6px 0;
				@include addTransition();

				&:hover, &:focus {
					body:not(.no-hover) & {
						color: $c_main;
						padding-left: 10px;
					}
				}

				span {
					font-size: 40px;
				}
			}
		}
	}

	.title-bold {
		@include ft($f_txt, 15px, 600, $c_txt, 18px);
		display: block;
		margin-bottom: 3px;
	}

	address {
		@include ft($f_txt, 15px, 300, $c_surtitre, 18px);
		margin-bottom: 30px;

		.title-bold {
			margin-bottom: 13px;
		}
	}

	.btn {
		margin-top: 10px;
	}
}

// Réseaux sociaux
// ==========================================================================
.social-networks {
	position: relative;
	margin-bottom: 25px;
	margin-top: 25px;
	@include clearfix();

	li {
		float: left;

		&:first-child {
			a {
				padding-left: 0;
			}
		}

		&:last-child {
			a {
				padding-right: 0;
			}
		}

		a {
			display: block;
			color: $c_greydark;
			font-size: 18px;
			padding: 0 8px;
			@include addTransition();

			&:hover, &:focus {
				body:not(.no-hover) & {
					color: $c_main;
				}
			}

			span::before {
				font-size: 17px !important;
			}
		}
	}
}


// Partenaires
// ==========================================================================
.footer-partenaires {
	box-sizing: border-box;
	clear: both;
	float: none;
	margin: 5px 0;
	border-top: 1px solid #ABB3B9;
	@include clearfix();
	@include mobile {
		margin: 0;
		border-top: 0;
	}

	&.open {
		.item {
			max-height: 100% !important;
			height: 100% !important;
		}
	}

	.wrapper-logos {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		@include clearfix();
		@include mobile {
			justify-content: center;
			padding-bottom: 40px;
		}
	}

	.footer-logo {
		margin: 0 15px;
		@include desktop1280 {
			max-width: 120px;
		}
		@include mobile {
			margin: 0 5px;
			//   display: inline-block;
		}

		&:first-child {
			margin-left: 0;
			@include mobile {
				margin-left: 5px;
			}
		}

		figure {
			background: none;

			img {
				height: auto;
				opacity: 0.5;
			}
		}

		.credits, figcaption {
			display: none;
		}
	}
}


// Footer - Europe
// ==========================================================================
.footer-europe {
	display: flex;
	align-items: center;
	clear: both;
	float: none;
	margin: 20px auto;
	@include mobile {
		flex-direction: column;
		margin-bottom: 0;
	}

	figure {
		background: none;
		margin-right: 20px;
		flex: none;
		@include mobile {
			width: 80px;
		}

		img {
			height: auto;
		}
	}

	.credits, figcaption {
		display: none;
	}

	p {
		@include ft($f_txt, 14px, 400, #1E2529, 22px);
		margin: 0;
		max-width: 640px;

		@include mobile {
			text-align: center;
			margin: 12px 12px 0 12px;
			line-height: 16px;
			font-size: 12px;
		}
	}

	a {
		text-decoration: underline;
	}
}


// Menu Copyright
// ==========================================================================
.menu-legal {
	position: relative;
	clear: both;
	padding: 10px 0 30px 0;
	@include clearfix();
	@include tablet_landscape {
		padding: 20px 0 30px 0;
	}

	.social-networks {
		display: none;
		@include mobile {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 25px;
		}

		li {
			a, span {
				width: auto;
				display: block;
				color: $c_greydark;
				font-size: 18px;
				padding: 0 8px;
				margin: 0;
				@include addTransition();

				&:hover, &:focus {
					body:not(.no-hover) & {
						color: $c_main;
					}
				}

				span {
					padding: 0;

					&::before {
						@include mobile {
							font-size: 24px !important;
						}
					}
				}
			}
		}
	}

	.menu-copyright {
		float: right;
		@include clearfix();
		@include tablet_landscape {
			float: none;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

		li {
			float: left;
			@include mobile {
				float: none;
				display: block;
				width: 100%;
			}

			&:first-child {
				a {
					&:before {
						content: none;
					}
				}
			}

			&:last-child {
				a {
					margin-right: 0;
				}
			}

			a {
				&:before {
					content: '-';
					margin-right: 4px;
					@include mobile {
						content: none;
					}
				}
			}
		}
	}

	span, a {
		@include ft($f_txt, 12px, 400, $c_light_txt, 24px);
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin: 0 4px 0 0;
		padding: 10px 0;
		@include mobile {
			width: 100%;
			color: rgba($c_txt, 0.5);
			font-size: calculateRem(12px);
			line-height: calculateRem(18px);
			text-align: center;
			padding: 6px 0;
			margin: 0;
		}
	}

	span {
		@include tablet_landscape {
			display: block;
			text-align: center;
			padding: 0;
		}
		@include mobile {
			margin-bottom: 15px;
		}
	}

	a {
		&:hover, &:focus {
			body:not(.no-hover) & {
				color: $c_txt;
			}
		}
	}
}

.logo-header {
	figure {
		background: transparent;
	}
}