.bloc-relation-explorateur {

    .splide__slide {
        width: calc(220px) !important;
    }

    .container-relation-cta-mobile {
        display: none;
    }

    @include mobile {

        .container-relation-cta-mobile {
            display: block;
            margin-top: 15px;

            .slider-relation-cta-mobile {
                width: auto;
            }
        }

        .cta-desktop-container {
            display: none;
        }
    }
}