body:not(.no-hover) .overlay.overlay-univers .close-overlay {
	&:focus, &:hover {
		color: $c_gold_luxe;
	}
}

.overlay-univers {
	max-width: 100%;
	z-index: 10000000001;
	background-color: $c_dark_grey;

	.close-overlay {
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		left: 50%;
		text-align: center;
		color: $c_white;
		cursor: pointer;
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		&:after {
			margin-left:0;
		}

		@include tablet_portrait {
			font-size: 12px;
		}
	}

	.gradient-deco {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		pointer-events: none;
	}

	> .container {
		padding-top: 120px;
		display: flex;
		max-height: 100%;
		height: 100%;
		flex-wrap: nowrap;
		flex-direction: column;
		align-content: center;
		justify-content: center;

		@include tablet_portrait {
			overflow-y: scroll;
			justify-content: flex-start;
		}

		&.show-gradient {
			&:after {
				opacity: 1;
			}
		}

		.univers {

			width: 80%;
			margin: 0 auto;

			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			@include tablet_portrait {
				max-width: 300px;
				text-align: center;
			}

			&:after {
				content: "";
				display: block;
				height: 4px;
				background-color: #707070;
				position: absolute;
				top: 50%;
				transform: translate(-50%,-4px);
				left: 50%;
				width: 0;
				opacity: 0;
				pointer-events: none;
				transition: 400ms linear all;
			}


			.titre {
				display: block;
				@include ft($f_txt, 26px, 200, $c_white, 34px);
				letter-spacing: 0.2px;
				background:$c_dark_grey;
				padding: 0px 15px;
				position: relative;
				z-index: 9;
				transition: 200ms linear all;
			}

			&:hover {
				.titre {
					color: $c_gold_luxe;
				}
				&:after {
					opacity: 1;
					width: 100%;
				}
			}

			& + .univers {
				margin-top:20px;
			}

		}

	}
}