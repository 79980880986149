// NOTE: dans ce fichier on a tous les overlays liés à l'évolution "luxe" : ajout d'un overlay avec carte interactive, d'un overlay contact et d'un overlay presta

.page-template-landing-luxe-thematique, .page-template-landing-luxe-generale {
	.overlay-luxe-presta {
		.top {
			background-color: transparent;
			align-items: flex-end;
			justify-content: flex-end;

			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 30px;
				background: linear-gradient(to top, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 99%, rgba(32, 37, 41, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				position: absolute;
				top: 100%;
				left: 0;
				pointer-events: none;
			}

			.title-overlay {
				color: $c_light_txt;
			}

			.close-overlay {
				color: $c_white;
				padding: 38px 40px 0 40px;
			}
		}


		&__content {
			display: flex;
			justify-content: center;
			align-items: stretch;
			height: 100%;

			&__gallery {
				position: relative;
				flex-grow: 1;
				height: 100%;
				overflow: hidden;

				.swiper {
					height: 100%;
				}

				.swiper-slide {
					position: relative;

					&:before {
						content: '';
						@extend %center;
						top: auto;
						bottom: 0;
						height: 150px;
						opacity: 0.8;
						/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,202529+100&0+0,1+99 */
						background: -moz-linear-gradient(top, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 99%, rgba(32, 37, 41, 1) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(top, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 99%, rgba(32, 37, 41, 1) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to bottom, rgba(32, 37, 41, 0) 0%, rgba(32, 37, 41, 1) 99%, rgba(32, 37, 41, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00202529', endColorstr='#202529', GradientType=0); /* IE6-9 */
						z-index: 1;
						pointer-events: none;
						@include tablet_portrait {
							display: none;
						}
					}

					&::after {
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						height: 75px;
						width: 100%;
						z-index: 1;
						/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+90&1+0,0+90 */
						background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba($c_greydark, 1) 90%); /* FF3.6-15 */
						background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba($c_greydark, 1) 90%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba($c_greydark, 1) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0); /* IE6-9 */
						pointer-events: none;
						display: none;
						@include mobile {
							display: block;
						}
					}
				}

				.fit-cover {
					height: 100%;
				}

				.swiper-interact-container {
					@media (hover: none) {
						position: absolute;
						bottom: 40px;
						right: 40px;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
					}
					@include tablet_landscape {
						display: flex;
						left: 40px;
					}
				}

				.swiper-button-prev,
				.swiper-button-next {
					&:after {
						content: none;
					}

					@media (hover: none) {
						position: relative;
						left: auto;
						right: auto;
						margin-top: 0;
						margin: 0 20px;
						border: solid 4px $c-gold_luxe;
						border-radius: 50%;
						padding: 30px;
						color: $c_white;
					}

					@media (hover: hover) {
						height: 100%;
						width: 50%;
						cursor: none !important;
						transform: translateY(-50%);
						background-color: transparent;

						&:after {
							content: "";
							display: block;
							width: 466px;
							height: 100%;
							background-repeat: no-repeat;
							background-size: cover;
							position: absolute;
							top: 0;
							z-index: 10;
							opacity: 0;
							pointer-events: none;
							@include addTransition();
						}

						&:hover, &:focus {
							body:not(.no-hover) & {
								&:after {
									opacity: 0.60;
								}
							}
						}

						span.icon {
							display: none;
						}
					}

					@include tablet_landscape {
						width: 50px;
						height: 50px;
						flex-shrink: 0;
						padding: 0;
						margin-right: 0;
					}
				}

				.swiper-button-prev {
					@media (hover: hover) {
						left: 0;

						&:after {
							background-image: url("../images/deco/overlay-presta-gradient-left.svg");
							left: 0;
						}
					}

				}

				.swiper-button-next {
					@media (hover: hover) {
						right: 0;

						&:after {
							background-image: url("../images/deco/overlay-presta-gradient-next.svg");
							right: 0;
						}
					}
				}

				.swiper {
					.swiper-pagination {
						@media (hover: none) {
							position: relative;
							bottom: auto;
							left: auto;
							right: auto;
						}

						@media (hover: hover) {
							bottom: 46px;
							right: 40px;
							left: auto;
							text-align: right;
							@include mobile {
								right: 20px;
								padding-right: 0;
								bottom: 20px;
							}
						}
					}

				}

				.swiper-pagination-bullet {
					background-color: $c_white !important;
					opacity: 1 !important;
					border-radius: 0;
					width: 4px;
					height: 4px;
					transition: all 0.2s ease-in;
					margin: 0 2.5px !important;
				}

				.swiper-pagination-bullet-active {
					background-color: $c_gold_luxe !important;
					width: 20px;
				}

				figcaption {
					&:before {
						display: none;
					}
				}

				.credits {

					@include tablet_landscape {
						left: 20px !important;
						bottom: 20px !important;
						max-width: 50%;
					}
				}

				@include tablet_portrait {
					position: relative;

					.swiper-pagination {
						display: inherit;
						z-index: 99;
						text-align: right;
						padding-right: 20px;
					}

					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						height: 75px;
						width: 100%;
						z-index: 0;
						opacity: 0;
						/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+90&1+0,0+90 */
						background: -moz-linear-gradient(top, rgba($c_greydark, 1) 0%, rgba($c_greydark, 0) 90%); /* FF3.6-15 */
						background: -webkit-linear-gradient(top, rgba($c_greydark, 1) 0%, rgba($c_greydark, 0) 90%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to bottom, rgba($c_greydark, 1) 0%, rgba($c_greydark, 0) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00ffffff', GradientType=0); /* IE6-9 */
						pointer-events: none;
					}
				}
			}

			&__info {
				position: relative;
				width: 500px;
				flex-shrink: 0;

				// Indique que le contenu est scrollable
				&.is-overflow {
					// gradient
					&:before {
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						z-index: 20;
						height: 150px;
						background-image: linear-gradient(to top, rgba(black, 100%), rgba(black, 0%));
						opacity: 0.75;
						pointer-events: none;
					}
				}

				// Indique que le contenu est scrollable
				&.has-scroll-arrow {
					.scroll-arrow {
						visibility: initial;
						opacity: initial;
					}
				}

				.scroll-arrow {
					position: absolute;
					bottom: 40px;
					left: 50%;
					z-index: 30;
					transform: translateX(-50%);
					display: flex;
					justify-content: center;
					align-items: center;
					width: 50px;
					height: 50px;
					color: #B49B4E;
					font-size: 20px;
					background-color: black;
					border-radius: 50%;
					visibility: hidden;
					opacity: 0;
					@include addTransition();

					@include mobile {
						bottom: 80px;
					}
				}

				&:after {
					content: "";
					display: block;
					width: 97px;
					height: 215px;
					background-color: rgba($c_white, 0.10);
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					z-index: -1;
					pointer-events: none;
				}

				.content {
					padding: 40px;
					-ms-overflow-style: none; /* IE and Edge */
					scrollbar-width: none; /* Firefox */
					&::-webkit-scrollbar {
						display: none;
					}

					&::after {
						content: none;
					}

					.city-location {
						display: block;
						margin-top: 5px;
					}
				}


				> .content {
					padding: 40px 40px 100px 40px;

					@include min-tablet_portrait {
						height: calc(100% - 70px);
					}
				}
			}





			&__header {
				margin-bottom: 30px;

				.title {
					@include ft($f_txt, 25px, 200, $c_white, 25px);
					letter-spacing: 0.5px;
				}

				.city-location {
					@include ft($f_txt, 12px, 300, $c_gold_luxe, 18px);
					margin-bottom: 16px;
					letter-spacing: 1.8px;
					text-transform: uppercase;
				}
			}

			.quick-menu-container {
				@include quick-container();
				display: none !important;
				@include mobile {
					display: inline-flex !important;
				}
			}

			.text-bloc {
				@include ft($f_txt, 15px, 200, $c_white, 28px);
				letter-spacing: 0.3px;
				opacity: 0.75;
				margin-bottom: 68px;
			}

			.contact-title {
				@include ft($f_txt, 15px, 600, $c_white, 24px);
				letter-spacing: 0.3px;
				text-transform: uppercase;
			}

			.contact-address {
				@include ft($f_txt, 15px, 200, $c_white, 28px);
				letter-spacing: 0.3px;
				opacity: 0.75;
				margin-bottom: 30px;
			}

			.contact-cta {
				display: flex;
				justify-content: space-between;
				margin-bottom: 30px;

				.contact-phone {
					span {
						text-decoration: underline;
					}
				}
			}

			&__next-presta {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				border-top: 1px solid rgba($c_gold_luxe, 0.6);

				.left a {
					color: $c_white;
					font-size: 20px;

					&:hover {
						color: $c_gold_luxe;
					}
				}

				.right {
					display: flex;
					align-items: center;
					cursor: pointer;

					.content {
						text-align: right;

						.presta-type {
							@include ft($f_txt, 12px, 300, $c_gold_luxe, 18px);
							letter-spacing: 1.8px;
							text-transform: uppercase;
						}

						.presta-title {
							@include ft($f_txt, 18px, 300, $c_white, 20px);
							letter-spacing: 0.3px;
							opacity: 0.75;
						}
					}

					.next-presta-arrow {
						color: $c_white;
					}
				}
			}

			@include tablet_portrait {
				flex-direction: column;

				&__gallery {
					height: 375px;

					.swiper .swiper-slide, .fit-cover {
						width: 100vw !important;
					}
				}

				&__info {
					position: relative;
					width: 100%;
					height: calc(100vh - 375px);
					transition: height 0.2s ease-in;

					/*&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						z-index: 999;
						pointer-events: none;
					}*/
					.top {
						position: fixed;
						right: 0;
						top: 0;

						.close-overlay {
							font-size: 0;
							padding: 16px 20px;
							letter-spacing: 0;
							opacity: 0;
							transition: opacity 0.2s ease-in;
						}
					}
				}

				.contact-cta {
					flex-direction: column;
					align-items: flex-start;
				}
			}
		}

		&.swiped-up {
			.overlay-luxe-presta__content__gallery {
				&::before {
					z-index: 9;
					opacity: 0.6;
				}
			}

			.overlay-luxe-presta__content__info {
				height: calc(100vh - 241px);

				&::before {
					content: none;
				}

				.top {
					.close-overlay {
						opacity: 1;
					}
				}
			}
		}
	}

	// @Imane j'ai ajouté .overlay-luxe-map juste pour avoir les styles de base, je sais pas si c'est judicieux de garder ça comme ça, où s'il vautm ieux refactoriser des trucs
	.overlay-luxe-contact {
		background-color: $c_greydark;

		.close-overlay {
			color: $c_white;
		}

		.headline-overlay {
			@include ft($f_txt, 20px, 300, $c_gold_luxe, 30px);
			text-transform: uppercase;
		}

		.title-overlay {
			@include ft($f_txt, 25px, 200, $c_white, 25px);
			margin-bottom: 24px;
		}

		.top {
			background-color: $c_greydark;
			margin-left: auto;

			&::after {
				content: none;
			}
		}

		.bloc-form input:not([type="radio"]),
		.bloc-form select,
		.bloc-form textarea {
			background-color: $c_greydark;
			border-color: #89794980;
			color: $c_white;
		}

		.bloc-form {
			.scroller {
				padding-top: 0;

				.group-switch {
					padding-top: 0;
				}
			}

			label,
			.frm_primary_label,
			.frm_switch_block .frm_on_label {
				color: $c_white_luxe;
			}

			.frm_switch .frm_slider:before {
				background-color: $c_white_luxe;
			}

			.frm_switch_block input:checked ~ .frm_switch .frm_slider:before {
				background-color: $c_gold_luxe;
			}

			.frm_switch_block .frm_switch,
			.frm_switch_block:hover .frm_switch,
			.frm_switch_block:focus .frm_switch {
				border-color: $c_gold_luxe !important;
			}

			input:not([type="radio"]):hover,
			input:not([type="radio"]):focus,
			select:hover,
			select:focus,
			textarea:hover,
			textarea:focus {
				border-color: $c_gold_luxe !important;
			}

			.form_submit p {
				color: $c_white_luxe;
			}

		}
	}

	// POUR QUE ÇA RESSEMBLE UN PEU À QQCHOSE PDT LE DEV, TU PEUX SUPPRIMER OU MODIFIER TOUT ÇA
	// STRUCTURE DES INFOS WINDOW DANS wp-content/themes/artge/assets/js/src-2/luxe_map.js
	.overlay-luxe-map {
		max-width: 100%;
		background-color: $c_dark_grey;
		padding: 0;


		.close-overlay {
			position: absolute;
			top: 0;
			right: 0;
			color: $c_white;
			text-align: right;
		}

		.headline-overlay {
			@include ft($f_txt, 20px, 300, $c_gold_luxe, 30px);
			text-transform: uppercase;
		}

		.title-overlay {
			@include ft($f_txt, 25px, 200, $c_white, 25px);
			margin-bottom: 24px;
		}

		.intro {
			position: absolute;
			bottom: 66px;
			left: 286px;
			z-index: 30;
			@include tablet_portrait {
				left: 40px;
			}
			@include mobile {
				display: none;
			}

			.title {
				@include ft($f_hx, 25px, 300, $c_white, 25px);

				strong {
					display: block;
					font-weight: 600;
				}
			}
		}

		.top {
			background-color: transparent;
			margin-left: auto;

			&::after {
				content: none;
			}
		}

		.content {
			background-color: $c_dark_grey;
		}

		.maps {
			height: 100%;
		}

		.custom-popup-thuria {
			width: 405px;
			@include mobile {
				position: fixed;
				bottom: 0 !important;
				top: auto !important;
				left: 0 !important;
				width: 100%;
				transform: none !important;
				margin: 0;
			}

			.leaflet-popup-content-wrapper {
				background: $c_deep_grey;
				border-radius: 3px;
				@include addTransition();

				a {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 11px 23px 11px 11px;
					@include addTransition();
				}

				&:hover, &:focus {
					body:not(.no-hover) & {
						a {
							background-color: $c_greenish_blue;

							.caption .title {
								color: $c_gold_luxe;
							}

							.location-container .icon-chevron-slider-right {
								transform: translateX(5px);
							}
						}
					}
				}

				.image-wrapper {
					flex-shrink: 0;
					width: 91px;
					@include aspect-ratio(91, 91);
					margin-right: 11px;

					figure, .no-photo {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}

				.caption {
					flex: 1 1 0px;

					.title {
						@include ft($f_txt, 16px, 600, $c_white, 22px);
						max-width: 80%;
						margin-bottom: 14px;
						@include addTransition();
					}
				}
			}

			.leaflet-popup-close-button {
				display: none;
			}

			.location-container {
				width: 100%;
				display: flex;
				align-items: center;

				.icon-chevron-slider-right {
					margin-left: auto;
					font-size: calculateRem(9px);
					color: $c_white;
					@include addTransition();
				}

				.icon-ico-location {
					position: relative;
					top: -2px;
					color: $c_gold_luxe;
					font-size: calculateRem(11px);
					margin-right: 5px;
				}

				.location-title {
					@include ft($f_txt, 13px, 400, $c_gold_luxe, 13px);
					@include letter-spacing(150);
					text-transform: uppercase;
				}
			}

			.leaflet-popup-tip-container {
				width: 1px;
				height: 37px;
				top: 100%;
				bottom: auto;
				transform: none;
				margin-left: 10px;

				.leaflet-popup-tip {
					background-color: #435C6C;
					width: 100%;
					height: 100%;

				}
			}
		}

		.leaflet-marker-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 34px !important;
			height: 34px !important;
			border-radius: 50%;
			background-color: $c_greenish_blue;
			border: solid 1px $c_gold_luxe;
			font-size: 18px;
			color: $c_white;
			@include addTransition();

			&:hover, &:focus {
				body:not(.no-hover) & {
					&:not(.custom-markers) {
						background-color: $c_gold_luxe;
					}
				}
			}

			&.custom-markers {
				border: none;
				background-color: transparent;
				cursor: initial;
			}

			&.marker-cluster {
				-webkit-tap-highlight-color: transparent;

				div {
					width: 100%;
					height: 100%;
					margin: 0;
					background-color: transparent;

					span {
						line-height: 32px;
					}
				}
			}
		}

		.leaflet-touch .leaflet-bar {
			border: 1px solid #AD8F43;
			margin-left: 70px;
			margin-top: 40px;
			@include mobile {
				margin-left: 20px;
				margin-top: 20px;
			}

			a {
				width: 34px;
				height: 34px;
				background: transparent;
				border-bottom: transparent;

				&:last-of-type {
					border-top: 1px solid #AD8F43;
				}

				&:first-child, &:last-child {
					&:after {
						color: $c_white;
					}
				}

				&:hover, &:focus {
					body:not(.no-hover) & {
						background-color: $c_greenish_blue;
					}
				}
			}
		}
	}
}