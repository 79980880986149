// used for choose images list
.myex-list-radio-image {
	gap: calculateRem(10px);
	display: grid;
	grid-template-columns: repeat(2, minmax(120px, 1fr));

	.myex-form-field {
		margin: 0;
	}

	@include min-mobile {
		grid-template-columns: repeat(3, minmax(120px, 1fr));
		gap: calculateRem(10px);
	}

	@include min-tablet_small {
		grid-template-columns: repeat(3, minmax(120px, 1fr));
	}
	@include min-tablet_landscape {
		grid-template-columns: repeat(4, minmax(120px, 1fr));
	}

	@include min-desktop {
		grid-template-columns: repeat(6, minmax(auto, 1fr));
	}
}
