.bloc-slider-splide {

    padding-bottom: 30px;

    .splide__arrow {
        color: #fff;
        background: #7A97AA;
        border-radius: 4px;
        width: 60px;
        height: 60px;
        opacity: 1;

        &--prev {
            left: 12vw;

            @include tablet_portrait {
                left: 11vw;
            }
        }

        &--next {
            right: 12vw;

            @include tablet_portrait {
                right: 11vw;
            }
        }

        &:disabled {
            opacity: 0;
        }

        &:hover {
            transition-duration: 300ms;
            background-color: #DEA99E;
            opacity: 1 !important;
        }

        @include mobile {
            display: none;
        }
    }

    .splide:not(.is-overflow) .splide__pagination, .splide:not(.is-overflow) .splide__arrow {
        display: flex;
    }

    .splide__track {
        padding-bottom: 2vh;
        margin: auto;

        .splide__slide {
            height: 45vw;
            margin-top: 20px;
            transition-duration: 800ms;

            @include min-large-desktop {
                height: 35vw;
            }

            &.transition {
                margin-top: 0;
            }

            .item {
                display: flex;
                align-items: flex-end;

                .caption {
                    max-width: 60%;
                    transform: none;
                    top: unset;
                    margin-bottom: 70px;

                    .surtitre {
                        font-size: 16px;
                    }

                    h2, h3 {
                        font-size: 40px;
                        font-weight: 600;
                        line-height: 1.2;
                    }

                    @include tablet_portrait {
                        max-width: 90%;
                        left: 20px;
                        right: 20px;

                        .surtitre {
                            font-size: 13px;
                        }

                        h2, h3 {
                            font-size: 25px;
                            line-height: 25px;
                        }
                    }

                    @include mobile {
                        margin-bottom: 25px;
                    }

                }
            }



            .credits {
                display: none;
            }
        }
    }

    .class-pagination {

        position: static;
        bottom: 1em;
        justify-content: center;
        max-width: 1510px;
        margin: auto;

        @include tablet_portrait {
            padding: 0 7vw;
        }

        @include mobile {
            padding: 0;
            justify-content: center;
        }
    }
    .class-page {
        margin: 0;
        height: 1px;
        border-radius: 8px;
        width: 100%;
        background-color: #627A89;
        &.is-active {
            height: 5px;
            background-color: #DEA99E;
            opacity: 1;
            transform: unset;
            padding-bottom: 2px;
        }
    }
}