.ancres-itinerance {
    --border-gutter : 80px;
    position: sticky;
    top: 80px;
    z-index: 500;
    display: flex;
    padding: 0 var(--border-gutter);
    background-color: $c_white;
    @include addTransition();

    @include desktop {
        --border-gutter : 40px;
    }

    @include mobile {
        top: 74px;
        background-color: transparent;
        padding-right: 0;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: calc(100% - 8px);
            width: 100%;
            background-color: white;
        }
    }

    // horizontal line
    &:after {
        content: '';
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($c_border, 0.3);

        @include mobile {
            bottom: 8px;
        }
    }

    .menu-ancres-itinerance {
        display: flex;
        align-items: center;
        margin-left: -20px;

        @include mobile {
            overflow-x: scroll;
            overflow-y: hidden;
            width: 100vw;
            padding-bottom: 8px;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        li {

            @include mobile {
                scroll-snap-align: start;
                white-space: nowrap;
                flex-shrink: 0;

                &:first-child {
                    margin-left: -10px;
                }
            }
        }

        a {
            @include ft($f_txt, 14px, 600, $c_surtitre, 22px);
            position: relative;
            display: inline-flex;
            align-items: center;
            padding: 20px 15px;
            text-align: center;
            font-weight: 600;

            @include addTransition();

            @media screen and (max-width: 720px) {
                padding: 30px 10px 28px;
            }

            @include mobile {
                font-size: 11px;
                padding: 20px 10px 18px;
                white-space: nowrap;
            }
            
            @include can-hover {
                color: $c_third;
            }

            i {
                font-size: 25px;
            }

            &.active {
                color: $c_third;

                &:after {
                    opacity: 1;
                    z-index: 99;
                }

                i {
                    color: $c_third;
                }
            }
            
            &:after {
                content: '';
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 16px;
                height: 16px;
                background-color: $c_third;
                border-radius: 50%;
                opacity: 0;
                @include addTransition();

                @include mobile {
                    width: 10px;
                    height: 10px;
                }
            }


            .icon-burger-arrow {
                color: $c_surtitre;
                font-size: 20px;

                @include can-hover {
                    color: $c_third;
                }

                @include mobile {
                    font-size: 18px;
                    margin-bottom: 3px
                }
            }
        }
    }


    .cta-add-favoris {
        @include reset-button;
        display: inline-flex;
        align-items: center;
        padding: 30px 20px 28px 20px;

        &.cta-favoris-checked {
            &:before {
                content: "\e959";
                color: $c_third;
            }
        }

        @media screen and (max-width: 720px) {
            padding-right: 0;
        }

        @include mobile {
            display: none;
        }

        @include can-hover {
            &:before {
                color: $c_main;
            }
        }

        &:before {
            content: "\e916";
            @include font();
            font-size: 22px;
            color: $c_third;
            @include addTransition();
        }
    }

    .cta-add-favoris {
        margin-left: auto;
    }

    .social-share {

        background-color: transparent;
        
        @media screen and (max-width: 720px) {
            display: none;
        }

        .toggle {
            display: inline-flex;
            align-items: center;
            height: 100%;
            padding: 0;
        }

        .network-list {
            top: -10px;
        }
    }


    // variante pour le listing des itinéraires
    &.--listing-itineraires {
        justify-content: center;
        padding: 0;
        background-color: #20252A;

        @include tablet_landscape {
            border-top: initial;
        }

        // horizontal line
        &:after {
            @include desktop {
                left: 0;
                right: 0;
            }

            @include tablet_landscape {
                left: calc(var(--border-gutter) * -1);
                right: calc(var(--border-gutter) * -1);
            }
        }

        .menu-ancres-itinerance {
            margin-left: initial;

            @include mobile {
                justify-content: space-between;
                width: 100%;
                margin-left: -4px;
            }

            li {
                @include mobile {
                    &:last-child a {
                        padding-right: 0;
                    }
                }
            }

            a {
                color: $c_light_txt;

                @include can-hover {
                    color: $c_white;
                }

                &.active {
                    color: $c_white;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}