body {
	&.archive, &.page-template-page-listing-auto {
		.header-color {
			opacity: 1;
		}
	}

	&.archive,
	&.page-template-page-listing-auto {
		.header-color {
			opacity: 1;
		}
	}

	.header-color {
		position: fixed;
		left: 0;
		right: 0;
		top: 80px;
		height: 109px;
		background: $c_greydark;
		z-index: 10000;
		transform-origin: top;
		opacity: 0;
		pointer-events: none;
		@include addTransition();

		@include desktop1280 {
			height: 89px;
		}

		@include tablet_portrait {
			top: 0;
			height: 100px;
			background: rgba($c_greydark, 0.8);
		}

		@include tablet_portrait {
			content: none;
		}
		@include mobile {
			height: 66px;
		}
	}
}

#header {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10000000;
	background-color: $c_greydark;
	@include addTransition();
	@include tablet_portrait {

		body.no-search & {
			display: none;
		}
	}

	.app-search {
		&.is-hidden {
			visibility: hidden;
		}
	}

	.background-search {
		background-color: #1e2529;
		width: 100%;
		height: 105px;
		display: flex;
		align-items: flex-end;

		&.hidden {
			display: none !important;
		}

		.breadcrumb-mobile {
			margin-bottom: 15px;
			margin-left: 20px;
			display: flex;
			align-items: flex-end;
			color: white;

			span {
				font-size: 10px;

				&.term {
					color: $c_third;
				}
			}

			a:before {
				font-family: "icomoon";
				content: "\e998";
				font-size: 14px;
				color: white;
				margin-right: 5px;
			}
		}
	}

	> .content-wrapper {
		z-index: 1000000;
		height: $header-height;
		@include tablet_portrait {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-right: 0 !important;
			height: 74px;
		}
	}

	.logo-header {
		z-index: 10000000;

		figure {
			position: absolute;
			background: none;
			top: 7px;
		}
	}

	.bar-in-front {
		top: 50%;
		left: 140px;
		transform: translateY(-50%);

		a:hover {
			color: $c_third;
		}
	}

	&.header-luxe {
		.in-header {
			width: auto;
		}
	}

	.in-header {
		padding: 26px 0 25px 0;
		display: flex;
		justify-content: flex-end;
		width: 100%;
		@include tablet_portrait {
			padding: 15px 0;
			width: 100%;
		}

		.cta-overlay-search {
			overflow: visible;
			padding-right: 25px;
			padding-top: 3px;
			margin: 0;
			background: none;
			max-width: 35px;

			@include tablet_portrait {
				max-width: 20px;
			}

			@include mobile {
				padding-top: 8px;
				padding-right: 20px;
			}

			&:hover {
				color: $c_third;
			}

			&::before {
				font-size: 20px;
				@include tablet_portrait {
					font-size: 24px;
					line-height: 34px;
				}
			}

			&:focus {
				background: none;
			}
		}

		.dropdown {
			padding: 2px 20px 0 20px;
			@include tablet_portrait {
				display: none;
			}
		}

		.user-account {
			background: none;
			border: none;
			padding-top: 3px;

			span {
				color: white;
				font-size: 18px;

				&:hover {
					color: $c_third;
					cursor: pointer;
				}
			}

			@include tablet_portrait {
				display: none;
			}
		}

		.wrapper-th-menu {
			background: none;
			border: none;
			position: relative;

			.burger {
				@include mobile {
					width: 42px;

					.burger-bar {
						height: 3px;

						&.top {
							width: 42px;
						}

						&.bottom {
							width: 19px;
						}
					}

					.middle {
						font-size: 12px;
						font-weight: 700;
						margin: 2px 0;
					}
				}
			}

			.notifications-counter {
				top: 2px;
				right: 10px;
			}
		}
	}
}

.notifications-icon-counter {
	position: relative;

	&.no-notif {
		.notifications-counter[data-count='0'] {
			display: none;
		}
	}

	.notifications-counter {
		position: absolute;
		top: -4px;
		right: -4px;
		height: 16px;
		width: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #e23060;
		border-radius: 50%;
		font-size: 10px;
		color: #fff;
		font-weight: 600;

		&::after {
			content: attr(data-count);
		}
	}

	&.header-notifications-btn {
		@include tablet-portrait {
			display: none;
		}
	}
}

// Header Form Search Mobile + Tablette Portrait
// ==========================================================================
.form-search-mobile {
	position: relative;
	display: none;
	align-items: center;
	justify-content: flex-start;
	background: $c_white;
	border-radius: 4px;
	width: 100%;
	@include tablet_portrait {
		display: flex;
		margin: 10px 0;
	}

	button {
		@include ft($f_hx, 12px, 700, $c_greydark, 20px);
		@extend %appearance;
		position: relative;
		text-align: left;
		background: $c_white;
		width: 100%;
		order: 2;
		box-sizing: border-box;
		padding: 22px 20px 18px 60px;
		background: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@include addTransition();
		@include mobile {
			padding: 14px 20px 12px 45px;
		}

		&::placeholder {
			color: $c_greydark;
		}

		&:before {
			content: "\e92d";
			@include font();
			font-size: 16px;
			color: $c_third;
			position: absolute;
			left: 25px;
			top: 50%;
			margin-top: -8px;
			@include mobile {
				font-size: 14px;
				left: 16px;
				margin-top: -7px;
			}
		}
	}
}

// Dropdown Langue Sites
// ==========================================================================
.lang {
	position: relative;
	display: flex;
	align-items: center;

	&:hover {
		cursor: pointer;
	}

	> a {
		&:before {
			content: "\e90a";
			@include font();
			float: right;
			margin-left: 5px;
			font-size: 11px;
			line-height: 20px;
		}
	}

	a {
		@include ft($f_hx, 14px, 400, $c_white, 20px);
		position: relative;
		text-align: center;
		display: block;
		text-transform: uppercase;

		@include mobile {
			@include ft($f_hx, 13px, 400, $c_white, 20px);
		}

		&:hover,
		&:focus {
			body:not(.no-hover) & {
				color: $c_third;
			}
		}
	}

	ul {
		position: absolute;
		background: $c_white;
		box-shadow: 2px 4px 10px rgba($c_greydark, 0.1);
		border-radius: 4px;
		width: auto;
		top: 40px;
		padding: 0 0;
		overflow: hidden;
		transform: scaleY(0);
		transform-origin: top;
		opacity: 0;
		@include addTransition();

		@include tablet_portrait {
			top: 70px;
		}

		&.sub-lang-mobile {
			display: none !important;
		}

		&.is-focus-lang {
			box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
			padding: 4px 0;
			top: $header-height;
			opacity: 1;
			z-index: 10;
			display: block;
			transform: scaleY(1);
		}

		li {
			display: block;
			width: 100%;

			a {
				@include ft("", "", "", $c_txt, "");
				padding: 8px 15px;

				&:hover,
				&:focus {
					body:not(.no-hover) & {
						color: $c_third;
					}
				}
			}
		}
	}

	select {
		display: none;

		&.is-display {
			display: block;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			height: 100%;
			width: 100%;
			z-index: 101010100;
		}
	}

	&.open {
		ul {
			opacity: 1;
			transform: scaleY(1);
		}
	}
}

// Nav Right Desktop et Mobile Bottom Bar
// ==========================================================================
.menu-barre-mobile {
	display: none;
	align-items: center;
	height: 100%;
	float: right;
	opacity: 1;
	@include addTransition();

	.overlay-menu-opened &,
	.overlay-search-opened & {
		display: none;
		@include tablet_portrait {
			display: flex;
		}
	}

	&.in-header {
		display: flex;

		.overlay-menu-opened &,
		.overlay-search-opened & {
			display: none;
		}

		@include tablet_portrait {
			display: none;
		}
	}

	.buttons-favoris {
		display: flex;
		align-items: center;

		@include tablet_portrait {
			justify-content: flex-end;
		}
	}

	@include tablet_portrait {
		display: flex;
		position: sticky;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 80px;
		background: $c_greydark;
		justify-content: flex-end;
		padding: 0;
		z-index: 100001;
	}
	@include mobile {
		height: 100%;
	}

	> *,
	.buttons-favoris > * {
		position: relative;
		padding: 0 15px;
		height: 100%;
		@include desktop1280 {
			padding: 0 10px;
		}
		@include tablet_portrait {
			padding: 0 20px;
		}
		@include mobile {
			padding: 20px;
			height: 100%;
		}

		&.lang {
			@include tablet_portrait {
				select {
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					opacity: 0;
					z-index: 10;
					width: 100%;
					padding: 0;
				}
			}
		}

		&:first-child {
			padding-left: 0;

			&.wrapper-th-menu {
				@include tablet_portrait {
					padding: 0 40px;
				}
				@include mobile {
					padding: 0 20px;
				}
			}
		}

		&:last-child {
			padding-right: 0;
			@include tablet_portrait {
				padding-right: 40px;
			}
			@include mobile {
				padding-right: 20px;
			}
		}

		&.buttons-favoris {
			display: flex;
			align-items: center;

			> * {
				position: relative;
				padding: 0 15px;
				@include desktop1280 {
					padding: 0 10px;
				}
				@include tablet_portrait {
					padding: 0 20px;
				}
				@include mobile {
					padding: 0 10px;
				}

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}

		&.wrapper-th-menu {
			padding: 0;
			margin-left: 30px;
			@include tablet_portrait {
				margin: 0;
				padding: 0 40px;
			}
			@include mobile {
				padding: 0 20px;
			}
		}
	}

	button {
		@extend %appearance;
		font-size: 20px;
		color: $c_white;
		background: none;
		@include addTransition();
		font-size: 20px;
		padding-right: 0;
		padding-left: 0;
		margin: 0 15px;

		&:hover,
		&:focus {
			body:not(.no-hover) & {
				cursor: pointer;
				color: $c_third;
			}
		}

		span {
			display: block;
		}

		@include mobile {
			&.notifications-icon-counter {
				padding: 0 7px 0 0;
				margin-left: 10px;
			}
		}

		.counters {
			@include ft($f_hx, 8px, 700, $c_white, 15px);
			position: absolute;
			padding-top: 1px;
			margin-top: -23px;
			right: 7px;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			background: $c_third;
			@include tablet_portrait {
				right: 10px;
			}
		}
	}
}

.menu-mobile-logo {
	position: absolute;
	left: 0;
	display: flex;
	align-items: center;

	figure {
		background: transparent;
	}
}

// Menu Burger (Defaut : Tablette + Mobile)
// ==========================================================================

.wrapper-th-menu {
	order: 4;
	display: none;
	align-items: center;
	@include tablet_portrait {
		display: flex;
	}

	.burger {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		width: 50px;

		.burger-bar {
			height: 4px;
			background-color: $c_white;
			border-radius: 10px;

			&.top {
				width: 50px;
			}

			&.bottom {
				width: 20px;
			}
		}

		.middle {
			@include ft($f_txt, 14px, 500, $c_white, 17px);
			margin: 5px 0;
		}

		.notifications-counter {
			right: -11px !important;
			top: -7px !important;
		}
	}
}

.cover-background {
	background-color: $c_greydark;
}

.cover-borders {
	overflow: hidden;
	border-radius: 14px 14px 0 0;
}

.mobile-search {
	&__wrapper {
		padding-top: 11px;
		padding-bottom: 15px;
		background-color: $c_greydark;
		display: none;

		@include tablet_portrait {
			display: block;
		}
	}

	&__input {
		position: relative;
		width: 100%;
		display: block;
		height: 46px;
		box-sizing: border-box;
		text-align: left;
		background: #fff;
		border-radius: 4px 0 0 4px;
		padding: 0 9px;
		border: 1px solid rgba($c_border, 0.5);
		border-right: none;
		line-height: 44px;
		color: $c_main;
		overflow: hidden;
		font-size: 13px;
		color: $c_light_txt;
		border-radius: 4px;

		&:before {
			@include font();
			content: "\e92d";
			display: inline-block;
			margin-right: 12px;
			font-size: 20px;
			color: $c_greydark;
		}
	}
}

.header-luxe,
.header-mice {
	.logo-header img {
		transition: width 0.16s ease-in, height 0.16s ease-in;
	}
}

body.is-scrolled {
	.header-luxe,
	.header-mice {
		.logo-header img {
			width: 60px;
			height: 60px;
		}
	}
}
