// Menu principal
// ==========================================================================
.wrapper-nav {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	transform: translate3d(-50%, 0, 0);
	white-space: nowrap;
	font-size: 0;
	@include tablet_portrait {
		display: none;
	}

	// Menu désactivé sur l'Overlay de recherche
	&.disabled {
		opacity: 0.6;

		nav {
			ul {
				li {
					a {
						pointer-events: none;
					}
				}
			}
		}
	}

	nav {
		display: inline-block;
		vertical-align: middle;
		position: relative;

		ul {
			display: flex;
			align-items: center;
			list-style-type: none;
			padding: 0;

			li {
				position: relative;
				margin: 0;
				display: inline-block;
				@include tablet_portrait {
					display: block;
				}

				&:first-child {
					a {
						&:before {
							content: none;
						}
					}
				}

				&.has-submenu.active {
					> a {
						opacity: 1;

						&:after {
							content: "";
							position: absolute;
							top: -16px;
							left: 50%;
							width: 32px;
							height: 32px;
							background: $c_third;
							border-radius: 50%;
							margin-left: -16px;
							@include tablet_portrait {
								content: none;
							}
						}
					}

					.submenu {
						display: flex;
					}
				}

				> a {
					.overlay-menu & {
						display: none;
						@include tablet_portrait {
							display: block;
						}
					}

					@include ft($f_hx, 16px, 500, $c_white, 30px);
					position: relative;
					box-sizing: border-box;
					z-index: 10;
					display: block;
					padding: 21px 15px 17px;
					@include addTransition();
					@include desktop1280 {
						font-size: calculateRem(14px);
						padding: 21px 10px 17px;
					}
					@include tablet_portrait {
						font-size: calculateRem(25px);
						line-height: calculateRem(35px);
						font-weight: 400;
						white-space: normal;
					}
					@include mobile_small {
						font-size: calculateRem(20px);
						line-height: calculateRem(25px);
					}

					&:before {
						content: "";
						position: absolute;
						top: 50%;
						left: 0;
						margin-top: -4px;
						width: 1px;
						height: 10px;
						background: $c_grey70;
						@include tablet_portrait {
							content: none;
						}
					}

					&:hover,
					&:focus {
						body:not(.no-hover) & {
							color: $c_third;
						}
					}

					body.no-search & {
						padding: 27px 15px 23px;
						@include desktop1280 {
							padding: 27px 10px 23px;
						}
						@media screen and (max-width: 1100px) {
							font-size: calculateRem(12px);
						}
						@include tablet_portrait {
							font-size: calculateRem(25px);
						}
					}
				}

				// SOUS MENU
				.submenu {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: none;
					flex-wrap: wrap;
					z-index: 1;
					padding-top: 250px;
					@include tablet_landscape {
						padding-top: 200px;
					}
					@include tablet_portrait {
						z-index: 100;
						padding-top: 0;
						align-items: center;
					}

					&:after {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						height: 80px;
						z-index: 100;
						pointer-events: none;
						/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,202529+99&0+0,1+100 */
						background: -moz-linear-gradient(
							top,
							rgba(32, 37, 41, 0) 0%,
							rgba(32, 37, 41, 0.99) 99%,
							rgba(32, 37, 41, 1) 100%
						); /* FF3.6-15 */
						background: -webkit-linear-gradient(
							top,
							rgba(32, 37, 41, 0) 0%,
							rgba(32, 37, 41, 0.99) 99%,
							rgba(32, 37, 41, 1) 100%
						); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(
							to bottom,
							rgba(32, 37, 41, 0) 0%,
							rgba(32, 37, 41, 0.99) 99%,
							rgba(32, 37, 41, 1) 100%
						); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00202529', endColorstr='#202529', GradientType=0); /* IE6-9 */
						@include tablet_portrait {
							bottom: 60px;
						}
					}

					.image-background {
						@extend %center;

						&:after {
							content: "";
							@extend %center;
							z-index: 10;
							opacity: 0.85;
							background: $c_greydark;
						}
					}

					figcaption,
					.credits {
						display: none;
					}
				}

				// NIVEAU 2
				.menu-niveau-2 {
					position: relative;
					display: block;
					text-align: left;
					width: 100%;
					max-width: $max-width;
					margin: 0 auto;
					padding-top: 15px;

					@media (min-width: 981px) {
						transform: translateX(-30px);
						z-index: 10;
					}
					@media screen and (max-width: 1440px) {
						margin: 0 80px;
						width: calc(100% - 160px);
					}
					@include tablet_landscape {
						margin: 0 40px;
						width: calc(100% - 80px);
					}
					@include tablet_portrait {
						position: static;
					}
					@include mobile {
						margin: 0 20px;
						width: calc(100% - 40px);
					}

					.menu-container {
						@media (min-width: 981px) {
							padding-left: 30px;
						}
					}

					// Style du menu avec le grand titre à gauche et menu niveau 3 / 4 à droite
					&.with-big-title {
						height: 100%;
						display: flex;

						.menu-niveau-3 {
							overflow: auto;
							-webkit-overflow-scrolling: touch;
							height: 100%;
							box-sizing: border-box;
							-ms-overflow-style: none; /* IE and Edge */
							scrollbar-width: none; /* Firefox */
							&::-webkit-scrollbar {
								display: none;
							}

							@include tablet_portrait {
								margin: 0;
								padding-top: 120px;
								height: 100%;
								&:after {
									content: "";
									position: relative;
									display: block;
									height: 100px;
								}
							}
							@include mobile {
								padding-top: 80px;
							}
						}
					}

					li {
						display: block;
						box-sizing: border-box;

						&.has-level-3 {
							&.active {
								> a {
									color: $c_white;
									opacity: 1;

									&:before {
										content: "";
										position: absolute;
										right: calc(100% + 10px);
										left: auto;
										top: 25px;
										width: 16px;
										height: 16px;
										background: $c_third;
										border-radius: 50%;
										@include tablet_landscape {
											top: 22px;
										}
										@include tablet_portrait {
											content: none;
										}
									}
								}

								.menu-niveau-3 {
									display: block;
									@include tablet_portrait {
										display: flex;
									}
								}
							}
						}

						&.back {
							@include ft($f_hx, 16px, 600, $c_third, 24px);
							position: relative;
							z-index: 10;
							display: none;
							padding: 0 0 0 25px;
							margin-bottom: 20px;
							@include tablet_portrait {
								display: block;
							}
							@include mobile {
								font-size: calculateRem(14px);
								line-height: calculateRem(22px);
								margin-bottom: 10px;
							}

							&:before {
								content: "\e954";
								@include font();
								position: absolute;
								left: 0;
								top: 2px;
								font-size: 15px;
								color: $c_light_txt;
							}

							// Lien qui reprend celui de niveau précédent
							// Bouton Découvrir sur Menu niveau 4
							a {
								@include ft($f_hx, 14px, 500, $c_white, 20px);
								padding: 11px 15px 9px 11px;
								box-sizing: border-box;
								position: absolute;
								top: auto;
								right: 0;
								bottom: 25px;
								background: none;
								border: 1px solid $c_border;
								border-radius: 4px;
								width: auto;
								z-index: 100;
								min-width: 150px;
								text-align: center;
								@include addTransition();

								&:hover,
								&:focus {
									body:not(.no-hover) & {
										color: $c_greydark;
										border-color: $c_white;
										background: $c_white;
										@include tablet_portrait {
											color: $c_white;
											background: $c_third;
											border-color: $c_third;
										}
									}
								}

								@include tablet_portrait {
									color: $c_third;
									border-color: $c_third;
								}
								@include mobile {
									font-size: calculateRem(12px);
									min-width: 100px;
								}
								@include mobile_small {
									min-width: 50px;
								}

								& ~ span {
									max-width: calc(100% - 160px);
									overflow: hidden;
									text-overflow: ellipsis;
									width: 100%;
									display: block;
									@include mobile {
										max-width: calc(100% - 100px);
									}
								}
							}
						}

						a {
							@include ft($f_hx, 30px, 300, $c_light_txt, 45px);
							position: relative;
							display: block;
							padding: 10px 0;
							width: calc(50% - 80px);
							white-space: normal;
							@include addTransition();

							&:before {
								content: none;
							}

							&:hover,
							&:focus {
								body:not(.no-hover) & {
									color: $c_white;
								}
							}

							@include tablet_landscape {
								font-size: calculateRem(25px);
								line-height: calculateRem(30px);
							}
							@include tablet_portrait {
								width: 100%;
								opacity: 1;
								font-weight: 400;
								color: $c_white;
								padding: 15px 0;
							}
							@include mobile {
								padding: 10px 0;
							}
							@include mobile_small {
								font-size: calculateRem(20px);
								line-height: calculateRem(25px);
							}
						}
					}
				}

				.image-territoire {
					position: absolute;
					top: -50px;
					right: 0;
					max-width: 50%;
					z-index: 10;
					@include tablet_portrait {
						display: none;
					}

					figure {
						background: none;

						img {
							@media screen and (max-height: 700px) {
								width: auto;
								display: block;
								position: relative;
								height: auto;
								margin: auto;
								max-width: 80%;
							}
							@media screen and (max-height: 700px) {
								max-width: 60%;
							}
						}
					}
				}

				// MENU NIVEAU 3
				.menu-niveau-3 {
					display: none;
					position: absolute;
					left: calc(50% - 60px);
					top: 0;
					right: 0;
					padding-top: 5px;
					overflow: auto;
					-webkit-overflow-scrolling: touch;
					height: 100%;
					box-sizing: border-box;
					-ms-overflow-style: none; /* IE and Edge */
					scrollbar-width: none; /* Firefox */
					&::-webkit-scrollbar {
						display: none;
					}

					&:after {
						content: "";
						position: relative;
						display: block;
						height: 100px;
					}

					@include tablet_portrait {
						background: $c_greydark;
						left: 0;
						z-index: 100;
						top: 0;
						bottom: 0;
						width: 100%;
						height: 100%;
						flex-wrap: nowrap;
						align-items: center;
						flex-direction: column;
						justify-content: center;
						padding: 0 40px;
						box-sizing: border-box;
						text-align: left;
						padding-top: 120px;
						padding-bottom: 100px;
						justify-content: flex-start;
					}
					@include mobile {
						padding: 100px 20px 80px;
					}

					li {
						@include tablet_portrait {
							width: 100%;
						}

						&.has-level-4 {
							&.active {
								> a {
									color: $c_white;
									opacity: 1;

									&:before {
										content: "";
										position: absolute;
										right: calc(100% - 10px);
										left: auto;
										top: 25px;
										width: 8px;
										height: 8px;
										background: $c_third;
										border-radius: 50%;
										@include tablet_landscape {
											top: 22px;
										}
										@include tablet_portrait {
											content: none;
										}
									}
								}
							}
						}

						> a {
							font-size: calculateRem(20px);
							line-height: calculateRem(30px);
							width: 100%;
							padding-left: 20px;
							@include tablet_landscape {
								font-size: calculateRem(20px);
								line-height: calculateRem(28px);
							}
							@include tablet_portrait {
								width: 100%;
								padding-left: 0;
							}
							@include mobile_small {
								font-size: calculateRem(16px);
								line-height: calculateRem(24px);
							}
						}
					}
				}

				// MENU NIVEAU 4
				.menu-niveau-4 {
					display: none;
					height: 100%;
					position: absolute;
					left: calc(50% - 40px);
					top: 0;
					right: 0;
					padding-right: 80px;

					&.active {
						display: block;

						ul {
							display: block;
						}

						& ~ .menu-niveau-3 {
							display: none !important;
						}
					}

					@include desktop {
						padding-right: 0;
					}

					@include tablet_portrait {
						background: $c_greydark;
						left: 0;
						z-index: 100;
						top: 0;
						bottom: 0;
						width: 100%;
						height: 100%;
						flex-wrap: wrap;
						flex-direction: column;
						padding: 120px 40px 0 40px;
						box-sizing: border-box;
						text-align: left;
						overflow: auto;
						-webkit-overflow-scrolling: touch;
						&:after {
							content: "";
							position: relative;
							display: block;
							height: 100px;
						}
					}
					@include mobile {
						padding: 80px 20px 0 20px;
					}

					// BACK MENU NIVEAU 4
					.back {
						@include ft($f_hx, 16px, 600, $c_third, 24px);
						position: relative;
						z-index: 10;
						display: block;
						padding: 15px 0 20px 0;
						margin-bottom: 0;
						border-bottom: 1px solid $c_grey70;

						&:before {
							content: "\e954";
							@include font();
							position: absolute;
							left: 0;
							top: 2px;
							font-size: 15px;
							color: $c_light_txt;
						}

						@include tablet_portrait {
							padding-top: 0;
						}
						@include mobile {
							font-size: calculateRem(14px);
							line-height: calculateRem(22px);
						}

						&:hover {
							body:not(.no-hover) & {
								cursor: pointer;

								.retour {
									color: $c_white;

									&:before {
										color: $c_white;
										margin-right: 5px;
									}
								}
							}
						}

						&:before {
							content: none;
						}

						span:not(.retour) {
							@include ft($f_hx, 20px, 300, $c_white, 26px);
							letter-spacing: 0.2px;
							@include mobile {
								font-size: calculateRem(16px);
								line-height: calculateRem(22px);
							}
						}

						.retour {
							@include ft($f_hx, 12px, 400, $c_third, 26px);
							position: relative;
							text-transform: uppercase;
							letter-spacing: 1.5px;
							display: block;
							@include addTransition();

							&:before {
								content: "\e954";
								@include font();
								float: left;
								font-size: 15px;
								line-height: 26px;
								color: $c_third;
								margin-right: 10px;
								@include addTransition();
							}
						}

						// Lien qui reprend celui de niveau précédent
						// Bouton Découvrir sur Menu niveau 4
						a {
							@include ft($f_hx, 14px, 500, $c_white, 20px);
							padding: 11px 15px 9px 11px;
							box-sizing: border-box;
							position: absolute;
							top: auto;
							right: 0;
							bottom: 25px;
							background: none;
							border: 1px solid $c_border;
							border-radius: 4px;
							width: auto;
							z-index: 100;
							min-width: 150px;
							text-align: center;
							@include addTransition();

							&:hover,
							&:focus {
								body:not(.no-hover) & {
									color: $c_greydark;
									border-color: $c_white;
									background: $c_white;
									@include tablet_portrait {
										color: $c_white;
										background: $c_third;
										border-color: $c_third;
									}
								}
							}

							@include tablet_portrait {
								color: $c_third;
								border-color: $c_third;
							}
							@include mobile {
								font-size: calculateRem(12px);
								min-width: 100px;
							}
							@include mobile_small {
								min-width: 50px;
							}

							& ~ span {
								max-width: calc(100% - 160px);
								overflow: hidden;
								text-overflow: ellipsis;
								width: 100%;
								display: block;
								@include mobile {
									max-width: calc(100% - 100px);
								}
							}
						}
					}

					ul {
						padding-top: 15px;
						overflow: auto;
						-webkit-overflow-scrolling: touch;
						height: calc(100% - 88px);
						box-sizing: border-box;
						-ms-overflow-style: none; /* IE and Edge */
						scrollbar-width: none; /* Firefox */
						&::-webkit-scrollbar {
							display: none;
						}

						&:after {
							content: "";
							position: relative;
							display: block;
							height: 100px;
						}

						@include tablet_portrait {
							overflow: hidden;
							height: auto;
						}
						@include mobile {
							padding-top: 20px;
						}

						li {
							> a {
								font-size: calculateRem(16px);
								line-height: calculateRem(20px);
								width: 100%;
								padding: 15px 0;
								@include mobile {
									padding: 10px 0;
								}
								@include mobile_small {
									font-size: calculateRem(14px);
									line-height: calculateRem(20px);
								}
							}
						}
					}
				}

				// Zone Left
				.zone-left {
					position: relative;
					z-index: 10;
					width: calc(50% - 80px);
					padding-right: 80px;
					@include tablet_portrait {
						display: none;
					}

					.big-title {
						@include ft($f_hx, 88px, 100, $c_white, 88px);
						display: block;
						z-index: 100;
						hyphens: auto;
						word-break: break-all;
						white-space: normal;
						@include desktop {
							font-size: calculateRem(90px);
							line-height: calculateRem(90px);
						}
						@include desktop1280 {
							font-size: calculateRem(70px);
							line-height: calculateRem(70px);
						}
						@include tablet_landscape {
							font-size: calculateRem(60px);
							line-height: calculateRem(60px);
						}
					}

					.list-tags {
						@include tablet_portrait {
							display: none;
						}
					}

					& ~ .menu-niveau-3 {
						display: block;

						li {
							a {
								font-weight: 300;
								@include tablet_portrait {
									font-weight: 400;
								}
							}
						}

						.menu-niveau-4 {
							li {
								a {
									font-weight: 400;
								}
							}
						}
					}
				}

				// Liste de tags
				.list-tags {
					display: block;
					margin-top: 15px;
					margin-bottom: -10px;
					@include clearfix();
					@include tablet_portrait {
						margin: 0;
						margin-bottom: 20px;
					}
					@include mobile {
						margin-bottom: 10px;
					}

					li {
						float: left;
						width: auto;
						margin: 0 10px 10px 0;
						@include mobile {
							margin: 0 5px 5px 0;
						}

						a {
							@include ft($f_txt, 14px, 500, $c_third, 20px);
							position: relative;
							box-sizing: border-box;
							padding: 13px 20px 11px 20px;
							border: 1px solid $c_third;
							border-radius: 4px;
							width: 100%;
							@include mobile {
								font-size: calculateRem(12px);
								line-height: calculateRem(18px);
								padding: 10px 15px 9px 15px;
							}

							&:hover,
							&:focus {
								body:not(.no-hover) & {
									color: $c_white;
									background: $c_third;
								}
							}
						}
					}
				}
			}
		}
	}
}

// Overlay - Spécial Overlay Menu
// ==========================================================================
.overlay-menu {
	background-color: white !important;
	z-index: 10000000000;
	@include tablet_portrait {
		max-width: 89vw !important;
	}

	&.from-fade.open {
		left: 11vw;
	}

	// Top
	.top-like-header {
		@include tablet_portrait {
			position: absolute;
			width: 100%;
			height: 60px;
			background-color: #f3f4f5;
			display: block;
			body.no-search & {
				display: none;
			}

			.notifications {
				position: absolute;
				top: 17px;
				left: 20px;
				z-index: 999;
				color: black;

				.icon {
					font-size: 23px;
				}
			}
		}

		.content-wrapper {
			text-align: right;

			// Fermer
			.close-overlay-menu {
				padding: 16px 0 16px 20px;

				@include tablet_portrait {
					color: black;
					top: -10px;
				}
				@include mobile {
					font-size: 11px;
					text-transform: none;
					text-decoration: underline;
					top: 0;
				}

				&::after {
					content: "\e90c";
					margin-left: 5px;
				}
			}
		}
	}

	// NAV
	.wrapper-nav {
		width: 100%;
		height: 100%;
		overflow: scroll;

		@include tablet_portrait {
			display: flex;
			top: 80px;
		}

		nav {
			position: static;
			@include tablet_portrait {
				width: 100%;
			}

			ul {
				position: static;
				padding-bottom: 300px;
				@include tablet_portrait {
					display: block;
					text-align: left;
				}

				li {
					position: static;

					> a {
						padding: 21px 15px 17px;
						color: black;
						@include desktop1280 {
							padding: 21px 10px 17px;
						}
						@include tablet_portrait {
							opacity: 1;
							padding: 20px 20px 0 20px;
							font-size: 26px;
							font-weight: bold;
						}
						@include mobile {
							padding: 20px 20px 0 20px;
						}

						&:hover,
						&:focus {
							body:not(.no-hover) & {
								opacity: 1;
							}
						}
					}

					> span.subtitle {
						margin-left: 20px;
						display: flex;
						flex-wrap: wrap;
					}
				}
			}
		}
	}

	.bottom-like-header {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		height: 135px;
		background-color: $c_white;

		&:after {
			content: "";
			background: linear-gradient(
				to bottom,
				rgba(32, 37, 41, 0) 0%,
				$c_white 100%
			);
			position: absolute;
			left: 0;
			right: 0;
			bottom: 135px;
			width: 100%;
			height: 100%;
			pointer-events: none;
		}

		.separator {
			height: 1px;
			display: block;
			background-color: #d5d9dc;
			margin-right: 15px;
			margin-left: 20px;
			position: relative;
			z-index: 5;
		}

		.dropdown {
			justify-content: end;
			margin-top: -20px;
			z-index: 10;
			position: relative;
			top: 42px;

			a {
				color: black;
				text-transform: uppercase;

				&.current-lang {
					border: 3px solid #d5d9dc;
					border-radius: 4px;
					background-color: white;
					margin-right: 15px;
					padding: 5px 12px 5px 12px;
					font-weight: 600;

					&::after {
						font-family: "icomoon" !important;
						content: "\e9a3";
						margin-left: 9px;
						font-size: 15px;
						color: #d5d9dc;
						top: 2px;
						position: relative;
					}
				}

				&::before {
					content: none;
				}
			}

			ul {
				top: -145px;
				right: 14px;
			}
		}

		.myex-button__connexion, .myex-preview-profil {
			width: 92%;
			margin: auto;
			margin-top: 10px;
		}

		.myex-preview-profil {
			display: none;
		}

		&.bottom-like-header-connected {
			height: 160px;

			& > * {
				color: black;
			}
			&:after {
				bottom: 160px;
			}
			.myex-preview-profil {
				display: flex;
			}
			.myex-button--deconnexion {
				margin-left: 25px;
				margin-top: 10px;
			}
			.myex-button__connexion {
				display: none;
			}
		}
	}

	.links-bottom-menu {
		display: block;
		margin-left: 20px;
		font-size: 11px;
		color: black;
		font-weight: 600;
		margin-bottom: 10px;

		&::after {
			font-family: "icomoon" !important;
			content: "\e9a4";
			margin-left: 9px;
			font-size: 10px;
			position: relative;
		}
	}

	// BOTTOM BARRE
	.menu-barre-mobile {
		display: none;
		position: absolute;
		@include tablet_portrait {
			display: flex;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 1px;
			background: rgba($c_white, 0.2);
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 100%;
			height: 40px;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e2529+0,1e2529+100&0+0,1+100 */
			background: -moz-linear-gradient(
				top,
				rgba(30, 37, 41, 0) 0%,
				rgba(30, 37, 41, 1) 100%
			); /* FF3.6-15 */
			background: -webkit-linear-gradient(
				top,
				rgba(30, 37, 41, 0) 0%,
				rgba(30, 37, 41, 1) 100%
			); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				to bottom,
				rgba(30, 37, 41, 0) 0%,
				rgba(30, 37, 41, 1) 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001e2529', endColorstr='#1e2529', GradientType=0); /* IE6-9 */
		}
	}
}