// Styles pour les landings générales et thématiques


// Styles spéciales pour la barre mobile du menu de navigation,
// qui doit disparaitre au scroll vers le bas et réapparaître seulement lorsque la couverture est visible
.menu-barre-mobile {
	@include mobile {
		&.is-visible {
			opacity: 1;
			transform: none;
		}
		&.is-hidden {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
	}
}

// Début des styles pour les pages générales et thématique
.page-template-landing-luxe-generale,
.page-template-landing-luxe-thematique {
	&:before {
		background-color: $c_deep_grey;
	}

	background-color: $c_greydark;
	overflow-x: hidden;


	.fullpage-header--luxe .caption {
		.btn-open-map-luxe {
			position: relative;
			-webkit-appearance: none;
			resize: none;
			box-shadow: none;
			border: none;
			background: transparent;
			cursor: pointer;
			margin-top: 10px;

			@include mobile {
				display: none;
			}

			> * {
				display: block;
			}

			.text {
				@include ft($f_hx, 14px, 500, $c_white, 16px);
				text-decoration: underline;
				@include addTransition();
			}

			[class^="icon-"], [class*=" icon-"] {
				color: $c_white;
				font-size: calculateRem(89px);
				margin-bottom: 10px;
				@include addTransition();
			}

			&:hover, &:focus {
				.text, [class^="icon-"], [class*=" icon-"] {
					color: $c_dark_gold_luxe;
				}


			}
		}
	}

	.header-color {
		@include tablet_portrait {
			display: none;
		}
	}

	#header.header-luxe {
		background-color: $c_greydark;
		animation: 1s ease-out 0s 1 slideFromTop;

		.container {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;

			@include tablet_portrait {
				display: block;
			}

			.back-to-main-site {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 1 1 0px;
				text-align: center;
				padding-left: 120px;
				height: 100%;
				@include addTransition();
				@include tablet_portrait {
					padding-left: 0;
					display: none;
				}
				@include mobile {
					justify-content: flex-start;
					padding-left: 20px;
				}

				.text {
					@include ft($f_hx, 16px, 500, $c_white, 25px);
					text-decoration: underline;
					@include addTransition();
					@include mobile {
						font-size: 11px;
						line-height: 20px;
					}
				}

				&:before {
					content: "\e97a";
					@include font();
					display: inline-block;
					font-size: calculateRem(17px);
					color: $c_white;
					margin-right: 15px;
					@include addTransition();
				}

				&:hover, &:focus {
					.text {
						color: $c_gold_luxe;
					}

					&:before {
						transform: translateX(5px);
						color: $c_gold_luxe;
					}
				}

			}
		}

		.logo-header {
			display: block;
		}

		.content-wrapper {
			@include tablet_portrait {
				flex-wrap: nowrap;
			}

			.logo-header {
				@include tablet_portrait {
					position: static;
					right: 0;
					flex-shrink: 0;
					height: auto;
					margin-left: 15px;
				}
				@include mobile {
					margin-right: 20px;
					margin-left: 0;
				}

				figure {
					@include tablet_portrait {
						display: flex;
						width: 50px;
					}

					img {
						@include tablet_portrait {
							height: auto;
						}
					}
				}

			}
		}

		// Animation pour le push fiche
		@keyframes slideFromTop {
			0% {
				opacity: 0;
				transform: translateY(-100%);
			}
			100% {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}

	.menu-barre-mobile:not(.in-header) {
		@include tablet_portrait {
			display: none;
		}
	}

	.headline {
		opacity: 0;
		animation-delay: 4s;
		animation: fadeInIntro 8s forwards;
		backface-visibility: hidden;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 1.8px;
		margin-bottom: 5px;
	}

	.luxe-intro-container {
		@include mobile {
			padding-left: 0;
			padding-right: 0;
		}

		.intro {
			opacity: 0;
			animation-delay: 4s;
			animation: fadeInIntro 8s forwards;
			backface-visibility: hidden;

			p {
				@include mobile {
					font-size: 14px;
					line-height: 24px;
				}
			}
		}
	}

	@keyframes fadeInTitle {
		0% {
			opacity: 0;
			transform: translate3d(20px, -40%, 0);
		}
		100% {
			opacity: 1;
			transform: none;
		}
	}
	@keyframes fadeInIntro {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes fadeInDecoElements {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 0.12;
		}
	}
	@for $i from 1 through 80 {
		.text-#{$i} {
			opacity: 0;
			animation: fadeInTitle 2s forwards;
			animation-delay: $i * 0.08s;
		}
	}


	.credits {
		@include ft($f_txt, 12px, 400, $c_dark_gold_luxe, 18px);
		text-transform: uppercase;
		@include letter-spacing(150);
		@include mobile {
			font-size: calculateRem(10px);
			line-height: calculateRem(10px);
		}
	}

	// En version mobile on force les éléments avec un data-anim-hide à être visibles
	[data-anim-hide] {
		@include mobile {
			opacity: 1;
		}
	}

	.cta-overlay-search,
	.menu-barre-mobile button .counters {
		background-color: $c_gold_luxe;
	}

	&:not(.no-hover) .menu-barre-mobile button:focus,
	&:not(.no-hover) .menu-barre-mobile button:hover,
	&:not(.no-hover) .wrapper-nav nav ul li > a:hover,
	&:not(.no-hover) .wrapper-nav nav ul li > a:focus {
		color: $c_gold_luxe;
	}

	.footer-conseils {

		.col-25 {
			@include mobile {
				margin-top: 30px;
			}
		}

		.wrapper-top-conseils {
			.title-h2 {
				@include mobile {
					margin-bottom: 10px
				}
			}

			p {
				@include ft($f_txt, 20px, 600, $c_gold_luxe, 30px);

				@include mobile {
					font-size: 16px !important;
					line-height: 20px !important;
				}
			}
		}

		[class^="icon-"], [class*=" icon-"] {
			font-size: 35px;
			color: $c_gold_luxe;
		}

	}

	.bloc-relation-onglet-wrapper {
		.bloc-relation-onglet-title {
			h2, p {
				color: white;
			}
	
			h2 {
				@include mobile {
					font-size: 18px;
					line-height: 28px;
					font-weight: 600;
					margin-top: 3px;
					margin-bottom: 10px;
				}
			}
		}

		a.btn {
			background-color: $c_gold_luxe;
		}
	}

	#footer {
		@include mobile {
			margin-top: 0;
		}
	}

}

.page-template-landing-luxe-generale {
	main {
		@include mobile {
			overflow-x: hidden;
		}
	}

	main header.fullpage-header--luxe.no-pic {

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 32%;
			width: 140px;
			height: 350px;
			background: #FFF;
			opacity: 0.12;

			@include desktop {
				top: 32%;
			}

			@include tablet_portrait {
				left: 70px;
			}

			@include mobile {
				left: 0;
				top: 100%;
				z-index: 1;
				height: 180px;
			}
		}

		&::after {
			content: "";
			position: absolute;
			left: 31%;
			top: 68%;
			width: 140px;
			height: 350px;
			background: #FFF;
			opacity: 0.12;
			transform: rotate(45deg);

			@include desktop {
				top: 69%;
				left: 33%;
			}

			@include tablet_portrait {
				left: 65%;
				top: 59%;
			}

			@include mobile {
				left: 71%;
				top: 125%;
				width: 90px;
				height: 180px;
			}
		}

		&:before, &:after {
			opacity: 0;
			animation-delay: 6s;
			animation: fadeInDecoElements 8s forwards;
			backface-visibility: hidden;
		}

	}

	.btn-container {
		position: relative;
		margin-top: 25px;
	}

	footer {
		.btn-scroll-top-generale {
			bottom: auto;
			top: 0;
		}
	}

	.btn-scroll-bottom-generale, .btn-scroll-top-generale {
		border-radius: 50%;
		-webkit-appearance: none;
		resize: none;
		box-shadow: none;
		border: none;
		width: 50px;
		height: 50px;
		background-color: rgba($c_dark, 0.75);
		display: none;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 120px;
		left: 50%;
		transform: translateX(-50%);
		@include addTransition();
		@include mobile {
			display: flex;
			bottom: -6px;
		}

		[class^="icon-"], [class*=" icon-"] {
			color: $c_dark_gold_luxe !important;
			font-size: calculateRem(18px);
		}

		&.is-visible {
			opacity: 1;
		}

		&.is-hidden {
			opacity: 0;
		}
	}
}

main header.fullpage-header--luxe.no-pic {
	background-color: #181E21;
	padding-top: 0;
	padding-bottom: 0;

	.js-scroll-search-display {
		@include tablet_portrait {
			&::before {
				content: "";
				position: absolute;
				width: 130px;
				top: 9px;
				left: 50%;
				transform: translateX(-50%);
				height: 4px;
				background-color: rgba(#fff, 0.6);
				border-radius: 4px;
				z-index: 90;
			}
		}
	}

	.caption {
		margin-top: 0;
		text-align: center;

		h1, h1 span {
			color: $c_gold_luxe;
			font-weight: 100;
			font-weight: 300;

			@include tablet_portrait {
				font-size: 40px;
			}
			@include mobile {
				font-size: 24px;
				line-height: 28px;
			}

		}

		h1 {
			@include mobile {
				margin-left: 10px;
				margin-right: 10px;
				margin-bottom: 30px;
			}
		}

		#breadcrumb {
			a, span {
				color: $c_white_luxe;
			}
		}

		p {
			color: #fff;
			max-width: 900px;
		}
	}
}

.landing-luxe-content {
	background-color: #181E21;
	overflow: hidden;

    .bloc-texte-mobile {
        @include mobile {
            width: 100%;
        }
    }

}

.landing-luxe-blocks {

	figure {
		z-index: 9;
	}

	&__item {

		@include mobile {
			margin-bottom: 20px !important;

			.credits {
				text-align: right !important;
			}
		}

		&.bloc-texte-image .content-wrapper .bloc-img {
			padding: 0;

			@include tablet_portrait {
				margin-bottom: 22px;
				order: 1 !important;
			}
		}


		&.bloc-align-left {

			@include tablet_portrait {
				.content-wrapper.is-vertical-center {
					flex-direction: column;
					align-items: flex-start;
				}
			}

			.bloc-texte {
				padding-left: 34px;
				padding-right: 10%;

				@include tablet_portrait {
					padding-left: 0
				}

			}
		}

		&.bloc-align-right {

			.content-wrapper.is-vertical-center {
				@include tablet_portrait {
					align-items: flex-start;

					.bloc-img, .bloc-texte {
						margin-left: auto;
						padding-right: 0;
					}
				}
			}

			.bloc-texte {
				padding-right: 34px;
				text-align: right;
				padding-left: 26%;

				@include desktop {
					padding-left: 10%;
				}

				@include tablet_portrait {
					text-align: left;
					padding-left: 0 !important;
					padding-right: 0 !important;
				}
			}
		}

		&.bloc-texte-image {

			@include tablet_portrait {
				.col-33, .col-40 {
					width: 100%;
					display: block;
					margin: 0;
				}

			}

			.bloc-texte {
				margin-top: 0 !important;
				display: block !important;

				@include tablet_portrait {
					width: 100% !important;

					.btn--fancy {
						display: block;
						width: 100%;
						box-sizing: border-box;
						text-align: center;
					}
				}
			}

			.bloc-texte-image__headline {
				@include ft($f_txt, 20px, 300, $c_gold_luxe, 30px);
				text-transform: uppercase;
				margin-bottom: 0;
				display: block;

				@include tablet_portrait {
					@include ft($f_txt, 13px, 300, $c_gold_luxe, 20px);
				}
			}

			.bloc-texte-image__title {
				color: $c_white;
				margin-top: 0;
				margin-bottom: 10px;
				display: block;

				@include mobile {
					width: 75%;
					margin-bottom: 20px;
				}
			}
		}

		&.landing-luxe-blocks__item {
			@include desktop1200 {
				margin-top: 0 !important;
			}
		}

		&.landing-luxe-blocks__item--1 {
			.bloc-img {
				&::after {
					content: "";
					position: absolute;
					top: 55%;
					left: 100%;
					width: 140px;
					height: 350px;
					background: #FFF;
					opacity: 0.12;
					z-index: 0;
					transform: rotate(90deg);
					animation: fadeInDecoElements 8s forwards;

					@include mobile {
						top: 122%;
						left: 122%;
						width: 90px
					}
				}
			}

			figure {
				@include aspect-ratio(525, 497);

				@include tablet_portrait {
					@include aspect-ratio(346, 230);
				}
			}
		}

		&.landing-luxe-blocks__item--2 {
			margin-top: -8%;

			figure:nth-of-type(1) {
				@include aspect-ratio(342, 308);
			}

			.image-wrapper:nth-of-type(1) {
				position: absolute;
				right: -10%;
				width: 70%;
				top: -54%;
				display: flex;
				flex-direction: column-reverse;

				@include tablet_portrait {
					position: relative;
					float: right;
					margin-bottom: -18%;
					
					display: none;
				}

				.credits {
					position: static;
					padding-right: 0px;
					top: 0;
					max-width: 80%;
					margin-bottom: 5px;
					max-width: 80%;
					@include tablet_portrait {
						text-align: right;
					}
				}
			}


			.image-wrapper:nth-of-type(2) {
				margin-top: 0;
				margin-left: 0 !important;
				width: auto;

				.credits {
					position: static;
					width: 100%;
					display: block;
					max-width: 80%;
					padding-right: 0;
					@include mobile {
						margin-top: 10px;
					}

					@include tablet_portrait {
						text-align: right;
					}
				}

				figure {
					@include tablet_portrait {
						@include aspect-ratio(346, 230);
					}
				}
			}
		}

		&.landing-luxe-blocks__item--3 {
			margin-top: -8%;

			figure {
				@include aspect-ratio(525, 443);

				@include tablet_portrait {
					@include aspect-ratio(346, 230);
				}
			}
		}

		&.landing-luxe-blocks__item--4 {
			margin-top: -12%;

			figure {
				@include aspect-ratio(442, 458);

				@include tablet_portrait {
					@include aspect-ratio(346, 230);
				}
			}
		}

		&.landing-luxe-blocks__item--5 {
			margin-top: -5%;

			figure:nth-of-type(1) {
				@include aspect-ratio(351, 308);
			}

			.image-wrapper:nth-of-type(1) {
				position: absolute;
				left: -10%;
				width: 70%;
				top: -54%;
				display: flex;
				flex-direction: column-reverse;
				@include tablet_portrait {
					display: none;
				}

				.credits {
					position: static;
					padding-left: 0px;
					top: 0;
					margin-right: auto;
					margin-bottom: 5px;
					max-width: 80% !important;
					@include tablet_portrait {
						text-align: right;
					}
				}
			}

			figure:nth-of-type(2) {
				@include aspect-ratio(525, 372);
			}

			.image-wrapper:nth-of-type(2) {
				margin-top: 0;
				width: 100%;
				margin-right: 0 !important;

				.credits {
					position: static;
					width: 100%;
					display: block;
					padding-left: 0;
					margin-right: auto;
					@include mobile {
						margin-top: 10px;
					}
					@include tablet_portrait {
						text-align: right;
					}
				}

				figure {
					@include tablet_portrait {
						@include aspect-ratio(346, 230);
					}
				}
			}

		}

		&.landing-luxe-blocks__item--6 {
			margin-top: -10%;

			&::after {
				content: "";
				position: absolute;
				top: -30%;
				right: 0;
				width: 8%;
				height: 70%;
				background: #FFF;
				opacity: 0.12;
				z-index: 0;

				@include tablet_portrait {
					content: none;
				}
			}

			.bloc-img {
				&::after {
					content: "";
					position: absolute;
					top: 30%;
					right: 70%;
					width: 70%;
					height: 26%;
					background: #FFF;
					opacity: 0.12;
					z-index: 0;
					transform: rotate(45deg);

					@include tablet_portrait {
						top: 110%;
						right: auto;
						left: 60%;
						width: 50%;
						height: 22%;
					}
				}
			}

			figure {
				@include aspect-ratio(445, 390);

				@include tablet_portrait {
					@include aspect-ratio(346, 230);
				}
			}
		}

	}
}

.btn--fancy {
	position: relative;
	background-color: transparent;
	border: 1px solid rgba(#897949, 0.8);
	padding-left: 30px;
	padding-right: 30px;
	display: inline-flex;
	align-items: flex-start;
	min-width: 0;
	font-weight: 600;

	&:hover {
		border-color: rgba($c-white, 0.8);
		background-color: transparent !important;
	}

	i {
		color: $c_gold_luxe;
		padding-right: 8px;
	}

}

.btn--no-border {
	border: 0;
	padding-left: 0;
	padding-right: 0;
}

.timeline-slider {
	display: flex !important;
	align-items: flex-start;

	&--desktop {

		@include mobile {
			display: none !important;
		}
	}

	&--mobile {
		display: none !important;

		@include mobile {
			display: inherit !important;
			padding-right: 0px !important;
			.swiper-wrapper {
				padding-left: 20px;
			}
		}
	}
}


.timeline-swiper .swiper-slide,
.overlay-luxe-presta__content__gallery .swiper-slide {
	width: 260px;
	transition: all 0.4s ease-in;
	transition-property: all;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include tablet_portrait {
		width: 150px !important;

		&.swiper-slide-active {
			width: 80% !important;
		}
	}

	&.swiper-slide-active {
		width: 585px;
	}
}

.timeline-card {
	position: relative;
	width: 260px;
	display: inline-block;
	transition: width 0.1s ease-in;
	flex-shrink: 0;
	cursor: pointer;

	@include tablet_portrait {
		width: 150px !important;

		&.active {
			width: 100% !important;
		}
	}

	&:before {
		content: '';
		display: block;
		width: 1px;
		height: 40px;
		background-color: $c_gold_luxe;
		opacity: 0.25;
		position: absolute;
		top: 30px;
		left: 0;
		z-index: -1;
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			opacity: 1 !important;
		}
	}

	&.active {
		width: 585px;
		opacity: 1 !important;

		.timeline-card {
			&__date {
				font-size: 35px;

				@include tablet_portrait {
					font-size: 25px;
					line-height: 25px;
				}
			}

			&__title {
				font-size: 35px;
				line-height: 35px;

				@include tablet_portrait {
					font-size: 20px;
					line-height: 22px;
				}
			}

			&__text {
				transition-delay: 1s;
				display: inherit;
				opacity: 0.6;
			}
		}
	}

	&__date {
		display: flex;
		@include ft($f_txt, 20px, 300, $c_gold_luxe !important, 35px);
		margin-bottom: 10px;
		transition: font-size 0.4s ease-in;

		@include tablet_portrait {
			@include ft($f_txt, 16px, 300, $c_gold_luxe !important, 25px);
		}

		&--bold {
			font-weight: 600;
		}
	}

	figure {
		@include aspect-ratio(585, 387);
		transition: all 0.4s ease-in;

		> img, > picture {
			@extend %center;
			transition: all 0.4s ease-in;
		}
	}

	&__content {
		padding: 24px;

		@include tablet_portrait {
			padding: 24px 12px;
		}
	}

	&__headline {
		@include ft($f_txt, 14px, 300, $c_gold_luxe, 21px);
		text-transform: uppercase;
		transition: all 0.4s ease-in;
		margin-bottom: 8px;

		@include tablet_portrait {
			@include ft($f_txt, 13px, 300, $c_gold_luxe, 20px);
		}
	}

	&__title {
		@include ft($f_txt, 25px, 600, $c_white, 24px);
		white-space: normal;
		transition: all 0.4s ease-in;

		@include tablet_portrait {
			@include ft($f_txt, 16px, 600, $c_white, 20px);
		}
	}

	&__text {
		@include ft($f_txt, 15px, 300, $c_white, 24px);
		white-space: normal;
		opacity: 0;
		margin-top: 5px;
		transition: opacity 0.16s ease-in;
		@include tablet_portrait {
			@include ft($f_txt, 14px, 300, $c_white, 24px);
		}
	}
}

.landing-luxe__timeline {
	.th-slider-wrapper {
		overflow: visible;
	}

	&__headline {
		@include ft($f_txt, 20px, 300, $c_gold_luxe, 30px);
		text-transform: uppercase;
		@include mobile {
			font-size: calculateRem(16px);
			line-height: calculateRem(16px);
		}
	}

	&__title {
		color: $c_white;
		margin-bottom: 40px;
	}

	.content-wrapper--slider {
		@include tablet_portrait {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}

	div[data-anim-hide="true"] {
		@include mobile {
			opacity: 1;
		}
	}
}

.landing-luxe__map-block {
	background-color: $c_greydark;
	margin-top: 0px;
	padding-bottom: 116px;

	@include tablet_portrait {
		padding-bottom: 70px;
	}

	.content-wrapper {
		display: flex;
		align-items: stretch;

		@include tablet_portrait {
			flex-direction: column;
		}
	}

	&__map {
		width: 50%;
		text-align: right;
		padding-top: 100px;
		flex-shrink: 0;

		@include tablet_portrait {
			width: 100%;
			padding-top: 0;
			svg {
				width: 100%;
			}
		}
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include tablet_portrait {
			margin-top: -60px;
		}

		&::after {
			content: "";
			position: absolute;
			z-index: 0;
			width: 105%;
			height: calc(100% + 70px);
			background-color: #fff;
			opacity: 0.08;
			bottom: -10px;
			right: 0;

			@include tablet_portrait {
				width: 94%;
				left: -10%;
			}

			@include mobile {
				bottom: 10px;
			}
		}
	}

	.bloc-texte {
		position: relative;
		z-index: 2;
		margin-left: 36px;
		width: 60%;

		@include tablet_portrait {
			margin-bottom: 60px;
			margin-top: 60px;
			margin-left: 0;
		}

		@include mobile {
			margin-top: 0;
			width: 78%
		}


		.landing-luxe__map-block {
			&__headline {
				@include ft($f_txt, 20px, 300, $c_gold_luxe, 30px);
				text-transform: uppercase;
				margin-bottom: 0;

				@include mobile {
					font-size: 13px;
					line-height: 20px;
				}
			}

			&__title {
				@include ft($f_txt, 40px, 200, $c_white, 40px);
				margin-bottom: 5px;
				margin-top: 0;

				@include mobile {
					font-size: 18px;
					line-height: 18px;
					font-weight: 600;
					margin-top: 3px;
					margin-bottom: 10px;
				}
			}

			&__text {
				@include ft($f_txt, 15px, 300, $c_white, 24px);
				opacity: 0.75;
				margin-bottom: 10px;

				@include mobile {
					font-size: 13px;
					line-height: 22px;
				}
			}
		}

		.btn {
			@include mobile {
				width: auto;
			}
		}
	}
}

.landing-luxe-prestas {

	&__item {

		figure {
			box-shadow: 0px 5px 90px #1a1e1f;
		}

		&.bloc-texte-image {
			.credits {
				padding: 0;
			}

			@include tablet_portrait {
				.btn--fancy {
					display: block;
					width: 100%;
					box-sizing: border-box;
					text-align: center;
				}
			}
		}

		&.bloc-display-vertical {

			&.display-background {
				margin-bottom: 60px;

				&::before {
					content: "";
					position: absolute;
					width: calc(100% + 120px);
					margin-left: -60px;
					margin-right: -60px;
					height: 80%;
					bottom: 0;
					background-color: $c_white;
					opacity: 0.08;

					@include tablet_portrait {
						width: 98%;
					}
				}
			}

			.bloc-img {
				figure {
					@include aspect-ratio(640, 760);
				}
			}

			.bloc-txt-img {
				figure {
					@include aspect-ratio(745, 460);
					width: calc(100% + 110px);
				}
			}

			&.bloc-align-right {
				.bloc-txt-img {
					figure {
						right: 110px;
					}
				}
			}

			&.bloc-align-left {
				.bloc-txt-img {
					figure {
						left: 0;
					}
				}
			}

			@include tablet_portrait {
				.bloc-img {
					display: none;
				}
				.bloc-txt-img {
					width: 100%;
					order: 2 !important;
				}

				&.bloc-align-right {
					.bloc-txt-img {
						figure {
							width: 100%;
							right: 0;
							margin-left: auto;
							margin-top: -10%;
						}
					}

					.bloc-img {
						margin-right: auto;
						margin-left: 0;
					}
				}

				&.bloc-align-left {
					.bloc-txt-img {
						figure {
							width: 100%;
							left: 0;
							margin-right: auto;
							margin-top: -10%;
						}
					}

					.bloc-img {
						margin-left: auto;
						margin-right: 0;

						.credits {
							text-align: right;
						}
					}
				}

			}


		}

		&.bloc-display-horizontal {

			&.display-background {
				margin-bottom: 60px;

				&::before {
					content: "";
					position: absolute;
					width: calc(60%);
					margin-left: -160px;
					height: 80%;
					bottom: 0;
					background-color: $c_white;
					opacity: 0.08;

					@include tablet_portrait {
						width: 98%;
						margin-left: -60px;
						margin-right: -60px;
					}
				}
			}

			.bloc-img {
				margin-bottom: 10%;

				figure {
					@include aspect-ratio(1020, 630);
					width: 100%;
				}
			}

			.bloc-txt-img {
				figure {
					@include aspect-ratio(460, 460);
					width: calc(100% + 40px);
					left: 0;
				}
			}

			@include tablet_portrait {
				.bloc-img {

					order: 1 !important;
					width: 100%;
					text-align: right;

					figure {
						z-index: 3;
						width: 100%;
						left: 0;
						margin-right: auto;
						@include aspect-ratio(346, 230);
					}
				}
				.bloc-txt-img {
					width: 100%;
					order: 3 !important;
					
					figure, .credits {
						display: none;
					}
					
				}
			}

		}

		.col-50, .col-30, .col-70 {
			padding: 0 !important;
		}

		.bloc-img {
			figure {
				z-index: 1;
			}
		}

		.bloc-txt-img {

			figure {
				z-index: 0;
			}

			.bloc-texte {
				margin: 34px 34px 80px;

				.bloc-texte-image__title {
					color: $c_white;
					margin-bottom: 5px;
					
					@include mobile {
						font-size: 18px;
						line-height: 20px;
					}
				}

				.bloc-texte-image__location {
					@include ft($f_txt, 12px, 300, $c_gold_luxe, 18px);
					margin-bottom: 16px;
					letter-spacing: 1.8px;
					text-transform: uppercase;
				}
			}
		}

		@include tablet_portrait {
			margin: 0 !important;

			.bloc-txt-img {
				.bloc-texte {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}

	}

}

.footer-luxe-contact {

	.txt {
		&::before {
			color: $c_gold_luxe;
		}
	}

	.cta-luxe-contact {
		background: $c_gold_luxe;

		&:hover, &:focus {
			body:not(.no-hover) & {
				background: rgba($c_gold_luxe, 0.8);
			}
		}
	}
}

// Landing luxe thématique

.page-template-landing-luxe-thematique {

	#content {
		background-color: #181E21;
		
		@include mobile {
			padding-bottom: 40px;
			border-bottom: 1px solid rgba(#ccc, 0.1);
		}
	}

	.landing-luxe-prestas__item {
		.credits {
			position: static;
			display: block;
			@include mobile {
				width: 100%;
				margin-top: 14px;
				text-align: right;
			}

			& + .bloc-texte {
				margin-top: 20px;
			}
		}

		.bloc-texte-image__location {
			[class^="icon-"], [class*=" icon-"] {
				margin-right: 5px;
			}
		}

		&:not(.bloc-align-left) {
			.content-wrapper .col-50:first-of-type {
				.credits {
					@include mobile {
						text-align: right;
					}
				}
			}
		}

		img {
			cursor: none;
		}

	}

	.swiper .swiper-wrapper .swiper-slide {
		.credits {
			position: absolute;
			bottom: 40px;
			top: auto;
			left: 40px;
		}
	}

	.swiper .swiper-pagination {
		bottom: 46px;
		right: 40px;
		left: auto;
		text-align: right;
		@include mobile {
			right: 20px;
			padding-right: 0;
			bottom: 20px;
		}
	}

	#cursor_custom_luxe {
		position: fixed;
		top: -30px;
		left: -30px;
		width: 60px;
		height: 60px;
		z-index: 9999999999;
		transition: opacity .2s;
		pointer-events: none;

		@media (hover: none) {
			display: none;
		}

		&.hidden {
			opacity: 0;
		}

		svg {
			width: 60px;
			height: 60px;
		}

		#arc180 {
			fill: none;
			stroke: $c_gold_luxe;
			stroke-width: 4;
		}

		.cursor_custom_path_part {
			transition: opacity .2s;

			fill: none;
			stroke: $c-white;
			stroke-width: 4;

			&.hidden {
				opacity: 0;
			}
		}

		.cursor_custom_luxe__picto {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #fff;
			font-size: 14px;
			transition: opacity .2s;

			&.hidden {
				opacity: 0;
			}

			&--plus {
				transform: translate(-50%, -50%) rotate(45deg);
			}
		}
	}

	.quick-menu-container {

		@include quick-container();

		height: 55px;

		@include min-tablet_portrait {
			right: 95px;
			margin-left: unset;
			left: unset;
			transform: none;
		}
	}
}

body.no-scroll.page-template-landing-luxe-thematique {
	#cursor_custom_luxe {
		.cursor_custom_path_part {
			&.actif {
				stroke: $c_gold_luxe;
			}
		}
	}
}