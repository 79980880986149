// Fonts Pop
// ns
// ==========================================================================
/* Libre Baskerville ajout Le MAG */
@font-face {
	font-family: 'Libre Baskerville';
	src: url('../fonts/LibreBaskerville/LibreBaskerville-Regular.woff2') format('woff2'),
	url('../fonts/LibreBaskerville/LibreBaskerville-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
    font-family: 'Oooh Baby';
    src: url('../fonts/OoohBaby/OoohBaby-Regular.woff2') format('woff2'),
        url('../fonts/OoohBaby/OoohBaby-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-BoldItalic.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-BoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-SemiBoldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-MediumItalic.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-Italic.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-LightItalic.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-Light.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-ExtraLight.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-ExtraLightItalic.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-ExtraLightItalic.woff') format('woff');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-Thin.woff2') format('woff2'),
	url('../fonts/Poppins/Poppins-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}


// Fonts
// ==========================================================================
$f_hx: 'Poppins', Sans-Serif;
$f_txt: 'Poppins', Sans-Serif;
$fnew_txt: 'Libre Baskerville', Times, Serif;

// Colors
// ==========================================================================
$c_main: #7A97AA;
$c_sub: #6FAC90;
$c_third: #DEA99E;
$c_grey: #4B5155;
$c_txt: #172540;
$c_txt_lien: #151B2A;
$c_surtitre: #4A5155;
$c_light_txt: #ABB3B9;
$c_titre: #627A89;
$c_border: #ADB3B8;
$c_border_input: #DCDBDA;
$c_bg_overlay: #343638;
$c_light: #F5F5F5;
$c_dark: #000000;
$c_gradient: #202529;
$c_greydark: #1E2529;
$c_grey70: #707070;
$c_deep_grey: #181E21;
$c_lightgray: #F1F5F7;
$c_white: #FFFFFF;
$c_red: #FF1A7B;
$c_error: #E91741;
$c_facebook: #4E89F7;
$c_twitter: #579AE9;
$c_white_luxe: #B9B5AB;
$c_greenish_blue: #142D38;
$c_dark_gold_luxe: #897949;
$c_dark_grey: #23292C;
$c_main_alt: #738C9F;
$c_white_mice: #B9B5AB;
$c_mice: #6FAC90;
$c_dark_mice: #897949;
$c_gold_luxe: #b99a3f;

// Transitions
// ==========================================================================
$t_duration: .2s;

// Sizes
// ==========================================================================
$s_h1: 66px;
$s_h2: 40px;
$s_h3: 25px;
$s_p: 15px;
$s_link: 14px;

// Width / Height / Spacing
// ==========================================================================
$max-width: 1280px;
$border-gutter: 80px;
$content-spacing: 120px;
$content-spacing-tab: 80px;
$content-spacing-mobile: 60px;
$content-gutter: 40px;
$header-height: 80px;
$copyright-height: 60px;
$grid-extra-small: 900px;
$grid-small: 900px;
$grid-medium: 1120px;
$max-width-large: 1600px - $border-gutter - $border-gutter;

// Breakpoints
// ==========================================================================
$breakpoint_mo_small: 320px;
$breakpoint_mo: 599px;
$breakpoint_tablet_small: 768px;
$breakpoint_portrait: 980px;
$breakpoint_paysage: 1024px;
$breakpoint_desktop: 1280px;
$breakpoint_wd: 1600px;

$breakpoints: (
		mobile-small : 320px,
		mobile : 599px,
		tablet-small : 768px,
		tablet-portrait : 980px,
		tablet-landscape: 1024px,
		desktop : 1280px,
		wide : 1600px
);


$border-radius: 10px;

// CSS Variables
// ==========================================================================
// Changement dynamiques des CSS vars en fonction des breakpoints
:root {
	--vh: 1vh; // unité de viewport height, css var injectée en JS pour le calcul des vh sur mobile

	--grid-gap: 20px;

	--darkblue: #1E2529;
	--deepblue: #212529;
	--blue:#233036;
	

	--rose: #DEA99E;
	--white:#fff;
	--black:#000;

	--grey:#D2D3D4;
	--lightgrey:#F3F4F5;
	--bluegrey:#4B5155;
	--verylightgrey:#ABB3B9;

}