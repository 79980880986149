.myex-form {
	// section
	&__section {
		margin-bottom: calculateRem(40px);
		&:not(:first-child) {
			margin-top: calculateRem(40px);
		}

		@include min-mobile {
			margin-bottom: calculateRem(60px);
			&:not(:first-child) {
				margin-top: calculateRem(60px);
			}
		}

		//title section form
		&__title {
			font-size: calculateRem(20px);
			margin-bottom: calculateRem(20px);
			color: currentColor;
			line-height: 1.5;

			@include min-mobile {
				font-size: calculateRem(18px);
				margin-bottom: calculateRem(40px);
			}
		}
	}
}

