.bloc-form {
	.js .frm_logic_form:not(.frm_no_hide) {
		display: none;
	}

	.frm_conf_field.frm_half label.frm_conf_label {
		overflow: hidden;
		white-space: nowrap;
	}

	.frm_time_wrap {
		white-space: nowrap;
	}

	select.frm_time_select {
		white-space: pre;
		display: inline;
	}

	.frm_repeat_sec {
		margin-bottom: 20px;
		margin-top: 20px;
		padding-bottom: 15px;
	}

	.frm_repeat_sec:last-child {
		border-bottom: none;
		padding-bottom: 0;
	}

	.frm_repeat_inline {
		clear: both;
	}

	.frm_invisible_section .frm_form_field,
	.frm_invisible_section {
		display: none !important;
		visibility: hidden !important;
		height: 0;
		margin: 0;
	}

	.frm_form_field .frm_repeat_sec .frm_add_form_row,
	.frm_section_heading div.frm_repeat_sec:last-child .frm_add_form_row.frm_hide_add_button,
	.frm_form_field div.frm_repeat_grid .frm_add_form_row.frm_hide_add_button,
	.frm_form_field div.frm_repeat_inline .frm_add_form_row.frm_hide_add_button {
		-moz-transition: opacity .15s ease-in-out;
		-webkit-transition: opacity .15s ease-in-out;
		transition: opacity .15s ease-in-out;
		pointer-events: none;
	}

	.frm_form_field .frm_repeat_sec .frm_add_form_row,
	.frm_section_heading div.frm_repeat_sec:last-child .frm_add_form_row.frm_hide_add_button {
		display: none;
	}

	.frm_form_field div.frm_repeat_grid .frm_add_form_row.frm_hide_add_button,
	.frm_form_field div.frm_repeat_inline .frm_add_form_row.frm_hide_add_button {
		visibility: hidden;
	}

	.frm_form_field div.frm_repeat_grid .frm_add_form_row,
	.frm_form_field div.frm_repeat_inline .frm_add_form_row,
	.frm_section_heading div.frm_repeat_sec:last-child .frm_add_form_row {
		display: inline-block;
		visibility: visible;
		pointer-events: auto;
	}

	.frm_add_form_row.frm_button.frm_hidden:last-child, .frm_add_form_row.frm_icon_font.frm_hidden:last-child {
		display: inline-block;
	}

	.frm_repeat_inline .frm_repeat_buttons a.frm_icon_font {
		vertical-align: sub;
	}

	.frm_repeat_inline .frm_repeat_buttons a.frm_icon_font:before {
		vertical-align: text-top;
	}

	.frm_repeat_grid .frm_button,
	.frm_repeat_inline .frm_button,
	.frm_repeat_sec .frm_button {
		display: inline-block;
		line-height: 1;
	}

	.frm_repeat_sec .frm_button .frm_icon_font:before,
	.frm_repeat_grid .frm_button .frm_icon_font:before,
	.frm_repeat_inline .frm_button .frm_icon_font:before {
		line-height: 1;
	}

	.frm_form_field .frm_repeat_grid ~ .frm_repeat_grid .frm_form_field .frm_primary_label {
		display: none !important;
	}

	/* Radio Scale */
	.frm_scale {
		margin-right: 15px;
		text-align: center;
		float: left;
	}
	.frm_scale input {
		display: block;
		margin: 0;
	}

	/* Star ratings */
	.frm-star-group {
		padding-bottom: 10px;
		&:hover {
			label:before {
				color: #F0AD4E;
			}
		}

		label {
			&:hover {
				& ~ label:before {
					color: #E0E0E0 !important;
				}
			}
		}
	}
	.frm-star-group input {
		display: none !important;
		> * {
			float: left;
		}
		&:checked {
			& + label {
				& ~ label {
					&:before {
						color: #E0E0E0 !important;
					}
				}
			}
		}
	}
	.frm-star-group .star-rating,
	.frm-star-group input + label {
		float: left;
		width: 25px;
		height: 25px;
		font-size: 25px;
		line-height: 1;
		cursor: pointer;
		display: block;
		background: transparent;
		overflow: hidden !important;
		clear: none;
		font-style: normal;
		padding-right: 15px;
	}
	.frm-star-group input + label:before,
	.frm-star-group .star-rating:before {
		content: "\e90b";
		@include font();
		color: #F0AD4E;
		display: inline-block;
		vertical-align: top;
	}
	.frm-star-group input[type=radio]:checked + label:before,
	.frm-star-group:not(.frm-star-hovered) input[type=radio]:checked + label:before {
		color: #F0AD4E;
	}
	.frm-star-group:not(.frm-star-hovered) input[type=radio]:checked + label:before,
	.frm-star-group input + label:hover:before,
	.frm-star-group:hover input + label:hover:before,
	.frm-star-group .star-rating-on:before,
	.frm-star-group .star-rating-hover:before {
		color: #F0AD4E;
	}
	.frm-star-group .star-rating-readonly {
		cursor: default !important;
	}

	/* Other input */
	.frm_other_input.frm_other_full {
		margin-top: 10px;
	}
	.frm_left_container .frm_other_input {
		grid-column: 2;
	}
	.frm_inline_container.frm_other_container .frm_other_input,
	.frm_left_container.frm_other_container .frm_other_input {
		margin-left: 5px;
	}
	.frm_right_container.frm_other_container .frm_other_input {
		margin-right: 5px;
	}
	.frm_inline_container.frm_other_container select ~ .frm_other_input,
	.frm_right_container.frm_other_container select ~ .frm_other_input,
	.frm_left_container.frm_other_container select ~ .frm_other_input {
		margin: 0;
	}

	/* Pagination */
	.frm_pagination_cont ul.frm_pagination {
		display: inline-block;
		list-style: none;
		margin-left: 0 !important;
	}
	.frm_pagination_cont ul.frm_pagination > li {
		display: inline;
		list-style: none;
		margin: 2px;
		background-image: none;
	}
	ul.frm_pagination > li.active a {
		text-decoration: none;
	}
	.frm_pagination_cont ul.frm_pagination > li:first-child {
		margin-left: 0;
	}
	.archive-pagination.frm_pagination_cont ul.frm_pagination > li {
		margin: 0;
	}

	/* Calendar Styling */
	.frmcal {
		padding-top: 30px;
	}
	.frmcal-title {
		font-size: 116%;
	}
	.frmcal table.frmcal-calendar {
		border-collapse: collapse;
		margin-top: 20px;
	}
	.frmcal table.frmcal-calendar,
	.frmcal,
	.frmcal-header {
		width: 100%;
	}
	.frmcal-header {
		text-align: center;
	}
	.frmcal-prev {
		margin-right: 10px;
	}
	.frmcal-prev,
	.frmcal-dropdown {
		float: left;
	}
	.frmcal-dropdown {
		margin-left: 5px;
	}
	.frmcal-next {
		float: right;
	}
	.frmcal table.frmcal-calendar thead tr th {
		text-align: center;
		padding: 2px 4px;
	}
	.frmcal table.frmcal-calendar tbody tr td {
		height: 110px;
		width: 14.28%;
		vertical-align: top;
		padding: 0 !important;
		color: $c_txt;
		font-size: 12px;
	}
	table.frmcal-calendar .frmcal_date {
		background-color: #A1A1A1;
		padding: 0 5px;
		text-align: right;
		box-shadow: 0 2px 5px rgba($c_dark, 0.1);
	}
	table.frmcal-calendar .frmcal-today .frmcal_date {
		background-color: #A1A1A1;
		padding: 0 5px;
		text-align: right;
		box-shadow: 0 2px 5px rgba($c_dark, 0.1);
	}
	.frmcal_day_name,
	.frmcal_num {
		display: inline;
	}
	.frmcal-content {
		padding: 2px 4px;
	}
	/* End Calendar Styling */

	/* Start Toggle Styling */
	.frm_switch_opt {
		padding: 0 8px 0 0;
		white-space: normal;
		display: inline;
		vertical-align: middle;
	}
	.frm_on_label {
		padding: 0 0 0 8px;
	}
	.frm_switch {
		position: relative;
		display: inline-block;
		width: 60px;
		height: 24px;
		vertical-align: middle;
	}
	.frm_switch input {
		display: none !important;
	}
	.frm_slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #E0E0E0;
		transition: .4s;
		border-radius: 30px;
	}
	.frm_slider:before {
		border-radius: 50%;
		position: absolute;
		content: "";
		height: 15px;
		width: 15px;
		left: 3px;
		top: 4px;
		background-color: white;
		transition: .4s;
		box-shadow: 0 2px 5px rgba($c_dark, 0.2);
	}
	input:checked + .frm_slider {
		background-color: $c_txt;
	}
	input:checked + .frm_slider:before {
		transform: translateX(38px);
	}

	/* Range slider */
	.frm_range_value {
		display: inline-block;
		margin-top: 20px;
	}
	input[type=range] {
		@extend %appearance;
		border-radius: 25px;
		border: none;
		height: 10px;
		cursor: pointer;
		padding: 0;
		display: block;
		width: 100%;
		font-size: 14px;
		background: #E0E0E0;
		margin: 10px 0 -5px 0;

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			-webkit-border-radius: 20px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 1px solid $c_dark;
			color: $c_txt;
			background-color: $c_dark;
			cursor: pointer;
			//margin-top: -11px;
			transition: background .15s ease-in-out;
			&:hover {
				background: $c_dark;
			}
		}

		&:active::-webkit-slider-thumb {
			background: $c_dark;
		}

		&::-moz-range-thumb {
			-webkit-appearance: none;
			-webkit-border-radius: 20px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 1px solid $c_dark;
			color: $c_txt;
			background-color: $c_dark;
			cursor: pointer;
			//margin-top: -11px;
			transition: background .15s ease-in-out;

			&:hover {
				background: $c_dark;
			}
		}

		&:active::-moz-range-thumb {
			background: $c_dark;
		}
	}
	input[type=range]:active,
	input[type=range]:focus {
		outline: none;
		box-shadow: none !important;
	}
	.frm_range_container {
		text-align: center;
	}
	input[type=range]::-moz-range-track {
		border-color: transparent;
		border-width: 39px 0;
		color: transparent;
	}

	/* Password strength meter CSS */
	@include mobile {
		.frm-pass-req, .frm-pass-verified {
			width: 50% !important;
			white-space: nowrap;
		}
	}
	.frm-pass-req, .frm-pass-verified {
		float: left;
		width: 20%;
		line-height: 20px;
		font-size: 12px;
		padding-top: 4px;
		min-width: 175px;
	}
	.frm-pass-req:before, .frm-pass-verified:before {
		padding-right: 4px;
		font-size: 12px !important;
		vertical-align: middle !important;
	}
	span.frm-pass-verified::before {
		content: '\e606';
	}
	span.frm-pass-req::before {
		content: '\e608';
	}
	div.frm-password-strength {
		width: 100%;
		float: left;
	}
	div.frm_repeat_grid:after, div.frm_repeat_inline:after, div.frm_repeat_sec:after {
		content: '';
		display: table;
		clear: both;
	}

}