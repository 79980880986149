.splide-wrapper-push-thematique {

    .splide-push-thematique-tab {
        position: relative;
        margin-bottom: 0;
        @include tablet_landscape{
            margin-bottom: 10px;
        }


        &:before {
            content: '';
            position: absolute;
            left: 85px;
            bottom: 20px;
            width: 100vw;
            height: 1px;
            background: #D3D8E7;
            z-index: -1;

            @include min-large-desktop {
                left: 10vw;
            }

            @include tablet_landscape {
                left: 40px;
            }

            @include mobile {
                left: 20px;
            }

        }

        .splide__track {
            padding-bottom: 20px;

            [class^="icon-"], [class*=" icon-"] {
                font-size: 60px;
                color: $c_main;
                display: block;
                margin: 0 auto 15px auto;
                pointer-events: none;
                @include addTransition();
                @include mobile {
                    font-size: 50px;
                }
            }

            button {
                @include ft($f_txt, 14px, 500, $c_greydark, 17px);
                @extend %appearance;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                background: none;
                position: relative;
                margin: 0 50px;
                padding: 0 0 55px;
                @include addTransition();
                @include tablet_landscape {
                    margin: 0 40px;
                    padding: 0 0 40px;
                }
                @include mobile {
                    font-size: calculateRem(12px);
                    line-height: calculateRem(15px);
                    padding: 0 0 35px;
                    margin: 0 40px 0 0;
                }

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        cursor: pointer;

                        [class^="icon-"], [class*=" icon-"] {
                            color: $c_third;
                        }
                    }
                }

                &.is-active {
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -8px;
                        left: 50%;
                        margin-left: -8px;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: $c_third;
                    }

                    [class^="icon-"], [class*=" icon-"] {
                        color: $c_third;
                    }
                }
            }
        }
    }
    // LES SLIDERS
    .bloc-push-thematique {
        .splide-push-thematique {
            display: flex;

            @include tablet_landscape {
                flex-direction: column;
            }
            @include clearfix();

            .left-txt {
                position: relative;
                box-sizing: border-box;
                z-index: 100;
                width: 400px;
                background: $c_white;
                padding: 40px 40px 40px 0;
                text-align: right;
                margin-left: 120px;

                @include min-large-desktop {
                    margin-left: 10vw;
                }

                @include tablet_landscape {
                    text-align: left;
                    float: none;
                    width: 100%;
                    margin: 0;
                    padding: 20px 0 0 40px;
                }
                @include mobile {
                    padding: 5px 40px 15px 20px;
                }

                > p, > ul, > .btn {

                    @include tablet_landscape {
                        display: none;
                    }
                }

                h2{
                    @include desktop1280{
                        margin-bottom: 0;
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 100%;
                    width: 100vw;
                    height: 100%;
                    background: $c_white;
                }
            }

            .splide__track {
                padding-top: 40px;

                @include mobile {
                    padding-top: 0;
                }

                .splide__arrow {
                    color: #fff;
                    background: #7A97AA;
                    border-radius: 4px;
                    width: 60px;
                    height: 60px;
                    opacity: 1;

                    @include tablet_landscape {
                        width: 40px;
                        height: 40px;
                    }

                    &--prev {
                        left: 10px;

                        @include tablet_landscape {
                            left: 30px;
                        }
                    }

                    &--next {
                        right: 25px;

                        @include tablet_landscape {
                            right: 30px;
                        }
                    }

                    &:disabled {
                        opacity: 0 !important;
                    }

                    &:hover {
                        transition-duration: 300ms;
                        background-color: #DEA99E;
                        opacity: 1;
                    }

                    @include mobile {
                        display: none;
                    }
                }
            }
        }

        .link-push-thematique-mobile {
            text-align: right;
            margin-right: 40px;
            margin-top: 15px;

            @include mobile {
                margin-right: 20px;
            }

            @include min-tablet_landscape {
                display: none;
            }
        }
    }
}