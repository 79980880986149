.wrapper-facettes{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: sticky;
    -ms-position: sticky;
    top: 80px;
    background: $c_white;
    padding: 10px 0 10px 20px;
    z-index: 2000;
    margin-left: -20px;
    @include tablet_portrait{
        display: none;
    }
}

// Tri par défault
.wrap-select{
    position: relative;

    text-align: right;

    &:after {
        content: "\e967";
        @include font();
        font-size: calculateRem(16px);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        pointer-events: none;
    }

    > label{
        @include ft('', 12px, 600, $c_greydark, 1.16);
        display: block;
        padding-right: 36px;
        cursor: pointer;
    }

    .selectric-wrapper {
        padding-right: 36px;
    }

    .selectric {
        overflow: visible;
        width: auto;
        border: none;

        .label {
            @include ft('', 12px, 400, $c_greydark, 1.16);
            padding: 0;
            height: auto;
            overflow: visible;
            @include addTransition();

            &:after {
                display: none;
            }
        }
    }

    .selectric-items {
        left: initial;
        right: 16px;
        min-width: 130px;

        .selectric-scroll ul li {
            white-space: nowrap;
        }
    }
}

// Form to pagination
.indic-pagination{
    @include ft('', 13px, 400, rgba($c_dark_grey, 0.5), '');
    text-align: right;
}