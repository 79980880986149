.discover-section {

	@include mobile {
		padding-left: 0;
		padding-right: 0;
	}

	&__header {
		position: sticky;
		z-index: 99;
		top: 5px;

		@include tablet_landscape {
			top: 20px;
		}

		@include mobile {
			top: 50px;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: calc(100% - 8px);
			background: white;
		}
		
		h2 {
			margin-bottom: 30px;

			@include mobile {
				margin-bottom: 10px;
			}
		}

		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			bottom: 6px;
			background-color: rgba($c_light_txt, 0.5);
			height: 1px;
			width: 100%;
		}

		@include mobile {
			margin-bottom: 15px;
		}
	}

	&__header-content {
		display: flex;

		@include tablet_portrait {
			flex-direction: column;

			.discover-section__categories-slider {
				margin-top: 20px;
			}
		}

		@include mobile {
			.discover-section__categories-slider {
				margin-top: 6px;
			}
		}
	}



	&__select {
		position: relative;
		width: 345px;
		height: 60px;
		margin-right: 10px;
		flex-shrink: 0;

		@include tablet_portrait {
			width: 100%;
		}

		&::after {
			font-family: 'icomoon';
			content: "\e9c6";
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 20px;
			color: black;

			@include mobile {
				top: 38%;
				right: 14px;
			}
		}

		select {
			width: 100%;
			height: 100%;
			background-color: #F3F4F5;
			border: 0;
			border-radius: 5px 5px 0 0;
			border-bottom: 2px solid $c_third;
			@include ft($f_hx, 20px, 600, $c_txt, 62px);
			padding: 0 0 0 15px;
			cursor: pointer;

			@include mobile {
				font-size: 18px;
				line-height: 2.8;
				height: 45px;
			}
		}
	}

	&__categories-slider {
		width: 100%;
		overflow: hidden;

		@include tablet_landscape {
			overflow: visible;
		}

		.splide-decouvrez-offres-tab {
			display: none;

			&.active {
				display: flex;
			}

			.splide__arrow {
				top: unset;
				bottom: -10px;
				background-color: white;
				opacity: 1;
				border-radius: unset;
				padding: 35px;

				&:hover {
					opacity: 1;
				}

				&--prev {
					left: unset;
					right: 45px;
					padding-left: 10px;

					&:after {
						content: '';
						position: absolute;
						top: 0;
						left: unset;
						right: 45px;
						height: 100%;
						background: -moz-linear-gradient(right,  $c_white 20%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(right,  $c_white 20%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to left,  $c_white 20%,rgba(0,0,0,0) 100%);
						opacity: 1;
						width: 150px;
					}
				}

				&--next {
					right: 0;
				}

				span:before {
					font-size: 18px;
				}

				@include tablet_portrait {
					display: none !important;
				}
			}
		}




	}

	&__category {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 5px;
		padding: 0 5px;
		cursor: pointer;
		color: $c_greydark;
		margin-bottom: 28px;
		transition: color 0.16s ease-in;

		@include mobile {
			margin: 0 0 18px 0;

			&:first-child {
				padding-left: 0
			}
		}

		&__icon {
			font-size: 35px;
			margin-bottom: 10px;
			color: inherit;

			@include mobile {
				font-size: 27px;
				margin-bottom: 5px;
			}
		}

		&__title {
			@include ft($f_txt, 12px, 600, inherit, 14px);

			@include mobile {
				font-size: 11px;
			}
		}

		&.selected {
			color: $c_third;

			&.splide__slide {
				&::after {
					content: "";
					position: absolute;
					bottom: -28px;
					background-color: $c_third;
					width: 12px;
					height: 12px;
					border-radius: 100%;

					@include mobile {
						bottom: -18px;
					}
				}
			}
		}

		&:hover {
			color: $c_third;
		}
	}


	&__offers {
		display: none;
		grid-template-columns: repeat(4, minmax(20%, 1fr));
		gap: 30px;

		&.active {
			display: grid;
			margin-top: 30px;
		}

		@include tablet_landscape {
			grid-template-columns: repeat(2, minmax(20%, 1fr));
			gap: 12px;
		}

		@include mobile {
			grid-template-columns: repeat(1, minmax(20%, 1fr));
			gap: 12px;
			margin-bottom: 20px;

			.card.card-offre {
				margin-bottom: 10px;
			}
		}

		.card.card-offre > div {
			position: static;
		}
	}

	.cta-expand {
		position: absolute;
		z-index: 95;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		background: $c_main;
		min-width: auto;
		color: #fff;
		padding: 0 20px;
		margin: 0;
		width: auto;
		height: 60px;
		font-size: calculateRem(30px);
		font-weight: 300;
		line-height: 65px;

		&::before {
			content: none;
		}

		@include mobile {
			line-height: 55px;
			height: 45px;
			width: 45px;
			align-items: center;
		}
	}
	.to-expand:not(.expand) {
		height: 45vw !important;

		@include wide {
			height: 40vw !important;
		}

		@include mobile {
			height: 1225px !important;
		}

		&::before {
			height: 125px;
		}
	}
}