.portrait {


    @include tablet_portrait {
		&#content.content--scrollable {
			margin-top: -29px;
		}
	}


    background-color: var(--darkblue);

    .lemag__title {
        @include mobile {
            display: none;
        }
    }

    &__bg {

        position: relative;

        &::before {
            content: '';
            position: absolute;
            z-index: 0;
            top: -185px;
            width: 100%;
            height: calc(100% + 185px);
            background-color: var(--white);
        }
    }

    &__header {
        &::before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            z-index: 10;
            top: 8px;
            margin: auto;
            width: 129px;
            height: 4px;
            border-radius: 2px;
            background-color: rgba(#1E2529, 0.3);
            display: none;

            @include mobile {
                display: block;
            }
        }

        position: relative;
        z-index: 1;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        max-width: 1440px;
        width: 100%;
        margin: 0px auto;
        background-color: var(--white);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        @include mobile {
            display: block;
        }

        figure {
            border-top-left-radius: 10px;
            overflow: hidden;
            width: 615px;
            height: 792px;

            @include mobile {
                width: 100%;
                height: 683px;
                border-top-right-radius: 10px;
            }

            &::before {
                @include mobile {
                    top: unset;
                    bottom: 0;
                    background: linear-gradient(to top, #202529 0%, rgba(32, 37, 41, 0) 100%);
                }
            }
        }

        &_content {
            width: 855px;
            padding: 70px 105px;

            @include mobile {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 11;
                width: 100%;
                height: 100%;
                padding: 0;

                display: flex;
                flex-direction: column;

            }

            &__headline {

                position: relative;
                font: normal normal normal 11px/17px Poppins;
                letter-spacing: 1.65px;
                color: var(--darkblue);
                text-transform: uppercase;
                text-align: center;
                padding-left: 13px;
                margin-bottom: 7px;
                display: flex;

                flex-direction: column;
                align-items: center;

                @include mobile {
                    padding-top: 25px;
                    padding-left: 0px;
                    color: var(--white);
                    display: unset;
                }

                strong {
                    position: relative;

                    @include tablet_portrait {
                        margin-left: 8px;
                    }

                    &::before {
                        content: '\e907';
                        @include font();
                        font-size: 10px;
                        color: var(--darkblue);
                        top: 3px;
                        left: -14px;
                        position: absolute;
                        height: 10px;
                        width: 6px;

                        @include mobile {
                            vertical-align: unset;
                            padding-right: 6px;
                            position: relative;
                            color: var(--white);
                        }
                    }
                }

            }

            h2 {
                margin-top: 60px;
                transform: matrix(0.99, -0.1, 0.1, 0.99, 0, 0);
                text-align: left;
                font: normal normal normal 76px/60px Oooh Baby;
                letter-spacing: 0px;
                color: var(--darkblue);

                @include mobile {
                    position: absolute;
                    bottom: 60px;
                    left: 0;
                    right: 0;
                    color: var(--white);
                    text-align: center;
                    font-size: 46px;
                    line-height: 40px;
                    

                }
            }

            h1 {
                padding-top: 15px;
                margin-bottom: 30px;
                font: normal normal bold 24px/30px Poppins;
                text-align: left;
                color: var(--darkblue);

                @include mobile {
                    position: absolute;
                    margin: 0;
                    bottom: 20px;
                    left: 0;
                    right: 0;
                    color: var(--white);
                    text-align: center;
                    font-size: 15px;
                    line-height: 21px;
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }

            p {
                font: normal normal normal 22px/34px Libre Baskerville;
                text-align: left;
                color: var(--black);

                @include mobile {
                    display: none;
                }
            }

            strong {
                @include mobile {
                    display: none;
                }
            }
        }



        .breadcrumbs-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 25px;

            @include tablet_portrait {
                flex-direction: column-reverse;
                align-items: baseline;
            }

            @include mobile {
                display: none;
            }
        }


    }

    &__nav {
        position: relative;
        z-index: 5;
        max-width: 1600px;
        width: 100%;
        margin: 0 auto;
        background-color: #fff;
    }


}