.bloc-texte-image {
    padding-left: 0;
    padding-right: 0;

    @include mobile {
        padding-top: 45px;
    }

    // Si l'otion background est cochée
    &.with-background {
        background: $c_main;
        padding: 80px 0;
        margin: $content-spacing/2 auto;
        @include clearfix();
        @include tablet_landscape {
            margin: $content-spacing-tab/2 auto;
            padding: 60px 0;
        }
        @include mobile {
            padding: 40px 0;
            margin: $content-spacing-mobile/2 auto;
        }

        .bloc-texte {
            @extend %bloc-texte-white;
            .btn {
                color: $c_greydark;
                background: $c_white;
                &:hover, &:focus {
                    body:not(.no-hover) & {
                        color: $c_white;
                        background: $c_greydark;
                    }
                }
            }
        }

        .credits{
            color: $c_white;
        }

        & + .with-background {
            margin-top: $content-spacing;
            @include tablet_landscape {
                margin-top: $content-spacing-tab;
            }
            @include mobile {
                margin-top: $content-spacing-mobile;
            }
        }
    }

    // Si 2 images sont renseignées
    &.two-images {
        figure{
            @include aspect-ratio(1, 1);
            &:first-child{
                & + .credits{
                    @include mobile{
                        bottom: 100%;
                        top: auto;
                        margin-bottom: 4px;
                        margin-top: 0;
                        text-align: left;
                    }
                }
            }
            & ~ figure{
                position: relative;
                margin-left: auto;
                right: 0;
                top: 0;
                margin-right: -40px;
                margin-top: calc(-25% - 12px);
                width: calc(50% + 25px);
                @include tablet_landscape{
                    margin-right: -20px;
                }
                @include mobile{
                    margin-top: -40px;
                    margin-left: auto;
                    margin-right: auto;
                    width: calc(100% - 40px);
                }

                & + .credits {
                    text-align: right;
                    max-width: calc(50% + 25px);
                    padding: 0;
                    margin-left: auto;
                    @include tablet_landscape{
                        max-width: calc(50% + 5px);
                    }
                    @include tablet_portrait{
                        max-width: calc(50% + 15px);
                    }
                    @include mobile{
                        max-width: 100%;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                }
            }
        }
        .credits{
            max-width: calc(50% + 15px);
            @include tablet_landscape{
                max-width: calc(50% - 5px);
            }
            @include tablet_portrait{
                max-width: calc(50% - 25px);
            }
            @include mobile{
                max-width: 100%;
                text-align: left;
            }
        }
    }


    .content-wrapper {
        @include clearfix();

        &.is-vertical-center{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        // col-50
        > div {
            position: relative;
            padding: 0 $border-gutter/2;
            @include clearfix();
            @include tablet_landscape{
                padding: 0 20px;
            }
            @include mobile {
                padding: 0;
                display: flex;
                flex-wrap: wrap;
            }

            &:first-child{
                @include tablet_portrait{
                    padding-left: 0;
                }
            }
            &:last-child{
                @include tablet_portrait{
                    padding-right: 0;
                }
            }

            &.bloc-texte{
                margin-top: 50px;
                @include tablet_landscape{
                    margin-top: 40px;
                }
                @include mobile{
                    margin-top: 35px;
                }
            }

            > * {
                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .no-photo {
        width: 100%;
        @include aspect-ratio(1, 1);
    }

    figure {
        position: relative;
        width: 100%;
        @include aspect-ratio(640, 918);
        overflow: visible;
        background: none;

        img, > picture {
            @extend %center;
        }

        figcaption {
            display: none;
        }
    }

    .credits{
        padding: 0 $border-gutter/2;
        margin-top: 10px;
        @include tablet_landscape{
            padding: 0 20px;
        }
        @include tablet_portrait{
            padding: 0;
        }
        @include mobile{
            top: 100%;
            margin-top: 5px;
        }
    }


    // Image à gauche - Texte à droite
    // ==========================================================================
    &.bloc-align-left {

        &.two-images{
        }

        .content-wrapper {
            > div {
                &:nth-of-type(1) {
                    float: left;
                    order: 1;
                    @include mobile {
                        float: none;
                        order: 1;
                    }
                }

                &:nth-of-type(2) {
                    float: right;
                    order: 2;
                    @include mobile {
                        order: 2;
                    }
                }
            }
        }
    }


    // Image à droite - Texte à gauche
    // ==========================================================================
    &.bloc-align-right {

        &.two-images{
        }

        .content-wrapper {
            > div {
                > * {
                    clear: both;
                }

                &:nth-of-type(1) {
                    float: right;
                    right: 0;
                    order: 2;
                    @include tablet_portrait{
                        padding-left: 20px;
                        padding-right: 0;
                    }
                    @include mobile {
                        padding: 0;
                        float: none;
                        order: 1;
                    }

                    .credits {
                        margin-left: auto;
                        text-align: right;
                        @include mobile{
                            text-align: left;
                        }
                    }

                    figure {
                        & ~ figure {
                            left: 0;
                            margin-right: auto;
                            margin-left: -40px;
                            @include tablet_landscape{
                                margin-left: -20px;
                            }
                            @include mobile{
                                margin-left: auto;
                            }
                            & + .credits{
                                margin-left: 0;
                                text-align: left;
                                @include mobile{
                                    margin-left: 20px;
                                    text-align: right;
                                }
                            }
                        }
                    }
                }

                &:nth-of-type(2) {
                    float: left;
                    order: 1;
                    @include tablet_portrait{
                        padding-right: 20px;
                        padding-left: 0;
                    }
                    @include mobile {
                        order: 2;
                        padding: 0;
                    }
                }
            }
        }
    }
}