.myex-form-field {

	select{
		width: 100%;
		@extend .style-field;
	}
	


	// selectric
	.selectric-wrapper {
		
	
		.selectric {
			@extend .style-field;

			.label {
				padding: 0;
				color: currentColor;

				// small arrow icon select
				&:after {
					bottom: 50%;
					transform: translateY(50%);
					font-size: calculateRem(11px);
				}
			}
		}

		.selectric-items {
			background-color: var(--bg-field);
			border-color: var(--bg-border-field);
			width: 100% !important; // overwrite rules mobile 142%

			.selectric-scroll ul li {
				color: currentColor;

				&.highlighted {
					background: var(--bg-field-hightlight);
				}
			}
		}
	}
}
