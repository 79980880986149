.myex-popin--remove-lieu {
    .myex-popin__scroller {
        display: flex;
        justify-content: center;
        align-items: baseline;
        padding-bottom: 30px;

        a {
            color: black;
            text-decoration: underline;
            font-weight: 500;
            margin-right: 35px;
        }
    }
}