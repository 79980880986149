.menu__lemag_bt {
    display: none;
    cursor: pointer;

    .floating-menu & {
        display: flex;
    }

    .floating-menu-open & {
        display: none !important;
    }

    @include desktop1200 {
        display: flex !important;
        top: unset;
        bottom: 80px;
        left: 0px;
        right: 0px;
        margin: auto;
        margin-left: auto;
    }

    position: fixed;
    z-index: 9999;
    top: 638px;
    width: 129px;
    height: 41px;
    top: 638px;
    width: 130px;
    margin-left: 106px;

    background-color: rgba(#1E2529, 0.9);
    box-shadow: 0px 4px 8px #00000058;
    border-radius: 21px;

    justify-content: flex-start;
    align-items: center;

    p {
        position: relative;
        font: normal normal bold 15px/15px Libre Baskerville;
        letter-spacing: 0px;
        color: var(--white);
        padding-left: 47px;
        margin: 0;

        &::before {
            position: absolute;
            top: 0px;
            left: 14px;
            content: '\e9ec';
            @include font();
            font-size: 15px;
            color: var(--white);
        }
    }
}


.menu__lemag {
    background-color: #fff;
    display: block;

    .floating-menu & {
        display: none;
    }

    .floating-menu-open & {
        overflow: hidden;
        top: 50%;
        transform: translateY(-45%);
        left: 81px;
        position: fixed;
        z-index: 9999;
        width: 690px;
        height: 390px;
        border-radius: 10px;
        box-shadow: 0px 4px 8px #00000029;
        display: block;

        @include desktop1200 {
            top: 75px;
            left: 0px;
            width: 100vw;
            height: calc(100vh - 75px);
            transform: none;
            box-shadow: none;
        }
    }

    &_mobile--close {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 74px;
        background-color: var(--white);
        
        justify-content: center;
        align-items: center;


        &::before {
            content: '';
            position: absolute;
            top: -19px;
            left: 0;
            width: 100%;
            height: 19px;
            background: transparent linear-gradient(180deg, #FFFFFF00 0%, #0000009F 74%, #000000 100%) 0% 0% no-repeat padding-box;
            opacity: 0.1;
        }

        p {
            text-align: center;
            text-decoration: underline;
            font: normal normal normal 11px/17px Poppins;
            margin: 0;
            color: var(--black);
        }

        .floating-menu-open & {
            display: none;

            @include desktop1200 {
                display: flex;
            }
        }
    }

    &_close {
        position: absolute;
        right: 40px;
        top: 32px;
        display: none;
        cursor: pointer;

        .floating-menu-open & {
            display: block;

            @include desktop1200 {
                display: none;
            }
        }

        &::after {
            position: absolute;
            top: 0px;
            right: 0;
            content: '\e940';
            @include font();
            font-size: 15px;
            color: #4B5155;



        }

        p {
            font: normal normal normal 12px/18px Poppins;
            letter-spacing: 1.8px;
            color: #4B5155;
            text-transform: uppercase;
            padding-right: 25px;
        }
    }

    ul {
        
        margin-left: 85px;
        margin-top: 37px;
        @include desktop1200 {
            margin: 0;
            width: 100%;
        }
        .floating-menu-open & {
            margin-left: 47px;
            position: relative;
            column-count: 3;
            height: 247px;

            @include desktop1200 {
                margin-left: 0px;
                column-count: unset;

                li {
                    text-align: center;
                }
            }
        }

        li {
            margin-bottom: 23px;
            line-height: 1;

            .floating-menu-open & {
                float: left;
                width: 100%;
            }

            a {
                position: relative;
                font: normal normal bold 20px/20px Poppins;
                letter-spacing: 0px;
                color: var(--darkblue);
                transition: color 300ms ease-out;

                &:hover {
                    color: var(--rose);

                    &::after {
                        opacity: 1;
                        transform: translate3d(0px, 0, 0);
                    }
                }

                &.active {
                    color: var(--rose);

                    &::after {
                        opacity: 1;
                        transform: translate3d(0px, 0, 0);
                    }
                }

                &::after {
                    position: absolute;
                    content: '\e90c';
                    @include font();
                    font-size: 11px;
                    font-weight: bold;
                    top: 11px;
                    right: -17px;
                    color: var(--rose);
                    transition: all 200ms ease-out 100ms;
                    opacity: 0;
                    transform: translate3d(-10px, 0, 0);
                }
            }
        }
    }

    &_toggle {
        padding-left: 77px;
        padding-top: 29px;

        .floating-menu-open & {
            padding-left: 47px;
            padding-top: 22px;
            background-color: #F3F4F5;
            height: 80px;
            width: 100%;

            @include desktop1200 {
                padding: 0;
                background-color: unset;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

}



/*  Toggle Switch  */
.toggleSwitch span span {
    display: none;
}

.toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    width: 248px;
    background-color: #D2D3D4;
    border: 1px solid #ccc;
    border-radius: 25px;
    height: 40px;
    @include desktop {
        margin: 0 auto;
    }

    .icon-article {
        font-size: 10px;
        line-height: 11px;
        color: var(--black);

        &:before {
            content: '\e907';
        }
    }
}

.toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.toggleSwitch label,
.toggleSwitch>span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
}

.toggleSwitch input:focus~a,
.toggleSwitch input:focus+label {
    outline: none;
}

.toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
}

.toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
}

.toggleSwitch>span {
    position: absolute;
    left: 0;
    width: calc(100% - 6px);
    margin: 0;
    text-align: left;
    white-space: nowrap;
    margin: 0 3px;
}

.toggleSwitch>span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font: normal normal normal 11px/17px Poppins;
    letter-spacing: 1.65px;
    color: #FFFFFF;
    text-transform: uppercase;
    width: auto;
    left: 0;
    top: 1px;
    opacity: 1;
    width: 40%;
    text-align: center;
    line-height: 40px;
}

.toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    top: 3px;
    bottom: 3px;
    padding: 0;
    left: 3px;
    width: 50%;
    background-color: #fff;
    border-radius: 20px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.toggleSwitch>span span:first-of-type {
    color: #000;
    opacity: 1;
    left: 0;
    margin: 0;
    width: 50%;
}

.toggleSwitch>span span:last-of-type {
    left: auto;
    right: 0;
    color: #fff;
    margin: 0;
    width: 50%;
}

.toggleSwitch>span:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -2px;
    /* background-color: #fafafa;
    border: 1px solid #ccc; */
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.toggleSwitch input:checked~a {
    left: calc(50% - 3px);
}

.toggleSwitch input:checked~span:before {
    /* border-color: #0097D1;
    box-shadow: inset 0 0 0 30px #0097D1; */
}

.toggleSwitch input:checked~span span:first-of-type {
    left: 0;
    color: #fff;
    text-transform: uppercase;
}

.toggleSwitch input:checked~span span:last-of-type {
    /* opacity: 1;
    color: #fff;	 */
    color: #000;
    text-transform: uppercase;
}

/* Switch Sizes */
.toggleSwitch.large {
    width: 60px;
    height: 27px;
}

.toggleSwitch.large a {
    width: 27px;
}

.toggleSwitch.large>span {
    height: 29px;
    line-height: 28px;
}

.toggleSwitch.large input:checked~a {
    left: 41px;
}

.toggleSwitch.large>span span {
    font-size: 1.1em;
}

.toggleSwitch.large>span span:first-of-type {
    left: 50%;
}

.toggleSwitch.xlarge {
    width: 80px;
    height: 36px;
}

.toggleSwitch.xlarge a {
    width: 36px;
}

.toggleSwitch.xlarge>span {
    height: 38px;
    line-height: 37px;
}

.toggleSwitch.xlarge input:checked~a {
    left: 52px;
}

.toggleSwitch.xlarge>span span {
    font-size: 1.4em;
}

.toggleSwitch.xlarge>span span:first-of-type {
    left: 50%;
}

/*  End Toggle Switch  */
