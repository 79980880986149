// Style General - Overlay - All site
// ==========================================================================
%overlay-gradient-bottom {
	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 80px;
		z-index: 1000;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
		@include mobile {
			height: 60px;
		}
	}
}


.overlay,
.overlay-more-filters {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	display: none;
	background: $c_white;
	z-index: 100000;
	max-width: 500px;
	flex-direction: column;
	transform: translateX(100%);
	transform: translate3d(100%, 0, 0);
	transition: transform 400ms cubic-bezier(0.86, 0, 0.07, 1);
	@include mobile {
		width: 100vw;
		max-width: 100%;
	}

	&.overlay-sncf-connect {
		max-width: 950px;
	}

	&.open {
		transform: translateX(0);
		transform: translate3d(0, 0, 0);
	}

	&:not(.overlay-absolute) {
		.overlay-load & {
			display: flex;
		}
	}

	// Arrivée du haut de l'écran
	&.from-fade {
		background: $c_greydark;
		left: -100%;
		max-width: 100%;
		transform: translate3d(0, 0, 0);
		transition: opacity 400ms cubic-bezier(0.86, 0, 0.07, 1);
		transition-property: opacity;
		opacity: 0;

		&.open {
			opacity: 1;
			left: 0;
		}

	}

	&.from-top {
		background: $c_greydark;
		left: -100%;
		max-width: 100%;
		transform: translateX(-100%);
		transform: translate3d(-100%, 0, 0);

		&.open {
			left: 0;
			transform: translateX(0);
			transform: translate3d(0, 0, 0);
		}
	}

	.top {
		position: relative;
		z-index: 100;
		background: $c_white;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		/*&:after {
			content: '';
			position: absolute;
			left: 40px;
			right: 40px;
			bottom: 0;
			height: 1px;
			background: rgba($c_grey70, 0.2);
			@include mobile {
				left: 20px;
				right: 20px;
			}
		}*/

		.title-overlay {
			@include ft('', 25px, 600, $c_greydark, 1);
			padding: 56px 0 30px 40px;

			@include mobile {
				padding: 15px 0 15px 15px;
			}

			&.with-icon {
				span {
					display: inline-block;
					margin-right: 4px;
					font-size: 18px;
				}
			}
		}

		.title-overlay-h2 {
			@include ft('', 40px, 300, $c_titre, 40px);
			letter-spacing: 0.2px;
			padding: 56px 0 30px 40px;
			@include mobile {
				font-size: calculateRem(20px);
				line-height: calculateRem(24px);
				padding: 15px 0 15px 15px;
			}
		}

		.sous-titre {
			@include ft($f_txt, 20px, 400, $c_main, 26px);
			display: block;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			margin-top: -5px;
			@include mobile {
				font-size: calculateRem(13px);
				line-height: calculateRem(20px);
				margin-top: -4px;
			}
		}
	}

	.content {
		position: relative;
		z-index: 10;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.scroller {
		height: 100%;
		padding: 0 40px;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		@include mobile {
			padding: 0 15px;
		}

		&:after {
			content: '';
			position: relative;
			display: block;
			width: 100%;
			height: 170px;
			pointer-events: none;
		}
	}

	.close-overlay {
		@include ft($f_hx, 12px, 400, $c_grey, 24px);
		@extend %appearance;
		position: relative;
		z-index: 1000;
		padding: 38px 40px;
		background: none;
		text-transform: uppercase;
		letter-spacing: 1.5px;
		margin: 0;
		flex: none;
		border-radius: 50%;
		@include addTransition();
		@include mobile {
			font-size: 0;
			padding: 16px 20px;
			letter-spacing: 0;
		}

		&:hover, &:focus {
			body:not(.no-hover) & {
				cursor: pointer;
				color: $c_third;
			}
		}

		&:after {
			content: "\e940";
			@include font();
			position: relative;
			top: -1px;
			float: right;
			font-size: 14px;
			line-height: 24px;
			margin-left: 10px;
			@include mobile {
				margin-left: 0;
			}
		}
	}

	// Stick Bottom
	.bottom {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10000;
		margin: 0;
		background: $c_white;
		padding: 0 40px 30px 40px;
		transform: translate3d(0, 0, 0);
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include mobile {
			padding: 0 20px 20px 20px;
		}

		@extend %overlay-gradient-bottom;

		&:before {
			bottom: 100%;
			pointer-events: none;
		}

		> * {
			flex: 1;
			width: 100%;
			margin-right: 40px;
			@include mobile {
				margin: 0 10px 0 0;
			}

			&.remove, &.reset {
				@include mobile_small {
					flex: none;
					width: auto;
				}
			}
		}

		.btn {
			background: $c_sub;
			margin: 0;
			text-transform: none;
			padding: 20px;
			@include mobile {
				font-size: calculateRem(12px);
				padding: 10px 20px;
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					cursor: pointer;
					background: $c_greydark;
				}
			}
		}

		.btn-reservation {
			text-align: right;

			&:before {
				content: "\e908";
				@include font();
				position: absolute;
				top: 50%;
				left: 20px;
				font-size: 22px;
				margin-top: -11px;
				@include mobile {
					font-size: 18px;
					margin-top: -9px;
				}
			}
		}
	}

	// Formulaire
	.bloc-form {
		.title-group {
			position: relative;
			display: block;
			margin-bottom: 20px;
			padding: 0;
			left: 0;
			@include ft('', 20px, 600, $c_greydark, 1);
			text-transform: none;
			letter-spacing: 0;

			@include mobile {
				font-size: calculateRem(18px);
			}
		}

		.title-group-small {
			position: relative;
			display: block;
			padding: 0;
			left: 0;
			@include ft('', 14px, 600, $c_greydark, 1);
			@include letter-spacing(20);
			text-transform: none;
		}


		form,
		.fake-form {
			/* Groupe de Facette Checkbox */
			.group-checkbox {
				padding: 40px 0;
				margin-top: 30px;
				border-top: 1px solid rgba($c_grey70, 0.2);

				@include mobile {
					padding: 30px 0;
					margin-top: 20px;
				}

				& + .group-checkbox {
					margin-top: 0;
				}

				.frm_checkbox {
					@include clearfix();
					margin-bottom: -10px;
					@include mobile {
						margin-bottom: 5px;
					}
				}

				label:not(.star-rating):not(.frm_hidden):not(.frm_switch):not(.type-destination) {
					position: relative;
					padding: 0;
					float: left;
					clear: none;
					margin: 0 10px 10px 0;
					left: 0;
					letter-spacing: 0;
					text-transform: none;
					@include mobile {
						margin: 0 5px 5px 0;
					}

					input {
						position: absolute;
						opacity: 0;
						margin: 0;
						display: block !important;

						&:checked {
							&:hover, &:focus {
								body:not(.no-hover) & {
									& ~ .value {
										border-color: $c_third;
									}
								}
							}

							& ~ .value {
								color: $c_white;
								background: $c_third;
								border-color: $c_third;
								padding-right: 50px;

								& + span {
									&:after {
										opacity: 1;
									}
								}
							}
						}

						&:hover, &:focus {
							body:not(.no-hover) & {
								& ~ .value {
									border-color: $c_third;
								}
							}
						}
					}

					.value {
						display: block;
						padding: 10px 20px 8px;
						@include ft('', 14px, 500, $c_surtitre, 20px);
						border: 1px solid $c_border_input;
						border-radius: 3px;
						clear: none;
						position: relative;
						top: 0;
						left: 0;
						width: auto;
						height: auto;

						&:before, &:after {
							content: none;
						}

						& + span {
							&:before {
								content: none;
							}

							&:after {
								content: "\e940";
								@include font();
								color: $c_white;
								background: none;
								float: right;
								font-size: 10px;
								width: 10px;
								height: 10px;
								transform: none;
								position: absolute;
								top: 50%;
								margin-top: -5px;
								right: 20px;
								left: auto;
								@include mobile {
									right: 15px;
								}
							}
						}
					}
				}

			}


			/* Facette Switch */
			.group-switch {
				border-top: 1px solid rgba($c_grey70, 0.2);
				padding-top: 20px;
				@include mobile {
					padding-top: 15px;
				}
			}

			.switch {

				&:not(.switch--pets) {
					border-top: initial !important;
					padding-top: 8px !important;
				}

				.switch--pets {
					padding-top: 35px;
				}
				
				label {
					padding-right: 80px;
					margin: 0;
					overflow: visible;

					&:hover {
						body:not(.no-hover) & {
							cursor: pointer;
						}
					}
				}

				input {
					display: none !important;

					&:checked {
						& ~ .frm_switch {
							background-color: $c_third;

							.frm_slider {
								&:before {
									transform: translateX(30px);
									transform: translate3d(30px, 0, 0);
								}
							}
						}
					}
				}

				.frm_switch {
					position: absolute;
					right: 0;
					left: auto;
					top: 50%;
					margin-top: -15px;
					box-sizing: border-box;
					width: 60px;
					height: 28px;
					border-radius: 14px;
					vertical-align: middle;
					background-color: $c_border_input;
					border: initial;
					@include addTransition();

					.frm_slider {
						position: absolute;
						cursor: pointer;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background-color: initial;

						&:before {
							content: '';
							position: absolute;
							top: 3px;
							left: 4px;
							width: 22px;
							height: 22px;
							background: $c_white;
							border-radius: 50%;
							transform: translate3d(0, 0, 0);
							box-shadow: initial;
							@include addTransition();
						}
					}
				}
			}


		}


		.frm_submit {
			button {
				width: 100%;
			}
		}
	}
}

// Overlay - Filtres
// ==========================================================================
.overlay-more-filters,
.overlay-map-search-mobile {
	z-index: 9999999999;

	&-shadow {
		position: fixed;
		top: 0;
		left: 100%;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba($c_greydark, 0.5);
		opacity: 0;
		z-index: 9999999998;
		display: block;
		overflow: hidden;
		transition: opacity 400ms cubic-bezier(0.86, 0, 0.07, 1);

		&.open {
			opacity: 1;
			left: 0;
		}
	}
}

.overlay.overlay-filtres,
.overlay-widget,
.overlay-map,
.overlay-slider-offres-auto,
.overlay-slider-offres-auto-map,
.overlay-concours,
.overlay-luxe-presta,
.overlay-luxe-contact,
.overlay-sncf-connect,
.overlay-luxe-map,
.overlay-mice-map,
.overlay-mice-contact,
.overlay-contact-presta{
	z-index: 10000000000;
}



.overlay-concours {
	&-top {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 40px;
			right: 40px;
			bottom: 0;
			height: 1px;
			background: rgba($c_grey70, 0.2);
			@include mobile {
				left: 20px;
				right: 20px;
			}
		}
	}

	.close-overlay {
		position: absolute;
		right: 0;
		top: 0;
	}

	&-title {
		max-width: 350px;
		@include ft($f_hx, 40px, 300, $c_titre, 40px);
		letter-spacing: 0.2px;
		padding: 0 40px;
		margin-top: 38px;
		@include mobile {
			font-size: calculateRem(20px);
			line-height: calculateRem(24px);
			padding: 0 20px;
			margin-top: 18px;
		}
	}

	&-text {
		padding: 0 40px;
		margin-top: 20px;

		@include mobile {
			padding: 0 20px;
		}
	}
}

// Overlay - Widget Réservation
// ==========================================================================
.overlay-sncf-connect {
	#container_sncf_connect h1 {
		color: $c_greydark;
	}
}

// Overlay - Widget Réservation
// ==========================================================================
.overlay-widget {
	.sous-titre-overlay {
		@include ft($f_hx, 12px, 300, #4B6D83, 14px);
		display: block;
		font-style: italic;
		margin-top: 8px;
		@include mobile {
			margin-top: 5px;
		}

		.cap {
			text-transform: capitalize;
		}
	}

	iframe {
		min-height: 100% !important;
	}

	.content.ctoutvert {
		overflow: auto;
		padding: 40px;
		@include mobile {
			padding: 20px;
		}
	}
}

.overlay-filtres,
.overlay-more-filters {
	.bloc-form {
		form,
		.fake-form {
			.scroller {
				> * {
					position: relative;
					clear: both;
					display: block;
					padding: 40px 0;
					margin: 0;
					border-top: 1px solid rgba($c_grey70, 0.2);
					@include mobile {
						padding: 20px 0;
					}

					&:first-child {
						border-top: none;
						padding-top: 0;
						margin-top: 0;
					}
				}
			}

			/* Facette Select */
			.select {
				select {
					padding: 20px 20px 18px 20px;
				}

				.selectric-wrapper {
					.selectric {
						width: 100%;

						.label {
							padding: 20px 20px 18px 20px;

							&:after {
								right: 20px;
								bottom: auto;
								top: 50%;
								margin-top: -5px;
							}
						}
					}
				}
			}
		}
	}
}

// Overlay - Image
// ==========================================================================
.overlay-image {
	z-index: 100000000000;

	.content {
		@extend %overlay-gradient-bottom;
	}

	.credits, figcaption {
		display: none;
	}

	.top-image {
		position: relative;
		z-index: 10;
		margin-left: -40px;
		margin-right: -40px;
		width: calc(100% + 80px);
		height: 200px;
		@include mobile {
			margin-left: -20px;
			margin-right: -20px;
			width: calc(100% + 40px);
			height: 160px;
		}

		& ~ .top-metas {
			margin-top: 0;

			.auteur-image {
				margin-top: -50px;
				@include mobile {
					margin-top: -30px;
				}
			}
		}

		figure {
			height: 100%;
		}

		.maps {
			@extend %center;
		}
	}

	.close-overlay {
		float: right;
		margin-right: -40px;
		@include mobile {
			margin-right: -20px;
		}
	}

	.top-metas {
		position: relative;
		z-index: 50;
		margin-top: 40px;

		&.no-author {
			padding-top: 38px;
		}

		.auteur-image {
			background: none;
			border-radius: 50%;
			border: 10px solid $c_white;
			width: 80px;
			height: 80px;
			margin: 0;
			margin-bottom: 25px;
			@include mobile {
				width: 60px;
				height: 60px;
				margin-bottom: 20px;
				border-width: 5px;
			}

			img {
				border-radius: 50%;
			}
		}

		.surtitre {
			font-size: calculateRem(18px);
			line-height: calculateRem(27px);
			@include mobile {
				font-size: calculateRem(13px);
				line-height: calculateRem(20px);
			}

			& + h2 {
				margin-top: -20px;
			}
		}

		h2 {
			font-size: calculateRem(25px);
			line-height: calculateRem(24px);
		}

		figure {
			margin: 35px auto 30px;
			background: none;
			@include mobile {
				margin: 20px auto;
			}

			img {
				height: auto;
			}
		}
	}
}

// Overlay - Offre
// ==========================================================================
.overlay-offre {
	z-index: 100000000000;

	.content {
		@extend %overlay-gradient-bottom;
	}

	.close-overlay {
		position: absolute;
		top: 0;
		right: 0;
		@include mobile {
			padding: 28px 20px;
		}
	}

	.location {
		margin-top: 70px;
		margin-bottom: 8px;
		display: inline-block;
		vertical-align: top;

		&:before {
			top: 2px;
		}

		@include ft($f_hx, 11px, 300, $c_main, 16px);
		letter-spacing: 0.4px;
	}

	.title-offre {
		@include ft($f_hx, 26px, 600, $c_greydark, 24px);
		display: block;
		margin: 0 0 5px 0;
		@include mobile {
			font-size: calculateRem(24px);
		}
	}

	.wrapper-cat {
		margin-bottom: 10px;

		span {
			color: $c_surtitre;
			font-weight: 400;
		}
	}

	.tarif {
		display: block;
		margin-bottom: 20px;
	}

	.th-slider-wrapper {
		margin: 30px auto !important;
		margin-left: -40px !important;
		margin-right: -40px !important;
		width: calc(100% + 80px);
		padding: 0;
		@include mobile {
			margin: 20px auto !important;
			margin-left: -20px !important;
			margin-right: -20px !important;
			width: calc(100% + 40px);
		}

		.nav {
			button {
				width: 40px;
				height: 40px;
				margin-top: -20px;
				line-height: 40px;

				&:after {
					font-size: 10px;
				}

				&:first-child {
					left: 50px;
				}

				&:last-child {
					right: 50px;
				}
			}
		}
	}

	.th-slider {
		margin: 0 0 0 40px !important;
		padding: 0;
		@include desktop {
			margin: 0 !important;
		}

		.no-hover & {
			margin: 0 !important;
			@include desktop {
				scroll-padding: 40px;
				padding-left: 40px;
				padding-right: 40px;
				margin: 0 40px;
			}
			@include mobile {
				scroll-padding: 20px;
				padding-left: 20px;
				padding-right: 20px;
				margin: 0 20px;
			}
		}

		.item {
			width: calc(100% - 40px);
			margin-right: 10px;
			@include desktop {
				width: 100%;
			}

			.credits, figcaption {
				display: none;
			}

			figure {
				@include aspect-ratio(420, 257);

				img {
					@extend %center;
				}
			}
		}
	}

	.info-contact-offre {
		margin-top: 30px;
		padding: 37px 0 0 0;
		border-top: 1px solid rgba($c_grey70, 0.2);

		.title-contact {
			@include ft($f_hx, 18px, 600, $c_greydark, 22px);
			margin: 0 0 10px 0;
		}

		address, a {
			@include ft($f_hx, 15px, 300, $c_txt, 18px);
			display: block;
			margin-top: 10px;
		}

		a {
			&:hover, &:focus {
				body:not(.no-hover) & {
					color: $c_sub;
				}
			}
		}
	}
}


// Overlay - Téléchargement Carte
// ==========================================================================
.overlay-telechargement-carte {
	z-index: 100000000000;
}


// Overlay - Newsletter
// ==========================================================================
.overlay-newsletter {
	z-index: 100000000000;
}

// Overlay - Destination
// ==========================================================================
.overlay-destination {
	z-index: 100000000000;

	.item-offre {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: 10px;
		@include clearfix();

		&:first-child {
			margin-top: 40px;
			@include mobile {
				margin-top: 20px;
			}
		}

		&:hover, &:focus {
			body:not(.no-hover) & {
				.bloc-texte {
					.link {
						color: $c_third;
					}
				}
			}
		}

		> * {
			position: relative;
		}

		.credits, figcaption {
			display: none;
		}

		figure {
			background: none;
		}

		figure, .no-photo {
			width: 120px;
			height: 120px;

			& + .bloc-texte {
				width: calc(100% - 120px);
				padding: 5px 20px;
				@include mobile {
					padding: 5px 10px;
				}
			}
		}

		.bloc-texte {
			h3 {
				@include ft($f_hx, 18px, 600, $c_txt, 22px);
				margin-bottom: 0;
				@include mobile {
					font-size: calculateRem(14px);
					line-height: calculateRem(18px);
				}
			}

			.link {
				@include ft($f_hx, 15px, 500, $c_main, 28px);
				display: block;
				@include addTransition();
				@include mobile {
					font-size: calculateRem(12px);
					line-height: calculateRem(18px);
				}
			}
		}
	}
}


// Overlay - Galerie
// ==========================================================================
.overlay-galerie {
	z-index: 9999999999;

	.top {
		background: none;

		&:after {
			content: none;
		}

		.title-overlay {
			color: $c_light_txt;
			padding: 70px 20px 38px 80px;
			@include tablet_landscape {
				padding: 70px 20px 38px 40px;
			}
			@include tablet_portrait {
				padding: 38px 20px 38px 40px;
			}
			@include mobile {
				padding: 16px 0 16px 20px;
			}
		}

		.close-overlay {
			padding: 70px 80px 38px;
			color: $c_white;
			@include tablet_landscape {
				padding: 70px 40px 38px;
			}
			@include tablet_portrait {
				padding: 38px 40px;
			}
			@include mobile {
				padding: 16px 20px;
			}
		}
	}

	.content {
		.th-slider-wrapper {
			height: 100%;

			&.scroller {
				overflow: hidden;
				padding: 0;
				@include tablet_portrait {
					overflow-y: auto;
					padding: 0 40px;
				}
				@include mobile {
					padding: 0 20px;
				}
			}

			.wrapper-slider {
				height: 100%;
				@include wide {
					max-width: 100%;
					padding-left: 80px;
				}
				@include tablet_portrait {
					height: initial;
					margin: 0;
					width: 100%;

					.nav {
						display: none;
					}
				}

				.th-slider {
					height: 100%;
					padding-bottom: 0;
					display: block;
					@include tablet_portrait {
						white-space: normal;
						overflow: initial;
						margin: 0 !important;
						padding: 0;
					}

					.item {
						position: relative;
						height: 100%;
						width: auto;
						margin-right: 40px;
						padding-bottom: 80px;
						@include tablet_landscape {
							padding-bottom: 40px;
						}
						@include tablet_portrait {
							width: 100%;
							height: auto;
							margin: 0;
						}
						@include mobile {
							padding-bottom: 30px;
						}

						figure {
							height: 100%;
							@include tablet_portrait {
								height: auto;
								background: none;
							}

							figcaption {
								padding: 30px 40px;
								@include tablet_portrait {
									position: relative;
									padding: 0;
									margin: 15px 0 0 0;
									&:before {
										content: none;
									}
								}
							}

							img {
								width: auto;
								@include tablet_portrait {
									width: 100%;
									height: auto;
								}
							}
						}

						.credits {
							padding-left: 90px;
							margin-top: 12px;
							color: $c_light_txt;

							@include tablet_portrait {
								padding: 0;
								margin-top: 10px;
								position: relative;
								display: block;
							}
						}

						.legendes {
							font-size: 11px;
							margin-top: 11px;
							margin-left: 40%;
							position: absolute;
							line-height: 1.5;
							color: #ABB3B9;
							width: 60%;
							text-align: right;
							font-weight: 300;

							@include tablet_portrait {
								text-align: left;
								font-size: 10px;
								margin-left: 0;
								width: 100%;
							}
						}
					}
				}
			}

			.counter {
				@include ft($f_txt, 12px, 400, $c_white, 18px);
				text-transform: uppercase;
				letter-spacing: 1.5px;
				position: absolute;
				left: 40px;
				margin: 10px 0 0 0;
				@include tablet_portrait {
					display: none;
				}
			}

			.nav {
				button {
					background: $c_main;
					top: calc(50% - 40px);

					&:hover, &:focus {
						body:not(.no-hover) & {
							background: $c_third;
						}
					}

					&:first-child {
						left: 80px;
						@include desktop {
							left: 0;
						}
					}

					&:last-child {
						right: 80px;
						@include desktop {
							right: 0;
						}
					}
				}
			}
		}
	}
}

.overlay-offre-durable {
	z-index: 9999999999;

	.top {
		background-color: transparent;

		.title-overlay {
			color: $c_light_txt;
		}

		.close-overlay {
			color: $c_white;
		}
	}

	.content {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.bloc-texte p {
		color: $c_white;
		font-size: calculateRem(22px);
		line-height: calculateRem(34px);
		box-sizing: border-box;

		@include tablet_portrait {
			padding: 0 20px;
			font-size: calculateRem(16px);
			line-height: calculateRem(26px);
		}
	}

}


// Overlay - Shadow
// ==========================================================================
.overlay-search.open ~ .shadow-overlay {
	display: none;
}

.shadow-overlay {
	position: fixed;
	top: 0;
	left: 100%;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: black;
	opacity: 0;
	z-index: 1000000000;
	display: block;
	overflow: hidden;
	transition: opacity 400ms cubic-bezier(0.86, 0, 0.07, 1);

	&.open {
		opacity: 0.7;
		left: 0;
	}
}

#elloha-widget {
	height: 100%;
}

// Overlay - Map pour la version mobile
.overlay-slider-offres-auto-map {
	.close-overlay {
		text-align: right;
	}
}

// Overlay menu (modifications au menu container faites juste ici pour éviter d'imapcter des autres overlay qui utilisent la même structure html)
.overlay-menu {
	.menu-container {
		display: block;
		overflow: auto;
		height: calc(100vh - 280px);
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
	}
}


//Fix d'un soucis qui causait un carré blanc dans menu sur mobile
.overlay-slider-offres-auto,
.overlay-slider-offres-auto-map {
	opacity: 0;
	pointer-events: none;

	&.open {
		opacity: 1;
		pointer-events: auto;
	}
}


.overlay-popin-pub {
	z-index: 9999999999999;
	top: 50%;
	bottom: auto;
	max-width: 960px;
	display: flex;
	left: 150%;
	right: auto;
	transform: translate(-50%, -50%) !important;

	@include tablet_landscape {
		max-width: 90%;
	}

	@include mobile {
		max-width: 100%;
		max-height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	&.open {
		left: 50%;
	}

	&__container {
		display: flex;
	}

	&__picture {
		flex-basis: 360px;
		flex-shrink: 0;

		.fit-cover {
			height: 100%;
		}

		@include tablet_landscape {
			display: none;
		}
	}

	.content {
		padding: 28px 40px;
	}

	.top {
		align-items: center;
		margin-bottom: 10px;

		&::after {
			content: unset;
		}

		h3 {
			margin-bottom: 0;
		}

		.close-overlay {
			padding: 0;
		}
	}

	.bloc-texte {
		margin-bottom: 10px;

		p {
			font-size: 14px;
			line-height: 20px;
			font-weight: 700;
		}
	}

	.bloc-form {
		.frm_switch_opt {
			p {
				font-size: 11px;
				line-height: 16px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__close-link {
		margin-top: 10px;
		@include ft($f_txt, 12px, 400, $c_light_txt, 20px);
		text-align: center;
		text-decoration: underline;

		span {
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}
	}

}


// Overlay webcam
// -------------------------

.overlay-webcam {
	z-index: 9999999999;
	left: 0;
	display: flex !important;
	flex-direction: initial;
	max-width: initial;

	.slider-webcams-wrapper {
		flex-grow: 1;
		background-color: #eee;

		@include tablet_portrait {
			display: none;
		}
	}

	.th-slider {
		display: flex;
		height: 100%;
		width: 100%;
		padding: initial !important;
		margin: initial !important;


		> * {
			user-select: none;
			display: block;
			flex-shrink: 0;

			&:first-child {
				margin-left: 0 !important;
			}

			&:last-child {
				margin-right: 0 !important;
			}
		}
	}

	.slide-webcam {
		width: 100%;
		height: 100%;

		iframe {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}


	.nav {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 50px 40px;
		max-width: initial;
		pointer-events: none;

		// gradients
		&:before,
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			height: 300px;
			width: 100%;
			max-width: 600px;
		}

		&:before {
			right: 0;
			//background-image: linear-gradient(to top left,  #181E21 50%, rgba(#181E21,0) 50%);
			background-image: linear-gradient(to top left, #181E21, rgba(#181E21, 0) 50%);
		}

		&:after {
			left: 0;
			//background-image: linear-gradient(to top right,  #aa1E21 50%, rgba(#aa1E21,0) 50%);
			background-image: linear-gradient(to top right, #181E21, rgba(#181E21, 0) 50%);
		}


		button {
			position: static;
			pointer-events: initial;

			&:first-child {
				margin: 0;
			}

			&:last-child {
				margin: 0;
			}
		}

		.counter {
			position: relative;
			z-index: 1;
			@include ft('', 20px, 400, $c_white, '');
			padding: 0 50px;

			.current {
				font-weight: 700;
			}
		}
	}


	.content {
		max-width: 500px;
		background-color: $c_white;

		@include tablet_portrait {
			max-width: initial;
		}
	}

	.scroller {
		display: flex;
		flex-direction: column;
		padding-top: 30px;
	}

	.close-overlay {
		border-radius: initial;
		align-self: flex-end;
		padding: 14px 0 14px 20px;

		@include tablet_portrait {
			padding: 0 0 14px 20px;
		}
	}


	.title-overlay {
		@include ft($f_hx, 25px, 300, $c_titre, 24px);
		letter-spacing: 0.2px;

		@include mobile {
			font-size: calculateRem(20px);
		}
	}

	.list-webcams {
		margin-top: 80px;
		margin-bottom: 80px;

		@include tablet_portrait {
			margin-top: 40px;
			margin-bottom: 40px;
		}

		@include mobile {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}

	.btn-webcam {
		appearance: none;
		padding: 0;
		border: none;
		outline: none;
		background-color: transparent;
		text-align: initial;

		display: flex;
		align-items: center;
		width: 100%;
		padding: 20px 25px;
		border-top: 1px solid rgba($c_border, 0.5);

		@include min-tablet_portrait {
			&:hover, &:focus {
				body:not(.no-hover) &:not([disabled]) {
					cursor: pointer;

					.btn-webcam__title {
						color: #DEA99E;
					}
				}
			}

			&.is-active .btn-webcam__title {
				color: #DEA99E;
			}
		}

		.btn-webcam__icon {
			flex-shrink: 0;
			font-size: 44px;
			color: #DEA99E;
		}

		.btn-webcam__caption {
			margin-left: 30px;

			.see-more {
				display: none;
			}

			@include tablet_portrait {
				display: flex;
				justify-content: space-between;
				width: 100%;

				.see-more {
					display: block;
				}
			}

			> * {
				display: block;
			}
		}

		.btn-webcam__title {
			@include ft('', 18px, 600, $c_txt, '');
			@include addTransition();
		}

		.btn-webcam__label {
			@include ft('', 15px, 500, #7A97AA, '');

			@include tablet_portrait {
				display: none;
			}
		}
	}


	.slide-webcam-mobile {
		height: 0;
		overflow: hidden;
		transition: height 0.2s ease-in;
	}

	.btn-webcam {

		.see-more {
			span {
				display: block;

				& + span {
					display: none;
				}
			}
		}

		&.is-active {

			@include tablet_portrait {
				.see-more {
					span {
						display: none;

						& + span {
							display: block;
						}
					}
				}
			}

			& + .slide-webcam-mobile {
				@include tablet_portrait {
					@include aspect-ratio(335, 244);
					height: 60vw;
					display: block;
					margin-top: 10px;
					margin-bottom: 30px;

					iframe {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}

}


// Overlay map
// -------------------------

.overlay-map {
	flex-direction: column;

	.maps {
		position: static;
		flex-grow: 1;
	}

	.btn-close-back-overlay {
		flex-shrink: 0;
	}

	.data-bottom {
		position: static;
		flex-shrink: 0;
		transform: initial;
	}
}


// Jeu concours
body.hide-scroll::-webkit-scrollbar {
	display: none;
}


.overlay-filtres {
	.close-overlay {
		@include mobile {
			display: none;
		}
	}

	.facette-slider {
		position: relative;

		.noUi-tooltip {
			display: none;
		}

		.facette-slider__current-range {
			position: absolute;
			top: 1px;
			right: 0;
			@include ft('', 15px, 600, $c_greydark, 1);
			text-align: right;
		}

		.facette-slider__minmax-wrapper {
			display: flex;
			justify-content: space-between;
		}

		.facette-slider__min-value,
		.facette-slider__max-value {
			@include ft('', 12px, 400, #A8A8A8, 1);
		}

		.facette-slider__max-value {
			text-align: right;
		}
	}


	.frm_checkbox.facette-destination {
		box-sizing: border-box;

		*, *:before, *:after {
			box-sizing: inherit;
		}

		display: grid;
		grid-template-columns: repeat(3, minmax(10px, 1fr));
		gap: 12px;

		@include mobile {
			display: grid !important;
			grid-template-columns: repeat(2, minmax(10px, 1fr));
			gap: 15px;
		}

		label {
			position: relative;
			padding: 0;
			margin: 0;
			left: 0;
			letter-spacing: 0;
			text-transform: none;
			top: initial;

			@include aspect-ratio(1, 1);
			overflow: visible;
			display: block;
			border-radius: 10px;
			background-color: $c_third;
			cursor: pointer;

			&:focus,
			&:hover {
				.image:after {
					opacity: 0.65;
				}
			}

			.image {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border-radius: 10px;
				overflow: hidden;
				@include addTransition();

				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 1;
					background-image: linear-gradient(to bottom, rgba($c_dark_grey, 0) 10%, rgba($c_dark_grey, 1) 60%);
					opacity: 0.5;
					@include addTransition();
				}

				figure {
					height: 100%;
					background-color: initial;
				}
			}


			input {
				position: absolute;
				opacity: 0;
				margin: 0;
				display: none !important;

				&:checked {
					& ~ .image {
						top: 5px;
						left: 5px;
						right: 5px;
						bottom: 5px;
						border-radius: 4px;
					}

					& ~ .cross {
						opacity: 1;
						visibility: visible;
					}
				}
			}

			.value {
				@include ft('', 16px, 600, $c_white, 1);
				text-align: center;
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: 5;
				width: 100%;
				padding: 10px;
				transform: translate(-50%, -50%);

				@include mobile {
					font-size: 18px;
				}

				&:before,
				&:after {
					content: initial !important;
				}
			}

			.cross {
				position: absolute;
				top: 0;
				right: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 22px;
				height: 22px;
				transform: translate(50%, -50%);
				font-size: 8px;
				color: $c_white;
				background-color: $c_third;
				border: 2px solid $c_white;
				border-radius: 50%;
				opacity: 0;
				visibility: hidden;
				@include addTransition();

				&:before {
					all: unset; // reset
					@include font();
					content: '\e940';
				}

				&:after {
					all: unset; // reset
				}
			}
		}
	}


	.overlay__footer {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 200;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 80px;
		padding: 0 40px;
		background-color: $c-white;
		box-shadow: -30px 0 40px rgba($c_dark, 0.15);

		@include mobile {
			height: 70px;
		}

		.btn-show-results {
			flex-grow: 1;
		}
	}

	.btn-close-back-overlay {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 200;
		box-shadow: -30px 0 40px rgba($c_dark, 0.15);
	}


}