.my-ex--container {
	$self: &;
	padding-left: var(--side-padding-left);
	padding-right: var(--side-padding-right);
}

//remove padding when not sidebar, because already on framed content
.my-ex--container--framed {
	@include tablet_portrait {
		background: var(--bg-dark);
	}

	> * {
		@extend .bg--dark;
		padding: var(--side-padding-base) 0;
		@include min-tablet_portrait {
			border-radius: var(--section-radius);
			padding: var(--section-padding);
		}
	}

	&:first-of-type {
		> * {
			@include tablet_portrait {
				padding-top: 0;
			}
		}
	}
}
