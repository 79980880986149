@mixin button-with-icon() {
	display: flex;
	gap: calculateRem(8px);
	justify-content: center;
	cursor: pointer;
	transition: all 0.2s ease;

	&:before {
		@include font();
		padding-bottom: 0.5%;
	}
}

.base-button {
	font-size: calculateRem(13px);
	line-height: 1.3;
	font-weight: 600;
	border-width: calculateRem(1px);
	border-style: solid;
	cursor: pointer;
	align-items: center;
	appearance: none;
	transition: border-color 0.2s ease;
	padding: calculateRem(13px) calculateRem(30px);
	border-radius: calculateRem(4px);
	@include min-mobile {
		padding: calculateRem(13px) calculateRem(40px);
	}

	@include button-with-icon();
}

//icon on the right side
.myex-button--icon-right {
	flex-direction: row-reverse;
}

// button disabled
.myex-button--disabled {
	pointer-events: none;
	opacity: 0.5;
}

// -------  button outline
.myex-button--outline {
	@extend .base-button;
	border-color: var(--border-color--button--outline);
	color: var(--color--button--outline);
	background-color: transparent;

	&:hover {
		background-color: var(--color-primary);
		color: #fff;
		border-color: var(--color-primary);
	}
}

// ------- bouton full primary color
.myex-button--primary {
	@extend .base-button;
	color: #fff;
	background-color: var(--color-primary);
	border-color: var(--color-primary);

	&:hover {
		background-color: var(--btn-primary-bg-hover);
		color: var(--btn-primary-color-hover) !important;
		border-color: var(--btn-primary-bg-hover);
	}
}

// ------- bouton full secondary color
.myex-button--secondary {
	@extend .base-button;
	color: #fff;
	background-color: var(--color-secondary);
	border-color: var(--color-secondary);

	&:hover {
		background-color: var(--btn-secondary-bg-hover);
		color: var(--btn-secondary-color-hover) !important;
		border-color: var(--btn-secondary-bg-hover);
	}
}

// ------- bouton full grey
.myex-button--grey {
	@extend .base-button;
	color: #fff;
	background-color: #4b5154;
	border-color: #4b5154;

	&:hover {
		background-color: var(--btn-primary-bg-hover);
		color: var(--btn-primary-color-hover) !important;
		border-color: var(--btn-primary-bg-hover);
	}
}

// ------- bouton gradient
.myex-button--gradient {
	@extend .base-button;
	color: #fff;
	background-color: var(--color-myex);
	background-image: linear-gradient(-80deg, #dea99e 0%, transparent 100%);
	border: none;
	transition: all ease 500ms;

	&:hover {
		color: #fff !important;
		background-image: linear-gradient(-80deg, #E13060FF 0%, #E13060FF 100%);
	}
}

// -------  bouton submit
.myex-button--submit {
	@extend .base-button;
	@extend .myex-button--primary;
	margin-top: calculateRem(16px);

	&.loading {
		.myex-button--submit-loader {
			display: inline-block;
		}

		.myex-button--submit-text {
			display: none;
		}
	}

	.myex-button--submit-loader {
		width: 25px;
		height: 25px;
		border: 3px solid #FFF;
		border-bottom-color: transparent;
		border-radius: 50%;
		display: none;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

// -------  bouton submit
.myex-button--aslink {
	@extend .base-button;
	text-decoration: underline;
	background: transparent;
	border-color: transparent;
	font-weight: normal;
	font-size: calculateRem(14px);

	&:hover {
		color: var(--color-primary);
	}
}

.myex-button--black {
	color: black;
}

// BUTTON FONCTIONNALITY
// ------- transparent--dark
.myex-button--transparent-dark {
	@extend .base-button;
	color: #fff;
	background-color: rgba(30, 37, 41, 0.9);
	border-color: transparent;
}

// ------- button action with icons
.myex-button--unsubscribe {
	@extend .icon-ico-favoris-ok;
	@include button-with-icon();
}

.myex-button--subscribe {
	@extend .icon-ico-favoris;
	@include button-with-icon();
}

.myex-button--edit {
	@extend .icon-edit-pencil;
	@include button-with-icon();
}

.myex-button--share {
	@extend .icon-ico-share;
	@include button-with-icon();
}

.myex-button--map {
	@extend .icon-ico-location;
	@include button-with-icon();
}

//button open map mobile
.myex-button--map-mobile {
	@extend .icon-ico-localisation;
	@include button-with-icon();

	@include min-mobile {
		display: none;
	}

	@include mobile {
		position: sticky;
		transform: translate(-50%);
		left: 50%;
		margin-top: calculateRem(40px);
		z-index: $-z-see-map;
		bottom: calculateRem(85px);
		border-radius: calculateRem(21px);
		font-size: calculateRem(11px);
		padding: calculateRem(12px) calculateRem(19px);
		box-shadow: 0 calculateRem(4px) calculateRem(8px) rgba(0, 0, 0, 0.35);
	}
}

.myex-button--close {
	@extend .icon-ico-close;
	@include button-with-icon();
}

.myex-button--deconnexion {
	@extend .icon-ico-logout;
	@include button-with-icon();
	@include reset-button;
	align-items: center;
	color: currentColor;
	padding: 0;

	span {
		text-decoration: underline;
	}
}

// btn edition avatar inside profil
.myex-button--editpicture {
	@extend .icon-edit-pencil;
	@include button-with-icon();
	appearance: none;
	background: var(--current-bg);
	border: none;
	color: currentColor;
	border-radius: 50%;
	height: calculateRem(25px);
	width: calculateRem(25px);
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px calculateRem(3px) calculateRem(3px) rgba(0, 0, 0, 0.1);

	@include min-mobile {
		height: calculateRem(35px);
		width: calculateRem(35px);

	}

	//icon
	&:before {
		font-size: calculateRem(10px);

		@include min-mobile {
			font-size: calculateRem(13px);
		}
	}

	&.on-load:before {
		content: '';
		width: 15px;
		height: 15px;
		border: 3px solid #FFF;
		border-bottom-color: transparent;
		border-radius: 50%;
		display: inline-block;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	&:hover,
	&:active,
	&:focus {
		&:before {
			transform: scale(1.1);
		}
	}
}

.myex-button--favorite {
	padding: calculateRem(8px);
	border-radius: 50%;
	background: #fff;
	width: calculateRem(30px);
	height: calculateRem(30px);
	border: none;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	box-shadow: calculateRem(5px) calculateRem(5px) calculateRem(10px) rgba(0, 0, 0, 0.05);

	&:before {
		@include font();
		font-size: calculateRem(14px);
		transition: all ease 500ms;
		color: black;
	}

	@extend .icon-ico-favoris;

	&:hover {
		@extend .icon-ico-favoris-ok;

		&:before {
			transform: scale(1.1);
		}
	}

	&.active {
		@extend .icon-ico-favoris-ok;

		&:before {
			color: var(--color-primary);
		}
	}
}

//button connexion
.myex-button__connexion {
	@extend .icon-profil;

	&:before {
		@include font();
		font-size: calculateRem(20px);
		transition: all ease 500ms;
	}
}

// button more actions
.myex-button--actions {
	@extend .icon-ico-dots;
	@include button-with-icon();

	appearance: none;
	background: transparent;
	width: calculateRem(30px);
	height: calculateRem(30px);
	border-radius: 50%;
	border: none;
	color: currentColor;
	transition: all ease-in-out 500ms;
	transform: rotate(90deg) translateY(-25%);
	align-items: center;

	@include min-mobile {
		width: calculateRem(34px);
		height: calculateRem(34px);
	}

	&:hover,
	&:active,
	&:focus {
		background: #404649;
		color: var(--color-primary);
	}
}

.myex-button--seedetails {
	@extend .icon-eye;
	@include button-with-icon();
}

.myex-button--addCollection {
	@extend .icon-ico-plus;
	@include button-with-icon();
}

// button back
.myex-button--back {
	@include button-with-icon();
	gap: calculateRem(10px);
	background: transparent;
	appearance: none;
	color: currentColor;
	font-weight: 600;
	border: none;
	padding: 0;
	font-size: calculateRem(12px);
	align-items: center;
	transition: all ease 500ms;
	@extend .icon-ico-chevron-left;

	@include min-mobile {
		font-size: calculateRem(16px);
		gap: calculateRem(20px);
	}

	&:hover {
		color: var(--color-primary);
	}
}

// button listing
.slider-relation-cta-mobile {
	width: 100%;
}
