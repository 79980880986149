.myex-form-field {
	display: flex;
	flex-direction: column;
	gap: 0.188rem;
	position: relative; // for input list suggestion

	&:not(:last-child) {
		margin-bottom: calculateRem(15px);
	}

	input[type="number"],
	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[list],
	textarea {
		height: auto;

		@extend .style-field;
	}

	//hide default arrow buttons
	input::-webkit-calendar-picker-indicator {
		display: none !important;
	}

	// ------asterix if field is required
	&--mandatory {
		label:after,
		.myex-label:after {
			content: " *";
			color: var(--color-primary);
			line-height: inherit;
		}
	}
}
