.myex-popin--modif-collection {
	padding-top: 52px;

	&__top {
		padding-top: 0;
		padding-bottom: 30px;
	}

	&__form {
		max-width: calculateRem(420px);
		margin-left: auto;
		margin-right: auto;

		.myex-form-field {
			&:not(:last-child) {
				margin-bottom: calculateRem(20px);
			}
		}
	}


	&__scroller{
		overflow: scroll;
	}

	&__avatar {
		max-width: calculateRem(134px);
		background: var(--bg-card-img);
		margin-left: auto;
		margin-right: auto;
		margin-top: calculateRem(-52px);
		border-radius: calculateRem(10px);
		border: 8px solid var(--current-bg);
		@include aspect-ratio(172, 172);

		@include min-mobile {
			max-width: calculateRem(172px);
		}
	}

	&__list-choose-img {
		gap: calculateRem(10px);
		display: grid;
		grid-template-columns: repeat(6, minmax(auto, 1fr));

		.myex-form-field {
			margin: 0;
		}
	}

	.myex-modif-collection-loader {
		width: 25px;
		height: 25px;
		border: 3px solid #FFF;
		border-bottom-color: transparent;
		border-radius: 50%;
		display: none;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;

		&.on-load {
			display: inline-block;
		}
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.myex-modif-collection-text {
		&.on-load {
			display: none;
		}
	}
}
