// Animations modifiées pour les adapter aux pages template "landing luxe générale et thématique"

[data-anim="opacity"] {
	&[data-anim-hide] {
		opacity: 0 !important;
		transition: opacity 2s ease;
	}

	&[data-anim-hide="false"] {
		opacity: 1 !important;
	}
}

[data-anim="pop"] {
	&[data-anim-hide] {
		opacity: 0 !important;
		transform: scale(0);
		transition: all 0.2s cubic-bezier(0.21, 0.93, 0.28, 1.51)
	}
	&[data-anim-hide="false"] {
		transform: scale(1);
		opacity: 1 !important;
	}
}

[data-anim="left"] {
	&[data-anim-hide] {
		opacity: 0;
		transform: translate3d(-20%, 0, 0);
		transition: all 0.75s cubic-bezier(0.24, 0.16, 0.17, 0.8);
		@include mobile {
			opacity: 1 !important;
			transform: translate3d(0, 0, 0);
		}
	}
	&[data-anim-hide="false"] {
		opacity: 1 !important;
		transform: translate3d(0, 0, 0);
	}
}

[data-anim="right"] {
	&[data-anim-hide] {
		opacity: 0;
		transform: translate3d(20%, 0, 0);
		transition: all 0.75s cubic-bezier(0.24, 0.16, 0.17, 0.8);
		@include mobile {
			opacity: 1 !important;
			transform: translate3d(0, 0, 0);
		}
	}
	&[data-anim-hide="false"] {
		opacity: 1 !important;
		transform: translate3d(0, 0, 0);
	}
}

[data-anim="top"] {
	&[data-anim-hide] {
		opacity: 0;
		transform: translate3d(0, -20px, 0);
		transition: all 2s ease;
	}
	&[data-anim-hide="false"] {
		opacity: 1 !important;
		transform: translate3d(0, 0, 0);
	}
}

[data-anim="bottom"] {
	&[data-anim-hide] {
		opacity: 0;
		transform: translate3d(0, 20px, 0);
		transition: all 2s ease;
	}
	&[data-anim-hide="false"] {
		opacity: 1 !important;
		transform: translate3d(0, 0, 0);
	}
}

[data-anim="bottom-deep"] {
	&[data-anim-hide] {
		opacity: 0;
		transform: translate3d(0, 20px, 0);
		transition: all 0.75s ease;
	}

	&[data-anim-hide="false"] {
		opacity: 1 !important;
		transform: translate3d(0, 0, 0);
	}
}

[data-anim="text-from-right"] {
	&[data-anim-hide] {
		opacity: 0;
		transform: translate3d(20px, 0, 0);
		transition: all 1s ease;
		@include mobile {
			opacity: 1 !important;
			transform: translate3d(0, 0, 0);
		}
	}

	&[data-anim-hide="false"] {
		opacity: 1 !important;
		transform: translate3d(0, 0, 0);
	}
}

[data-anim="text-from-left"] {
	&[data-anim-hide] {
		opacity: 0;
		transform: translate3d(-20px, 0, 0);
		transition: all 1s ease;
		@include mobile {
			opacity: 1 !important;
			transform: translate3d(0, 0, 0);
		}
	}

	&[data-anim-hide="false"] {
		opacity: 1 !important;
		transform: translate3d(0, 0, 0);
	}
}

[data-anim$="stack"] {
	&[data-anim-hide] {
		> * {
			&:nth-child(1) {
				transition-delay: 0s;
			}

			&:nth-child(2) {
				transition-delay: 0s;
			}
			&:nth-child(3) {
				transition-delay: 0s;
			}
			&:nth-child(4) {
				transition-delay: 0s;
			}
			&:nth-child(5) {
				transition-delay: 0s;
			}
			&:nth-child(6) {
				transition-delay: 0s;
			}
			&:nth-child(7) {
				transition-delay: 0s;
			}
			&:nth-child(8) {
				transition-delay: 0s;
			}
			&:nth-child(9) {
				transition-delay: 0s;
			}
			&:nth-child(10) {
				transition-delay: 0s;
			}
			&:nth-child(11) {
				transition-delay: 0s;
			}
			&:nth-child(12) {
				transition-delay: 0s;
			}
		}
	}
}

[data-anim="left-stack"] {
	&[data-anim-hide] {
		> * {
			opacity: 0;
			transform: translate3d(-20px, 0, 0);
			transition: all 2s ease;
		}
	}
	&[data-anim-hide="false"] {
		> * {
			opacity: 1 !important;
			transform: translate3d(0, 0, 0);
		}
	}
}

[data-anim="right-stack"] {
	&[data-anim-hide] {
		> * {
			opacity: 0;
			transform: translate3d(20px, 0, 0);
			transition: all 2s ease;
		}
	}
	&[data-anim-hide="false"] {
		> * {
			opacity: 1 !important;
			transform: translate3d(0, 0, 0);
		}
	}
}

[data-anim="top-stack"] {
	&[data-anim-hide] {
		> * {
			position: relative;
			opacity: 0 !important;
			transform: translate3d(0, -20px, 0) !important;
			transition: all 2s ease;
		}
	}
	&[data-anim-hide="false"] {
		> * {
			opacity: 1 !important;
			transform: translate3d(0, 0, 0) !important;
		}
	}
}

[data-anim="bottom-stack"] {
	&[data-anim-hide] {
		> * {
			opacity: 0 !important;
			transform: translate3d(0, 40px, 0) !important;
			transition: all 0.5s ease, opacity 0.5s ease-in;
		}
	}
	&[data-anim-hide="false"] {
		> * {
			opacity: 0.3 !important;
			transform: translate3d(0, 0, 0) !important;
		}
	}
}

[data-anim="bottom-listing-stack"] {
	&[data-anim-hide] {
		> * {
			&:nth-child(n+5) {
				opacity: 0 !important;
				transform: translate3d(0, 20px, 0) !important;
				transition: all 2s ease;
			}
			&:nth-child(5) {
				transition-delay: 0.2s;
			}
			&:nth-child(6) {
				transition-delay: 0.2s;
			}
			&:nth-child(7) {
				transition-delay: 0.2s;
			}
			&:nth-child(8) {
				transition-delay: 0.2s;
			}
			&:nth-child(9) {
				transition-delay: 2s;
			}
			&:nth-child(10){
				transition-delay: 0.6s;
			}
			&:nth-child(11){
				transition-delay: 0.7s;
			}
			&:nth-child(12){
				transition-delay: 0.8s;
			}
		}
	}
	&[data-anim-hide="false"] {
		> * {
			&:nth-child(n+5){
				opacity: 1 !important;
				transform: translate3d(0, 0, 0) !important;
			}
		}
	}
}

@keyframes slowZoom {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.1);
	}
}
@keyframes check {
	0% {
		height: 0;
		width: 0;
	}

	33.3333% {
		width: 6px;
		height: 0;
	}
}