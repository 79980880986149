.myex-anchor-slider {
	width: 100%;
	--anchor-slider-ball: #{calculateRem(10px)};

	&--sticky {
		position: sticky;
		background: var(--current-bg);
		top: var(--header-height);
		z-index: $-z-nav;
		box-shadow: 0px 0px calculateRem(10px) calculateRem(10px)
			rgba(30, 37, 41, 0.4);
	}

	&__slider {
		// horizontal line
		&:after {
			content: "";
			height: 1px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba($c_border, 0.3);
		}
	}

	&__track {
		overflow: visible;
		padding-left: var(--side-padding-base) !important;
		padding-right: var(--side-padding-base) !important;
		@include gradient-overflow(var(--side-padding-base), var(--current-bg));
	}

	&__scroll {
		position: relative;
		display: flex;
		align-items: stretch;
		white-space: nowrap;
		padding: 0;
		width: 100%;
		margin: 0 auto;
	}

	&__slide {
		display: flex;
		align-items: center;
		padding: calculateRem(16px) 0;
		margin: 0;
		color: currentColor;
		font-weight: 600;
		font-size: calculateRem(12px);
		width: unset !important; // overwrite js style

		&:first-child {
			padding-left: 0;
		}

		// element active
		&.active {
			color: $c_third;
			&:after {
				opacity: 1;
			}
		}
		//circle active item
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			width: var(--anchor-slider-ball);
			height: var(--anchor-slider-ball);
			background: var(--color-primary);
			transform: translate(-50%, 50%);
			border-radius: 50%;
			opacity: 0;
			@include addTransition();
		}
	}

	&__button {
		position: relative;
		display: flex;
		align-items: center;
		gap: calculateRem(7px);
		width: auto;
		margin: 0;
		font-weight: 600;
		background: transparent;
		white-space: nowrap;
		color: currentColor;
		@include addTransition();
		line-height: 1.16;
		appearance: none;
		border: none;
		cursor: pointer;

		i,
		.svg {
			font-size: calculateRem(16px);
		}

		.myex-logo {
			font-size: calculateRem(10px);
		}

		&:hover {
			color: currentColor !important; // overwrite
		}
	}
}
