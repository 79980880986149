.lemag__video-slider-row {
    overflow:hidden;
    background: white;

    .content-wrapper {
        width:100%;
    }
}

.splide-videos-slider {

    margin-right: -240px;
    margin-bottom: 22px;

    @include wide {
        margin-right: -290px;
    }

    @include tablet_landscape {
        margin-right: auto;
        margin-bottom: 11px;
    }

    .splide__slide--has-video {

        border-radius: 10px;
        overflow: hidden;

        &:hover {
            .bloc-texte {
                h3, .splide-video-info__time {
                    opacity: 0.7;
                }
            }

            .splide__video__play {
                opacity: 0.7;
            }
        }

        figure {
            height: 100%;
        }

        iframe {
            position: relative;
            z-index: 99;
        }

        .splide__video__play {
            background: transparent;
            border: 6px solid white;
            opacity: 1;
            height: 80px;
            width: 80px;
            left: 76px;
            z-index: 8;

            @include mobile {
                height: 40px;
                width: 40px;
                border-width: 4px;
                left: 45px;
            }

            &::after {
                border-color: transparent transparent transparent #fff;
                border-width: 20px 0 20px 37px;
                margin-left: 8px;

                @include mobile {
                    border-width: 9px 0 9px 17px;
                    margin-left: 4px;
                }
            }
        }
    }

    .wrapper-bloc-video {
        position: relative;
        height: 100%;

        &::before {
            content: "";
            position: absolute;
            z-index: 4;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0.3;
        }

        .bloc-texte {
            position: absolute;
            left: 152px;
            top: 50%;
            bottom: auto;
            transform: translateY(-40px);
            transition-duration: 0.15s;
            margin-bottom: 0;

            h3 {
                margin-bottom: 0;
                color: #fff;
            }

            @include mobile {
                left: 80px;
                transform: translateY(-20px);
            }
        }

        .splide-video-info {
            color: white;

            &__time {
                font: normal normal 600 13px/22px Poppins;
            }

            &__cta {
                font: normal normal 500 13px/22px Poppins;
                text-transform: uppercase;
                margin-left: 20px;

                @include mobile {
                    font-size: 11px;
                }
            }
        }

    }

}

.splide-videos-slider-thumbnails {

    padding-right: 30px;

    @include mobile {
        padding-right: 0;
    }

    &::after {
        content: "";
        position: absolute;
        right: 0px;
        top: -8px;
        height: 100%;
        width: 80px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 69%, rgba(255,255,255,1) 100%);

        @include mobile {
            right: -4px;
        }
    }

    .splide__slide {
        border: 0 !important;

        figure {
            flex-shrink: 0;
            width: 100px;
            height: 100px;
            border-radius: 5px;
            overflow: hidden;
            background: black;

            @include mobile {
                width: 75px;
                height: 75px;
            }

            img {
                opacity: 0.8 !important;
            }

            &::before {
                content: "";
                z-index: 8;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                
                border: 3px solid white;
                border-radius: 100%;
                opacity: 1;
                height: 30px;
                width: 30px;
            }

            &::after {
                content: "";
                z-index: 8;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                border-style: solid;
                border-width: 7px 0 7px 12px;
                display: inline-block;

                border-color: transparent transparent transparent #fff;
                margin-left: 2px;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;

        &__content {
            margin-left: 18px;
        }

        &__title {
            font: normal normal 600 16px/22px Poppins;
            margin-bottom: 0;
            
            @include mobile {
                font-size: 13px;
                line-height: 18px;
            }
        }

        &__time {
            font: normal normal normal 11px/22px Poppins;

            color: var(--darkblue);
            padding-left: 17px;
            position: relative;
            margin-bottom: 0;

            &::before {
                content: '\e9eb';
                @include font();
                font-size: 12px;
                color: var(--rose);
                top: 3px;
                left: 0;
                position: absolute;
                height: 12px;
                .bloc__grandest & {
                    color: var(--darkblue);
                }
            }
        }
    }
}