.filterRange,
.facet_slider {
	height: 5px;
	box-shadow: none;
	background: none;
	border-radius: 0;
	border: none;
    margin: 10px 0 14px 0;

	@include mobile{
		width: calc(100% - 20px);
	}
	.noUi-base {
		background: $c_border_input;
		border-radius: 5px;
	}
	.noUi-connect {
		background-color: $c_third;
		border-radius: 5px;
	}
	.noUi-tooltip {
		@include ft($f_txt, 12px, 400, $c_surtitre, 16px);
		border: none;
		background: none;
		padding: 0;
        bottom: auto;
        margin-top: 15px;
	}
	.noUi-handle {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $c_white;
        box-shadow: none;
		right: -20px;
		top: -8px;
		border: 6px solid $c_greydark;
		box-sizing: content-box;
		@include addTransition();

		&:hover, &:focus {
			body:not(.no-hover) & {
				cursor: pointer;
			}
		}
		&:before, &:after {
			content: none;
		}
		&.noUi-handle-upper {
			right: -20px;
		}
        .noUi-touch-area{
			opacity: 0;
        }
	}
}