.page-template-page-my-explore {
	background-color: var(--current-bg);
	color: var(--color-text);
	min-height: 100dvh;

	p {
		color: currentColor;
	}

	p:last-child {
		margin-bottom: 0;
	}

	* {
		box-sizing: border-box;
	}

	#main-my-explore {
		overflow-x: clip;
	}

	#app-my-explore {
		position: relative;
	}

	.myex-generic-loader {
		width: 18px;
		height: 18px;
		border: 3px solid #FFF;
		border-bottom-color: transparent;
		border-radius: 50%;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;

		@include mobile {
			width: 15px;
			height: 15px;
		}
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}



