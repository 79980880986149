.bloc-explorer-lieux {
    margin-bottom: 50px;
    /*height: 50vh;*/
    padding-top: 50px;

    @include tablet_portrait {
        margin-bottom: 0;
    }

    .splide:not(.is-overflow) .splide__pagination {
        display: flex;
    }

    .lieux-title {
        padding-left: 13vw;
        font-size: 28px;
        font-weight: 500;
        color: white;

        @include tablet_portrait {
            padding-left: 7vw;
        }
    }

    .lieux-cta-desktop {
        position: absolute;
        right: 13vw;

        @include tablet_portrait {
            display: none;
        }
    }

    .lieux-cta-mobile {
        display: none;
        position: relative;
        bottom: -45px;
        left: 40px;
        width: calc(100% - 80px);

        @include tablet_portrait {
            display: inline-block;
        }

        @include mobile {
            left: 20px;
        }
    }

    .splide__track {
        margin-top: 20px;

        .splide__slide {

            .lieux-tab {
                max-width: 220px;
                margin-right: 30px;

                @include mobile {
                    max-width: 150px;
                    margin-right: 0;
                }

                a {

                    .item {
                        width: 220px;
                        height: 220px;

                        @include mobile {
                            max-width: 150px;
                            max-height: 150px;
                        }

                        figure, img {
                            border-radius: 10px;
                        }

                        .caption {
                            position: relative;
                            left: 0;
                            right: 0;
                            width: 100%;
                            text-align: center;
                            top: 60%;
                            z-index: 102;

                            @include mobile {
                                position: absolute;
                                top: 20%;
                            }

                            p {
                                color: white;
                                font-size: 20px;
                                font-weight: 600;

                                @include mobile {
                                    font-size: 18px;
                                }
                            }

                            &+figure:after {
                                position: absolute;
                                content: '';
                                right: auto;
                                bottom: 0;
                                height: 100%;
                                width: 100%;
                                max-width: calc(900px + 120px);
                                opacity: 0.75;
                                z-index: 10;
                                background: linear-gradient(to top, #202529 0%, rgba(32,37,41,0) 100%);
                            }
                        }

                        img {
                            height: 220px;

                            @include mobile {
                                height: 150px;
                            }
                        }
                    }

                    .hovered {
                        opacity: 0;

                        &:after {
                            background: linear-gradient(to bottom, rgba(32,37,41,0) 0%, #202529 100%);
                            content: '';
                            z-index: 0;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;

                        }
                    }

                    &:hover {
                        .hovered {
                            transition: all .2s ease;
                            opacity: 1;
                        }
                    }
                }

                ul {
                    margin-top: 35px;

                    @include mobile {
                        margin-top: 10px;
                    }

                    li {

                        a {
                            font-size: 14px;
                            font-weight: 500;
                            text-decoration: underline;
                            line-height: 18px;
                        }

                        &.lieux-autres-liens a {
                            color: white;

                            &:hover {
                                color: #DEA99E;
                            }
                        }

                        &.lieux-lien-general a {
                            color: #DEA99E;
                        }
                    }
                }
            }
        }
    }

    .splide__arrow {
        top: unset;
        bottom: -55px;
        background-color: #1E2529;

        svg {
            fill: white;
        }

        &--prev {
            left: unset;
            right: 16vw;
            @media screen and (min-width: 1440px) {
                right: 15vw;
            }
        }

        &--next {
            right: 13vw;
        }

        span:before {
            font-size: 18px;
        }

        @include tablet_portrait {
            display: none;
        }
    }

    .class-pagination {

        bottom: -2.5em;
        padding-right: 7em;
        padding-left: 0;

        @include tablet_portrait {
            padding: 0 2em 0 2em;
        }

        @include mobile {
            padding: 0;
        }
    }
    .class-page {
        margin: 0;
        height: 1px;
        border-radius: 8px;
        width: 100%;
        background-color: #627A89;
        &.is-active {
            height: 5px;
            background-color: #DEA99E;
            opacity: 1;
            transform: unset;
            padding-bottom: 2px;
        }
    }
}

