.recipe {

    @include tablet_portrait {
		&#content.content--scrollable {
			margin-top: -29px;
		}
	}

    background-color: var(--darkblue);

    .lemag__title {
        @include mobile {
            display: none;
        }
    }

    .content-wrapper--lemag-title {
        @media screen and (min-width: 1440px) {
            margin: 0 80px;
        }
    }

    &__bg {

        position: relative;

        &::before {
            content: '';
            position: absolute;
            z-index: 0;
            top: -505px;
            width: 100%;
            height: calc(100% + 505px);
            background-color: var(--white);
        }

        .credits {
            text-align: right;
            position: static;
            width: 100%;
            display: block;

            @include mobile {
                padding-right: 20px;
            }
        }

    }

    &__header {
        &::before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            top: 8px;
            margin: auto;
            width: 129px;
            height: 4px;
            border-radius: 2px;
            background-color: rgba(#1E2529, 0.3);
            display: none;

            @include mobile {   
                display: block;
            }
        }

        position: relative;
        z-index: 1;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        max-width: 1440px;
        width: 100%;
        padding-top: 60px;
        margin: 0px auto;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &__headline {

            position: relative;
            font: normal normal normal 11px/17px Poppins;
            letter-spacing: 1.65px;
            color: var(--darkblue);
            text-transform: uppercase;
            text-align: center;
            padding-left: 13px;
            margin-bottom: 7px;
            display: flex;

            @include tablet_portrait {
                flex-direction: column;
                align-items: center;
            }

            strong {
                position: relative;

                @include tablet_portrait {
                    margin-left: 8px;
                }

                &::before {
                    content: '\e907';
                    @include font();
                    font-size: 10px;
                    color: var(--darkblue);
                    top: 3px;
                    left: -14px;
                    position: absolute;
                    height: 10px;
                    width: 6px;
                }
            }
        }

        h1 {
            font: normal normal bold 44px/48px Poppins;

            color: var(--darkblue);
            text-align: center;

            @include mobile {
                font-size: 28px;
                line-height: 34px;
                padding: 0 15px;
            }
        }

        p {
            max-width: 900px;
            font: normal normal normal 22px/34px Libre Baskerville;
            text-align: center;
            color: var(--darkblue);

            @include mobile {
                font-size: 15px;
                line-height: 22px;
                padding: 0 15px;
            }
        }

        &_author {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-bottom: 40px;

            figure {
                width: 67px;
                height: 67px;
                border-radius: 40px;
                overflow: hidden;
            }

            &--title {
                margin-left: 10px;

                p {
                    font: normal normal normal 15px/30px Poppins;
                    text-align: left;
                    color: #172540;
                    margin-bottom: 0px;
                }

                h6 {
                    text-align: left;
                    font: normal normal normal 26px/30px Oooh Baby;

                    color: #172540;
                }
            }

        }

    }

    .breadcrumbs-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        @include tablet_portrait {
            flex-direction: column-reverse;
            align-items: baseline;
        }

        @include mobile {
            display: none;
        }
    }

    &__nav {
        position: relative;
        z-index: 5;
        max-width: 1600px;
        width: 100%;
        margin: 0 auto;
        background-color: #fff;
    }


    &__content {
        position: relative;
        z-index: 2;
        width: 100%;
        margin: 0 auto;

        &_info {

            margin: 0 auto;

            height: 115px;
            width: 100%;
            background-color: #F4F4F4;
            border: 1px solid rgba(#ABB3B9, 0.5);

            ul {
                max-width: 515px;
                width: 100%;
                height: 100%;
                margin: 0 auto;
                display: flex;
                justify-content: space-around;
                align-items: center;

                li {
                    font: normal normal 600 15px/16px Poppins;

                    color: var(--darkblue);
                    margin-left: 40px;
                    position: relative;
                    .icon::before {
                        position: absolute;
                        top: -12px;
                        left: -40px;
                        color: var(--rose);
                        font-size: 30px;

                        @include mobile {
                            top: -26px;
                            left: 0px;
                            right: 0px;
                            margin: 0 auto;
                            text-align: center;
                        }
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        &::after {
                            content: '';
                            position: absolute;
                            top: -18px;
                            left: -70px;

                            @include mobile {

                                left: -28px;
                            }

                            height: 47px;
                            width: 1px;
                            background-color: rgba(#ABB3B9, 0.5);
                        }
                    }
                }
            }
        }

        &_ingredients {
            position: relative;
            z-index: 10;
            margin: 0 auto;
            margin-top: -20px;
            max-width: 900px;
            width: calc(100% - 30px);

            @include mobile {
                padding: 0 15px;
            }

            background: #DEA99E 0% 0% no-repeat padding-box;
            border-radius: 14px;

            &::before {
                content: '\e9e9';
                position: absolute;
                bottom: 35px;
                right: 25px;
                @include font();
                font-size: 82px;
                color: #965050;
            }

            h4 {
                padding-top: 19px;
                margin-left: 20px;
                font: normal normal 600 20px/24px Poppins;

                color: #FFFFFF;
            }

            ul {
                column-count: 2;
                padding: 26px 20px;

                @include mobile {
                    column-count: 1;
                }

                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: var(--white);
                    opacity: 0.7;
                }

                li {
                    font: normal normal normal 15px/30px Poppins;

                    color: #FFFFFF;
                    float: left;
                    margin-bottom: 5px;
                    width: 100%;
                    position: relative;
                    padding-left: 10px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 11px;
                        left: 0;
                        border-radius: 10px;
                        width: 4px;
                        height: 4px;
                        background-color: var(--white);
                    }
                }
            }
        }

        &_steps {

            max-width: 900px;
            width: 100%;
            margin: 0 auto;
            padding-top: 80px;

            @include mobile {
                padding-top: 20px;

                padding-left: 15px;
                padding-right: 15px;
            }

            h2 {
                font: normal normal 600 40px/44px Poppins;

                color: var(--darkblue);
                margin-top: 40px;

                @include mobile {
                    margin-top: 20px;
                    padding-bottom: 20px;
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            &--item {}
        }

        &_story {
            max-width: 1230px;
            width: 100%;
            margin: 0 auto;

            padding-top: 80px;


            @include mobile {
                padding-top: 0px;
                padding-left: 15px;
                padding-right: 15px;
            }

            h2 {
                font: normal normal 600 40px/44px Poppins;



                color: var(--darkblue);
                margin-bottom: 40px;

                @include mobile {
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 20px;
                }
            }

            &--wrapper {
                max-width: 900px;
                width: 100%;
                margin: 40px auto;

                @include mobile {
                    margin: 20px auto;
                }

                p {
                    font: normal normal normal 15px/26px Libre Baskerville;
                    color: #172540;
                    margin-bottom: 30px;

                    @include mobile {
                        font-size: 13px;
                        line-height: 21px;
                    }
                }

                h3 {
                    font: normal normal normal 18px/26px Poppins;
                    font-weight: 700;
                    color: #172540;
                    margin-bottom: 5px;

                    @include mobile {
                        font-size: 15px;
                        line-height: 21px;
                    }
                }

            }
        }
    }


}