.pagination {
    position: relative;
    text-align: center;
    padding: 20px 0;
    background-color: $c_white;
    
    &:empty {
        display: none;
    }

    @include mobile {
        position: sticky;
        bottom: 0;
        z-index: 1000;
        padding: 15px 0;
        box-shadow: -30px 0 40px rgba($c_dark,0.15);
        box-shadow: 0.08s cubic-bezier(0.16, 0.91, 1, 1);

        &.unstick {
            box-shadow: unset;
        }
    }

    .content-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        @include tablet_portrait{
            flex-wrap: wrap;
        }

        @include mobile {
            flex-wrap: initial;
            justify-content: initial;
            align-items: initial;
        }
    }

    .indic-pagination {
        padding-left: 20px;

        strong {
            font-weight: 700;
        }
    }

    .btn-pagination {
        & + * {
            margin-left: 20px;

            @include mobile {
                margin-left: 12px;
            }
        }

        &.--next {
            @include mobile {
                flex-grow: 1;
            }
        }
    }

    .pagination-number {
        display: flex;
        justify-content: center;
        padding: 0 10px;

        @include tablet_portrait {
            order: -1;
            width: 100%;
            margin-bottom: 20px;
            margin-left: initial;
            padding: initial;
        }

        @include mobile {
            display: none;
        }

        .btn-page, span {
            position: relative;
            display: inline-block;
            padding: 0 8px;
            @include ft('', 13px, 400, $c_border, 1.53);
            text-align: center;

            &.disabled {
                opacity: 0.2;

                @include mobile {
                    opacity: 0.5;
                }

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        cursor: default;
                    }
                }
            }
        }

        .btn-page {
            @include addTransition();

            &.is-active {
                font-weight: 600;
                color: $c_greydark;

                &:after {
                    opacity: 1;
                }
            }

            @include can-hover {
                font-weight: 600;
                color: $c_greydark;
            }

            &:after {
                content: '';
                position: absolute;
                top: calc(50% + 10px);
                left: 0;
                right: 0;
                margin: auto;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                opacity: 0;
                background-color: $c_third;
                @include addTransition();
            }
        }
    }

    // Select seulement en mobile
    .select-pagination-wrapper {
        display: flex;
        align-items: center;
        padding-left: 20px;
        margin-left: auto;
        @include ft('', 11px, 400, $c_greydark, 1);

        &:after {
            @include font();
            content: '\e900';
            display: inline-block;
            margin-left: 8px;
            font-size: 8px;
            color: #A0A5A7;
        }

        @include min-mobile {
            display: none;
        }

        label {
            display: none;
        }

        select {
            @include ft('', 11px, 700, $c_greydark, 1);
            width: initial;
            border: initial;
            padding: initial;
        }
    }
}