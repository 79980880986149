.myex-preview-profil {
	display: flex;
	gap: calculateRem(12px);
	&__avatar {
		border-radius: 50%;
		width: calculateRem(35px);
		height: calculateRem(35px);
		overflow: hidden;
		background: var(--bg-card-img);

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&__content {
		align-items: center;
	}

	&__name {
		letter-spacing: 0.02em;
		font-weight: 600;
		line-height: 1.2;
		display: block;
	}

	&__link {
		display: block;
		@include reset-button;
		color: currentColor;
		text-decoration: underline;
	}

	+ .myex-button--deconnexion{
		margin-top: calculateRem(20px);;
	}
}
