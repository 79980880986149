.myex-popin--add-lieu {
	overflow: visible;

	.myex-popin__scroller {
		border-radius: 0 0 15px 15px;

	}
}

.myex-form--add-place {
	display: flex;
	row-gap: calculateRem(25px);
	column-gap: calculateRem(15px);
	flex-direction: column;
	padding-bottom: 30px;

	@include min-mobile {
		flex-direction: row;
	}

	.myex-form-field {
		width: 100%;
		@extend .bg--light;
		background: transparent;
		margin-bottom: 0;
	}

	.myex-button--submit {
		flex-shrink: 0;
	}

	> * {
		margin: 0;
	}

	.myex-bloc-lieu-loader {
		width: 25px;
		height: 25px;
		border: 3px solid #FFF;
		border-bottom-color: transparent;
		border-radius: 50%;
		display: none;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;

		&.on-load {
			display: inline-block;
		}
	}

	.myex-bloc-lieu-text {
		&.on-load {
			display: none;
		}
	}
}
