.post-type-archive-experiences {
    .header-color {
        display: none;
    }
}

.page-listing-experiences {
    padding-bottom: 20px;

    header {
        background: $c_greydark;

        .bloc-texte{
            @include tablet_portrait{
                padding: 0;
            }
        }

        #breadcrumb{
            @extend %breadcrumb-white;
            a{
                text-decoration: none;
            }
        }
    }


    // TOP EXPERIENCES
    .th-slider-wrapper{
        
        margin-top: 80px;

        @include mobile {
            margin-top: 40px;
        }

        .th-slider{

            display: flex;
            align-items: stretch;
            .item{
                width: 920px;
                height: 300px;
                margin-right: 80px;
                @include desktop{
                    width: 800px;
                    margin-right: 60px;
                }
                @include tablet_landscape{
                    width: 700px;
                }
                @include tablet_portrait{
                    width: 400px;
                }
                @include mobile{
                    width: 315px;
                    margin-right: 20px;
                }
                @include mobile420{
                    width: calc(100vw - 120px);
                }
                @include mobile_small{
                    width: calc(100vw - 80px);
                }
            }
            .card{
                width: 80%;
                height: 100%;
                background: none;
                &:hover, &:focus{
                    body:not(.no-hover) &{
                        .bloc-texte{
                            .basic-link{
                                color: $c_grey;
                            }
                        }
                    }
                }
                > div{
                    display: flex;
                    flex-wrap: wrap;
                    transform: none;
                    flex-direction: row;
                    align-items: center;
                    @include tablet_portrait{
                        display: block;
                    }
                    > * {
                        width: 50%;
                        @include tablet_portrait{
                            width: 100%;
                        }
                    }
                }
                figure{

                    @include aspect-ratio(600, 400);
                    height: 100%;

                    &:after{
                        content: none;
                        @include tablet_portrait{
                            content: '';
                        }
                    }
                }

                .wrapper-caption{
                    position: relative;
                    box-sizing: border-box;
                    z-index: 500;
                    padding: 0 0 0 40px;

                    @include tablet_portrait{
                        padding: 0;
                    }
                    > * {
                        box-sizing: border-box;
                        padding: 10px 0;

                        @include tablet_landscape{
                            padding: 15px 0;
                        }
                        @include tablet_portrait{
                            padding: 0;
                        }
                    }

                    .bloc-texte{
                        p{
                            @include tablet_portrait{
                                display: none;
                            }
                        }
                        .basic-link{
                            text-align: left;
                            @include mobile{
                                margin-right: 0;
                            }
                        }
                    }
                }

                .caption{
                    position: relative;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    display: flex;
                    align-items: flex-end;
                    padding: 0;

                    @include tablet_portrait{
                        display: block;
                        padding: 0;
                    }

                    .wrapper-txt{
                        display: flex;
                        flex-wrap: wrap;
                        @include tablet_portrait{
                            display: block;
                        }
                    }

                    .wrapper-cat{
                        order: 1;
                    }

                    .title-card{
                        font-size: calculateRem(40px);
                        line-height: calculateRem(40px);
                        order: 2;
                        margin: 0;
                        font-weight: 600;

                        @include tablet_landscape{
                            font-size: calculateRem(26px);
                            line-height: calculateRem(28px);
                            margin-bottom: 10px;
                        }
                        @include mobile{
                            font-size: calculateRem(18px);
                            line-height: calculateRem(18px);
                        }
                    }
                }
            }
        }

        .nav{
            button{
                top: calc(50% - 30px);
                margin-top: -30px;
            }
        }
    }

    // LISTING EXPERIENCES
    .wrapper-listing-experiences{
        .facettes-experiences{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 70px;
            padding-bottom: 45px;
            @include desktop1280{
                padding-left: 0;
                padding-right: 0;
            }
            @include tablet_landscape{
                padding: 50px 0 25px 0;
            }
            @include tablet_portrait{
                padding: 40px 0 15px 0;
            }
            @include mobile{
                padding: 30px 0 15px 0;
            }
        }

        .wrap-btn-filtrer{
            margin-top: 20px;
            text-align: right;
            @include tablet_portrait {
                display: none;
            }
        }

        .listing-title-separator {
            border: none;
            border-top: 1px solid rgba($c_grey70,0.2);
        }

        .wrapper-listing-cards{
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 20px;
            @include tablet_landscape{
                padding-bottom: 0;
            }
            > * {
                margin: 20px 0;
                
                @include mobile{
                    width: 100%;
                    margin: 10px 0;
                    &:nth-child(2),&:nth-child(2n+3), &:nth-child(3n+4), &:nth-child(3n+6), &:nth-child(3n+5){
                        margin-top: 10px;
                    }
                }
            }
        }
    }

}