.myex-collection_is-loading, .myex-home_is-loading, .myex-explorer_is-loading, .myex-favorites_is-loading {

    h1, h2, h3, p, span {
        display: inline-block;
    }

    .slider-loader {
        display: flex;

        a {
            margin-right: 30px;
        }
    }

    .loader {
        background: linear-gradient(100deg, #303336 8%, #414548 18%, #303336 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
        border: none;

        &:before , &:after {
            content: '';
        }

        &.w400 {
            width: 400px;
        }

        &.w320 {
            width: 320px;
        }

        &.w245 {
            width: 245px;
        }

        &.w230 {
            width: 230px;
        }

        &.w220 {
            width: 220px;
        }

        &.w215 {
            width: 215px;
        }

        &.w210 {
            width: 210px;
        }

        &.w185 {
            width: 185px;
        }

        &.w165 {
            width: 165px;
        }

        &.w135 {
            width: 135px;
        }

        &.w115 {
            width: 115px;
        }

        &.w85 {
            width: 85px;
        }

        &.w25 {
            width: 25px;
        }

        &.h220 {
            height: 220px;
        }

        &.h45 {
            height: 45px;
        }

        &.h40 {
            height: 40px;
        }

        &.h35 {
            height: 35px;
        }

        &.h25 {
            height: 25px;
        }

        &.h15 {
            height: 15px;
        }

        &.mb10 {
            margin-bottom: 10px;
        }
    }

    @keyframes shine {
        to {
            background-position-x: -200%;
        }
    }

    &.loaded {
        display: none;
    }


    // Specificities
    .myex-card-explorer__avatar, .myex-explorer__header__avatar {
        border-radius: 50%;
    }

    .myex-to-expand--hide:before {
        content: unset;
    }

    .myex-to-expand__button {
        display: none;
    }


    @include mobile {

        .no-mobile {
            display: none;
        }

        // Card
        .slider-loader {
            .myex-card-collection {
                flex-direction: column;
            }
        }

        // Single collection
        .myex-collection__header {
            margin-bottom: calculateRem(55px);

            &__title {
                max-width: 200px;
            }

            .myex-author__content {
                max-width: 165px;
            }
        }

        .myex-card-product {
            &__meta {
                max-width: 110px;
                max-height: 15px;
            }

            &__title {
                max-width: 175px;
                max-height: 20px;
            }

            &__price {
                max-width: 100px;
                max-height: 20px;
            }
        }

        // Single explorer
        .myex-explorer__header__title {
            max-width: 200px;
        }
    }
}