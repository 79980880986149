/*
 *
 * tout ce css peut être modifié sans problème
 * c'était juste pour avoir un truc plus ou moins propre et utilisable pendant le dev
 *
 */

#overlay-slider-offres-auto {
    background-color: $c_white;

    &.open {
        max-width: 75% !important;
        right: 0;
        left: auto;
        @include tablet_landscape {
            max-width: 100% !important;
        }
    }

    .top {
        background-color: $c_white;
        color: $c_titre;
        border-bottom: 1px solid rgba($c_border, 0.5);

        &:after {
            display: none;
        }

        .title-overlay {
            padding-top: 40px;
        }

        .close-overlay {
            cursor: pointer;
            color: $c_grey;
        }

        .right-col {
            display: flex;
            align-items: center;
        }

    }

    .content {
        position: relative;
        z-index: 10;
        width: 100%;
        height: 100%;
        overflow: visible;
        @include tablet_portrait {
            overflow: hidden;
        }

        .wrapper-results {
            margin: 18px 30px 20px auto;
            text-align: right;
            color: rgba($c_grey, 0.5);
        }

        .facette_budget {
            position: relative;
            margin-bottom: 0px;
            margin-top: 6px;
            padding: 5px 40px 20px 20px;
            width: calc(100% - 450px);
            margin-left: auto;
            @media (max-width: 1420px) {
                width: calc(100% - 450px);
                padding: 0 40px 20px 20px;
            }
            @include tablet_landscape {
                width: calc(100% - 400px);
                margin-top: 10px;
            }
            @include tablet_portrait {
                width: 100%;
                padding: 0 40px 20px 41px;
            }
            @include mobile {
                padding: 0 20px 20px 21px;
            }

            .title-group {
                color: $c_txt;
                font-weight: 600;
            }
        }

        .scroller {
            padding: 0;
            color: $c_dark;

            .has-no-results, #results {
                position: relative;
                float: right;
                width: calc(100% - 458px);
                @include tablet_landscape {
                    width: calc(100% - 400px);
                }
                @include tablet_portrait {
                    width: 100%;
                    float: none;
                    margin: 0;
                    margin-top: -15px;
                }
            }
            #results {
                position: relative;
                padding: 0px 20px 5px 20px;
                margin-top: 0px;
                margin-bottom: 120px;
                box-sizing: border-box;

                display: flex;
                flex-wrap: wrap;

                @include tablet_portrait {
                    padding: 0 40px;
                    margin-bottom: 20px;
                }
                @include mobile {
                    padding: 0 20px;
                }

                .wrapper-listing-cards__see-more {
                    width: 100%;
                    text-align: center;
                }

                &:after {
                    content: '';
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100px;
                    clear: both;
                    @include mobile {
                        height: 40px;
                    }
                }

                .card {
                    margin: 20px 2.5%;
                    padding: 0;

                    width: 45%;

                    @include desktop1280 {
                        width: 95%;
                    }

                    @include tablet_landscape {
                        width: 45%;
                    }

                    @include tablet_small {
                        width: 95%;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .reservable {
                        left: unset;
                    }
                }

            }

        }

        .wrapper-map {
            position: absolute;
            top: 0px;
            left: 0;
            bottom: 0;
            float: left;
            width: 450px;
            height: 100%;
            z-index: 1;
            background: $c_main;
            @include tablet_landscape {
                width: 400px;
            }
            @include tablet_portrait {
                display: none;
            }
            .map {
                width: 100%;
                height: 100%;
            }
        }

        // Bottom Fixe filtres / map tablette portrait + mobile
        .bottom {
            display: none;
            background: $c_white;
            @include tablet_portrait {
                display: flex;
                justify-content: center;
            }

            &:before {
                background: linear-gradient(to top, rgba(255, 255, 255, 0.99) 0%, rgba(255, 255, 255, 0.79) 20%, rgba(255, 255, 255, 0.59) 40%, rgba(255, 255, 255, 0.39) 60%, rgba(255, 255, 255, 0.19) 80%, rgba(255, 255, 255, 0.01) 100%);
            }

            > * {
                position: relative;
                margin: 0;
                flex: none;
                width: calc(50% - 10px);
                min-width: calc(50% - 10px);
                font-size: calculateRem(15px);
                padding: 21px 20px 19px;
                @include mobile{
                    width: calc(50% - 5px);
                    min-width: calc(50% - 5px);
                    font-size: calculateRem(12px);
                    padding: 11px 20px 9px;
                }
            }

            .btn-map{
                text-align: left;
                &:after{
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    margin-top: -10px;
                    @include mobile{
                        font-size: 14px;
                        line-height: 14px;
                        margin-top: -7px;
                    }
                }
            }

            .btn-filtrer{
                background: $c_sub;
                &:before{
                    top: 50%;
                    position: absolute;
                    left: 20px;
                    margin-top: -10px;
                    @include mobile{
                        font-size: 14px;
                        line-height: 14px;
                        margin-top: -7px;
                    }
                }
                span{
                    color: $c_greydark;
                    background: $c_white;
                    right: -5px;
                }
            }
        }

    }

    .facet_slider .noUi-handle {
        border: 6px solid $c_greydark;
    }

    .facet_slider .noUi-handle .noUi-touch-area {
        background: $c_greydark;
    }

    // overlay map mobile
    &-map {
        .map {
            width: 100%;
            height: 100%;
        }

        .th-slider-wrapper {
            @include mobile {
                margin-left: -20px;
                margin-right: -20px;
                width: calc(100% + 40px);
                max-width: calc(100% + 40px) !important;
            }

            .th-slider-auto-overlay-map {
                @include mobile {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
    }
}
