// AU NIVEAU DU BLOC PARENT
.th-slider-wrapper {
    overflow: hidden;
    position: relative;
    &.bloc {
        padding-bottom: 0;
    }

    // WRAPPER DU SLIDER
    .wrapper-slider {
        display: block;
        position: relative;
        max-width: $max-width;
        margin: 0 auto;
        z-index: 20;
        @include wide{
            max-width: $max-width-large;
        }
        @include desktop {
            margin: 0 $border-gutter;
            width: calc(100% - #{$border-gutter*2});
        }
        @include tablet_landscape {
            margin: 0 40px;
            width: calc(100% - 80px);
        }
        @include mobile {
            margin: 0 20px;
            width: calc(100% - 40px);
        }

        // TOUCH DEVICE
        .no-hover & {
            @include desktop {
                margin-left: -$border-gutter;
                margin-right: -$border-gutter;
                width: calc(100% + #{$border-gutter*2});
                max-width: calc(100% + (#{$border-gutter}*2));
            }
            @include tablet_landscape {
                margin-left: -40px;
                margin-right: -40px;
                width: calc(100% + 80px);
                max-width: calc(100% + 80px);
            }
            @include mobile {
                margin-left: -20px;
                margin-right: -20px;
                width: calc(100% + 40px);
                max-width: calc(100% + 40px) !important;
            }
        }
    }

    // SLIDER
    .th-slider:not(.nav-categories),
    .th-slider-auto-overlay-map,
    .timeline-slider {
        position: relative;
        display: inline-flex;
        font-size: 0;
        white-space: nowrap;
        padding-bottom: $content-spacing/2;

        @include tablet_landscape{
            padding-bottom: $content-spacing-tab/2;
        }
        @include mobile {
            padding-bottom: $content-spacing-mobile/2;
        }

        // SCROLL MODE
        &.scroll-mode {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            scroll-snap-type: x mandatory;
        }

        &.scroll-mode > div, > a, > li, > button {
            scroll-snap-align: start;
        }
        &.scroll-mode > div:last-child, > a:last-child, > li:last-child, > button:last-child {
            scroll-snap-align: end;
        }

        // TOUCH DEVICE
        .no-hover & {
            @include desktop {
                overflow: hidden;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                scroll-snap-type: both mandatory;
                scroll-padding: $border-gutter;
                padding-left: $border-gutter;
                padding-right: $border-gutter;
                margin: 0 $border-gutter;
                > div, > a, > li {
                    scroll-snap-align: start;
                }

                > div:last-child, > a:last-child, > li:last-child {
                    scroll-snap-align: end;
                    margin-right: 0;
                }
            }
            @include tablet_landscape {
                scroll-padding: 40px;
                padding-left: 40px;
                padding-right: 40px;
                margin: 0 40px;
            }
            @include mobile {
                scroll-padding: 20px;
                padding-left: 20px;
                padding-right: 20px;
                margin: 0 20px;
            }
        }

        .item, .card, a {
            width: 300px;
            display: inline-block;
            vertical-align: top;
            flex-shrink: 0;
            user-select: none;
            white-space: normal;
            margin-right: 30px;

            &.card-offre {
                @include aspect-ratio(300, 420);
                overflow: visible;

                &:before {
                    content: none;
                }

                > div {
                    position: relative;
                }

                .title-card {
                    margin: 0 0 15px 0;
                }
                .reservable{
                    bottom: 0;
                }
            }
            &.edito-v2{
                figure,.no-photo {
                    @include aspect-ratio(300, 420);
                }
            }
            @include tablet_landscape {
                width: 300px;
            }
            @include mobile{
                margin-right: 20px;
            }
            @include mobile420{
                width: calc(100vw - 120px);
            }
            @include mobile_small{
                width: calc(100vw - 80px);
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    // NAV ARROWS
    .nav {
        z-index: 500;
        max-width: $max-width;
        margin: auto;
        @include mobile {
            display: none;
        }

        // TOUCH DEVICE
        .no-hover & {
            button {
                &:first-child {
                    @include desktop {
                        left: $border-gutter*2;
                    }
                    @include tablet_landscape {
                        left: 60px;
                    }
                }
                &:last-child {
                    @include desktop {
                        right: $border-gutter*2;
                    }
                    @include tablet_landscape {
                        right: 60px;
                    }
                }
            }
        }

        button {
            @extend %appearance;
            position: absolute;
            top: 50%;
            z-index: 600;
            margin-top: -30px;
            padding: 0;
            outline: none;
            overflow: visible;
            color: $c_white;
            background: $c_main;
            border-radius: 4px;
            width: 60px;
            height: 60px;
            line-height: 60px;
            font-size: 0;
            display: block;
            text-align: center;
            @include addTransition();
            @include tablet_landscape {
                width: 40px;
                height: 40px;
                line-height: 40px;
                margin-top: -20px;
            }
            &:after {
                content: '';
                @include font();
                color: $c_white;
                font-size: 15px;
                @include addTransition();
                @include tablet_landscape{
                    font-size: 12px;
                }
            }

            &:disabled {
                opacity: 0;
                cursor: auto;
                pointer-events: none;
            }
            &:first-child {
                left: calc((100% - 50vw) / 2);
                margin-left: -30px;
                @include desktop {
                    left: 0;
                }
                @include tablet_landscape {
                    margin-left: 0;
                }
                &:after {
                    content: "\e903";
                }
            }
            &:last-child {
                right: calc((100% - #{$max-width-large}) / 2);
                margin-right: -30px;
                @include desktop {
                    right: 0;
                }
                @include tablet_landscape {
                    margin-right: 0;
                }
                &:after {
                    content: "\e901";
                }
            }

            &:hover, &:focus {
                body:not(.no-hover) & {
                    &:not(:disabled) {
                        cursor: pointer;
                        background: $c_third;
                    }
                }
            }
        }
    }

    // DOTS
    .dots {
        position: absolute;
        right: calc((100% - #{$max-width-large}) / 2);
        bottom: 63px;
        font-size: 0;
        z-index: 100;
        @include tablet_landscape{
            bottom: 43px
        }
        button {
            width: 24px;
            height: 5px;
            border: none;
            outline: none;
            background: $c_white;
            cursor: pointer;
            padding: 0;
            margin: 0 5px;
            @include addTransition();
            &:hover {
                body:not(.no-hover) & {
                    background-color: $c_third;
                }
            }

            &.active {
                background-color: $c_third;
                &:hover {
                    body:not(.no-hover) & {
                        background-color: $c_third;
                    }
                }
            }
        }
    }
}