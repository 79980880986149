$margin: 20px;
$circleSize: 12px;
$circleRight: $circleSize / 2 + $margin;

.myex-sidebar {
	display: flex;
	gap: calculateRem(30px);
	flex-direction: column;
	height: 100%;
	position: relative;

	//line on the right
	&:after {
		content: "";
		width: 1px;
		background: var(--color-border);
		right: calculateRem($circleSize / 2);
		position: absolute;
		top: 0;
		bottom: 0;
	}

	&__brand {
		display: flex;
		align-items: center;
		gap: calculateRem(13px);
		font-weight: 600;
		letter-spacing: 0.02em;
		cursor: pointer;

		&.active {
			color: var(--color-myex);
		}
	}

	&__nav {
		overflow-y: scroll;
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none; /* Firefox */
		overflow: -moz-scrollbars-none;

		&::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}

		.myex-logo-icon {
			font-size: calculateRem(27px);
		}

		ul {
			margin: 0;
			display: flex;
			gap: calculateRem(30px);
			flex-direction: column;

			li {
				line-height: 1.3;
				font-weight: 600;
				color: currentColor;
				padding-right: calculateRem($circleRight);
			}

			a,
			button {
				appearance: none;
				background: none;
				border: none;
				color: currentColor;
				padding: 0;
				font-size: calculateRem(16px);
				font-weight: 600;
				letter-spacing: 0.02em;
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: calculateRem(10px);
				transition: all ease-in-out 500ms;

				&:hover {
					color: var(--color-primary);
				}
			}

			.myex-inspiration {
				a,
				button {
					opacity: 0.5;
					&:hover {
						opacity: 1;
						color: var(--color-text);
					}
				}
			}

			.myex-nav__submenu {
				padding-left: calculateRem(28px);
				display: flex;
				gap: calculateRem(15px);
				flex-direction: column;
				margin-top: calculateRem(19px);
				max-height: 0;
				margin-right: -26px;
				transition: all ease 500ms;
				padding-right: calculateRem($circleRight);

				li {
					padding-right: 0;

					&:after {
						right: calculateRem(-$circleRight);
					}
					a,
					button {
						font-size: calculateRem(12px);
					}
				}
			}
		}
	}

	&__bottom {
		margin-top: auto;
		padding-right: calculateRem($circleRight);
	}

	//open menu state
	.open-menu {
		.myex-nav__submenu {
			max-height: 999px;
		}
	}

	//active menu
	.active a,
	.active button {
		color: #e04b6e;
	}

	.myex-nav__submenu .active a,
	.myex-nav__submenu .active button {
		color: var(--color-primary);
	}

	//circle current active menu
	.active {
		position: relative;
		&:after {
			content: "";
			border-radius: 50%;
			height: calculateRem($circleSize);
			width: calculateRem($circleSize);
			right: 0;
			position: absolute;
			top: 50%;
			bottom: 0;
			background: var(--color-primary);
			transform: translate(0%, -50%);

			z-index: 7;
		}
	}
}
