.myex-form--create-collection {
	position: relative;
    margin-bottom: calculateRem(15px);

	.myex-form-field {
		margin: 0;

		.myex-field {
			padding: calculateRem(10px) calculateRem(15px);
			background: var(--bg-grey-light);
			border: none;
			min-height: calculateRem(62px);
			font-weight: 600;
		}
	}

	#create_collection__input-cta {
		right: calculateRem(15px);
		top: 50%;
		transform: translateY(-50%);
		position: absolute;
		line-height: 1;
		padding-left: calculateRem(30px);
		padding-right: calculateRem(30px);

		&[disabled="true"] {
			background-color: #c5c5c5;
			border: #c5c5c5;
		}
	}

	
}
