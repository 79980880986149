


.btn-mobile-layer {
    @include reset-button;
    position: relative;
    z-index: 100;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: $c_white;
    background-color: $c_main;
    border-radius: 4px;

    &:before {
        content: "\e900";
        @include font();
        font-size: 16px;
    }
}



.btn-icon {
    @include reset-button;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 18px;
    min-width: 100px;
    @include ft('', 14px, 500, $c_white, 1.3);
    background-color: $c_main;
    border-radius: 4px;
    @include addTransition();

    &:before{
        @include font();
        content: var(--icon-type);
        display: inline-block;
        margin-right: 20px;
        font-size: 24px;
        transform: translateY(-2px);
        color: $c_white;
    }

    @include can-hover {
        color: $c_white;
        background-color: $c_greydark;
    }

    // -- modifiers
    &.--secondary {
        background-color: $c_sub;
    }
    &.--tertiary {
        background-color: $c_third;
    }



    &.--small {
        padding: 14px 20px 12px;
        font-size: 13px;

        &:before {
            font-size: 14px;
        }
    }

    &.--icon-right {
        flex-direction: row-reverse;

        &:before {
            margin-right: initial;
            margin-left: 10px;
        }
    }


    &.--icon-photos {
        --icon-type: "\e927";
    }
    &.--icon-etapes {
        --icon-type: "\e995";
    }
    &.--icon-chevron-slider-right {
        --icon-type: "\e901";
    }


    &.--hover-grey {
        @include can-hover {
            background-color: $c_grey;
        }
    }
}



.btn-filters {
    @include reset-button;
    display: inline-flex;
    align-items: center;
    padding: 13px 40px 11px;
    @include ft('', 14px, 600, $c_dark_grey, 1);
    background-color: $c_white;
    border: 2px solid #D5D9DC;
    border-radius: 4px;
    @include addTransition();

    @include tablet_portrait {
        padding: 13px 20px 11px;
    }

    @include mobile {
        padding: 10px 8px 8px;
        font-size: 11px;
    }

    &:after {
        @include font();
        content: '\e93f';
        display: inline-block;
        margin-left: 13px;
        font-size: 18px;

        @include mobile {
            margin-left: 4px;
            font-size: 13px;
        }
    }

    @include can-hover {
        color: $c_white;
        background-color: $c_third;
        border-color: $c_third;
    }
}


// Boutons pages suivantes / précédentes
.btn-pagination {
    @include reset-button;
    display: inline-flex;
    align-items: center;
    padding: 17px 40px 15px;
    min-width: 100px;
    @include ft('', 13px, 500, $c_dark_grey, 1);
    background-color: #F3F4F5;
    border-radius: 3px;
    @include addTransition();

    @include mobile {
        min-width: initial;
        padding: 17px 14px 15px;
        font-size: 11px;
    }

    &:before,
    &:after {
        @include font();
        display: inline-block;
        font-size: 13px;
    }

    @include can-hover {
        color: $c_white;
        background-color: $c_third;
    }

    // --modifiers
    &.--prev {
        @include mobile {
            padding: 17px 26px 15px;
        }

        &:before {
            content: '\e90b';

            @include min-mobile {
                margin-right: 20px;
            }
        }

        span {
            @include mobile {
                display: none;
            }
        }
    }

    &.--next {
        color: $c_white;
        background-color: $c_third;

        @include mobile {
            justify-content: center;
        }

        &:after {
            content: '\e90c';
            margin-left: 20px;
        }
    }
}


.btn-map-overlay {
    @include reset-button;
    display: inline-flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    @include ft('', 11px, 400, $c_white, 1);
    background-color: rgba($c_greydark, 0.9);
    border-radius: 20px;
    filter: drop-shadow(0 4px 8px rgba($c_dark,0.35));


    &:before {
        @include font();
        content: '\e91e';
        display: inline-block;
        margin-right: 6px;
        font-size: 14px;
    }
}


.btn-close-back-overlay {
    @include reset-button;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    @include ft('', 11px, 400, $c_greydark, 1);
    text-decoration: underline;
    background-color: $c-white;
    border-top: 1px solid $c_light;

    @include can-hover {
        color: $c_third;
    }
}

//Badge ReCaptcha
.grecaptcha-badge {
    display: none !important;
}