.bloc__recipe_step {
    max-width: 900px;
    width: 100%;

    display: flex;
    flex-direction: row;
    position: relative;
    padding-bottom: 58px;

    &::after {
        position: absolute;
        content: '';
        bottom: 18px;
        left: 0;
        background-color: #ABB3B9;
        width: 100%;
        height: 1px;
        opacity: 0.7;
    }

    &--incr {
        position: absolute;
        z-index: 10;
        top: 10px;
        left: 10px;
        width: 42px;
        height: 42px;

        @include mobile {
            top: -32px;
            width: 24px;
            height: 24px;
        }

        border-radius: 26px;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal normal 600 18px/27px Poppins;
        letter-spacing: 0px;
        color: #DEA99E;
    }

    figure {
        width: 437px;
        height: 287px;

        @include mobile {
            width: 120px;
            height: 79px;
        }

        border-radius: 10px;
        overflow: hidden;
        background-color: #DCDBDA;
        position: relative;

        &::before {
            position: absolute;
            content: '\e9e7';
            width: 100%;
            height: 100%;
            @include font();
            color: var(--white);
            font-size: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    p {
        font: normal normal normal 15px/26px Libre Baskerville;
        color: var(--black);
        width: 437px;
        margin-left: 26px;

        @include mobile {
            margin-left: 13px;
            width: calc(100% - 120px);

        }
    }
}