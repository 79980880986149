// Tooltip
// ==========================================================================
.tooltip {
	position: relative;
	display: inline-block;
	z-index: 1000;
	@include addTransition(visibility, 0.3s, ease);

	&:hover {
		.tooltiptext {
			visibility: visible;
			color: $c_greydark !important;
		}
	}

	.tooltiptext {
		@include ft($f_txt, 12px, 300, $c_greydark !important, 20px);
		box-sizing: border-box;
		visibility: hidden;
		white-space: nowrap;
		top: calc(50% + 20px);
		left: 50%;
		width: 90px;
		background: $c_white;
		box-shadow: 2px 4px 10px rgba($c_greydark, 0.1);
		text-align: center;
		padding: 4px 12px;
		border-radius: 4px;
		/* Position the tooltip text - see examples below! */
		position: absolute;
		margin-left: -45px;
		@include addTransition(visibility, 0.1s, ease-in-out);

		.no-hover & {
			display: none;
		}
	}
}


// Evitement - Haut de page
// ==========================================================================
.list-skip-links {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 100%;
}

.evitement {
	@include ft($f_hx, $s_p, 700, $c_white, $s_p);
	text-transform: uppercase;
	position: absolute;
	left: -999999px;
	width: auto;
	display: inline-block;

	&:focus {
		body:not(.no-hover) & {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 1000000000000000;
			padding: 10px 15px;
			background: $c_sub;
			color: $c_white;
		}
	}
}


// Texte Expand
// ==========================================================================
[data-expand] {
	position: relative;
	overflow: hidden;

	&.to-expand {
		@include addTransition();

		&:not(.expand) {
			&:before {
				content: '';
				@extend %center;
				z-index: 12;
				/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+90&0+0,1+90 */
				background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
				top: auto;
				height: 80px;
			}
		}

	}

	&.expand {
		overflow: visible;

		& + .cta-expand,
		& .cta-expand {
			display: none;
		}
	}

	& + .expand-link {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 100;
	}
}

.cta-expand {
	@include ft($f_txt, 14px, 600, $c_greydark, 1.14);
	@extend %appearance;
	background: none;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-left: auto;
	padding: 0;
	@include addTransition();

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;
			color: $c_third;
		}
	}

	&:after {
		content: '';
		width: 20px;
		height: 3px;
		margin-top: 4px;
		background-color: $c_third;
		border-radius: 3px;
		@include addTransition();
	}

	&.--style-button {
		&:before {
			display: none;
		}

		position: relative;
		align-self: center;
		margin-left: initial;
		padding: 10px 20px 9px 20px;
		@include ft('', 14px, 500, $c_white, 1.42);
		border-radius: 4px;
		background: $c_third;
		transform: translateY(26px);

		@include can-hover {
			background: $c_greydark;
			color: $c_white !important;
		}
	}
}


[data-expand="110"] {
	//&:not(.expand) {
	&.to-expand:not(.expand) {
		height: 110px !important;
	}

	&:not(.parsed) {
		height: 110px !important;
	}
}


[data-expand="120"] {
	//&:not(.expand) {
	&.to-expand:not(.expand) {
		height: 120px !important;
	}

	&:not(.parsed) {
		height: 120px !important;
	}
}

[data-expand="180"] {
	//&:not(.expand) {
	&.to-expand:not(.expand) {
		height: 180px !important;
	}

	&:not(.parsed) {
		height: 180px !important;
	}
}

[data-expand="200"] {
	//&:not(.expand) {
	&.to-expand:not(.expand) {
		height: 200px !important;
	}

	&:not(.parsed) {
		height: 200px !important;
	}
}

[data-expand="300"] {
	//&:not(.expand) {
	&.to-expand:not(.expand) {
		height: 300px !important;
	}

	&:not(.parsed) {
		height: 300px !important;
	}
}

[data-expand="400"] {
	//&:not(.expand) {
	&.to-expand:not(.expand) {
		height: 400px !important;
	}

	&:not(.parsed) {
		height: 400px !important;
	}
}

[data-expand="450"] {
	//&:not(.expand) {
	&.to-expand:not(.expand) {
		height: 450px !important;
	}

	&:not(.parsed) {
		height: 450px !important;
	}
}

[data-expand="500"] {
	//&:not(.expand) {
	&.to-expand:not(.expand) {
		height: 500px !important;
	}

	&:not(.parsed) {
		height: 500px !important;
	}
}


[data-expand="550"] {
	&.to-expand:not(.expand) {
		height: 550px !important;
	}

	&:not(.parsed) {
		height: 550px !important;
	}
}

[data-expand="600"] {
	&.to-expand:not(.expand) {
		height: 600px !important;
	}

	&:not(.parsed) {
		height: 600px !important;
	}

	&.to-expand:not(.expand)::before {
		z-index: 90;
		bottom: -1px;
		height: 200px;
		background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 70%, rgba(255,255,255,1) 100%);
	}
}

[data-expand="620"] {
	&.to-expand:not(.expand) {
		height: 620px !important;
		@include mobile {
			height: 550px !important;
		}
	}

	&:not(.parsed) {
		height: 620px !important;
		@include mobile {
			height: 550px !important;
		}
	}
}

[data-expand="800"] {
	&.to-expand:not(.expand) {
		height: 800px !important;
	}

	&:not(.parsed) {
		height: 800px !important;
	}

	&.to-expand:not(.expand)::before {
		z-index: 90;
		bottom: -1px;
		height: 200px;
		background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 70%, rgba(255,255,255,1) 100%);
	}
}


.button-scroll {
	@extend %appearance;
	padding: 0;
	background: none;
	display: block;
	margin: auto;
	margin-top: 20px;
	@include mobile {
		margin-top: 10px;
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;

			.mouse {
				border-color: $c_third;
			}

			.scroll {
				background: $c_third;
			}
		}
	}

	.mouse {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		z-index: 100;
		width: 24px;
		height: 40px;
		border-radius: 20px;
		margin: auto;
		@include addTransition();
	}

	[class^="icon-"], [class*=" icon-"] {
		font-size: calculateRem(15px);
		animation: finger 1.5s infinite;
	}

	.scroll {
		display: inline-block;
		width: 2px;
		height: 7px;
		margin: 5px auto;
		border-radius: 6px;
		background: $c_white;
		animation: finger 1.5s infinite;
		@include addTransition();
	}

	@keyframes finger {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			transform: translateY(8px);
		}
	}
}

//CSS pour le pixel de pageview
.stats-pageview {
	position: absolute;
	top: 0;
	right: 0;
	opacity: 0;
	pointer-events: none;
	display: block;
}