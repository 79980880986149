.largearticle {

    background-color: var(--darkblue);

    .lemag__title {
        @include mobile {
            display: none;
        }
    }

    .content-wrapper--lemag-title {
        @media screen and (min-width: 1440px) {
            margin: 0 80px;
        }
    }

    &__bg {

        position: relative;

        &::before {
            content: '';
            position: absolute;
            z-index: 0;
            top: -505px;
            width: 100%;
            height: calc(100% + 505px);
            background-color: var(--white);
        }
    }

    &__header {
        &::before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            top: 8px;
            margin: auto;
            width: 129px;
            height: 4px;
            border-radius: 2px;
            background-color: rgba(#1E2529, 0.3);
            display: none;

            @include mobile {
                display: block;
            }
        }

        position: relative;
        z-index: 1;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        max-width: 1440px;
        width: 100%;
        padding-top: 60px;
        margin: 0px auto;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        h3 {

            position: relative;
            font: normal normal normal 11px/17px Poppins;
            letter-spacing: 1.65px;
            color: var(--darkblue);
            text-transform: uppercase;
            text-align: center;
            padding-left: 13px;
            margin-bottom: 7px;
            display: flex;

            @include mobile {
                font-size: 9px;
                line-height: 13px;

            }

            &::before {
                content: '\e907';
                @include font();
                font-size: 10px;
                color: var(--darkblue);
                top: 3px;
                left: 0;
                position: absolute;
                height: 10px;
                width: 6px;
            }
        }

        h2 {
            text-align: center;
            font: normal normal bold 22px/38px Poppins;
            
            color: var(--darkblue);
            text-transform: uppercase;
            margin: 0;

            @include mobile {
                font-size: 15px;
                line-height: 20px;
                
            }
        }

        h1 {
            font: normal normal bold 44px/48px Poppins;

            color: var(--darkblue);
            text-align: center;

            @include mobile {
                font-size: 28px;
                line-height: 34px;
                padding: 0 15px;
            }
        }

        p {
            font: normal normal normal 22px/34px Libre Baskerville;
            text-align: center;
            color: var(--darkblue);

            @include mobile {
                font-size: 15px;
                line-height: 22px;
                padding: 0 15px;
            }
        }



    }

    &__nav {
        position: relative;
        z-index: 5;
        max-width: 1600px;
        width: 100%;
        margin: 0 auto;
        background-color: #fff;
    }


    &__content {
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 1230px;
        margin: 0 auto;

    }


}