main {
    header {
        position: relative;

        & + .bloc {
            padding-top: 100px;
            @include mobile {
                padding-top: 40px;
            }
        }

        figure {
            height: 100%;

            &:before {
                content: '';
                @extend .gradient-header;
                z-index: 10;
                opacity: 0.65;
                height: 300px;
            }

            img {
                height: 100%;
            }

            figcaption {
                display: none;
            }
        }

        .credits {
            display: none;
        }

        // Hauteur de page - 80px
        // ==========================================================================
        &.fullpage-header {
            position: relative;
            height: 70vh;

            @include tablet_portrait {
                &::before {
                    content: "";
                    position: absolute;
                    width: 130px;
                    top: 9px;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 4px;
                    background-color: rgba(#fff, 0.6);
                    border-radius: 4px;
                    z-index: 90;
                }
            }

            .text-cover {
                 * {
                    color: $c_white;
                }
            }

            #breadcrumb {
                margin-bottom: 18px;
            }
            

            figure {
                &:after {
                    content: '';
                    @extend .gradient-bottom;
                    top: auto;
                    height: 70%;
                    opacity: 0.85;
                    z-index: 10;
                    @include mobile{
                        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#202529+0,202529+75&0+0,1+75 */
                        background: -moz-linear-gradient(top,  rgba(32,37,41,0) 0%, rgba(32,37,41,1) 75%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(top,  rgba(32,37,41,0) 0%,rgba(32,37,41,1) 75%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to bottom,  rgba(32,37,41,0) 0%,rgba(32,37,41,1) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00202529', endColorstr='#202529',GradientType=0 ); /* IE6-9 */
                    }
                }
            }

            .caption {
                #breadcrumb {
                    @extend %breadcrumb-white;

                    @include mobile {
                        display: none;
                    }
                }

                padding-bottom: 60px;
                @include mobile {
                    padding-bottom: 30px;
                }
            }

            //#11204
            &.no-pic{
                position: relative;
                height:auto !important;
                min-height: 0 !important;
                padding-bottom: 5px;

                background-color: $c_white;

                &::before {
                    background-color: rgba(#1E2529, 0.3);
                }
                
                .no-photo{
                    display: none;
                }
                .caption{
                    position: relative;

                    padding-top: 60px;
                    @include tablet_portrait{
                        padding-top: 40px;
                    }
                    
                    *{
                        color: $c_greydark;
                    }
                    #breadcrumb{
                        span, a{
                            color: $c_greydark;
                        }

                        a{
                            &:hover, &:focus {
                                body:not(.no-hover) & {
                                    span{
                                        color: $c_main !important;
                                    }
                                }
                            }
                        }
                    }

                    .text-cover {
                         * {
                             color: $c_greydark;
                         }
                    }
                }


                &.colored-bg{
                    background-color: $c_greydark;
                    overflow: hidden;

                    .caption{
                        *{
                            color: white;
                        }
                        #breadcrumb {
                            @extend %breadcrumb-white;
                        }
                    }

                    &::before {
                        background-color: rgba(#fff, 0.6) !important;
                    }
                }


                +.bloc-ancre{
                    hr:first-child{
                        display: block;
                    }
                }
                
            }
        }

        // Header avec hauteur fixe
        // ==========================================================================
        &.small-header {
            height: 450px;
        }


        // Caption text
        // ==========================================================================
        .caption {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            z-index: 20;
            > * {
                color: $c_white;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            p {
                line-height: 26px;
            }

            h1 {
                font-size: 65px;
            }
            @include desktop {
                margin: 0 80px;
                padding: 0 40px;
                width: calc(100% - 160px);
            }
            @include tablet_landscape {
                margin: 0 40px;
                width: calc(100% - 80px);
            }
            @include mobile{
                margin: 0 20px;
                width: calc(100% - 40px);
                padding: 0 0;

                h1 {
                    font-size: 32px !important;
                    line-height: 35px;
                }

                .text-cover > * {
                    font-size: 15px;
                }

                p {
                    font-size: 15px;
                    line-height: 21px;
                }
            }

            .wrapper-cat {
                margin-bottom: 10px;
                max-width: 100%;
                display: inline-block;
                @include mobile{
                    white-space: normal;
                    overflow: visible;
                    text-align: left;
                }

                span, a, button {
                    font-weight: 500;
                }
            }

            p {
                max-width: 750px;
                margin-left: auto;
                margin-right: auto;
                @include mobile{
                    font-size: calculateRem(14px);
                    line-height: calculateRem(24px);
                }
            }
        }

        

        .btn-overlay-webcam {
            position: absolute;
            z-index: 1;
            bottom: 40px;
            right: -20px;
            appearance: none;
            display: block;
            padding: 0;
            border: none;
            outline:none;
            background-color: transparent;
            @include addTransition();

            &:hover, &:focus {
                body:not(.no-hover) & {
                    cursor: pointer;
                    transform: rotate(-3deg) scale(1.08);
                }
            }

            @include desktop {
                position: relative;
                margin-left: auto;
                bottom: initial;
                right: initial;
                margin-bottom: -30px;
            }

            @include tablet_portrait {
                width: 80px;
                margin-bottom: -20px;
            }
        }
    }
}


// STYLE POUR L'INSERTION D'UNE VIDEO EN BACKGROUND
// ==========================================================================
.cover {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .container-video {
        z-index: 20;
        @include aspect-ratio(16, 9);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        background: $c_dark;
        //height: 100%;
        @media screen and (max-aspect-ratio: 165/101) {
            width: 130%;
        }
        @media screen and (max-aspect-ratio: 138/101) {
            width: 140%;
        }
        @media screen and (max-aspect-ratio: 126/72) {
            width: 120%;
        }
        @media screen and (max-aspect-ratio: 128/80) {
            width: 115%;
        }
        @media screen and (max-aspect-ratio: 1223/960) {
            width: 110%;
        }
        @media screen and (max-aspect-ratio: 1485/960) {
            width: 140%;
        }
        @media screen and (max-aspect-ratio: 118/101) {
            display: none;
        }
        @include tablet_portrait {
            display: none;
        }

        > video {
            @extend %center;
            width: 100%;
            height: 100%;
            z-index: 50;
            @include tablet_portrait {
                display: none;
            }
        }
    }

    figure {
        @extend %center;
        width: 100%;
        height: 100%;
    }
}


// Metas Datas Cover
// ==========================================================================
.wrapper-meta-header{
    position: relative;

    @include tablet_portrait {
        margin: 0 20px;
    }

    &:before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background: rgba($c_white, 0.2);
        @include mobile{
            left: -20px;
            right: -40px;
            width: calc(100% + 60px);
        }
    }
}
.metas-header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    @include mobile {
        margin-left: -40px;
        margin-right: -40px;
        padding: 27px 40px 27px;
        scroll-padding: 40px;
        display: block;
        overflow: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: both mandatory;
        font-size: 0;
        white-space: nowrap;
    }

    li {
        @include ft($f_hx, 15px, 600, $c_txt, 15px);
        margin: 20px 35px;
        text-align: left;
        padding-left: 50px;
        @include tablet_portrait{
            margin: 20px 25px;
        }
        @include mobile {
            font-size: calculateRem(13px);
            line-height: calculateRem(13px);
            margin: 10px 0;
            padding: 0 30px 0 40px;
            display: inline-block;
            vertical-align: middle;
            scroll-snap-align: start;
        }

        [class^="icon-"], [class*=" icon-"] {
            position: absolute;
            top: -3px;
            left: 0;
            font-size: 35px;
            color: $c_third;
            @include mobile{
                font-size: 30px;
            }
        }

        span {
            display: block;
            font-weight: 300;
        }
    }
}


.js-scroll-search-display {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    height: 90px;
    width: 100%;
}

.header-left {
    @include tablet_portrait {

        position: relative;
        background-color: $c_greydark;

        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background-color: white;
            border-radius: 14px 14px 0 0;
        }

        &::after {
            content: "";
            position: absolute;
            width: 130px;
            top: 9px;
            left: 50%;
            transform: translateX(-50%);
            height: 4px;
            background-color: rgba(#1E2529, 0.3);
            border-radius: 4px;
            z-index: 90;
        }
    }
}


.breadcrumb-mobile-wrapper {
    display: none;
    background-color:  $c_greydark;
    padding-bottom: 10px;

    @include tablet_portrait {
        display: block;
    }

    #breadcrumb {

        overflow: visible;
        
        span, a {
            color: $c_white;
            line-height: 18px;
        }

        [typeof="BreadcrumbList"] {
            > span:nth-child(1) a {
                &::before {
                    content: "\e9c5  /"; // icon maison
                    @include font();
                    font-size: 15px;
                    margin-top: -4px;
                }
            }

            a {
                &::after {
                    content: "/";
                    margin: 0;
                }
            }
        }

        .breadcrumb_last { 
            position: relative;

            span {
                color: $c_third;
            }
        }
    }
}

// Spé. page hub station
.page-template-page-hub-station {
    main {
        header {
            .content-wrapper {

                @include mobile {
                    display: flex;
                    padding-left: 0;
                    padding-right: 0;
                    align-items: center;
                }

                h1 {

                    @media screen and (max-width: 1440px) {
                        max-width: 750px;
                        margin: auto;
                    }

                    @include tablet_portrait {
                        max-width: 550px;
                    }

                    @include mobile {
                        max-width: unset;
                        margin: unset;
                        font-size: 24px !important;
                        text-align: left;
                        margin-bottom: 0;
                        line-height: 31px;
                    }
                }

                .btn-overlay-webcam {

                    @media screen and (max-width: 1440px) {
                        position: absolute;
                        right: -20px;
                        bottom: 50px;
                    }

                    @include tablet_portrait {
                        width: 125px;
                        right: -30px;
                    }

                    @include mobile {
                        width: 90px;
                        flex-shrink: 0;
                        position: relative;
                        right: unset;
                        bottom: unset;
                    }
                }
            }
        }
    }
}

//Spé. archives exp
.post-type-archive-experiences {

    main {

        header {

            .content-wrapper {

                h1{
                    @include mobile {
                        font-size: 24px;
                        line-height: 1.8rem;
                    }
                }
            }
        }
    }
}

header {
    #breadcrumb {
        @include tablet_portrait {
            display: none;
        }
    }
}